import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import fields from '../../../../config/quoteShippingFields.json';
import StepOne from '../../stepsComponents/stepOne/stepOne';

import Modal from '../../../modal';
import ModalChildren from '../../../modalChildren';

import './stepOne.scss';

const OneStep = ({
  next,
  setErrorsList,
  errorsList,
  isMobileDevice,
  userToUpdate,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [listFields, setListFields] = useState([]);
  const countryCode = useSelector((state) => state.country);
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    setListFields(fields.country[countryCode].fieldsCompletingData
      .filter((item) => item.step === 1));
  }, [fields.country[countryCode]]);

  const fieldsForm = {
    businessName: user.businessName || '',
    locationName: user.locationName || '',
    address: user.address || '',
  };

  return (
    <>
      <div id="container-info-receiver">
        <StepOne
          errorsList={errorsList}
          listFields={listFields}
          title="Información del remitente"
          subtitle="Se requieren los siguientes datos para procesar tus envíos."
          typeTypography="title"
          setErrorsList={setErrorsList}
          classListFields="content"
          isMobileDevice={isMobileDevice}
          countryCode={countryCode}
          titleButton="Continuar"
          next={(formValues) => {
            next({
              ...formValues,
              locationCode: user ? user.locationCode : formValues.firstLocationCode,
            });
          }}
          changes={userToUpdate}
          classButton="button-container"
          valuesForm={fieldsForm}
        />
      </div>

      {modalContent && (
        <Modal>
          <ModalChildren
            className="modalCompletingData"
            close={() => setModalContent(null)}
            headerText={modalContent.title}
            confirmButtonText={modalContent.type === 'success' ? '¡Entendido!' : 'Intentar de nuevo'}
            onClickButtonConfirm={() => setModalContent(null)}
            bodyText={modalContent.text}
          />
        </Modal>
      )}
    </>
  );
};

OneStep.propTypes = {
  next: PropTypes.func.isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setErrorsList: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  userToUpdate: PropTypes.shape({
    businessName: PropTypes.string,
    locationCode: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
};

export default OneStep;
