/* eslint-disable import/prefer-default-export */
import { wompi, wompiPublicKey } from '../../../../config/nocompanyconfig.fron.json';
import { getWompi, postWompi } from '../../../../services/wompi';

const getTransaction = async (transactionId) => new Promise((resolve, reject) => {
  const endPoint = `${wompi.urlBase}/${wompi.transactionEnpoint}/${transactionId}`;
  setTimeout(() => {
    getWompi(endPoint)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  }, 1000);
});

export const createTransaction = async (ammount, acceptanceToken, email, userId) => {
  try {
    const data = {
      acceptance_token: acceptanceToken,
      currency: 'COP',
      amount_in_cents: ammount,
      customer_email: email,
      reference: `${Date.now()}-${userId}`,
      payment_method: {
        type: 'BANCOLOMBIA_TRANSFER',
        user_type: 'PERSON',
        payment_description: 'Recarga de saldo mi paquete',
        ...wompi.devEnv ? { sandbox_status: 'APPROVED' } : {},
      },
    };

    const endPoint = `${wompi.urlBase}/${wompi.transactionEnpoint}`;
    const headers = { Authorization: `Bearer ${wompiPublicKey}` };

    const transactionCreated = await postWompi(endPoint, headers, data);
    const transactionFound = await getTransaction(transactionCreated?.id);

    return transactionFound?.payment_method?.extra?.async_payment_url;
  } catch (error) {
    return error;
  }
};
