/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
import { removeAccents } from '../../../utils/strings';
import { findLocationByName } from '../../../utils/filters';

// Funcion para tomar los datos del excel y formatear los nombres de las columnas
// que asigna los valores a nombres de variables ya conocidas, legibles y generales en la plataforma
export const formatJsonDataExcelFunction = (row, locations) => {
  const newRow = {};
  const originLocation = findLocationByName(
    row['Ciudad Origen*'],
    row['Departamento Origen*'],
    locations,
  );
  const destinyLocation = findLocationByName(
    row['Ciudad Destino*'],
    row['Departamento Destino*'],
    locations,
  );

  newRow.shippingOriginName = row['Ciudad Origen*'];
  newRow.shippingOriginCode = originLocation.locationCode;
  newRow.shippingOriginDepartamentName = row['Departamento Origen*'];
  newRow.shippingOriginDepartamentCode = originLocation.departmentOrStateCode;
  newRow.shippingOriginCountryName = 'Colombia';
  newRow.shippingOriginCountryCode = originLocation.countryCode;
  newRow.shippingDestinyName = row['Ciudad Destino*'];
  newRow.shippingDestinyCode = destinyLocation.locationCode;
  newRow.shippingDestinyDepartamentName = row['Departamento Destino*'];
  newRow.shippingDestinyDepartamentCode = destinyLocation.departmentOrStateCode;
  newRow.shippingDestinyCountryName = 'Colombia';
  newRow.shippingDestinyCountryCode = destinyLocation.countryCode;
  newRow.articleHeight = row['Alto* (cm)'];
  newRow.articleWidth = row['Ancho* (cm)'];
  newRow.articleLength = row['Largo* (cm)'];
  newRow.articleWeight = row['Peso en Kilos* (por unidad)'];
  newRow.quantityOfArticles = row['Unidades logísticas* (Cantidad de paquetes)'];
  newRow.declaredValue = row['Valor declarado* (sin puntos)'];
  const collectionService = !!(removeAccents(row['¿Necesitas Servicio de pago Contra Entrega?* (Escribe Si o No)']).toLowerCase() === 'si');
  newRow.collectionService = collectionService;
  const saleValue = row['Valor de la venta* (Escribe sin puntos el valor de la venta si tu envío es con pago contra entrega, de lo contrario escribe N/A)'];
  if (collectionService && !isNaN(saleValue)) {
    newRow.saleValue = saleValue;
  }
  const addShippingToCollectionService = !!(removeAccents(row['¿Quieres que sumemos el costo del envío al valor de la venta?* (Si tu envío es con Pago Contra Entrega escribe SI o NO, de lo contrario escribe N/A)']).toLowerCase() === 'si');
  if (collectionService && addShippingToCollectionService) {
    newRow.addShippingToCollectionValue = addShippingToCollectionService;
  }
  newRow.deliveryCompanySelected = {};
  newRow.articleDescription = row['Descripción de producto*']?.toString();
  newRow.recommendationsForDeliveryCompany = row['Recomendaciones para la transportadora (Opcional; No horarios, ni días especificos)'];
  newRow.notProhibitedArticles = false;
  newRow.senderName = null;
  newRow.senderSurname = null;
  newRow.senderIdentificationNumber = null;
  newRow.senderIdentificationType = null;
  newRow.senderEmail = null;
  newRow.senderCellPhone = null;
  newRow.senderAccountBank = null;
  newRow.senderAddress = row['Dirección de recogida, donde se encuentra el paquete.* (Puedes poner en cada fila una dirección diferente para cada paquete)'];
  newRow.collectionOrTakeToOffice = 'collectionInAddress'; // Tambien puede ser 'takeToOffice'
  newRow.receiverName = row['Nombre destinatario*'];
  newRow.receiverSurname = row['Apellido destinatario*'];
  newRow.receiverIdentificationNumber = null;
  newRow.receiverEmail = row['Email destinatario (Opcional, si lo escribes le podrán llegar notificaciones sobre su envío)']?.trim();
  newRow.receiverCellPhone = row['Celular destinatario*'];
  newRow.receiverAddress = row['Dirección destinatario*'];
  newRow.deliveryInAddressOrOffice = 'deliveryInAddress'; // Tambien puede ser 'deliveryInOffice'
  newRow.paymentMethod = null; // Al no haber pago contraentrega el metodo 'pago con saldo' estara por defecto
  newRow.conflicts = [];
  newRow.rowNum = row.__rowNum__;

  return newRow;
};

export default formatJsonDataExcelFunction;
