/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import './typography.scss';

const Typography = ({
  personalizedId, className, type, text, children,
  style, onClick,
}) => {
  switch (type) {
    case 'superTitle-italic':
      return (
        <h1
          id={personalizedId}
          className={`italic ${className ? className : ''}`}
          style={style}
          onClick={() => onClick && onClick()}
        >
          {text}
          {children}
        </h1>
      );
    case 'superTitle':
      return (
        <h1
          id={personalizedId}
          className={className ? className : ''}
          style={style}
        >
          {text}
          {children}
        </h1>
      );
    case 'title':
      return (
        <h2
          id={personalizedId}
          className={className ? className : ''}
          style={style}
        >
          {text}
          {children}
        </h2>
      );
    case 'subtitle':
      return (
        <h3
          id={personalizedId}
          onClick={onClick}
          className={className ? className : ''}
          style={style}
        >
          {text}
          {children}
        </h3>
      );
    case 'paragraph':
      return (
        <p
          id={personalizedId}
          className={className ? className : ''}
          style={style}
          onClick={() => onClick && onClick()}
        >
          {text}
          {children}
        </p>
      );
    case 'field-gray-light':
      return (
        <span
          id={personalizedId}
          className={`${className ? className : ''} ${type}`}
          style={style}
          onClick={() => onClick && onClick()}
        >
          {text}
          {children}
        </span>
      );
    default:
      return (
        <span
          id={personalizedId}
          className={`${className ? className : ''} ${type}`}
          style={style}
        >
          {text}
          {children}
        </span>
      );
  }
};

Typography.propTypes = {
  type: PropTypes.oneOf([
    'superTitle-italic', 'superTitle', 'title', 'subtitle', 'paragraph', 'span', 'field', 'value', 'field-gray',
    'field-gray-light', 'field-dark-gray', 'title-dark', 'field-title-dark',
  ]).isRequired,
  personalizedId: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.any,
  children: PropTypes.any,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

Typography.defaultProps = {
  personalizedId: null,
  className: null,
  children: null,
  text: null,
  style: {},
  onClick: null,
};

export default Typography;
