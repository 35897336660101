import masterCardImage from '../assets/images/typesCard/MasterCard.png';
import americanExpress from '../assets/images/typesCard/AmericaExpress.png';
import noCard from '../assets/images/typesCard/Franciquicas.png';
import visa from '../assets/images/typesCard/Visa.png';

const getCreditCardsType = (cardNumber) => {
  if (/^(4026|417500|4508|4844|491(3|7))/.test(cardNumber)) return { name: 'Visa Electron', image: visa };

  if (/^4/.test(cardNumber)) return { name: 'Visa', image: visa };

  if (/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/.test(cardNumber)) return { name: 'Diners Club', image: noCard };

  if (/^5[1-5][0-9]{5,}$/.test(cardNumber)) return { name: 'MasterCard', image: masterCardImage };

  if (/^36/.test(cardNumber)) return { name: 'Diners Club International', image: noCard };

  if (/^3[47][0-9]{5,}$/.test(cardNumber)) return { name: 'American Express', image: americanExpress };

  return null;
};

export default getCreditCardsType;
