/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Typography from '../typography';

import './datePickerComponent.scss';

const DatePickerComponent = ({
  dateInitSelected,
  handleInitSelected,
  dateEndSelected,
  handleEndSelected,
  className,
  classItems,
}) => (
  <div className={`date-picker__content ${className}`}>
    <div className={`date-picker__content-items ${classItems}`}>
      <Typography type="field-dark-gray" text="Desde" className="value" />
      <div className="datepicker-content">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            openTo="day"
            mask="__/__/____"
            inputFormat="dd/MM/yyyy"
            views={['year', 'month', 'day']}
            value={dateInitSelected}
            onChange={handleInitSelected}
            renderInput={(params) => (
              <TextField
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
    <div className="date-picker__content-items">
      <Typography type="field-dark-gray" text="Hasta" className="value" />
      <div className="datepicker-content">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            openTo="day"
            inputFormat="dd/MM/yyyy"
            mask="__/__/____"
            views={['year', 'month', 'day']}
            value={dateEndSelected}
            onChange={handleEndSelected}
            renderInput={(params) => <TextField {...params} />}
            minDate={dateInitSelected}
            disabled={!dateInitSelected}
          />
        </LocalizationProvider>
      </div>
    </div>
  </div>
);
DatePickerComponent.propTypes = {
  className: PropTypes.string,
  classItems: PropTypes.string,
  onClickModal: PropTypes.func.isRequired,
  dateInitSelected: PropTypes.string.isRequired,
  handleInitSelected: PropTypes.func.isRequired,
  dateEndSelected: PropTypes.string.isRequired,
  handleEndSelected: PropTypes.func.isRequired,

};

DatePickerComponent.defaultProps = {
  className: null,
  classItems: null,
};

export default DatePickerComponent;
