import React from 'react';
import PropTypes from 'prop-types';
import { useValidationCountry } from '../../../../../utils/validationCountry';

import Button from '../../../../../components/button';
import Checkbox from '../../../../../components/checkBox';
import ImageEmpyState from '../../../../../components/imagesEmptyState';

import NoShipping from '../../../../../assets/images/sin_envios.png';

import './desktopTable.scss';
import ItemListDesktop from './itemListDesktop';

const DesktopTable = ({
  shippingList, findAllIntoShippingSelecteds, loadingShippings,
  shippingSelecteds, handleChangeShippingSelected, downloadShippingGuides,
  handleChangeShippingSelecteds, goToQuoteShipping, deliveryCompanies,
  addOrRemoveAllShippingsToSelecteds,
}) => {
  const isCountryMex = useValidationCountry('CO');
  return (
    <>
      {loadingShippings && (
        <div className="info-data">
          <div className="preloader" />
        </div>
      )}

      {shippingList.length <= 0 && !loadingShippings && (
        <div className="image-empty-state">
          <ImageEmpyState
            uri={NoShipping}
            title="Aún no has hecho tu primer envío"
            description="Aquí verás lo envíos que realices"
          />
          <Button
            text="Realiza tu primer envío"
            type={isCountryMex ? 'primary-mx' : 'primary'}
            onClick={() => goToQuoteShipping()}
            className="buttonEmpyState"
          />
        </div>
      )}

      {shippingList.length > 0 && !loadingShippings && (
        <div>
          <div className="history_row none_curso">
            <div className={`desktop-table__shipping-history ${isCountryMex ? 'mx' : ''}`}>
              <div className="head-cell__shipping-history ">
                <Checkbox
                  onClick={() => addOrRemoveAllShippingsToSelecteds()}
                  checked={findAllIntoShippingSelecteds()}
                  selectedColor="blue"
                />
              </div>
              <div className="head-cell__shipping-history two" />
              <div className="head-cell__shipping-history">Nº guía</div>
              <div className="head-cell__shipping-history">Código MP</div>
              <div className="head-cell__shipping-history">Origen - Destino</div>
              <div className="head-cell__shipping-history">Destinatario</div>
              <div className="head-cell__shipping-history">Estado</div>
              <div className="head-cell__shipping-history eight">Fecha de solicitud</div>
              { !isCountryMex && <div className="head-cell__shipping-history" /> }
              <div className="head-cell__shipping-history">Guía</div>
            </div>
          </div>
            {shippingList.map((shipping) => (
              <ItemListDesktop
                key={shipping._id}
                shipping={shipping}
                deliveryCompanies={deliveryCompanies}
                downloadShippingGuides={downloadShippingGuides}
                handleChangeShippingSelected={handleChangeShippingSelected}
                handleChangeShippingSelecteds={handleChangeShippingSelecteds}
                shippingSelecteds={shippingSelecteds}
                isCountryMex={isCountryMex}
              />
            ))}
        </div>
      )}
    </>
  );
};

DesktopTable.propTypes = {
  shippingList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shippingSelecteds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChangeShippingSelected: PropTypes.func.isRequired,
  findAllIntoShippingSelecteds: PropTypes.func.isRequired,
  loadingShippings: PropTypes.bool.isRequired,
  goToQuoteShipping: PropTypes.func.isRequired,
  downloadShippingGuides: PropTypes.func.isRequired,
  handleChangeShippingSelecteds: PropTypes.func.isRequired,
  deliveryCompanies: PropTypes.arrayOf(PropTypes.shape()),
  addOrRemoveAllShippingsToSelecteds: PropTypes.func.isRequired,
};

DesktopTable.defaultProps = {
  deliveryCompanies: [],
};

export default DesktopTable;
