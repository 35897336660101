import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Typography from '../../../../components/typography';
import Select from '../../../../components/select';
import Button from '../../../../components/button';
import ButtonHelp from '../../../../components/buttonHelp';
import { getCollectionPaymentsByUser, getShipmentPaidByUser } from './getCollectionData';
import {
  deliveryCompaniesSelectedId, deliveryCompaniesSelectedName, statesSelected, statesSelectedName,
} from './checkedData';

import {
  dataTable, options, dataState,
} from '../data';

import IconFilter from '../../../../assets/images/filter_list.svg';
import IconArrowBack from '../../../../assets/images/icon-arrow-back-color-primary.svg';
import IconInfoHelp from '../../../../assets/images/Icon-open-external-link.svg';
import IconInfoAgent from '../../../../assets/images/icon-contact-agent.svg';

import ModalFilters from '../modals/filters';
import SearchMobile from '../modals/searchMobile';
import OrderCollections from '../modals/orderCollections';

import NoTickets from './noTickets';
import TicketsList from '../ticketsList';
import { removeFilter, dataMethodFiltered, initialFalseCheck } from '../common';

import Swal from '../modals/errors';
import ShipmentsList from '../shipmentList';
import ActivFilters from '../../../../components/activFilters';
import queryFilters from '../common/queryFilters';

import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import getReportData from '../common/getReportData';
import '../myCollections.scss';
import InputFilter from './inputFilter';

const GetTickets = ({ deliveryCompanies, handleTicketDetail }) => {
  const navigate = useNavigate();

  const [modalFilter, setModalFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [keyNames, setKeyNames] = useState({});
  const [downloadModal, setDownloadModal] = useState(false);
  const [modalFilterMobile, setModalFilterMobile] = useState(false);
  const [showOrderCollections, setShowOrderCollections] = useState(false);
  const [optionSelected, setOptionSelected] = useState('guide');
  const [inputGuide, setInputGuide] = useState(null);
  const [inputMp, setInputMp] = useState(null);
  const [dataCollections, setDataCollections] = useState(dataTable.collectionReports);
  const [dataDeliveryCompany, setDataDeliveryCompany] = useState(deliveryCompanies);
  const [dataStatesReport, setDataStateReport] = useState(dataState);
  const [dataCollectionsFilter, setDataCollectionsFilter] = useState(dataCollections);
  const [filterDataDelivery, setFilterDataDelivery] = useState(dataDeliveryCompany);
  const [filterDataStates, setFilterDataStates] = useState(dataStatesReport);
  const [showTicketDetail] = useState(false);
  const [dateInitSelected, setDateInitSelected] = useState(null);
  const [dateEndSelected, setDateEndSelected] = useState(null);
  const [billingData, setBillingData] = useState(null);
  const [shipmentsData, setShipmentsData] = useState(null);
  const [lastItem, setLastItem] = useState(null);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const closeModalFilter = () => {
    setDownloadModal(false);
    setModalFilter(false);
  };

  const getDataWithFilters = async ({
    itemsPerPage = 10, latestItem = undefined, page = undefined,
  }) => {
    const query = {
      ...deliveryCompaniesSelectedId(dataDeliveryCompany).length > 0 && {
        deliveryCompanies: deliveryCompaniesSelectedId(dataDeliveryCompany),
      },
      ...dateInitSelected && { startDate: dateInitSelected },
      ...dateEndSelected && { endDate: dateEndSelected },
      ...inputGuide && { guideNumber: inputGuide },
      ...inputMp && { code: Number.parseFloat(inputMp) },
      ...statesSelected(dataStatesReport).length > 0
      && { states: statesSelected(dataStatesReport) },
    };

    const data = await getCollectionPaymentsByUser(itemsPerPage, latestItem, page, query);

    if (data.tickets.length !== 0) setBillingData(data.tickets);
  };

  const getShipmentsWithFilters = async () => {
    const query = queryFilters(dateInitSelected,
      dateEndSelected,
      deliveryCompaniesSelectedId,
      dataDeliveryCompany,
      statesSelected,
      dataStatesReport,
      lastItem);
    const showError = () => Swal({ text: 'No se encontraron datos', iconType: 'warning' });
    const shipments = await getShipmentPaidByUser(query, showError);
    if (shipments?.length === 0) return setFilters({});

    if (downloadModal) {
      closeModalFilter();
      return getReportData({ ...query, itemsQuantity: 30 });
    }
    if (shipments?.length > 0) {
      const companies = deliveryCompaniesSelectedName(dataDeliveryCompany);
      let companiesName = {};
      companies.forEach((name) => {
        companiesName = {
          ...companiesName,
          [name]: name,
        };
      });

      const status = statesSelectedName(dataStatesReport);
      let statusName = {};
      status.forEach((name) => {
        statusName = {
          ...statusName,
          [name]: name,
        };
      });

      const tempFilters = {
        ...companiesName,
        ...dateInitSelected && { 'fecha Inicial': new Date(query.paidDate?.init).toLocaleDateString('es') },
        ...dateEndSelected && { 'fecha Final': new Date(query.paidDate?.end).toLocaleDateString('es') },
        ...inputMp && { 'código MP': query.code },
        ...inputGuide && { Guía: query.guideNumber },
        ...statusName,
      };

      const tempKeyNames = companies.map((company) => ({ value: company }))
        .concat(status.map((state) => ({ value: state })));

      setKeyNames(tempKeyNames);
      setFilters(tempFilters);

      closeModalFilter();

      const lastItemInQuery = lastItem
        ? shipmentsData[shipmentsData.length - 1]?._id === shipments[shipments.length - 1]?._id
        : null;

      if (!lastItemInQuery) {
        const completeShipments = lastItem ? shipmentsData.concat(shipments) : shipments;
        setShipmentsData(completeShipments);
      }
    }
    return null;
  };

  const removeAllFilters = () => {
    removeFilter(dataDeliveryCompany, setDataDeliveryCompany);
    removeFilter(dataStatesReport, setDataStateReport);
    setDateEndSelected(null);
    setDateInitSelected(null);
    setLastItem(null);
  };

  useEffect(() => {
    dataMethodFiltered(filterDataDelivery, setFilterDataDelivery, '_id');
    dataMethodFiltered(filterDataStates, setFilterDataStates, 'state');
    dataMethodFiltered(dataCollections, setDataCollectionsFilter, 'numberOfGuides');

    if (Object.keys(filters).length <= 0) {
      getDataWithFilters({});
      initialFalseCheck(deliveryCompanies, setDataDeliveryCompany);
      initialFalseCheck(dataTable.collectionReports, setDataCollections);
      initialFalseCheck(dataState, setDataStateReport);
    }
  }, []);

  useEffect(() => {
    if (showTicketDetail) handleTicketDetail(billingData);
  }, [billingData]);

  useEffect(() => {
    if (Object.keys(filters)?.length <= 0) {
      setShipmentsData(null);
      setDataDeliveryCompany(deliveryCompanies);
      setDataStateReport(dataState);
      setDateInitSelected(null);
      setDateEndSelected(null);
      setInputGuide(null);
      setInputMp(null);
      removeAllFilters();
    }
  }, [filters]);

  useEffect(() => {
    if (Object.keys(filters).length > 0 && !modalFilter) {
      getShipmentsWithFilters();
    }
  }, [dataDeliveryCompany, dataStatesReport]);

  useEffect(() => {
    if (lastItem) getShipmentsWithFilters();
  }, [lastItem]);

  const selectLastItem = () => {
    const latestItem = shipmentsData[shipmentsData.length - 1];
    setLastItem(latestItem._id);
  };

  const removeOneFilter = (newFilters) => {
    const keyFilters = Object.keys(newFilters);
    const newCheckedCompanies = dataDeliveryCompany.map((company) => ({
      ...company,
      checked: !!keyFilters.find((key) => key.toUpperCase() === company.name.toUpperCase()),
    }));

    const newStates = dataStatesReport.map((state) => ({
      ...state,
      checked: !!keyFilters.find((key) => key.toUpperCase() === state.state.toUpperCase()),
    }));

    setLastItem(null);
    setFilters(newFilters);
    setDataDeliveryCompany(newCheckedCompanies);
    setDataStateReport(newStates);
  };

  const arrowBackProcess = () => {
    if (shipmentsData?.length > 0) setShipmentsData(null);
    if (!shipmentsData || shipmentsData?.length <= 0) navigate(-1);
  };

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div id="my-collections">
      <div className="top">
        <img src={IconArrowBack} alt="icon" className="arrow-back-mobile" onClick={() => arrowBackProcess()} />
        <div className="left">
          <img src={IconArrowBack} alt="icon" className="arrow-back" onClick={() => arrowBackProcess()} />
          <Typography type="superTitle-italic" text="Tus recaudos" className="title" />
        </div>
        <div className="right-mobile">
          <img
            src={IconFilter}
            alt="icon-search"
            onClick={() => setModalFilterMobile(true)}
          />
        </div>
      </div>
      <div className="line" />
      <div className="content">
        <div className="buttons-filter">
          <div className="buttons-filter__position">
            <div className="select-content">
              <Select
                value={optionSelected}
                options={options}
                onChange={setOptionSelected}
                className="select-button"
                selectColor="accent"
                showLabel
              />
              <InputFilter
                item={optionSelected}
                handleTicketDetail={handleTicketDetail}
                setInputGuide={setInputGuide}
                setInputMp={setInputMp}
                inputGuide={inputGuide}
                inputMp={inputMp}
              />
            </div>
            <div className="filter-content">
              <Button
                iconText={(
                  <div className="filter-content__title">
                    <p className="text">Filtrar</p>
                    <img src={IconFilter} alt="icon-filter" />
                  </div>
                )}
                type="tertiary"
                onClick={() => setModalFilter(true)}
                className="filter-content__button"
              />
            </div>
          </div>
        </div>
        <div className="my-collections-information">
          <div className="my-collections-information__primary">
            {!billingData && (
              <NoTickets />
            )}
            {billingData && !shipmentsData && (
              <TicketsList
                billingData={billingData}
                setBillingData={setBillingData}
                handleChangeTicketDetail={handleTicketDetail}
              />
            )}
            {shipmentsData && (
              <>
                <ActivFilters
                  filters={filters}
                  handleChangeFilter={(newFilters) => removeOneFilter(newFilters)}
                  show={Object.keys(filters).length > 0}
                  keyNames={keyNames}
                />
                <ShipmentsList
                  deliveryCompanies={deliveryCompanies}
                  shipmentsData={shipmentsData}
                  downloadData={() => setModalFilter(true)}
                  customShipmentListClass="less-height"
                  showMore={selectLastItem}
                />
              </>
            )}
          </div>
          {billingData && (
            <div className="my-collections-information__secondary">
              <Button
                text={dataCollectionsFilter?.length > 0 ? `Descargar recaudos (${dataCollectionsFilter?.length})` : 'Descargar recaudos'}
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={() => setDownloadModal(true)}
                className="button-footer"
              />
              {!isMobileDevice && (
                <ButtonHelp
                  text="Preguntas frecuentes"
                  imgRigth={IconInfoAgent}
                  imgLeft={IconInfoHelp}
                  linkHelp="https://www.mipaquete.com/soluciones-ecommerce/envios-pago-contraentrega#preguntas-frecuentes"
                  linkIconHelp="https://mipaquete.atlassian.net/servicedesk/customer/portal/8/group/48"
                />
              )}
            </div>
          )}
        </div>
      </div>
      {(modalFilter || downloadModal) && (
        <ModalFilters
          dataDeliveryCompany={dataDeliveryCompany}
          dataStatesReport={dataStatesReport}
          dateEndSelected={dateEndSelected}
          dateInitSelected={dateInitSelected}
          modalFilter={modalFilter}
          searchByfilters={() => getShipmentsWithFilters()}
          hideDatePicker={false}
          removeAllFilters={() => removeAllFilters()}
          setDataDeliveryCompany={setDataDeliveryCompany}
          setDataStateReport={setDataStateReport}
          setDateEndSelected={setDateEndSelected}
          setDateInitSelected={setDateInitSelected}
          setDownloadModal={setDownloadModal}
          setModalFilter={setModalFilter}
          setModalFilterMobile={setModalFilterMobile}
        />
      )}
      {modalFilterMobile && (
        <SearchMobile
          inputFilter={(dataSelected) => (
            <InputFilter
              item={dataSelected}
              handleTicketDetail={handleTicketDetail}
              setInputGuide={setInputGuide}
              setInputMp={setInputMp}
              inputGuide={inputGuide}
              inputMp={inputMp}
            />
          )}
          optionSelected={optionSelected}
          setOptionSelected={setOptionSelected}
          setModalFilter={setModalFilter}
          setModalFilterMobile={setModalFilterMobile}
          setShowOrderCollections={setShowOrderCollections}
        />
      )}
      {showOrderCollections && (
        <OrderCollections
          setModalFilterMobile={setModalFilterMobile}
          setShowOrderCollections={setShowOrderCollections}
        />
      )}
    </div>
  );
};

GetTickets.propTypes = {
  deliveryCompanies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleTicketDetail: PropTypes.func.isRequired,
};

export default GetTickets;
