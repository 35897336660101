import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import GetTickets from './getTickets';
import TicketDetail from './ticketDetail';

import { showDependingOnBusinessFlag } from '../../../utils/businessFlag';
import { detailTypes } from './common/enums';
import NextCollectionDetail from './nextCollectionDetail';
import ShipmentDetail from './shipmentDetail';

const MyCollections = () => {
  const { deliveryCompanies } = useSelector((state) => state.globalData);
  const businessFlags = useSelector((state) => state.globalData.businessFlags);

  const [ticketDetail, setTicketDetail] = useState(null);

  const handleStepSelected = (detailType, data) => {
    const detailTypeSelected = detailType || detailTypes.selectedTicket;
    setTicketDetail({
      detailType: detailTypeSelected,
      ...data && { data },
    });
  };

  return (
    showDependingOnBusinessFlag({ businessFlags, section: 'tusRecaudos' })
    && (
    <>
      {!ticketDetail?.data && (
      <GetTickets
        deliveryCompanies={deliveryCompanies}
        handleTicketDetail={(data, detailType) => handleStepSelected(detailType, data)}
      />
      )}
      {ticketDetail?.detailType === detailTypes.selectedTicket && (
      <TicketDetail
        deliveryCompanies={deliveryCompanies}
        ticket={ticketDetail.data}
        handleTicketDetail={(data, detailType) => handleStepSelected(detailType, data)}
      />
      )}
      {ticketDetail?.detailType === detailTypes.currentTicket && (
        <NextCollectionDetail
          ticket={ticketDetail.data}
          deliveryCompanies={deliveryCompanies}
          handleTicketDetail={setTicketDetail}
        />
      )}
      {ticketDetail?.detailType === detailTypes.detailCurrentShipment && (
        <ShipmentDetail
          guideNumber={ticketDetail?.data?.guideNumber}
          deliveryCompanies={deliveryCompanies}
          handleTicketDetail={setTicketDetail}
        />
      )}
    </>
    )
  );
};

export default MyCollections;
