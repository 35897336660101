/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { React, useState } from 'react';
import Modal from '../modal';
import Button from '../button';
import Typography from '../typography';

import IconClose from '../../assets/images/close.svg';
import IconArrowBack from '../../assets/images/icon-arrow-back-color-gray-dark.svg';
import arrowNext from '../../assets/images/icon-arrow-side-color-gray.svg';

import { useValidationCountry, useFetchIpData } from '../../utils/validationCountry';

import './screenSteps.scss';

const ScreenModal = ({
  currentStep, next, back, step, localStorage, isMobileDevice,
}) => {
  const [close, setClose] = useState(false);
  const numberPenultimate = 5;
  const nextFunction = () => {
    next();
  };

  const backFunction = () => {
    back();
  };

  const showModalFunction = () => {
    localStorage();
    setClose(!close);
  };

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div>
      {!close && (
        <Modal
          className="desktop-modal-screen"
          closeBackground={showModalFunction}
        >
          <div className="header-close">
            {isMobileDevice ? <img src={IconArrowBack} className="back" alt="Atrás" onClick={backFunction} /> : <div />}
            <Typography
              text={currentStep.title}
              type="superTitle-italic"
            />
            <img src={IconClose} className="close" alt="Cerrar" onClick={showModalFunction} />
          </div>
          <Typography
            type="field-gray-light"
            className="paragraphModal"
          >
            {currentStep.specialText ? (
              <>
                <b>{currentStep.specialText}</b>
                {' '}
                {currentStep.description}
              </>
            ) : (
              currentStep.description
            )}
          </Typography>
          <div className="container-gif-and-drop-rigth">
            {!isMobileDevice && <img src={arrowNext} alt="atras" onClick={backFunction} />}
            <div className="columnGif">
              <div className={currentStep.className} />
            </div>
            {!isMobileDevice && <img src={arrowNext} className="next" alt="adelante" onClick={nextFunction} />}
          </div>
          <div className="points">
            <div className={step === 0 ? 'currentStep' : ''} />
            <div className={step === 1 ? 'currentStep' : ''} />
            <div className={step === 2 ? 'currentStep' : ''} />
            <div className={step === 3 ? 'currentStep' : ''} />
            <div className={step === 4 ? 'currentStep' : ''} />
            <div className={step === 5 ? 'currentStep' : ''} />
            <div className={step === 6 ? 'currentStep' : ''} />
          </div>
          <div className="finishOrContinueButtons">
            {step >= numberPenultimate + 1 ? (
              <Button
                text="Comenzar"
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={showModalFunction}
              />
            ) : (
              isMobileDevice && (
                <Button
                  text="Continuar"
                  type={isCountryValid ? 'primary-mx' : 'primary'}
                  onClick={() => nextFunction()}
                  className="btnNext"
                />
              )
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

ScreenModal.propTypes = {
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentStep: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  localStorage: PropTypes.func,
  isMobileDevice: PropTypes.bool.isRequired,
};

ScreenModal.defaultProps = {
  localStorage: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(ScreenModal);
