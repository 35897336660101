/* eslint-disable default-param-last */
import { CLOSE_MENU, SHOW_MENU } from '../actions/type.actions';

const initialState = {
  show: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MENU: {
      return {
        show: true,
      };
    }
    case CLOSE_MENU: {
      return {
        show: false,
      };
    }
    default: {
      return state;
    }
  }
};
