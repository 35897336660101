/* eslint-disable no-unused-expressions */
/* eslint-disable quote-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { validationSteps } from '../validate';
import { getDataList, paramsLocation } from '../../../../utils/paramsLocations';
import { stepOneValidation } from '../../../../utils/validation/validationsQuoteShipping';
import { findObjectInArray } from '../../../../utils/filters';
import { updateUserService } from '../../../../services/user';
import { useFindLocations, useLocationService } from '../../../../hooks/useShippingSteps';
import { UpdateUserAction } from '../../../../store/actions/auth.actions';

import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Typography from '../../../../components/typography';
import SuccessModal from '../../../../components/successModal';

import colombiaFlag from '../../../../assets/images/colombia.svg';
import mexicoFlag from '../../../../assets/images/mexico-square.svg';

import './editProfile.scss';

const EditProfile = ({ user, isMobileDevice }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countryCode = useSelector((state) => state.country);
  const locations = useSelector((state) => state.globalData.locations || []);
  let timer;
  const [formValue, setFormValue] = useState({
    businessName: user.businessName ?? '',
    name: `${user.name || ''} ${user.surname || ''}`,
    locationName: user.locationName || '',
    address: user.address || '',
    cellPhone: user.cellPhone || user.phone,
  });
  const [errorList, setErrorList] = useState([]);
  const [modalContent, setModalContent] = useState(false);
  const [modalContentSuccess, setModalContentSuccess] = useState(false);
  const [annotationError, setAnnotationError] = useState('');

  const { listLocations, getLocationService } = useLocationService();
  const { locationCode } = useFindLocations(
    countryCode,
    formValue.locationName,
    countryCode === '170' ? locations : listLocations,
  );

  const handleSaveChanges = async () => {
    try {
      const updatedUser = await updateUserService(user._id, {
        cellPhone: formValue.cellPhone,
        address: formValue.address,
        locationName: formValue.locationName,
        locationCode: countryCode === '170' ? locationCode : formValue.locationName?.split('-')[0],
        name: formValue.name?.split(' ')[0],
        surname: formValue.name?.split(' ')[1],
        businessName: formValue.businessName,
      });
      dispatch(UpdateUserAction(updatedUser));
      setModalContentSuccess(true);
    } catch (error) {
      setModalContent(true);
    }
  };

  const showList = () => {
    if (countryCode === '484') {
      return getDataList(listLocations, 'locationName', countryCode).firstList;
    }
    return getDataList(locations, 'locationName', countryCode);
  };

  const filtersLocations = async (value) => {
    if (countryCode === '484') {
      await paramsLocation('locationName', value, countryCode, getLocationService);
    }
  };

  const handleModalClose = () => {
    setModalContentSuccess(false);
    navigate('/perfil/informacion-de-contacto');
  };

  const flag = {
    '170': colombiaFlag,
    '484': mexicoFlag,
  };

  const title = () => (
    <div>
      Mantén
      {' '}
      <span>actualizados todos tus datos</span>
      {' '}
      , para entregarte lo mejor de nuestro servicio.
    </div>
  );

  const handleInputChange = (key, value) => {
    setFormValue({
      ...formValue,
      [key]: value,
    });
  };

  useEffect(() => {
    const [code] = formValue.locationName.split('-');
    handleInputChange('locationCode', code);
  }, [formValue.locationName]);

  return (
    <div
      className={`content-edit-contact-info ${isMobileDevice && 'edit-mobile'}`}
    >
      <div className="content-with-button">
        <Typography className="main-title-edit-contact" text={title()} />
        <div className="input-content">
          <div className="content-edit-left">
            <div className="inputContainer-contact">
              <Input
                label="Nombre comercial en tu guía"
                value={formValue.businessName}
                onChange={(value) => handleInputChange('businessName', value)}
                annotationError={
                  formValue.businessName ? null : 'Ingresa tu nombre comercial'
                }
                annotation="Es el nombre de tu tienda o comercio que aparecerá como remitente en las guías."
              />
            </div>
            <div className="inputContainer">
              <Input
                label="Nombre completo"
                value={formValue.name}
                onChange={(value) => handleInputChange('name', value)}
                annotationError={formValue.name ? null : 'Ingresa tu nombre completo'}
              />
            </div>
          </div>
          <div className="content-edit-right">
            <div className="inputContainer-contact">
              <Input
                label="Ciudad"
                placeholder={countryCode === '170' ? 'MEDELLÍN-ANTIOQUIA' : '06700-ROMA-MÉXICO'}
                value={formValue.locationName}
                dataList={showList()}
                onChange={async (value) => {
                  handleInputChange('locationName', value);
                  clearTimeout(timer);
                  if (value.trim() !== '') {
                    timer = setTimeout(() => {
                      filtersLocations(value);
                    }, 600);
                  }
                  if (findObjectInArray('locationName', errorList, 'name')) {
                    stepOneValidation(
                      errorList,
                      setErrorList,
                      'locationName',
                      value,
                      countryCode === '484' ? listLocations : locations,
                      countryCode,
                    );
                  }
                  !value
                    ? setAnnotationError('Ingresa una ciudad válida*')
                    : setAnnotationError(null);
                }}
                onClick={(value) => {
                  !value
                    ? setAnnotationError('Ingresa una ciudad válida*')
                    : setAnnotationError(null);
                }}
                annotationError={annotationError}
              />
            </div>
            <div className="inputContainer">
              <Input
                label="Dirección de recogida predeterminada"
                value={formValue.address}
                onChange={(value) => handleInputChange('address', value)}
                onBlur={() => validationSteps({
                  errorList,
                  setErrorList,
                  lengthError: true,
                  value: formValue.address,
                  personalized: 'Ingresa una dirección detallada',
                })}
                annotationError={
                  findObjectInArray('lengthError', errorList, 'name')
                    ? findObjectInArray('lengthError', errorList, 'name').error
                    : null
                }
              />
            </div>
            <div className="inputContainer">
              <Input
                type="number-text"
                iconLeft={flag[user.countryCode]}
                label="Celular"
                value={formValue.cellPhone}
                onChange={(value) => {
                  handleInputChange('cellPhone', value);
                  if (findObjectInArray('cellPhone', errorList, 'name')) {
                    validationSteps({
                      errorList,
                      setErrorList,
                      cellPhone: value,
                    });
                  }
                }}
                onBlur={() => validationSteps({
                  errorList, setErrorList, cellPhone: formValue.cellPhone,
                })}
                annotationError={
                  findObjectInArray('cellPhone', errorList, 'name')
                    ? findObjectInArray('cellPhone', errorList, 'name').error
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="content-button">
        <Button
          onClick={handleSaveChanges}
          type={user.countryCode === '484' ? 'quaternary' : 'primary'}
          text="Guardar cambios"
        />
      </div>
      {modalContent && (
        <SuccessModal
          text="Hubo un error al guardar los cambios"
          onClose={() => setModalContent(false)}
        />
      )}
      {modalContentSuccess && (
        <SuccessModal
          text="Los cambios se han guardado exitosamente"
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

EditProfile.propTypes = {
  user: PropTypes.shape.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

EditProfile.defaultProps = {

};

export default EditProfile;
