import axios from 'axios';
import {
  NotificationsMicroserviceUrl, customerKeyNotifications,
} from '../constants';

export const sendEmailToResetPasswordService = (
  email,
) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyNotifications;
  axios.post(`${NotificationsMicroserviceUrl}/restpassword`, {
    email,
  })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      reject(e);
    });
});

export const resetRegisterEmail = (userId) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyNotifications;
  axios.post(`${NotificationsMicroserviceUrl}/email`, {
    userId, notificationType: 'register',
  })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      reject(e);
    });
});

export const createValidationCodes = (process) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyNotifications;
  axios.post(`${NotificationsMicroserviceUrl}/createValidationCodes`, process)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      reject(e);
    });
});

export const validateCodes = (process) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyNotifications;
  axios.post(`${NotificationsMicroserviceUrl}/validateCodes`, process)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      reject(e);
    });
});
