/* eslint-disable no-param-reassign,max-len */
/* eslint-disable no-useless-escape */
import {
  currencyFractionDigits,
  currentCurrency,
  localeCurrency,
} from '../constants/general';

const countryLocaleCurrency = (code) => (localeCurrency[code]);

const validationStyleCurrency = (isStyle, user, countryCode) => {
  if (isStyle === 'currency') {
    return user?.currencyToPay ? user?.currencyToPay : currentCurrency[countryCode];
  }
  return '';
};
export const toCurrencyCop = (user, mount, countryCode = '170', isStyle = 'currency') => {
  const roundedAmount = Math.floor(parseFloat(mount));
  const formattedAmount = new Intl.NumberFormat(
    `es-${user?.currencyToPay ? localeCurrency[user?.currencyToPay] : countryLocaleCurrency(countryCode)}`,
    {
      style: isStyle,
      ...isStyle === 'currency' && { currency: user?.currencyToPay ? user?.currencyToPay : currentCurrency[countryCode] },
      minimumFractionDigits: currencyFractionDigits[user?.currencyToPay ? user?.currencyToPay : countryCode],
    }).format(parseFloat(roundedAmount));
  return `${formattedAmount} ${validationStyleCurrency(isStyle, user, countryCode)}`;
};

export const toCurrencyCopWithOutCOP = (user, mount, countryCode = '170', isStyle = 'currency') => {
  const roundedAmount = Math.floor(parseFloat(mount));
  const formattedAmount = new Intl.NumberFormat(
    `es-${user?.currencyToPay ? localeCurrency[user?.currencyToPay] : countryLocaleCurrency(countryCode)}`,
    {
      style: isStyle,
      ...isStyle === 'currency' && { currency: user?.currencyToPay ? user?.currencyToPay : currentCurrency[countryCode] },
      minimumFractionDigits: currencyFractionDigits[user?.currencyToPay ? user?.currencyToPay : countryCode],
    }).format(parseFloat(roundedAmount));
  return formattedAmount;
};

export const cashThousandPoint = (cash) => {
  const numeroRevertido = String(cash).split('').reverse().join('');
  const numeroConPuntos = numeroRevertido.replace(/(\d{3})/g, '$1.').replace(/\.$/, '');
  return numeroConPuntos.split('').reverse().join('');
};

export const toCurrencyUsd = (mount) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 1,
}).format(mount);

export const removeAccents = (value) => value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const replaceCharacter = (value) => value.replace(/,/g, '');

const regValidateEmail = /^(([^<>()[\]\\#*¨?.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regCellPhone = /^3\d{9}$/;

export const addThousandPoint = (value) => {
  if (value !== '' && value !== undefined) {
    const currentValue = value.toString().split('').reverse();
    let count = 0;
    const newValue = [];
    currentValue.forEach((item) => {
      if (item !== '.') {
        if (
          count === 3
        ) {
          newValue.push('.');
          newValue.push(item);
          count = 0;
        } else {
          newValue.push(item);
        }
        count += 1;
      }
    });
    return newValue.reverse().join('');
  }
  return 0;
};

export const addThousandPointCollection = (value) => {
  if (value !== '' && value !== undefined) {
    const formattedValue = parseFloat(value).toLocaleString('es-ES', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    });

    const lastIndex = formattedValue.lastIndexOf(',');
    return lastIndex !== -1 ? formattedValue.substring(0, lastIndex) : formattedValue;
  }
  return 0;
};

export const removeThousandPoint = (value) => {
  if (value !== '') {
    const currentValue = value.toString().split('');
    const newValue = [];
    currentValue.forEach((item) => {
      if (item !== '.') {
        newValue.push(item);
      }
    });
    return parseInt(newValue.join(''), 10);
  }
  return value;
};

export const toNumber = (valueToConvert) => {
  if (valueToConvert) {
    parseInt(valueToConvert, 10);
  }
  return null;
};

export const extractToSpecialCharacter = (string, character) => {
  if (typeof string === 'string') {
    return string.trim().split(character);
  }
  return [];
};

export const capitalizeWords = (string) => {
  if (string) {
    return string.toLowerCase()
      .trim()
      .split(' ')
      .map((v) => v && v[0].toUpperCase() + v.substr(1))
      .join(' ');
  }
  return null;
};

export const userName = (name, surname) => {
  let nameToDisplay;
  if (name && typeof name === 'string') nameToDisplay = name;
  if (surname && typeof surname === 'string') {
    nameToDisplay = nameToDisplay ? `${nameToDisplay.split(' ')[0]} ${surname.split(' ')[0]}` : `${surname}`;
  }
  return nameToDisplay ? capitalizeWords(nameToDisplay) : '';
};

const validateRFCFormat = (rfc) => Array.from(rfc).every((char) => /[A-Za-z0-9\s]/.test(char));

export const validationValue = (value, type, list) => {
  if (value) {
    let passwordLength = false;

    if (type === 'lengthError') {
      if (value.length === 0 || value === '') {
        return {
          correct: false,
        };
      }
      return {
        correct: true,
      };
    }

    if (type === 'destinationAddress') {
      if (value.length > 5) {
        return {
          value: value.trim(),
          correct: true,
        };
      }
      return {
        value,
        correct: false,
        error: 'Ingresa máximo 200 caracteres.',
      };
    }

    if (type === 'RFC') {
      const validRFC = validateRFCFormat(value);
      if (value.length < 12) {
        return {
          value: value.trim(),
          correct: false,
          error: 'Ingresa tu RFC completo',
        };
      }
      if (validRFC) {
        return {
          value: value.trim(),
          correct: true,
        };
      }
      return {
        value,
        correct: false,
        error: 'Ingresa tu RFC completo',
      };
    }

    if (type === 'postalCode') {
      const isValidPostalCode = /^\d+$/.test(value);
      if (!isValidPostalCode || value.length !== 5) {
        return {
          value,
          correct: false,
          error: 'Ingresa un código postal válido',
        };
      }
      return {
        value: value.trim(),
        correct: true,
      };
    }

    if (type === 'password') {
      value.trim()
        .split('')
        .map((v, index) => {
          if (index >= 8) passwordLength = true;
          return null;
        });
    }

    if (type === 'image') {
      try {
        const ext = value.substring(value.lastIndexOf('.') + 1);
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        if (ext) {
          if (allowedExtensions.find((e) => e === ext)) {
            return {
              value: value.trim(),
              correct: true,
            };
          }
        }
        return {
          value,
          correct: false,
        };
      } catch (e) {
        return {
          value,
          correct: false,
        };
      }
    }

    if (type === 'excel') {
      try {
        const ext = value.substring(value.lastIndexOf('.') + 1);
        const allowedExtensions = ['xlsx'];
        if (ext) {
          if (allowedExtensions.find((e) => e === ext)) {
            return {
              value: value.trim(),
              correct: true,
            };
          }
        }
        return {
          value,
          correct: false,
        };
      } catch (e) {
        return {
          value,
          correct: false,
        };
      }
    }

    if (type === 'email' || type === 'emailForBilling') {
      const validateEmail = regValidateEmail.test(value);
      if (validateEmail) return { value: value.trim(), correct: true };
      return {
        value,
        correct: false,
        error: 'Ingresa un correo electrónico válido.',
      };
    }

    if (type === 'cellPhone') {
      const validateCellPhone = regCellPhone.test(value);

      if (validateCellPhone && value.length === 10) {
        return {
          value: value.toString().trim(),
          correct: true,
        };
      }

      if (!validateCellPhone || value.length !== 10 || value[0] !== '3') {
        return {
          value: value.toString().trim(),
          correct: false,
          error: 'Ingrese un número de celular válido',
        };
      }

      return {
        value: value.toString().trim(),
        correct: false,
      };
    }

    if (type === 'identificationNumber') {
      if (value.length > 5) return { value: value.trim(), correct: true };
      return {
        value,
        correct: false,
      };
    }

    if (type === 'list' && list.length > 0) {
      const valueInList = list.find((data) => data === value);

      if (valueInList) {
        return {
          value: valueInList,
          correct: true,
        };
      }
      return {
        value,
        correct: false,
      };
    }

    if (type === 'accountBankNumber') {
      if (value.length >= 6) {
        return {
          value,
          correct: true,
        };
      }
      return {
        value,
        correct: false,
      };
    }

    if (value.trim().length > 0) {
      if (
        (type === 'password' && (!passwordLength))
        || (type === 'email' && !/^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-Z0-9]{2,4}$/.test(value.trim()))
        || (type === 'cellphone' && (value.trim().length !== 10 || value[0] !== '3'))
      ) {
        return {
          value,
          correct: false,
        };
      }
      return {
        value: value.trim(),
        correct: true,
      };
    }
  }

  return {
    value,
    correct: false,
  };
};

export const insertOrRemoveErrorFromList = (
  errorList, errorName, errorText, action,
) => {
  const indexOfFind = errorList.map((element) => element.name).indexOf(errorName);
  if (action === 'insert') {
    if (indexOfFind < 0) {
      errorList.push({
        name: errorName,
        error: errorText,
      });
    } else {
      errorList[indexOfFind].name = errorName;
      errorList[indexOfFind].error = errorText;
    }
  } else if (action === 'remove') {
    if (indexOfFind !== -1) {
      errorList.splice(indexOfFind, 1);
    }
  }
};

export const formatDay = (date, format = 'DD-MM-YYY') => {
  let optionFormatDay;

  switch (format) {
    case 'DD-MM':
      optionFormatDay = {
        month: 'long',
        day: 'numeric',
      };
      break;
    default:
      optionFormatDay = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
      break;
  }
  return date.toLocaleDateString(undefined, optionFormatDay);
};

export const formatBytes = (bytes, decimals = 1) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const codeAndGuideNumber = (code) => {
  const regMPCode = /^MP\d{1,}$/;
  const regGuideCode = /^\d*$/;
  if (regMPCode.test(code)) {
    return {
      code,
      correct: true,
    };
  }
  if (regGuideCode.test(code)) {
    return {
      code,
      correct: true,
    };
  }
  return {
    code,
    correct: false,
  };
};

export const concatenateArray = (array, conjunction, finalPunctuationMark) => {
  const sentence = '';
  if (Array.isArray(array)) {
    if (array.length > 1) {
      return `${array.join('` ').replace(/` ([^`]*)$/, ` ${conjunction} $1`).replace('`', ',')}${finalPunctuationMark || ''}`;
    }
    if (array.length === 1) {
      return `${array[0]}${finalPunctuationMark || ''}`;
    }
  }
  return sentence;
};

export const hideAccountNumbers = (accountNumber) => (accountNumber ? JSON.stringify(accountNumber)?.replace(/\d(?=\d{4})/g, '*')?.replace(/"/g, '') : '');

export const bankNameFormat = (name) => {
  if (!name || name === '') return name;
  const onlyBankName = name.split('(');
  const number = name.match(/\$\d*.\d*/);
  if (number && number[0]) {
    return `${capitalizeWords(onlyBankName[0])} (${number[0].replace(/\)/, '')})`;
  }
  return `${capitalizeWords(onlyBankName[0])}`;
};
