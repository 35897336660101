import React from 'react';
import Prototype from 'prop-types';
import Typography from '../../../components/typography';
import UserAvatar from '../../../components/userAvatar';
import { capitalizeWords, toCurrencyCop } from '../../../utils/strings';
import PickupTag from '../../../components/pickupTag';
import IconArrowBack from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './sectionsDetails.scss';

const SenderDetail = ({
  senderName, senderAddress, senderLocation, pickup,
}) => (
  <div className="sender-shipping-details">
    <Typography type="subtitle" className="subtitle">Remitente</Typography>
    <div className="sender-data-container">
      <UserAvatar
        firstLetter={senderName && senderName?.charAt(0).toUpperCase()}
        size={40}
      />
      <div className="text-sender-data">
        <Typography type="paragraph" className="text-sender-detail">{capitalizeWords(senderName)}</Typography>
        <Typography type="paragraph" className="text-sender-detail">{senderLocation}</Typography>
        <Typography type="paragraph" className="text-sender-detail">{senderAddress}</Typography>
        <PickupTag pickup={pickup} />
      </div>
    </div>
  </div>
);

const RecipientDetail = ({
  recipientName,
  recipientAddress,
  recipientLocation,
  recipientEmail,
  recipientCellphone,
  recipientComment,
}) => (
  <div className="receiver-shipping-details">
    <Typography type="subtitle" className="subtitle">Destinatario</Typography>
    <div className="text-receiver-data">
      <Typography type="paragraph" className="text-receiver-detail">{capitalizeWords(recipientName)}</Typography>
      <Typography type="paragraph" className="text-receiver-detail">{recipientEmail}</Typography>
      <Typography type="paragraph" className="text-receiver-detail">{recipientCellphone}</Typography>
      <Typography type="paragraph" className="text-receiver-detail">{recipientLocation}</Typography>
      <Typography type="paragraph" className="text-receiver-detail">{recipientAddress}</Typography>
      <Typography type="paragraph" className="text-receiver-detail">{recipientComment}</Typography>
    </div>
  </div>
);

const HeaderDetail = ({ code, guideNumber, back }) => (
  <div className="header-shipping-details">
    <div
      key="uniqueKey"
      onClick={() => back()}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          back();
        }
      }}
      className="header-back-arrow"
      role="button"
      tabIndex={0}
      aria-label="Back"
    >
      <img className="back-arrow" src={IconArrowBack} alt="" />
    </div>
    <div className="header-guide-code-container">
      <span>
        {`MP${code} -`}
      </span>
      <span>
        Guía
        {' '}
        <span className="color-ligth">{guideNumber}</span>
      </span>
    </div>
  </div>
);

const ProductDetail = ({
  description,
  width,
  height,
  large,
  realWeight,
  weight,
  declaredValue,
  quantity,
  user,
}) => (
  <div className="product-shipping-details">
    <Typography type="subtitle" className="subtitle">Producto</Typography>
    <div className="text-product-data">
      <div className="container-text-product-detail">
        <Typography type="paragraph" className="text-product-detail">Tipo de producto:</Typography>
        <Typography type="paragraph" className="text-product-detail">{description}</Typography>
      </div>
      <div className="container-text-product-detail">
        <Typography type="paragraph" className="text-product-detail">Ancho:</Typography>
        <Typography type="paragraph" className="text-product-detail">
          {width}
          {' '}
          cm
        </Typography>
      </div>
      <div className="container-text-product-detail">
        <Typography type="paragraph" className="text-product-detail">Alto:</Typography>
        <Typography type="paragraph" className="text-product-detail">
          {height}
          {' '}
          cm
        </Typography>
      </div>
      <div className="container-text-product-detail">
        <Typography type="paragraph" className="text-product-detail">Largo:</Typography>
        <Typography type="paragraph" className="text-product-detail">
          {large}
          {' '}
          cm
        </Typography>
      </div>
      <div className="container-text-product-detail">
        <Typography type="paragraph" className="text-product-detail">Peso:</Typography>
        <Typography type="paragraph" className="text-product-detail">
          {realWeight}
          {' '}
          Kg
        </Typography>
      </div>
      <br />
      <div className="container-text-product-detail">
        <Typography type="paragraph" className="text-product-detail">Peso volumen:</Typography>
        <Typography type="paragraph" className="text-product-detail">
          {weight}
          {' '}
          Kg
        </Typography>
      </div>
      <br />
      <div className="container-text-product-detail">
        <Typography type="paragraph" className="text-product-detail">Valor declarado por unidad:</Typography>
        <Typography type="paragraph" className="text-product-detail">{toCurrencyCop(user, declaredValue)}</Typography>
      </div>
      <div className="container-text-product-detail">
        <Typography type="paragraph" className="text-product-detail">Cantidad de unidades:</Typography>
        <Typography type="paragraph" className="text-product-detail">{quantity}</Typography>
      </div>

    </div>
  </div>
);

const DeliveryCompanyDetails = ({
  imageUrl, deliveryCompanyName, price, observations, user,
}) => (
  <div className="delivery-company-details">
    <Typography type="subtitle" className="subtitle">
      {user.countryCode === '484' ? 'Paquetería' : 'Transportadora'}
    </Typography>
    <div className="conetent-image-delivary-company">
      <img src={imageUrl} alt="deliveryCompany" />
      <Typography type="subtitle" className="delivery-company-name">{deliveryCompanyName}</Typography>
      <Typography type="subtitle" className="content-curency-pay">{toCurrencyCop(user, price)}</Typography>
    </div>
    <Typography type="paragraph" className="text-observations">{observations}</Typography>
  </div>
);

ProductDetail.propTypes = {
  description: Prototype.string.isRequired,
  width: Prototype.string.isRequired,
  height: Prototype.string.isRequired,
  large: Prototype.string.isRequired,
  realWeight: Prototype.string.isRequired,
  weight: Prototype.string.isRequired,
  declaredValue: Prototype.string.isRequired,
  quantity: Prototype.string.isRequired,
  user: Prototype.shape({}).isRequired,
};

SenderDetail.propTypes = {
  senderName: Prototype.string.isRequired,
  senderAddress: Prototype.string.isRequired,
  senderLocation: Prototype.string.isRequired,
  pickup: Prototype.bool.isRequired,
};

RecipientDetail.propTypes = {
  recipientName: Prototype.string.isRequired,
  recipientAddress: Prototype.string.isRequired,
  recipientLocation: Prototype.string.isRequired,
  recipientEmail: Prototype.string.isRequired,
  recipientCellphone: Prototype.string.isRequired,
  recipientComment: Prototype.string.isRequired,
};

HeaderDetail.propTypes = {
  guideNumber: Prototype.string.isRequired,
  code: Prototype.number.isRequired,
  back: Prototype.func.isRequired,
};

ProductDetail.propTypes = {
  description: Prototype.string.isRequired,
  width: Prototype.string.isRequired,
  height: Prototype.string.isRequired,
  large: Prototype.string.isRequired,
  realWeight: Prototype.string.isRequired,
  weight: Prototype.string.isRequired,
  declaredValue: Prototype.string.isRequired,
  quantity: Prototype.string.isRequired,
};

DeliveryCompanyDetails.propTypes = {
  imageUrl: Prototype.string.isRequired,
  deliveryCompanyName: Prototype.string.isRequired,
  price: Prototype.number.isRequired,
  observations: Prototype.string.isRequired,
  user: Prototype.shape({
    countryCode: Prototype.string.isRequired,
  }).isRequired,
};

export {
  SenderDetail,
  RecipientDetail,
  HeaderDetail,
  ProductDetail,
  DeliveryCompanyDetails,
};
