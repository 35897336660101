import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';

import RechargeBalance from './rechargeBalance';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './rechargeBalanceScreen.scss';

const RechargeBalancegScreen = ({ setShowRecharBalanceScreen }) => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const [className, setclassName] = useState();

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <RechargeBalance
          setclassName={setclassName}
          setShowRecharBalanceScreen={setShowRecharBalanceScreen}
        />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <div
          className={`container-recharge-balance__section-desktop ${className || ''}`}
        >
          <RechargeBalance
            setclassName={setclassName}
            setShowRecharBalanceScreen={setShowRecharBalanceScreen}
          />
        </div>
      </ContainerSectionDesktop>
    )
  );
};

RechargeBalancegScreen.propTypes = {
  setShowRecharBalanceScreen: PropTypes.func,
};

RechargeBalancegScreen.defaultProps = {
  setShowRecharBalanceScreen: null,
};

export default RechargeBalancegScreen;
