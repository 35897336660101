/* eslint-disable no-console */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ResetPassword from './resetPassword';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './resetPassword.scss';

const ResetPasswordScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const navigate = useNavigate();

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <ResetPassword next={() => navigate('/')} />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop fullWidth>
        <div
          className="container-reset-password__desktop-mode"
        >
          <ResetPassword next={() => navigate('/')} />
        </div>
      </ContainerSectionDesktop>
    )
  );
};

export default ResetPasswordScreen;
