import React from 'react';
import PropTypes from 'prop-types';

import { useValidationCountry, useFetchIpData } from '../../../../../utils/validationCountry';
import Button from '../../../../../components/button';
import ImageEmpyState from '../../../../../components/imagesEmptyState';
import ItemListMobile from './itemListMobile';
import Typography from '../../../../../components/typography';

import NoShipping from '../../../../../assets/images/sin_envios.png';

import './mobileTable.scss';

const MobileTable = ({
  shippingList,
  numberAllItems,
  handleChangeCurrentPage,
  loadingShippings,
  currentPage,
  shippingSelecteds,
  handleChangeShippingSelected,
  downloadShippingGuides,
  handleChangeShippingSelecteds,
  goToQuoteShipping,
}) => {
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <>
      {
        shippingList.length <= 0 && !loadingShippings && (
          <div className="image-empty-state">
            <ImageEmpyState
              uri={NoShipping}
              title="Aún no has hecho tu primer envío"
              description="Aquí verás lo envíos que realices"
            />
            <Button
              text="Realiza tu primer envío"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => goToQuoteShipping()}
              className="buttonEmpyState"
            />
          </div>
        )
      }

      {
        (shippingList.length > 0 && !loadingShippings) && (
          <>
            {
              shippingList.flatMap((shipping) => (
                <ItemListMobile
                  onChangeBack={() => handleChangeShippingSelected(shipping.code)}
                  key={shipping.code}
                  shipping={shipping}
                  handleChangeShippingSelecteds={handleChangeShippingSelecteds}
                  shippingSelecteds={shippingSelecteds}
                  downloadShippingGuides={downloadShippingGuides}
                />
              ))
            }
          </>
        )
      }

      {loadingShippings && (
        <div className="info-data bottom-shippint-list-mobile">
          <div className="preloader" />
        </div>
      )}

      {!loadingShippings && numberAllItems > shippingList.length && (
        <button
          type="button"
          onClick={() => handleChangeCurrentPage(currentPage + 1)}
          className="see-more bottom-shippint-list-mobile"
        >
          <Typography type="superTitle-italic">Ver más</Typography>
        </button>
      )}
    </>
  );
};

MobileTable.propTypes = {
  shippingList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shippingSelecteds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleChangeShippingSelected: PropTypes.func.isRequired,
  numberAllItems: PropTypes.number.isRequired,
  handleChangeCurrentPage: PropTypes.func.isRequired,
  loadingShippings: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  goToQuoteShipping: PropTypes.func.isRequired,
  handleChangeShippingSelecteds: PropTypes.func.isRequired,
  downloadShippingGuides: PropTypes.func.isRequired,
};

export default MobileTable;
