import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UpdateUserAction } from '../../../../store/actions/auth.actions';
import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';
import { typeDelivery } from '../../../../constants/general';

import Input from '../../../input';
import Typography from '../../../typography';
import Modal from '../../../modal';
import Button from '../../../button';

import './directionSelect.scss';

const validationAddress = (address) => {
  if (address?.length < 5) {
    return true;
  }
  return false;
};

export const collectionOrTakeToOfficeFunction = (
  setCollectionOrTakeToOffice,
  closeAndRemoveNewDirecction,
  setHiddenComponent = null,
) => {
  setCollectionOrTakeToOffice('takeToOffice');
  if (closeAndRemoveNewDirecction) closeAndRemoveNewDirecction();
  if (setHiddenComponent) setHiddenComponent(true);
};

const DirectionSelect = ({
  directions, onChange, hideElement, UpdateFunction, user, isMobileDevice,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showNewDirection, setShowNewDirection] = useState(false);
  const [collectionOrTakeToOffice, setCollectionOrTakeToOffice] = useState('collectionInAddress');
  const [addressName, setAddresName] = useState(null);
  const [addAddress, setAddAddress] = useState(null);
  const [errorAddAddress, setErrorAddAddress] = useState(null);
  const [hiddenComponent, setHiddenComponent] = useState(false);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const closeAndRemoveNewDirecction = () => {
    setShowNewDirection(false);
  };

  const onChangeFunction = () => {
    onChange({
      selectedAddress,
      CollectionOrTakeToOffice: collectionOrTakeToOffice,
    });
  };

  const saveNewAddress = () => {
    if (errorAddAddress) setErrorAddAddress(null);
    const tempUser = user;
    const newAddressObject = {
      name: addressName || addAddress,
      address: addAddress,
    };
    if (tempUser.alternativeDirections) tempUser.alternativeDirections.unshift(newAddressObject);
    if (!tempUser.alternativeDirections) tempUser.alternativeDirections = [newAddressObject];
    UpdateFunction(tempUser);
    setSelectedAddress(addAddress);
    closeAndRemoveNewDirecction();
    setHiddenComponent(true);
  };

  useEffect(() => {
    if (hiddenComponent) setTimeout(() => hideElement(false), 100);
  }, [hiddenComponent]);

  useEffect(() => {
    if (selectedAddress) {
      onChangeFunction();
    }
  }, [selectedAddress]);

  const validation = () => {
    if (!addAddress || addAddress === '' || validationAddress(addAddress)) {
      setErrorAddAddress('Debes ingresar una dirección valida mayor a 5 caracteres');
      return false;
    }
    return true;
  };

  return (
    <>
      <button
        type="button"
        aria-label="Show/Hide Element"
        className={`back-ground-direction-select ${hiddenComponent ? 'hiddenComponent' : ''} ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
        onClick={() => { if (hideElement) hideElement(false); }}
      />
      <div className="container-address-list">
        <div className="container-add-new-address">
          <Input
            placeholder="Busca tu dirección"
            value={selectedAddress}
            onChange={setSelectedAddress}
            dataList={directions.map((address) => (`${address.name}-${address.address}`))}
            onClickDataList={() => setHiddenComponent(true)}
          />
          <h2
            className="add-address-button"
            onClick={() => setShowNewDirection(true)}
            onKeyUp={() => setShowNewDirection(true)}
          >
            Crear nueva dirección
          </h2>
          <hr />
        </div>
        <div className="address-list">
          {directions.map((address) => (
            <button
              aria-label="address"
              type="button"
              key={address?.name}
              className="each-address"
              onClick={() => {
                setSelectedAddress(address.address || address);
                setHiddenComponent(true);
              }}
            >
              <Typography
                text={address.name || address.address || address}
                type="value"
              />
            </button>
          ))}
          <hr />
        </div>
        <button
          type="button"
          className="collection-or-take-to-office"
          onClick={() => collectionOrTakeToOfficeFunction(
            setCollectionOrTakeToOffice,
            closeAndRemoveNewDirecction,
            setHiddenComponent)}
        >
          <h1>
            Lo llevaré a la
            {' '}
            {typeDelivery[user.countryCode]}
            {' '}
          </h1>
        </button>

        {showNewDirection && (
          <Modal
            closeBackground={closeAndRemoveNewDirecction}
          >
            <div className={`container-modal-add-new-address ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
              <div className="first-row">
                <div
                  className="container-close"
                  onClick={closeAndRemoveNewDirecction}
                  onKeyDown={closeAndRemoveNewDirecction}
                >
                  <div className="close-button" />
                </div>
                <Typography
                  text="Nueva dirección de recogida"
                  type="title"
                  className="first-title"
                />
                <div className="container-inputs">
                  <Input
                    label="Nombre de la sede (Opcional)"
                    placeholder="Sede de caribe"
                    value={addressName}
                    onChange={setAddresName}
                  />
                  <Input
                    label="Escribe la dirección"
                    placeholder="Carrera 11 #00 - 00"
                    value={addAddress}
                    onChange={setAddAddress}
                    annotation="Proporciona una dirección detallada para la recogida de tu envío."
                    annotationError={errorAddAddress}
                  />
                </div>
              </div>
              <div className="modal-buttons-add-address">
                <div>
                  <Button
                    text="Cancelar"
                    type="secondary"
                    onClick={closeAndRemoveNewDirecction}
                  />
                </div>
                <div>
                  <Button
                    text="Guardar nueva sede"
                    type={isCountryValid ? 'primary-mx' : 'primary'}
                    onClick={() => {
                      if (validation()) {
                        saveNewAddress();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

DirectionSelect.propTypes = {
  directions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  UpdateFunction: PropTypes.func.isRequired,
  user: PropTypes.shape({
    countryCode: PropTypes.string,
  }).isRequired,
  hideElement: PropTypes.func,
  isMobileDevice: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

const mapDispatchToProps = (dispatch) => ({
  UpdateFunction: bindActionCreators(UpdateUserAction, dispatch),
});

DirectionSelect.defaultProps = {
  hideElement: null,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DirectionSelect, collectionOrTakeToOfficeFunction);
