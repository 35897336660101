import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Modal from '../modal';
import CheckBox from '../checkBox';
import Button from '../button';
import Typography from '../typography';

const ModalTypeProduct = ({
  closeModal,
  optionsProductType,
  changeToCheck,
  handleSave,
}) => {
  const isMobileDevice = useSelector((state) => state?.mediaQuery?.isMobileDevice);

  return (
    <Modal
      className={`modal-product-type-list ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
      closeBackground={() => closeModal()}
    >
      <>
        <div className="title">
          <div className="title-center">
            <Typography
              text="Tipo de producto que envías"
              type="title"
              className="italic"
            />
            <div
              className="close"
              onClick={() => closeModal()}
              onKeyUp={() => closeModal()}
              role="button"
              aria-hidden
            />
          </div>
        </div>
        <div className="comments">
          <Typography
            text="Puedes seleccionar hasta"
            type="value"
            className="emphasis"
          />
          <Typography
            text="tres"
            type="value"
            className="emphasis tres-text"
          />
          <Typography
            text="opciones"
            type="value"
            className="emphasis"
          />
        </div>
        <div className="product-type-list">
          {optionsProductType.map((product) => (
            <CheckBox
              key={product.value}
              text={product.text}
              onChange={(checked) => { changeToCheck({ product, checked }); }}
              checked={product.checked}
              checkedClassContainer="change-at-font-family"
            />
          ))}
        </div>
        <div className="buttons">
          <Button
            text="Guardar"
            type="primary"
            onClick={handleSave}
          />
        </div>
      </>
    </Modal>
  );
};

ModalTypeProduct.propTypes = {
  closeModal: PropTypes.func.isRequired,
  optionsProductType: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  changeToCheck: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};
export default ModalTypeProduct;
