import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { existRole } from '../../utils/role';
import redirectOperationUserRouter from '.';

const NoUserRoutes = ({ children }) => {
  const { user } = useSelector((state) => state.auth);

  if (existRole(user)) return redirectOperationUserRouter();
  if (user) return <Navigate to="/cotizacion" replace />;

  return children;
};

NoUserRoutes.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NoUserRoutes;
