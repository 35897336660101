import axios from 'axios';

import {
  interactionsMicroserviceUrl, customerKeyInteractionsMicroservice,
} from '../constants';

export const getAds = () => new Promise((resolve, reject) => {
  const instance = axios.create({
    method: 'get',
    baseURL: `${interactionsMicroserviceUrl}/ads`,
    headers: {},
  });
  delete instance.defaults.headers;
  instance.get()
    .then((res) => resolve(res.data))
    .catch((error) => reject(error));
});

/**
 * @param {string} ad ad id
 */
export const createInteractionSchema = (ad) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyInteractionsMicroservice;
  axios.post(`${interactionsMicroserviceUrl}/interactionUserAds`, { ad })
    .then((res) => resolve(res.data))
    .catch((error) => reject(error));
});

export const getInteraction = (ad, userId) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyInteractionsMicroservice;
  axios.get(`${interactionsMicroserviceUrl}/interactionUserAd/${ad}/${userId}`)
    .then((res) => resolve(res.data))
    .catch((error) => reject(error));
});
