import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { typeDelivery } from '../../../../../constants/general';
import Typography from '../../../../../components/typography';
import Button from '../../../../../components/button';

import './contentTop.scss';

const ContentTop = ({
  isMobileDevice, shippingList, handleVisibilityFiltersModal, triggerDownloadReport,
}) => {
  const countryCode = useSelector((state) => state?.country || null);

  return (
    <div className="container-top__shipping-history">
      <Typography type="span" className="header-quote-gray">
        Descarga tu guía, imprímela y pégala a tu paquete;
        para entregarlo a la
        {' '}
        {typeDelivery[countryCode]}
        .
      </Typography>

      <div className="container-bottom__options__shipping-history closeness">
        <div className="left complete_header">
          {!isMobileDevice && shippingList.length > 0 && (
            <Button
              type="secondary"
              text="Filtra y busca envíos"
              onClick={() => handleVisibilityFiltersModal(true)}
            />
          )}

          {shippingList.length > 0 && !isMobileDevice && (
            <Button
              type="tertiary"
              text="Descarga informe de envíos"
              onClick={() => {
                handleVisibilityFiltersModal(true);
                triggerDownloadReport(true);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ContentTop.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  shippingList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleVisibilityFiltersModal: PropTypes.func.isRequired,
  triggerDownloadReport: PropTypes.func.isRequired,
};

export default ContentTop;
