/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/extensions
import { Workbook } from 'exceljs/dist/es5/exceljs.browser.js';
import { saveAs } from 'file-saver';

const EXCEL_EXTENSION = '.xlsx';

async function saveAsExcel(data, excelFileName, otherHeader) {
  try {
    let headers;
    headers = Object.entries(data[0]);
    if (otherHeader) {
      headers = Object.entries(otherHeader);
    }
    const header = [];
    const headersArray = [];
    headers.forEach((element) => {
      header.push(element[0]);
      headersArray.push(element[0]);
    });

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet();
    // // Add Header Row
    const headerRow = worksheet.addRow(header);
    // // Cell Style : Fill and Border
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'EF6C00' },
        bgColor: { argb: 'EF6C00' },
      };
      cell.alignment = {
        wrapText: true,
        horizontal: 'center',
      };
      cell.font = {
        color: { argb: 'FFFFFF' },
        bold: true,
        underline: true,
      };
    });

    // Add Data and Conditional Formatting
    data.forEach((element) => {
      const eachRow = [];
      // eslint-disable-next-line no-shadow
      headersArray.forEach((headers) => {
        eachRow.push(element[headers]);
      });
      worksheet.addRow(eachRow);
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 30;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 30 ? 30 : maxLength;
    });
    // Generate Excel File with given name
    const buf = await workbook.xlsx.writeBuffer();

    saveAs(new Blob([buf]), `${excelFileName}${EXCEL_EXTENSION}`);
  } catch (error) {
    throw new Error(error);
  }
}

export default saveAsExcel;
