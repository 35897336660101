import React from 'react';

import PropTypes from 'prop-types';

import Modal from '../../../../components/modal';
import CheckBox from '../../../../components/checkBox';
import ModalChildren from '../../../../components/modalChildren';
import Typography from '../../../../components/typography';
import DatePickerComponent from '../../../../components/datePickerComponent';
import { checkedItems, handleAllCheckbox, handleChangeCheckbox } from '../common';

const ModalFilters = ({
  modalFilter, dateInitSelected, dateEndSelected, setDateInitSelected,
  setDateEndSelected, dataDeliveryCompany, setDataDeliveryCompany,
  dataStatesReport, setDataStateReport, removeAllFilters,
  setModalFilter, setDownloadModal, setModalFilterMobile,
  hideDatePicker, searchByfilters,
}) => (
  <Modal className={`${'method-filter-content'} ${hideDatePicker ? 'hide-date-section' : ''}`}>
    <ModalChildren
      className="filter-report-modal"
      headerText={
        modalFilter ? <Typography type="superTitle-italic" text="Filtra tu informe por" />
          : <Typography type="superTitle-italic" text="Descarga tu informe" />
      }
      bodyText={(
        <div className="filter-report-modal__content">
          {modalFilter
            ? (
              <Typography
                type="field-dark-gray"
                text="Puedes seleccionar varios métodos para filtrar."
                className="title-filter"
              />
            )
            : (
              <div className="header-download">
                <Typography
                  type="field-dark-gray"
                  text="Puedes seleccionar o cambiar los filtros con los que se descargará el informe."
                  className="title-download"
                />
                <Typography
                  type="field-dark-gray"
                  text="Si no tienes seleccionado ningún filtro se descargará todo el histórico"
                  className="title-download"
                />
              </div>
            )}
          <div className={`${'sections'} ${!hideDatePicker ? '' : 'hide-date-section'}`}>
            <div className="delivery-companies">
              <div className="delivery-companies__content">
                <div className="information">
                  <Typography
                    type="field-dark-gray"
                    text="Transportadora"
                    className="color-darker-gray heebo-medium"
                  />
                  <CheckBox
                    checked={checkedItems(dataDeliveryCompany)}
                    onClick={() => handleAllCheckbox(
                      dataDeliveryCompany, setDataDeliveryCompany)}
                  />
                </div>
                {dataDeliveryCompany.map((item, index) => (
                  <div className="information" key={(index + 1).toString()}>
                    <div className="name">
                      <img src={item.image} alt="delivery-company" />
                      <Typography
                        type="title-dark"
                        text={item.name === 'Entrégalo (entregas El Mismo Día*)' ? 'Entrégalo' : item.name}
                      />
                    </div>
                    <CheckBox
                      checked={item.checked}
                      onClick={() => handleChangeCheckbox(item._id, '_id', dataDeliveryCompany, setDataDeliveryCompany)}
                    />
                  </div>
                ))}
              </div>
            </div>
            {!hideDatePicker && (
            <div className="date-information">
              <div className="date-information__title">
                <Typography
                  type="field-dark-gray"
                  text="Fecha de pago"
                  className="color-darker-gray heebo-medium"
                />
              </div>
              <DatePickerComponent
                className="date-picker-mobile"
                dateInitSelected={dateInitSelected}
                dateEndSelected={dateEndSelected}
                handleInitSelected={setDateInitSelected}
                handleEndSelected={setDateEndSelected}
              />
            </div>
            )}
            <div className="states">
              <div className="states__information">
                <Typography
                  type="field-dark-gray"
                  text="Estado"
                  className="color-darker-gray heebo-medium"
                />
                <CheckBox
                  checked={checkedItems(dataStatesReport)}
                  onClick={() => handleAllCheckbox(dataStatesReport, setDataStateReport)}
                  className="checkbox"
                />
              </div>
              {dataStatesReport.map((item, index) => (
                <div className="states__information" key={(index + 1).toString()}>
                  <Typography type="title-dark" text={item.state} />
                  <CheckBox
                    checked={item.checked}
                    onClick={() => handleChangeCheckbox(item.id, 'id', dataStatesReport, setDataStateReport)}
                    className="checkbox"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      onClickButtonCancel={() => removeAllFilters()}
      onClickButtonConfirm={() => searchByfilters()}
      confirmButtonType="primary"
      cancelButtonType="primary-second"
      classButtonCancel="button-cancel"
      classButtonConfirm="button-confirm"
      cancelButtonText="Limpiar filtros"
      confirmButtonText={modalFilter ? 'Aplicar filtros' : 'Descagar informe'}
      close={() => {
        setModalFilter(false);
        setDownloadModal(false);
        setModalFilterMobile(false);
      }}
      classButtonsFooter="buttons-footer"
    />
  </Modal>
);

ModalFilters.propTypes = {
  dataStatesReport: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  modalFilter: PropTypes.bool.isRequired,
  dateInitSelected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  dateEndSelected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  searchByfilters: PropTypes.func.isRequired,
  hideDatePicker: PropTypes.bool.isRequired,
  setDateInitSelected: PropTypes.func.isRequired,
  setDateEndSelected: PropTypes.func.isRequired,
  dataDeliveryCompany: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setDataDeliveryCompany: PropTypes.func.isRequired,
  setDataStateReport: PropTypes.func.isRequired,
  removeAllFilters: PropTypes.func.isRequired,
  setModalFilter: PropTypes.func.isRequired,
  setDownloadModal: PropTypes.func.isRequired,
  setModalFilterMobile: PropTypes.func.isRequired,
  // setCompleteFilter: PropTypes.func.isRequired,
};

ModalFilters.defaultProps = {
  dateInitSelected: null,
  dateEndSelected: null,
};

export default ModalFilters;
