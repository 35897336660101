/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import IconArrowSideColorAccent from '../../assets/images/icon-arrow-side-color-accent.svg';

import './carousel.scss';

const Carousel = ({
  children, className, buttonSize, style, pixelsPerStep, comunicationPosition,
}) => {
  const [marginLeft, setMarginLeft] = useState(0);
  const [containerWidth, setContainerWidth] = useState(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [contentWidth, setContentWidth] = useState(null);
  const containerReference = useRef(null);
  const contentReference = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const width = containerReference.current?.offsetWidth || 0;
      const height = containerReference.current?.offsetHeight || 0;
      setContainerWidth(width);
      setContainerHeight(height);
      clearTimeout(timeout);
    }, 150);
  }, [containerReference.current]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const width = contentReference.current?.scrollWidth || 0;
      setContentWidth(width);
      clearTimeout(timeout);
    }, 150);
  }, [contentReference.current]);

  const changePosition = (direction) => {
    if (direction === 'toRight') {
      setMarginLeft(marginLeft - (pixelsPerStep || containerWidth));
      if (comunicationPosition) comunicationPosition(direction);
    }
    if (direction === 'toLeft') {
      setMarginLeft(marginLeft + (pixelsPerStep || containerWidth));
      if (comunicationPosition) comunicationPosition(direction);
    }
  };

  return (
    <div className={`carousel ${className}`} ref={containerReference} style={style}>
      {contentWidth >= containerWidth && (
        <img
          src={IconArrowSideColorAccent}
          style={{
            width: `${buttonSize}px`,
            height: `${buttonSize}px`,
            top: `${(containerHeight / 2) - (buttonSize / 2)}px`,
            left: `${-(buttonSize / 2)}px`,
          }}
          className={`change-margin-left ${marginLeft >= 0 ? 'hidden' : ''}`}
          onClick={() => changePosition('toLeft')}
          alt="left"
        />
      )}
      <div className="container-content-carousel">
        <div style={{ marginLeft }} ref={contentReference}>
          {children}
        </div>
      </div>
      {contentWidth >= containerWidth && (
        <img
          src={IconArrowSideColorAccent}
          style={{
            width: `${buttonSize}px`,
            height: `${buttonSize}px`,
            top: `${(containerHeight / 2) - (buttonSize / 2)}px`,
            right: `${-(buttonSize / 2)}px`,
          }}
          className={`
            change-margin-left
            ${(((contentReference.current?.scrollWidth || 0) + marginLeft) <= containerReference.current?.offsetWidth || 0) ? 'hidden' : ''}
          `}
          onClick={() => changePosition('toRight')}
          alt="right"
        />
      )}
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  buttonSize: PropTypes.number,
  style: PropTypes.object,
  pixelsPerStep: PropTypes.number,
  comunicationPosition: PropTypes.func,
};

Carousel.defaultProps = {
  className: '',
  buttonSize: 48,
  style: {},
  pixelsPerStep: null,
  comunicationPosition: null,
};

export default Carousel;
