/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Filter from '../filter';
import Typography from '../../../../components/typography';

import filterList from '../../../../assets/images/filter_list-24px.svg';

import './filterToggle.scss';

// eslint-disable-next-line consistent-return
const ButtonFilterToggle = ({ onChange }) => {
  const [isVisible, setVisible] = useState(false);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    <>
      <button onClick={() => setVisible(!isVisible)} className={isMobileDevice ? 'toggleFilterButtonMobile' : 'toggleFilterButton'} aria-label="button">
        <Typography
          text={isMobileDevice ? '' : 'Filtrar por'}
          type="superTitle-italic"
          className="toggleFilterText"
        />
        <img src={filterList} className="iconFilter" alt="list-icon" />
      </button>
      <div className={isMobileDevice
        ? ((isVisible) ? 'toggleFilterMobile' : 'noToggleFilter')
        : ((isVisible) ? 'toggleFilter' : 'noToggleFilter')}
      >
        <div className="conten-herader">
          <Typography
            text="Filtrar por"
            type="superTitle-italic"
            className="FilterText"
          />
          <button onClick={() => setVisible(!isVisible)}>X</button>
        </div>
        <Filter onChangeFilter={onChange} isVisible={isVisible} />
      </div>
    </>
  );
};

ButtonFilterToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default ButtonFilterToggle;
