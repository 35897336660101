/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { capitalizeWords, toCurrencyCop } from '../../../../../utils/strings';
import { humanizationOfTimeDuration } from '../../../../../utils/generals';
import { finalPrice, collectionServiceValue } from '../../../../../utils/prices';
import { findObjectInArray } from '../../../../../utils/filters';
import { typeDelivery } from '../../../../../constants/general';
import { pendingCredit } from '../../../../../services/cash';

import Modal from '../../../../../components/modal';
import RadioButton from '../../../../../components/radioButton';
import Typography from '../../../../../components/typography';
import Button from '../../../../../components/button';
import NotificationsSettings from '../../../../../components/notificationsSettings/notificationsSettings';
import BalanceErrorInNotifications from '../../../../../components/balanceErrorInNotifications';
import NotificationsInfoModal from '../../../../../components/notificationsInfoModal';

import RechargeBalancegScreen from '../../../../rechargeBalanceScreen';
import { showDependingOnBusinessFlag } from '../../../../../utils/businessFlag';
import { useValidationCountry, useFetchIpData } from '../../../../../utils/validationCountry';
import calculateToNotificationsPrice from '../../../../../utils/calculateTonotificationsPrice';
import notificationsPaymentForSendingsWithPaymentType102 from './notificationsPaymentLogic';

import './stepThree.scss';

const TitlesAndlable = ({ text, type, className }) => (
  <Typography
    text={text}
    type={type}
    className={className}
  />
);

const StepThreeShipping = ({
  deliverySelected, shippingInfo, user, deliveryCompaniesConfig,
  addShippingToCollectionValue, onChange, isMobileDevice, businessFlags,
  generatingSending,
}) => {
  const [showShippingInfoDetail, setShowShippingInfoDetail] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [showRecharBalanceScreen, setShowRecharBalanceScreen] = useState(false);
  const [errorBalanceInNotification, setErrorBalanceInNotification] = useState(false);
  const [showCollectionService] = useState(
    shippingInfo.collectionService,
  );
  const [showModalNotificationInfo, setShowModalNotificationInfo] = useState(false);
  const [valueTotalNotifications, setValueTotalNotifications] = useState(0);
  const [notificationsToSendings, setNotificationsToSendings] = useState(null);
  const [TypeNotifications] = useState((shippingInfo.deliverySelected?.negotiation) ? 'saas' : 'mp');
  const [finalPriceAndNotifications, setFinalPriceAndNotifications] = useState(0);
  const [availableCredit, setAvailableCredit] = useState(0);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);
  const mobileDevice = isMobileDevice ? 'mobile-mode' : 'desktop-mode';

  const totalSendingValueNumber = finalPrice(
    deliverySelected.shippingCost,
    shippingInfo.collectionService,
    addShippingToCollectionValue,
    deliverySelected.collectionCommissionWithRate,
    deliverySelected.collectionCommissionWithOutRate,
  );

  const collectionValueNumber = collectionServiceValue(
    shippingInfo.saleValue,
    deliverySelected.shippingCost,
    shippingInfo.collectionService,
    addShippingToCollectionValue,
    deliverySelected.collectionCommissionWithRate,
    deliverySelected.collectionCommissionWithOutRate,
  );

  const thePaymentFromCollecionValueIsPosible = () => (
    deliveryCompaniesConfig ? (
      findObjectInArray(deliverySelected.deliveryCompanyId, deliveryCompaniesConfig, 'deliveryCompany')?.paymentFromCollecionValue
    ) : false
  );

  useEffect(async () => {
    const [credit] = await pendingCredit(user?._id);
    const creditAvaliable = (user.creditLimitCop || 0) - (credit ? credit.total : 0);
    setAvailableCredit(creditAvaliable);
  }, []);

  useEffect(() => {
    const CommissionWithRate = addShippingToCollectionValue
      ? deliverySelected.collectionCommissionWithRate
      : deliverySelected.collectionCommissionWithOutRate;

    setFinalPriceAndNotifications(
      CommissionWithRate + valueTotalNotifications + deliverySelected.shippingCost,
    );
  }, [valueTotalNotifications]);

  const handleShippingDetail = () => setShowShippingInfoDetail(!showShippingInfoDetail);

  return (
    <div id="payment-method-container">
      <div className={`inner-container ${mobileDevice}`}>

        <div className={`delivery-company ${mobileDevice}`}>
          <TitlesAndlable
            text={capitalizeWords(typeDelivery[user.countryCode])}
            type="title"
            className="center-text hebboMedium"
          />
          <div className="delivery-company-info">
            <div className="delivery-company-image">
              <img
                src={deliverySelected.deliveryCompanyImgUrl}
                alt={deliverySelected.deliveryCompanyName}
              />
            </div>
            <div className="content-delivery-info-detail">
              <TitlesAndlable
                text={deliverySelected.deliveryCompanyName}
                type="title"
                className="hebboMedium"
              />
              <div className="delivery-time">
                <TitlesAndlable
                  type="value"
                  text="Promedio de entrega:"
                  className="size"
                />
                <TitlesAndlable
                  type="value"
                  className="shipping-time size"
                  text={humanizationOfTimeDuration(deliverySelected.shippingTime)}
                />
              </div>
            </div>
          </div>
          <div className="shipping-value-info">
            <TitlesAndlable
              text="Valor total del servicio:"
              type="value"
            />
            <div className="shipping-value-info__discount">
              <TitlesAndlable
                text={toCurrencyCop(user, finalPriceAndNotifications)}
                type="value"
                className={deliverySelected.shippingRealCost && 'discount'}
              />
            </div>
          </div>
          {showCollectionService && (
            <div className="show-collection-service-info">
              <TitlesAndlable
                text="El precio incluye el pago contra entrega"
                type="value"
              />
            </div>
          )}
          {showDependingOnBusinessFlag({ businessFlags, section: 'notificationsSettings' })
            && (
              <NotificationsSettings
                customClass={`class-notifications ${mobileDevice}`}
                TypeNotifications={TypeNotifications}
                onChange={(notifications) => {
                  const {
                    sumWithInitial,
                    listCodeNotifications,
                  } = calculateToNotificationsPrice(notifications, shippingInfo, TypeNotifications);
                  setValueTotalNotifications(sumWithInitial);
                  setNotificationsToSendings(listCodeNotifications);
                }}
              />
            )}
          <button
            className={`change-button ${isMobileDevice ? 'mobile-mode' : 'destop-mode'}`}
            onClick={() => setShowModalNotificationInfo(true)}
            type="button"
            aria-label="button"
          >
            <Typography
              type="field"
              text="Ver notificaciones"
            />
          </button>
          {showModalNotificationInfo && (
          <NotificationsInfoModal
            close={() => setShowModalNotificationInfo(false)}
            isMobileDevice={isMobileDevice}
            closeBackground
            countryCode={user.countryCode}
          />
          )}
          <div><hr /></div>
          <div
            className={`show-shipping-info-detail ${isMobileDevice ? 'mobile-mode' : 'destop-mode'}`}
          >
            <button
              type="button"
              onClick={handleShippingDetail}
            >
              <span>{(!showShippingInfoDetail) ? 'Ver detalle' : 'Ocultar detalle'}</span>
              <div
                className={`image ${showShippingInfoDetail} ? 'displayed' : ''`}
              />
            </button>
          </div>
          {showShippingInfoDetail && (
            <div className="shipping-values-detail">
              <div className="freight-value">
                <Typography
                  text="Valor del flete:"
                  type="value"
                  className="label"
                />
                <Typography
                  text={toCurrencyCop(user, Math.floor(deliverySelected.shippingCost))}
                  type="value"
                />
              </div>
              {(showCollectionService)
                && (
                  <div className="freight-value">
                    <Typography
                      text="Comisión de recaudo:"
                      type="value"
                      className="label"
                    />
                    <Typography
                      text={toCurrencyCop(
                        user,
                        addShippingToCollectionValue
                          ? deliverySelected.collectionCommissionWithRate
                          : deliverySelected.collectionCommissionWithOutRate)}
                      type="value"
                    />
                  </div>
                )}
              {showDependingOnBusinessFlag({ businessFlags, section: 'notificationsSettings' })
                && (
                  <div className="freight-value">
                    <Typography
                      text="Total de notificaciones:"
                      type="value"
                      className="label"
                    />
                    <Typography
                      text={toCurrencyCop(user, valueTotalNotifications)}
                      type="value"
                    />
                  </div>
                )}
              <div className="freight-value">
                <Typography
                  text="Total del servicio:"
                  type="value"
                  className="label-total"
                />
                <Typography
                  text={toCurrencyCop(user, finalPriceAndNotifications)}
                  type="value"
                  className="label-total"
                />
              </div>
            </div>
          )}
        </div>
        <div className="payment-method">
          {(user.cash <= finalPriceAndNotifications)
            && !(shippingInfo.collectionService
              && collectionValueNumber > totalSendingValueNumber
              && thePaymentFromCollecionValueIsPosible()
              && notificationsPaymentForSendingsWithPaymentType102(
                valueTotalNotifications, user.cash,
              ))
            && (
              <>
                <div className="show-collection-service-info">
                  <Typography
                    text="Saldo insuficiente"
                    type="value"
                  />
                </div>
                <Button
                  text="Recargar Saldo"
                  type={isCountryValid ? 'primary-mx' : 'primary'}
                  onClick={() => {
                    setShowRecharBalanceScreen(true);
                  }}
                  className="recharge-balance"
                />
                {paymentMethod !== 'availableCredit' && !notificationsPaymentForSendingsWithPaymentType102(
                  valueTotalNotifications, user?.cash || 0, shippingInfo.collectionService) && (
                  <Typography
                    type="value"
                    text="El saldo no alcanza para pagar las notificaciones, puedes recargar saldo o desactivarlas para generar tu envío"
                    className="button-message"
                  />
                )}
              </>
            )}
          {user.cash >= finalPriceAndNotifications && (
            <RadioButton
              text="Quiero pagar con mi saldo disponible"
              value="availableBalance"
              name="paymentMethod"
              checked={paymentMethod === 'availableBalance'}
              onChange={setPaymentMethod}
              className="radio-button-container"
            />
          )}
          {user.creditPayment && availableCredit > finalPriceAndNotifications && (
            <RadioButton
              text="Quiero pagar con mi crédito"
              value="availableCredit"
              name="paymentMethod"
              checked={paymentMethod === 'availableCredit'}
              onChange={setPaymentMethod}
              className="radio-button-container"
            />
          )}
          {shippingInfo.collectionService
            && collectionValueNumber > totalSendingValueNumber
            && thePaymentFromCollecionValueIsPosible()
            && notificationsPaymentForSendingsWithPaymentType102(
              valueTotalNotifications, user?.cash || 0, shippingInfo.collectionService)
            && (
              <RadioButton
                text="Quiero descontar el costo de mi envío del valor a recaudar"
                value="deductFromCollection"
                name="paymentMethod"
                personalizedId="deduct"
                checked={paymentMethod === 'deductFromCollection'}
                onChange={setPaymentMethod}
                className="radio-button-container"
              />
            )}
          <div className="button-container">
            <Button
              text="Pagar ahora"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => {
                if (paymentMethod === 'deductFromCollection' && valueTotalNotifications > user.cash) {
                  setErrorBalanceInNotification(!errorBalanceInNotification);
                } else {
                  const data = {
                    action: 'pay',
                    PaymentMethod: paymentMethod,
                    notifications: notificationsToSendings,
                  };
                  onChange(data);
                }
              }}
              className="pay"
              loading={generatingSending}
              disabled={
                paymentMethod === null
                || (paymentMethod === 'availableBalance' && user.cash < finalPriceAndNotifications)
                || (paymentMethod === 'deductFromCollection' && collectionValueNumber < totalSendingValueNumber)
                || (paymentMethod === 'availableCredit' && availableCredit < finalPriceAndNotifications)
                || (paymentMethod === 'deductFromCollection' && !notificationsPaymentForSendingsWithPaymentType102(
                  valueTotalNotifications, user?.cash || 0, shippingInfo.collectionService))
              }
            />
          </div>
        </div>
      </div>
      {showRecharBalanceScreen && (
        <Modal className="modal-recharge-balance">
          <RechargeBalancegScreen setShowRecharBalanceScreen={setShowRecharBalanceScreen} />
        </Modal>
      )}
      {errorBalanceInNotification && (
        <BalanceErrorInNotifications
          onChangeBack={() => setErrorBalanceInNotification(!errorBalanceInNotification)}
          countryCode={user.countryCode}
        />
      )}
    </div>
  );
};

StepThreeShipping.propTypes = {
  deliverySelected: PropTypes.object.isRequired,
  shippingInfo: PropTypes.object.isRequired,
  addShippingToCollectionValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
  deliveryCompaniesConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  businessFlags: PropTypes.shape({}).isRequired,
  generatingSending: PropTypes.bool.isRequired,
};

StepThreeShipping.defaultProps = {
  addShippingToCollectionValue: false,
};

TitlesAndlable.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

TitlesAndlable.defaultProps = {
  className: '',
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  deliveryCompaniesConfig: state.globalData.deliveryCompaniesConfig,
  user: state.auth.user,
  businessFlags: state.globalData.businessFlags || [],
});

export default compose(
  connect(mapStateToProps),
)(StepThreeShipping);
