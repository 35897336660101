/* eslint-disable no-unused-vars */
import { noveltiesCenterUrl, nameLocalStorage } from '../constants';
import { decriptData, encriptData } from './auth';

export const redirectToOtherPlatforms = async ({ urlToRedirect, token, keep }) => {
  let realToken = null;
  let realKeep = false;

  if (token) {
    realToken = decriptData(token);
    realKeep = keep || false;
  } else if (localStorage.getItem(nameLocalStorage)) {
    realToken = localStorage.getItem(nameLocalStorage);
    realKeep = true;
  } else if (sessionStorage.getItem(nameLocalStorage)) {
    realToken = sessionStorage.getItem(nameLocalStorage);
  }

  if (realToken) {
    const tokenObject = token ? JSON.parse(realToken) : JSON.parse(decriptData(realToken));
    delete tokenObject.user.transportersWithNegotiation;
    delete tokenObject.user.alternativeDirections;
    delete tokenObject.user.deliveryCompanyDisabled;
    delete tokenObject.user.activeNotifications;
    delete tokenObject.user.volumeDiscountNegotiation;
    const encryptRealToken = await encriptData(tokenObject);

    let hrefToRedirect = urlToRedirect || noveltiesCenterUrl;
    if (urlToRedirect) {
      const startIndex = urlToRedirect.indexOf('?');
      if (startIndex >= 0) {
        hrefToRedirect = urlToRedirect.split('').map((char, index) => index < startIndex && char).join('');
      }
    }
    const intercommunicationData = JSON.stringify({
      token: encryptRealToken,
      keep: realKeep,
    });
    window.location.assign(`${hrefToRedirect}?intercommunicationData=${intercommunicationData}`);
  }
};

export default {
  redirectToOtherPlatforms,
};
