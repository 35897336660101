/* eslint-disable quote-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import mexicoConfig from '../../../../../config/config.mexico.json';

import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import Select from '../../../../../components/select';
import Typography from '../../../../../components/typography';
import SuccessModal from '../../../../../components/successModal';

import { validationSteps } from '../../validations';
import { findObjectInArray } from '../../../../../utils/filters';
import { UpdateUserAction } from '../../../../../store/actions/auth.actions';
import { invoicingDataUpdateService, updateUserService } from '../../../../../services/user';
import { methodOfPaymentOptionsMexico, personTypeOptionsMexico } from '../../../../../constants/general';

import IconSearch from '../../../../../assets/images/search.svg';

import '../billingDataEdit.scss';

const CFDIList = mexicoConfig.CFDIKey;
const dataListPersonaMoral = mexicoConfig.moralPerson;
const dataListPersonaFisica = mexicoConfig.physicalPerson;

const BillingDataEditMexico = ({ user, isMobileDevice }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [RFC, setRFC] = useState(user.invoicingData?.RFC || '');
  const [tradeName, setTradeName] = useState(user.tradeName || '');
  const [personType, setPersonType] = useState(user.personType || '');
  const [taxResidence, setTaxResidence] = useState(user.invoicingData?.taxResidence || '');
  const [postalCode, setPostalCode] = useState(user.invoicingData?.postalCode || '');
  const [methodOfPayment, setMethodOfPayment] = useState(user.invoicingData?.methodOfPayment || '');
  const [CFDI, setCFDI] = useState(user.invoicingData?.CFDI || '');
  const [emailForBilling, setEmailForBilling] = useState(user.invoicingData?.emailForBilling || '');
  const [taxRegime, setTaxRegime] = useState(user.invoicingData?.taxRegime || '');
  const [modalContent, setModalContent] = useState(false);
  const [modalContentSuccess, setModalContentSuccess] = useState(false);
  const [currentDataList, setCurrentDataList] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorList, setErrorList] = useState([]);

  const updateErrors = (name, error) => {
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const validateRFC = (value) => {
    const isValidRFC = /^[\D\d]{12,13}$/.test(value);
    updateErrors('RFC', isValidRFC ? null : 'RFC inválido');
  };

  const handleSaveChanges = async () => {
    try {
      const updatedUser = await updateUserService(user._id, {
        tradeName, personType,
      });
      const updateInvoicingData = await invoicingDataUpdateService({
        RFC,
        taxResidence,
        postalCode,
        methodOfPayment,
        CFDI,
        taxRegime,
        emailForBilling,
        countryCode: user.countryCode,
      });
      dispatch(UpdateUserAction({
        ...updatedUser,
        invoicingData: {
          ...updateInvoicingData,
        },
      }));
      setErrors({});
      setModalContentSuccess(true);
    } catch (error) {
      setModalContent(true);
    }
  };

  const handleModalClose = () => {
    setModalContentSuccess(false);
    navigate('/perfil/datos-de-facturacion');
  };

  const validatePostalCode = (value) => {
    const isValidPostalCode = /^\d{5}$/.test(value);
    updateErrors('postalCode', isValidPostalCode ? null : 'Código postal inválido');
  };

  const validateEmail = (value) => {
    const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    updateErrors('emailForBilling', isValidEmail ? null : 'Email inválido');
  };

  const title = () => (
    <div>
      Los siguientes datos son necesarios para
      facturar y deben coincidir con los del
      {' '}
      <span>SAT </span>
      <br />
      {' '}
      (Servicio de Administración Tributaria)
    </div>
  );

  const hasErrors = Object.values(errors).some((error) => error !== null);

  return (
    <div className={`content-edit-info ${isMobileDevice && 'billing-edit-mobile'}`}>
      <div className="content-with-button">
        <Typography
          className="main-title"
          text={title()}
        />
        <div className="input-content">
          <div className="content-edit-left">
            <div className="inputContainer">
              <Input
                label="RFC (Registro Federal de Contribuyentes)"
                value={RFC}
                onChange={(value) => {
                  const truncatedValue = value.slice(0, 13);
                  setRFC(truncatedValue);
                  validateRFC(truncatedValue);
                }}
                onBlur={() => {
                  validationSteps({ errorList, setErrorList, RFC });
                  validateRFC(RFC);
                }}
                annotationError={errors.RFC ? findObjectInArray('RFC', errorList, 'name')?.error : null}
              />
            </div>
            <div className="inputContainer">
              <Input
                label="Razón social sin los conceptos mercantiles"
                value={tradeName}
                onChange={setTradeName}
                onBlur={() => validationSteps({
                  errorList, setErrorList, lengthError: true, value: tradeName, personalized: 'Ingresa tu razón social',
                })}
                annotationError={
                  findObjectInArray('lengthError', errorList, 'name')
                    ? findObjectInArray('lengthError', errorList, 'name').error
                    : null
                }
                annotation="Ejemplo: ClicOH (sin el S.A. de C.V.)"
              />
            </div>
            <div className="selectContainer">
              <Typography className="textType" text="Tipo de persona" type="title" />
              <Select
                label="Selecciona una opción"
                value={personType}
                onChange={(value) => {
                  setPersonType(value);
                  if (value === 'Persona Física') {
                    setCurrentDataList(dataListPersonaFisica);
                    setTaxRegime(null);
                  } else if (value === 'Persona Moral') {
                    setCurrentDataList(dataListPersonaMoral);
                    setTaxRegime(null);
                  }
                }}
                options={personTypeOptionsMexico}
              />
            </div>
            <div className="inputContainer">
              <Input
                label="Régimen Fiscal"
                value={taxRegime}
                onChange={(value) => {
                  setTaxRegime(value);
                }}
                iconRigth={IconSearch}
                dataList={currentDataList}
              />
            </div>
          </div>
          <div className="content-edit-right">
            <div className="inputContainer">
              <Input
                label="Domicilio fiscal"
                value={taxResidence}
                onChange={(value) => setTaxResidence(value)}
                onBlur={() => validationSteps({ errorList, setErrorList, address: taxResidence })}
                annotationError={
                  findObjectInArray('address', errorList, 'name')
                    ? findObjectInArray('address', errorList, 'name').error
                    : null
                }
              />
            </div>
            <div className="inputContainer">
              <Input
                label="Código postal"
                value={postalCode}
                onChange={(value) => {
                  const truncatedValue = value.slice(0, 5);
                  setPostalCode(truncatedValue);
                  validatePostalCode(truncatedValue);
                }}
                onBlur={() => {
                  validationSteps({ errorList, setErrorList, postalCode });
                  validatePostalCode(postalCode);
                }}
                annotationError={errors.postalCode ? findObjectInArray('postalCode', errorList, 'name')?.error : null}
              />
            </div>
            <div className="selectContainer">
              <Typography className="textType" text="Forma de pago" type="title" />
              <Select
                label="Selecciona una opción"
                value={methodOfPayment}
                onChange={(value) => setMethodOfPayment(value)}
                className="selectUserToUpdate-mexico"
                options={methodOfPaymentOptionsMexico}
                startingPosition="top-left"
              />
            </div>
            <div className="inputContainer">
              <Input
                label="Clave de uso CFDI"
                value={CFDI}
                onChange={(value) => {
                  setCFDI(value);
                }}
                dataList2={CFDIList}
                iconRigth={IconSearch}
              />
            </div>
            <div className="inputContainer">
              <Input
                label="E-mail para facturación electrónica"
                value={emailForBilling}
                onChange={(value) => {
                  setEmailForBilling(value);
                  validateEmail(value);
                }}
                onBlur={() => {
                  validationSteps({ errorList, setErrorList, emailForBilling });
                  validateEmail(emailForBilling);
                }}
                annotationError={errors.emailForBilling ? findObjectInArray('emailForBilling', errorList, 'name')?.error : null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="content-button">
        <Button
          onClick={handleSaveChanges}
          type="quaternary"
          text="Guardar cambios"
          disabled={hasErrors}
        />
      </div>
      {modalContent && (
        <SuccessModal
          text="Hubo un error al guardar los cambios"
          onClose={() => setModalContent(false)}
        />
      )}
      {modalContentSuccess && (
        <SuccessModal
          text="Los cambios se han guardado exitosamente"
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

BillingDataEditMexico.propTypes = {
  user: PropTypes.instanceOf.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

BillingDataEditMexico.defaultProps = {

};

export default BillingDataEditMexico;
