/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import mexicoConfig from '../../../../config/config.mexico.json';

import useHandleFields from '../../../../hooks/useHandleFields';
import { useFindLocations, useLocationService } from '../../../../hooks/useShippingSteps';
import { getDataList, paramsLocation } from '../../../../utils/paramsLocations';
import { limitCharacters, validationsFilledInputs } from '../../../../utils/quoteShipping';
import { findObjectInArray } from '../../../../utils/filters';
import { stepsValidation } from '../../quoteCompletingSteps/validation';
import {optionsSelectFiltered} from "../../../../utils/generals";


import Input from '../../../input';
import Button from '../../../button';
import Select from '../../../select';
import Typography from '../../../typography';

import IconSearch from '../../../../assets/images/search.svg';
import FlagColombia from '../../../../assets/images/colombia.svg';

import './stepTwo.scss';

const StepTwo = ({
  next,
  setErrorsList,
  errorsList,
  valuesForm,
  listFields,
  title,
  children,
  handleButton,
  titleButton,
  changes,
  loading,
}) => {
  const locations = useSelector((state) => state.globalData.locations || []);
  const countryCode = useSelector((state) => state.country);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const { getLocationService } = useLocationService();

  const [disabledInput, setDisabledInput] = useState(true);
  const [listData, setListData] = useState([]);

  const [formValues, updateFormValues] = useHandleFields(valuesForm);

  const { locationCode } = useFindLocations(countryCode, formValues.locationName, locations);

  useEffect(() => {
    if (formValues.personType) {
      setDisabledInput(false);
      countryCode === '484' && validationsInputs('personType', formValues.personType, '')[formValues.personType]();
    }
  }, [changes, listFields]);
  const validationsInputs = (name, value, maxValue) => {
    return {
      'cellPhone': () => limitCharacters(name, value, maxValue, updateFormValues),
      'codeVerification': () => limitCharacters(name, value, maxValue, updateFormValues),
      'RFC': () => limitCharacters(name, value, maxValue, updateFormValues),
      'postalCode': () => limitCharacters(name, value, maxValue, updateFormValues),
      'Persona Jurídica': () => {
        updateFormValues(name, value);
        updateFormValues('documentType', 'NIT');
        setDisabledInput(true);
      },
      'Persona Natural': () => {
        updateFormValues(name, value);
        updateFormValues('documentType', '');
        setDisabledInput(false);
      },
      'Persona Física': () => {
        setListData(mexicoConfig.physicalPerson);
        updateFormValues(name, value);
        !formValues.personType && updateFormValues('taxRegime', '');
        setDisabledInput(false);
      },
      'Persona Moral': () => {
        setListData(mexicoConfig.moralPerson);
        updateFormValues(name, value)
        !formValues.personType && updateFormValues('taxRegime', '');
        setDisabledInput(false);
      },
      'CFDI': () => {
        setListData(mexicoConfig.CFDIKey);
        updateFormValues(name, value)
      },
      'documentType': () => {
        updateFormValues('identificationNumber', '')
        updateFormValues(name, value)
      },
    }
  };
  const changeTypeField = () => {
    if (formValues.documentType === 'Pasaporte') {
      return 'text';
    }
    return 'number';
  };

  const handleChange = (value, name, maxValue) => {
    if ((name === 'personType' || formValues.personType) && value === 'Persona Natural') {
      updateFormValues('identificationNumber', '');
      updateFormValues('codeVerification', '');
    }

    const validations = validationsInputs(name, value, maxValue)[name] || validationsInputs(name, value, maxValue)[value];
    return validations ? validations() : updateFormValues(name, value);
  };

  const showListOptions = (name) => {
    if (countryCode === '484') return listData;
    return getDataList(locations, name, countryCode);
  };

  const validationButton = () => {
    return !validationsFilledInputs(formValues, errorsList);
  };

  const stylesContainer = () => {
    if (countryCode === '170' && listFields.length > 5) {
      return 'content-large-list';
    }
    if (listFields.length > 5) {
      return 'content-new-large-list';
    }
    return 'content-short-list';
  };

  return (
    <div id="container-info-receiver">
      <div className={`container-info-receiver-shipping ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
        <div className="title">
          {title && <Typography text={title} type="title" />}
          { children }
        </div>
        {/* eslint-disable-next-line no-nested-ternary */}
        <div className={stylesContainer()}>
          { listFields?.map(({
            label,
            name,
            type,
            placeholder,
            typeField,
            isIconRight,
            options,
            isLine,
            textError,
            isDataList,
            annotations,
            maxValue,
            isImgLeft,
          }) => (
            <div className="field-container" key={name}>
              { typeField === 'input'
                ? (
                  <Input
                    value={formValues[name]}
                    onChange={async (value) => {
                      handleChange(value, name, maxValue);
                      if (name === 'locationName' && countryCode === '484') {
                        await paramsLocation(name, value, countryCode, getLocationService);
                      }
                      if (findObjectInArray(name, errorsList, 'name')) {
                        stepsValidation(
                          name,
                          textError,
                          value,
                          errorsList,
                          setErrorsList,
                          locations,
                          countryCode
                        );
                      }
                    }}
                    placeholder={placeholder}
                    label={label}
                    type={type}
                    iconRigth={isIconRight && IconSearch}
                    annotationError={findObjectInArray(name, errorsList, 'name')?.error ?? null}
                    onBlur={(e) => {
                      stepsValidation(
                        name,
                        textError,
                        e.target.value,
                        errorsList,
                        setErrorsList,
                        countryCode === '170' && locations,
                        countryCode
                      );
                    }}
                    dataList={isDataList && showListOptions(name)}
                    disabled={name === 'taxRegime' && disabledInput}
                    annotation={annotations}
                    isFormatNumber={false}
                    iconLeft={isImgLeft && FlagColombia}
                  />
                )
                : (
                  <>
                    <Typography
                      text={label}
                      className="label"
                      type=""
                    />
                    <Select
                      label="Selecciona una opción"
                      className="selectUserToUpdate"
                      value={formValues[name]}
                      onChange={(value) => {
                        handleChange(value, name);
                        if (findObjectInArray(name, errorsList, 'name')) {
                          stepsValidation(
                            name,
                            textError,
                            formValues,
                            errorsList,
                            setErrorsList,
                            countryCode
                          );
                        }
                      }}
                      options={optionsSelectFiltered(name, options, formValues.personType)}
                      startingPosition="top-left"
                      annotationError={
                        findObjectInArray(name, errorsList, 'name')
                          ? findObjectInArray(name, errorsList, 'name').error
                          : null
                      }
                      disabled={name === 'documentType' && disabledInput}
                    />
                  </>
                )}
              {formValues[name] === 'NIT' && countryCode === '170' && (
                <div className="input-nit">
                  <Input
                    value={formValues.identificationNumber}
                    onChange={(value) => {
                      handleChange(value, 'identificationNumber')
                      if (findObjectInArray('identificationNumber', errorsList, 'name')) {
                        stepsValidation(
                          'identificationNumber',
                          textError,
                          value,
                          errorsList,
                          setErrorsList,
                          locations,
                          countryCode
                        );
                      }
                    }
                  }
                    placeholder="1012542210"
                    label="Número de NIT"
                    type="number"
                    annotationError={findObjectInArray('identificationNumber', errorsList, 'name')?.error ?? null}
                    onBlur={(e) => {
                      stepsValidation(
                        'identificationNumber',
                        'Ingrese un número de NIT',
                        e.target.value,
                        errorsList,
                        setErrorsList,
                        countryCode
                      );
                    }}
                    isFormatNumber={false}
                  />
                  <span className="line">-</span>
                  <Input
                    value={formValues.codeVerification}
                    onChange={(value) => {
                      handleChange(value, 'codeVerification');
                      limitCharacters('codeVerification', value, 1, updateFormValues);
                      if (findObjectInArray('codeVerification', errorsList, 'name')) {
                        stepsValidation(
                          'codeVerification',
                          textError,
                          value,
                          errorsList,
                          setErrorsList,
                          locations,
                          countryCode
                        );
                      }
                    }}
                    placeholder="1"
                    label=""
                    type="text"
                    annotationError={findObjectInArray('codeVerification', errorsList, 'name')?.error ?? null}
                    onBlur={(e) => {
                      stepsValidation(
                        'codeVerification',
                        'Ingrese un código de verificación',
                        e.target.value,
                        errorsList,
                        setErrorsList,
                        countryCode
                      );
                    }}
                    className="code-input"
                  />
                </div>
              )}
              {['CC', 'PA', 'CE'].includes(formValues[name]) && countryCode === '170' && (
                <div className="input-identification">
                  <Input
                    value={formValues.identificationNumber}
                    onChange={(value) => {
                      handleChange(value, 'identificationNumber');
                      if (findObjectInArray('identificationNumber', errorsList, 'name')) {
                        stepsValidation(
                          'identificationNumber',
                          textError,
                          value,
                          errorsList,
                          setErrorsList,
                          locations,
                          countryCode
                        );
                      }
                    }}
                    placeholder="1012542210"
                    label="Número de identificación"
                    type={changeTypeField()}
                    annotationError={findObjectInArray('identificationNumber', errorsList, 'name')?.error ?? null}
                    onBlur={(e) => {
                      stepsValidation(
                        'identificationNumber',
                        'Ingrese un número de de identificación',
                        e.target.value,
                        errorsList,
                        setErrorsList,
                        countryCode
                      );
                    }}
                    isFormatNumber={false}
                  />
                </div>
              )}
              {formValues[name] === 'Persona Natural' && countryCode === '170' && (
                <div className="last-inputs">
                  <Input
                    value={formValues.name}
                    onChange={(value) => {
                      handleChange(value, 'name');
                      if (findObjectInArray('name', errorsList, 'name')) {
                        stepsValidation(
                          'name',
                          textError,
                          value,
                          errorsList,
                          setErrorsList,
                          locations,
                          countryCode
                        );
                      }
                    }}
                    placeholder="Juan Pablo Pérez Botero"
                    label="Nombre completo"
                    type="text"
                    annotationError={findObjectInArray('name', errorsList, 'name')?.error ?? null}
                    onBlur={(e) => {
                      stepsValidation(
                        'name',
                        'Ingrese un nombre',
                        e.target.value,
                        errorsList,
                        setErrorsList,
                        countryCode
                      );
                    }}
                  />
                </div>
              )}

              {formValues[name] === 'Persona Jurídica' && countryCode === '170' && (
                <div className="last-inputs">
                  <Input
                    value={formValues.tradeName}
                    onChange={(value) => {
                      handleChange(value, 'tradeName');
                      if (findObjectInArray('tradeName', errorsList, 'name')) {
                        stepsValidation(
                          'tradeName',
                          textError,
                          value,
                          errorsList,
                          setErrorsList,
                          locations,
                          countryCode
                        );
                      }
                    }}
                    placeholder="Mi empresa SAS"
                    label="Razón social"
                    type="text"
                    annotationError={findObjectInArray('tradeName', errorsList, 'name')?.error ?? null}
                    onBlur={(e) => {
                      stepsValidation(
                        'tradeName',
                        'Ingresa tu razón social',
                        e.target.value,
                        errorsList,
                        setErrorsList,
                        countryCode
                      );
                    }}
                  />
                </div>
              )}
              {listFields?.length > 5 && <div className={isLine && 'divider'} />}
            </div>
          ))}
        </div>
        <div className="button-container">
          <Button
            text={titleButton}
            type="quaternary"
            onClick={() => {
              next(formValues);
              handleButton({
                formValues,
                locationCode,
                changes,
              });
            }}
            loading={loading}
            className="strict-width"
            disabled={validationButton()}
          />
        </div>
      </div>
    </div>
  );
};

StepTwo.propTypes = {
  next: PropTypes.func.isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setErrorsList: PropTypes.func.isRequired,
  userToUpdate: PropTypes.shape({
    nameGuide: PropTypes.string,
    locationCode: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  changes: PropTypes.shape({}).isRequired,
  valuesForm: PropTypes.shape({}).isRequired,
  listFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  handleButton: PropTypes.func,
  titleButton: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

StepTwo.defaultProps = {
  title: '',
  handleButton: () => {},
};

export default StepTwo;
