/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';

import { PDFDownloadLink } from '@react-pdf/renderer';

import MyDoc from '../../../../utils/newGeneratePdf';
import { ReceiptOfPaymentStructurePdf } from './dataToPdf';
import { toCurrencyCop, userName } from '../../../../utils/strings';

import Typography from '../../../../components/typography';

import './receiptOfPayment.scss';

const ReceiptOfPayment = ({
  isMobileDevice, elpResult, user,
}) => (
  <div id="receipt-container" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
    <div id="header__receipt-container">
      <div className="left" />
      <div className="center">
        <Typography text="Recibo de transacción" type="superTitle-italic" className="left-space" />
      </div>
      <div className="rigth" />
    </div>
    <div id="body__receipt-container">
      <div className="bank-info">
        <div className="row">
          <div className="inner-row column">
            <Typography text="Razón social:" type="superTitle-italic" className="left" />
            <Typography text={user.businessName || userName(user.name, user.surname)} type="superTitle-italic" className="rigth" />
          </div>
          <div className="inner-row rigth column">
            <Typography text="NIT:" type="superTitle-italic" className="left" />
            <Typography text={user.documentNumber} type="superTitle-italic" className="rigth" />
          </div>
        </div>
        <div className="row">
          <div className="inner-row column">
            <Typography text="Banco:" type="superTitle-italic" className="left" />
            <Typography text={elpResult.TARJETAbanco} type="superTitle-italic" className="rigth" />
          </div>
          <div className="inner-row rigth column">
            <Typography text="CUS:" type="superTitle-italic" className="left" />
            <Typography text={elpResult.CodigoUnico} type="superTitle-italic" className="rigth" />
          </div>
        </div>
      </div>
      <div className="bank-info">
        <div className="row">
          <div className="inner-row column">
            <Typography text="Número de factura:" type="superTitle-italic" className="left" />
            <Typography text={elpResult.Referencia} type="superTitle-italic" className="rigth" />
          </div>
          <div className="inner-row rigth column">
            <Typography text="Fecha de transacción:" type="superTitle-italic" className="left" />
            <Typography text={elpResult.fechatransaccion} type="superTitle-italic" className="rigth" />
          </div>
        </div>
        <div className="row">
          <div className="inner-row column">
            <Typography text="Descripción de pago:" type="superTitle-italic" className="left" />
            <Typography text="Recarga de saldo" type="superTitle-italic" className="rigth" />
          </div>
          <div className="inner-row rigth column">
            <div>
              <Typography text="Hora de transacción:" type="superTitle-italic" className="left" />
            </div>
            <div>
              <Typography text={elpResult.HoraTransaccion} type="superTitle-italic" className="rigth" />
            </div>
          </div>
        </div>
      </div>
      <div className="bank-info price-info">
        <div className="row">
          <div className="inner-row">
            <Typography text="Valor:" type="superTitle-italic" className="left" />
            <Typography text={toCurrencyCop(user, elpResult.ValorTotalConversion)} type="superTitle-italic" className="rigth" />
          </div>
          <div className="inner-row rigth">
            <Typography text="Estado:" type="superTitle-italic" className="left" />
            <Typography
              text={elpResult.respuesta?.toUpperCase()}
              type="superTitle-italic"
              className={`${elpResult.respuesta === 'aprobada' ? 'green' : 'red'} rigth m4`}
            />
          </div>
        </div>
        <div className="row">
          <div className="inner-row">
            <Typography text="IVA:" type="superTitle-italic" className="left" />
            <Typography
              text={toCurrencyCop(user, elpResult.ValorIvaConversion)}
              type="superTitle-italic"
              className="rigth"
            />
          </div>
          <div className="inner-row rigth">
            {elpResult.Ciclo && (
            <>
              <Typography text="Ciclo:" type="superTitle-italic" className="left" />
              <Typography text={elpResult.Ciclo} type="superTitle-italic" className="rigth" />
            </>
            )}
          </div>
        </div>
      </div>
      <div className="bank-info price-info">
        <div className="row">
          <div className="inner-row" />
          <div className="inner-row rigth">
            {elpResult.ip && (
            <>
              <Typography text="Dirección IP:" type="superTitle-italic" className="left" />
              <Typography text={elpResult.ip} type="superTitle-italic" className="rigth" />
            </>
            )}
          </div>
        </div>
      </div>
    </div>
    <div id="body__receipt-button">
      <div className="button">
        <PDFDownloadLink document={<MyDoc customDoc={ReceiptOfPaymentStructurePdf(elpResult, user)} />} fileName={`${Date.now()}.pdf`}>
          {({
            blob, url, loading, error,
          }) => (loading ? 'Loading document...' : 'Download now!')}
        </PDFDownloadLink>
      </div>
    </div>
  </div>
);

ReceiptOfPayment.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  elpResult: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(ReceiptOfPayment);
