/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { findCodeLocation } from '../../../../utils/generals';
import { useShippingSteps } from '../../../../hooks/useShippingSteps';
import { updateUserCompletingData } from '../../../../utils/users/completeDataModal';

import Modal from '../../../modal';
import StepOne from '../stepOne/stepOne';
import StepTwo from '../stepTwo/stepTwo';
import StepThree from '../stepThree/stepThree';

import ModalChildren from '../../../modalChildren';
import AlertIncompleteData from './alertIncompleteData';

import './completingData.scss';

const CompletingDataSteps = () => {
  const user = useSelector((state) => state.auth.user);
  const countryCode = useSelector((state) => state?.country || null);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [stepsData, setStepsData] = useState({});
  const [errorList, setErrorList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    isUpdateData,
    showModalDataCompleting,
    updateShippingSteps,
    noDataCompleting,
  } = useShippingSteps(countryCode, user);

  useEffect(() => {
    updateShippingSteps();
  }, [user]);

  useEffect(() => {
    if (showModalDataCompleting && noDataCompleting) {
      setTimeout(() => {
        setShowAlert(true);
      }, 3000);
    }
  }, [showModalDataCompleting]);

  const getCodeLocation = (nameLocation, locationCode) => {
    if (!locationCode) {
      return findCodeLocation(nameLocation);
    }

    return locationCode;
  };
  const stepSelected = () => {
    switch (step) {
      case 1: return (
        <StepOne
          close={() => setStep(0)}
          next={(changes) => {
            setStepsData(changes);
            setStep(2);
          }}
          errorList={errorList}
          setErrorList={setErrorList}
          stepsData={stepsData}
        />
      );
      case 2: return (
        <StepTwo
          close={() => setStep(0)}
          next={(changes) => {
            setStepsData({ ...stepsData, ...changes });
            setStep(3);
          }}
          back={() => setStep(1)}
          errorList={errorList}
          setErrorList={setErrorList}
          stepsData={stepsData}
        />
      );
      case 3: return (
        <StepThree
          close={() => setStep(0)}
          next={(changes) => {
            setStepsData({ ...stepsData, ...changes });
          }}
          back={() => setStep(2)}
          errorsList={errorList}
          setErrorsList={setErrorList}
          isMobileDevice={isMobileDevice}
          stepsData={stepsData}
          loading={loading}
          handleButton={(value) => {
            const data = {
              changes: value.formValues,
              formValues: value.changes,
              locationCode: value.locationCode,
              user,
              countryCode,
            };
            updateUserCompletingData(
              data,
              dispatch,
              getCodeLocation,
              setShowModal,
              setModalContent,
              setLoading,
              setStep,
              isUpdateData,
            );
          }}
        />
      );
      default: return <></>;
    }
  };

  return user ? (
    <>
      <AlertIncompleteData
        visible={showAlert}
        next={() => {
          setStep(1);
          setShowAlert(false);
        }}
        close={() => {
          setShowAlert(false);
        }}
      />
      {stepSelected()}

      {showModal && (
        <Modal>
          <ModalChildren
            className="modalCompletingData"
            close={() => {
              setShowModal(false);
              setModalContent(null);
            }}
            headerText={modalContent?.title}
            confirmButtonText={modalContent?.type === 'success' ? '¡Entendido!' : 'Intentar de nuevo'}
            onClickButtonConfirm={() => {
              if (modalContent?.type === 'success') setStep(0);
              setShowModal(false);
            }}
            bodyText={modalContent?.text}
          />
        </Modal>
      )}
    </>
  ) : null;
};

export default CompletingDataSteps;
