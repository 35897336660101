import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Typography from '../typography';

import './dropDown.scss';

const Dropdown = ({
  options, tooltip, dropDownText, mainImg, onChange, selectText,
}) => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const [isOpen, setIsOpen] = useState(true);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const toggleDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className={`custom-dropdown ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`} ref={dropdownRef}>
      {isOpenDropdown ? (
        <div className="custom-select">
          <button
            type="button"
            className="custom-select-trigger"
            onClick={() => setIsOpenDropdown(false)}
          >
            <span>{selectText}</span>
            <div className="selectArrow" />
          </button>
          {isOpen && (
            <div className="custom-options">
              {options.map((option) => (
                <button
                  type="button"
                  key={(option.value)}
                  className="custom-option"
                  onClick={() => {
                    setIsOpen(true);
                    setIsOpenDropdown(false);
                    onChange(option.value);
                  }}
                >
                  {option.img && <img src={option.img} alt="img" />}
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </div>
      ) : (
        <button
          type="button"
          className="dropdown-header"
          onClick={toggleDropdown}
        >
          <img
            src={mainImg}
            alt="icon"
            className="adjust-flag"
          />
          <Typography text={dropDownText} className="text-flag" />
          <div className="dropdown" />
          {tooltip && <span className="tooltip">{tooltip}</span>}
        </button>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  tooltip: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  mainImg: PropTypes.string.isRequired,
  selectText: PropTypes.string.isRequired,
  dropDownText: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Dropdown.defaultProps = {
  tooltip: null,
};

export default Dropdown;
