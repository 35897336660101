/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  routesMicroserviceUrl,
} from '../constants';

export const quoteShippingService = (
  originCountryCode, originLocationCode, destinyCountryCode, destinyLocationCode,
  height, width, length, weight, quantity, declaredValue, saleValue, interCode,
) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = '74390816-b8bd-4c38-8c5d-4453256c110e';
  axios.post(`${routesMicroserviceUrl}/quoteShipping`, {
    originCountryCode,
    originLocationCode,
    destinyCountryCode,
    destinyLocationCode,
    height,
    width,
    length,
    weight,
    quantity,
    declaredValue,
    saleValue,
    ...(interCode) ? { interCode } : {},
  })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      reject(e);
    });
});
