/* eslint-disable no-console */
import React from 'react';
import { useSelector } from 'react-redux';

import CreateAccount from './createAccount';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './createAccountScreen.scss';

const CreateAccountScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <CreateAccount />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop fullWidth>
        <div
          id="container-create-account__desktop-mode"
        >
          <CreateAccount />
        </div>
      </ContainerSectionDesktop>
    )
  );
};

export default CreateAccountScreen;
