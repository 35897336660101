/* eslint-disable import/prefer-default-export */

export const paramsLocation = async (name, value, countryCode, locationService) => {
  const fieldMappings = {
    locationName: 'locationName',
    origin: 'origin',
    destiny: 'destiny',
  };

  if (fieldMappings[name] && value) {
    const locationServiceParams = {
      countryCode,
    };

    if (/^\d+$/.test(value)) {
      locationServiceParams.locationCode = value;
    }

    if (/^[a-zA-Z]+$/.test(value)) {
      locationServiceParams[fieldMappings[name]] = value;
    }

    await locationService(locationServiceParams);
  }
};

export const getDataList = (locations, name, countryCode) => {
  if (locations && (name === 'locationName' || name === 'origin' || name === 'destiny')) {
    if (countryCode === '484') {
      const isCodeList = locations.map((location) => `${location.locationCode}-${location.locationName}-${location.municipality}-${location.departmentOrStateName}`);
      const notCodelist = locations.map((location) => `${location.locationName}-${location.municipality}-${location.departmentOrStateName}`);
      return {
        firstList: isCodeList,
        secondList: notCodelist,
      };
    }
    return locations.map((location) => `${location.locationName}-${location.departmentOrStateName}`);
  }
  return [];
};
