/* eslint-disable quote-props */
import React from 'react';
import { useSelector } from 'react-redux';

import Typography from '../../../components/typography';
import ImageRedirectProfile from '../../../components/imageRedirectProfile';

import BillingDataEditMexico from './dataInvoicingData/Mexico';
import BillingDataEditColombia from './dataInvoicingData/Colombia';

import ArrowBack from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './invoicingData.scss';

const InvoicingDataEditScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth.user);

  const billingDataCountries = () => {
    switch (user.countryCode) {
      case '170':
        return (
          <BillingDataEditColombia
            user={user}
            isMobileDevice={isMobileDevice}
          />
        );
      case '484':
        return (
          <BillingDataEditMexico
            user={user}
            isMobileDevice={isMobileDevice}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div id="profile" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className={`header-profile ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
        <div className="content-back">
          <ImageRedirectProfile
            src={ArrowBack}
            url="/perfil/datos-de-facturacion"
          />
          <Typography
            type="superTitle-italic"
            text="Datos de facturación"
            className="button-back"
          />
        </div>
      </div>
      <div className="profile">
        {billingDataCountries()}
      </div>
    </div>
  );
};

export default InvoicingDataEditScreen;
