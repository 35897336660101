const documentTypeList = [
  { label: 'Cédula de Ciudadanía', value: 'CC' },
  { label: 'Cédula de Extranjería', value: 'CE' },
  { label: 'NIT', value: 'NIT' },
  { label: 'Pasaporte', value: 'PP' },
  { label: 'Registro Civil de Nacimiento', value: 'RC' },
  { label: 'Documento de Identificación Extranjero', value: 'DE' },
];

export default documentTypeList;
