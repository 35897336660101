/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import fields from '../../../../../config/quoteShippingFields.json';

import useFields from '../../../../../hooks/useFields';
import useHandleFields from '../../../../../hooks/useHandleFields';
import { stepTwoValidation, returnNumber } from '../../../../../utils/validation/validationsQuoteShipping';
import { findObjectInArray } from '../../../../../utils/filters';
import { replaceCharacter, toCurrencyCop } from '../../../../../utils/strings';
import { colorButton, limitCharacters } from '../../../../../utils/quoteShipping';
import { parametersDeliveryCompanies } from '../../../../../utils/parametersDeliveryCompanies';

import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import Select from '../../../../../components/select';
import Tooltip from '../../../../../components/tooltip';

import IconCm from '../../../../../assets/images/Medidas.svg';
import IconBoxHeight from '../../../../../assets/images/Caja_Alta_Eneable.svg';
import IconBoxHeightFocus from '../../../../../assets/images/Caja_Alta_focus.svg';
import IconBoxWidth from '../../../../../assets/images/Caja_ancha_eneable.svg';
import IconBoxWidthFocus from '../../../../../assets/images/Caja_ancha_Focus.svg';
import IconBoxLength from '../../../../../assets/images/Caja_larga_eneable.svg';
import IconBoxLengthFocus from '../../../../../assets/images/Caja_larga_Focus.svg';
import IconBoxWeight from '../../../../../assets/images/peso.svg';
import IconBoxWeightFocus from '../../../../../assets/images/peso_Focus.svg';

import '../stepsQuoteShipping.scss';
import { getConfigDeliveryCompanyPerUser } from '../../../../../services/user';

const StepTwo = ({
  shippingData,
  errorList,
  setErrorList,
  next,
  setShowModalCollectionService,
  isMobileDevice,
}) => {
  const user = useSelector((state) => state.auth.user);
  const countryCode = useSelector((state) => state.country);
  const { deliveryCompanies } = useSelector((state) => state.globalData);

  const [listFields, setListFields] = useState([]);
  const [formValues, updateFormValues] = useHandleFields({
    height: '',
    width: '',
    length: '',
    weight: '',
    unitOfMeasurement: 'Kg',
    quantity: '',
    declaredValue: '',
  });
  const [focusInput, setFocusInput] = useState(null);
  const [maxValueDeclared, setMaxValueDeclared] = useState(null);
  const [dataConfigUser, setDataConfigUser] = useState([]);

  const imgsByName = {
    height: IconBoxHeight,
    width: IconBoxWidth,
    length: IconBoxLength,
    weight: IconBoxWeight,
  };

  const imgsFocusByName = {
    height: IconBoxHeightFocus,
    width: IconBoxWidthFocus,
    length: IconBoxLengthFocus,
    weight: IconBoxWeightFocus,
  };
  const imgNameFields = (name, listImgs) => listImgs[name];

  const { handleSubmit } = useFields(formValues);

  const getConfigPerUser = async () => {
    if (user) {
      const response = await getConfigDeliveryCompanyPerUser(user._id);
      const configUser = response.map((item) => {
        const hasMessageData = item.deliveryCompaniesMessage?.length > 0;
        const hasPackageData = item.deliveryCompaniesPackage?.length > 0;
        if (hasMessageData || hasPackageData) {
          const config = {};
          if (hasMessageData) {
            config.deliveryCompaniesMessage = item.deliveryCompaniesMessage;
          }
          if (hasPackageData) {
            config.deliveryCompaniesPackage = item.deliveryCompaniesPackage;
          }
          return config;
        }
        return null;
      }).filter((item) => item !== null);
      setDataConfigUser(configUser.length > 0 ? configUser : null);
    }
  };

  useEffect(() => {
    if (user) {
      getConfigPerUser();
    }
  }, []);

  useEffect(() => {
    setListFields(fields.country[countryCode].fieldsQuoteShipping
      .filter((item) => item.step === 2));
  }, [fields.country[countryCode].fieldsQuoteShipping]);

  const conditionsDisabledButton = () => {
    let valueField;
    if (countryCode === '170') {
      valueField = formValues.declaredValue > maxValueDeclared;
    } else {
      const declaredValueReplace = replaceCharacter(formValues.declaredValue);
      valueField = declaredValueReplace > 200000.00 || declaredValueReplace < 19.00;
    }

    return errorList.length > 0 || valueField || formValues.declaredValue < 10000
      || formValues.quantity < 1
      || (formValues.unitOfMeasurement === 'g' && formValues.weight > 150000)
      || (formValues.unitOfMeasurement === 'Kg' && formValues.weight > 150)
      || !formValues.unitOfMeasurement
      || formValues.weight < 1
      || formValues.length > 200
      || formValues.length < 1
      || formValues.width > 200
      || formValues.width < 1
      || formValues.height > 200
      || formValues.height < 1;
  };

  const getMaxValue = () => {
    const maxValue = parametersDeliveryCompanies(
      deliveryCompanies,
      dataConfigUser);
    setMaxValueDeclared(maxValue);
  };

  useEffect(() => {
    listFields.forEach(({ name }) => {
      const value = shippingData[name] || (name === 'unitOfMeasurement' ? 'Kg' : '');
      updateFormValues(name, value);
    });
    getMaxValue();
  }, [shippingData, listFields]);

  const handleFocusInput = (name) => {
    setFocusInput(name);
  };
  const validationCountryCode = (value) => (countryCode === '484'
    ? replaceCharacter(value)
    : returnNumber(value));
  const validationDeclaredValue = (name, value) => (
    name !== 'declaredValue'
      ? returnNumber(value)
      : validationCountryCode(value));

  const formatInputValue = (inputValue, name) => {
    if (countryCode === '484' && name === 'declaredValue') {
      const value = parseFloat(String(inputValue).replaceAll(/,/g, ''));
      setFocusInput(false);
      if (!isNaN(value)) {
        const formattedValue = toCurrencyCop(user, value, countryCode, 'decimal');
        updateFormValues(name, formattedValue);
      }
    }
  };

  const validationFields = (name, value, minValue, maxValue, valueMaxError, valueMinError) => {
    stepTwoValidation(
      errorList,
      setErrorList,
      name,
      validationDeclaredValue(name, value),
      formValues.unitOfMeasurement,
      minValue,
      maxValue,
      valueMaxError,
      valueMinError,
    );
  };
  return (
    <div className="contentStepQuoteShipping stepTwo">
      <section className="content stepTwo">
        { listFields.map(({
          label,
          type,
          name,
          placeholder,
          children,
          annotation,
          isChildrenRight,
          isIconRight,
          isImgLeft,
          minValue,
          valueMaxError,
          valueMinError,
        }) => (
          <div className="containerMeasurementArticle" key={name}>
            { isImgLeft && (
            <img
              className="box"
              src={focusInput === name
                ? imgNameFields(name, imgsFocusByName)
                : imgNameFields(name, imgsByName)}
              alt="img"
            />
            )}
            <Input
              label={label}
              value={formValues[name]}
              type={type}
              iconRigth={isIconRight && IconCm}
              onChange={(value) => {
                updateFormValues(name, value);
                if (name === 'declaredValue') {
                  limitCharacters(name, value, 8, updateFormValues);
                  getMaxValue();
                }
                if (findObjectInArray(name, errorList, 'name')) {
                  validationFields(
                    name,
                    value,
                    minValue,
                    maxValueDeclared,
                    valueMaxError,
                    valueMinError,
                  );
                }
              }}
              onBlur={(e) => {
                formatInputValue(e.target.value, name);
                validationFields(
                  name,
                  validationDeclaredValue(name, e.target.value),
                  minValue,
                  maxValueDeclared,
                  valueMaxError,
                  valueMinError,
                );
              }}
              annotationError={findObjectInArray(name, errorList, 'name')?.error ?? null}
              placeholder={placeholder}
              annotation={annotation}
              childrenRigth={
                isChildrenRight ? (
                  <Tooltip
                    overlayTitle="Valor declarado"
                    overlayText="Recuerda que en caso de pérdida o necesidad de indemnización, el proceso se realizará sobre el valor que haya indicado aquí además del soporte de tu factura."
                    startingPosition="bottom-rigth"
                  />
                ) : null
              }
              onFocus={() => {
                handleFocusInput(name);
              }}
              isFormatNumber={name === 'declaredValue' || name === 'weight'}
            />
            {name === 'weight'
              && children.map((item) => (
                <Select
                  key={item.name}
                  label={item.label}
                  value={formValues[item.name]}
                  onChange={(value) => {
                    updateFormValues(item.name, value);
                    if (findObjectInArray(item.name, errorList, 'name')) {
                      stepTwoValidation(
                        errorList, setErrorList, item.name, value,
                      );
                    }
                  }}
                  startingPosition="bottom-rigth"
                  options={item.options}
                />
              ))}
          </div>
        ))}
      </section>
      <div className="containerButtonsStepsQuoteShipping">
        <Button
          text="Continuar"
          type={colorButton(countryCode)}
          disabled={conditionsDisabledButton()}
          onClick={handleSubmit(() => {
            next(formValues);
            if (isMobileDevice) {
              setShowModalCollectionService(false);
            } else if (countryCode !== '484') setShowModalCollectionService(true);
          })}
        />
      </div>
    </div>
  );
};

StepTwo.propTypes = {
  shippingData: PropTypes.shape({}).isRequired,
  errorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setErrorList: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  setShowModalCollectionService: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

export default StepTwo;
