import { useSelector } from 'react-redux';
import React from 'react';

import IntegrationsScreen from './integrations';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './integrationsScreen.scss';

const MyAddressesScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return isMobileDevice ? (
    <ContainerSectionMobile>
      <IntegrationsScreen />
    </ContainerSectionMobile>
  ) : (
    <ContainerSectionDesktop>
      <IntegrationsScreen />
    </ContainerSectionDesktop>
  );
};

export default MyAddressesScreen;
