const shippingValue = (delivery, collectionService, saleValue, addShippingToCollectionValue) => {
  if (collectionService && saleValue) {
    if (addShippingToCollectionValue) {
      return delivery.collectionCommissionWithRate + delivery.shippingCost + saleValue;
    }
    return delivery.collectionCommissionWithOutRate + saleValue;
  }
  return delivery.shippingCost;
};

const ascOrDesc = (upOrDown) => upOrDown === 'asc' || upOrDown !== 'desc';

export const order = (deliveriesList, filterToSort, upOrDown) => (
  deliveriesList.sort((a, b) => {
    const textA = a[filterToSort];
    const textB = b[filterToSort];
    let orderNumber = 0;

    if (ascOrDesc(upOrDown)) {
      if (textA < textB) {
        orderNumber = -1;
      }
      if (textA > textB) {
        orderNumber = 1;
      }
    }

    if (!ascOrDesc(upOrDown)) {
      if (textA > textB) {
        orderNumber = -1;
      }
      if (textA < textB) {
        orderNumber = 1;
      }
    }

    return orderNumber;
  }));

export const shippingValueSort = (deliveriesList, upOrDown) => (
  deliveriesList.sort((a, b) => {
    const textA = shippingValue(a);
    const textB = shippingValue(b);
    let orderNumber = 0;

    if (ascOrDesc(upOrDown)) {
      if (textA < textB) {
        orderNumber = -1;
      } else if (textA > textB) {
        orderNumber = 1;
      }
    }

    if (!ascOrDesc(upOrDown)) {
      if (textA > textB) {
        orderNumber = -1;
      } else if (textA < textB) {
        orderNumber = 1;
      }
    }

    return orderNumber;
  }));
