/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { toCurrencyCop, userName } from '../../../../utils/strings';

const receiptOfPaymentPdf = (elpResult, user) => {
  let text = `Razón social: ${user.businessName || userName(user.name, user.surname)}`;
  text += `\nNIT: ${user.documentNumber}`;
  text += `\nBanco: ${elpResult.TARJETAbanco}`;
  text += `\nCUS: ${elpResult.CodigoUnico}`;
  text += `\nNúmero de factura: ${elpResult.Referencia}`;
  text += `\nFecha de transacción: ${elpResult.fechatransaccion}`;
  text += '\nDescripción de pago:';
  if (elpResult.mensajesistema) {
    text += `\n ${elpResult.mensajesistema.substring(0, 36)}`;
    text += `\n ${elpResult.mensajesistema.substring(36, elpResult.mensajesistema.length)}`;
  } else {
    text += 'Recarga de saldo';
  }
  text += `\nHora de transacción: ${elpResult.HoraTransaccion}`;
  text += `\nValor: ${toCurrencyCop(user, elpResult.ValorTotalConversion)}`;
  text += `\nEstado: ${elpResult.respuesta.toUpperCase()}`;
  text += `\nIVA: ${toCurrencyCop(user, elpResult.ValorIvaConversion)}`;
  if (elpResult.Ciclo) {
    text += `\nCiclo: ${elpResult.Ciclo}`;
  }
  if (elpResult.ip) {
    text += `\nDirección IP: ${elpResult.ip}`;
  }

  return text;
};

export const receiptOfPaymentTablePdf = (elpResult, user) => {
  let data = [
    { name: 'Razón social:', value: user.businessName || userName(user.name, user.surname) },
    { name: 'NIT:', value: user.documentNumber },
    { name: 'Banco:', value: elpResult.TARJETAbanco },
    { name: 'CUS:', value: elpResult.CodigoUnico },
    { name: 'Numero de factura:', value: elpResult.Referencia },
    { name: 'Fecha de transacción:', value: elpResult.fechatransaccion },
    { name: 'Descripción de pago:', value: 'Recarga de saldo' },
  ];

  const restOfData = [
    { name: 'Hora de transacción:', value: elpResult.HoraTransaccion },
    { name: 'Valor: ', value: toCurrencyCop(user, elpResult.ValorTotalConversion) },
    { name: 'Estado:', value: elpResult.respuesta.toUpperCase() },
    { name: 'IVA:', value: toCurrencyCop(user, elpResult.ValorIvaConversion) },
  ];

  data = [...data, ...restOfData];

  if (elpResult.Ciclo) {
    data.push({ name: 'Ciclo:', value: elpResult.Ciclo });
  }

  if (elpResult.ip) {
    data.push({ name: 'Dirección IP:', value: elpResult.ip });
  }

  return data;
};

export const ReceiptOfPaymentStructurePdf = (elpResult, user) => {
  const styles = StyleSheet.create({
    page_container: {
      color: 'white', textAlign: 'center', margin: '30',
    },
    row: {
      marginTop: '30',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '10 50 10 50',
      border: '2 solid black',
    },
  });

  const doc = (
    <>
      <View style={styles.page_container}>
        <View style={styles.row}>
          <Text>Razón social</Text>
          <Text render={() => (`${user.businessName || userName(user.name, user.surname)}`)} />
        </View>
      </View>
    </>
  );

  return doc;
};

export default receiptOfPaymentPdf;
