import React from 'react';
import PropTypes from 'prop-types';

import './cardsProfileInfo.scss';
import Typography from '../typography';

const CardsProfileInfo = ({
  icon, title, subtitle, iconInfo,
  iconRight, showBorderTop, onClick, onClickInfo,
}) => {
  const handleIconClick = (event) => {
    event.stopPropagation();
    if (onClickInfo) {
      onClickInfo();
    }
  };

  return (
    <button
      onClick={onClick}
      className={`main-container-profile-cards ${showBorderTop ? 'border-top' : ''}`}
      type="button"
    >
      <div className="card-container">
        <div className="data-info">
          <img alt="img" className="iconLeft" src={icon} />
          <div>
            <Typography className="title-info" text={title} />
            <Typography className="subtitle-info" text={subtitle} />
          </div>
        </div>
        <div className="icons-info">
          {iconInfo
          && (
          <button type="button" onClick={handleIconClick}>
            <img
              alt="img"
              className="iconLeft"
              src={iconInfo}
            />
            <span className="tooltip">Completa tus datos</span>
          </button>
          )}
          <img alt="img" className="icon-drop-down" src={iconRight} />
        </div>
      </div>
    </button>
  );
};

CardsProfileInfo.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  iconInfo: PropTypes.string,
  iconRight: PropTypes.string.isRequired,
  showBorderTop: PropTypes.bool,
  onClick: PropTypes.func,
  onClickInfo: PropTypes.func,
};

CardsProfileInfo.defaultProps = {
  iconInfo: null,
  onClick: null,
  showBorderTop: false,
  onClickInfo: null,
};

export default CardsProfileInfo;
