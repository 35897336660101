/* eslint-disable react/forbid-prop-types,react/jsx-one-expression-per-line,no-mixed-operators */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';

import fields from '../../../../../config/quoteShippingFields.json';
import useHandleFields from '../../../../../hooks/useHandleFields';
import { findObjectInArray } from '../../../../../utils/filters';
import { typeDelivery } from '../../../../../constants/general';

import RadioButtonItems from './radioButtonItems/radioButtonItems';
import Index from '../../../../../components/lastDetails/prohibitedItems';
import Tooltip from '../../../../../components/tooltip';
import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import CheckBox from '../../../../../components/checkBox';
import Typography from '../../../../../components/typography';
import {
  stepTwoValidation,
} from '../../../../../components/completingData/quoteCompletingSteps/validation';
import DeliveryCompaniesObservations from '../../../../../components/deliveryCompaniesObservations';

import ColombiaFlag from '../../../../../assets/images/colombia.svg';
import MexicoFlag from '../../../../../assets/images/mexico-flag.svg';
import whatsapp from '../../../../../assets/images/whatsapp.svg';

import './stepTwo.scss';

const StepTwoShipping = ({
  onChange,
  isMobileDevice,
  deliverySelected,
  shippingData,
}) => {
  const countryCode = useSelector((state) => state?.country || null);
  const user = useSelector((state) => state?.auth?.user || null);
  const [listFields, setListFields] = useState([]);
  const [formValues, updateFormValues] = useHandleFields({
    receiverName: '',
    receiverLastName: '',
    receiverEmail: '',
    receiverCellphone: '',
    prohibitedItems: false,
    destinationAddress: '',
    receiverDocumentNumber: '',
    takeToOfficeOrCollection: '',
  });
  const [showProhibitedItems, setShowProhibitedItems] = useState(false);
  const [errorsList, setErrorsList] = useState([]);
  const [showDeliveryAnnottation, setShowDeliveryAnnottation] = useState(false);

  useEffect(() => {
    setListFields(fields.country[countryCode].fieldsInfoShipping.filter((item) => item.step === 2));
  }, [fields.country[countryCode]]);

  const setData = ({
    values,
  }) => ({
    ...formValues,
    values,
  });

  useEffect(() => {
    Object.keys(shippingData).forEach((key) => {
      updateFormValues(key, shippingData[key] || '');
    });
  }, [shippingData]);

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      callback();
    }
  };

  const checkText = (
    <p className="check-text">
      Acepto las
      {' '}
      <span
        onClick={() => setShowDeliveryAnnottation(true)}
        onKeyDown={(e) => handleKeyDown(e, () => setShowDeliveryAnnottation(true))}
        role="button"
        tabIndex="0"
      >
        políticas de la transportadora
      </span>
      {' '}
      y declaro no enviar
      {' '}
      <span
        onClick={() => setShowProhibitedItems(true)}
        onKeyDown={(e) => handleKeyDown(e, () => setShowProhibitedItems(true))}
        role="button"
        tabIndex="0"
      >
        artículos prohibidos
      </span>
    </p>
  );
  const handleDisabledButton = () => !!(
    formValues.prohibitedItems
    && (formValues.receiverName
      && formValues.receiverLastName
      && formValues.receiverCellphone
      && errorsList.length === 0
      || (formValues.takeToOfficeOrCollection === 'takeToOffice' ? formValues.receiverDocumentNumber : formValues.destinationAddress))
  );

  return (
    <div id="container-info-receiver">
      <div
        className={`container-info-receiver-shipping-stepTwo ${
          isMobileDevice ? 'mobile-mode' : 'desktop-mode'
        }`}
      >
        <div className="title">
          <Typography text="Datos del destinatario" type="title" />
        </div>
        <div className="div">
          {listFields
            .filter((it) => it.name !== 'reference')
            .map(
              ({
                label,
                type,
                name,
                placeholder,
                isChildrenRight,
                isImgLeft,
                typeField,
                className,
              }) => typeField === 'input' && (
              <React.Fragment key={name}>
                {name !== 'receiverDocumentNumber' && (
                <Input
                  value={formValues[name]}
                  onChange={(value) => {
                    updateFormValues(name, value);
                    if (findObjectInArray(name, errorsList, 'name')) {
                      stepTwoValidation(
                        countryCode,
                        formValues,
                        errorsList,
                        setErrorsList,
                      );
                    }
                    if (name === 'receiverCellphone') {
                      const limitValue = value.toString();
                      updateFormValues(name, limitValue.slice(0, 10));
                    }
                  }}
                  className={className ?? ''}
                  placeholder={placeholder}
                  label={label}
                  annotationError={
                          findObjectInArray(name, errorsList, 'name')?.error
                          ?? null
                        }
                  type={type}
                  childrenRigth={
                          isChildrenRight && (
                            <Tooltip
                              className="whatsapp"
                              overlayTitle="Notificaciones Whatsapp"
                              overlayText="Escribe el número de celular de tu destinatario, este rebirá notificaciones con el estado de su envío."
                              startingPosition="bottom-right"
                              iconTooltip={whatsapp}
                            />
                          )
                        }
                  iconLeft={
                          isImgLeft
                          && (countryCode === '170' ? ColombiaFlag : MexicoFlag)
                        }
                />
                )}
              </React.Fragment>
              ),
            )}
          <RadioButtonItems
            listFields={listFields}
            deliverySelected={deliverySelected}
            onChange={setData}
            errorsList={errorsList}
            setErrorsList={setErrorsList}
            countryCode={countryCode}
            formValues={formValues}
            updateFormValues={updateFormValues}
          />
        </div>
        {!deliverySelected.officeAddress && (
          <div className="officeType">
            <span>Para este destino no hay entrega en</span>
            <br />
            <span>oficina de la {typeDelivery[countryCode]}</span>
          </div>
        )}
        <div className="container-button-step-two">
          <CheckBox
            onChange={(value) => updateFormValues('prohibitedItems', value)}
            text={checkText}
            checked={!!formValues.prohibitedItems}
          />
          <Button
            text="Solicitar envío "
            type="primary"
            onClick={() => {
              const validation = stepTwoValidation(
                countryCode,
                formValues,
                errorsList,
                setErrorsList,
              );
              if (validation) {
                const data = {
                  action: 'continue',
                  formValues,
                };
                onChange(data);
              }
            }}
            className="strict-width"
            disabled={!handleDisabledButton()}
          />
        </div>
        {showProhibitedItems && (
          <Index
            close={() => setShowProhibitedItems(false)}
            isMobileDevice={isMobileDevice}
            closeBackground
          />
        )}
        {showDeliveryAnnottation && (
          <DeliveryCompaniesObservations
            deliveryCompanyImgUrl={deliverySelected.deliveryCompanyImgUrl}
            deliveryCompanyName={deliverySelected.deliveryCompanyName}
            deliveryCompanyId={deliverySelected.deliveryCompanyId}
            close={() => setShowDeliveryAnnottation(false)}
            isMobileDevice={isMobileDevice}
            countryCode={user?.countryCode || countryCode}
          />
        )}
      </div>
    </div>
  );
};

StepTwoShipping.propTypes = {
  onChange: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  deliverySelected: PropTypes.object.isRequired,
  shippingData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(StepTwoShipping);
