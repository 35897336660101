import React from 'react';

import PropTypes from 'prop-types';

import Modal from '../../../../components/modal';
import Select from '../../../../components/select';
import Button from '../../../../components/button';
import ModalChildren from '../../../../components/modalChildren';

import { options } from '../data';

import IconFilter from '../../../../assets/images/filter_list.svg';

const SearchMobile = ({
  optionSelected, setOptionSelected, inputFilter,
  setModalFilter, setShowOrderCollections, setModalFilterMobile,
}) => (
  <Modal className="search-my-collections">
    <ModalChildren
      className=""
      headerText="Busca tus recaudos"
      bodyText={(
        <div className="search-my-collections__content">
          <Select
            value={optionSelected}
            options={options}
            onChange={setOptionSelected}
            className="select-button"
            selectColor="accent"
          />
          {inputFilter(optionSelected)}
          {/* <Input */}
          {/*  placeholder="Escribe el número" */}
          {/*  onChange={() => {}} */}
          {/*  className="search-input" */}
          {/*  svgClasscss="magnifying-glass" */}
          {/*  onClickIconRight={() => {}} */}
          {/*  changeColorsvg */}
          {/*  inputColor="line-focused-register" */}
          {/*  iconRightClass="icon-right" */}
          {/* /> */}
          <div className="button-mobile">
            <Button
              iconText={(
                <div className="filter-content__title">
                  <p className="text">Filtrar</p>
                  <img src={IconFilter} alt="icon-filter" />
                </div>
              )}
              type="tertiary"
              onClick={() => { setModalFilter(true); setModalFilterMobile(false); }}
              className="filter-content__button"
            />
          </div>
          <div className="last-button-mobile">
            <Button
              text="Ordenar recaudos"
              type="tertiary"
              onClick={() => setShowOrderCollections(true)}
              className="filter-content__button"
            />
          </div>
        </div>
      )}
      close={() => { setModalFilterMobile(false); }}
    />
  </Modal>
);

SearchMobile.propTypes = {
  optionSelected: PropTypes.string.isRequired,
  setOptionSelected: PropTypes.func.isRequired,
  inputFilter: PropTypes.func.isRequired,
  setShowOrderCollections: PropTypes.func.isRequired,
  setModalFilter: PropTypes.func.isRequired,
  setModalFilterMobile: PropTypes.func.isRequired,
};

export default SearchMobile;
