/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import fields from '../../../../config/quoteShippingFields.json';

import StepOne from '../../stepsComponents/stepOne/stepOne';
import Modal from '../../../modal';
import ModalChildren from '../../../modalChildren';

import '../steps.scss';

const StepOneMexico = ({
  close, next, errorList, setErrorList, stepsData,
}) => {
  const { user } = useSelector((state) => state.auth);
  const countryCode = useSelector((state) => state.country);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const [listFields, setListFields] = useState([]);

  useEffect(() => {
    setListFields(fields.country[countryCode]?.fieldsCompletingData.filter((item) => item.step === 1));
  }, [fields.country[countryCode]]);

  const fieldsForm = {
    businessName: user?.businessName || '',
    locationCode: user?.locationCode || '',
    address: user?.address || '',
    locationName: user.locationName || '',
  };

  return (
    <Modal>
      <ModalChildren
        headerText="Completa tus datos (1 de 3)"
        close={close}
        className="stepCompletingData-mexico"
      >
        <StepOne
          errorsList={errorList}
          listFields={listFields}
          subtitle="Se requieren los siguientes datos para procesar tus envíos."
          setErrorsList={setErrorList}
          classListFields="stepCompletingData-mexico__list-fields"
          isMobileDevice={isMobileDevice}
          countryCode={countryCode}
          titleButton="Continuar"
          next={(formValues) => {
            next(formValues);
          }}
          changes={stepsData}
          valuesForm={fieldsForm}
          classButton="button-footer"
        />
      </ModalChildren>
    </Modal>
  );
};

StepOneMexico.propTypes = {
  close: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  errorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setErrorList: PropTypes.func.isRequired,
  stepsData: PropTypes.shape({}).isRequired,
};

export default StepOneMexico;
