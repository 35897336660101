import crypto from 'crypto-js';
import { setHeaderRequest } from '../services/config';
import { nameLocalStorage, secretEncryptionKey } from '../constants';

export const encriptData = async (data) => (
  crypto.AES.encrypt(JSON.stringify(data), secretEncryptionKey).toString()
);

export const decriptData = (string) => (
  crypto.AES.decrypt(string, secretEncryptionKey).toString(crypto.enc.Utf8)
);

export const setCurrentUser = async (data, keep) => {
  const DataCrypto = await encriptData(data);
  if (keep) localStorage.setItem(nameLocalStorage, DataCrypto);
  else sessionStorage.setItem(nameLocalStorage, DataCrypto);
  setHeaderRequest(data.token);
};

export const loginStorage = async () => {
  if (localStorage.getItem(nameLocalStorage)) {
    return {
      data: JSON.parse(decriptData(localStorage.getItem(nameLocalStorage))),
      keep: true,
    };
  } if (sessionStorage.getItem(nameLocalStorage)) {
    return {
      data: JSON.parse(decriptData(sessionStorage.getItem(nameLocalStorage))),
      keep: false,
    };
  }
  return {};
};

export const logout = () => {
  localStorage.removeItem(nameLocalStorage);
  sessionStorage.removeItem(nameLocalStorage);
  setHeaderRequest(null);
};
