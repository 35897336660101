import swal from 'sweetalert';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { countries } from '../../../constants/general';
import { findObjectInArray } from '../../../utils/filters';
import { validateEmailService } from '../../../services/user';
import { validationSteps } from '../createAccount/validations';
import { businessFlagValidation } from '../../../constants/countryCodeValidation';
import { insertOrRemoveErrorFromList, validationValue } from '../../../utils/strings';
import dataIp from '../../../services/ipapi';

import Input from '../../../components/input';
import Button from '../../../components/button';
import Dropdown from '../../../components/dropDown';
import Typography from '../../../components/typography';
import ButtonsSocialMedia from '../../../components/buttonsLoginSocialMedia';

import mexicoFlag from '../../../assets/images/mexico-circle.svg';
import colombiaFlag from '../../../assets/images/colombia-circle.svg';
import { ReactComponent as IconArrowBack } from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './stepsCreateAccount.scss';

const StepOne = ({
  goBack, next, history, userToCreate, errorList, setErrorList,
}) => {
  const [email, setEmail] = useState(userToCreate.email || '');
  const [searchingEmail, setSearchingEmail] = useState(false);
  const [country, setCountry] = useState('170');
  const [ipData, setIpData] = useState({});

  const validateCountry = businessFlagValidation();

  const mexicoValidate = validateCountry.find((item) => item.country === '484');

  const searchEmail = async () => {
    const temporalErrorList = errorList.slice();
    try {
      setSearchingEmail(true);
      const response = await validateEmailService(email);
      if (response.status === 200) {
        insertOrRemoveErrorFromList(temporalErrorList, 'email', 'Este correo ya está registrado', 'insert');
      } else {
        insertOrRemoveErrorFromList(temporalErrorList, 'email', null, 'remove');
        next(email, country);
      }
    } catch {
      swal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error, inténtelo de nuevo.',
        button: 'Intentar de nuevo',
      });
    } finally {
      setSearchingEmail(false);
      setErrorList(temporalErrorList);
    }
  };

  const flagValidate = (countryCode) => {
    if (ipData.country === 'MX' || countryCode === '484') {
      return { flag: mexicoFlag, alfa2code: 'MX', name: 'México' };
    }
    return { flag: colombiaFlag, alfa2code: 'CO', name: 'Colombia' };
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await dataIp();
      setIpData(result);
    };
    fetchData();
  }, []);

  return (
    <div className="stepCreateAccount">
      <div className="top">
        <IconArrowBack onClick={goBack} className="arrowBack" />
        <Typography type="superTitle-italic" text="Crea tu cuenta" />
        <div />
      </div>
      {mexicoValidate && mexicoValidate.enabled && (
        <Dropdown
          value={country}
          onChange={(value) => {
            setCountry(value);
            validationSteps({
              errorList,
              setErrorList,
              country: value,
              countries,
            });
          }}
          selectText="Selecciona una país"
          mainImg={flagValidate(country).flag}
          dropDownText={flagValidate(country).alfa2code}
          tooltip={flagValidate(country).name}
          options={countries}
        />
      )}
      <div className="bottom">
        <Input
          label="Correo electrónico"
          className="inputUserToCreate"
          value={email}
          onChange={(value) => {
            setEmail(value);
            if (findObjectInArray('email', errorList, 'name')) {
              validationSteps({ errorList, setErrorList, email: value });
            }
          }}
          onBlur={() => validationSteps({ errorList, setErrorList, email })}
          annotation="Tu correo no podrá ser editado posteriormente"
          annotationError={
            findObjectInArray('email', errorList, 'name')?.error || null
          }
          placeholder="usuario@email.com"
        />
        <Button
          text="Continuar"
          type={ipData.country === 'CO' ? 'primary-mx' : 'primary'}
          disabled={email === '' || !validationValue(email, 'email').correct}
          loading={searchingEmail}
          onClick={searchEmail}
        />

        <div className="separator">
          <hr />
          <span>o</span>
          <hr />
        </div>

        <Typography type="paragraph" className="redirectToLogin">
          Regístrate con tus redes sociales
        </Typography>
        <ButtonsSocialMedia />
        <Typography type="paragraph" className="redirectToLogin">
          ¿Ya tienes cuenta?
          <button
            type="button"
            onClick={() => history?.replace('/ingreso')}
            className="linkButton"
          >
            Inicia sesión aquí
          </button>
        </Typography>
      </div>
    </div>
  );
};

StepOne.propTypes = {
  next: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  userToCreate: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  errorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setErrorList: PropTypes.func.isRequired,
};

export default StepOne;
