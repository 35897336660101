import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ReactComponent as IconPagination } from '../../assets/images/icon-pagination.svg';

import './pagination.scss';

const filterOptions = [
  {
    name: 'sort',
    title: 'Orden resultados',
    options: [
      {
        value: -1,
        text: 'Más reciente primero',
      },
      {
        value: 1,
        text: 'Más antiguo primero',
      },
    ],
  },
  {
    name: 'itemsPerPage',
    title: 'Ver resultados',
    options: [
      {
        value: 25,
        text: '25 resultados',
      },
      {
        value: 50,
        text: '50 resultados',
      },
      {
        value: 75,
        text: '75 resultados',
      },
      {
        value: 100,
        text: '100 resultados',
      },
    ],
  },
];

const Pagination = ({
  filters = {},
  handleChangeFilters = () => null,
  totalItems,
  disabledLoading,
  filterOptionsToShow = ['itemsPerPage', 'sort'],
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const firstSelectNumber = (((filters.actualPage - 1) * filters.itemsPerPage) + 1);
  const lastSelectNumber = filters.actualPage * filters.itemsPerPage < totalItems
    ? filters.actualPage * filters.itemsPerPage : totalItems;

  const disabledBack = filters.actualPage === 1;
  const disabledNext = lastSelectNumber === totalItems;

  return (
    <div className="paginationComponent">
      {totalItems > 0 && (
        <>
          <div className="paginationState" onClick={() => setShowFilters(!showFilters)}>
            {`${firstSelectNumber}-${lastSelectNumber} de ${totalItems}`}
          </div>
          <div className={`filterSelect ${showFilters ? 'visible' : ''}`} onClick={() => null}>
            {filterOptions.map((filterOption) => (
              filterOptionsToShow?.includes(filterOption.name) && (
                <>
                  <span className="filterTitle">{filterOption.title}</span>
                  {filterOption.options.map((option) => (
                    <span
                      className={`filterItem ${filters[filterOption.name] === option.value ? 'selected' : ''}`}
                      onClick={() => {
                        const newFilter = JSON.parse(JSON.stringify(filters));
                        newFilter[filterOption.name] = option.value;
                        handleChangeFilters(newFilter);
                      }}
                    >
                      {option.text}
                    </span>
                  ))}
                </>
              )
            ))}
          </div>
          <IconPagination
            onClick={() => !disabledBack && !disabledLoading && (
              handleChangeFilters({ ...filters, actualPage: filters.actualPage - 1 })
            )}
            className={`pagination back ${disabledBack || disabledLoading ? 'disabled' : ''}`}
          />
          <IconPagination
            onClick={() => !disabledNext && !disabledLoading && (
              handleChangeFilters({ ...filters, actualPage: filters.actualPage + 1 })
            )}
            className={`pagination ${disabledNext || disabledLoading ? 'disabled' : ''}`}
          />
        </>
      )}
    </div>
  );
};

Pagination.propTypes = {
  filters: PropTypes.shape({
    itemsPerPage: PropTypes.oneOf([25, 50, 75, 100]),
    sort: PropTypes.oneOf([1, -1]),
    actualPage: PropTypes.number,
  }).isRequired,
  handleChangeFilters: PropTypes.func.isRequired,
  totalItems: PropTypes.number,
  disabledLoading: PropTypes.bool,
  filterOptionsToShow: PropTypes.arrayOf(PropTypes.string),
};

Pagination.defaultProps = {
  filterOptionsToShow: ['itemsPerPage', 'sort'],
  disabledLoading: false,
};

Pagination.defaultProps = {
  totalItems: 0,
};

export default Pagination;
