export const orderListDeliveries = [
  {
    value: 'upward',
    label: 'Ascendente A-Z',
  },
  {
    value: 'falling',
    label: 'Descendente Z-A',
  },
  {
    value: 'highestToLowest',
    label: 'De mayor a menor precio',
  },
  {
    value: 'lowestToHighest',
    label: 'De menor a mayor precio',
  },
  {
    value: 'bestGrade',
    label: 'Mejor calificación',
  },
  {
    value: 'deliveryTime',
    label: 'Tiempo de entrega',
  },
];

export const newOrderListDeliveries = [
  {
    value: 'lowestToHighest',
    label: 'Más económico',
  },
  {
    value: 'bestGrade',
    label: 'Mejor calificación',
  },
  {
    value: 'deliveryTime',
    label: 'Menor tiempo',
  },
];
export const newSortDeliveries = {
  lowestToHighest: ['shippingValue', 'shippingValueSortAsc'],
  bestGrade: ['score', 'desc'],
  deliveryTime: ['shippingTime', 'asc'],
};

export const sortDeliveries = {
  upward: ['deliveryCompanyName', 'asc'],
  falling: ['deliveryCompanyName', 'desc'],
  highestToLowest: ['shippingValue', 'shippingValueSortDesc'],
  lowestToHighest: ['shippingValue', 'shippingValueSortAsc'],
  bestGrade: ['score', 'desc'],
  deliveryTime: ['shippingTime', 'asc'],
};
export const optionsProductTypeArray = [
  {
    value: 'textile',
    text: 'Moda, ropa y textiles',
    checked: false,
  },
  {
    value: 'tecnology',
    text: 'Tecnología y electrónicos',
    checked: false,
  },
  {
    value: 'beauty',
    text: 'Belleza y cuidado personal',
    checked: false,
  },
  {
    value: 'toy',
    text: 'Juguetería',
    checked: false,
  },
  {
    value: 'stationery',
    text: 'Papelería, arte y cultura',
    checked: false,
  },
  {
    value: 'sport',
    text: 'Artículos deportivos',
    checked: false,
  },
  {
    value: 'food',
    text: 'Alimentos no perecederos',
    checked: false,
  },
  {
    value: 'forVehicles',
    text: 'Artículos para vehículos',
    checked: false,
  },
  {
    value: 'forPets',
    text: 'Artículos para mascotas',
    checked: false,
  },
  {
    value: 'forHome',
    text: 'Artículos de hogar',
    checked: false,
  },
  {
    value: 'imitationJewerly',
    text: 'Accesorios y bisutería',
    checked: false,
  },
  {
    value: 'others',
    text: 'Otros',
    checked: false,
  },
];
