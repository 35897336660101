/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { toCurrencyCop } from '../../../../../utils/strings';
import DeliveryTime from '../../../../../constants/deliveryTime';
import PickupTag from '../../../../../components/pickupTag';
import DeliveryCompaniesObservations from '../../../../../components/deliveryCompaniesObservations';
import DeliveryImage from '../../../../../constants/handleImageClick';
import DeliveryValue from '../../../../../constants/deliveryValue';

import IconStar from '../../../../../assets/images/star.svg';
import IconDropUp from '../../../../../assets/images/Dropup_black.svg';

import '../deliveryCardToList.scss';

const DeliveryCardToList = ({
  id,
  deliveryName,
  deliveryImg,
  deliveryTime,
  shippingValue,
  shippingRealValue,
  collectionValue,
  deliveryScore,
  delivery,
  details,
  routeType,
  pickupService,
  officeAddress,
  routeTypeValidation,
  handleShowDetail,
}) => {
  const isMobileDevice = useSelector(
    (state) => state.mediaQuery.isMobileDevice,
  );
  const user = useSelector((state) => state.auth.user);
  const countryCode = useSelector((state) => state?.country || null);
  const [showDeliveryAnnottation, setShowDeliveryAnnottation] = useState(false);

  return (
    <div className="container__content-top-and-content-bottom">
      <div className="content-top__delivery-card-to-list">
        <div className="container-delivery-info">
          <DeliveryImage
            deliveryImg={deliveryImg}
            deliveryName={deliveryName}
            setShowDeliveryAnnottation={setShowDeliveryAnnottation}
          />
          <div className="content-delivery-info">
            <span className="delivery-name">{deliveryName}</span>
            <DeliveryTime deliveryTime={deliveryTime} />
            <div className="delivery-score">
              {countryCode === '170' ? (
                <div className="routeType">
                  {routeTypeValidation(routeType)}
                </div>
              ) : (
                <>
                  {pickupService && <PickupTag pickup={pickupService} />}
                  {officeAddress && <PickupTag />}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="shipping-value">
          {collectionValue > 0 && details && (
            <button
              type="button"
              className="see-more"
              onClick={(e) => handleShowDetail(e)}
            >
              {delivery && delivery.id === id ? 'Ver menos' : 'Ver más'}
              <img
                src={IconDropUp}
                className={delivery && delivery.id === id ? 'up' : 'down'}
                alt=""
              />
            </button>
          )}
          {shippingRealValue ? (
            <DeliveryValue
              shippingValue={shippingValue}
              shippingRealValue={shippingRealValue}
              user={user}
            />
          ) : (
            <div className="shipping-value__mount-score">
              <span>
                {toCurrencyCop(user, Math.floor(shippingValue), countryCode)}
              </span>
              <div className="score">
                <img src={IconStar} alt="score-img" height="16px" />
                <p>{deliveryScore}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {showDeliveryAnnottation && (
        <DeliveryCompaniesObservations
          deliveryCompanyImgUrl={deliveryImg}
          deliveryCompanyName={deliveryName}
          deliveryCompanyId={id}
          close={() => setShowDeliveryAnnottation(false)}
          isMobileDevice={isMobileDevice}
          countryCode={user?.countryCode || countryCode}
        />
      )}
      <hr />
    </div>
  );
};

DeliveryCardToList.propTypes = {
  id: PropTypes.string.isRequired,
  deliveryName: PropTypes.string.isRequired,
  deliveryImg: PropTypes.string.isRequired,
  deliveryTime: PropTypes.number.isRequired,
  shippingValue: PropTypes.number.isRequired,
  shippingRealValue: PropTypes.number,
  collectionValue: PropTypes.number,
  deliveryScore: PropTypes.number.isRequired,
  delivery: PropTypes.any,
  details: PropTypes.bool,
  routeType: PropTypes.string.isRequired,
  pickupService: PropTypes.bool.isRequired,
  officeAddress: PropTypes.string,
  routeTypeValidation: PropTypes.func.isRequired,
  handleShowDetail: PropTypes.func.isRequired,
};

DeliveryCardToList.defaultProps = {
  collectionValue: null,
  delivery: null,
  details: true,
  shippingRealValue: null,
  officeAddress: null,
};

export default DeliveryCardToList;
