import swal from 'sweetalert';

/**
 * @param {*} text text is a string, the text the user will see like a subtitle
 * @param {*} title title is a string, the title the user will see and its strong and large
 * @param {*} iconType iconType is a string, the iconType the user
 *  will see, its default value its error, valids value error, success, warning and info
 * @returns swal from sweeeralert
 */
const Swal = ({ text, title, iconType }) => {
  swal({
    icon: iconType || 'error',
    title: title || 'Hubo un error en la búsqueda',
    text: text || 'No se encontraron datos',
  });
};

export default Swal;
