import saveAsCSV from './csv';

const convertDecimal = (number) => (Number.parseFloat(number).toFixed(2));

const filter = (filterSelected) => {
  switch (filterSelected) {
    case 'price':
      return 'Menor precio';
    case 'time':
      return 'Menor tiempo';
    case 'bestService':
      return 'Mejor servicio';
    default:
      return 'N/A';
  }
};

const valueCollectionCommission = (collectionService,
  addShippingToCollectionValue,
  collectionCommissionWithRate,
  collectionCommissionWithOutRate,
) => {
  if (collectionService) {
    if (addShippingToCollectionValue) {
      return convertDecimal(collectionCommissionWithRate);
    }
    return convertDecimal(collectionCommissionWithOutRate);
  }
  return 'No aplica';
};
/* eslint-disable no-plusplus */
const dataFormatMultipleShippings = async (shipments, filterSelected) => {
  const csvData = [];
  const totalShipments = shipments.length;

  for (let i = 0; i < totalShipments; i++) {
    const currentShipment = shipments.shift();

    csvData.push({
      'Producto o Referencia': currentShipment.articleDescription,
      Origen: `${currentShipment.shippingOriginDepartamentName}-${currentShipment.shippingOriginName}`,
      'Nombre del remitente': currentShipment.senderName,
      'Tipo de Identificacion Remitente': currentShipment.senderIdentificationType,
      'Identificacion del remitente': currentShipment.senderIdentificationNumber,
      'Celular del Remitente': currentShipment.senderCellPhone,
      'Direccion Remitente': currentShipment.collectionOrTakeToOffice === 'takeToOffice' ? 'Lo llevaré a la oficina' : currentShipment.senderAddress,
      Destino: `${currentShipment.shippingDestinyDepartamentName}-${currentShipment.shippingDestinyName}`,
      Destinatario: currentShipment.receiverName,
      'Celular del destinatario': currentShipment.receiverCellPhone,
      'Direccion Destinatario': currentShipment.receiverAddress,
      Cantidad: currentShipment.quantityOfArticles,
      'Dimensiones(cm)': `${currentShipment.articleHeight}x${currentShipment.articleWidth}x${currentShipment.articleLength}`,
      'Peso Volumen (kg)': currentShipment.deliveryCompanySelected.realWeightVolume,
      'Peso (kg)': currentShipment.articleWeight,
      'Peso Facturado (kg)': currentShipment.deliveryCompanySelected.realWeightVolume,
      'servicio adicional': currentShipment.collectionService ? 'SI' : 'NO',
      'Valor a recaudar': currentShipment.collectionService ? convertDecimal(currentShipment.saleValue) : 'No aplica',
      'Valor declarado': convertDecimal(currentShipment.declaredValue),
      'Valor de la Venta': convertDecimal(currentShipment.saleValue),
      Transportadora: currentShipment.deliveryCompanySelected.deliveryCompanyName,
      'Valor total del servicio': currentShipment.collectionService ? `${convertDecimal(currentShipment.deliveryCompanySelected.shippingCost + currentShipment.deliveryCompanySelected.collectionCommissionWithRate)}` : convertDecimal(currentShipment.deliveryCompanySelected.shippingCost),
      'Valor del flete': convertDecimal(currentShipment.deliveryCompanySelected.shippingCost),
      'Valor del seguro': currentShipment.deliveryCompanySelected.insuranceValue,
      'Valor de la comision de recaudo': valueCollectionCommission(currentShipment.collectionService, currentShipment.addShippingToCollectionValue, currentShipment.deliveryCompanySelected.collectionCommissionWithRate, currentShipment.deliveryCompanySelected.collectionCommissionWithOutRate),
      'Valor Flete tarifa Plena': convertDecimal(currentShipment.deliveryCompanySelected.shippingCost),
      'Tipo de trayecto': currentShipment.deliveryCompanySelected.routeType,
      'Tiempo promesa de entrega en dias habiles': currentShipment.deliveryCompanySelected.pickupTime < 1440 ? 1 : currentShipment.deliveryCompanySelected.pickupTime / 1440,
      Calificación: currentShipment.deliveryCompanySelected.score,
      Criterio: filter(filterSelected),
    });
  }
  return csvData;
};

const transformToCsv = async (data, filterSelected) => {
  const convertDataToCSV = await dataFormatMultipleShippings(data, filterSelected);
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;

  const fileName = `Cotización-${formattedDate}.csv`;
  return saveAsCSV(convertDataToCSV, fileName);
};

export default transformToCsv;
