import { getShipmentPaidService } from '../../../../services/billing';

/* eslint-disable import/prefer-default-export */
export const getShippingForList = async ({
  query, showError = null, showSuccess = null,
}) => {
  try {
    const data = await getShipmentPaidService(query);
    if ((data?.length <= 0 || !data) && showError) showError();
    if (showSuccess) showSuccess(data);
    return data;
  } catch (error) {
    if (showError) showError();
  }

  return null;
};
