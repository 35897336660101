import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import PositionHandler from './inputs';
import Typography from '../../../../components/typography';

import { validateData } from './validate';
import createAndUpdateNegotiationService from './service';

import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import './negotiationSchema.scss';

const componetKey = (index) => (index + 1);

const UpdateNegotiation = ({
  negotiationSchema, deliverySelected, setCurrentStep, user, updateUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorsList, setErrorsList] = useState([]);
  const [creationData, setCreationData] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const supportNegotiationSchema = negotiationSchema?.inputs;

  useEffect(() => {
    const companySelected = user?.transportersWithNegotiation.find(
      (delivery) => delivery.deliveryCompanyId === deliverySelected._id);
    if (companySelected) {
      supportNegotiationSchema.map((input) => {
        if (input.value === null || input.value === 0 || input.value) {
          companySelected[input.propertyName] = input.value;
        }
        return null;
      });
    }
    setCreationData(companySelected);
  }, []);

  const validateUpdate = async () => {
    const keys = Object.keys(creationData);
    const companyToUpdate = user.transportersWithNegotiation.find(
      (negotiation) => negotiation.deliveryCompanyId === deliverySelected._id);
    const newData = {};
    keys.forEach((key) => {
      if (creationData[key] && creationData[key] !== companyToUpdate[key]) {
        newData[key] = creationData[key];
      }
    });

    const validated = validateData({
      errorsList, setErrorsList, data: newData, inputs: supportNegotiationSchema,
    });

    if (validated.correct) {
      const negotiation = {
        deliveryCompany: deliverySelected._id,
        dataToUpdate: {
          ...validated.data,
        },
      };
      const userNegotiation = await createAndUpdateNegotiationService(negotiation);

      if (userNegotiation.email) {
        return { message: 'success', user: { ...userNegotiation } };
      }
      return { message: 'error' };
    }

    return {};
  };

  if (!Number.isInteger(supportNegotiationSchema?.length || 0 / 2)) {
    supportNegotiationSchema.push({ leftOrRigth: 1 });
  }

  return (
    <div className="inputs">
      <Typography
        text="Todos los campos son obligatorios"
        type="value"
        className="create-negotiation-title"
      />
      <div className="inputs-body">
        {supportNegotiationSchema?.map((input, index) => {
          if (Number.isInteger(input.leftOrRigth / 2)) {
            return (
              <PositionHandler
                key={componetKey(index || 0)}
                inputs={supportNegotiationSchema}
                creationData={creationData}
                setCreationData={setCreationData}
                position={index}
                errorsList={errorsList}
              />
            );
          }
          return null;
        })}
      </div>
      <div className="input-buttons">
        <Button
          onClick={() => setCurrentStep(null)}
          text="Cancelar vinculación"
          type="secondary"
        />
        <Button
          onClick={async () => {
            setLoading(true);
            const validated = await validateUpdate();
            if (validated.message === 'success') {
              updateUser(validated.user);
              setShowModal(true);
              setTimeout(() => (setCurrentStep(null)), 1500);
              setLoading(false);
            }
            if (validated.message === 'error') {
              setShowModal(false);
              setShowErrorModal(true);
              setLoading(false);
            }
            setLoading(false);
          }}
          text="Vincular Transportadora"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          loading={loading}
        />
      </div>
      {showModal && (
        <Modal closeBackground={() => setShowModal(false)}>
          <div className="modal-wrapper">
            <Typography
              text={`Se ha actualizado ${deliverySelected.name} exitosamente`}
              type="superTitle-italic"
              className="create-success-title"
            />
          </div>
        </Modal>
      )}
      {showErrorModal && (
        <Modal closeBackground={() => setShowErrorModal(false)}>
          <div className="error-modal-wrapper">
            <div className="error-header">
              <Typography
                text={`¡Ups! hubo un problema al actualizar ${deliverySelected.name} `}
                type="superTitle-italic"
                className="error-header-text"
              />
              <div className="close-wrapper" onClick={() => setShowErrorModal(false)}>
                <div className="close" />
              </div>
            </div>
            <Typography
              text="Inténtalo más tarde o comunícate con un asesor de mipaquete.com"
              type="value"
              className="error-description"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

UpdateNegotiation.propTypes = {
  negotiationSchema: PropTypes.shape().isRequired,
  deliverySelected: PropTypes.shape().isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

export default (UpdateNegotiation);
