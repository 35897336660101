/* eslint-disable max-len */
import React from 'react';
import { compose } from 'redux';

import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import iconTooltipGray from '../../../../assets/images/tooltip-gray.svg';
import iconTooltipOrange from '../../../../assets/images/Tooltip.svg';

import Tooltip from '../../../../components/tooltip';
import Typography from '../../../../components/typography';

import './ticketDetail.scss';

import { hideAccountNumbers, toCurrencyCop, bankNameFormat } from '../../../../utils/strings';

const HeaderTicketDetail = ({ totalData, globalBankData }) => {
  const feeBank = Object.entries(globalBankData.bankTransferFee).find(([bank]) => bank === totalData?.bankInformation?.bank.toLowerCase());
  const user = useSelector((state) => state?.auth?.user || null);
  return (
    <div className="header-text">
      <div className="content-header__block">
        <div className="title-field small-block">
          <Typography type="field-title-dark" text="Total de guías:" />
          <Typography type="header-quote-gray" text={totalData?.totalGuides} className="secondary-field" />
        </div>
        <div className="title-field small-block">
          <Typography type="field-title-dark" text="Entregadas:" />
          <Typography type="header-quote-gray" text={totalData?.totalDeliveredGuides} className="secondary-field" />
        </div>
        <div className="title-field small-block">
          <Typography type="field-title-dark" text="Devueltas:" />
          <Typography type="header-quote-gray" text={totalData?.totalReturnedGuides} className="secondary-field" />
        </div>
      </div>

      <div className="content-header__block">
        <div className="title-field high-block">
          <Typography type="field-title-dark" text="Valor recaudado:" />
          <Typography type="header-quote-gray" text={toCurrencyCop(user, totalData?.totalValueCollection)} className="secondary-field" />
        </div>
        <div className="title-field high-block tooltip">
          <Typography type="field-title-dark" text="Valor descontado:" />
          <Typography type="header-quote-gray" text={toCurrencyCop(user, totalData?.totalDiscountValue)} className="secondary-field" />
          <Tooltip
            overlayText="Incluye costo del recaudo más el costo de las devoluciones"
            iconTooltip={iconTooltipGray}
            iconTooltipHover={iconTooltipOrange}
            startingPosition="bottom-rigth"
          />
        </div>
        <div className="title-field high-block">
          <Typography type="field-title-dark" text="Valor transferido:" />
          <Typography type="header-quote-gray" text={toCurrencyCop(user, totalData?.totalTransferred)} className="secondary-field" />
        </div>
      </div>

      <div className="content-header__block">
        <Typography type="field-title-dark" text="Beneficiario" className="title-field margin user-data" />
        <Typography type="header-quote-gray" text={totalData?.bankInformation?.beneficiaryName || totalData?.bankInformation?.nameBeneficiary} className="secondary-field title-field margin user-data" />
        <Typography type="header-quote-gray" text={`${totalData?.bankInformation?.typeId || totalData?.bankInformation?.nitTypeBeneficiary}: ${totalData?.bankInformation?.numberId || totalData?.bankInformation?.numberAccount}`} className="title-field margin user-data" />
      </div>

      <div className="content-header__block">
        <div className="title-field high-block tooltip">
          <Typography type="field-title-dark" text="Banco:" />
          <Typography type="header-quote-gray" text={bankNameFormat(totalData?.bankInformation?.bank)} className="secondary-field" />
          <Typography type="header-quote-gray" text={`(desembolso ${toCurrencyCop(user, feeBank[1])})`} className="secondary-field" />
          <Tooltip
            overlayText="Dependiendo del banco, el valor de transferencia es diferente"
            iconTooltip={iconTooltipGray}
            iconTooltipHover={iconTooltipOrange}
            startingPosition="top-rigth"
          />
        </div>
        <div className="title-field high-block">
          <Typography type="field-title-dark " text="Tipo de cuenta:" />
          <Typography type="header-quote-gray" text={(totalData.bankInformation.typeAccount || totalData.bankInformation.accountType)} className="secondary-field" />
        </div>
        <div className="title-field high-block">
          <Typography type="field-title-dark" text="N° de cuenta:" />
          <Typography type="header-quote-gray" text={hideAccountNumbers(totalData?.bankInformation?.accountNumber || totalData?.bankInformation?.numberAccount)} className="secondary-field" />
        </div>
      </div>
    </div>
  );
};

HeaderTicketDetail.propTypes = {
  totalData: PropTypes.shape({
    totalGuides: PropTypes.number,
    totalDeliveredGuides: PropTypes.number,
    totalReturnedGuides: PropTypes.number,
    totalTransferred: PropTypes.number,
    totalValueCollection: PropTypes.number,
    totalDiscountValue: PropTypes.number,
    bankInformation: {
      bank: PropTypes.string,
      typeAccount: PropTypes.string,
      numberAccount: PropTypes.string,
      nameBeneficiary: PropTypes.string,
      nitBeneficiary: PropTypes.number,
    },
  }),
  globalBankData: PropTypes.shape(),
};

HeaderTicketDetail.defaultProps = {
  totalData: {
    totalGuides: 0,
    totalDeliveredGuides: 0,
    totalReturnedGuides: 0,
    totalTransferred: 0,
    totalValueCollection: 0,
    totalDiscountValue: 0,
    bankInformation: {
      bank: '',
      typeAccount: '',
      numberAccount: '',
      nameBeneficiary: '',
      nitBeneficiary: 0,
    },
  },
  globalBankData: {},
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  globalBankData: state.globalData.bankTransferFee || {},
});

export default compose(
  connect(mapStateToProps),
)(HeaderTicketDetail);
