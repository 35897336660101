// eslint-disable-next-line import/prefer-default-export
export const dataTable = {
  collectionReports: [
    {
      paymentDate: '12/31/2022',
      numberOfGuides: '125',
      totalValuePaid: 1500000,
      shippings: [
        {
          deliveryCompanyId: 'aedrfqw34ffg23123',
          guideNumber: '02143',
          code: 12334,
          state: 'Entregado',
          discountedValue: 1500000,
          valueCollected: 1500000,
          transferredValue: 1500000,
          paymentDate: '12/31/2022',
          receiverName: 'Sebastian Meneses',
          bankData: {
            bank: 'BANCOLOMBIA (Desembolso: $0)',
            typeAccount: 'ahorros',
            numberAccount: '91210235065',
            nameBeneficiary: 'Jhorman Olivero',
            nitBeneficiary: '1012465268',
            nitTypeBeneficiary: 'CC',
          },
        },
        {
          deliveryCompanyId: 'aedrfqw34ffg23123',
          guideNumber: '02143',
          code: 12334,
          state: 'Devuelto',
          discountedValue: 1500000,
          valueCollected: 1500000,
          transferredValue: 1500000,
          paymentDate: '12/31/2022',
          receiverName: 'Sebastian Meneses',
          bankData: {
            bank: 'BANCOLOMBIA (Desembolso: $0)',
            typeAccount: 'ahorros',
            numberAccount: '91210235065',
            nameBeneficiary: 'Jhorman Olivero',
            nitBeneficiary: '1012465268',
            nitTypeBeneficiary: 'CC',
          },
        },
      ],
    },
    {
      paymentDate: '12/31/2022',
      numberOfGuides: '125',
      totalValuePaid: 1500000,
      shippings: [
        {
          deliveryCompanyId: 'aedrfqw34ffg23123',
          guideNumber: '02143',
          code: 12334,
          state: 'Entregado',
          discountedValue: 1500000,
          valueCollected: 1500000,
          transferredValue: 1500000,
          paymentDate: '12/31/2022',
          receiverName: 'Sebastian Meneses',
          bankData: {
            bank: 'BANCOLOMBIA (Desembolso: $0)',
            typeAccount: 'ahorros',
            numberAccount: '91210235065',
            nameBeneficiary: 'Jhorman Olivero',
            nitBeneficiary: '1012465268',
            nitTypeBeneficiary: 'CC',
          },
        },
        {
          deliveryCompanyId: 'aedrfqw34ffg23123',
          guideNumber: '02143',
          code: 12334,
          state: 'Devuelto',
          discountedValue: 1500000,
          valueCollected: 1500000,
          transferredValue: 1500000,
          paymentDate: '12/31/2022',
          receiverName: 'Sebastian Meneses',
          bankData: {
            bank: 'BANCOLOMBIA (Desembolso: $0)',
            typeAccount: 'ahorros',
            numberAccount: '91210235065',
            nameBeneficiary: 'Jhorman Olivero',
            nitBeneficiary: '1012465268',
            nitTypeBeneficiary: 'CC',
          },
        },
      ],
    },
  ],
  totalItems: 24,
};
export const dataShipments = [
  {
    _id: '6094131b5c836c3ff8c6be81',
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    courtDate: '2021-05-24T05:00:00.000Z',
    deliveryCompany: '6080a75ef08a770ddd9724fd',
    deliveryCompanyName: 'ENVIA',
    guideNumber: '14109450133',
    paymentType: 102,
    price: 17630,
    state: 'Devuelto',
    valueCollection: 160000,
    receiverName: 'Wilmar',
    receiverSurname: 'Wilmar',
    transferCost: 142370,
    discountValue: 17630,
    code: 220477,
  },
  {
    _id: '6094131b8842e228264f1263',
    price: 20350,
    state: 'Entregado',
    deliveryCompanyName: 'SERVIENTREGA',
    paymentType: 102,
    valueCollection: 130000,
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    deliveryCompany: '5fceb46c8229797cb139a7aa',
    guideNumber: '2105077744',
    courtDate: '2021-05-24T05:00:00.000Z',
    receiverName: 'Edwin',
    receiverSurname: 'Guarata',
    transferCost: 109650,
    discountValue: 20350,
    code: 220476,
  },
  {
    _id: '6094131c5c836c1485c6be83',
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    courtDate: '2021-05-24T05:00:00.000Z',
    deliveryCompany: '5cb0f5fd244fe2796e65f9fc',
    deliveryCompanyName: 'COORDINADORA',
    guideNumber: '98130650910',
    paymentType: 102,
    price: 15370,
    state: 'Devuelto',
    valueCollection: 240000,
    receiverName: 'Hansel ',
    receiverSurname: 'Mendoza',
    transferCost: 224630,
    discountValue: 15370,
    code: 220484,
  },
  {
    _id: '6094131c8842e25f174f1265',
    price: 17680,
    state: 'Entregado',
    deliveryCompanyName: 'COORDINADORA',
    paymentType: 102,
    valueCollection: 160000,
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    deliveryCompany: '5cb0f5fd244fe2796e65f9fc',
    guideNumber: '98130650909',
    courtDate: '2021-05-24T05:00:00.000Z',
    receiverName: 'Hedison ',
    receiverSurname: 'Jimenez',
    transferCost: 142320,
    discountValue: 17680,
    code: 220483,
  },
  {
    _id: '6094131d5c836c136fc6be84',
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    courtDate: '2021-05-24T05:00:00.000Z',
    deliveryCompany: '6080a75ef08a770ddd9724fd',
    deliveryCompanyName: 'ENVIA',
    guideNumber: '14109450137',
    paymentType: 102,
    price: 14250,
    state: 'Devuelto',
    valueCollection: 130000,
    receiverName: 'Horacio Antonio ',
    receiverSurname: 'Medina',
    transferCost: 115750,
    discountValue: 14250,
    code: 220488,
  },
  {
    _id: '6094131da0a25b239e57fa0d',
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    courtDate: '2021-05-24T05:00:00.000Z',
    deliveryCompany: '6080a75ef08a770ddd9724fd',
    deliveryCompanyName: 'ENVIA',
    guideNumber: '014109450135',
    paymentType: 102,
    price: 16450,
    state: 'Devuelto',
    valueCollection: 130000,
    receiverName: 'Eliecer ',
    receiverSurname: 'Garcia',
    transferCost: 113550,
    discountValue: 16450,
    code: 220486,
  },
  {
    _id: '6094131da0a25b9b3e57fa0e',
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    courtDate: '2021-05-24T05:00:00.000Z',
    deliveryCompany: '5cb0f5fd244fe2796e65f9fc',
    deliveryCompanyName: 'COORDINADORA',
    guideNumber: '98130650912',
    paymentType: 102,
    price: 18810,
    state: 'Devuelto',
    valueCollection: 320000,
    receiverName: 'William Alberto ',
    receiverSurname: 'Castaño',
    transferCost: 301190,
    discountValue: 18810,
    code: 220490,
  },
  {
    _id: '6094131da7b2f70cd0c47145',
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    courtDate: '2021-05-24T05:00:00.000Z',
    deliveryCompany: '5cb0f5fd244fe2796e65f9fc',
    deliveryCompanyName: 'COORDINADORA',
    guideNumber: '98130650911',
    paymentType: 102,
    price: 16500,
    state: 'Devuelto',
    valueCollection: 130000,
    receiverName: 'José ',
    receiverSurname: 'Garcia',
    transferCost: 113500,
    discountValue: 16500,
    code: 220489,
  },
  {
    _id: '6094131e8842e2fe714f1267',
    price: 16500,
    state: 'Entregado',
    deliveryCompanyName: 'COORDINADORA',
    paymentType: 102,
    valueCollection: 130000,
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    deliveryCompany: '5cb0f5fd244fe2796e65f9fc',
    guideNumber: '98130650913',
    courtDate: '2021-05-24T05:00:00.000Z',
    receiverName: 'Jair Ricardo ',
    receiverSurname: 'Chaparro Pallares',
    transferCost: 113500,
    discountValue: 16500,
    code: 220491,
  },
  {
    _id: '60944961a0a25b080b57fa6b',
    price: 27950,
    state: 'Entregado',
    deliveryCompanyName: 'SERVIENTREGA',
    paymentType: 101,
    valueCollection: 320000,
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    deliveryCompany: '5fceb46c8229797cb139a7aa',
    guideNumber: '2105077770',
    courtDate: '2021-05-24T05:00:00.000Z',
    receiverName: 'Jorge ',
    receiverSurname: 'Negrete',
    transferCost: 320000,
    discountValue: 27950,
    code: 220661,
  },
  {
    _id: '6094efb4a7b2f75e7ac47224',
    bankInformation: {
      bank: 'BANCO DE BOGOTA(Desembolso: $7.400)',
      typeAccount: 'Ahorros',
      numberAccount: '542226519',
      nameBeneficiary: 'LORENA ZAFRA ',
      nitBeneficiary: 1026287097,
    },
    courtDate: '2021-05-24T05:00:00.000Z',
    deliveryCompany: '6080a75ef08a770ddd9724fd',
    deliveryCompanyName: 'ENVIA',
    guideNumber: '14109464937',
    paymentType: 102,
    price: 14250,
    state: 'Devuelto',
    valueCollection: 130000,
    receiverName: 'Alexander ',
    receiverSurname: 'Martínez mancilla',
    transferCost: 115750,
    discountValue: 14250,
    code: 220857,
  }];

export const totalDataShipments = {
  totalGuides: 80,
  totalDeliveredGuides: 40,
  totalReturnedGuides: 40,
  totalTransferred: 600000,
  totalValueCollection: 1600000,
  totalDiscountValue: 600000,
  bankInformation: {
    bank: 'BANCO DAVIVIENDA(Desembolso: $7.400)',
    typeAccount: 'Ahorros',
    numberAccount: '55100043377',
    nameBeneficiary: 'Maria Alejandra Ruiz',
    nitBeneficiary: 1152456886,
    nitTypeBeneficiary: 'CC',
  },

};
export const dataState = [
  {
    state: 'Entregado',
    id: '1',
  },
  {
    state: 'Devuelto',
    id: '2',
  },
];

export const optionsResults = [
  {
    value: 25,
    text: '25 resultados',
  },
  {
    value: 50,
    text: '50 resultados',
  },
  {
    value: 75,
    text: '75 resultados',
  },
  {
    value: 100,
    text: '100 resultados',
  },
];

export const options = [
  {
    value: 'guide',
    label: 'N° de guía',
    secondLabel: 'Número de guía',
  },
  {
    value: 'mp',
    label: 'N° de MP',
    secondLabel: 'Número de MP',
  },
];
