/* eslint-disable no-console */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Login from '../../components/login';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './login.scss';

const LoginScreen = () => {
  const navigate = useNavigate();
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <Login next={() => navigate(-1)} />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop fullWidth>
        <div
          className="container-login__desktop-mode"
        >
          <Login next={() => navigate(-1)} />
        </div>
      </ContainerSectionDesktop>
    )
  );
};

export default LoginScreen;
