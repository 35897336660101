import swal from 'sweetalert';

import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { encriptData, setCurrentUser } from '../../../utils/auth';
import { SignInAction, UpdateTokenAction } from '../../../store/actions/auth.actions';
import { createAccountService, loginSocialAccountService } from '../../../services/user';

import Modal from '../../../components/modal';
import ModalChildren from '../../../components/modalChildren';
import StepOne from '../stepsCreateAccount/stepOne';
import StepTwo from '../stepsCreateAccount/stepTwo';
import StepFour from '../stepsCreateAccount/stepFour';
import StepSocialAccount from '../stepsCreateAccount/stepSocialAccount';

import './createAccount.scss';

const CreateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const countryUser = useSelector((state) => state.country);

  const [searchParams] = useSearchParams();

  const [step, setStep] = useState(1);
  const [userToCreate, setUserToCreate] = useState({});
  const [errorList, setErrorList] = useState([]);
  const [executingAction, setExecutingAction] = useState(false);
  const [modalSuccessfull, setModalSuccessfull] = useState(false);
  const temporalCredentials = useSelector((state) => state.auth.temporalCredentials);

  const history = {
    replace: (url) => (navigate(url, { replace: true })),
  };
  const loginFunction = async () => {
    try {
      setExecutingAction(true);
      const response = await loginSocialAccountService({
        accessToken: userToCreate.accessToken,
        socialUserId: userToCreate.socialUserId,
        socialNetwork: userToCreate.socialNetwork,
      });
      await setCurrentUser(response, true);
      dispatch(UpdateTokenAction(encriptData(response)));
      dispatch(SignInAction(response.user));
    } finally {
      setExecutingAction(false);
    }
  };

  useEffect(() => {
    const values = Object.fromEntries([...searchParams]);
    if (values?.step === 'socialAccount' && temporalCredentials) {
      setUserToCreate({
        country: countryUser.countryCode ?? '170',
        email: temporalCredentials.email ?? '',
        name: temporalCredentials.name ?? '',
        surname: temporalCredentials.surname ?? '',
        cellPhone: temporalCredentials.cellPhone ?? '',
        accessToken: temporalCredentials.accessToken ?? '',
        socialUserId: temporalCredentials.socialUserId ?? '',
        socialNetwork: temporalCredentials.socialNetwork ?? '',
      });
      setStep(4);
      navigate('/registro', { replace: true });
    }
  }, [Object.fromEntries([...searchParams])]);

  const createAccountFunction = async (dataUserToCreate) => {
    setExecutingAction(true);
    try {
      await createAccountService({
        email: dataUserToCreate.email?.toLowerCase(),
        name: dataUserToCreate.name?.trim(),
        surname: dataUserToCreate.surname?.trim(),
        cellPhone: dataUserToCreate.cellPhone?.toString().trim(),
        countryCode: dataUserToCreate.country,
        tyc: true,
        businessName: dataUserToCreate.businessName?.length > 0
          ? dataUserToCreate.businessName?.trim() : dataUserToCreate.name?.trim(),
        ...dataUserToCreate.averageShipments && {
          averageShipments: dataUserToCreate.averageShipments,
        },
        ...dataUserToCreate.password && { password: dataUserToCreate.password?.trim() },
        ...dataUserToCreate.socialNetwork && {
          socialNetwork: dataUserToCreate.socialNetwork,
        },
      });

      setModalSuccessfull(true);
    } catch {
      swal({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al momento de crear el usuario',
        button: 'Intentar de nuevo',
      });
    } finally {
      setExecutingAction(false);
    }
  };

  const selectContentToStep = (currentStepParam) => {
    switch (currentStepParam) {
      case 1:
        return (
          <StepOne
            goBack={() => navigate(-1)}
            next={(email, country) => {
              setUserToCreate({ ...userToCreate, email, country });
              setStep(2);
            }}
            userToCreate={userToCreate}
            errorList={errorList}
            setErrorList={setErrorList}
            history={history}
          />
        );
      case 2:
        return (
          <StepTwo
            goBack={() => setStep(1)}
            next={(name, surname, cellPhone, averageShipments) => {
              setUserToCreate({
                ...userToCreate, name, surname, cellPhone, averageShipments,
              });
              setStep(3);
            }}
            userToCreate={userToCreate}
            errorList={errorList}
            setErrorList={setErrorList}
          />
        );
      case 3:
        return (
          <StepFour
            goBack={() => setStep(2)}
            next={(password, confirmPassword) => {
              setUserToCreate({ ...userToCreate, password, confirmPassword });
              createAccountFunction({ ...userToCreate, password, confirmPassword });
            }}
            userToCreate={userToCreate}
            executingAction={executingAction}
            errorList={errorList}
            setErrorList={setErrorList}
          />
        );
      case 4:
        return (
          <StepSocialAccount
            goBack={() => navigate(-1)}
            next={(email, name, surname, cellPhone, averageShipments) => {
              setUserToCreate({
                ...userToCreate, email, name, surname, cellPhone, averageShipments,
              });
              createAccountFunction({
                ...userToCreate, email, name, surname, cellPhone, averageShipments,
              });
            }}
            userToCreate={userToCreate}
            executingAction={executingAction}
            errorList={errorList}
            setErrorList={setErrorList}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div id="create-account">
        {selectContentToStep(step)}
      </div>

      {modalSuccessfull && (
        <Modal>
          <ModalChildren
            className="register-content-modal"
            headerChildren={(
              <div className="modal-header-main">
                Tu cuenta ha sido
                {' '}
                <span className="modal-header">creada exitosamente</span>
              </div>
                )}
            confirmButtonText="¡Entendido!"
            onClickButtonConfirm={() => {
              if (userToCreate.socialNetwork) loginFunction();
              navigate('/');
            }}
            bodyText={(
              <div className="modal-register-email">
                {step !== 5 && (
                  <span className="modal-text email">
                    Revisa tu correo para activar tu cuenta, finalizar
                    el proceso de registro y así comenzar a hacer tus envíos.
                  </span>
                )}
                <span className="modal-text">
                  Muy pronto
                  {' '}
                  <b>te llamaremos</b>
                  {' '}
                  o contactaremos
                  {' '}
                  <b>por WhatsApp</b>
                  {' '}
                  para ayudarte en caso de tener dudas.
                </span>
              </div>
                )}
          />
        </Modal>
      )}
    </>
  );
};

export default CreateAccount;
