import React from 'react';

import PropTypes from 'prop-types';

import config from '../../../config/nocompanyconfig.fron.json';

import Modal from '../../modal';
import ModalChildren from '../../modalChildren';
import Typography from '../../typography';
import { typeDelivery } from '../../../constants/general';

import wppIcon from '../../../assets/images/whatsapp withe.svg';

import './deliveryConfirmation.scss';

const NoDeliveryConfirmation = ({ closeModal, countryCode }) => (
  <Modal closeBackground={() => closeModal()}>
    <ModalChildren
      className="delivery-confirmation-modal"
      headerText={(
        <Typography
          text="Comprobante no disponible"
          type="superTitle-italic"
          className="UbuntuBold"
        />
      )}
      bodyText={(
        <div className="text-container">
          <Typography type="field-gray-light" className="header-quote-gray">
            <p>
              A veces, los
              {' '}
              <span className="relevant">comprobantes de entrega</span>
              {' '}
              no se actualizan en el sistema por retrasos en la
              {' '}
              {typeDelivery[countryCode]}
            </p>
          </Typography>
          <br />
          <Typography type="field-gray-light" className="header-quote-gray">
            <p>
              Por ello, solicítalo en
              {' '}
              <span className="relevant">WhatsApp</span>
              {' '}
              al
              {' '}
              <span className="relevant">+57 316 4736403.</span>
            </p>
          </Typography>
          <br />
        </div>
      )}
      confirmButtonText="Solicitar comprobante"
      confirmButtonType="primary"
      onClickButtonConfirm={() => window.open(config.wppConfirmationDeliveryURL, '_blank')}
      rightImgButtonConfirm={wppIcon}
      close={() => closeModal()}
    />
  </Modal>
);

NoDeliveryConfirmation.propTypes = {
  closeModal: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default NoDeliveryConfirmation;
