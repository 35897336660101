import { useSelector } from 'react-redux';
import React from 'react';

import NotificationSettingsViewer from './notificationSettingsViewer';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './notificationsScreen.scss';

const NotificationsScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (isMobileDevice ? (
    <ContainerSectionMobile>
      <NotificationSettingsViewer />
    </ContainerSectionMobile>
  ) : (
    <ContainerSectionDesktop>
      <NotificationSettingsViewer />
    </ContainerSectionDesktop>
  ));
};

export default NotificationsScreen;
