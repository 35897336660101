import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toCurrencyCop } from 'utils/strings';

import SenderCollectionServiceDetailsPrices from 'components/senderCollectionServiceDetailsPrices';

import TagPrice from 'assets/images/tag-price.svg';

import './contentSenderCollectionServiceDetails.scss';

const ContentSenderCollectionServiceDetails = ({
  user,
  shippingValue,
  showDetails,
  shippingRealValue,
  collectionCommissionValue,
  realCollectionCommissionValue,
  dataShipping,
  dataCommissionService,
  totalToPay,
  totalSaved,
}) => (
  <div className="content-sender-collection-details">
    <SenderCollectionServiceDetailsPrices
      user={user}
      amountDiscount={shippingValue}
      realAmount={shippingRealValue}
      title="Valor total del envío"
      icon={TagPrice}
      showDetails={showDetails}
      data={dataShipping}
    />
    <SenderCollectionServiceDetailsPrices
      user={user}
      amountDiscount={collectionCommissionValue}
      realAmount={realCollectionCommissionValue}
      title="Servicio de recaudo"
      icon={TagPrice}
      showDetails={showDetails}
      data={dataCommissionService}
    />
    {showDetails && (
      <div className="content-sender-collection-details__bottom">
        <div className="total">
          <span className="text">Total a pagar</span>
          <span className="total-to-pay">{toCurrencyCop(user, totalToPay)}</span>
        </div>
        {totalSaved > 0 && (
        <div className="total">
          <span className="text">Total ahorrado</span>
          <span className="total-saved">{toCurrencyCop(user, totalSaved)}</span>
        </div>
        )}
      </div>
    )}
  </div>
);

ContentSenderCollectionServiceDetails.propTypes = {
  shippingValue: PropTypes.number.isRequired,
  showDetails: PropTypes.bool.isRequired,
  shippingRealValue: PropTypes.number.isRequired,
  collectionCommissionValue: PropTypes.number.isRequired,
  realCollectionCommissionValue: PropTypes.number.isRequired,
  dataShipping: PropTypes.arrayOf(PropTypes.shape({})),
  dataCommissionService: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({}).isRequired,
  totalToPay: PropTypes.number.isRequired,
  totalSaved: PropTypes.number,
};

ContentSenderCollectionServiceDetails.defaultProps = {
  dataShipping: null,
  dataCommissionService: null,
  totalSaved: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
});

export default connect(mapStateToProps)(ContentSenderCollectionServiceDetails);
