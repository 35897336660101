import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../modal';
import ModalChildren from '../modalChildren';

import './modalErrorUbication.scss';

const ModalErrorUbication = ({ countryCode, onClickConfirm }) => (
  <Modal>
    <ModalChildren
      className="error-ubication-modal"
      headerText="Error de ubicación"
      bodyText={(
        <div className="text">
          Parece que eres un usuario registrado en
          {' '}
          <span className="span">
            {countryCode === '170' ? 'Colombia' : 'Mexico'}
          </span>
          {' '}
          .
          Te llevaremos al cotizador de tu país.
        </div>
      )}
      confirmButtonText="Entiendo"
      confirmButtonType="primary"
      onClickButtonConfirm={() => onClickConfirm()}
    />
  </Modal>
);

export default ModalErrorUbication;

ModalErrorUbication.propTypes = {
  countryCode: PropTypes.string.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
};
