import React from 'react';
import PropTypes from 'prop-types';

import { validationSteps } from '../createAccount/validations';
import { findObjectInArray } from '../../../utils/filters';

import Tooltip from '../../../components/tooltip';
import Input from '../../../components/input';

import IconWhatsapp from '../../../assets/images/whatsapp.svg';
import colombiaFlag from '../../../assets/images/colombia.svg';
import mexicoFlag from '../../../assets/images/mexico-square.svg';

import './stepsCreateAccount.scss';

const NameSurnameAndCellPhone = ({
  name, setName,
  surname, setSurname,
  cellPhone, setCellPhone,
  errorList, setErrorList, userToCreate,
}) => {
  const flagValidate = (countryCode) => {
    if (countryCode === '484') {
      return { flag: mexicoFlag, demonym: 'mexicano' };
    }
    return { flag: colombiaFlag, demonym: 'colombiano' };
  };
  return (
    <>
      <Input
        label="Nombre"
        className="inputUserToCreate"
        value={name}
        onChange={(value) => {
          setName(value);
          if (findObjectInArray('name', errorList, 'name')) {
            validationSteps({ errorList, setErrorList, name: value });
          }
        }}
        onBlur={() => validationSteps({ errorList, setErrorList, name })}
        annotationError={findObjectInArray('name', errorList, 'name')?.error || null}
        placeholder="Nombre"
      />
      <Input
        label="Apellidos"
        className="inputUserToCreate"
        value={surname}
        onChange={(value) => {
          setSurname(value);
          if (findObjectInArray('surname', errorList, 'name')) {
            validationSteps({ errorList, setErrorList, surname: value });
          }
        }}
        onBlur={() => validationSteps({ errorList, setErrorList, surname })}
        annotationError={findObjectInArray('surname', errorList, 'name')?.error || null}
        placeholder="Apellidos"
      />
      <Input
        iconLeft={flagValidate(userToCreate.country).flag || null}
        label="Celular"
        className="inputUserToCreate"
        value={cellPhone}
        type="number-text"
        onChange={(value) => {
          setCellPhone(value);
          if (findObjectInArray('cellPhone', errorList, 'name')) {
            validationSteps({
              errorList,
              setErrorList,
              cellPhone: value,
              country: flagValidate(userToCreate.country).demonym,
            });
          }
        }}
        onBlur={() => validationSteps({
          errorList,
          setErrorList,
          cellPhone,
          country: flagValidate(userToCreate.country).demonym,
        })}
        annotationError={findObjectInArray('cellPhone', errorList, 'name')?.error || null}
        placeholder="3053432099"
        childrenRigth={(
          <Tooltip
            overlayTitle="Notificaciones Whatsapp"
            overlayText="Por whatsapp podrías recibir notificaciones para facilitar tu proceso de envíos."
            startingPosition="bottom-rigth"
            iconTooltip={IconWhatsapp}
          />
        )}
      />
    </>
  );
};

NameSurnameAndCellPhone.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  surname: PropTypes.string.isRequired,
  setSurname: PropTypes.func.isRequired,
  cellPhone: PropTypes.string.isRequired,
  setCellPhone: PropTypes.func.isRequired,
  userToCreate: PropTypes.instanceOf.isRequired,
  errorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setErrorList: PropTypes.func.isRequired,
};

export default NameSurnameAndCellPhone;
