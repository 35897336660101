/* eslint-disable default-param-last */
import {
  LOAD_DATA_GLOBAL, UPDATE_DATA_GLOBAL,
} from '../actions/type.actions';

const initialState = {
  locations: null,
  deliveryCompanies: null,
  deliveryCompaniesConfig: null,
  businessFlags: null,
  beneficts: null,
  noveltyCodes: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA_GLOBAL: {
      return action.payload;
    }

    case UPDATE_DATA_GLOBAL: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
