/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { humanizationOfTimeDuration } from '../../../../utils/generals';
import { finalPrice } from '../../../../utils/prices';
import { capitalizeWords, toCurrencyCop } from '../../../../utils/strings';
import { typeDelivery } from '../../../../constants/general';

import DeliveryCompaniesObservations from '../../../../components/deliveryCompaniesObservations';
import LastDetails from '../../../../components/lastDetails';
import ContentSection from '../../../../components/contentSection';
import Button from '../../../../components/button';
import Checkbox from '../../../../components/checkBox';
import Typography from '../../../../components/typography';

import ArrowBack from '../../../../assets/images/icon-arrow-back-color-gray-dark.svg';
import IconDelete from '../../../../assets/images/delete_red.svg';
import IconStar from '../../../../assets/images/star.svg';

import './shippingDetails.scss';
import './generalQuoteMultipleShippings.scss';

const ShippingDetails = ({
  back,
  shippingSelected,
  setShippingSelected,
  selectDeliveryCompanyManually,
  deleteShipping,
  user,
  isMobileDevice,
}) => {
  const [deliveryCompanySelectedForObservations, setDeliveryCompanySelectedForObservations] = useState(null);

  const changeDataJson = (label, value) => {
    const temporalObject = JSON.parse(JSON.stringify(shippingSelected));
    temporalObject[label] = value;
    setShippingSelected(temporalObject);
  };

  return (
    <>
      <ContentSection
        headerChildrenLeft={isMobileDevice ? (
          <img
            src={ArrowBack}
            alt="back"
            onClick={() => back()}
          />
        ) : (
          <Typography className="title-shipping-details" text="Detalle de envío" type="superTitle-italic" />
        )}
        headerChildrenCenter={isMobileDevice ? (
          <Typography className="title-shipping-details" text="Detalle de envío" type="superTitle-italic" />
        ) : (
          <span className="title-conflicts">{shippingSelected?.conflicts?.join[' '] ?? ''}</span>
        )}
        headerChildrenRigth={isMobileDevice ? (
          <img
            src={IconDelete}
            alt="back"
            onClick={() => {
              back();
              deleteShipping('row', shippingSelected?.rowNum);
            }}
          />
        ) : (
          <span className="title-auto-save">Los cambios se guardan automaticamente</span>
        )}
      >
        <div id="multiple-shippings__shipping-details" className={isMobileDevice ? 'mobile-mode' : ''}>
          {!isMobileDevice && (
            <div className="flotants-buttons">
              <img
                src={ArrowBack}
                alt="back"
                onClick={() => back()}
              />
              <Button
                type="secondary"
                onClick={() => null}
              >
                <>
                  Eliminar
                  <img
                    src={IconDelete}
                    alt="back"
                    onClick={() => {
                      back();
                      deleteShipping('row', shippingSelected?.rowNum);
                    }}
                  />
                </>
              </Button>
            </div>
          )}

          <div className="container-shipping-details">
            <LastDetails
              showAllDropDwons
              showButtonGoBack={false}
              detailsFromQuoteMultipleShippings
              shippingOriginName={shippingSelected?.shippingOriginName}
              shippingDestinyName={shippingSelected?.shippingDestinyName}
              articleHeight={shippingSelected?.articleHeight}
              articleWidth={shippingSelected?.articleWidth}
              articleLength={shippingSelected?.articleLength}
              articleWeight={shippingSelected?.articleWeight}
              unitOfMeasurement="Kg"
              quantityOfArticles={shippingSelected?.quantityOfArticles}
              declaredValue={shippingSelected?.declaredValue}
              saleValue={shippingSelected?.saleValue}
              articleDescription={shippingSelected?.articleDescription}
              setArticleDescription={(e) => changeDataJson('articleDescription', e)}
              recommendationsForDeliveryCompany={shippingSelected?.recommendationsForDeliveryCompany}
              setRecommendationsForDeliveryCompany={(e) => changeDataJson('recommendationsForDeliveryCompany', e)}
              errorsList={[]}
              setErrorsList={() => null}
              deliverySelected={shippingSelected?.deliveryCompanySelected}
              senderName={shippingSelected?.senderName}
              setSenderName={(e) => changeDataJson('senderName', e)}
              senderSurname={shippingSelected?.senderSurname}
              setSenderSurname={(e) => changeDataJson('senderSurname', e)}
              senderIdentificationNumber={shippingSelected?.senderIdentificationNumber}
              senderEmail={shippingSelected?.senderEmail}
              senderCellPhone={shippingSelected?.senderCellPhone}
              setSenderCellPhone={(e) => changeDataJson('senderCellPhone', e)}
              senderAddress={shippingSelected?.senderAddress}
              setSenderAddress={(e) => changeDataJson('senderAddress', e)}
              collectionOrTakeToOffice={shippingSelected?.collectionOrTakeToOffice}
              setCollectionOrTakeToOffice={(e) => changeDataJson('collectionOrTakeToOffice', e)}
              receiverName={shippingSelected?.receiverName}
              setReceiverName={(e) => changeDataJson('receiverName', e)}
              receiverSurname={shippingSelected?.receiverSurname}
              setReceiverSurname={(e) => changeDataJson('receiverSurname', e)}
              receiverIdentificationNumber={shippingSelected?.receiverIdentificationNumber}
              setReceiverIdentificationNumber={(e) => changeDataJson('receiverIdentificationNumber', e)}
              receiverEmail={shippingSelected?.receiverEmail}
              setReceiverEmail={(e) => changeDataJson('receiverEmail', e)}
              receiverCellPhone={shippingSelected?.receiverCellPhone}
              setReceiverCellPhone={(e) => changeDataJson('receiverCellPhone', e)}
              receiverAddress={shippingSelected?.receiverAddress}
              setReceiverAddress={(e) => changeDataJson('receiverAddress', e)}
              deliveryInAddressOrOffice={shippingSelected?.deliveryInAddressOrOffice}
              setDeliveryInAddressOrOffice={(e) => changeDataJson('deliveryInAddressOrOffice', e)}
              collectionService={shippingSelected?.collectionService}
              addShippingToCollectionValue={shippingSelected?.addShippingToCollectionValue}
              user={user}
            />
          </div>
          {shippingSelected?.deliveryCompaniesOptions?.length > 0 && (
            <div className="delivery-companies-list__shipping-details">
              <table className="general-table">
                <thead>
                  <tr>
                    <th />
                    <th>{capitalizeWords(typeDelivery[user.countryCode])}</th>
                    <th>Tiempo de entrega</th>
                    <th />
                    <th>Valor de envío</th>
                    <th>Pago contra entrega</th>
                    <th>Calificación</th>
                  </tr>
                </thead>
                <tbody>
                  {shippingSelected?.deliveryCompaniesOptions?.map((deliveryCompany) => (
                    <tr key="Individual">
                      <td className="checkbox-select-delicery-company">
                        <Checkbox
                          onClick={() => (
                            selectDeliveryCompanyManually(deliveryCompany, shippingSelected?.rowNum)
                          )}
                          checked={shippingSelected?.deliveryCompanySelected === deliveryCompany}
                        />
                      </td>
                      <td>
                        <div className="delivery-company">
                          <img src={deliveryCompany.deliveryCompanyImgUrl} alt="logo" />
                          <span>{deliveryCompany.deliveryCompanyName}</span>
                        </div>
                      </td>
                      <td>{humanizationOfTimeDuration(deliveryCompany.shippingTime)}</td>
                      <td
                        className="blue observations"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setDeliveryCompanySelectedForObservations(deliveryCompany);
                        }}
                      >
                        Observaciones
                      </td>
                      <td>
                        {
                          toCurrencyCop(
                            user,
                            finalPrice(
                              deliveryCompany?.shippingCost,
                              shippingSelected?.collectionService,
                              shippingSelected?.addShippingToCollectionValue,
                              deliveryCompany?.collectionCommissionWithRate,
                              deliveryCompany?.collectionCommissionWithOutRate,
                            ))
                        }
                      </td>
                      <td>{shippingSelected?.collectionService ? 'Sí' : 'No'}</td>
                      <td>
                        <div className="delivery-company-rate">
                          <img src={IconStar} alt="" />
                          <span>{deliveryCompany?.score || '3.5'}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </ContentSection>

      {deliveryCompanySelectedForObservations?.deliveryCompanyId && (
        <DeliveryCompaniesObservations
          deliveryCompanyImgUrl={deliveryCompanySelectedForObservations.deliveryCompanyImgUrl}
          deliveryCompanyName={deliveryCompanySelectedForObservations.deliveryCompanyName}
          deliveryCompanyId={deliveryCompanySelectedForObservations.deliveryCompanyId}
          close={() => setDeliveryCompanySelectedForObservations(null)}
          showButtons={false}
          isMobileDevice={isMobileDevice}
          countryCode={user.countryCode}
        />
      )}
    </>
  );
};

ShippingDetails.propTypes = {
  back: PropTypes.func.isRequired,
  shippingSelected: PropTypes.any.isRequired,
  setShippingSelected: PropTypes.func.isRequired,
  selectDeliveryCompanyManually: PropTypes.func.isRequired,
  deleteShipping: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

export default ShippingDetails;
