/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconArrow from '../../assets/images/selectIcons/arrow_drop_down-Orange.svg';
import IconArrowAccent from '../../assets/images/selectIcons/arrow_drop_down-Blue.svg';
import IconArrowGray from '../../assets/images/arrow_drop_down_gray.svg';
import IconArrowDisabled from '../../assets/images/selectIcons/arrow_drop_down-Disable.svg';

import './select.scss';

const Select = ({
  value, label, alwaysShowLabel, startingPosition, options, onChange, element,
  personalizedId, className, showValue, annotation, annotationError,
  defaultValueText, disabled, showLabel, selectColor, nonBlur,
}) => {
  const [focus, setFocus] = useState(false);
  const showOptionSelected = (array, valueToSearch) => {
    if (showValue && valueToSearch && valueToSearch !== '') {
      const object = array?.find((option) => valueToSearch === option.value);
      const addText = defaultValueText || '';
      return object?.shortLabel || `${addText} ${object?.label}`;
    }
    return null;
  };

  const labelColor = () => {
    if (selectColor === 'accent') return 'accentColor';
    if (selectColor === 'primary') return 'orangeColor';
    return 'grayColor';
  };

  const icon = () => {
    if (selectColor === 'accent') return IconArrowAccent;
    if (selectColor === 'primary') return IconArrow;
    return IconArrowGray;
  };

  const validateValue = (option, propName) => {
    switch (propName) {
      case 'className':
        return `option-select-general ${value === option?.value ? 'selected' : ''} ${selectColor === 'gray' && 'grayColor'}`;
      case 'key':
        return `option-${option?.value}`;
      default:
        return option?.value;
    }
  };

  return (
    <label
      id={personalizedId}
      className={`containter-select-all ${startingPosition} ${className} ${focus && !disabled ? 'focus' : ''} ${disabled ? 'disabled' : ''}`}
    >
      <div className={`container-value-selected__select-general ${selectColor === 'gray' ? 'grayColor' : ''}`}>
        {showLabel && (
          <span className={`${focus && !disabled ? labelColor() : ''} ${selectColor}`}>{alwaysShowLabel ? label : showOptionSelected(options, value) || label}</span>
        )}
        {!disabled && <img src={focus ? icon() : IconArrowGray} alt="" />}
        {disabled && <img src={IconArrowDisabled} alt="" />}
      </div>
      <div
        className={`container-options__select-general ${focus ? 'visible' : ''} ${disabled ? 'disabled' : ''} ${selectColor}`}
        onClick={() => {
          if (!disabled) {
            setFocus(!focus);
            if (nonBlur) nonBlur();
          }
        }}
      >
        <div
          className={`container-value-selected__select-general ${selectColor}`}
          onClick={() => onChange(value)}
        >
          {element}
          {showLabel && (
            <span className={`${focus ? labelColor() : ''} ${selectColor}`}>{alwaysShowLabel ? label : showOptionSelected(options, value) || label}</span>
          )}
          {!disabled && <img src={(selectColor ? icon() : IconArrowGray) || focus ? icon() : IconArrowGray} alt="" />}
          {disabled && <img src={IconArrowDisabled} alt="" />}
        </div>
        {showValue && !disabled && options?.map((option) => (
          <span
            className={validateValue(option, 'className')}
            key={validateValue(option, 'key')}
            onClick={() => onChange(validateValue(option))}
          >
            {option.img && <img src={option.img} alt="img" />}
            {option.secondLabel ? option.secondLabel : option.label}
          </span>
        ))}
      </div>
      <span
        className={`annotation__select-general ${focus ? 'focus' : ''} ${annotation ? 'visible' : ''}`}
      >
        {annotation && `*${annotation}`}
      </span>
      <span
        className={`annotation__select-general error ${(annotationError) ? 'visible' : ''}`}
      >
        {annotationError && `*${annotationError}`}
      </span>
    </label>
  );
};

Select.propTypes = {
  personalizedId: PropTypes.string,
  className: PropTypes.string,
  element: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
  ]),
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  showValue: PropTypes.bool,
  startingPosition: PropTypes.oneOf(['top-left', 'top-rigth', 'bottom-left', 'bottom-rigth']),
  selectColor: PropTypes.oneOf(['primary', 'accent', 'gray']),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  annotation: PropTypes.string,
  annotationError: PropTypes.string,
  defaultValueText: PropTypes.string,
  disabled: PropTypes.bool,
  alwaysShowLabel: PropTypes.bool,
  showLabel: PropTypes.bool,
  nonBlur: PropTypes.func,
};

Select.defaultProps = {
  value: null,
  startingPosition: 'top-left',
  selectColor: 'primary',
  element: null,
  label: null,
  personalizedId: '',
  className: '',
  showValue: true,
  annotation: null,
  annotationError: null,
  defaultValueText: '',
  disabled: false,
  alwaysShowLabel: false,
  showLabel: true,
  nonBlur: null,
};

export default Select;
