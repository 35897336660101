import React from 'react';

import ImageStepTwo from '../../assets/images/novelties-center-guide.png';

export const contentSteps = [
  {
    title: 'El centro de ayuda se ha renovado',
    description: (
      <>
        Ahora es el
        {' '}
        <b className="color-primary">centro de novedades.</b>
        {' '}
        En mipaquete.com te escuchamos.
        Por ello te traemos una de las mejoras más solicitadas.
      </>
    ),
  }, {
    subTitle: 'Centro de novedades',
    description: 'Ahora la sección también estará dentro de la plataforma para facilitarte el acceso a esta.',
    image: ImageStepTwo,
  },
];

export default { contentSteps };
