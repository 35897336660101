import React from 'react';
import { useSelector } from 'react-redux';

import MyCollections from './myCollections';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

const MyCollectionsScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <MyCollections />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <MyCollections />
      </ContainerSectionDesktop>
    )
  );
};

export default MyCollectionsScreen;
