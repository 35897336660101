import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { findObjectInArray } from '../../../../../../utils/filters';
import { stepTwoValidation } from '../../../../../../components/completingData/quoteCompletingSteps/validation';
import { typeDelivery } from '../../../../../../constants/general';
import RadioButton from '../../../../../../components/radioButton';

import Input from '../../../../../../components/input';
import Tooltip from '../../../../../../components/tooltip';
import tooltip from '../../../../../../assets/images/tooltip-gray.svg';

import '../stepTwo.scss';

const RadioButtonItems = ({
  listFields,
  deliverySelected,
  errorsList,
  countryCode,
  onChange,
  formValues,
  updateFormValues,
  setErrorsList,
}) => {
  useEffect(() => {
    onChange({
      values: formValues,
    });
  }, [formValues]);

  useEffect(() => {
    if (formValues.takeToOfficeOrCollection === 'takeToOffice') {
      updateFormValues('destinationAddress', '');
    }
  }, [formValues?.takeToOfficeOrCollection]);

  useEffect(() => {
    if (deliverySelected.officeAddress && !deliverySelected.pickupService) {
      updateFormValues('takeToOfficeOrCollection', 'takeToOffice');
    } else {
      updateFormValues('takeToOfficeOrCollection', 'collectionInAddress');
    }
  }, []);

  const textRadioButton = () => `Entrega en oficina de la ${typeDelivery[countryCode]}
                ${deliverySelected.officeAddress}`;
  return (
    <>
      {listFields?.map(({
        name,
        typeField,
        children,
        checkedCollectionMethod,
        placeholder,
        label,
        type,
        annotation,
      }) => (
        <React.Fragment key={name}>
          {typeField === 'radioButton' && (
          <>
            {deliverySelected.pickupService && checkedCollectionMethod === 'collectionInAddress' && (
            <RadioButton
              name={name}
              text=""
              value={checkedCollectionMethod}
              onChange={(value) => updateFormValues('takeToOfficeOrCollection', value)}
              checked={formValues?.takeToOfficeOrCollection === checkedCollectionMethod}
            >
              {children && children.map((it) => (
                <React.Fragment key={it.name}>
                  <Input
                    value={formValues[it.name]}
                    label={it.label}
                    label2={it.labelTwo}
                    onChange={(value) => {
                      const limitValue = value.toString();
                      updateFormValues(it.name, limitValue.slice(0, 200));
                      if (findObjectInArray(it.name, errorsList, 'name')) {
                        stepTwoValidation(
                          countryCode,
                          formValues,
                          errorsList,
                          setErrorsList,
                        );
                      }
                    }}
                    className={it.className ?? ''}
                    placeholder={it.placeholder}
                    annotationError={findObjectInArray(it.name, errorsList, 'name')?.error ?? null}
                    type={it.type}
                    onFocus={() => updateFormValues('takeToOfficeOrCollection', 'collectionInAddress')}
                  />
                  <Tooltip
                    overlayText="Para prevenir devoluciones y novedades con tu envío ingresa la dirección completa de tu destinatario."
                    startingPosition="bottom-rigth"
                    iconTooltip={tooltip}
                  />
                </React.Fragment>
              ))}
            </RadioButton>
            )}
            {deliverySelected.officeAddress && checkedCollectionMethod === 'takeToOffice' && (
            <RadioButton
              name={name}
              value={checkedCollectionMethod}
              onChange={(value) => updateFormValues('takeToOfficeOrCollection', value)}
              checked={formValues?.takeToOfficeOrCollection === checkedCollectionMethod}
              annotationError={findObjectInArray('prohibitedItems', errorsList, 'name')?.error ?? null}
              text={textRadioButton()}
            />
            )}
          </>
          )}
          {formValues?.takeToOfficeOrCollection === 'takeToOffice' && name === 'receiverDocumentNumber' ? (
            <Input
              value={formValues[name]}
              onChange={(value) => updateFormValues(name, value)}
              placeholder={placeholder}
              label={label}
              annotation={annotation}
              annotationError={findObjectInArray(name, errorsList, 'name')?.error ?? null}
              type={type}
            />
          ) : formValues?.takeToOfficeOrCollection === 'collectionInAddress' && name === 'reference' && countryCode === '484' && (
            <Input
              value={formValues[name]}
              onChange={(value) => updateFormValues(name, value)}
              placeholder={placeholder}
              label={label}
              annotationError={findObjectInArray(name, errorsList, 'name')?.error ?? null}
              type={type}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

RadioButtonItems.propTypes = {
  listFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deliverySelected: PropTypes.shape({
    officeAddress: PropTypes.string,
    pickupService: PropTypes.bool,
  }).isRequired,
  formValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateFormValues: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  countryCode: PropTypes.string.isRequired,
  setErrorsList: PropTypes.func.isRequired,
};
export default RadioButtonItems;
