import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Modal from '../modal';
import Typography from '../typography';

import IconNormal from '../../assets/images/icon-normal.svg';
import IconCollection from '../../assets/images/icon-collection.svg';

import './modalShippingType.scss';

const ModalShippingType = ({
  closeModal,
  setDeliverySelected,
  deliveryList,
  setStepsFunction,
}) => {
  const isMobileDevice = useSelector((state) => state?.mediaQuery?.isMobileDevice);

  return (
    <Modal
      className={`modal__collection-service ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
      closeBackground={() => closeModal()}
    >
      <>
        <div className="header__collection-service">
          <div className="content-close-modal">
            <button
              type="button"
              className="rigth"
              onClick={() => {
                closeModal();
                setDeliverySelected();
              }}
              aria-label="close modal"
            />
          </div>
        </div>
        <div className="header__collection-service">
          <Typography
            text="¿Qué tipo de envío realizas?"
            type="superTitle-italic"
            className="title__header__collection-service"
          />
        </div>
        <div className="container-type-shipment">
          <button
            type="button"
            className="footer__collection-service background-primary"
            onClick={() => {
              deliveryList();
            }}
          >
            <img
              src={IconNormal}
              alt="icon"
              className="adjust-image-normal"
            />
            <Typography
              text="Tradicional"
              type="title"
              className="title__header__collection-service"
            />
            <Typography
              text="No se recauda dinero"
              type="title"
              className="subtitle__header__collection-service"
            />
          </button>
          <button
            type="button"
            className="footer__collection-service background-secondary"
            onClick={() => {
              setStepsFunction();
            }}
          >
            <img
              src={IconCollection}
              alt="icon"
            />
            <Typography
              text="Pago contraentrega"
              type="title"
              className="title__header__collection-service"
            />
            <Typography
              text="Con recaudo de dinero"
              type="title"
              className="subtitle__header__collection-service"
            />
          </button>
        </div>
      </>
    </Modal>
  );
};

ModalShippingType.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setDeliverySelected: PropTypes.func.isRequired,
  deliveryList: PropTypes.func.isRequired,
  setStepsFunction: PropTypes.func.isRequired,
};
export default ModalShippingType;
