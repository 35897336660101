import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';

import fields from '../../../../../config/quoteShippingFields.json';

import useHandleFields from '../../../../../hooks/useHandleFields';
import { useLocationService } from '../../../../../hooks/useShippingSteps';
import { routeExistenceValidationFunction, stepOneValidation } from '../../../../../utils/validation/validationsQuoteShipping';
import { findLocationByName, findLocationByNameMexico, findObjectInArray } from '../../../../../utils/filters';
import { getDataList, paramsLocation } from '../../../../../utils/paramsLocations';
import { colorButton } from '../../../../../utils/quoteShipping';

import Input from '../../../../../components/input';
import Button from '../../../../../components/button';

import IconSearch from '../../../../../assets/images/search.svg';

import '../stepsQuoteShipping.scss';

const StepOne = ({
  shippingData, errorList, setErrorList, next,
}) => {
  const countryCode = useSelector((state) => state.country);
  const locations = useSelector((state) => state.globalData.locations || []);
  const [originCode, setOriginCode] = useState(null);
  const [destinyCode, setDestinyCode] = useState(null);
  const [formValues, updateFormValues] = useHandleFields({
    origin: '',
    destiny: '',
  });
  let timer;
  const { listLocations, getLocationService } = useLocationService();
  const [loading, setLoading] = useState(false);
  const [listFields, setListFields] = useState([]);

  useEffect(() => {
    setListFields(fields.country[countryCode]?.fieldsQuoteShipping
      .filter((item) => item.step === 1));
  }, [fields.country[countryCode]]);

  useEffect(() => {
    listFields?.forEach(({ name }) => {
      updateFormValues(name, shippingData[name] || '');
    });
  }, [shippingData, listFields]);

  useEffect(() => {
    let codeOrigin;
    const originString = formValues.origin.split('-');
    if (originString?.length > 1) {
      if (countryCode === '170') {
        codeOrigin = findLocationByName(
          originString[0],
          originString[1],
          locations,
        );
      } else {
        codeOrigin = findLocationByNameMexico(
          originString[0],
          originString[1],
          originString[2],
          originString[3],
          listLocations,
        );
      }
      setOriginCode(codeOrigin?.locationCode ?? '');
    }
  }, [formValues.origin]);

  useEffect(() => {
    let codeDestiny;
    const destinyString = formValues.destiny.split('-');
    if (destinyString?.length > 1) {
      if (countryCode === '170') {
        codeDestiny = findLocationByName(
          destinyString[0],
          destinyString[1],
          locations,
        );
      } else {
        codeDestiny = findLocationByNameMexico(
          destinyString[0],
          destinyString[1],
          destinyString[2],
          destinyString[3],
          listLocations,
        );
      }
      setDestinyCode(codeDestiny?.locationCode ?? '');
    }
  }, [formValues.destiny]);

  const beforeNext = async () => {
    setLoading(true);
    await routeExistenceValidationFunction(
      countryCode,
      originCode || shippingData?.originCode,
      destinyCode || shippingData?.destinyCode,
      () => {
        const data = {
          ...formValues,
          originCode,
          destinyCode,
        };
        next(data);
      });
    setLoading(false);
  };
  const showListOptions = (name) => {
    if (countryCode === '484') {
      return getDataList(listLocations, name, countryCode).firstList;
    }
    return getDataList(locations, name, countryCode);
  };
  const filtersLocations = async (name, value) => {
    if (countryCode === '484') {
      await paramsLocation(name, value, countryCode, getLocationService);
    }
  };

  const handleDisabledButton = () => {
    if (shippingData.originCode && shippingData.destinyCode && !errorList.length) {
      return true;
    }
    return originCode && destinyCode && !errorList.length;
  };

  return (
    <div className="contentStepQuoteShipping">
      <section className="content stepOne">
        {listFields?.map(({ label, placeholder, name }) => (
          <Fragment key={name}>
            <Input
              key={name}
              label={label}
              iconRigth={IconSearch}
              dataList={showListOptions(name)}
              value={formValues[name]}
              onChange={(value) => {
                updateFormValues(name, value);
                clearTimeout(timer);
                if (value.trim() !== '') {
                  timer = setTimeout(() => {
                    filtersLocations(name, value);
                  }, 600);
                }

                if (findObjectInArray(name, errorList, 'name')) {
                  stepOneValidation(
                    errorList,
                    setErrorList,
                    name,
                    value,
                    countryCode === '484' ? listLocations : locations,
                    countryCode,
                  );
                }
              }}
              onBlur={(e) => {
                stepOneValidation(
                  errorList,
                  setErrorList,
                  name,
                  e.target.value,
                  countryCode === '484' ? listLocations : locations,
                  countryCode,
                );
              }}
              annotationError={findObjectInArray(name, errorList, 'name')?.error ?? null}
              placeholder={placeholder}
              changeColorsvg
              svgClasscss="magnifying-glass"
            />
          </Fragment>
        ))}
      </section>

      <section className="containerButtonsStepsQuoteShipping">
        <Button
          text="Continuar"
          type={colorButton(countryCode)}
          disabled={!handleDisabledButton()}
          loading={loading}
          onClick={beforeNext}
        />
      </section>
    </div>
  );
};

StepOne.propTypes = {
  shippingData: PropTypes.shape({
    origin: PropTypes.string,
    originCode: PropTypes.string,
    destiny: PropTypes.string,
    destinyCode: PropTypes.string,
  }).isRequired,
  errorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setErrorList: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

export default StepOne;
