import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { typeDelivery } from '../../../constants/general';

import Typography from '../../../components/typography';
import RadioButton from '../../../components/radioButton';

import existGuideSettings, { updateOrCreatePrintFormat } from './format';
import formatType from './formatTypes';

import './printFormatSettings.scss';

const openImage = (imageUri) => {
  if (imageUri) {
    axios.get(imageUri, { responseType: 'arraybuffer' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.target = '_blank';
        anchor.click();
      })
      .catch(() => null);
  }
};

const createIndex = (index, prestring) => `${(index || 0) + 1}-${prestring}`;

const cheked = (formatsSelected, company, value) => formatsSelected.find((companyFormat) => (
  companyFormat.deliveryCompany === company.deliveryCompany
  && companyFormat.printFormat === value));

const PrinFormatSettings = () => {
  const { deliveryCompaniesConfig } = useSelector((state) => state.globalData);
  const user = useSelector((state) => state.auth.user);

  const [companiesWithPrintFormat, setCompaniesWithPrintFormat] = useState([]);
  const [formatsSelected, setFormatSelected] = useState([]);

  useEffect(() => {
    existGuideSettings(deliveryCompaniesConfig, setCompaniesWithPrintFormat, setFormatSelected);
  }, [user]);

  const setPrintFormat = (printFormat, deliveryCompany) => {
    const formatSelectedSupport = [...formatsSelected];
    const currentFormats = [...formatsSelected];
    const index = formatsSelected.findIndex((format) => format.deliveryCompany === deliveryCompany);

    const object = { deliveryCompany, printFormat: formatType(deliveryCompany, printFormat) };

    if (index <= -1) formatSelectedSupport.push(object);
    if (index > -1) formatSelectedSupport[index] = object;

    updateOrCreatePrintFormat(object, currentFormats, setFormatSelected);
    setFormatSelected(formatSelectedSupport);
  };

  return companiesWithPrintFormat?.length > 0 ? (
    <div id="print_format_settings">
      <div className="headers">
        <Typography
          type="title"
          text="Formato impresión de guías"
          className="heebo-bold"
        />
        <Typography
          type="field-gray-light"
          text={`Selecciona para cada ${typeDelivery[user.countryCode]} el formato de guía de tu preferencia`}
          className="title-dark print-title"
        />
        <Typography
          type="paragraph"
          text="Medidas en centímetros Ancho x Alto"
          className="heebo-bold sub_title"
        />
      </div>

      <div className="wrapper">
        {companiesWithPrintFormat?.map((company, index) => (
          <div className="company" key={createIndex(index, 'wraper-company')}>
            <div>
              <Typography
                className="field-gray color-darker title"
                text={company?.companyName}
                type="field-gray"
              />
              <div>
                {company.printFormat?.map(({ label, value, imageUri }) => (
                  <div className="print-format-and-example" key={createIndex(index, 'print-format')}>
                    <RadioButton
                      text={label}
                      value={value}
                      name={company?.companyName}
                      className="print-format-radio-button"
                      checked={cheked(formatsSelected, company, value)}
                      onChange={(e) => setPrintFormat(e, company.deliveryCompany)}
                    />
                    <div
                      className="image-container"
                      onKeyDown={() => {}}
                      onClick={() => openImage(imageUri)}
                    >
                      <div
                        className="exmaple-image"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PrinFormatSettings;
