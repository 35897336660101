/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useValidationCountry, useFetchIpData } from '../../../../../utils/validationCountry';

import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import CheckBox from '../../../../../components/checkBox';
import Typography from '../../../../../components/typography';

import IllustrationTitle from '../../../../../assets/images/money.svg';

import '../stepsQuoteShipping.scss';

const CashDeliveryService = ({
  saleValue, setSaleValue, addShippingToCollectionValue, setAddShippingToCollectionValue, back,
  nextWithCollectionService, saleValueError,
}) => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div id="collection-service-quote-shipping" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className="container-top__collection-service-quote-shipping">
        <div className="title__collection-service__modal">
          {isMobileDevice && (
            <div
              className="left icon-row-left"
              onClick={() => { if (back) back(); }}
              onKeyUp={() => { if (back) back(); }}
              role="button"
              aria-hidden
            />
          )}
          <div className="center">
            <img src={IllustrationTitle} alt="" />
            <Typography text="Servicio de pago contra entrega" type="title" />
          </div>
          <div className="back__collection-service-quote-shipping" />
        </div>
        <div className="body__collection-service__modal">
          <Input
            label="¿Cuál es el valor que debemos recaudar por ti?"
            value={saleValue}
            onChange={setSaleValue}
            type="number"
            placeholder="50.000"
            annotation="Valor máximo a recaudar $ 2’000.000"
            annotationError={saleValueError}
            changeColorsvg
            svgClasscss="attach_money"
            classNameContainer="strict-width"
          />
          <div className="body__collection-service__check-box">
            <CheckBox
              checked={addShippingToCollectionValue}
              onChange={setAddShippingToCollectionValue}
              text="Quiero que mi destinatario también pague el envío"
            />
          </div>
        </div>
      </div>
      <div
        className={`container-buttons__steps-quote-shipping ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
      >
        <Button
          text="Continuar"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          onClick={nextWithCollectionService}
        />
      </div>
    </div>
  );
};

CashDeliveryService.propTypes = {
  saleValue: PropTypes.number,
  addShippingToCollectionValue: PropTypes.bool.isRequired,
  setSaleValue: PropTypes.func.isRequired,
  setAddShippingToCollectionValue: PropTypes.func.isRequired,
  nextWithCollectionService: PropTypes.func.isRequired,
  saleValueError: PropTypes.string,
  back: PropTypes.func,
};

CashDeliveryService.defaultProps = {
  saleValue: null,
  saleValueError: null,
  back: null,
};

export default CashDeliveryService;
