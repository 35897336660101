/* eslint-disable arrow-body-style */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { readExcel } from '../../../utils/excelgenerator/readExcel';
import splitLists from '../../../enums/splitlists';
import { quoteShippingService } from '../../../services/quoteShipping';
import { findObjectInArray } from '../../../utils/filters';
import { UpdateUserAction } from '../../../store/actions/auth.actions';
import { getUserService } from '../../../services/user';
import { formatJsonDataExcelFunction } from './readDataExcel';
import { excelValidation } from './multipleShippingValidation';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';
import {
  selectDeliveryCompanyByIdForAllShippings,
  selectDeliveryCompanyManually,
  selectPaymentMethodManually,
  insertOrRemoveConflicts,
  isPosibleContinueToPayment,
  declareNotProhibitedArticles,
  orderShippingListToPayment,
  generationOfShippings,
  deleteShippings,
  thePaymentFromCollecionValueIsPosible,
  applyFilters,
  officeInAddresEnabled,
} from './filtersAndFunctions';

import StepExcelErrors from './steps/stepExcelErrors';
import StepShippingList from './steps/stepShippingList';
import StepOneQuoteMultipleShippings from './steps/stepOne';
import StepTwoQuoteMultipleShippings from './steps/stepTwo';
import ShippingDetails from './steps/shippingDetails';
import PaymentMultipleShippings from './steps/paymentMultipleShippings/paymentMultipleShippings';
import GeneratedSuccess from './steps/generatedSuccess';
import Modal from '../../../components/modal';
import BankData from '../../../components/bankData';
import Login from '../../../components/login';
import Button from '../../../components/button';
import Typography from '../../../components/typography';

import IconClose from '../../../assets/images/close.svg';

import './quoteMultipleShippings.scss';

const QuoteMultipleShippings = ({
  setFullContainerQuoteMultipleShipping,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user || null);
  const locations = useSelector((state) => state.globalData.locations || null);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice || false);
  const deliveryCompanies = useSelector((state) => state.globalData.deliveryCompanies || null);
  const deliveryCompaniesConfig = useSelector((state) => state.globalData.deliveryCompaniesConfig || null);

  const history = {
    push: (url) => (navigate(url)),
    replace: (url) => (navigate(url, { replace: true })),
    goBack: () => (navigate(-1)),
  };

  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [jsonFromExcel, setJsonFromExcel] = useState(null);
  const [excelErrors, setExcelErrors] = useState([]);
  const [readExcelLoading, setReadExcelLoading] = useState(false);
  const [shippingsWithConflicts, setShippingsWithConflicts] = useState(false);

  const [dataShippings, setDataShippings] = useState([]);
  const [dataShippingsToPay, setDataShippingsToPay] = useState([]);
  const [dataShippingsSuccess, setDataShippingsSuccess] = useState([]);
  const [dataShippingsError, setDataShippingsError] = useState([]);

  const [deliveryCompanySelectByFilter, setDeliveryCompanySelectByFilter] = useState(null);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);
  const [deliverySelectedForAll, setDeliverySelectedForAll] = useState(null);
  const [notProhibitedArticles, setNotProhibitedArticles] = useState(false);
  const [excelWithErrors, setExcelWithErrors] = useState(false);
  const [userActualized, setUserActualized] = useState(false);

  const [loadingdataToPayment, setLoadingdataToPayment] = useState(false);
  const [loadingSendingGenerating, setLoadingSendingGenerating] = useState(false);

  const [section, setSection] = useState('firstSection');
  const [goToShippingDetails, setGoToShippingDetails] = useState(false);
  const [goToPayShippings, setGoToPayShippings] = useState(false);
  const [shippingSelected, setShippingSelected] = useState(null);

  const [showLogin, setShowLogin] = useState(false);
  const [showNoDataBank, setShowNoDataBank] = useState(false);
  const [showBankData, setShowBankData] = useState(false);
  const [showSendingSave, setShowSendingSave] = useState(false);
  const [showOfficcesAddress, setShowOfficcesAddres] = useState(false);
  const [selectRequestPickupFalse, setSelectRequestPickupFalse] = useState(null);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const validateCollectionServiceExistance = (shippingList) => (
    shippingList?.filter((shipping) => shipping.collectionService).length > 0
  );

  /**
   * Actualizacion de datos de usuario en los envios
   */
  useEffect(() => {
    if (user && dataShippings.length > 0 && !userActualized) {
      const temporalDataShippings = dataShippings.slice();
      temporalDataShippings.forEach((shipping) => {
        shipping.senderName = user.businessName || user.name;
        shipping.senderSurname = user.businessName ? '.' : user.surname;
        shipping.senderIdentificationNumber = user.documentNumber;
        shipping.senderIdentificationType = user.documentType;
        shipping.senderEmail = user.email;
        shipping.senderCellPhone = user.cellPhone;
        shipping.senderAccountBank = user.accountBank;
        return shipping;
      });
      setUserActualized(true);
      setDataShippings(temporalDataShippings);
    }
  }, [user, dataShippings]);

  /**
   * Validaciones de logueo y cuenta bancaria para listar los envíos solicitados
   */
  const continueToShippingList = (organizedShippings) => {
    if (!user) {
      setShowLogin(true);
    } else if (
      user
      && validateCollectionServiceExistance(organizedShippings)
      && !user.accountBank
    ) {
      setShowNoDataBank(true);
    } else {
      setShowLogin(false);
      setShowNoDataBank(false);
      setFullContainerQuoteMultipleShipping(true);
      setGoToShippingDetails(false);
      setSection('sendingList');
    }
  };

  /**
   * Lectura del excel, validación de los datos y agregación de rutas para el envío
   */
  const validateExcel = async () => {
    setReadExcelLoading(true);
    if (locations && deliveryCompanies) {
      // Lectura del excel
      const responseExcelToJson = await readExcel(file);
      setJsonFromExcel(responseExcelToJson);
      // Validación de los datos ingresados en el excel
      const responseExcelValidation = excelValidation(responseExcelToJson, locations);
      const organizedShippings = [];
      // En caso de haber errores en la validación, los errores se utilziarán desde excelErrors
      setExcelErrors(responseExcelValidation);

      // En caso de existir un error en el excel, se avanzará a la vista de errores,
      // de lo contrario, se avanzará a cotizar los envíos.
      if (responseExcelValidation.length > 0) {
        setSection('excelErrors');
        setFullContainerQuoteMultipleShipping(true);
        setExcelWithErrors(true);
        setReadExcelLoading(false);
      } else {
        setExcelWithErrors(false);
        // Los datos obtenidos se convertirán a datos a variables utilizables.
        responseExcelToJson.map((row) => (
          organizedShippings.push(formatJsonDataExcelFunction(row, locations))
        ));

        const list = splitLists(organizedShippings, 80);

        let r = [];

        for (let i = 0; i < list.length; i++) {
          const element = list[i];
          const routes = await Promise.all(element.map((shipping) => (
            quoteShippingService(
              shipping.shippingOriginCountryCode, shipping.shippingOriginCode,
              shipping.shippingDestinyCountryCode, shipping.shippingDestinyCode,
              shipping.articleHeight, shipping.articleWidth, shipping.articleLength,
              shipping.articleWeight, shipping.quantityOfArticles,
              shipping.declaredValue,
              shipping.saleValue,
            )
          )));

          r = [...r, ...routes];
        }

        // Las rutas obtenidas se agregarán al envío correspondiente
        organizedShippings.forEach((shipping, index) => {
          organizedShippings[index].deliveryCompaniesOptions = r[index];
          if (r[index].length <= 0) {
            organizedShippings[index] = insertOrRemoveConflicts(shipping, 'noCoverage', 'insert');
          }
        });

        // A cada transportadora se le asignará si tiene o no el servicio de descontar el envío del valor a recaudar
        // dependiendo de los datos en el archivo de configuración
        const collectionServiceExistance = validateCollectionServiceExistance(organizedShippings);

        organizedShippings.forEach((shipping, index) => {
          const companyOptions = shipping.deliveryCompaniesOptions.map((deliveryCompany) => {
            deliveryCompany.thePaymentFromCollecionValueIsPosible = shipping.collectionService
              && findObjectInArray(deliveryCompany.deliveryCompanyId, deliveryCompaniesConfig, 'deliveryCompany')?.paymentFromCollecionValue;
            return deliveryCompany;
          });
          organizedShippings[index].deliveryCompaniesOptions = companyOptions;
          if (!collectionServiceExistance) organizedShippings[index].paymentMethod = 101;
        });

        setDataShippings(organizedShippings);
        setGoToShippingDetails(true);
        continueToShippingList(organizedShippings);
        setReadExcelLoading(false);
      }
    }
  };

  /**
   * Si no se habian cargado las transportadoras y/o ciudades, la bandera 'readExcelLoading'
   * se habra levantado, por lo que cuando 'locations' y 'deliveryCompanies' esten cargadas
   * se procedera a leer el excel
   */
  useEffect(() => {
    if (readExcelLoading && locations && deliveryCompanies && deliveryCompaniesConfig) {
      validateExcel();
    }
  }, [locations, deliveryCompanies, deliveryCompaniesConfig]);

  /**
   * Se podrá continuar a visualizar la lista de envíos si hay uno o más envíos a mostrar
   * y si la bandera "goToShippingDetails" está levantada
   */
  useEffect(() => {
    if (goToShippingDetails && dataShippings.length > 0) {
      continueToShippingList();
    }
  }, [goToShippingDetails, dataShippings, user]);

  /**
   * Setear toda la información necesaria para proceder con el pago de los envíos
   * @param {Object[]} listToRead listado de envíos a pagar, si no se recibe se toma el estado "dataShippings"
   */
  const setDataToPaymentFunction = (listToRead) => {
    setLoadingdataToPayment(true);
    const temporalDataShippings = listToRead || dataShippings;
    const temporalShippingsToPay = orderShippingListToPayment(temporalDataShippings);

    if (
      temporalShippingsToPay.length !== dataShippings.length
      && !shippingsWithConflicts
    ) {
      setShippingsWithConflicts(true);
    } else if (temporalShippingsToPay.length > 0) {
      setShippingsWithConflicts(false);
      setDataShippingsToPay(temporalShippingsToPay);
      setGoToPayShippings(true);
    } else {
      swal(
        'Importante',
        'Seleccione método de pago y transportadora, además de declarar no enviar artículos prohibidos para los envíos.',
        'warning',
      );
    }
    setLoadingdataToPayment(false);
  };

  /**
   * Si se está en la vista de conflictos y un envío es editado, volverá a la sección de listado de envíos
   */
  useEffect(() => {
    if (shippingsWithConflicts) {
      setShippingsWithConflicts(false);
    }
    officeInAddresEnabled(dataShippings, setShowOfficcesAddres);
  }, [dataShippings]);

  /**
   * Se procederá al pago si hay uno o más envíos a pagar y si la bandera "goToPayShippings" está levantada
   */
  useEffect(() => {
    if (dataShippingsToPay.length > 0 && goToPayShippings) {
      setSection('payShipping');
      setGoToPayShippings(false);
    }
  }, [dataShippingsToPay, goToPayShippings]);

  /**
   * Generación de envíos
   */
  const paymentMultipleShippingsFunction = async () => {
    setLoadingSendingGenerating(true);

    const shippingsGenerated = await generationOfShippings(dataShippingsToPay, user._id);
    if (shippingsGenerated.dataShippingsError.length > 0) {
      swal(
        'Importante',
        'Algunos envíos no fueron generados con éxito.',
        'warning',
      );
    } else {
      swal(
        'Excelente',
        'Todos los envíos se han generado exitosamente.',
        'success',
      );
    }
    setDataShippingsSuccess(shippingsGenerated.dataShippingsSuccess);
    setDataShippingsError(shippingsGenerated.dataShippingsError);
    // Actualizacion de datos del usuario, principalmente cash
    const responseUser = await getUserService(user._id);
    dispatch(UpdateUserAction(responseUser));
    setSection('generatedSuccess');
    setLoadingSendingGenerating(false);
  };

  /**
   * Actualización de envio seleccionado individualmente y depende del cambio de 'shippingSelected'
   */
  useEffect(() => {
    if (shippingSelected) {
      const temporalDataShippings = dataShippings.slice().map((shipping) => {
        if (shippingSelected.rowNum === shipping.rowNum) {
          shipping = shippingSelected;
        }
        return shipping;
      });
      setDataShippings(temporalDataShippings);
    }
  }, [shippingSelected]);

  /**
   * Verificación de existencia de envíos para su muestra, si no hay, no es necesario continuar
   */
  useEffect(() => {
    if (dataShippings.length <= 0) {
      setSection('firstSection');
      setFullContainerQuoteMultipleShipping(false);
      setFileName(null);
      setFile(null);
    }
  }, [dataShippings]);

  /**
   * Función que asigna una transportadora y tipo de pago a un envío con base en el tipo de filtro
   */
  useEffect(() => {
    if (selectPaymentMethod !== null) {
      applyFilters(dataShippings, deliveryCompanySelectByFilter, selectPaymentMethod, selectRequestPickupFalse, setDataShippings);
    }
  }, [selectPaymentMethod]);
  useEffect(() => {
    if (deliveryCompanySelectByFilter !== null) {
      setDeliverySelectedForAll(null);
      applyFilters(dataShippings, deliveryCompanySelectByFilter, selectPaymentMethod, selectRequestPickupFalse, setDataShippings);
    }
  }, [deliveryCompanySelectByFilter]);
  useEffect(() => {
    if (selectRequestPickupFalse !== null) {
      applyFilters(dataShippings, deliveryCompanySelectByFilter, selectPaymentMethod, selectRequestPickupFalse, setDataShippings);
    }
  }, [selectRequestPickupFalse]);

  /**
   * Función que devuelve las transportadoras que aparecen en todos los envíos recién cotizados
   */
  const availableDeliveryCompanies = () => {
    const temporalDeliveryCompanies = [];
    deliveryCompanies?.forEach((deliverycompanyGlobal) => {
      let deliverycompanyExistanceInAllRoutes = 0;
      dataShippings.forEach((shipping) => {
        let deliverycompanyExistanceInRoute = false;
        shipping.deliveryCompaniesOptions.forEach((deliveryCompany) => {
          if (
            deliverycompanyGlobal._id === deliveryCompany.deliveryCompanyId
            && !deliverycompanyExistanceInRoute
          ) deliverycompanyExistanceInRoute = true;
        });
        if (
          shipping.deliveryCompaniesOptions.length <= 0
          || deliverycompanyExistanceInRoute
        ) {
          deliverycompanyExistanceInAllRoutes += 1;
        }
      });
      if (deliverycompanyExistanceInAllRoutes === dataShippings.length) {
        const foundObject = findObjectInArray(deliverycompanyGlobal._id, temporalDeliveryCompanies, 'id');
        if (!foundObject) {
          temporalDeliveryCompanies.push({
            id: deliverycompanyGlobal._id,
            img: deliverycompanyGlobal.image,
            name: deliverycompanyGlobal.name,
          });
        }
      }
    });
    return temporalDeliveryCompanies;
  };

  /**
   * Selector de el componente a renderizar dependiendo del estado 'section'
  */
  const sectionSelected = () => {
    switch (section) {
      case 'firstSection':
        return (
          <StepOneQuoteMultipleShippings
            isMobileDevice={isMobileDevice}
            history={history}
            excelWithErrors={excelWithErrors}
            next={() => {
              validateExcel();
            }}
            back={() => {
              setSection('firstSection');
              setFullContainerQuoteMultipleShipping(false);
              setFileName(null);
              setFile(null);
            }}
            file={file}
            setFile={setFile}
            fileName={fileName}
            setFileName={setFileName}
            readExcelLoading={readExcelLoading}
          />
        );
      case 'uploadTemplate':
        return (
          <StepTwoQuoteMultipleShippings
            excelWithErrors={excelWithErrors}
            next={() => {
              validateExcel();
            }}
            back={() => {
              setSection('firstSection');
              setFullContainerQuoteMultipleShipping(false);
              setFileName(null);
              setFile(null);
            }}
            file={file}
            setFile={setFile}
            fileName={fileName}
            setFileName={setFileName}
            readExcelLoading={readExcelLoading}
            isMobileDevice={isMobileDevice}
          />
        );
      case 'excelErrors':
        return (
          <StepExcelErrors
            next={() => {
              setSection('uploadTemplate');
            }}
            back={() => {
              setSection('uploadTemplate');
            }}
            excelErrors={excelErrors}
            numberOfShippings={jsonFromExcel.length}
            isMobileDevice={isMobileDevice}
          />
        );
      case 'sendingList':
        return (
          <StepShippingList
            next={() => {
              setDataToPaymentFunction();
            }}
            back={() => {
              if (shippingsWithConflicts) {
                setShippingsWithConflicts(false);
              } else {
                setSection('firstSection');
                setFullContainerQuoteMultipleShipping(false);
                setFileName(null);
                setFile(null);
                setDeliveryCompanySelectByFilter(null);
                setSelectPaymentMethod(false);
              }
            }}
            deliveryCompanySelectByFilter={deliveryCompanySelectByFilter}
            setDeliveryCompanySelectByFilter={setDeliveryCompanySelectByFilter}
            deliverySelectedForAll={deliverySelectedForAll}
            selectByDeliverySelected={(deliveryCompanyId) => {
              selectDeliveryCompanyByIdForAllShippings(dataShippings, deliveryCompanyId, selectPaymentMethod, setDataShippings);
              const deliveryCompanySelelectedForAll = findObjectInArray(deliveryCompanyId, availableDeliveryCompanies(), 'id');
              setDeliverySelectedForAll(deliveryCompanySelelectedForAll);
              setDeliveryCompanySelectByFilter(null);
            }}
            selectDeliveryCompanyManually={(deliveryCompany, rowNum) => {
              selectDeliveryCompanyManually(dataShippings, deliveryCompany, rowNum, selectPaymentMethod, setDataShippings);
            }}
            selectPaymentMethod={selectPaymentMethod}
            setSelectPaymentMethod={setSelectPaymentMethod}
            selectPaymentMethodManually={(paymentMethod, rowNum) => {
              selectPaymentMethodManually(dataShippings, paymentMethod, rowNum, setDataShippings);
            }}
            loadingdataToPayment={loadingdataToPayment}
            setShippingSelected={setShippingSelected}
            dataShippings={dataShippings}
            deliveryCompanies={availableDeliveryCompanies()}
            notProhibitedArticles={notProhibitedArticles}
            isPosibleContinueToPayment={isPosibleContinueToPayment}
            setNotProhibitedArticles={(value) => {
              setNotProhibitedArticles(value);
              declareNotProhibitedArticles(dataShippings, value, setDataShippings);
            }}
            collectionServiceExistance={validateCollectionServiceExistance(dataShippings)}
            shippingsWithConflicts={shippingsWithConflicts}
            deleteShipping={(type, rowNum) => deleteShippings(dataShippings, type, rowNum, setDataShippings)}
            thePaymentFromCollecionValueIsPosible={thePaymentFromCollecionValueIsPosible}
            isMobileDevice={isMobileDevice}
            showOfficcesAddress={showOfficcesAddress}
            selectRequestPickupFalse={selectRequestPickupFalse}
            setSelectRequestPickupFalse={setSelectRequestPickupFalse}
          />
        );
      case 'payShipping':
        return (
          <PaymentMultipleShippings
            next={() => {
              paymentMultipleShippingsFunction();
            }}
            back={() => {
              setSection('sendingList');
            }}
            dataShippingsToPay={dataShippingsToPay}
            currentBalance={user?.cash}
            loadingSendingGenerating={loadingSendingGenerating}
            rechargeBalance={() => setShowSendingSave(true)}
            isMobileDevice={isMobileDevice}
            onChange={(listShippingStatus) => {
              const listCodeNotifications = [];
              listShippingStatus.forEach((state) => {
                if (state.activeMp === true) listCodeNotifications.push(state.code);
              });
              const preSetDataShippingsToPay = [];
              dataShippingsToPay.forEach((data) => {
                const element = data;
                element.activeNotifications = listCodeNotifications;
                preSetDataShippingsToPay.push(element);
              });
              setDataShippingsToPay(preSetDataShippingsToPay);
            }}
          />
        );
      case 'generatedSuccess':
        return (
          <GeneratedSuccess
            dataShippingsSuccess={dataShippingsSuccess}
            dataShippingsError={dataShippingsError}
            next={() => history.push('/historial-envios')}
            secondNext={() => {
              setSection('uploadTemplate');
              setFileName(null);
              setFile(null);
              setFullContainerQuoteMultipleShipping(false);
            }}
            isMobileDevice={isMobileDevice}
          />
        );
      default:
        return (
          ''
        );
    }
  };

  return (
    <>
      {shippingSelected ? (
        <ShippingDetails
          back={() => setShippingSelected(null)}
          shippingSelected={shippingSelected}
          setShippingSelected={setShippingSelected}
          selectDeliveryCompanyManually={(deliveryCompany, rowNum) => {
            selectDeliveryCompanyManually(dataShippings, deliveryCompany, rowNum, selectPaymentMethod, setDataShippings);
          }}
          deleteShipping={(type, rowNum) => deleteShippings(dataShippings, type, rowNum, setDataShippings)}
          selectPaymentMethodManually={(paymentMethod, rowNum) => {
            selectPaymentMethodManually(dataShippings, paymentMethod, rowNum, setDataShippings);
          }}
          thePaymentFromCollecionValueIsPosible={thePaymentFromCollecionValueIsPosible}
          user={user}
          isMobileDevice={isMobileDevice}
        />
      ) : (
        sectionSelected()
      )}

      {showLogin && (
        <Modal className="quote-shipping__modal-login">
          <Login
            next={() => {
              continueToShippingList();
              setShowLogin(false);
            }}
          />
        </Modal>
      )}

      {showBankData && (
        <Modal className="quote-shipping__modal-bank-data">
          <BankData
            countryCode={user.countryCode}
            next={() => {
              continueToShippingList();
              setShowBankData(false);
            }}
            userIdToUpdate={user._id}
            close={() => {
              setShowBankData(false);
            }}
          />
        </Modal>
      )}

      {showNoDataBank && (
        <Modal
          className="modal__no-data-bank"
        >
          <>
            <div className="header__no-data-bank">
              <div className="left" />
              <div className="center">
                <Typography
                  text="¡Uy! aún no tenemos tus datos bancarios"
                  type="superTitle-italic"
                  className="title__header__no-data-bank"
                />
              </div>
              <div className="right">
                <img
                  src={IconClose}
                  alt="cerrar"
                  onClick={() => {
                    setShowNoDataBank(false);
                  }}
                />
              </div>
            </div>
            <div className="body__no-data-bank">
              <Typography
                type="paragraph"
                text="Para poder realizar el pago de recaudos tus datos bancarios deben ser proporcionados."
              />
            </div>
            <div className="footer__no-data-bank">
              <Button
                text="Continuar"
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={() => {
                  setShowBankData(true);
                  setShowNoDataBank(false);
                }}
              />
            </div>
          </>
        </Modal>
      )}

      {showSendingSave && (
        <Modal
          className="modal__sending-save"
        >
          <>
            <div className="body__sending-save">
              <span>
                Recarga tu saldo, luego vuelve a crear tu envío ya que este no quedará guardado.
              </span>
            </div>
            <div className="footer__sending-save">
              <Button
                text="Continuar"
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={() => {
                  history.push('/recargar-saldo');
                  setShowSendingSave(false);
                }}
              />
              <Button
                text="Cancelar"
                type="secondary"
                onClick={() => {
                  setShowSendingSave(false);
                }}
              />
            </div>
          </>
        </Modal>
      )}

      {loadingSendingGenerating && (
        <Modal>
          <div className="create-sendings">
            <p>Se están generando tus envíos, esto puede tardar un momento.</p>
            <div className="general-loading" />
          </div>
        </Modal>
      )}
    </>
  );
};

QuoteMultipleShippings.propTypes = {
  setFullContainerQuoteMultipleShipping: PropTypes.func,
};

QuoteMultipleShippings.defaultProps = {
  setFullContainerQuoteMultipleShipping: () => null,
};

export default QuoteMultipleShippings;
