/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import {
  cashMicroserviceUrl,
} from '../constants';

export const updateBalance = (data) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = '8c8b408c-eb7a-46d5-8408-cab0a75e9e60';
  axios.post(`${cashMicroserviceUrl}/paymentcampaign`, {
    ...data,
  })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error));
});

export const getTransactionService = (
  filter,
  lastTransactionId,
  numberItemsPage,
) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = '8c8b408c-eb7a-46d5-8408-cab0a75e9e60';
  axios.post(`${cashMicroserviceUrl}/listtransactions`, {
    query: {
      ...filter,
    },
    ...lastTransactionId,
    limit: numberItemsPage,
  })
    .then(({ data }) => resolve(data))
    .catch((e) => reject(e));
});

export const redeemPointsService = (
  userId, positiveBalance,
) => new Promise((resolve, reject) => {
  const endPoint = '/redeemPoints';
  axios.defaults.headers.common['customer-key'] = '8c8b408c-eb7a-46d5-8408-cab0a75e9e60';
  axios.post(`${cashMicroserviceUrl}${endPoint}`, {
    userId,
    moneyInFavor: positiveBalance,
  })
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const redeemPointsServieAndProductService = (
  userId, productOrServiceId,
) => new Promise((resolve, reject) => {
  const endPoint = '/redeemPointsServiceAndProduct';
  axios.defaults.headers.common['customer-key'] = '8c8b408c-eb7a-46d5-8408-cab0a75e9e60';
  axios.post(`${cashMicroserviceUrl}${endPoint}`, {
    userId,
    ProductOrServiceId: productOrServiceId,
  })
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const processCards = (data) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = '8c8b408c-eb7a-46d5-8408-cab0a75e9e60';
  axios.post(`${cashMicroserviceUrl}/elpPayments`, {
    ...data,
  })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error));
});

export const pendingCredit = (userId) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = '8c8b408c-eb7a-46d5-8408-cab0a75e9e60';
  axios.post(`${cashMicroserviceUrl}/getPendingCredit/${userId}`)
    .then((response) => resolve(response.data))
    .catch((error) => reject(error));
});
