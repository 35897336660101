/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { noSpaces } from './validation';

import './inputEdit.scss';

const InputEdit = ({
  label, value, onChange, type, OnFocus, disabled, edit,
  annotation, annotationError, classNameContainer,
  classNameInput, iconLeft, leftIconCustomClass,
}) => {
  const [focus, setFocus] = useState(OnFocus);
  const [lineHidden] = useState(false);

  return (
    <label className={classNameContainer}>
      <div className={`first-container ${focus ? 'focus' : ''} ${edit ? 'edit' : ''}`}>
        {!edit ? (
          <div className="editable-container">
            <div className="label">
              {iconLeft && (
                <img
                  src={iconLeft}
                  alt=""
                  className={leftIconCustomClass}
                />
              )}
              {label}
            </div>
            <div className="value">
              {value}
            </div>
          </div>
        ) : (
          <div className="editable-container-input">
            <span className="editable-container-input__icon-label">
              {iconLeft && (
              <img
                src={iconLeft}
                alt=""
                className={leftIconCustomClass}
              />
              )}
              <div className="label">
                {label}
              </div>
            </span>
            <div className="value">
              <input
                type={type}
                value={value}
                placeholder={value}
                disabled={disabled}
                className={`no-input ${OnFocus ? 'focus' : ''} ${classNameInput}`}
                onChange={(e) => {
                  if (e.target.value && e.target.value !== '') {
                    if (type === 'number') {
                      onChange(parseInt(noSpaces(e.target.value), 10));
                      return null;
                    }
                    if (type === 'number-text' && !/^[0-9]+$/.test(e.target.value)) {
                      onChange(noSpaces(e.target.value));
                      return null;
                    }
                  }
                  onChange(e.target.value);
                  return null;
                }}
                onFocus={() => { if (edit) setFocus(true); }}
                onBlur={() => { if (edit) setFocus(false); }}
              />
            </div>
          </div>
        )}
        <span
          className={`annotation__input-custom ${focus ? 'focus' : ''} ${annotation ? 'visible' : ''} ${lineHidden ? 'lineHidden' : ''}`}
        >
          {annotation && `*${annotation}`}
        </span>
        <span
          className={`annotation__input-custom error ${(annotationError) ? 'visible' : ''} ${lineHidden && !annotationError ? 'lineHidden' : ''}`}
        >
          {annotationError && `*${annotationError}`}
        </span>
      </div>
    </label>
  );
};

InputEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  edit: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number', 'number-text']),
  OnFocus: PropTypes.bool,
  annotation: PropTypes.string,
  annotationError: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameInput: PropTypes.string,
  iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  leftIconCustomClass: PropTypes.string,
};

InputEdit.defaultProps = {
  type: 'text',
  OnFocus: false,
  disabled: false,
  edit: false,
  annotation: null,
  annotationError: null,
  classNameContainer: '',
  classNameInput: '',
  iconLeft: null,
  leftIconCustomClass: '',
};

export default InputEdit;
