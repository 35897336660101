import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { toast, ToastContainer } from 'react-toastify';

import alert from '../../assets/images/toast-alert.svg';
import closeIcon from '../../assets/images/icon-close-color-primary.svg';

import './toast.scss';
import 'react-toastify/dist/ReactToastify.css';

const CustomCloseButton = ({ closeToast }) => (
  <button type="button" className="CustomCloseButton" onClick={() => closeToast()}>
    <img src={closeIcon} alt="close" className="CloseIcon" />
  </button>
);

const notify = (
  autoClose,
  setShowToast,
  options,
  position,
  setToastShown,
  iconLeft,
  progressColor,
) => {
  const { title, message } = options;

  if (!toast.isActive('uniqueToast')) {
    toast(
      <div className="content-toast">
        <img src={iconLeft || alert} alt="alert" className="img-content" />
        <div className="toast-content">
          <span className="toast-title">{title}</span>
          <span className="toast-message">{message}</span>
        </div>
      </div>,
      {
        toastId: 'uniqueToast',
        position,
        autoClose,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        hideProgressBar: false,
        theme: 'light',
        progressClassName: `Toastify__progress-bar-theme--light progress-${progressColor}`,
        onClose: () => {
          setShowToast(false);
          setToastShown(false);
        },
      },
    );
  }
};

const Toast = ({
  autoClose, title, message, position, iconLeft, progressColor,
}) => {
  const [showToast, setShowToast] = useState(true);
  const [toastShown, setToastShown] = useState(false);

  return (
    <div
      onClick={() => setShowToast(false)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setShowToast(false);
        }
      }}
      role="button"
      tabIndex="0"
    >
      <ToastContainer closeButton={<CustomCloseButton />} />
      {showToast
        && !toastShown
        && notify(
          autoClose,
          setShowToast,
          { title, message },
          position,
          setToastShown,
          iconLeft,
          progressColor,
        )}
    </div>
  );
};

Toast.propTypes = {
  autoClose: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.string,
  iconLeft: PropTypes.string,
  position: PropTypes.oneOf(['top-right', 'top-left', 'top-center', 'bottom-left', 'bottom-right', 'bottom-center']),
  progressColor: PropTypes.oneOf(['success', 'error', 'none']),
};

CustomCloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

Toast.defaultProps = {
  autoClose: 7000,
  title: 'Title',
  message: 'Default message',
  position: 'top-right',
  iconLeft: alert,
  progressColor: 'none',
};

export default Toast;
