/**
 * @param {string} address input value
 * @param {function} setAnnotationErrorAddress useState set
 */
const addressValidation = (address, setAnnotationErrorAddress) => {
  setAnnotationErrorAddress(null);
  if (!address) setAnnotationErrorAddress('Ingresa la dirección de origen');
  if (address?.length > 150) setAnnotationErrorAddress('Máximo 150 caracteres');
};

export default addressValidation;
