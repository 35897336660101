import DeliveryTracking from '../assets/images/sectionIcons/Delivery_Tracking.svg';
import DeliveryTrackingOrange from '../assets/images/sectionIcons/Delivery_Tracking_Orange.svg';
import DeliveryTrackingDisabled from '../assets/images/sectionIcons/Delivery_Tracking_Disable.svg';
import DeliveryTrackingWhite from '../assets/images/sectionIcons/Delivery_Tracking_White.svg';
import DeliveryTrackingDark from '../assets/images/sectionIcons/Delivery_Tracking_Dark.svg';

import Wallet from '../assets/images/sectionIcons/Tu_billetera.svg';
import WalletOrange from '../assets/images/sectionIcons/Tu_Billetera_Orange.svg';
import WalletDisabled from '../assets/images/sectionIcons/Tu_Billetera_Disable.svg';
import WalletWhite from '../assets/images/sectionIcons/Tu_Billetera_White.svg';
import WalletDark from '../assets/images/sectionIcons/Tu_Billetera_Dark.svg';

import NoveltiesCenter from '../assets/images/sectionIcons/Novelties.svg';
import NoveltiesCenterSelected from '../assets/images/novelties-center-icon.svg';
import NoveltiesCenterOrange from '../assets/images/sectionIcons/Novelties_Orange.svg';
import NoveltiesCenterDisabled from '../assets/images/sectionIcons/Novelties_Disable.svg';
import NoveltiesCenterWhite from '../assets/images/sectionIcons/Novelties_White.svg';
import NoveltiesCenterDark from '../assets/images/sectionIcons/Novelties_Dark.svg';

import Collection from '../assets/images/sectionIcons/Collection.svg';
import CollectionOrange from '../assets/images/sectionIcons/Collection_Orange.svg';
import CollectionDisabled from '../assets/images/sectionIcons/Collection_Disable.svg';
import CollectionWhite from '../assets/images/sectionIcons/Collection_White.svg';
import CollectionDark from '../assets/images/sectionIcons/Collection_Dark.svg';

import Quote from '../assets/images/sectionIcons/Quote.svg';
import QuoteOrange from '../assets/images/sectionIcons/Quote_Orange.svg';
import QuoteDisabled from '../assets/images/sectionIcons/Quote_Disable.svg';
import QuoteWhite from '../assets/images/sectionIcons/Quote_White.svg';
import QuoteDark from '../assets/images/sectionIcons/Quote_Dark.svg';

import Negotiations from '../assets/images/negotiations-icon.svg';
import NegotiationsColor from '../assets/images/icon-negotiation-active.svg';

import Saas from '../assets/images/saas-icon.svg';
import SaasColor from '../assets/images/icon-saas-active.svg';

import BranchOfficeDark from '../assets/images/icon_sucursales.svg';
import BranchOfficeColor from '../assets/images/icon_sucursales_color.svg';

import usersIcon from '../assets/images/usersImage.svg';
import usersIconDark from '../assets/images/usersImageDark.svg';

import integrationsDark from '../assets/images/integrationsDark.svg';
import integrationsActive from '../assets/images/integrationsActive.svg';

import {
  noveltiesCenterUrl, adminUrl, collectionPaidUrl, businessFlags, operationsUrl,
} from './index';
import { searchBusinessFlag } from '../utils/businessFlag';

const dataSections = {
  notAutenticated: [
    {
      url: '/cotizacion',
      label: 'Cotiza tu envío',
      Icon: Quote,
      IconMonocromatic: QuoteOrange,
      IconLight: QuoteWhite,
      IconDark: QuoteDark,
      IconDisabled: QuoteDisabled,
    },
    {
      url: '/seguimiento-envio',
      label: 'Seguimiento de envíos',
      Icon: DeliveryTracking,
      IconMonocromatic: DeliveryTrackingOrange,
      IconLight: DeliveryTrackingWhite,
      IconDark: DeliveryTrackingDark,
      IconDisabled: DeliveryTrackingDisabled,
    },
    {
      url: '/registro',
      label: 'Registro',
      IconHome: true,
      noLeftMenu: true,
    },
  ],
  userAutenticated: [
    {
      url: '/cotizacion',
      label: 'Cotiza tu envío',
      Icon: Quote,
      IconMonocromatic: QuoteOrange,
      IconLight: QuoteWhite,
      IconDark: QuoteDark,
      IconDisabled: QuoteDisabled,
    },
    {
      url: '/historial-envios',
      label: 'Tus envíos',
      Icon: DeliveryTracking,
      IconMonocromatic: DeliveryTrackingOrange,
      IconLight: DeliveryTrackingWhite,
      IconDark: DeliveryTrackingDark,
      IconDisabled: DeliveryTrackingDisabled,
    },
    {
      url: '/billetera',
      label: 'Tu billetera',
      Icon: Wallet,
      IconMonocromatic: WalletOrange,
      IconLight: WalletWhite,
      IconDark: WalletDark,
      IconDisabled: WalletDisabled,
    },
    {
      url: '/perfil/mobile',
      label: 'Tu perfil',
      noLeftMenu: true,
      Icon: Quote,
      IconLight: QuoteWhite,
    },
    {
      url: noveltiesCenterUrl,
      label: 'Novedades',
      Icon: NoveltiesCenter,
      IconMonocromatic: NoveltiesCenterOrange,
      IconLight: NoveltiesCenterWhite,
      IconDark: NoveltiesCenterDark,
      IconDisabled: NoveltiesCenterDisabled,
      redirect: true,
    },
    {
      url: searchBusinessFlag(businessFlags, 'tusRecaudos')?.enabled ? '/tus-recaudos' : collectionPaidUrl,
      label: 'Tus recaudos',
      Icon: Collection,
      IconMonocromatic: CollectionOrange,
      IconLight: CollectionWhite,
      IconDark: CollectionDark,
      IconDisabled: CollectionDisabled,
      blank: !searchBusinessFlag(businessFlags, 'tusRecaudos')?.enabled,
    },
    searchBusinessFlag(businessFlags, 'integrations')?.enabled ? {
      url: '/integraciones',
      label: 'Integraciones',
      Icon: integrationsActive,
      IconMonocromatic: integrationsDark,
      IconLight: integrationsActive,
      IconDark: integrationsDark,
      IconDisabled: integrationsDark,
    } : {},
  ],
  internalAutenticated: [
    {
      url: `${adminUrl}/recaudos`,
      label: 'Recaudos',
      Icon: Collection,
      IconDark: CollectionDark,
    },
    {
      url: noveltiesCenterUrl,
      label: 'Novedades',
      Icon: NoveltiesCenterSelected,
      IconDark: NoveltiesCenterDark,
      redirect: true,
    },
    {
      url: `${adminUrl}/negociaciones`,
      label: 'Negociaciones',
      Icon: NegotiationsColor,
      IconDark: Negotiations,
      redirect: true,
    },
    {
      url: `${operationsUrl}/modelo-saas`,
      label: 'Modelo SaaS',
      Icon: SaasColor,
      IconDark: Saas,
    },
    {
      url: `${adminUrl}/sucursales`,
      label: 'Sucursales Interrapidísimo',
      Icon: BranchOfficeColor,
      IconDark: BranchOfficeDark,
      redirect: true,
    },
    {
      url: `${adminUrl}/usuarios`,
      label: 'Usuarios',
      Icon: usersIcon,
      IconDark: usersIconDark,
      shadding: true,
    },
  ],
};

export default dataSections;
