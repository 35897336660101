/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '../typography';
import ArrowBack from '../../assets/images/icon-arrow-back-color-primary.svg';

import './headerButtonBack.scss';

// eslint-disable-next-line no-unused-vars
const HeaderButtonBack = ({ isMobileDevice, title }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={`header-button-back ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
        <div className="content-back">
          <img
            src={ArrowBack}
            alt="back"
            onClick={() => navigate(-1)}
          />
          <Typography
            type="superTitle-italic"
            text={title}
            className="button-back"
          />
        </div>
      </div>
    </>
  );
};

HeaderButtonBack.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(HeaderButtonBack);
