import PropTypes from 'prop-types';

const shippingPropTypes = {
  guideNumber: PropTypes.string.isRequired,
  deliveryCompanyImage: PropTypes.string.isRequired,
  observations: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  code: PropTypes.number.isRequired,
  comments: PropTypes.string.isRequired,
  currencyToPay: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  deliveryCompanyName: PropTypes.string.isRequired,
  tracking: PropTypes.arrayOf(PropTypes.shape({})),
  productInformation: PropTypes.shape({
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    large: PropTypes.string.isRequired,
    realWeight: PropTypes.string.isRequired,
    weight: PropTypes.string.isRequired,
    declaredValue: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
  }),
  sender: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    pickupAddress: PropTypes.string.isRequired,
  }).isRequired,
  receiver: PropTypes.shape({
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    cellPhone: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    destinationAddress: PropTypes.string.isRequired,
  }),
  locate: PropTypes.shape({
    originName: PropTypes.string.isRequired,
    originDepName: PropTypes.string.isRequired,
    originDaneCode: PropTypes.string.isRequired,
    destinyName: PropTypes.string.isRequired,
    destinyDepName: PropTypes.string.isRequired,
    destinyDaneCode: PropTypes.string.isRequired,
  }).isRequired,
  requestPickup: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
};

export default shippingPropTypes;
