import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import { compose } from 'redux';

import { capitalizeWords } from '../../../../utils/strings';
import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';
import { typeDelivery } from '../../../../constants/general';

import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import PositionHandler from './inputs';
import Typography from '../../../../components/typography';

import createAndUpdateNegotiationService from './service';

import { requiredData, validateData } from './validate';

import './negotiationSchema.scss';

const CreateNegotiation = ({
  negotiationSchema, deliverySelected, setCurrentStep, updateUser, countryCode,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [creationData, setCreationData] = useState({});
  const [errorsList, setErrorsList] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const supportNegotiationSchema = negotiationSchema.inputs;

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  if (!Number.isInteger(supportNegotiationSchema.length / 2)) {
    supportNegotiationSchema.push({ leftOrRigth: 1 });
  }

  const validateFunction = async () => {
    const required = requiredData({
      errorsList, setErrorsList, data: creationData, inputs: supportNegotiationSchema,
    });

    let validated;
    if (required) {
      validated = validateData({
        errorsList, setErrorsList, data: creationData, inputs: supportNegotiationSchema,
      });
    }

    if (validated.correct && required) {
      const negotiation = {
        deliveryCompany: deliverySelected._id,
        dataToUpdate: {
          ...validated.data,
        },
      };

      const userNegotiation = await createAndUpdateNegotiationService(negotiation);
      if (userNegotiation.email) {
        return { message: 'success', user: { ...userNegotiation } };
      }
      return { message: 'error' };
    }
    return {};
  };

  return (
    <div className="inputs">
      <Typography
        text="Todos los campos son obligatorios"
        type="value"
        className="create-negotiation-title"
      />
      <div className="inputs-body">
        {supportNegotiationSchema.map((input, index) => {
          if (Number.isInteger(input.leftOrRigth / 2)) {
            return (
              <PositionHandler
                inputs={supportNegotiationSchema}
                creationData={creationData}
                setCreationData={setCreationData}
                position={index}
                errorsList={errorsList}
              />
            );
          }
          return null;
        })}
      </div>
      <div className="input-buttons">
        <Button
          onClick={() => setCurrentStep(null)}
          text="Cancelar vinculación"
          type="secondary"
        />
        <Button
          text={`Vincular ${capitalizeWords(typeDelivery[countryCode])}`}
          type={isCountryValid ? 'primary-mx' : 'primary'}
          loading={loading}
          onClick={async () => {
            setLoading(true);
            const validated = await validateFunction();
            if (validated.message === 'success') {
              updateUser(validated.user);
              setShowModal(true);
              setTimeout(() => (setCurrentStep(null)), 1500);
              setLoading(false);
            }
            if (validated.message === 'error') {
              setShowModal(false);
              setShowErrorModal(true);
              setLoading(false);
            }
            setLoading(false);
          }}
        />
      </div>
      {showModal && (
        <Modal closeBackground={() => setShowModal(false)}>
          <div className="modal-wrapper">
            <Typography
              text={`Se ha vinculado a ${deliverySelected.name} exitosamente`}
              type="superTitle-italic"
              className="create-success-title"
            />
          </div>
        </Modal>
      )}
      {showErrorModal && (
        <Modal closeBackground={() => setShowErrorModal(false)}>
          <div className="error-modal-wrapper">
            <div className="error-header">
              <Typography
                text={`¡Ups! hubo un problema al vincular a ${deliverySelected.name} `}
                type="superTitle-italic"
                className="error-header-text"
              />
              <div className="close-wrapper" onClick={() => setShowErrorModal(false)}>
                <div className="close" />
              </div>
            </div>
            <Typography
              text="Inténtalo más tarde o comunícate con un asesor de mipaquete.com"
              type="value"
              className="error-description"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

CreateNegotiation.propTypes = {
  negotiationSchema: PropTypes.shape().isRequired,
  deliverySelected: PropTypes.shape().isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(CreateNegotiation);
