/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import OnboardingNoveltiesCenter from '../onboardingNoveltiesCenter';

import Header from '../header';
import Background from '../background';
import LeftSideMenu from '../leftSideMenu';
import RigthSideMenu from '../rightSideMenu';
import LoadingScreen from '../../screens/loading';
import CompletingDataSteps from '../completingData/modalSteps/implementSteps';

import Home from '../../screens/home';
import LoginScreen from '../../screens/loginScreen';
import FirstLoginScreen from '../../screens/firstLoginScreen';

import QuoteShippingScreen from '../../screens/quoteShippingScreen';
import ResetPasswordScreen from '../../screens/resetPasswordScreen';
import ValidateEmailScreen from '../../screens/validateEmailScreen';
import PublicShippingTrackingScreen from '../../screens/publicShippingTrackingScreen';
import CreateAccountScreen from '../../screens/createAccountScreen';

import EditBankData from '../../screens/editBankData';
import ProfileScreen from '../../screens/profileScreen';
import ShippingDetailsScreen from '../../screens/shippingDetails';
import MyDiscountsScreen from '../../screens/discountsScreen';
import ContactInfoScreen from '../../screens/contactInfoScreen';
import MyAddressesScreen from '../../screens/myAddressesScreen';
import PointsModuleScreen from '../../screens/pointsModuleScreen';
import MyCollectionsScreen from '../../screens/myCollectionsScreen';
import NotificationsScreen from '../../screens/notificationsScreen';
import ChangePasswordScreen from '../../screens/changesPasswordScreen';
import IntegrationsScreen from '../../screens/integrationsScreen';
import MyTransportersSettingsScreen from '../../screens/myTrasportersSettingsScreen';

import WalletScreen from '../../screens/walletScreen';
import NegotiationsScreen from '../../screens/negotiationsScreen';

import FirstSendingScreen from '../../screens/firstSendingScreen';
import RechargeBalanceScreen from '../../screens/rechargeBalanceScreen';
import ShippingHistoryAndQuotesScreen from '../../screens/shippingHistoryAndQuotesScreen';
import ContactInfoEditScreen from '../../screens/contactInfoEditScreen';
import InvoicingDataScreen from '../../screens/invoicingDataScreen';
import BankDataScreen from '../../screens/bankDataScreen';
import InvoicingDataEditScreen from '../../screens/invoicinDataEditScreen';
import ProtectedRouter, { SaaSRouter } from '../protectedRouter/user';
import NoUserRoutes from '../protectedRouter/noUser';
import NoRoleRoutes, { ExistRole } from '../protectedRouter/noRole';
// import ModalFromInterrapidisimo from '../modalFromInterrapidisimo';
import ModalNewTyC from '../modalNewTyC';
import ModalAds from '../modalAds';
import GlobalData from '../globalData';

export const Router = () => (
  <BrowserRouter>
    <Background />
    <Header />
    <RigthSideMenu />
    <LeftSideMenu />
    <OnboardingNoveltiesCenter />
    <CompletingDataSteps />
    <GlobalData />
    {/* <ModalFromInterrapidisimo /> */}
    <ModalAds />
    <ModalNewTyC />
    <Routes>
      {/* user && !operationValidate(user) */}
      <Route exact path="/primer-envio" element={<NoRoleRoutes><FirstSendingScreen /></NoRoleRoutes>} />
      <Route exact path="/recargar-saldo" element={<NoRoleRoutes><RechargeBalanceScreen /></NoRoleRoutes>} />
      <Route exact path="/historial-envios" element={<NoRoleRoutes><ShippingHistoryAndQuotesScreen /></NoRoleRoutes>} />
      <Route exact path="/detalle-envio/:code" element={<NoRoleRoutes><ShippingDetailsScreen /></NoRoleRoutes>} />

      {/* user logic user && !operationValidate(user) && user.clientType === 'SaaS' */}
      <Route exact path="/mis-negociaciones" element={<SaaSRouter><NegotiationsScreen /></SaaSRouter>} />

      {/* user logic user && without role */}
      <Route exact path="/billetera" element={<NoRoleRoutes><WalletScreen /></NoRoleRoutes>} />
      <Route exact path="/integraciones" element={<NoRoleRoutes><IntegrationsScreen /></NoRoleRoutes>} />

      {/* user logic */}
      <Route exact path="/perfil" element={<ProtectedRouter><ProfileScreen /></ProtectedRouter>} />
      <Route exact path="/perfil/informacion-de-contacto" element={<ProtectedRouter><ContactInfoScreen /></ProtectedRouter>} />
      <Route exact path="/editar-perfil/informacion-de-contacto" element={<ProtectedRouter><ContactInfoEditScreen /></ProtectedRouter>} />
      <Route exact path="/perfil/datos-de-facturacion" element={<ProtectedRouter><InvoicingDataScreen /></ProtectedRouter>} />
      <Route exact path="/editar-perfil/datos-de-facturacion" element={<ProtectedRouter><InvoicingDataEditScreen /></ProtectedRouter>} />
      <Route exact path="/perfil/datos-bancarios" element={<ProtectedRouter><BankDataScreen /></ProtectedRouter>} />
      <Route exact path="/perfil/cambiar-contrasena" element={<ProtectedRouter><ChangePasswordScreen /></ProtectedRouter>} />
      <Route exact path="/actualizar-datos-bancarios/:code" element={<ProtectedRouter><EditBankData /></ProtectedRouter>} />
      <Route exact path="/mis-descuentos" element={<ProtectedRouter><MyDiscountsScreen /></ProtectedRouter>} />
      <Route exact path="/tus-recaudos" element={<ProtectedRouter><MyCollectionsScreen /></ProtectedRouter>} />
      <Route exact path="/notificaciones-a-destinatarios" element={<ProtectedRouter><NotificationsScreen /></ProtectedRouter>} />
      <Route exact path="/mis-direcciones" element={<ProtectedRouter><MyAddressesScreen /></ProtectedRouter>} />
      <Route exact path="/mis-transportadoras" element={<ProtectedRouter><MyTransportersSettingsScreen /></ProtectedRouter>} />
      <Route exact path="/acumula-puntos" element={<PointsModuleScreen />} />

      {/* !user logic  */}
      <Route exact path="/registro" element={<NoUserRoutes><CreateAccountScreen /></NoUserRoutes>} />
      <Route exact path="/validar-correo" element={<NoUserRoutes><ValidateEmailScreen /></NoUserRoutes>} />
      <Route exact path="/restaurar-contrasena" element={<NoUserRoutes><ResetPasswordScreen /></NoUserRoutes>} />
      <Route exact path="/seguimiento-envio" element={<NoUserRoutes><PublicShippingTrackingScreen /></NoUserRoutes>} />
      <Route exact path="/ingreso" element={<NoUserRoutes><LoginScreen /></NoUserRoutes>} />

      <Route path="/cotizacion" element={<ExistRole><QuoteShippingScreen /></ExistRole>} />
      <Route exact path="/primer-ingreso" element={<FirstLoginScreen />} />
      <Route path="*" loader={<LoadingScreen />} element={<ExistRole><QuoteShippingScreen /></ExistRole>} />
      <Route path="/" loader={<LoadingScreen />} element={<Home />} />
    </Routes>
  </BrowserRouter>
);

export default {
  Router,
};
