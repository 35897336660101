/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { routeTypes } from '../../../../constants/general';

import CardDesktop from './cardDesktop/cardDesktop';
import CardMobile from './cardMobile/cardMobile';

import './deliveryCardToList.scss';

const routeTypeValidation = (type) => routeTypes[type] || 'No especificado';

const DeliveryCardToList = ({
  id,
  deliveryName,
  deliveryImg,
  deliveryTime,
  shippingValue,
  shippingRealValue,
  collectionValue,
  deliveryScore,
  onClick,
  delivery,
  setHideDetails,
  setShowDetails,
  detailsCostElement,
  isMobileDevice,
  focused,
  details,
  routeType,
  pickupService,
  officeAddress,
}) => {
  const handleShowDetail = (e) => {
    e.stopPropagation();
    if (delivery && delivery.id === id) {
      setHideDetails();
    } else {
      setShowDetails();
    }
  };

  return (
    <div
      className={`delivery-card-to-list ${
        isMobileDevice ? 'mobile-mode' : 'desktop-mode'
      } ${focused ? 'focused' : ''}`}
      onClick={() => onClick()}
      onKeyUp={() => onClick()}
      role="button"
      aria-hidden
    >
      {isMobileDevice ? (
        <CardMobile
          pickupService={pickupService}
          deliveryTime={deliveryTime}
          officeAddress={officeAddress}
          deliveryImg={deliveryImg}
          detailsCostElement={detailsCostElement}
          id={id}
          deliveryScore={deliveryScore}
          deliveryName={deliveryName}
          routeType={routeType}
          shippingValue={shippingValue}
          isMobileDevice={isMobileDevice}
          shippingRealValue={shippingRealValue}
          collectionValue={collectionValue}
          details={details}
          delivery={delivery}
          routeTypeValidation={routeTypeValidation}
          handleShowDetail={handleShowDetail}
        />
      ) : (
        <CardDesktop
          pickupService={pickupService}
          deliveryTime={deliveryTime}
          officeAddress={officeAddress}
          deliveryImg={deliveryImg}
          detailsCostElement={detailsCostElement}
          id={id}
          delivery={delivery}
          deliveryScore={deliveryScore}
          deliveryName={deliveryName}
          routeType={routeType}
          shippingValue={shippingValue}
          shippingRealValue={shippingRealValue}
          collectionValue={collectionValue}
          details={details}
          routeTypeValidation={routeTypeValidation}
          handleShowDetail={handleShowDetail}
        />
      )}
    </div>
  );
};

DeliveryCardToList.propTypes = {
  id: PropTypes.string.isRequired,
  deliveryName: PropTypes.string.isRequired,
  deliveryImg: PropTypes.string.isRequired,
  deliveryTime: PropTypes.number.isRequired,
  shippingValue: PropTypes.number.isRequired,
  shippingRealValue: PropTypes.number,
  collectionValue: PropTypes.number,
  deliveryScore: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  setShowDetails: PropTypes.func.isRequired,
  setHideDetails: PropTypes.func.isRequired,
  delivery: PropTypes.any,
  detailsCostElement: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  focused: PropTypes.bool,
  details: PropTypes.bool,
  routeType: PropTypes.string.isRequired,
  pickupService: PropTypes.bool.isRequired,
  officeAddress: PropTypes.string,
};

DeliveryCardToList.defaultProps = {
  collectionValue: null,
  delivery: null,
  focused: false,
  details: true,
  shippingRealValue: null,
  officeAddress: null,
};

export default DeliveryCardToList;
