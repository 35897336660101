import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

import { cancelSendingService, downloadPdfRequired, getShipmentService } from '../../services/sendings';
import { findImageIntoDeliveries } from '../../utils/generals';
import ShippingDetailsDesktop from './ShippingDetailsDesktop';
import downLoadPdfToUrl from '../../utils/downLoadPdfToUrl';

import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';
import ShippingDetailsMobile from './ShippingDetailsMobile';

import './ShippingDetails.scss';

const CANCELLED_STATUS = 'Envío cancelado';

const cancellationIsValid = (shipment) => {
  const validStates = ['Recolección programada', 'Envío programado', 'Procesando tu envío', 'Envío pendiente por pago'];
  const shippingCancelled = shipment.state === CANCELLED_STATUS;
  const stateShipping = shipment.state;
  return validStates.includes(stateShipping) && !shippingCancelled;
};

const ShippingDetailsScreen = () => {
  const [shippment, setShippment] = useState(null);
  const [cancellingShipping, setCancellingShipping] = useState(null);
  const [executingDownload, setExecutingDownload] = useState(false);

  const [isValidCancelation, setIsValidCancelation] = useState(true);

  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const deliveryCompanies = useSelector((state) => state.globalData.deliveryCompanies);
  const noveltyCodes = useSelector((state) => state.globalData.noveltyCodes);
  const businessFlags = useSelector((state) => state.globalData.businessFlags);
  const { code } = useParams();
  const navigate = useNavigate();
  const back = () => (navigate(-1));

  useEffect(() => {
    if (!shippment) {
      getShipmentService({ filters: { mpCode: parseInt(code, 10) } }).then((response) => {
        const shippingSelected = response.shipments[0];
        const deliveryCompanyImage = findImageIntoDeliveries(
          shippingSelected.deliveryCompany, deliveryCompanies,
        );
        setIsValidCancelation(cancellationIsValid(shippingSelected));
        setShippment({ ...shippingSelected, deliveryCompanyImage });
      });
    }
  }, []);
  const { user } = useSelector((state) => state.auth || {});

  const shippingCancelFunction = async (mpCodeParam) => {
    setCancellingShipping(true);
    try {
      const response = await cancelSendingService(null, parseInt(mpCodeParam, 10));
      if (response) {
        swal('¡Envío Cancelado!', 'El envío ha sido cancelado exitosamente.');
      }
    } catch {
      swal('Error', 'Ha habido un problema al intentar cancelar el envío, es posible que ya se haya cancelado o el estado actual no lo permita, de no ser asi, intentelo de nuevo.');
    } finally {
      setCancellingShipping(false);
    }
  };

  const downloadGuideFunction = () => {
    setExecutingDownload(true);
    downloadPdfRequired(shippment, true).then((res) => {
      downLoadPdfToUrl(res);
      setExecutingDownload(false);
    }).catch(() => {
      setExecutingDownload(false);
      swal('Error', 'Ha habido un problema al intentar descargar el envío, es posible que ya se haya cancelado o el estado actual no lo permita, de no ser asi, intentelo de nuevo.');
    });
  };

  if (!shippment) return <h1>Cargando</h1>;

  return isMobileDevice ? (
    <ContainerSectionMobile>
      <ShippingDetailsMobile
        shipping={shippment}
        back={back}
        noveltyCodes={noveltyCodes}
        businessFlags={businessFlags}
        user={user}
        cancellingShipping={cancellingShipping}
        shippingCancelFunction={shippingCancelFunction}
        cancellationIsValid={isValidCancelation}
        downloadGuideFunction={downloadGuideFunction}
        executingDownload={executingDownload}
      />
    </ContainerSectionMobile>
  ) : (
    <ContainerSectionDesktop>
      <ShippingDetailsDesktop
        shipping={shippment}
        back={back}
        noveltyCodes={noveltyCodes}
        businessFlags={businessFlags}
        user={user}
        cancellingShipping={cancellingShipping}
        shippingCancelFunction={shippingCancelFunction}
        cancellationIsValid={isValidCancelation}
        downloadGuideFunction={downloadGuideFunction}
        executingDownload={executingDownload}
      />
    </ContainerSectionDesktop>
  );
};

export default ShippingDetailsScreen;
