import { useSelector } from 'react-redux';
import React from 'react';

import Negotiations from './negotiations';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './negotiationsScreen.scss';

const NegotiationsScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile className="bargainings-information">
        <Negotiations />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <Negotiations />
      </ContainerSectionDesktop>
    )
  );
};

export default NegotiationsScreen;
