/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import fields from '../../../../config/quoteShippingFields.json';

import SecondStep from '../../stepsComponents/stepTwo/stepTwo';
import Modal from '../../../modal';
import Typography from '../../../typography';
import ModalChildren from '../../../modalChildren';

import '../steps.scss';

const StepThree = ({
  close,
  next,
  back,
  errorsList,
  setErrorsList,
  stepsData,
  handleButton,
}) => {
  const { user } = useSelector((state) => state.auth);
  const countryCode = useSelector((state) => state.country);
  const [listFields, setListFields] = useState([]);

  useEffect(() => {
    setListFields(fields.country[countryCode]?.fieldsCompletingData
      .filter((item) => item.modalStep === 3));
  }, [fields.country[countryCode]]);

  const fieldsFormMexico = {
    emailForBilling: user?.invoicingData?.emailForBilling || user?.email || '',
    postalCode: user?.invoicingData?.postalCode || '',
    taxResidence: user?.invoicingData?.taxResidence || '',
    CFDI: user?.invoicingData?.CFDI || '',
    methodOfPayment: user?.invoicingData?.methodOfPayment || '',
  };

  const fieldsFormColombia = {
    emailForBilling: user?.invoicingData?.emailForBilling || user?.email || '',
    locationCode: user?.invoicingData?.locationCode || stepsData?.firstLocationCode || '',
    locationName: user?.invoicingData?.locationName || stepsData?.locationName || '',
    address: user.invoicingData?.address || stepsData?.address || '',
    cellPhone: user?.cellPhone || '',
  };

  const countryFields = countryCode === '484' ? fieldsFormMexico : fieldsFormColombia;

  return (
    <Modal>
      <ModalChildren
        headerText="Completa tus datos (3 de 3)"
        close={close}
        back={back}
        className="stepCompletingData-mexico"
      >
        <SecondStep
          next={(values) => next(values)}
          valuesForm={countryFields}
          listFields={listFields}
          errorsList={errorsList}
          setErrorsList={setErrorsList}
          changes={stepsData}
          handleButton={(value) => {
            handleButton({
              ...value,
              locationCode: value.locationCode,
            });
          }}
          titleButton="Guardar cambios"
          children={(
              countryCode === '170' && (
                <Typography type="value">
                  Recibirás tu
                  {' '}
                  {' '}
                  <span className="bold">factura</span>
                  {' '}
                  {' '}
                  con los siguientes datos. Verifica que sean los correctos.
                </Typography>
              )
            )}
        />

      </ModalChildren>
    </Modal>
  );
};

StepThree.propTypes = {
  close: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setErrorsList: PropTypes.func.isRequired,
  stepsData: PropTypes.shape({
    firstLocationCode: PropTypes.string,
    address: PropTypes.string,
    locationName: PropTypes.string,
  }).isRequired,
  handleButton: PropTypes.func,
};

StepThree.defaultProps = {
  handleButton: () => {},
};
export default StepThree;
