import React from 'react';
import PropTypes from 'prop-types';

import './stepProgressBar.scss';

const StepProgressBar = ({
  steps, currentStep, orientation, noAutomaticMeasure, className, sizePoints,
}) => (
  <div
    className={`container-step-progress ${orientation} ${noAutomaticMeasure ? 'no-automatic' : ''} ${className}`}
  >
    <div id="container-lines__step-progress">
      {steps.map((step, index) => (
        index > 0 && (
          <div
            key={`setp-line-${index.toString()}`}
            className={`step-line line-${index}`}
          >
            <div
              className={`content_step-line ${step.success || index + 1 === currentStep ? 'success' : ''} ${step.error ? 'error' : ''}`}
            />
          </div>
        )
      ))}
    </div>
    <div id="container-points__step-progress">
      {steps.map((step, index) => (
        <div
          key={`setp-point-${index + 1}`}
          className={`step-point point-${index + 1}`}
          style={{
            width: `${sizePoints}px`,
            height: `${sizePoints}px`,
          }}
        >
          <div
            className={`content_step-point ${step.success || index + 1 === currentStep ? 'success' : ''} ${step.error ? 'error' : ''}`}
          />
        </div>
      ))}
    </div>
  </div>
);

StepProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentStep: PropTypes.number.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  noAutomaticMeasure: PropTypes.bool,
  className: PropTypes.string,
  sizePoints: PropTypes.number,
};

StepProgressBar.defaultProps = {
  orientation: 'horizontal',
  noAutomaticMeasure: false,
  className: '',
  sizePoints: 10,
};

export default StepProgressBar;
