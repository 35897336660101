/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { React, useState } from 'react';
import {
  stepOne, stepTwo, stepThree, stepFour, stepFive, stepSix,
} from './modalSteps/stepOne';

import Modal from '../modal';
import ScreenModal from './screen';
import Button from '../button';
import ModalChildren from '../modalChildren';

import Logo from '../../assets/images/onBoarding/Pop_Up.png';
import LogoMobile from '../../assets/images/onBoarding/Pop_Up_mobile.png';

import { useValidationCountry, useFetchIpData } from '../../utils/validationCountry';

import './onboarding.scss';

const removeLocalStorage = () => {
  window.localStorage.removeItem('finishedOnboarding');
  window.sessionStorage.removeItem('finishedOnboarding');
};

const Onboarding = ({
  isMobileDevice, history,
}) => {
  removeLocalStorage();
  const [showModal, setShowModal] = useState(
    (JSON.parse(window.localStorage.getItem('finishedOnboarding')) === null || JSON.parse(window.localStorage.getItem('finishedOnboarding')) === false)
    && JSON.parse(window.sessionStorage.getItem('finishedOnboarding')) === null,
  );
  const [step, setStep] = useState(0);

  const nextStep = () => {
    if (step < 7) setStep(step + 1);
  };

  const previous = () => {
    if (step > 0) setStep(step - 1);
  };

  const localStorage = () => {
    if (JSON.parse(window.localStorage.getItem('finishedOnboarding')) === false) {
      window.localStorage.setItem('finishedOnboarding', true);
    } else {
      window.localStorage.setItem('finishedOnboarding', false);
      window.sessionStorage.setItem('finishedOnboarding', true);
    }
  };

  const showModalFunction = () => {
    localStorage();
    setShowModal(!showModal);
  };

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const ScreenOne = () => (
    <Modal
      className={`desktop-modal ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
      closeBackground={showModalFunction}
    >
      <ModalChildren close={showModalFunction} className="onboarding-modal-children">
        <div className="img">
          <img src={isMobileDevice ? LogoMobile : Logo} alt="Mipaquete.com" />
        </div>
        {/* <Typography
          text="¡Se ha actualizado!"
          type="field-gray"
          className="titleCustom"
        /> */}
        <Button
          text="Quiero registrarme"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          className="btnNext"
          onClick={() => {
            history.push('/registro');
            showModalFunction();
          }}
        />
        <div className="text-container">
          <h3 className="new-color-gray organice-paragraph">
            Aplican TyC. Infórmate más
            <span onClick={() => { window.open('https://www.mipaquete.com/tyc-campanas-comerciales/', 'WindowName', 'noopener'); }}> aquí,</span>
            {' '}
            el saldo será aplicado después de 24 horas de realizar el registro.
          </h3>
          {/* <Typography
            text="Aplican TyC. Infórmate más aquí, el saldo será aplicado después de 24 horas de realizar el registro."
            type="field-dark-gray"
            className="new-color-gray organice-paragraph"
          /> */}
          {/* <br />
          <Typography
            text="Podrás ganar muchos puntos para que los redimas como prefieras ¡Te esperamos!"
            type="field-dark-gray"
            className="new-color-gray organice-paragraph"
          />  */}
        </div>
        {/* <div className="points">
          <div className={step === 0 ? 'currentStep' : ''} />
          <div className={step === 1 ? 'currentStep' : ''} />
          <div className={step === 2 ? 'currentStep' : ''} />
          <div className={step === 3 ? 'currentStep' : ''} />
          <div className={step === 4 ? 'currentStep' : ''} />
          <div className={step === 5 ? 'currentStep' : ''} />
          <div className={step === 6 ? 'currentStep' : ''} />
        </div> */}
        {/* <div className="content-buttons">
          { <Button
            text="No volver a mostrar"
            type="secondary"
            onClick={() => showModalFunction()}
            className={`btnNoShowMore ${JSON.parse(window.localStorage.getItem('finishedOnboarding')) === false ? 'visible' : 'hidden'}`}
          />
        </div> */}
      </ModalChildren>
    </Modal>
  );

  const Steps = {
    1: stepOne,
    2: stepTwo,
    3: stepThree,
    4: stepFour,
    5: stepFive,
    6: stepSix,
  };

  return (
    <div className="onboarding">
      {showModal && step === 0 && (Steps[step])}
      {showModal && step > 0 && (
        <ScreenModal
          currentStep={Steps[step]}
          next={nextStep}
          back={previous}
          step={step}
          localStorage={localStorage}
        />
      )}
    </div>
  );
};

Onboarding.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(Onboarding);
