/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  sendingsMicroserviceUrl,
  customerKeyUsersMicroservice,
} from '../constants';
import { countryCode, prefixCountry, typeDelivery } from '../constants/general';
import STATES from '../enums/states';
import { replaceCharacter } from '../utils/strings';

/**
 * servicio para generacion de envios
 * @param {Object} deliverySelected - informacion dela transportadora seleccionada
 * @param {Object} user - datos del usuario autenticado
 * @param {String} senderInfo - datos del remitente
 * @param {String} senderAddress - direccion del remitente
 * @param {Object} shippingData  - data de todos los pasos para la creacion del envio
 * @param {Number} price - valor del envio
 * @param {Boolean} collectionService - indicacion si hay servicio de recoleccion o no
 * @param {Number} paymentType - tipo de pago, 101 para pago con saldo, 102 pago con recoleccion
 * @param {Number} collectionValue - valor que debe recibir el repartidor
 * @param {String} bankName - nombre del banco (si hay sercivio de recoleccion)
 * @param {String} typeAccount - tipo de cuenta bancaria (si hay sercivio de recoleccion)
 * @param {Number} numberAccount - numero de cuenta bancaria (si hay sercivio de recoleccion)
 * @param {String} nameBeneficiary - nombre del beneficiario (si hay sercivio de recoleccion)
 * @param {Number} nitBeneficiary - numero de identifiacion del beneficiario (si hay sercivio de recoleccion)
 * @param {String} nitTypeBeneficiary - typo de identifiacion del beneficiario (si hay sercivio de recoleccion)
 * @param {Number} saleValue - valor de la venta
 * @param {Number} collectionValue - valor a recolectar
 * @param {Number} collectionCommission - comision por recoleccion
 * @param {Number} sendingValue - valor del envio
 * @param {String} channel - canal utilizado para generar el envío
 */
export const createSendingService = (
  deliverySelected,
  user,
  senderInfo,
  senderAddress,
  shippingData,
  price,
  collectionService,
  paymentType,
  bankName,
  typeAccount,
  numberAccount,
  nameBeneficiary,
  nitBeneficiary,
  nitTypeBeneficiary,
  saleValue,
  collectionValue,
  collectionCommission,
  sendingValue,
  channel,
  activeNotifications,
) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.post(`${sendingsMicroserviceUrl}/createSendings`, {
    sender: {
      name: senderInfo.senderName,
      surname: senderInfo.senderLastName,
      cellPhone: senderInfo.senderCellPhone,
      prefix: prefixCountry[user.countryCode],
      email: senderInfo.senderEmail,
      pickupAddress: senderAddress,
      nit: senderInfo.senderIdentificationNumber?.toString() ?? '0',
      nitType: senderInfo.senderIdentificationType,
    },
    receiver: {
      name: shippingData.receiverName,
      surname: shippingData.receiverLastName,
      cellPhone: shippingData.receiverCellphone,
      prefix: prefixCountry[user.countryCode],
      ...(shippingData.receiverEmail) ? { email: shippingData.receiverEmail } : {},
      destinationAddress: (shippingData.destinationAddress && shippingData.reference)
        ? `${shippingData.destinationAddress} ${shippingData.reference}`
        : shippingData.destinationAddress
        || `Destinatario recoge en oficina de la ${typeDelivery[user.countryCode]}: ${deliverySelected.officeAddress || ''}`,
      ...(shippingData.receiverDocumentNumber) ? {
        nit: shippingData.receiverDocumentNumber,
        nitType: 'N/A',
      } : {},
    },
    productInformation: {
      quantity: shippingData.quantity,
      width: shippingData.width,
      large: shippingData.length,
      height: shippingData.height,
      weight: parseInt(shippingData.unitOfMeasurement === 'g' ? Math.ceil(shippingData.weight / 1000) : shippingData.weight, 10),
      declaredValue: user.countryCode === '484' ? parseFloat(replaceCharacter(shippingData.declaredValue)) : shippingData.declaredValue,
      realWeight: deliverySelected.realWeightVolume, // peso calculado con el factor peso/Volumen
      forbiddenProduct: shippingData.prohibitedItems,
      productReference: shippingData.productType,
    },
    locate: {
      originDaneCode: shippingData.originCode,
      destinyDaneCode: shippingData.destinyCode,
      originCountryCode: countryCode[user.countryCode],
      destinyCountryCode: countryCode[user.countryCode],
      ...(shippingData.interCode) ? { interCode: shippingData.interCode } : {},
    },
    channel: channel || 'página transaccional',
    price: price,
    deliveryTime: deliverySelected.shippingTime,
    pickupTime: deliverySelected.pickupTime,
    user: user._id,
    deliveryCompanyName: deliverySelected.deliveryCompanyName,
    deliveryCompany: deliverySelected.deliveryCompanyId,
    description: user.countryCode === '170' ? shippingData.productDescription : shippingData.productType,
    comments: shippingData.recommendations || 'Producto delicado',
    collectionService: collectionService,
    paymentType: paymentType,
    valueCollection: collectionValue,
    requestPickup: shippingData.collectionOrTakeToOffice === 'collectionInAddress',
    shippingTime: deliverySelected.shippingTime,
    ...(bankName && typeAccount && numberAccount && nameBeneficiary && nitBeneficiary && nitTypeBeneficiary) ? {
      bankInformation: {
        bank: bankName,
        typeAccount: typeAccount,
        numberAccount: numberAccount,
        nameBeneficiary: nameBeneficiary,
        nitBeneficiary: nitBeneficiary,
        nitTypeBeneficiary: nitTypeBeneficiary,
      },
    } : {},
    adminTransactionData: {
      saleValue: saleValue,
    },
    ...(activeNotifications) ? { activeNotifications } : {},
  })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      if (e.response.data.message.code === 306) {
        resolve({ mpCode: e.response.data.message.detail.split(' ')[4] });
      }
      reject(e);
    });
});

export const getSendingsService = (
  userId,
  pageNumber,
  pageSize,
  initialDateConfirmation,
  finalDateConfirmation,
  initialDateDelivery,
  finalDateDelivery,
  deliveryCompanies,
  services,
  states,
  mpCode,
  guideNumber,
  receiverCellphone,
  senderNit,
  destinyLocationCode,
) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.post(`${sendingsMicroserviceUrl}/getSendings/${pageNumber}`, {
    ...initialDateConfirmation && finalDateConfirmation ? {
      confirmationDate: {
        init: initialDateConfirmation,
        end: finalDateConfirmation,
      },
    } : {},
    ...initialDateDelivery && finalDateDelivery ? {
      confirmationDate: {
        init: initialDateDelivery,
        end: finalDateDelivery,
      },
    } : {},
    ...userId ? { user: userId } : {},
    ...deliveryCompanies ? { deliveryCompany: deliveryCompanies } : {},
    ...services ? { service: services } : {},
    ...states ? { state: states } : {},
    ...mpCode ? { mpCode: mpCode } : {},
    ...guideNumber ? { guideNumber: guideNumber } : {},
    ...receiverCellphone ? { receiverCellphone: receiverCellphone } : {},
    ...senderNit ? { senderNit: senderNit } : {},
    ...destinyLocationCode ? { destinyLocation: destinyLocationCode } : {},
    pageSize: pageSize || 25,
  })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      reject(e);
    });
});

const trackingParams = (mpCode, guideNumber, id) => {
  if (id) return `id=${id}`;
  if (mpCode) return `mpCode=${mpCode}`;
  return `guideNumber=${guideNumber}`;
};

/**
  * servicio para seguimiento de envios
  * @param {String} mpCode - codigo asignado a un envio por mi paquete (codigo MP)
  * @param {String} guideNumber - numero de la guia otorgado por la transportadora
  * @param {String} id - id del envío a buscar
*/
export const getSendingTrackingService = ({ mpCode, guideNumber, id }) => new Promise((resolve, reject) => {
  const endpoint = `/getSendingsTracking?${trackingParams(mpCode, guideNumber, id)}`;
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.get(`${sendingsMicroserviceUrl}${endpoint}`)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      // swal('Error', 'No hemos encontrar un envio con los parametros ingresados.');
      reject(e);
    });
});

/**
  * servicio para descarga de informes de envios
  * @param {Object} query - objeto para la descarga del informe
  * @param {Number} page - numero de la pagina
*/
export const getSendingToExcel = (query, page) => new Promise((resolve, reject) => {
  const endpoint = `/getSendings/${page}`;
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.post(`${sendingsMicroserviceUrl}${endpoint}`, query)
    .then((response) => {
      resolve(response.data);
    })
    .catch((e) => { reject(e); });
});

export const getSendingToExcelUsers = (query, page) => new Promise((resolve, reject) => {
  const endpoint = `/getSendings/users/${page}`;
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.post(`${sendingsMicroserviceUrl}${endpoint}`, query)
    .then((response) => {
      resolve(response.data);
    })
    .catch((e) => { reject(e); });
});

export const cancelSendingService = (
  sendingId,
  mpCode,
) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.post(`${sendingsMicroserviceUrl}/cancelSending`, {
    ...sendingId ? { sendingId } : {},
    ...mpCode ? { mpCode } : {},
  })
    .then(({ data }) => resolve(data))
    .catch((e) => {
      reject(e);
    });
});

export const getSendingsPriceDifference = () => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.defaults.headers.common['session-tracker'] = uuidv4();
  axios.get(`${sendingsMicroserviceUrl}/sendingsPriceDifference`)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => { reject(e); });
});

export const getAverageSendings = () => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.defaults.headers.common['session-tracker'] = uuidv4();
  axios.get(`${sendingsMicroserviceUrl}/averageSendings`)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => { reject(e); });
});

export const getDeliveryConfirmation = (guideNumber, deliveryCompanyId) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.defaults.headers.common['session-tracker'] = uuidv4();
  axios.get(`${sendingsMicroserviceUrl}/getDeliveryConfirmation?guideNumber=${guideNumber}&deliveryCompanyId=${deliveryCompanyId}`)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => { reject(e); });
});

export const getShipmentService = (requestData) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.defaults.headers.common['session-tracker'] = uuidv4();
  axios.post(`${sendingsMicroserviceUrl}/getShipments`, requestData)
    .then(({ data }) => resolve(data))
    .catch((error) => reject(error));
});

const getPDFConfirmation = (shipping) => getDeliveryConfirmation(shipping.guideNumber, shipping.deliveryCompany);

export const isDelivered = (shipping) => shipping.state === STATES.delivered;

export const downloadPdfRequired = async (shipping, pdfConfirmation, index) => {
  if (isDelivered(shipping) && pdfConfirmation) {
    const linkPdf = await getPDFConfirmation(shipping);
    if (linkPdf?.deliveryConfirmation) return linkPdf.deliveryConfirmation;
  }

  return shipping.pdfGuide[index || 0] || null;
};
