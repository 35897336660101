/* eslint-disable no-restricted-syntax */
import dayjs from 'dayjs';
import configS3 from '../../../config/nocompanyconfig.fron.json';

// specific dates of every month
export const showInCourtDates = () => {
  const { courtDays } = configS3;
  const currentDate = dayjs().get('date');
  for (const courtDay of courtDays) {
    const firtsDayBefore = dayjs().set('date', courtDay).subtract(3, 'day');
    if (currentDate >= firtsDayBefore.$D && currentDate <= courtDay) {
      return true;
    }
  }
  return false;
};

// wednesday is 3 and thursday is 4
export const showInCourtDays = () => {
  const { courtInDaysOfWeek } = configS3;
  const currentDay = new Date().getDay();

  if (courtInDaysOfWeek.includes(currentDay)) return true;

  return false;
};
