import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import { toCurrencyCop } from '../../../../../utils/strings';
import { finalPrice, collectionServiceValue, collectionServiceCommision } from '../../../../../utils/prices';
import { addDiscount } from '../../../../../utils/generals';

import CheckBox from '../../../../../components/checkBox';
import DeliveryCardToList from '../../deliveryCardToList';
import Typography from '../../../../../components/typography';
import Onboarding from '../../../../../components/onboarding';

import '../stepsQuoteShipping.scss';

const DeliveriesList = ({
  deliveriesList,
  addShippingToCollectionValue,
  collectionService,
  setAddShippingToCollectionValue,
  next,
  isMobileDevice,
  saleValue,
  deliveryCompaniesConfig,
  continueToLastDetails,
}) => {
  const user = useSelector((state) => state.auth.user);

  const [deliverySelected, setDeliverySelected] = useState(null);
  const [showOnboarding, setShowOnborading] = useState(null);

  useEffect(() => {
    setTimeout(() => (
      setShowOnborading(true)
    ), 4000);
  }, []);

  const functionDiscount = (delivery) => {
    const shippingCost = finalPrice(
      delivery.shippingCost,
      collectionService,
      addShippingToCollectionValue,
      delivery.collectionCommissionWithRate,
      delivery.collectionCommissionWithOutRate,
    );

    const shippingCostshippingCost = finalPrice(
      delivery.shippingRealCost ?? delivery.shippingCost,
      collectionService,
      addShippingToCollectionValue,
      delivery.realCollectionCommissionWithRateValue ?? delivery.collectionCommissionWithRate,
      delivery.realCollectionCommissionWithoutRateValue
      ?? delivery.collectionCommissionWithOutRate,
    );

    return shippingCost === shippingCostshippingCost ? 0 : addDiscount(
      shippingCost,
      shippingCostshippingCost,
      delivery.deliveryCompanyId,
      delivery.routeType,
      delivery.negotiation,
      deliveryCompaniesConfig,
    );
  };

  const detailsCost = () => {
    if (deliverySelected) {
      return (
        <>
          <div
            className="collection-value color-blue"
          >
            <span>
              Tarifa calculada para recaudo:
              {toCurrencyCop(
                user,
                collectionServiceValue(
                  saleValue,
                  deliverySelected.shippingCost,
                  collectionService,
                  addShippingToCollectionValue,
                  deliverySelected.collectionCommissionWithRate,
                  deliverySelected.collectionCommissionWithOutRate,
                ),
              )}
            </span>
          </div>
          <div className="container-detail__delivery-details">
            <Typography type="field" text="Envío:" />
            {' '}
            <Typography
              type="value"
              text={deliverySelected && (
                toCurrencyCop(user, deliverySelected.shippingCost)
              )}
            />
          </div>
          <div className="container-detail__delivery-details">
            <Typography type="field" text="Comision de recaudo:" />
            {' '}
            <Typography
              type="value"
              text={deliverySelected && (
                toCurrencyCop(
                  user,
                  collectionServiceCommision(
                    collectionService,
                    addShippingToCollectionValue,
                    deliverySelected.collectionCommissionWithRate,
                    deliverySelected.collectionCommissionWithOutRate,
                  ),
                )
              )}
            />
          </div>
          <div className="container-detail__delivery-details">
            <Typography type="field" text="Total:" />
            {' '}
            <Typography
              type="value"
              text={deliverySelected && (
                toCurrencyCop(
                  user,
                  finalPrice(
                    deliverySelected.shippingCost,
                    collectionService,
                    addShippingToCollectionValue,
                    deliverySelected.collectionCommissionWithRate,
                    deliverySelected.collectionCommissionWithOutRate,
                  ),
                )
              )}
            />
          </div>
        </>
      );
    }
    return null;
  };
  return (
    <div
      id={isMobileDevice ? 'deliveries-list-quote-shipping' : 'deliveries-list-quote-shipping-mobile'}
      className={`${isMobileDevice ? 'mobile-mode' : 'desktop-mode'} ${collectionService ? 'list-with-checkbox-bottom' : ''}`}
    >
      {!user && showOnboarding && !deliverySelected && (<Onboarding />)}
      <div className="container-list__deliveries-list-quote-shipping">
        {deliveriesList && deliveriesList.length > 0 && deliveriesList.map((delivery) => (
          <DeliveryCardToList
            key={`item-${delivery.id}`}
            id={delivery.deliveryCompanyId}
            deliveryName={delivery.deliveryCompanyName}
            deliveryImg={delivery.deliveryCompanyImgUrl}
            deliveryTime={delivery.shippingTime}
            shippingValue={
              finalPrice(
                delivery.shippingCost,
                collectionService,
                addShippingToCollectionValue,
                delivery.collectionCommissionWithRate,
                delivery.collectionCommissionWithOutRate,
              )
            }
            shippingRealValue={
              functionDiscount(delivery)
            }
            collectionValue={
              collectionServiceValue(
                saleValue,
                delivery.shippingCost,
                collectionService,
                addShippingToCollectionValue,
                delivery.collectionCommissionWithRate,
                delivery.collectionCommissionWithOutRate,
              )
            }
            deliveryScore={delivery.score || 0}
            onClick={() => {
              setDeliverySelected(delivery);
              next(delivery);
              continueToLastDetails();
            }}
            delivery={deliverySelected}
            setShowDetails={() => setDeliverySelected(delivery)}
            setHideDetails={() => setDeliverySelected(null)}
            detailsCostElement={detailsCost}
            isMobileDevice={isMobileDevice}
            focused={deliverySelected && deliverySelected.id === delivery.id}
            details={false}
            routeType={delivery.routeType}
            pickupService={delivery.pickupService}
            officeAddress={delivery.officeAddress}
          />
        ))}
        {collectionService && (
          <div className="checkbox-add-shipping-to-collection-value">
            <CheckBox
              checked={addShippingToCollectionValue}
              onChange={setAddShippingToCollectionValue}
              text="Quiero que mi destinatario también pague el envío"
            />
          </div>
        )}
      </div>
    </div>
  );
};

DeliveriesList.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  deliveriesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addShippingToCollectionValue: PropTypes.bool.isRequired,
  collectionService: PropTypes.bool.isRequired,
  setAddShippingToCollectionValue: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  saleValue: PropTypes.number,
  continueToLastDetails: PropTypes.func.isRequired,
  deliveryCompaniesConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({}),
};

DeliveriesList.defaultProps = {
  saleValue: null,
  user: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  deliveryCompaniesConfig: state.globalData.deliveryCompaniesConfig,
  user: state.auth.user,
});

export default connect(mapStateToProps)(DeliveriesList);
