/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import swal from 'sweetalert';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { validationSteps } from './validations';
import { validationValue } from '../../../utils/strings';
import { findObjectInArray } from '../../../utils/filters';
import { resetPasswordService, loginService, loginSocialAccountService } from '../../../services/user';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import Input from '../../../components/input';
import Button from '../../../components/button';
import Typography from '../../../components/typography';
import ContentSection from '../../../components/contentSection';

import IconVisibility from '../../../assets/images/icon-visibility-color-gray.svg';
import IconVisibilityOff from '../../../assets/images/icon-visibility-off-color-primary.svg';
import { ReactComponent as ArrowBack } from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './changePassword.scss';
import { annotationPassword } from '../../../constants/annotations';

const ChangePassword = () => {
  const user = useSelector((state) => state.auth.user || {});
  const navigate = useNavigate();

  const changeOrCreatePassword = typeof user.password === 'string' ? 'change' : 'create';
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [executingAction, setExecutingAction] = useState(false);

  const updatePasswordFunction = async () => {
    let response;
    setExecutingAction(true);
    if (changeOrCreatePassword === 'change') {
      try {
        response = await loginService({ email: user.email, password: currentPassword });
      } catch { swal('Error', 'Contraseña actual incorrecta'); }
    } else if (changeOrCreatePassword === 'create') {
      const { accessToken, socialUserId, socialNetwork } = user;
      try {
        response = await loginSocialAccountService({ accessToken, socialUserId, socialNetwork });
      } catch { swal('Error', 'Error al validar datos'); }
    }

    try {
      if (response?.token) {
        await resetPasswordService(user._id, newPassword, response.token);
        swal('Cambio Exitoso', 'Su contraseña se ha cambiado exitosamente');
        navigate('/perfil');
      }
    } catch {
      swal('Error', 'Hubo un error al cambiar la contraseña');
    } finally {
      setExecutingAction(false);
    }
  };

  const svgClassCss = (boolean) => (boolean ? 'orangeOff' : 'orange');
  const textOrPassword = (boolean) => (boolean ? 'text' : 'password');
  const iconRight = (boolean) => (boolean ? IconVisibilityOff : IconVisibility);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <ContentSection
      className="changeOrCreatePassword"
      headerChildrenLeft={(
        <div className="containerHeader">
          <ArrowBack onClick={() => navigate(-1)} className="arrowBack" />
          <Typography
            type="superTitle-italic"
            text={changeOrCreatePassword === 'change' ? 'Cambia tu contraseña' : 'Establece tu contraseña'}
          />
        </div>
      )}
    >
      <div className="containerBody">
        <section>
          {changeOrCreatePassword === 'change' && (
            <Input
              label="Contraseña actual"
              placeholder="Escribe tu contraseña"
              classNameContainer="fullWidth"
              value={currentPassword}
              onChange={setCurrentPassword}
              personalizedFirstSpan="changeColor"
              changeColorsvg
              svgClasscss={svgClassCss(showConfirmPassword)}
              type={textOrPassword(showConfirmPassword)}
              iconRigth={iconRight(showConfirmPassword)}
              onClickIconRight={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          )}
          <Input
            label="Contraseña nueva"
            placeholder="Escribe tu contraseña"
            value={newPassword}
            onChange={(value) => {
              setNewPassword(value);
              if (findObjectInArray('newPassword', errorList, 'name')) {
                validationSteps({ errorList, setErrorList, newPassword: value });
              }
            }}
            onBlur={() => validationSteps({ errorList, setErrorList, newPassword })}
            personalizedFirstSpan="changeColor"
            changeColorsvg
            svgClasscss={svgClassCss(showCurrentPassword)}
            type={textOrPassword(showCurrentPassword)}
            iconRigth={iconRight(showCurrentPassword)}
            onClickIconRight={() => setShowCurrentPassword(!showCurrentPassword)}
            annotation={annotationPassword}
            annotationError={findObjectInArray('newPassword', errorList, 'name')?.error || null}
          />
          <Input
            label="Confirma tu contraseña nueva"
            placeholder="Escribe tu contraseña"
            value={confirmPassword}
            onChange={(value) => {
              setConfirmPassword(value);
              if (findObjectInArray('confirmPassword', errorList, 'name')) {
                validationSteps({
                  errorList, setErrorList, newPassword, confirmPassword: value,
                });
              }
            }}
            onBlur={() => validationSteps({
              errorList, setErrorList, newPassword, confirmPassword,
            })}
            personalizedFirstSpan="changeColor"
            changeColorsvg
            svgClasscss={svgClassCss(showNewPassword)}
            type={textOrPassword(showNewPassword)}
            iconRigth={iconRight(showNewPassword)}
            onClickIconRight={() => setShowNewPassword(!showNewPassword)}
            onCopy
            onPaste
            annotationError={findObjectInArray('confirmPassword', errorList, 'name')?.error || null}
          />
        </section>

        <div className="contentButtons">
          <Button
            text="Cancelar"
            type="secondary"
            onClick={() => navigate(-1)}
            disabled={executingAction}
            className="buttonCancel"
          />
          <Button
            text={changeOrCreatePassword === 'change' ? 'Guardar cambios' : 'Crear contraseña'}
            disabled={
              newPassword === '' || !validationValue(newPassword, 'password').correct
              || confirmPassword !== newPassword
            }
            loading={executingAction}
            type={isCountryValid ? 'primary-mx' : 'primary'}
            onClick={() => updatePasswordFunction()}
          />
        </div>
      </div>
    </ContentSection>
  );
};

export default ChangePassword;
