import React from 'react';
import { useFetchIpData, useValidationCountry } from '../../utils/validationCountry'; // Reemplaza con la ruta correcta

import Logo from '../../assets/images/logo.svg';
import logoMex from '../../assets/images/logo-mexico.png';

import './loadingScreen.scss';

const LoadingScreen = () => {
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div id="loading-screen">
      <img src={isCountryValid ? logoMex : Logo} alt="logo" />
      <div />
    </div>
  );
};

export default LoadingScreen;
