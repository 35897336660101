import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toCurrencyCop } from 'utils/strings';

import './senderCollectionServiceDiscount.scss';

const SenderCollectionServiceDetailsPrices = ({
  title,
  realAmount,
  amountDiscount,
  icon,
  user,
  showDetails,
  data,
}) => {
  const [showValueDiscount, setShowValueDiscount] = useState(false);

  useEffect(() => {
    if ((realAmount > 0 && (realAmount !== amountDiscount))) {
      setShowValueDiscount(true);
    }
  }, []);
  return (
    <div className="sender-collection-service-discount">
      <div className="sender-collection-service-discount__top">
        <p className="title">{title}</p>
        <div className="information-prices">
          <div className="discount-price">
            {showValueDiscount && (<img src={icon} alt="" />)}
            {amountDiscount && (
            <p className={!showValueDiscount ? 'discount-price__without-amount' : 'discount-price__amount'}>
              {toCurrencyCop(user, amountDiscount)}
            </p>
            )}
          </div>
          {showValueDiscount && !showDetails && (<span className="real-value">{toCurrencyCop(user, realAmount)}</span>)}
        </div>
      </div>
      {showDetails && (
        <div className="sender-collection-service-discount__details-data">
          {data?.map((item) => (
            <div
              key={item?.title}
              className="data-list-items"
            >
              <span className="text">{item?.title}</span>
              {!isNaN(item?.value) && (<span className={item?.isDiscount ? 'discount' : 'value'}>{toCurrencyCop(user, item?.value)}</span>)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

SenderCollectionServiceDetailsPrices.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  realAmount: PropTypes.number.isRequired,
  amountDiscount: PropTypes.number,
  user: PropTypes.shape({}).isRequired,
  showDetails: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

SenderCollectionServiceDetailsPrices.defaultProps = {
  amountDiscount: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
});

export default connect(mapStateToProps)(SenderCollectionServiceDetailsPrices);
