import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { toCurrencyCop, capitalizeWords } from '../../../../utils/strings';
import { findImageIntoDeliveries, findNameIntoDeliveries } from '../../../../utils/generals';
import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import Button from '../../../../components/button';
import Typography from '../../../../components/typography';
import { detailTypes } from '../common/enums';

import './shipmentList.scss';

const linkCollection = 'https://www.mipaquete.com/soluciones-ecommerce/envios-pago-contraentrega#preguntas-frecuentes';

const ShipmentsList = ({
  shipmentsData, deliveryCompanies, downloadData, customTotalColumClass,
  customShipmentListClass, showMore, handleTicketDetailShipments,
}) => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state?.auth?.user || null);
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);
  return (
    <div id="shipment-list-information" className={customShipmentListClass}>
      <div className="primary">
        <div className="table-shipping-list">
          {!isMobileDevice && (
            <div className={`header ${customTotalColumClass}`}>
              <Typography type="field" text="" />
              <Typography type="field" text="N° de guía" />
              <Typography type="field" text="N° de MP" />
              <Typography type="field" text="Destinatario" />
              <Typography type="field" text="Estado" />
              <Typography type="field" text="Valor descontado" className="right-side" />
              <Typography type="field" text="Valor recaudado" className="right-side" />
              {shipmentsData?.length > 0 && shipmentsData[0].transferCost && (
                <Typography type="field" text="Valor transferido" className="right-side" />
              )}
            </div>
          )}
          <div className={`body ${customTotalColumClass}`}>
            <div className="message-collection-mobile">
              <p>
                Conoce
                {' '}
                <span onClick={() => window.open(linkCollection, 'blank', 'noopener')}>aquí</span>
                {' '}
                las fechas de transferencia de recaudos y sus costos.

              </p>
            </div>
            {!isMobileDevice && shipmentsData && shipmentsData.map((item, index) => (
              <div className={`body__content ${customTotalColumClass}`} key={(index + 1).toString()} onClick={() => handleTicketDetailShipments(item, detailTypes.detailCurrentShipment)}>
                <img
                  src={findImageIntoDeliveries(item.deliveryCompany, deliveryCompanies)}
                  alt=""
                />
                <Typography
                  type="field-title-dark"
                  text={item.guideNumber}
                />
                <Typography
                  type="field-dark-gray"
                  text={`MP${item.code}`}
                />
                <Typography
                  type="field-dark-gray"
                  text={capitalizeWords(`${item.receiverName || item.receiver.name || ''} ${item.receiverSurname || item.receiver.surname || ''}`)}
                />
                <Typography
                  type="field-dark-gray"
                  text={item.state}
                  className={item.state === 'Entregado' ? 'blue' : 'red'}
                />
                <Typography
                  type="field-dark-gray"
                  text={toCurrencyCop(user, item.discountValue)}
                  className="right-side"
                />
                <Typography
                  type="field-dark-gray"
                  text={item.state === 'Entregado' ? toCurrencyCop(user, item.valueCollection) : '$ 0'}
                  className="right-side"
                />
                {(item.transferCost || item.transferCost === 0) && (
                  <Typography
                    type="field-dark-gray"
                    text={toCurrencyCop(user, item.transferCost)}
                    className="right-side"
                  />
                )}
              </div>
            ))}
            {isMobileDevice && shipmentsData && shipmentsData.map((item, index) => (
              <div className="body-content-mobile" key={(index + 1).toString()} onClick={() => handleTicketDetailShipments(item, detailTypes.detailCurrentShipment)}>
                <div className="first row">
                  <Typography type="field" text={findNameIntoDeliveries(item.deliveryCompany, deliveryCompanies)} />
                  <Typography type="field-dark-gray" text={item.state} className={item.state === 'Entregado' ? 'blue' : 'red'} />
                </div>
                <div className="row">
                  <Typography type="field" text="Guia" />
                  <Typography type="field" text={item.guideNumber} className="separate" />
                </div>
                <div className="row">
                  <Typography type="field-dark-gray" text={`MP${item.code}`} />
                </div>
                <div className="row">
                  <Typography type="field-dark-gray" text={capitalizeWords(`${item.receiverName || ''} ${item.receiverSurname || ''}`)} />
                </div>
                <div className="row">
                  <Typography type="field" text="Valor descontado:" />
                  <Typography type="field-dark-gray" text={toCurrencyCop(user, item.discountValue)} className="separate" />
                </div>
                <div className="row">
                  <Typography type="field" text="Valor recaudado:" />
                  <Typography
                    type="field-dark-gray"
                    text={item.state === 'Entregado' ? toCurrencyCop(user, item.valueCollection) : '$ 0'}
                    className="separate"
                  />
                </div>
              </div>
            ))}
            {showMore && shipmentsData?.length > 30 && (
              <div className="show-more-button">
                <Button
                  text="Ver más"
                  onClick={showMore}
                  type="secondary"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {downloadData && (
        <div className="secondary">
          <Button
            text="Descargar recaudos"
            type={isCountryValid ? 'primary-mx' : 'primary'}
            onClick={() => downloadData && downloadData()}
            className="button-footer-list"
          />
        </div>
      )}
    </div>
  );
};

ShipmentsList.propTypes = {
  shipmentsData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  deliveryCompanies: PropTypes.arrayOf().isRequired,
  customTotalColumClass: PropTypes.string,
  downloadData: PropTypes.func,
  customShipmentListClass: PropTypes.string,
  showMore: PropTypes.func,
  handleTicketDetailShipments: PropTypes.func.isRequired,
};

ShipmentsList.defaultProps = {
  downloadData: null,
  customTotalColumClass: '',
  customShipmentListClass: '',
  showMore: null,
};

export default ShipmentsList;
