import axios from 'axios';
import {
  routesMicroserviceUrl,
  customerKeyRoutesMicroservice,
} from '../constants';

/**
  * subir rutas
  * @param {Object} params - params to send
  * @param {Object} query - body to send
*/
export const createUpdateRouteSaaSService = (params, query) => new Promise((resolve, reject) => {
  const endpoint = `/createUpdateRouteSaaS/${params.deliveryCompany}/${params.userId}`;
  axios.defaults.headers.common['customer-key'] = customerKeyRoutesMicroservice;
  axios.post(`${routesMicroserviceUrl}${endpoint}`, query)
    .then(({ data }) => resolve(data))
    .catch((e) => reject(e));
});

export const getCountriesService = () => new Promise((resolve, reject) => {
  const endpoint = '/getCountries';
  axios.defaults.headers.common['customer-key'] = customerKeyRoutesMicroservice;
  axios.get(`${routesMicroserviceUrl}${endpoint}`)
    .then(({ data }) => resolve(data))
    .catch((e) => reject(e));
});

export const getLocationsFilter = (params) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyRoutesMicroservice;
  axios.get(`${routesMicroserviceUrl}/getLocationsFilter`, { params })
    .then(({ data }) => resolve(data))
    .catch((error) => reject(error));
});

export const getTypeProducts = (params) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyRoutesMicroservice;
  axios.get(`${routesMicroserviceUrl}/getWaybill`, { params })
    .then(({ data }) => resolve(data))
    .catch((error) => reject(error));
});

export default {
  createUpdateRouteSaaSService,
};
