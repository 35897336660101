import React from 'react';
import PropTypes from 'prop-types';

import './cardSectionMobile.scss';

const CardSectionMobile = (props) => {
  const {
    img, label, onClick, elementRight,
  } = props;

  return (
    <div className="card-section-mobile" onClick={onClick}>
      {img && (
        <div className="container-img__card-section-mobile">
          <img src={img} alt="Icon card" />
        </div>
      )}
      <span>{label}</span>
      {elementRight}
    </div>
  );
};

CardSectionMobile.propTypes = {
  img: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  elementRight: PropTypes.element,
};

CardSectionMobile.defaultProps = {
  elementRight: null,
};

export default CardSectionMobile;
