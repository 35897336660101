import { getLocationsService } from '../../services/location';

import userUpdateService from '../../components/completingData/updateUserService';
import payloadData from '../../components/completingData/updateUserService/payloadService/payloadService';

export const getLocationService = async (user, setLocations, setNewLocation) => {
  const getLocation = await getLocationsService();
  const locationFilter = getLocation.filter(
    (location) => location.locationCode === user.invoicingData?.locationCode,
  )[0];
  setLocations(getLocation);
  setNewLocation(`${locationFilter.locationName}-${locationFilter.departmentOrStateName}`);
};

export const updateUserCompletingData = async (data, dispatch, getCodeLocation, setShowModal,
  setModalContent, setLoading, setStep, isUpdateData,
) => {
  const {
    formValues, changes, locationCode, countryCode, user,
  } = data;

  let newChanges;
  let newValuesForm;

  if (countryCode === '170') {
    newChanges = {
      address: formValues.address,
      businessName: formValues.businessName,
      locationName: formValues.locationName,
      firstLocationCode: formValues.firstLocationCode,
    };

    newValuesForm = {
      ...formValues,
      cellPhone: changes.cellPhone,
      locationCode,
      emailForBilling: changes.emailForBilling,
      locationName: changes.locationName,
      address: changes.address,
    };
  } else {
    newChanges = {
      address: formValues.address,
      businessName: formValues.businessName,
      locationName: formValues.locationName,
      locationCode: formValues.locationCode,
    };

    newValuesForm = {
      ...formValues,
      postalCode: changes.postalCode,
      methodOfPayment: changes.methodOfPayment,
      CFDI: changes.CFDI,
      emailForBilling: changes.emailForBilling,
      taxResidence: changes.taxResidence,
      locationCode: getCodeLocation(formValues.locationName),
    };
  }

  await userUpdateService(
    payloadData(newChanges, newValuesForm, user),
    user._id,
    setShowModal,
    setModalContent,
    isUpdateData,
    user.invoicingData?._id,
    setLoading,
    (action) => {
      if (action) {
        setModalContent({
          title: 'Exitoso',
          text: 'Se han guardado los datos correctamente.',
          type: 'success',
        });
        setStep(0);
        setShowModal(true);
      }
    },
  );
};
