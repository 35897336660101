import axios from 'axios';

const downLoadPdfToUrl = (pdfUri) => {
  axios.get(pdfUri, { responseType: 'blob' }).then((response) => {
    const separteUri = response.config.url.split('/');
    const fileName = separteUri[separteUri.length - 1];

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response.data);
    link.download = fileName;

    link.click();
    window.URL.revokeObjectURL(link);
    return response;
  });
};

export default downLoadPdfToUrl;
