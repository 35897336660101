import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import Typography from '../../../../../components/typography';

import iconClose from '../../../../../assets/images/icon-close-color-primary.svg';

import './deleteModal.scss';

const DeleteModal = ({ deleteApikeyService, setShowModalDelete }) => (
  <Modal closeBackground={() => setShowModalDelete(false)}>
    <div className="modal-container">
      <div>
        <button
          type="button"
          className="icon-button"
          onClick={() => setShowModalDelete(false)}
        >
          <img alt="iconClose" className="icon-close" src={iconClose} />
        </button>
      </div>
      <Typography text="¿Quieres eliminar la ApiKey?" className="title-text" />
      <span className="subtitle-modal">
        Al eliminar esta clave, tus integraciones se desconectarán de la
        plataforma. Podrás volver a conectarlas generando una nueva clave.
      </span>
      <div className="modal-buttons">
        <Button
          className="button-cancel"
          text="No, volver atrás"
          type="secondary"
          onClick={() => setShowModalDelete(false)}
        />
        <Button
          className="button-confirm"
          text="Sí, eliminar"
          type="quaternary"
          onClick={() => {
            deleteApikeyService();
            setShowModalDelete(false);
          }}
        />
      </div>
    </div>
  </Modal>
);

DeleteModal.propTypes = {
  setShowModalDelete: PropTypes.func.isRequired,
  deleteApikeyService: PropTypes.func.isRequired,
};

DeleteModal.defaultProps = {};

export default DeleteModal;
