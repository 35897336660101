/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import { getAverageSendings, getSendingsPriceDifference } from '../../../services/sendings';

import { toCurrencyCop } from '../../../utils/strings';
import Tooltip from '../../../components/tooltip';
import Typography from '../../../components/typography';

import IconArrowBack from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './discounts.scss';

const Discounts = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const { user } = useSelector((state) => state.auth);
  const { name, email, cellPhone } = user?.volumeDiscountNegotiation?.consultant || {};

  const {
    negotiationDescription, shipmentsPromise, createdAt,
  } = user?.volumeDiscountNegotiation || {};

  const navigate = useNavigate();

  const [shipments, setShipements] = useState(null);
  const [{ money, date }, setData] = useState({
    money: '',
    date: '',
  });

  const percentageValue = (item) => `${item * 100}%`;

  useEffect(() => {
    const dataSendings = async () => getSendingsPriceDifference();
    dataSendings().then((res) => setData({
      money: toCurrencyCop(user, res.sendingsPriceDifference),
      date: moment(res.dateFilter).format('DD/MM/YYYY'),
    }));
    const dataShipment = async () => getAverageSendings();
    dataShipment().then((res) => setShipements(res.shipmentAverage));
  }, []);

  return (
    <div id="my-discounts" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className="wrapper-header">
        <img src={IconArrowBack} alt="goBack" onClick={() => navigate(-1)} className="go-back" />
        <Typography type="superTitle-italic" text="Tus descuentos" className="title" />
      </div>
      <div className="wrapper-discounts">
        <div className={isMobileDevice ? 'information__money-mobile' : 'none'}>
          <Typography type="title" text="Dinero ahorrado" className="saved-money" />
          <Tooltip
            overlayText={`Esta es la cantidad de dinero ahorrado desde ${date}`}
            startingPosition="bottom-rigth"
            withoutIcon={(<Typography type="field" className="total">{money}</Typography>)}
          />
        </div>
        <div className="information">
          <div className="information__assessor">
            <Typography type="field" text="Asesor" className="title" />
            <p className="data-first">{name}</p>
            <p className="data">{email}</p>
            <p className="data">{cellPhone}</p>
          </div>
          <div className="information__sendings">
            <Typography type="field" text="Promesa de envios" />
            <p className="data">{shipmentsPromise}</p>
          </div>
          <div className="information__sendings">
            <Typography type="field" text="Envios entregados" />
            <Tooltip
              overlayText="Cantidad de envíos que has realizado o devueltos hasta el día de hoy desde el primer día del mes."
              startingPosition="bottom-rigth"
              withoutIcon={(<p className="data-green">{shipments}</p>)}
            />
          </div>
          <div className="information__sendings">
            <Typography type="field" text="Inicio de la negociacion" />
            <p className="data">{moment(createdAt).format('DD/MM/YYYY')}</p>
          </div>
          <div className="information__money">
            <Typography type="title" text="Dinero ahorrado" className="saved-money" />
            <Tooltip
              overlayText={`Esta es la cantidad de dinero ahorrado desde ${date}`}
              startingPosition="bottom-rigth"
              withoutIcon={(<Typography type="field" className="total">{money}</Typography>)}
            />
          </div>
        </div>
        <div className="description-negotiation">
          <Typography type="field" text="Descripción de la negociación" className="description-negotiation__title" />
          <p className="description">
            {/* eslint-disable-next-line max-len */}
            {negotiationDescription}
          </p>
        </div>
        <div className="table-discounts">
          <div className="table-title">
            <div className="title-table">
              <Typography type="field" text="Descuentos aplicados" />
            </div>
          </div>
          {isMobileDevice ? (
            <>
              {user?.volumeDiscountNegotiation?.volumeDiscountList.map((item, index) => (
                <div key={index} className="mobile-information">
                  <div className="item_company">
                    <h1>{item.deliveryCompanyName}</h1>
                  </div>
                  <div className="item">
                    <p className="item__title">Local</p>
                    <p className="item__information">{percentageValue(item.local.discountValue)}</p>
                  </div>
                  <div className="item">
                    <p className="item__title">Regional</p>
                    <p className="item__information">{percentageValue(item.regional.discountValue)}</p>
                  </div>
                  <div className="item">
                    <p className="item__title">Nacional</p>
                    <p className="item__information">{percentageValue(item.nacional.discountValue)}</p>
                  </div>
                  <div className="item">
                    <p className="item__title">Zonal</p>
                    <p className="item__information">{percentageValue(item.zonal.discountValue)}</p>
                  </div>
                  <div className="item">
                    <p className="item__title">Reexpedicion</p>
                    <p className="item__information">{percentageValue(item.reexpedicion.discountValue)}</p>
                  </div>
                  <div className="item">
                    <p className="item__title">Especial</p>
                    <p className="item__information">{percentageValue(item.especial.discountValue)}</p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <table>
                <thead>
                  <tr className="table-header">
                    <th className="table-header__title">Transportadora</th>
                    <th className="table-header__title">Local</th>
                    <th className="table-header__title">Regional</th>
                    <th className="table-header__title">Nacional</th>
                    <th className="table-header__title">Zonal</th>
                    <th className="table-header__title">Reexpedicion</th>
                    <th className="table-header__title">Especial</th>
                  </tr>
                </thead>
                <tbody>
                  {user?.volumeDiscountNegotiation?.volumeDiscountList.map((item, index) => (
                    <tr key={index} className="table-body">
                      <td className="table-body__content">
                        <p className="item">
                          {item.deliveryCompanyName}
                        </p>
                      </td>
                      <td className="table-body__content">
                        <p>
                          {percentageValue(item.local.discountValue)}
                        </p>
                      </td>
                      <td className="table-body__content">
                        <p>
                          {percentageValue(item.regional.discountValue)}
                        </p>
                      </td>
                      <td className="table-body__content">
                        <p>
                          {percentageValue(item.nacional.discountValue)}
                        </p>
                      </td>
                      <td className="table-body__content">
                        <p>
                          {percentageValue(item.zonal.discountValue)}
                        </p>
                      </td>
                      <td className="table-body__content">
                        <p>
                          {percentageValue(item.reexpedicion.discountValue)}
                        </p>
                      </td>
                      <td className="table-body__content">
                        <p>
                          {percentageValue(item.especial.discountValue)}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Discounts;
