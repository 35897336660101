/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../typography';

import './contentSection.scss';

const ContentSection = ({
  headerChildrenLeft,
  headerTextLeft,
  headerText,
  headerTextAlign,
  headerChildrenCenter,
  headerChildrenRigth,
  colorTypeHeaderBottomLine,
  containerWithShadow,
  children,
  className,
  noShowHeader,
  notitleItalic,
}) => {
  const showHeader = () => (
    (
      headerChildrenLeft || headerTextLeft || headerText
      || headerChildrenCenter || headerChildrenRigth
    )
    && !noShowHeader
  );

  return (
    <div className={`${className ? className : ''} content-section__container ${containerWithShadow ? 'shadow' : ''} `}>
      {showHeader() && (
        <div className="content-section__header">
          <div className="content-section__content-header">
            <div className="header-left">
              {headerChildrenLeft}
              {headerTextLeft && <Typography text={headerTextLeft} type={notitleItalic ? 'superTitle' : 'superTitle-italic'} />}
            </div>
            <div className={`header-center ${headerTextAlign}`}>
              {headerChildrenCenter}
              {headerText && <Typography text={headerText} type={notitleItalic ? 'superTitle' : 'superTitle-italic'} />}
            </div>
            <div className="header-rigth">
              {headerChildrenRigth}
            </div>
          </div>
          <hr className={colorTypeHeaderBottomLine} />
        </div>
      )}
      <div
        className={`content-section__body ${showHeader() ? 'with-header' : 'without-header'}`}
      >
        {children}
      </div>
    </div>
  );
};

ContentSection.propTypes = {
  headerChildrenLeft: PropTypes.element,
  headerChildrenCenter: PropTypes.element,
  headerTextLeft: PropTypes.string,
  headerText: PropTypes.string,
  headerTextAlign: PropTypes.oneOf(['left', 'center', 'rigth']),
  headerChildrenRigth: PropTypes.element,
  colorTypeHeaderBottomLine: PropTypes.oneOf(['primary', 'accent']),
  children: PropTypes.element.isRequired,
  containerWithShadow: PropTypes.bool,
  className: PropTypes.string,
  noShowHeader: PropTypes.bool,
  notitleItalic: PropTypes.bool,
};

ContentSection.defaultProps = {
  headerChildrenCenter: null,
  className: null,
  headerChildrenLeft: null,
  headerText: null,
  headerTextLeft: null,
  headerTextAlign: 'center',
  headerChildrenRigth: null,
  colorTypeHeaderBottomLine: 'primary',
  containerWithShadow: false,
  noShowHeader: false,
  notitleItalic: false,
};

export default ContentSection;
