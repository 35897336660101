import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../components/input';
import Swal from '../modals/errors';
import { detailTypes } from '../common/enums';
import { getShippingForList } from '../services/getShipping';

const searchDataFromFilters = async (data, handleTicketDetail) => {
  const dataShipment = await getShippingForList(data);
  if (dataShipment?.length > 0) {
    handleTicketDetail(dataShipment[0], detailTypes.detailCurrentShipment);
  }
};

const InputFilter = ({
  item,
  inputGuide,
  setInputGuide,
  handleTicketDetail,
  inputMp,
  setInputMp,
}) => (
  <>
    {item === 'guide' ? (
      <Input
        value={inputGuide}
        placeholder="Escribe el número"
        onChange={setInputGuide}
        className="search-input"
        svgClasscss="magnifying-glass"
        onClickIconRight={() => inputGuide
          && searchDataFromFilters({
            query: { guideNumber: inputGuide },
            showError: () => Swal({ text: `No se encontró la guía ${inputGuide}` }),
          }, handleTicketDetail)}
        changeColorsvg
        inputColor="line-focused-register"
        iconRightClass="icon-right"
        type="number-text"
      />
    ) : (
      <Input
        placeholder="Escribe el número"
        value={inputMp}
        onChange={setInputMp}
        className="search-input"
        svgClasscss="magnifying-glass"
        onClickIconRight={() => inputMp
          && searchDataFromFilters({
            query: { code: Number.parseFloat(inputMp) },
            showError: () => (Swal({ text: `No se encontró el MP ${inputMp}` })),
          }, handleTicketDetail)}
        changeColorsvg
        inputColor="line-focused-register"
        iconRightClass="icon-right"
        type="number-text"
      />
    )}
  </>
);

InputFilter.propTypes = {
  item: PropTypes.objectOf({}),
  inputGuide: PropTypes.string,
  setInputGuide: PropTypes.func.isRequired,
  handleTicketDetail: PropTypes.func.isRequired,
  inputMp: PropTypes.string,
  setInputMp: PropTypes.func.isRequired,
};

InputFilter.defaultProps = {
  item: null,
  inputGuide: null,
  inputMp: null,
};

export default InputFilter;
