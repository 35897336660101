import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import { findObjectInArray } from '../../../../../utils/filters';

import Input from '../../../../../components/input';

const PasswordInput = ({
  input, data, setData, errorsList, setInputInformation, key,
}) => {
  const {
    showTitleToUser, inputType, placeholder, propertyName,
    annotation,
  } = input;

  const [showPassWord, setShowPassWord] = useState(null);

  return (
    <Input
      key={key}
      label={showTitleToUser}
      placeholder={placeholder || ''}
      value={data[propertyName]}
      type={showPassWord || inputType === 'string' ? 'text' : inputType}
      onChange={(e) => setInputInformation(
        data, { propertyName, value: e }, setData)}
      annotation={annotation || ''}
      className="separation-input"
      changeColorsvg
      svgClasscss={showPassWord ? 'visibility-icon-orange' : 'no-visibility-icon-orange'}
      onClickIconRight={() => setShowPassWord(showPassWord ? null : 'string')}
      annotationError={
        findObjectInArray(propertyName, errorsList, 'name')
          ? findObjectInArray(propertyName, errorsList, 'name').error
          : null
      }
    />
  );
};

PasswordInput.propTypes = {
  key: PropTypes.string.isRequired,
  input: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  errorsList: PropTypes.func.isRequired,
  setInputInformation: PropTypes.func.isRequired,
};

export default (PasswordInput);
