/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../button';
import Input from '../../input';
import Typography from '../../typography';
import RadioButton from '../../radioButton';

import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';
import { typeDelivery } from '../../../constants/general';

import '../lastDetails.scss';

const SenderDetails = ({
  next,
  heightType,
  user,
  senderName,
  setSenderName,
  senderSurname,
  setSenderSurname,
  senderIdentificationNumber,
  senderEmail,
  senderCellPhone,
  setSenderCellPhone,
  senderAddress,
  setSenderAddress,
  collectionOrTakeToOffice,
  setCollectionOrTakeToOffice,
  deliverySelected,
  isMobileDevice,
  senderNameError,
  senderSurnameError,
  senderIdentificationNumberError,
  senderEmailError,
  senderCellPhoneError,
  senderAddressError,
  collectionOrTakeToOfficeError,
  detailsFromQuoteMultipleShippings,
}) => {
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);
  return (
    <div
      className={`body-accordion__last-details sender-details ${heightType ? heightType : ''} ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
    >
      <div
        className="second-body-accordion__last-details"
      >
        <div
          className="container__second-body-accordion__last-details top"
        >
          <div className="content-top-bottom-or-side__container__second-body-accordion__last-details">
            <Input
              label="Nombre"
              value={senderName}
              onChange={setSenderName}
              annotationError={senderNameError}
            />
            <Input
              label="Apellido"
              value={senderSurname}
              onChange={setSenderSurname}
              annotationError={senderSurnameError}
            />
            <Input
              label="Número de identificación"
              value={senderIdentificationNumber}
              type="number-text"
              onChange={() => null}
              annotationError={senderIdentificationNumberError}
              disabled
            />
          </div>
          <div className="content-top-bottom-or-side__container__second-body-accordion__last-details">
            <Input
              label="Celular"
              value={senderCellPhone}
              type="number-text"
              onChange={setSenderCellPhone}
              annotationError={senderCellPhoneError}
            />
            <Input
              label="Correo"
              value={senderEmail}
              type="email"
              onChange={() => null}
              annotationError={senderEmailError}
              disabled
            />

            <div className="container-address__sender-details">
              <Typography
                type="subtitle"
                className="title-address__sender-details"
                text="Dirección de recogida"
              />
              {deliverySelected.pickupService && (
                <RadioButton
                  name="address-sending"
                  value="collectionInAddress"
                  onChange={setCollectionOrTakeToOffice}
                  checked={collectionOrTakeToOffice === 'collectionInAddress'}
                >
                  <Input
                    value={senderAddress}
                    onChange={setSenderAddress}
                    annotationError={senderAddressError}
                    onFocus={() => setCollectionOrTakeToOffice('collectionInAddress')}
                  />
                </RadioButton>
              )}
              <RadioButton
                name="address-sending"
                text={`Lo llevaré a la  ${typeDelivery[user.countryCode]}`}
                value="takeToOffice"
                onChange={setCollectionOrTakeToOffice}
                checked={collectionOrTakeToOffice === 'takeToOffice'}
                annotationError={collectionOrTakeToOfficeError}
              />
              {collectionOrTakeToOffice === 'takeToOffice' && (
                <Typography type="value" className="address-info__sender-details" text="En caso de requerir devolución, esta se hará a la dirección registrada en tu perfil." />
              )}
            </div>
          </div>
        </div>
        {!detailsFromQuoteMultipleShippings && (
          <div
            className="container__second-body-accordion__last-details bottom"
          >
            <Button
              text="Continuar"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => next()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

SenderDetails.propTypes = {
  heightType: PropTypes.string,
  next: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
  senderName: PropTypes.string.isRequired,
  setSenderName: PropTypes.func.isRequired,
  senderSurname: PropTypes.string.isRequired,
  setSenderSurname: PropTypes.func.isRequired,
  senderIdentificationNumber: PropTypes.string.isRequired,
  senderEmail: PropTypes.string.isRequired,
  senderCellPhone: PropTypes.string.isRequired,
  setSenderCellPhone: PropTypes.func.isRequired,
  senderAddress: PropTypes.string.isRequired,
  setSenderAddress: PropTypes.func.isRequired,
  collectionOrTakeToOffice: PropTypes.bool,
  setCollectionOrTakeToOffice: PropTypes.func.isRequired,
  deliverySelected: PropTypes.any.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  senderNameError: PropTypes.string,
  senderSurnameError: PropTypes.string,
  senderIdentificationNumberError: PropTypes.string,
  senderEmailError: PropTypes.string,
  senderCellPhoneError: PropTypes.string,
  senderAddressError: PropTypes.string,
  collectionOrTakeToOfficeError: PropTypes.string,
  detailsFromQuoteMultipleShippings: PropTypes.bool.isRequired,
};

SenderDetails.defaultProps = {
  heightType: 'h-50',
  collectionOrTakeToOffice: null,
  senderNameError: null,
  senderSurnameError: null,
  senderIdentificationNumberError: null,
  senderEmailError: null,
  senderCellPhoneError: null,
  senderAddressError: null,
  collectionOrTakeToOfficeError: null,
};

export default SenderDetails;
