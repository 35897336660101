/* eslint-disable default-param-last */
import {
  AUTH_IN, AUTH_OUT, AUTH_UPDATE, AUTH_UPDATE_TOKEN,
  SHOW_MODAL_NEW_TYC, AUTH_TEMPORAL_DATA,
} from '../actions/type.actions';

const initialState = {
  user: null,
  temporalCredentials: null,
  showModalNewTyC: false,
  logged: false,
  token: null,
};

const parserUser = (user) => {
  if (user) {
    const userClean = user;
    if (userClean.password) userClean.password = '';
    return userClean;
  }
  return null;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_OUT: {
      return initialState;
    }
    case SHOW_MODAL_NEW_TYC: {
      return {
        temporalCredentials: action.payload,
        showModalNewTyC: true,
      };
    }
    case AUTH_IN: {
      return {
        logged: true,
        user: parserUser(action.payload),
        temporalCredentials: null,
        showModalNewTyC: false,
      };
    }
    case AUTH_UPDATE: {
      return {
        logged: true,
        user: parserUser(action.payload),
        temporalCredentials: null,
      };
    }
    case AUTH_UPDATE_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case AUTH_TEMPORAL_DATA: {
      return {
        ...state,
        temporalCredentials: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
