/* eslint-disable no-useless-escape */
const noSpecialCharatersReg = /([^<>#$%&\/\'\d¿?¡!*+~|°¬={}`])+$/g;
const thereAreLettersReg = /[\D]/g;
const thereAreNumbersReg = /\d/g;

const typeValue = (type, value) => {
  if (type === 'number') return Number.parseInt(value, 10);
  return value;
};

const inputsValidations = ({
  inputType, radioButtonOptions, value, validations,
}) => {
  const {
    justLetters, noSpecialCharaters, maxLength, minLength,
  } = validations;

  if (justLetters && thereAreNumbersReg.test(value)) {
    return {
      value,
      correct: false,
    };
  }

  if (noSpecialCharaters && !noSpecialCharatersReg.test(value)) {
    return {
      value,
      correct: false,
    };
  }

  if ((minLength && value?.length < minLength) || (maxLength && value?.length > maxLength)) {
    return {
      value,
      correct: false,
    };
  }

  if (inputType === 'radioButton' && radioButtonOptions.length > 0) {
    const optionSelected = radioButtonOptions.find(
      (optionValue) => optionValue.value === typeValue(optionValue.typeValue, value));
    return optionSelected
      ? { value: optionSelected.value, correct: true }
      : { value, correct: false };
  }

  if (inputType === 'number-text' && thereAreLettersReg.test(value)) {
    return {
      value,
      correct: false,
    };
  }

  return {
    value: typeof value === 'number' ? value : value.trim(),
    correct: true,
  };
};

export default inputsValidations;
