/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import Button from '../../../components/button';
import Typography from '../../../components/typography';
import HeaderButtonBack from '../../../components/headerButtonBack';
import ImageEmpyState from '../../../components/imagesEmptyState';

import Addresses from './addresses';
import ModalCreateAddress from './modalCreateAddress';
import ModalUpdateAddress from './modalUpdateAddress';
import ModalDeleteAddress from './modalDeleteAddress';
import { getUsersAddresses } from '../../../services/user';
import { findLocationByName } from '../../../utils/filters';
import { getLocationService } from '../../../utils/users/completeDataModal';

import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import emptyDirection from '../../../assets/images/empty-direction.svg';

import './myAddressesSettingsViewer.scss';

const MyAddressesSettingsViewer = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth?.user || {});

  const [locations, setLocations] = useState([]);
  const [listAddress, setListAddress] = useState(null);
  const [showCreateAddressModal, setShowCreateAddressModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [deleteAddress, setDeleteAddress] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [, setLocationCode] = useState(user.locationCode);
  const [newLocation, setNewLocation] = useState('');

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const getAddressesService = async () => {
    // eslint-disable-next-line no-return-await
    const getAddresses = async () => await getUsersAddresses();
    const addressestoMap = await getAddresses();
    setListAddress(addressestoMap);
  };

  useEffect(() => {
    getLocationService(user, setLocations, setNewLocation);
  }, []);

  useEffect(() => {
    const originSplitted = newLocation.split('-');
    if (originSplitted.length > 1) {
      const originObject = findLocationByName(
        originSplitted[0], originSplitted[1], locations,
      );
      setLocationCode(originObject?.locationCode ?? '');
    }
  }, [newLocation]);

  useEffect(() => {
    getAddressesService();
  }, []);

  const expressShipping = (
    <p>
      Registra tus direcciones de recolección.
    </p>
  );

  const textEmptyState = (
    <p>
      Aquí podrás crear y gestionar tus direcciones de origen
      {' '}
      Crea tus envíos desde
      {' '}
      <strong>cotización Express.</strong>
    </p>
  );
  return (
    <div className="card-myAddress">
      <HeaderButtonBack title="Mis direcciones - Origen" />
      <div className="container-section-myAddress">
        {
          (listAddress?.length <= 0) && (
            <>
              <ImageEmpyState
                title="Aún no tienes direcciones creadas"
                description={textEmptyState}
                uri={emptyDirection}
              />
              <div className="empty-state">
                <div>
                  <Button
                    onClick={() => setShowCreateAddressModal(true)}
                    text="Crear dirección"
                    type="primary"
                  />
                </div>
              </div>
            </>
          )
        }
        {
          (listAddress?.length > 0) && (
            <>
              <div className={`container-title-notificaion ${(isMobileDevice) ? 'isMobileDevice' : ''}`}>
                <Typography
                  type="paragraph"
                  text={expressShipping}
                  className="paragraph-notification-settings heeboRegular"
                />
              </div>
              <div className={`container-title-notificaion2 ${(isMobileDevice) ? 'isMobileDevice' : ''}`}>
                <Typography
                  type="paragraph"
                  text={expressShipping}
                  className="paragraph-notification-settings heeboRegular"
                />
              </div>
              <div className="default-address">
                <div className="adjust-sede">
                  <Typography className="font-title" type="title" text="Sedes" />
                </div>
                <div>
                  <Typography className="font-title" type="title" text="Ciudad" />
                </div>
                <div>
                  <Typography className="font-title" type="title" text="Dirección" />
                </div>
                <div>
                  <Typography className="font-title" type="title" text=" " />
                </div>
              </div>
              {
                listAddress?.map((item) => (
                  <Addresses
                    item={item}
                    key={item._id}
                    onChangeEdit={() => {
                      setEditItem(item);
                      setShowUpdateModal(true);
                    }}
                    onChangeDelete={() => {
                      setDeleteAddress(item);
                      setShowModalDelete(true);
                    }}
                  />
                ))
              }
            </>
          )
        }
        {(listAddress?.length > 0) && (
          <div className="changeButton">
            <div>
              <Button
                onClick={() => setShowCreateAddressModal(true)}
                text="Crear dirección"
                type={isCountryValid ? 'primary-mx' : 'primary'}
              />
            </div>
          </div>
        )}
      </div>
      {
        showCreateAddressModal && (
          <div>
            <ModalCreateAddress
              closeAndRemove={() => {
                setShowCreateAddressModal(false);
              }}
              setShowCreateAddressModal={() => {
                setShowCreateAddressModal(false);
              }}
              user={user}
              onChangeListAddress={setListAddress}
            />
          </div>
        )
      }
      {
        showUpdateModal && editItem && (
          <div>
            <ModalUpdateAddress
              editItem={editItem}
              closeAndRemove={() => {
                setShowUpdateModal(false);
              }}
              setShowUpdateModal={() => { setShowUpdateModal(false); }}
              user={user}
              onChangeListAddress={setListAddress}
            />
          </div>
        )
      }
      {
        showModalDelete && (
          <ModalDeleteAddress
            closeAndRemove={() => {
              setShowModalDelete(false);
            }}
            setShowModalDelete={setShowModalDelete}
            setListAddress={setListAddress}
            deleteAddress={deleteAddress}
          />
        )
      }
    </div>

  );
};

export default MyAddressesSettingsViewer;
