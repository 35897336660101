/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import 'moment/locale/es';

import { findIndexOfObject, findLocationByName, findLocationByNameMexico } from './filters';

export const whiteLogoRegister = (currentSection) => {
  if (
    currentSection === '/ingreso'
    || currentSection === '/registro'
  ) {
    return true;
  }
  return false;
};

export const fullWidth = (currentSection) => {
  if (
    currentSection === '/restaurar-contrasena'
    || currentSection === '/validar-correo'
    || currentSection === '/pre-registro'
    || currentSection === '/campaña-de-recuperacion'
  ) {
    return true;
  }
  return false;
};

export const backgroundWhite = (currentSection) => currentSection === '/primer-ingreso'
    || currentSection === '/pre-registro';

export const backgroundBlue = (currentSection) => {
  if (
    currentSection === '/crear-transportadora'
    || currentSection === '/billetera'
    || currentSection === '/buscar-codigo-mp'
    || currentSection === '/reportes-transportadora'
    || currentSection === '/informacion-transportadora'
    || currentSection === '/transportadora'
    || currentSection === '/campaña-de-recuperacion'
    || currentSection === '/acumula-puntos'
  ) {
    return true;
  }
  return false;
};

export const backgroundFigurePosition = (currentSection) => {
  if (
    currentSection === '/restaurar-contrasena'
    || currentSection === '/validar-correo'
    || currentSection === '/pre-registro'
    || currentSection === '/campaña-de-recuperacion'
  ) {
    return 'bottom-right';
  }
  return 'top-left';
};

export const hiddenRigthMenu = () => false;

export const humanizationOfTimeDuration = (minutesParam) => {
  if (minutesParam) {
    const duration = moment.duration(minutesParam, 'minutes');

    if (duration._data.months > 0) {
      if (duration._data.months === 1) {
        return `${duration._data.months} mes`;
      }
      return `${duration._data.months} meses`;
    } if (duration._data.days > 0) {
      if (duration._data.days === 1) {
        return `${duration._data.days} día hábil`;
      }
      return `${duration._data.days} días hábiles`;
    } if (duration._data.hours > 0) {
      if (duration._data.hours === 1) {
        return `${duration._data.hours} hora hábil`;
      }
      return `${duration._data.hours} horas hábiles`;
    } if (duration._data.minutes > 0) {
      if (duration._data.minutes === 1) {
        return `${duration._data.minutes} minuto`;
      }
      return `${duration._data.minutes} minutos`;
    }
    return '0';
  }
  return minutesParam;
};

export const humanizationOfTimeDate = (date, format) => {
  if (date) {
    if (format === 'general') return moment(date).format('YYYY/MM/DD');
    return moment(date).calendar();
  }
  return date;
};

export const findImageIntoDeliveries = (deliveryCompanyId, deliveryCompaniesList) => {
  if (deliveryCompaniesList) {
    const index = findIndexOfObject(deliveryCompanyId, deliveryCompaniesList, '_id');
    if (index >= 0) {
      return deliveryCompaniesList[index].image;
    }
    return null;
  }
  return null;
};

export const proccesCodeLocation = (
  countryCode,
  locationString,
  codeKey,
  setLocationsCode,
  locations) => {
  let locationObject;
  const splitted = locationString.split('-');

  if (splitted.length > 1) {
    if (countryCode === '170') {
      locationObject = findLocationByName(
        splitted[0],
        splitted[1],
        locations,
      );
    } else {
      locationObject = findLocationByNameMexico(
        splitted[0],
        splitted[1],
        splitted[2],
        splitted[3],
        locations,
      );
    }
    setLocationsCode((prevCodes) => ({
      ...prevCodes,
      [codeKey]: locationObject?.locationCode ?? '',
    }));
  }
};
export const findNameIntoDeliveries = (deliveryCompanyId, deliveryCompaniesList) => {
  if (!Array.isArray(deliveryCompaniesList)) return null;
  return deliveryCompaniesList.find((company) => company._id.toString()
    === deliveryCompanyId)?.name;
};

export const findCodeLocation = (locationName) => {
  const numbersCode = locationName?.match(/\d+/g);
  return numbersCode ? numbersCode.join('') : '';
};
export const addDiscount = (
  shippingCost,
  shippingRealCost,
  deliveryCompanyId,
  deliveryRouteType,
  negotiation,
  config,
) => {
  if (shippingRealCost || negotiation) return shippingRealCost || 0;

  const delivery = config
    .find(({ deliveryCompany }) => deliveryCompany === deliveryCompanyId);

  if (!delivery?.discounts) return shippingRealCost || 0;
  if (!delivery?.discounts.enabled) return shippingRealCost || 0;

  const existRouteType = delivery.discounts[deliveryRouteType];

  return existRouteType ? (1 + existRouteType) * shippingCost : 0;
};
export default {
  fullWidth,
};

export const optionsSelectFiltered = (name, options, personType) => {
  if (personType === 'Persona Natural' && name === 'documentType') {
    return options.filter((option) => option.value !== 'NIT');
  }
  return options;
};
