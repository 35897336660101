const deliveriesSelected = (dataDeliveryCompany, key) => {
  const companySelected = dataDeliveryCompany.filter((deliveryCompany) => deliveryCompany.checked);
  return companySelected.map((company) => company[key]);
};

export const deliveryCompaniesSelectedId = (dataDeliveryCompany) => deliveriesSelected(dataDeliveryCompany, '_id');

export const deliveryCompaniesSelectedName = (dataDeliveryCompany) => deliveriesSelected(dataDeliveryCompany, 'name');

export const statesSelected = (dataStatesReport) => {
  const states = dataStatesReport.filter((state) => state.checked);
  return states.map((state) => state.state);
};

export const statesSelectedName = (dataStatesReport) => {
  const states = dataStatesReport.filter((state) => state.checked);
  return states.map((state) => state.state);
};
