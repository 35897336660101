import React from 'react';
import PropTypes from 'prop-types';

import './containerSectionMobile.scss';

const ContainerSectionMobile = ({ children, className }) => (
  <div className={`container-section-mobile ${className || ''}`}>
    {children}
  </div>
);

ContainerSectionMobile.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

ContainerSectionMobile.defaultProps = {
  className: null,
};

export default ContainerSectionMobile;
