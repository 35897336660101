/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import Modal from '../../../../components/modal';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Typography from '../../../../components/typography';

import { createUsersAddresses } from '../../../../services/user';
import { findLocationByName } from '../../../../utils/filters';
import { getLocationsService } from '../../../../services/location';
import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import './modalCreateAddress.scss';
import addressValidation from './validations';

const ModalCreateAddress = ({
  closeAndRemove,
  setShowCreateAddressModal,
  onChangeListAddress,
  isMobileDevice,
}) => {
  const [locations, setLocations] = useState(null);
  const [newLocation, setNewLocation] = useState('');
  const [addressName, setAddressName] = useState('');
  const [address, setAddress] = useState('');
  const [newLocationCode, setNewLocationCode] = useState('');
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [annotationError, setAnnotationError] = useState('');
  const [annotationErrorAddressName, setAnnotationErrorAddressName] = useState('');
  const [annotationErrorAddress, setAnnotationErrorAddress] = useState('');

  const createAddressService = async (data) => {
    const createAddress = await createUsersAddresses([data]);
    onChangeListAddress(createAddress);
  };

  const genLocationService = async () => {
    const getLocation = await getLocationsService();
    setLocations(getLocation);
  };

  useEffect(() => {
    genLocationService();
  }, []);

  useEffect(() => {
    const originSplitted = newLocation.split('-');
    if (originSplitted.length > 1) {
      const originObject = findLocationByName(
        originSplitted[0], originSplitted[1], locations,
      );
      setNewLocationCode(originObject?.locationCode ?? '');
    }
  }, [newLocation]);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const modalParagraph = (
    <p>
      Te notificaremos por
      {' '}
      <span>correo electrónico</span>
      {' '}
      y
      {' '}
      <span>WhatsApp</span>
      {' '}
      cuando esta
      <br />
      sede, que ahora está
      {' '}
      <span>"En proceso"</span>
      , cambie al estado
      {' '}
      <span>"Registrada"</span>
      {' '}
      .
    </p>
  );

  return (
    <div>
      <Modal closeBackground={closeAndRemove}>
        <div className="createDirection">
          <div className="iconClose" onClick={setShowCreateAddressModal} />
          <div className="centerTitleModal">
            <Typography
              type="title"
              text="Nueva Sede de recogida"
              className="italic"
            />
          </div>
          <div className="center">
            <div className="input-top">
              {
                locations
                && (
                  <Input
                    label="¿Cuál es la ciudad de origen?"
                    placeholder="¿Cuál es la ciudad de origen?"
                    value={newLocation}
                    dataList={locations.map((location) => `${location.locationName}-${location.departmentOrStateName}`)}
                    onChange={(value) => {
                      setNewLocation(value);
                      !value ? setAnnotationError('Origen no encontrado') : setAnnotationError(null);
                    }}
                    onClick={(value) => {
                      !value ? setAnnotationError('Origen no encontrado') : setAnnotationError(null);
                    }}
                    annotationError={annotationError}
                  />
                )
              }
            </div>
            <div className="input-mid">
              <Input
                label="Nombre de la sede"
                placeholder="Sede de caribe"
                value={addressName}
                onChange={(value) => {
                  setAddressName(value);
                  !value ? setAnnotationErrorAddressName('Ingresa el nombre de tu dirección') : setAnnotationErrorAddressName(null);
                }}
                onClick={(value) => {
                  !value ? setAnnotationErrorAddressName('Ingresa el nombre de tu dirección') : setAnnotationErrorAddressName(null);
                }}
                annotationError={annotationErrorAddressName}
              />
            </div>
            <div className="input-bottom">
              <Input
                label="Escribe la dirección"
                placeholder="Carrera 11 #00 - 00"
                value={address}
                onChange={(value) => {
                  setAddress(value);
                  addressValidation(value, setAnnotationErrorAddress);
                }}
                onClick={(value) => {
                  addressValidation(value, setAnnotationErrorAddress);
                }}
                annotationError={annotationErrorAddress}
                annotation="Recuerda agregar datos detallados"
              />
            </div>
          </div>
          <div className="buttonModal">
            <Button
              type="secondary"
              text="Cancelar"
              id="cancelModal"
              onClick={setShowCreateAddressModal}
            />
            <Button
              type={isCountryValid ? 'primary-mx' : 'primary'}
              text="Guardar nueva sede"
              id="saveModal"
              disabled={false}
              onClick={newLocationCode && addressName && address ? () => {
                createAddressService({
                  locationCode: newLocationCode,
                  name: addressName,
                  address,
                  countryCode: '170',
                });
                setShowModalConfirm(true);
              } : () => {
                if (!addressName) setAnnotationErrorAddressName('Ingresa el nombre de tu dirección');
                if (!address) setAnnotationErrorAddress('Ingresa la dirección de origen');
                if (!newLocationCode) setAnnotationError('Origen no encontrado');
              }}
            />
          </div>
        </div>
      </Modal>
      {
        showModalConfirm && newLocation && (
          <div>
            <Modal closeBackground={() => {
              setShowModalConfirm(false);
              setShowCreateAddressModal();
            }}
            >
              <div className="confirmSaveDirectionCreate">
                <button
                  type="button"
                  className="iconClose2"
                  onClick={setShowCreateAddressModal}
                  aria-label="cerrar"
                />
                <div className="contentConfirmAddresses">
                  <Typography
                    type="title"
                    text="Se ha creado la Sede con éxito"
                    className="confirmAddressTitle"
                  />
                  <Typography
                    type="pagrapah"
                    text={modalParagraph}
                    className="confirmAddressParagraph"
                  />
                  <div className="confirmAddressImgContent">
                    <div className="inProcessContent">
                      <div className="inProcessImg" />
                      {
                        isMobileDevice ? (
                          <div className="inProcessTextMobile">
                            Tu sede se está registrando
                            {' '}
                            <br />
                            {' '}
                            en
                            {' '}
                            <span>Inter Rapidísimo</span>
                          </div>
                        ) : (
                          <div className="inProcessText">
                            Tu sede se está registrando en
                            {' '}
                            <span>Inter Rapidísimo</span>
                          </div>
                        )
                      }
                    </div>
                    <div className="enabledContent">
                      <div className="enabledImg" />
                      {
                        isMobileDevice ? (
                          <div className="enabledTextMobile">
                            Tu sede ya está habilitada y podrás
                            {' '}
                            <br />
                            {' '}
                            enviar con
                            {' '}
                            <span>Inter Rapidísimo</span>
                          </div>
                        )
                          : (
                            <div className="enabledText">
                              Tu sede ya está habilitada y podrás enviar
                              {' '}
                              <br />
                              {' '}
                              con
                              {' '}
                              <span>Inter Rapidísimo</span>
                            </div>
                          )
                      }
                    </div>
                  </div>
                  <Button
                    type={isCountryValid ? 'primary-mx' : 'primary'}
                    text="¡Entendido!"
                    className="confirmAddressButton"
                    onClick={setShowCreateAddressModal}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )
      }
    </div>
  );
};

ModalCreateAddress.propTypes = {
  setShowCreateAddressModal: PropTypes.func.isRequired,
  closeAndRemove: PropTypes.func.isRequired,
  onChangeListAddress: PropTypes.shape().isRequired,
  isMobileDevice: PropTypes.func.isRequired,
};

ModalCreateAddress.defaultProps = {};

const mapStateToProps = (state) => ({
  user: (state.auth) ? state.auth?.user : {},
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(ModalCreateAddress);
