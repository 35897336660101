import React from 'react';

import PropTypes from 'prop-types';

import Modal from '../../../../components/modal';
import ModalChildren from '../../../../components/modalChildren';
import RadioButton from '../../../../components/radioButton';
import Typography from '../../../../components/typography';

import { optionsResults } from '../data';

const OrderCollections = ({
  setShowOrderCollections, setModalFilterMobile,
}) => (
  <Modal className="order-my-collections__mobile">
    <ModalChildren
      className="order-my-collections"
      headerText="Ordenar resultados"
      bodyText={(
        <div className="order-my-collections__content">
          <div className="first-content">
            <div className="first-content__items">
              <Typography
                type="field-dark-gray"
                text="Más reciente primero"
              />
              <RadioButton
                name="account-type"
                value="ahorros"
                onChange={() => { }}
                className="primeryColor"
              />
            </div>
            <div className="first-content__items">
              <Typography
                type="field-dark-gray"
                text="Más reciente primero"
              />
              <RadioButton
                name="account-type"
                value="ahorros"
                onChange={() => { }}
                className=""
              />
            </div>
          </div>
          <div className="second-content">
            <Typography
              type="superTitle-italic"
              text="Ver resultados"
              className="color-darker-gray heebo-medium second-title"
            />
            {optionsResults.map((data) => (
              <div className="second-content__items">
                <Typography
                  type="field-dark-gray"
                  text={data.text}
                />
                <RadioButton
                  name="account-type"
                  value="ahorros"
                  onChange={() => { }}
                  className="primeryColor"
                />
              </div>
            ))}
          </div>
        </div>
      )}
      close={() => {
        setShowOrderCollections(false);
        setModalFilterMobile(false);
      }}
      onClickButtonConfirm={() => { }}
      confirmButtonType="primary"
      classButtonConfirm="button-confirm"
      confirmButtonText="Guardar"
    />
  </Modal>
);

OrderCollections.propTypes = {
  setShowOrderCollections: PropTypes.func.isRequired,
  setModalFilterMobile: PropTypes.func.isRequired,
};

export default OrderCollections;
