/* eslint-disable no-return-assign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import parse from 'react-html-parser';

import { urlMassiveTemplateExcel } from '../../../../constants';

import { ReactComponent as ArrowBack } from '../../../../assets/images/icon-arrow-back-color-primary.svg';
import ContentSection from '../../../../components/contentSection';
import Typography from '../../../../components/typography';
import Button from '../../../../components/button';
import Input from '../../../../components/input';

import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import Add from '../../../../assets/images/add.svg';

import './stepOne.scss';

const text = parse('<span>Aprende <strong style="color:#009AF0; cursor:pointer">aquí</strong> como utilizarla</span>');
const linkYoutube = 'https://www.youtube.com/watch?v=mYfA2K8KO5Y&t=1s';

const StepOneQuoteMultipleShippings = ({
  next, isMobileDevice, history, file, setFile, fileName, setFileName,
  readExcelLoading,
}) => {
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);
  return (
    <ContentSection
      className="container-stepOne-quoteMultipleShippings"
    >
      <div
        id="multiple-shippings__template"
        className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}
      >
        <div className="content-top">
          <div className={isMobileDevice ? 'adjust-arrow' : null}>
            <ArrowBack
              className={isMobileDevice ? 'arrow' : 'arrow-hidden'}
              onClick={() => history.goBack()}
            />
            {isMobileDevice && (
              <Typography
                text="Cotización Masiva"
                type="superTitle-italic"
              />
            )}
          </div>
          <Typography
            text="Plantilla múltiples envíos"
            type="title"
          />
          <Typography
            text="Diligencia la plantilla para solicitar envíos"
            type="paragraph"
          />
          <Typography
            text={text}
            type="paragraph"
            className=""
            onClick={() => {
              window.open(linkYoutube, '_blank', 'noopener');
            }}
          />
          <Input
            label="Selecciona el archivo a cargar"
            value={fileName}
            onChange={(target) => {
              setFile(target.files[0]);
              setFileName(target.files[0].name);
            }}
            type="file"
            iconLeft={Add}
            leftIconCustomClass="add_box"
            personalizedValueSpan="w100"
            typeHidden
            lineHidden
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.ms-excel"
          />
          <div className="content-bottom">
            <Button
              text="Cargar archivo"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              disabled={!file}
              onClick={() => next()}
              loading={readExcelLoading}
            />
            <Typography
              text="Descargar plantilla"
              type="superTitle-italic"
              className="accent"
              onClick={() => {
                window.open(urlMassiveTemplateExcel, '_blank', 'noopener');
              }}
            />
          </div>
        </div>
      </div>
    </ContentSection>
  );
};

StepOneQuoteMultipleShippings.propTypes = {
  next: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  file: PropTypes.any,
  setFile: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  setFileName: PropTypes.func.isRequired,
  readExcelLoading: PropTypes.bool.isRequired,
};

StepOneQuoteMultipleShippings.defaultProps = {
  file: null,
  fileName: null,
};

export default StepOneQuoteMultipleShippings;
