import { createInteractionSchema, getAds, getInteraction } from '../../services/interactions';

export const getAdsService = async () => {
  try {
    const ad = await getAds();
    return ad?.[0];
  } catch (error) {
    return null;
  }
};

export const getInteractionAd = async (ad, user) => {
  try {
    const interactionFound = await getInteraction(ad, user);
    return !interactionFound.exists;
  } catch (error) {
    return false;
  }
};

export const createInterationService = async (ad) => {
  try {
    return await createInteractionSchema(ad._id);
  } catch (error) {
    return false;
  }
};
