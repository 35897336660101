const errorGuideActive = ({ error, state }) => state === 'Procesando tu envío' && error;
export const guideErrorState = (shipment) => {
  if (errorGuideActive(shipment)) {
    return 'Envío no procesado';
  }

  return shipment.state;
};

export default errorGuideActive;
