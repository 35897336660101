import React from 'react';
import { PropTypes } from 'prop-types';

import { findObjectInArray } from '../../../../../utils/filters';

import Typography from '../../../../../components/typography';
import RadioButton from '../../../../../components/radioButton';

const showAnotationError = (index, lastIndex, propertyName, errorsList) => {
  if (index === lastIndex) {
    return findObjectInArray(propertyName, errorsList, 'name')
      ? findObjectInArray(propertyName, errorsList, 'name').error
      : null;
  }
  return '';
};

const typeValue = (type, value) => {
  if (type === 'number') return Number.parseInt(value, 10);
  return value;
};

const RadioButtonSchema = ({
  input, data, setData, errorsList, setInputInformation,
}) => {
  const {
    showTitleToUser, propertyName, radioButtonOptions,
  } = input;

  return (
    <div>
      <Typography
        text={showTitleToUser}
        type="value"
        className="radio-button-title"
      />
      {radioButtonOptions.map((option, index) => (
        <RadioButton
          name={showTitleToUser}
          key={(index + 1).toString()}
          text={option.label}
          value={option.value}
          checked={option.typeValue
            ? typeValue(option.typeValue, data[propertyName]) === option.value
            : data[propertyName] === option.value}
          onChange={(e) => setInputInformation(
            data, { propertyName, value: e }, setData)}
          annotationError={showAnotationError(
            index, radioButtonOptions.length - 1, propertyName, errorsList,
          )}
        />
      ))}
    </div>
  );
};

RadioButtonSchema.propTypes = {
  input: PropTypes.shape().isRequired,
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setInputInformation: PropTypes.func.isRequired,
};

export default (RadioButtonSchema);
