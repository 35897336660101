import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalDeliveryCompaniesData } from './load';
import { UpdateDataGlobalAction } from '../../store/actions/global.actions';

const GlobalData = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const UpdateDataGlobalFunction = (data) => (dispatch(UpdateDataGlobalAction(data)));

  useEffect(() => {
    if (user) updateGlobalDeliveryCompaniesData(UpdateDataGlobalFunction);
  }, [user]);

  return (
    <>
    </>
  );
};

export default GlobalData;
