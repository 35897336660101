import { setHeaderRequest } from '../services/config';
import { loginService, loginSocialAccountService, pointsCounterService } from '../services/user';
import {
  decriptData, encriptData, loginStorage, setCurrentUser,
} from './auth';

const getUserPoints = async (userId) => {
  try {
    const responsePoints = await pointsCounterService(userId);
    return parseInt(responsePoints?.points ?? 0, 10);
  } catch (e) {
    return 0;
  }
};

const loginSaveData = async (
  userAndTokenObject, keep, updateTokenFunction, SignInFunction, setLoadingToken,
  setCountryFunction,
  needEncrypt,
) => {
  const realTokebObject = needEncrypt ? decriptData(userAndTokenObject) : userAndTokenObject;
  setHeaderRequest(realTokebObject.token);
  const newUserAndTokenObject = realTokebObject;

  const userPoints = await getUserPoints(userAndTokenObject.user._id);
  newUserAndTokenObject.user.points = userPoints;

  await setCurrentUser(newUserAndTokenObject, keep);
  updateTokenFunction(encriptData(newUserAndTokenObject));
  SignInFunction(newUserAndTokenObject.user);
  setCountryFunction(newUserAndTokenObject?.user?.countryCode);
  setLoadingToken(false);
  return null;
};

export const loginSystem = async (
  updateTokenFunction, SignInFunction, setLoadingToken, setCountryFunction) => {
  try {
    const data = await loginStorage();
    const userToLogin = data?.data?.user ?? {};

    if (userToLogin.accessToken && userToLogin.socialUserId && userToLogin.socialNetwork) {
      const { accessToken, socialUserId, socialNetwork } = userToLogin;
      const response = await loginSocialAccountService({
        accessToken, socialUserId, socialNetwork,
      });
      response.user.accessToken = accessToken;
      response.user.socialUserId = socialUserId;
      response.user.socialNetwork = socialNetwork;
      loginSaveData(
        response, data.keep, updateTokenFunction,
        SignInFunction, setLoadingToken, setCountryFunction);
    } else if (userToLogin.email && userToLogin.password) {
      const newToken = await loginService({
        email: userToLogin.email,
        password: userToLogin.password,
      });
      newToken.user.password = userToLogin.password;
      loginSaveData(
        newToken, data.keep, updateTokenFunction,
        SignInFunction, setLoadingToken, setCountryFunction);
    }

    return null;
  } catch (error) {
    window.localStorage.clear();
    window.location.reload();
    throw error;
  }
};

export default {
  loginSystem,
};
