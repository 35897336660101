const splitLists = (originalList, limitSize) => {
  const listOfLists = [];
  for (let i = 0; i < originalList.length; i += limitSize) {
    const splitList = originalList.slice(i, i + limitSize);
    listOfLists.push(splitList);
  }
  return listOfLists;
};

export default splitLists;
