/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './notificationsSettings.scss';
import StateSwitch from '../stateSwitch';
import getShippingStatus from '../../services/getConfigurations';
import Typography from '../typography';
import Tooltip from '../tooltip';

import MarkQuestion from '../../assets/images/mark-question-icon.svg';

const NotificationsSettings = ({ onChange, TypeNotifications, customClass }) => {
  const [listStatus, setLisStatus] = useState(null);
  const [activeToggle, setActiveToggle] = useState(true);
  const [countNotifications, setCountNotifications] = useState(0);

  useEffect(() => {
    if (listStatus) {
      const AllNotificationSaas = listStatus.filter((status) => status.activeSaas === true);
      const AllNotificationMp = listStatus.filter((status) => status.activeMp === true);
      setCountNotifications((TypeNotifications === 'mp') ? AllNotificationMp.length : AllNotificationSaas.length);
    }
  }, [listStatus]);

  useEffect(async () => {
    const getListStatus = await getShippingStatus();
    setLisStatus(getListStatus);
    onChange(getListStatus);
  }, []);

  const tooltipComponent = () => (
    <div className="toggle">
      <div className="container-tooltip">
        <Typography
          type="field-dark-gray"
          className="active-notifications"
          text={`${countNotifications} notificaciones activas`}
        />
        <Tooltip
          overlayText="Notificaciones de trazabilidad que tu destinatario recibirá por WhatsApp. Actívalas o Desactívalas como prefieras."
          startingPosition="bottom-rigth"
          iconTooltip={MarkQuestion}
        />
      </div>
      <div
        className="show-shipping-info-detail"
      >
        <label onClick={() => {
          setActiveToggle(!activeToggle);
        }}
        >
          <span>Cambiar</span>
          <div className={`image ${activeToggle ? 'displayed' : ''}`} />
        </label>
      </div>
    </div>
  );

  return (
    <>
      <div className={`settings-payment-notifications ${!activeToggle ? 'isInactive' : ''} ${customClass}`}>
        {(TypeNotifications === 'mp') && (
          <>
            {tooltipComponent()}
            <div className={`section-payment-notifications ${(activeToggle) ? '' : 'isInactive'}`}>
              {(listStatus) && (
                <>
                  {listStatus.map(
                    (status) => (
                      <StateSwitch
                        key={status.code}
                        text={status.description}
                        active={!!(status.activeMp)}
                        cost={status.fee.mp}
                        onChange={() => {
                          const listShipping = [];
                          listStatus.forEach((element) => {
                            const elementStatus = element;
                            if (elementStatus.code === status.code) {
                              elementStatus.activeMp = !status.activeMp;
                            }
                            listShipping.push(elementStatus);
                          });
                          setLisStatus(listShipping);
                          onChange(listShipping);
                        }}
                      />
                    ))}
                </>
              )}
            </div>
          </>
        )}
        {(TypeNotifications === 'saas') && (
          <>
            {tooltipComponent()}
            <div className={`section-payment-notifications ${(activeToggle) ? '' : 'isInactive'}`}>
              {(listStatus) ? (
                <>
                  {listStatus.map(
                    (status) => (
                      <StateSwitch
                        key={status.code}
                        text={status.description}
                        active={!!(status.activeSaas)}
                        cost={status.fee.saas}
                        onChange={() => {
                          const listShipping = [];
                          listStatus.forEach((element) => {
                            const elementStatus = element;
                            if (elementStatus.code === status.code) {
                              elementStatus.activeSaas = !status.activeSaas;
                            }
                            listShipping.push(elementStatus);
                          });
                          setLisStatus(listShipping);
                          onChange(listShipping);
                        }}
                      />
                    ))}
                </>
              ) : null}
            </div>
          </>

        )}
      </div>
    </>
  );
};

NotificationsSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
  TypeNotifications: PropTypes.string,
  customClass: PropTypes.string,
};

NotificationsSettings.defaultProps = {
  TypeNotifications: 'mp',
  customClass: '',
};

export default NotificationsSettings;
