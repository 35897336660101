import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { apiKeyPriceLimit, currentCurrency, formatNumberAsCurrency } from 'constants/general';

import Typography from '../../../../components/typography';

import emptyLogo from '../../../../assets/images/integrationsEmptyState.svg';

import './emptyState.scss';

const title = (countryCode, isMobileDevice) => (
  <>
    Recarga al menos $
    {formatNumberAsCurrency(apiKeyPriceLimit[countryCode])}
    {' '}
    {currentCurrency[countryCode]}
    {' '}
    o comunícate con tu asesor
    {isMobileDevice ? '' : <br />}
    comercial o
    al correo soporte@mipaquete.com
    {isMobileDevice ? '' : <br />}
    para revisar tu cupo crédito
  </>
);

const EmptyStateCreditUser = ({ isMobileDevice }) => {
  const countryCode = useSelector((state) => state.country);

  return (
    <div className="empty-state-container">
      <img alt="empty" src={emptyLogo} className="logo" />
      <Typography text="Aún no puedes generar claves" className="title" />
      <Typography text={title(countryCode, isMobileDevice)} className="subtitle" />
    </div>
  );
};

EmptyStateCreditUser.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
};

EmptyStateCreditUser.defaultProps = {};

export default EmptyStateCreditUser;
