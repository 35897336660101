import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';

import { capitalizeWords, toCurrencyCop } from '../../../../utils/strings';
import { humanizationOfTimeDuration } from '../../../../utils/generals';
import { collectionServiceValue, finalPrice } from '../../../../utils/prices';
import { typeDelivery } from '../../../../constants/general';

import CheckBox from '../../../checkBox';
import Typography from '../../../typography';

import '../columns.scss';

const Left = ({
  deliverySelected, shippingInfo, productInfo, isMobileDevice,
  addShippingToCollectionValue, setAddShippingToCollectionValue,
}) => {
  const user = useSelector((state) => state?.auth?.user || null);
  const [showShippingInfoDetail, setShowShippingInfoDetail] = useState(false);
  const [customerPayShipping, setCustomerPayShipping] = useState(addShippingToCollectionValue);
  const [showCollectionService] = useState(
    shippingInfo.collectionService,
  );

  const totalRealCost = finalPrice(
    deliverySelected.shippingRealCost,
    shippingInfo.collectionService,
    addShippingToCollectionValue,
    deliverySelected.collectionCommissionWithRate,
    deliverySelected.collectionCommissionWithOutRate,
  );

  const [totalSendingValue, setTotalSendingValue] = useState(toCurrencyCop(user, Math.floor(
    finalPrice(
      deliverySelected.shippingCost,
      shippingInfo.collectionService,
      addShippingToCollectionValue,
      deliverySelected.collectionCommissionWithRate,
      deliverySelected.collectionCommissionWithOutRate,
    )),
  ));

  const [totalValue, setTotalValue] = useState(toCurrencyCop(
    user,
    collectionServiceValue(
      productInfo.saleValue,
      deliverySelected.shippingCost,
      shippingInfo.collectionService,
      addShippingToCollectionValue,
      deliverySelected.collectionCommissionWithRate,
      deliverySelected.collectionCommissionWithOutRate,
    ),
  ));

  const [showContainerValues, setShowContainerValues] = useState(false);

  useEffect(() => {
    setTotalSendingValue(
      toCurrencyCop(
        user,
        finalPrice(
          deliverySelected.shippingCost,
          shippingInfo.collectionService,
          addShippingToCollectionValue,
          deliverySelected.collectionCommissionWithRate,
          deliverySelected.collectionCommissionWithOutRate,
        ),
      ),
    );

    setTotalValue(
      toCurrencyCop(
        user,
        collectionServiceValue(
          productInfo.saleValue,
          deliverySelected.shippingCost,
          shippingInfo.collectionService,
          addShippingToCollectionValue,
          deliverySelected.collectionCommissionWithRate,
          deliverySelected.collectionCommissionWithOutRate,
        ),
      ),
    );
  }, [customerPayShipping]);

  return (
    <div className={`delivery-company ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
      <Typography
        text={capitalizeWords(typeDelivery[user.countryCode])}
        type="title"
        className="center-text hebboMedium"
      />
      <div className="delivery-company-info">
        <div className="delivery-company-image">
          <img
            src={deliverySelected.deliveryCompanyImgUrl}
            alt={deliverySelected.deliveryCompanyName}
          />
        </div>
        <div className="content-delivery-info-detail">
          <Typography
            text={deliverySelected.deliveryCompanyName}
            type="title"
            className="hebboMedium"
          />
          <div className="delivery-time">
            <Typography
              type="value"
              text="Promedio de entrega:"
              className="size"
            />
            <Typography
              type="value"
              className="shipping-time size"
              text={humanizationOfTimeDuration(deliverySelected.shippingTime)}
            />
          </div>
        </div>
      </div>
      <div className="shipping-value-info">
        <Typography
          text="Valor total de envío:"
          type="value"
        />
        <div className="shipping-value-info__discount">
          <Typography
            text={totalSendingValue}
            type="value"
            className={deliverySelected.shippingRealCost ? 'discount' : null}
          />
          {deliverySelected.shippingRealCost && (
            <Typography
              text={toCurrencyCop(user, totalRealCost)}
              type="value"
              className="real"
            />
          )}
        </div>
      </div>
      {showShippingInfoDetail && (
        <div className="shipping-values-detail">
          <div className="freight-value">
            <Typography
              text="Valor del flete:"
              type="value"
            />
            <Typography
              text={toCurrencyCop(user, Math.floor(deliverySelected.shippingCost))}
              type="value"
            />
          </div>
          {shippingInfo.collectionService && (
            <div className="freight-value">
              <Typography
                text="Comisión de recaudo:"
                type="value"
              />
              <Typography
                text={toCurrencyCop(
                  user,
                  addShippingToCollectionValue
                    ? deliverySelected.collectionCommissionWithRate
                    : deliverySelected.collectionCommissionWithOutRate)}
                type="value"
              />
            </div>
          )}
        </div>
      )}
      <div
        className="show-shipping-info-detail"
      >
        <button
          type="button"
          onClick={() => setShowShippingInfoDetail(!showShippingInfoDetail)}
        >
          {showShippingInfoDetail ? (
            'Ocultar detalle'
          ) : (
            'Ver detalle'
          )}
          <div
            className={`image ${showShippingInfoDetail} ? 'displayed' : ''`}
          />
        </button>
      </div>
      {showCollectionService && (
        <div className="show-collection-service-info">
          <Typography
            text="El precio incluye el pago contra entrega"
            type="value"
          />
        </div>
      )}
      {showCollectionService && (
        <CheckBox
          checked={customerPayShipping}
          onChange={(checked) => {
            setAddShippingToCollectionValue(checked);
            setCustomerPayShipping(checked);
          }}
          text="Quiero que mi destinatario también pague el envío"
        />
      )}
      <div className="totals-information">
        {!isMobileDevice && (
          <>
            {productInfo.saleValue && (
              <div className="product-value">
                <Typography
                  text="Valor de tu producto:"
                  type="value"
                />
                <Typography
                  text={toCurrencyCop(user, productInfo.saleValue)}
                  type="value"
                />
              </div>
            )}
            <div className="shipping-value">
              <Typography
                text="Valor de envío:"
                type="value"
              />
              <Typography
                text={totalSendingValue}
                type="value"
              />
            </div>
            {user.countryCode === '170' && (
              <div className="totals">
                <Typography
                  text="Tu cliente pagará:"
                  type="title"
                />
                <Typography
                  text={totalValue}
                  type="title"
                />
              </div>
            )}
          </>
        )}
        {isMobileDevice && showCollectionService && (
          <button
            type="button"
            className="toals-box"
            onClick={() => setShowContainerValues(!showContainerValues)}
          >
            {user.countryCode === '170' && (
              <div className="totals">
                <Typography
                  text="Tu cliente pagará:"
                  type="title"
                />
                <Typography
                  text={totalValue}
                  type="title"
                />
              </div>
            )}
            <div className={`container-values ${showContainerValues ? 'show' : ''}`}>
              <div className="product-value">
                <Typography
                  text="Valor de tu producto:"
                  type="value"
                />
                <Typography
                  text={toCurrencyCop(user, productInfo.saleValue)}
                  type="value"
                />
              </div>
              <div className="shipping-value">
                <Typography
                  text="Valor de envío:"
                  type="value"
                />
                <Typography
                  text={totalSendingValue}
                  type="value"
                />
              </div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

Left.propTypes = {
  deliverySelected: PropTypes.shape({
    deliveryCompanyId: PropTypes.string.isRequired,
    collectionCommissionWithRate: PropTypes.number,
    shippingCost: PropTypes.number.isRequired,
    collectionCommissionWithOutRate: PropTypes.number,
    shippingRealCost: PropTypes.number,
    shippingTime: PropTypes.number.isRequired,
    deliveryCompanyName: PropTypes.string.isRequired,
    deliveryCompanyImgUrl: PropTypes.string.isRequired,
  }).isRequired,
  shippingInfo: PropTypes.shape({
    collectionService: PropTypes.bool.isRequired,
    declaredValues: PropTypes.string.isRequired,
  }).isRequired,
  addShippingToCollectionValue: PropTypes.bool,
  setAddShippingToCollectionValue: PropTypes.func.isRequired,
  productInfo: PropTypes.shape({
    saleValue: PropTypes.string,
  }),
  isMobileDevice: PropTypes.bool.isRequired,
};

Left.defaultProps = {
  addShippingToCollectionValue: false,
  productInfo: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(Left);
