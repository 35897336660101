/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Typography from '../../../../components/typography';

import '../myAddressesSettingsViewer.scss';

const Addresses = ({ onChangeEdit, onChangeDelete, item }) => (
  <div className="default-address2">
    <div><Typography className="font-title2" type="subtitle" text={item.name} /></div>
    <div><Typography className="address-font2" type="paragraph" text={`${item.locationName}-${item.departmentOrStateName}`} /></div>
    <div><Typography className="address-font2" type="paragraph" text={item.address} /></div>
    <div className="adjust-items">
      <div onClick={onChangeEdit}><Typography className="edit-profile2" type="subtitle" text="Editar" /></div>
      <div onClick={onChangeDelete} className="editaTrashcan" />
    </div>
  </div>
);

Addresses.propTypes = {
  onChangeEdit: PropTypes.shape().isRequired,
  onChangeDelete: PropTypes.shape().isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    locationName: PropTypes.string,
    departmentOrStateName: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
};

Addresses.defaultProps = {};

const mapStateToProps = (state) => ({
  user: (state.auth) ? state.auth?.user : {},
  isMobileDevice: state.mediaQuery.isMobileDevice,
  onChange: PropTypes.bool.isRequired,
});

export default compose(
  connect(mapStateToProps),
)(Addresses);
