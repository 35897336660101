import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Typography from '../../../typography';
import Input from '../../../input';
import Button from '../../../button';

import useHandleFields from '../../../../hooks/useHandleFields';
import { useFindLocations, useLocationService } from '../../../../hooks/useShippingSteps';
import { findObjectInArray } from '../../../../utils/filters';
import { validationsFilledInputs } from '../../../../utils/quoteShipping';
import { getDataList, paramsLocation } from '../../../../utils/paramsLocations';
import { stepsValidation } from '../../quoteCompletingSteps/validation';

import IconSearch from '../../../../assets/images/search.svg';

import './stepOne.scss';

const StepOne = ({
  title,
  subtitle,
  listFields,
  errorsList,
  setErrorsList,
  isMobileDevice,
  classContent,
  classButton,
  classListFields,
  typeTypography,
  countryCode,
  next,
  colorButton,
  valuesForm,
  changes,
}) => {
  const locations = useSelector((state) => state.globalData.locations || []);
  const [disabledButton, setDisabledButton] = useState(false);

  const [formValues, updateFormValues] = useHandleFields(valuesForm || {});
  const { listLocations, getLocationService } = useLocationService();

  const { locationCode } = useFindLocations(
    countryCode,
    formValues.locationName,
    countryCode === '170' ? locations : listLocations,
    errorsList,
    setErrorsList,
  );

  useEffect(() => {
    listFields.forEach(({ name }) => {
      if (!formValues[name]) {
        updateFormValues(name, changes[name]);
      }
      return formValues[name];
    });
  }, [changes, listFields]);

  useEffect(() => setDisabledButton(!validationsFilledInputs(formValues, errorsList)),
    [formValues, errorsList]);

  const splitString = (location) => location.split('-');

  const showList = (name) => {
    if (countryCode === '484') {
      return getDataList(listLocations, name, countryCode).secondList;
    }
    return getDataList(locations, name, countryCode);
  };

  return (
    <div className={`content-wrapper ${classContent}`}>
      <div className={`${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
        <div className="title">
          <Typography text={title} type={typeTypography} />
          <Typography type="value" text={subtitle} />
        </div>
        <div className={classListFields}>
          { listFields?.map(({
            label,
            name,
            type,
            placeholder,
            isIconRight,
            annotation,
            textError,
          }, index) => (
            <div key={name} className={index === listFields.length - 1 ? 'last-item' : 'items'}>
              <Input
                value={formValues[name]}
                onChange={async (value) => {
                  updateFormValues(name, value);
                  if (findObjectInArray(name, errorsList, 'name')) {
                    stepsValidation(
                      name,
                      textError,
                      value,
                      errorsList,
                      setErrorsList,
                      locations,
                      countryCode,
                    );
                  }
                  if (name === 'locationName' && countryCode === '484') {
                    await paramsLocation(name, value, countryCode, getLocationService);
                    if (value.length > 15) {
                      const foundLocation = getDataList(listLocations, name, countryCode).firstList
                        .find((location) => {
                          const parts = splitString(location);
                          const valueSplit = splitString(value);
                          const locationName = parts[1];
                          return locationName === valueSplit[0];
                        });
                      if (foundLocation) {
                        const [code] = foundLocation.split('-');
                        updateFormValues('locationCode', code);
                      }
                    }
                  }
                }}
                placeholder={placeholder}
                label={label}
                type={type}
                iconRigth={isIconRight && IconSearch}
                annotation={annotation}
                annotationError={findObjectInArray(name, errorsList, 'name')?.error ?? null}
                onBlur={(e) => {
                  stepsValidation(
                    name,
                    textError,
                    e.target.value,
                    errorsList,
                    setErrorsList,
                    countryCode === '170' && locations,
                    countryCode,
                  );
                }}
                dataList={showList(name)}
              />
            </div>
          ))}
        </div>
        <div className="wrapper-button">
          <Button
            text="Continuar"
            type={colorButton}
            onClick={() => next({
              ...formValues,
              firstLocationCode: locationCode,
            })}
            disabled={disabledButton}
            className={classButton}
          />
        </div>
      </div>
    </div>
  );
};

StepOne.propTypes = {
  listFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setErrorsList: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
  next: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  colorButton: PropTypes.string,
  classContent: PropTypes.string,
  classButton: PropTypes.string,
  classListFields: PropTypes.string,
  typeTypography: PropTypes.string,
  changes: PropTypes.shape({}).isRequired,
  valuesForm: PropTypes.shape({}).isRequired,
};

StepOne.defaultProps = {
  title: '',
  subtitle: '',
  colorButton: 'quaternary',
  classContent: '',
  classButton: '',
  typeTypography: 'value',
  classListFields: '',
};
export default StepOne;
