/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { validateUserBillingData, validateUserInfo } from 'constants/general';
import { findCodeLocation } from '../../../../utils/generals';
import { useShippingSteps } from '../../../../hooks/useShippingSteps';
import { updateUserCompletingData } from '../../../../utils/users/completeDataModal';

import Button from '../../../../components/button';
import CardsProfileInfo from '../../../../components/cardsProfileInfo';
import StepsModalCompletigData from '../../../../components/stepsModalCompletingData';

import alert from '../../../../assets/images/profile-alert-icon.svg';
import wallet from '../../../../assets/images/profile-wallet-icon.svg';
import billing from '../../../../assets/images/profile-billing-icon.svg';
import password from '../../../../assets/images/profile-password-icon.svg';
import arrow from '../../../../assets/images/profile-arrow-orange-icon.svg';
import contactInfo from '../../../../assets/images/profile-contact-info-icon.svg';

import Modal from '../../../../components/modal';
import ModalChildren from '../../../../components/modalChildren';

import './dinamicDataUser.scss';

const validateBankData = (user) => {
  const { accountBank } = user;
  return !!(
    accountBank?.beneficiaryName
    && accountBank?.typeId
    && accountBank?.bank
    && accountBank?.accountNumber
    && accountBank?.accountType
  );
};

const DinamicDataUser = ({ user, isMobileDevice }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const countryCode = useSelector((state) => state?.country || null);

  const [showModal, setShowModal] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);
  const [stepsData, setStepsData] = useState({});
  const [errorList, setErrorList] = useState([]);
  const [errorsList, setErrorsList] = useState([]);

  const [missingBankData] = useState(!validateBankData(user));

  const missingUserInfo = !validateUserInfo(user);
  const missingUserBillingData = !validateUserBillingData(user);

  const { isUpdateData, updateShippingSteps } = useShippingSteps(
    countryCode,
    user,
  );

  const getCodeLocation = (nameLocation, locationCode) => {
    if (!locationCode && countryCode === '484') {
      return findCodeLocation(nameLocation);
    }

    return locationCode;
  };

  useEffect(() => {
    updateShippingSteps();
  }, [user]);

  const redirectUserBilling = (isUserBilling) => (isUserBilling
    ? () => navigate('/editar-perfil/datos-de-facturacion')
    : () => navigate('/perfil/datos-de-facturacion'));

  const classCompleteData = isMobileDevice ? 'complete-data-mobile' : 'complete-data';
  const resolutionScreen = isMobileDevice ? 'mobile-mode' : 'desktop-mode';

  const iconInfoAlert = (item) => {
    if (item) {
      return alert;
    }

    return null;
  };
  return (
    <div className="main-dinamic-data">
      <div
        id="content-contact-profile"
        className={resolutionScreen}
      >
        <CardsProfileInfo
          icon={contactInfo}
          iconRight={arrow}
          iconInfo={missingUserInfo ? alert : null}
          showBorderTop
          onClick={
            missingUserInfo
              ? () => navigate('/editar-perfil/informacion-de-contacto')
              : () => navigate('/perfil/informacion-de-contacto')
          }
          onClickInfo={() => navigate('/editar-perfil/informacion-de-contacto')}
          title="Información de contacto"
          subtitle="Asegúrate que estén actualizados"
        />
        <CardsProfileInfo
          icon={billing}
          iconRight={arrow}
          iconInfo={iconInfoAlert(missingUserBillingData)}
          onClick={redirectUserBilling(missingUserBillingData)}
          onClickInfo={() => navigate('/editar-perfil/datos-de-facturacion')}
          title="Datos de facturación"
          subtitle="Recibirás cada mes tu factura electrónica"
        />
        {user.countryCode === '170' && (
          <CardsProfileInfo
            icon={wallet}
            iconRight={arrow}
            iconInfo={iconInfoAlert(missingBankData)}
            onClick={() => navigate('/perfil/datos-bancarios')}
            title="Datos bancarios"
            subtitle="Para recibir el pago de tus recaudos"
          />
        )}
        <CardsProfileInfo
          icon={password}
          iconRight={arrow}
          onClick={() => navigate('/perfil/cambiar-contrasena')}
          title="Contraseña"
          subtitle="Cambiar clave de ingreso"
        />
      </div>
      {(missingUserBillingData || missingUserInfo) && (
        <div
          className={classCompleteData}
        >
          <Button
            type="quaternary"
            text="Completar datos"
            onClick={() => setShowModal(true)}
          />
        </div>
      )}
      {showModal && (
        <StepsModalCompletigData
          step={step}
          propsStepOne={{
            close: () => setStep(0),
            next: (changes) => {
              setStepsData(changes);
              setStep(2);
            },
            errorList,
            setErrorList,
            stepsData,
          }}
          propsStepTwo={{
            close: () => setStep(0),
            next: (changes) => {
              setStepsData({ ...stepsData, ...changes });
              setStep(3);
            },
            back: () => setStep(1),
            stepsData,
            errorsList,
            setErrorsList,
            isMobileDevice,
          }}
          propsStepThree={{
            close: () => setStep(0),
            next: (changes) => {
              setStepsData({ ...stepsData, ...changes });
            },
            back: () => setStep(2),
            errorsList,
            setErrorsList,
            isMobileDevice,
            stepsData,
            loading,
            handleButton: (value) => {
              updateUserCompletingData({
                user,
                countryCode,
                changes: value.formValues,
                formValues: value.changes,
                locationCode: value.locationCode,
              },
              dispatch,
              getCodeLocation,
              setShowResponseModal,
              setModalContent,
              setLoading,
              setStep,
              isUpdateData,
              );
            },
          }}
        />
      )}

      {showResponseModal && (
        <Modal>
          <ModalChildren
            className="modalCompletingData"
            close={() => {
              setShowModal(false);
              setShowResponseModal(false);
              setModalContent(null);
            }}
            headerText={modalContent?.title}
            confirmButtonText={modalContent?.type === 'success' ? '¡Entendido!' : 'Intentar de nuevo'}
            onClickButtonConfirm={() => {
              if (modalContent?.type === 'success') setStep(1);
              setShowResponseModal(false);
              setShowModal(false);
            }}
            bodyText={modalContent?.text}
          />
        </Modal>
      )}
    </div>
  );
};

DinamicDataUser.propTypes = {
  user: PropTypes.shape({
    countryCode: PropTypes.string,
    _id: PropTypes.string,
  }).isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

DinamicDataUser.defaultProps = {};

export default DinamicDataUser;
