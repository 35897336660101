import swal from 'sweetalert';
import { nameLocalStorage } from '../constants';
import { loginStorage, logout } from './auth';
import { operationValidate } from './role';

import Zzz from '../assets/images/zzz.svg';

export const tokenRenewal = (token, loginSystemRouter, logoutFunction) => {
  if (token) {
    let click = false;
    let seconds = 1200;
    const myInterval = setInterval(async () => {
      window.addEventListener('click', () => { click = true; });
      if (seconds === 0) {
        if (localStorage.getItem(nameLocalStorage) || sessionStorage.getItem(nameLocalStorage)) {
          const data = await loginStorage();
          if (click || (operationValidate(data?.data?.user || {}))) {
            loginSystemRouter();
          } else {
            logout();
            logoutFunction();
            swal({
              icon: Zzz,
              className: 'swal-inactive',
              text: 'Hemos detectado inactividad en tu sesión, por tu seguridad ingresa nuevamente.',
            });
          }
        }
        clearInterval(myInterval);
      } else if (seconds > 0) {
        seconds -= 1;
      }
    }, 1000);
  }
};

export default {
  tokenRenewal,
};
