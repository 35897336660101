import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Typography from '../../../components/typography';
import MovementsTab from '../movementsTab';
import ButtonFilterToggle from '../movementsTab/filterToggle';

import './wallet.scss';

const WalletScreen = () => {
  const { user } = useSelector((state) => state.auth || {});
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [listTransactions, setListTransactions] = useState([]);

  const getChange = (data) => {
    setListTransactions([]);
    setPage(1);
    setFilter(data);
  };

  return (
    <div id="wallet" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className={isMobileDevice ? 'headerMobile' : 'header'}>
        {isMobileDevice && (<div />)}
        <Typography text="Movimientos" className="wallet-title" type="subtitle" />
        {isMobileDevice && (<ButtonFilterToggle onChange={getChange} />)}
      </div>
      <div className="container-section-wallet">
        <MovementsTab
          user={user}
          filter={filter}
          page={page}
          setPage={setPage}
          getChange={getChange}
          listTransactions={listTransactions}
          setListTransactions={setListTransactions}
        />
      </div>
    </div>
  );
};

export default WalletScreen;
