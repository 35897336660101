/* eslint-disable quote-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '../../../../components/typography';

import colombiaFlag from '../../../../assets/images/colombia.svg';
import mexicoFlag from '../../../../assets/images/mexico-square.svg';

import './contactInfo.scss';
import { getLocationsService } from '../../../../services/location';

const title = () => (
  <div>
    Mantén
    {' '}
    <span>actualizados todos tus datos</span>
    , para entregarte lo mejor de nuestro servicio.
  </div>
);

const flag = {
  '170': colombiaFlag,
  '484': mexicoFlag,
};

// eslint-disable-next-line arrow-body-style
const DataContactInfo = ({ user, isMobileDevice }) => {
  const [locations, setLocations] = useState('');

  const genLocationService = async () => {
    const getLocation = await getLocationsService({ locationCode: user.locationCode });
    const userLocate = getLocation.find((locate) => locate.locationCode === user.locationCode);
    setLocations(userLocate);
  };

  useEffect(() => {
    genLocationService();
  }, []);
  return (
    <div className={`contacInfo-container ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
      <Typography
        className="main-title"
        text={title()}
      />
      <div className="content-info">
        <div className="content-left-contact">
          <div>
            <Typography className="title-info" text="Nombre comercial en tu guía" />
            <Typography className="subtitle-info" text={user.businessName ?? ''} />
          </div>
          <div>
            <Typography className="title-info" text="Nombre completo" />
            <Typography className="subtitle-info" text={`${user.name ?? ''} ${user.surname ?? ''}`} />
          </div>
          <div className="last">
            <Typography className="title-info" text="Correo electrónico" />
            <Typography className="subtitle-info" text={user.email} />
          </div>
        </div>
        <div className="content-right-contact">
          <div>
            <Typography className="title-info" text="Ciudad" />
            <Typography className="subtitle-info" text={user?.locationName || `${locations?.locationName || ''}-${locations?.departmentOrStateName || ''}`} />
          </div>
          <div>
            <Typography className="title-info" text="Dirección de recogida predeterminada" />
            <Typography className="subtitle-info" text={user.address ?? ''} />
          </div>
          <div className="last">
            <Typography className="title-info" text="Celular" />
            <div className="cellphone-img">
              <img alt="img" src={flag[user.countryCode]} />
              <Typography className="subtitle-info" text={user.cellPhone ?? ''} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
DataContactInfo.propTypes = {
  user: PropTypes.instanceOf.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

DataContactInfo.defaultProps = {

};

export default DataContactInfo;
