import { useState } from 'react';

const useHandleFields = (initialValues) => {
  const [formValues, setFormValues] = useState(initialValues);
  const updateFormValues = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return [formValues, updateFormValues, setFormValues];
};

export default useHandleFields;
