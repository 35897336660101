import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { addThousandPoint, toCurrencyCop } from 'utils/strings';

const AmountDiscount = ({
  realValue,
  valueDiscount,
  user,
  isMobileDevice,
  collectionCommissionWithOutRate,
}) => {
  const valueWithoutDiscount = collectionCommissionWithOutRate && !realValue;
  const isDifferentValue = realValue !== valueDiscount;
  const classReaValue = () => {
    if (isDifferentValue) {
      return 'previous-amount';
    }
    return 'without-discount';
  };

  const realValueCondition = () => {
    if (isDifferentValue) {
      return `(${addThousandPoint(realValue)})`;
    }

    return `${toCurrencyCop(user, realValue)}`;
  };
  return (
    <div className={`${valueWithoutDiscount ? 'container-amount-without-discount' : 'container-amount-discount'} ${isMobileDevice ? 'mobile-mode' : ''}`}>
      {!isMobileDevice ? (
        <>
          <div className={`${valueWithoutDiscount ? 'container-amount-without-discount__without-real-value' : 'container-amount-discount__values'}`}>
            {valueWithoutDiscount ? (
              <span className="amount">{`+${toCurrencyCop(user, collectionCommissionWithOutRate)}`}</span>
            ) : (
              <>
                {isDifferentValue && (<p className="amount-discount">{`+ ${toCurrencyCop(user, valueDiscount)}`}</p>)}
                {' '}
                {realValue && (<p className={classReaValue()}>{realValueCondition()}</p>)}
              </>
            )}
          </div>
          <p className="container-amount-discount__text">
            Comisión de recaudo
          </p>
        </>
      ) : (
        <div className={`${valueWithoutDiscount ? 'container-amount-without-discount__without-values' : 'container-amount-discount__values'}`}>
          { valueWithoutDiscount ? (
            <span className="amount">{`+${toCurrencyCop(user, collectionCommissionWithOutRate)}`}</span>
          ) : (
            <>
              {isDifferentValue && (<span className="amount-discount">{`+ $${addThousandPoint(valueDiscount)}`}</span>)}
              {realValue && (<p className={classReaValue()}>{realValueCondition()}</p>)}
            </>
          )}
          <span className="container-amount-discount__text">
            Comisión de recaudo
          </span>
        </div>
      )}
    </div>
  );
};

AmountDiscount.propTypes = {
  realValue: PropTypes.number.isRequired,
  valueDiscount: PropTypes.number.isRequired,
  user: PropTypes.shape({}).isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  collectionCommissionWithOutRate: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
});

export default connect(mapStateToProps)(AmountDiscount);
