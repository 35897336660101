/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// styles
import './columns.scss';

const Columns = ({
  quanitycolumns, children, customclasses,
}) => (
  <div className={`${quanitycolumns} row ${customclasses !== null ? customclasses : ''}`}>
    {children}
  </div>
);

Columns.propTypes = {
  quanitycolumns: PropTypes.string.isRequired,
  children: PropTypes.any,
  customclasses: PropTypes.string,
};

Columns.defaultProps = {
  children: null,
  customclasses: null,
};

export default Columns;
