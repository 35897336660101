import * as XLSX from 'xlsx';

export const readExcel = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onloadend = async (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = await XLSX.read(data, { type: 'array' });
    const xlsRowObject = await XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[workbook.SheetNames[0]],
    );
    resolve(xlsRowObject);
  };
});

export default readExcel;
