const calculateToNotificationsPrice = (notifications, shippingInfo, TypeNotifications) => {
  const list = [];
  const listCodeNotifications = [];
  const pushList = (element) => {
    list.push(element);
    listCodeNotifications.push(element.code);
  };
  if (shippingInfo.deliverySelected?.negotiation) {
    notifications.forEach((e) => ((e.activeSaas) ? pushList(e) : null));
  } else {
    notifications.forEach((e) => ((e.activeMp) ? pushList(e) : null));
  }
  const sumWithInitial = (list.length === 0) ? 0 : list.map(
    (arr) => arr.fee[TypeNotifications]).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
  );
  return {
    sumWithInitial,
    listCodeNotifications,
  };
};

export default calculateToNotificationsPrice;
