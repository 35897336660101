import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';

import { toCurrencyCop } from 'utils/strings';
import { humanizationOfTimeDuration } from 'utils/generals';
import { finalPrice } from 'utils/prices';

import Typography from 'components/typography';

import '../columns.scss';

const Left = ({
  deliverySelected, shippingInfo, isMobileDevice,
  addShippingToCollectionValue,
}) => {
  const user = useSelector((state) => state?.auth?.user || null);

  const totalRealCost = finalPrice(
    deliverySelected.shippingRealCost,
    shippingInfo.collectionService,
    addShippingToCollectionValue,
    deliverySelected.collectionCommissionWithRate,
    deliverySelected.collectionCommissionWithOutRate,
  );

  const totalSendingValue = toCurrencyCop(user, Math.floor(
    finalPrice(
      deliverySelected.shippingCost,
      shippingInfo.collectionService,
      addShippingToCollectionValue,
      deliverySelected.collectionCommissionWithRate,
      deliverySelected.collectionCommissionWithOutRate,
    )),
  );

  return (
    <div className={`delivery-company-collection-service  ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
      <div className="delivery-company-info">
        <div className="delivery-company-image">
          <img
            src={deliverySelected.deliveryCompanyImgUrl}
            alt={deliverySelected.deliveryCompanyName}
          />
        </div>
        <div className="content-delivery-info-detail">
          <Typography
            text={deliverySelected.deliveryCompanyName}
            type="title"
            className="hebboMedium"
          />
          <div className="delivery-time">
            <Typography
              type="value"
              text="Promedio de entrega:"
              className="size"
            />
            <Typography
              type="value"
              className="shipping-time size"
              text={humanizationOfTimeDuration(deliverySelected.shippingTime)}
            />
          </div>
        </div>
      </div>
      <div className="shipping-value-info">
        <Typography
          text="Valor total de envío:"
          type="value"
        />
        <div className="shipping-value-info__discount">
          <Typography
            text={totalSendingValue}
            type="value"
            className={deliverySelected.shippingRealCost ? 'discount' : null}
          />
          {deliverySelected.shippingRealCost && (
            <Typography
              text={toCurrencyCop(user, totalRealCost)}
              type="value"
              className="real"
            />
          )}
        </div>
      </div>
    </div>
  );
};

Left.propTypes = {
  deliverySelected: PropTypes.shape({
    deliveryCompanyId: PropTypes.string.isRequired,
    collectionCommissionWithRate: PropTypes.number,
    shippingCost: PropTypes.number.isRequired,
    collectionCommissionWithOutRate: PropTypes.number,
    shippingRealCost: PropTypes.number,
    shippingTime: PropTypes.number.isRequired,
    deliveryCompanyName: PropTypes.string.isRequired,
    deliveryCompanyImgUrl: PropTypes.string.isRequired,
  }).isRequired,
  shippingInfo: PropTypes.shape({
    collectionService: PropTypes.bool.isRequired,
    declaredValues: PropTypes.string.isRequired,
  }).isRequired,
  addShippingToCollectionValue: PropTypes.bool,
  isMobileDevice: PropTypes.bool.isRequired,
};

Left.defaultProps = {
  addShippingToCollectionValue: false,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(Left);
