import React from 'react';
import Prototype from 'prop-types';

import {
  HeaderDetail, SenderDetail, RecipientDetail, ProductDetail, DeliveryCompanyDetails,
} from '../sectionsDetails';
import shippingPropTypes from '../propTypesShippingDetails';

import { isDelivered } from '../../../services/sendings';
import { validateComments, validateDestinyLocation, validateOriginLocation } from '../../../constants/shippingDetails';

import Tracking from '../../../components/shippingDetails/traking/tracking';
import Button from '../../../components/button';

import iconFeatherDownload from '../../../assets/images/Icon-feather-download-line.svg';

import './shippingDetailsMobile.scss';

const ShippingDetailsMobile = ({
  shipping,
  back,
  noveltyCodes,
  businessFlags,
  user,
  cancellingShipping,
  shippingCancelFunction,
  cancellationIsValid,
  executingDownload,
  downloadGuideFunction,
}) => (
  <div className="card-container-mobile-shipping-details">
    <HeaderDetail
      guideNumber={shipping.guideNumber}
      code={shipping.code}
      back={back}
    />
    <div className="content-datails-shipment">
      <div className="content-datails">

        {shipping?.error?.clientMessageR && (
          <div className="container-error">
            <div className="container-title">
              <div className="image"><div className="ex" /></div>
              <div className="title"><h3>{shipping?.error?.clientMessage ?? ''}</h3></div>
            </div>
          </div>
        )}

        <SenderDetail
          senderName={`${shipping.sender.name} ${shipping.sender.surname}`}
          senderAddress={shipping.sender.pickupAddress}
          senderLocation={
              validateOriginLocation(
                user.countryCode,
                shipping.locate.originDaneCode,
                shipping.locate.originName,
                shipping.locate.originDepName,
              )
            }
          pickup={shipping.requestPickup}
        />
        <RecipientDetail
          recipientAddress={shipping.receiver.destinationAddress}
          recipientLocation={
            validateDestinyLocation(
              user.countryCode,
              shipping.locate.destinyDaneCode,
              shipping.locate.destinyName,
              shipping.locate.destinyDepName,
            )
          }
          recipientName={`${shipping.receiver.name} ${shipping.receiver.surname}`}
          recipientEmail={shipping.receiver.email}
          recipientCellphone={shipping.receiver.cellPhone}
          recipientComment={shipping.comments}
        />
        <ProductDetail
          description={shipping.description}
          width={shipping.productInformation.width}
          height={shipping.productInformation.height}
          large={shipping.productInformation.large}
          realWeight={shipping.productInformation.realWeight}
          weight={shipping.productInformation.weight}
          declaredValue={shipping.productInformation.declaredValue}
          quantity={shipping.productInformation.quantity}
          user={user}
        />
        {isDelivered(shipping) && (
          <Button
            type="tertiary"
            className="secondary"
            onClick={() => {
              downloadGuideFunction();
            }}
            loading={executingDownload}
          >
            <span className="button-inner-text">Comprobante</span>
            <img src={iconFeatherDownload} alt="D" />
          </Button>
        )}
        {cancellationIsValid && (
          <Button
            type="secondary"
            className="secondary"
            onClick={() => {
              shippingCancelFunction(shipping.code);
            }}
            text="Cancelar envío"
            loading={cancellingShipping}
          />
        )}
        <DeliveryCompanyDetails
          deliveryCompanyName={shipping.deliveryCompanyName}
          curencyPay={shipping.currencyToPay}
          price={shipping.price}
          observations={validateComments(shipping.comments)}
          imageUrl={shipping.deliveryCompanyImage}
          user={user}
        />

        <Tracking
          noveltyCodes={noveltyCodes}
          guideNumber={shipping.guideNumber}
          isMobileDevice
          tracking={shipping.tracking}
          access={null}
          businessFlags={businessFlags}
        />
      </div>
    </div>

  </div>
);

ShippingDetailsMobile.propTypes = {
  shipping: Prototype.shape(shippingPropTypes).isRequired,
  back: Prototype.func.isRequired,
  noveltyCodes: Prototype.shape({}).isRequired,
  businessFlags: Prototype.shape({}).isRequired,
  user: Prototype.shape({
    countryCode: Prototype.string,
  }).isRequired,
  cancellingShipping: Prototype.bool.isRequired,
  shippingCancelFunction: Prototype.func.isRequired,
  cancellationIsValid: Prototype.bool.isRequired,
  downloadGuideFunction: Prototype.func.isRequired,
  executingDownload: Prototype.bool.isRequired,
};

export default ShippingDetailsMobile;
