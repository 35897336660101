import React from 'react';

import PointsModule from './pointsModule';
import ContainerSection from '../../components/containerSection';

const PointsModuleScreen = () => (
  <ContainerSection>
    <PointsModule />
  </ContainerSection>
);

export default PointsModuleScreen;
