import { validationValue, insertOrRemoveErrorFromList } from '../../../utils/strings';

export const validationSteps = ({
  errorList, setErrorList, newPassword, confirmPassword,
}) => {
  const temporalErrorList = errorList.slice();

  if (newPassword && !confirmPassword) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'newPassword',
      'Ingresa una contraseña válida.',
      validationValue(newPassword, 'password').correct ? 'remove' : 'insert',
    );
  }

  if (newPassword && confirmPassword) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'confirmPassword',
      'Las contraseñas deben coincidir.',
      (confirmPassword === newPassword) ? 'remove' : 'insert',
    );
  }

  setErrorList(temporalErrorList);
};

export default { validationSteps };
