/* eslint-disable */
const dividerName = (string) => {
  const largeName = string.split(' ');

  const name = largeName[0];

  const surname = largeName.slice(1).join(' ');

  return { name, surname };
};
const payloadData = (changes, formValues, user) => {

  const dataName = formValues.name && dividerName(formValues.name);
  if (user?.countryCode === '170') {
    const colInvoicingData = {
      emailForBilling: formValues.emailForBilling,
      countryCode: user.countryCode,
      address: formValues.address,
      locationCode: formValues.locationCode,
      locationName: formValues.locationName,
    };

    const colUserData = {
      documentType: formValues.documentType,
      documentNumber: formValues.documentType === 'NIT' ? String(formValues.identificationNumber + formValues?.codeVerification) : String(formValues.identificationNumber),
      personType: formValues.personType,
      address: changes.address,
      cellPhone: formValues.cellPhone,
      businessName: changes.businessName,
      locationCode: changes.firstLocationCode,
      ...(formValues.personType === 'Persona Natural') && {
        name: dataName.name,
        surname: dataName.surname,
      },
      locationName: changes.locationName,
      ...(formValues.personType === 'Persona Jurídica') && {
        tradeName: formValues.tradeName
      },
    };

    return {
      invoicingData: colInvoicingData,
      userData: colUserData,
    };
  }

  const mxInvoicingData = {
    RFC: formValues.RFC,
    taxRegime: formValues.taxRegime,
    postalCode: formValues.postalCode,
    methodOfPayment: formValues.methodOfPayment,
    CFDI: formValues.CFDI,
    emailForBilling: formValues.emailForBilling,
    taxResidence: formValues.taxResidence,
    countryCode: user.countryCode,
    locationName: changes.locationName,
  };

  const mxUserData = {
    businessName: changes.businessName,
    locationCode: changes.firstLocationCode || changes.locationCode,
    locationName: changes.locationName,
    address: changes.address,
    tradeName: formValues.tradeName,
    personType: formValues.personType,
  };

  return {
    invoicingData: mxInvoicingData,
    userData: mxUserData,
  };
};

export default payloadData;
