import axios from 'axios';

import {
  noveltiesMicroserviceUrl,
  customerKeyNoveltiesMicroservice,
} from '../constants';

/**
  * obtener los envíos con novedad
  * @param {String} data - datos para anexar a la query, el objeto puede contenter:
  * guideNumber, mpCode, noveltyId, date, state, page, numberItemsPerPage,
  * userId, userEmail, userDocumentNumber, userCellphone,
*/
export const getShippingsWithNoveltiesService = (
  data,
) => new Promise((resolve, reject) => {
  const endPoint = '/getnovelties';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, {
    ...data.guideNumber ? { guide: data.guideNumber } : {},
    ...data.mpCode ? { mpCode: data.mpCode } : {},
    ...data.noveltyId ? { noveltyId: data.noveltyId } : {},
    ...data.date ? { date: data.date } : {},
    ...data.state ? { state: data.state } : {},
    currentPage: data.page || 1,
    ...data.numberItemsPerPage ? { size: data.numberItemsPerPage } : {},
    ...data.userId ? { userId: data.userId } : {},
    ...data.userEmail ? { usemailUsererId: data.userEmail } : {},
    ...data.userDocumentNumber ? { documentNumberUser: data.userDocumentNumber } : {},
    ...data.userCellphone ? { cellphone: data.userCellphone } : {},
    ...data.deliveries ? { deliveries: data.deliveries } : {},
    ...data.noveltyCode ? { noveltyCode: data.noveltyCode } : {},
  })
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

/**
  * obtener los códigos de novedades
  * @param {String} data - datos para anexar a la query, el objeto puede contenter:
  * module
*/
export const getNoveltyCodesService = (data) => new Promise((resolve, reject) => {
  const endPoint = '/noveltycodes';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, { ...data })
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

export default {
  getShippingsWithNoveltiesService,
  getNoveltyCodesService,
};
