import { getUserConfigs, updatePrintFormat } from '../../../services/user';

const getGuidesFormatUser = async () => {
  try {
    const guidesFormat = await getUserConfigs(['printFormat']);
    return guidesFormat?.guidesSettings || [];
  } catch (error) {
    return [];
  }
};

export const updateOrCreatePrintFormatService = async (newPrintFormat) => {
  const settings = {
    companySetting: [
      newPrintFormat,
    ],
  };
  return updatePrintFormat(settings);
};

export default getGuidesFormatUser;
