/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import './radioButton.scss';

const RadioButton = ({
  name,
  personalizedId,
  className,
  text,
  checked,
  value,
  onChange,
  annotation,
  annotationError,
  children,
  disabled,
  iconLeft,
}) => (
  <>
    <label
      id={personalizedId ? personalizedId : ''}
      className={`radio-button ${className ? className : ''}`}
    >
      <input
        type="radio"
        name={name}
        checked={checked}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      />
      {iconLeft && (
        <img
          src={iconLeft}
          alt=""
        />
      )}
      <span
        className={checked ? 'checked' : ''}
      >
        {text}
        {children}
      </span>
    </label>
    <span
      className={`annotation__radio-button-general ${annotation ? 'visible' : ''}`}
    >
      {annotation && `*${annotation}`}
    </span>
    <span
      className={`annotation__radio-button-general error ${(annotationError) ? 'visible' : ''}`}
    >
      {annotationError && `*${annotationError}`}
    </span>
  </>
);

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  personalizedId: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  annotation: PropTypes.string,
  annotationError: PropTypes.string,
  children: PropTypes.element,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.string,
};

RadioButton.defaultProps = {
  personalizedId: null,
  className: null,
  annotation: null,
  annotationError: null,
  children: null,
  text: null,
  disabled: false,
  iconLeft: null,
};

export default RadioButton;
