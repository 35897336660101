/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import React, { useEffect, useState } from 'react';

import { toCurrencyCop } from '../../../../utils/strings';
import listBank from '../../../../services/elp';
import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import Input from '../../../../components/input';
import Select from '../../../../components/select';
import Button from '../../../../components/button';
import Typography from '../../../../components/typography';

import documentTypeList from '../commons/documents';
import { pseValidate } from './validate';
import pseIcon from '../../../../assets/images/pse_icon.png';

import './methodPayments.scss';
import { findObjectInArray } from '../../../../utils/filters';

const personTypeList = [
  { label: 'Persona Natural', value: '0' },
  { label: 'Persona Juridica', value: '1' },
];

const PSE = ({
  isMobileDevice, balanceToRecharge, user,
  paymentFunction, setErrorsListBalance,
}) => {
  const [date] = useState((new Date()));
  const [errorsList, setErrorsList] = useState([]);

  const [showData, setShowData] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [bankSelected, setBankSelected] = useState(null);
  const [personType, setPersonType] = useState(null);
  const [pseName, setPseName] = useState(user.name);
  const [pseSurname, setPseSurname] = useState(user.surname);
  const [documentType, setDocumentType] = useState(null);
  const [documentNumber, setDocumentNumber] = useState(user.documentNumber);
  const [email, setEmail] = useState(user.email);
  const [city, setCity] = useState(null);
  const [cellPhone] = useState(user.cellPhone);
  const [address] = useState(user.address);
  const [countryCode] = useState('COL');

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  useEffect(async () => {
    const newBankList = await listBank();
    setBankList(newBankList);
  }, []);

  const continueWithTheProcess = () => {
    setShowData(true);
  };

  return (
    <div id="pse-container">
      <Typography
        text="Resumen de tu transacción"
        type="title"
        className="summary"
      />
      <Typography
        text="Valor a pagar"
        type="value"
        className="no-value"
      />
      <Typography
        text={toCurrencyCop(user, balanceToRecharge)}
        type="title"
        className="balance-to-recharge"
      />
      <Typography
        text="Detalles de pago"
        type="value"
        className="no-value"
      />
      <br />
      <Typography
        text="Recarga de saldo en mipaquete.com"
        type="title"
        className="no-value space"
      />
      <div className={`pse-info ${isMobileDevice ? 'mobile-mode' : ''}`}>
        <div className="date-and-ref">
          <Typography
            text="Fecha límite de pago:"
            type="value"
            className="no-value"
          />
          <Typography
            text={date.toLocaleDateString('es-CO')}
            type="value"
            className="no-value"
          />
        </div>
        <div className="date-and-ref">
          <Typography
            text="Referencia de pago:"
            type="value"
            className="no-value"
          />
          <Typography
            text={Date.parse(date)}
            type="value"
            className="no-value"
          />
        </div>
        <div className="pse-button" onClick={() => continueWithTheProcess()}>
          <img src={pseIcon} alt="pse" />
          <div className="left-column">
            <Typography text="Débito bancario PSE" type="field" className="method" />
            <div className="arrow" />
          </div>
        </div>
      </div>
      {showData && (
        <div className="pse-data">
          {bankList?.length > 0 && (
            <Select
              options={bankList}
              value={bankSelected}
              onChange={setBankSelected}
              label="Banco"
              className="bank-select first-select"
              annotationError={
                findObjectInArray('bankList', errorsList, 'name')
                  ? findObjectInArray('bankList', errorsList, 'name').error
                  : null
              }
            />
          )}
          <Select
            options={personTypeList}
            value={personType}
            onChange={setPersonType}
            label="Tipo de persona"
            className="bank-select second-select"
            annotationError={
              findObjectInArray('personType', errorsList, 'name')
                ? findObjectInArray('personType', errorsList, 'name').error
                : null
            }
          />
          <Input
            onChange={setPseName}
            value={pseName}
            placeholder="Nombre"
            type="text"
            label="Nombre"
            annotationError={
              findObjectInArray('pseName', errorsList, 'name')
                ? findObjectInArray('pseName', errorsList, 'name').error
                : null
            }
          />
          <Input
            onChange={setPseSurname}
            value={pseSurname}
            placeholder="Apellido"
            type="text"
            label="Apellido"
            annotationError={
              findObjectInArray('pseSurname', errorsList, 'name')
                ? findObjectInArray('pseSurname', errorsList, 'name').error
                : null
            }
          />
          <Select
            options={documentTypeList}
            value={documentType}
            onChange={setDocumentType}
            label="Tipo de identificación"
            className="bank-select third-select"
            annotationError={
              findObjectInArray('documentType', errorsList, 'name')
                ? findObjectInArray('documentType', errorsList, 'name').error
                : null
            }
          />
          <Input
            onChange={setDocumentNumber}
            value={documentNumber}
            placeholder="1033231231"
            type="number-text"
            label="Número de documento"
            annotationError={
              findObjectInArray('documentNumber', errorsList, 'name')
                ? findObjectInArray('documentNumber', errorsList, 'name').error
                : null
            }
          />
          <Input
            onChange={setEmail}
            value={email}
            placeholder="test@test.com"
            label="Correo electrónico"
            annotationError={
              findObjectInArray('email', errorsList, 'name')
                ? findObjectInArray('email', errorsList, 'name').error
                : null
            }
          />
          <Input
            onChange={setCity}
            value={city}
            placeholder="Medellín"
            label="Ciudad"
            annotationError={
              findObjectInArray('city', errorsList, 'name')
                ? findObjectInArray('city', errorsList, 'name').error
                : null
            }
          />
          <Button
            type={isCountryValid ? 'primary-mx' : 'primary'}
            text="Pagar"
            onClick={() => {
              if (pseValidate({
                email,
                documentNumber,
                pseName,
                pseSurname,
                city,
                documentType,
                bankSelected,
                bankList,
                personType,
                personTypeList,
                balanceToRecharge,
                errorsList,
                setErrorsList,
                setErrorsListBalance,
              })) {
                paymentFunction({
                  amount: balanceToRecharge,
                  email,
                  documentNumber,
                  name: pseName,
                  surname: pseSurname,
                  cellPhone,
                  address,
                  countryCode,
                  city,
                  documentType,
                  bank: bankSelected,
                  personType: Number.parseInt(personType, 10),
                  user: user._id,
                }, 'PSE');
              }
            }}
            className="less-width"
          />
        </div>
      )}
    </div>
  );
};

PSE.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  balanceToRecharge: PropTypes.number.isRequired,
  paymentFunction: PropTypes.func.isRequired,
  setErrorsListBalance: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(PSE);
