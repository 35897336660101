import { invoicingDataService, invoicingDataUpdateService, updateUserService } from '../../../services/user';

const userUpdateService = async (
  payload,
  userId,
  setShowModal,
  setModalContent,
  isUserUpdate,
  idInvoicing,
  setLoading,
  next,
) => {
  let invoicingResult;
  let userUpdateResponse;

  setLoading(true);

  if (isUserUpdate && idInvoicing) {
    [invoicingResult, userUpdateResponse] = await Promise.allSettled([
      invoicingDataUpdateService(payload.invoicingData),
      updateUserService(userId, payload.userData),
    ]);
  } else {
    [invoicingResult, userUpdateResponse] = await Promise.allSettled([
      invoicingDataService(payload.invoicingData),
      updateUserService(userId, payload.userData),
    ]);
  }
  if (invoicingResult.status === 'fulfilled' && userUpdateResponse.status === 'fulfilled') {
    setLoading(false);
    next('continue');
  } else {
    setLoading(false);
    setShowModal(true);
    setModalContent({
      title: 'Ups!',
      text: 'Ha habido un problema al intentar actualizar los datos.',
      type: 'error',
    });
  }
};

export default userUpdateService;
