/* eslint-disable import/prefer-default-export,prefer-const,max-len */
const getUserMaxDeclaredValue = (data) => {
  const messageValues = data?.deliveryCompaniesMessage.map((company) => company.messageDeclaredValue);
  const packageValues = data?.deliveryCompaniesPackage.map((company) => company.packageDeclaredValue);

  const allValues = [...messageValues, ...packageValues];
  return Math.max(...allValues);
};
export const parametersDeliveryCompanies = (listDeliveryCompanies, userConfig) => {
  let maxDeclaredValue = 0;
  let largeMount = 0;
  if (userConfig?.length) {
    maxDeclaredValue = userConfig.map((item) => getUserMaxDeclaredValue(item));
    return maxDeclaredValue[0];
  }

  listDeliveryCompanies.forEach((company) => {
    largeMount = Math.max(largeMount, company.maxDeclaredValueMessage || 0,
      company.maxDeclaredValuePackage || 0);
    if (largeMount > maxDeclaredValue) {
      maxDeclaredValue = largeMount;
    }
  });

  return maxDeclaredValue;
};
