import companiesConfig from '../../../config/deliveryCompanies.json';

const formatType = (company, printFormat) => {
  const type = companiesConfig.printFormatTypes[company];

  if (type === 'number') return Number.parseInt(printFormat, 10);
  return printFormat;
};

export default formatType;
