import React from 'react';
import { useSelector } from 'react-redux';

import ShippingHistoryAndQuotes from './shippingHistoryAndQuotes';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './shippingHistoryAndQuotesScreen.scss';

const ShippingHistoryAndQuotesScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <ShippingHistoryAndQuotes />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <ShippingHistoryAndQuotes />
      </ContainerSectionDesktop>
    )
  );
};

export default ShippingHistoryAndQuotesScreen;
