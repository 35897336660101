import React from 'react';
import PropTypes from 'prop-types';

import { humanizationOfTimeDuration } from '../utils/generals';

const DeliveryTime = ({ deliveryTime }) => (
  <span className="delivery-time">
    {'Promedio de entrega: '}
    {deliveryTime < 1440 ? (
      <b className="blue">{humanizationOfTimeDuration(deliveryTime)}</b>
    ) : (
      <b className="days-delivered">
        {humanizationOfTimeDuration(deliveryTime)}
      </b>
    )}
  </span>
);

DeliveryTime.propTypes = {
  deliveryTime: PropTypes.number.isRequired,
};

export default DeliveryTime;
