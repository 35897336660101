import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Calendar from 'react-calendar';
import Typography from '../typography';
import Columns from '../columns';
import Button from '../button';
import Modal from '../modal';

import { formatDay } from '../../utils/strings';
import { useValidationCountry, useFetchIpData } from '../../utils/validationCountry';

import './calendar.scss';

const CalendarComponent = ({
  setShowModal,
  datesRange,
  onChange,
}) => {
  const [initDate, setInitDate] = useState('');
  const [finishDate, setFinishDate] = useState('');

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  useEffect(() => {
    if (datesRange) {
      const reg = new RegExp(/de/g);
      if (datesRange[0]) setInitDate(formatDay(datesRange[0]).replace(reg, ' '));
      if (datesRange[1]) setFinishDate(formatDay(datesRange[1]).replace(reg, ' '));
    }
  });
  const locale = 'es-MX';

  const closeAndRemove = () => {
    onChange(null);
    setShowModal();
  };

  return (
    <div>
      <Modal closeBackground={closeAndRemove}>
        <div className="calendarBox">
          <div className="header__modal-calendar-step paddingTop">
            <div className="" />
            <div className="center">
              <Typography text="Fecha" type="superTitle" className="fontSize18" />
            </div>
            <div className="rigth" onClick={closeAndRemove} />
          </div>
          <Calendar
            onChange={onChange}
            allowPartialRange
            locale={locale}
            formatShortWeekday={(_locale, date) => ['D', 'L', 'M', 'W', 'J', 'V', 'S'][date.getDay()]}
            minDetail="year"
            selectRange
          />
          {datesRange && (
            <div>
              <div className="selectionBox">
                <Columns quanitycolumns="two">
                  <div className="div dates">
                    <Typography text="Desde" type="field-dark-gray" className="color-darker" />
                    <Typography
                      text={initDate || '-'}
                      type="field-gray"
                      className="color-darker"
                    />
                  </div>
                  <div className="div dates">
                    <Typography text="Hasta" type="field-dark-gray" className="color-darker" />
                    <Typography
                      text={finishDate || '-'}
                      type="field-gray"
                      className="color-darker"
                    />
                  </div>
                </Columns>
              </div>
              <div className="confirmSelection">
                <Button
                  type={isCountryValid ? 'primary-mx' : 'primary'}
                  text="Selecciona"
                  onClick={() => {
                    if (datesRange) setShowModal();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

CalendarComponent.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  datesRange: PropTypes.arrayOf(Date),
  onChange: PropTypes.func.isRequired,
};

CalendarComponent.defaultProps = {
  datesRange: null,
};

export default CalendarComponent;
