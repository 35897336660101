import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BankData from './bankData';
import FirstBankData from '../../components/bankData';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './bankDataScreen.scss';

const BankDataScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();

  return (
    isMobileDevice ? (
      <ContainerSectionMobile className="profile-information">
        {!user?.accountBank && (
          <FirstBankData
            isMobileDevice={isMobileDevice}
            countryCode={user.countryCode}
            userIdToUpdate={user._id}
            next={() => 'nothing'}
            back={() => navigate('/perfil')}
          />
        )}
        {user?.accountBank && (<BankData />)}
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        {!user?.accountBank && (
          <FirstBankData
            isMobileDevice={isMobileDevice}
            countryCode={user.countryCode}
            userIdToUpdate={user._id}
            next={() => 'nothing'}
            back={() => navigate('/perfil')}
            completeScreen
          />
        )}
        {user?.accountBank && (<BankData />)}
      </ContainerSectionDesktop>
    )
  );
};

export default BankDataScreen;
