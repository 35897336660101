/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import observations from './observations';

import Typography from '../typography';
import Modal from '../modal';

import closeImg from '../../assets/images/icon-close-color-primary.svg';

import './deliveryCompaniesObservations.scss';

const DeliveryCompaniesObservations = ({
  isMobileDevice, showButtons, close, next,
  deliveryCompanyImgUrl, deliveryCompanyName, deliveryCompanyId,
}) => {
  const convertName = (nombre) => {
    if (nombre.length === 0) {
      return nombre;
    }
    return nombre.charAt(0).toUpperCase() + nombre.slice(1).toLowerCase();
  };

  const deliveryTitle = (name) => (
    <p className="deliveryTitle">
      Observaciones de
      {' '}
      <span>{name}</span>
    </p>
  );

  const handleModalClose = (event) => {
    event.stopPropagation();
    if (close) {
      close();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleModalClose(event);
    }
  };

  return (
    <Modal
      className={`quote-shipping__modal-annotations ${
        isMobileDevice ? 'mobile-mode' : 'desktop-mode'
      }`}
      closeBackground={handleModalClose}
    >
      <>
        <div className="header__modal-annotations">
          <div className="close-container">
            <img src={closeImg} alt="close" className="close-button" onClick={handleModalClose} onKeyDown={handleKeyPress} />
          </div>
          <img src={deliveryCompanyImgUrl} alt="delivery" />
          <Typography
            text={deliveryTitle(convertName(deliveryCompanyName))}
            type="title"
            className="title__header__modal-annotations"
          />
        </div>
        <div className="body__modal-annotations">
          {observations[deliveryCompanyId]}
        </div>
      </>
    </Modal>
  );
};

DeliveryCompaniesObservations.propTypes = {
  deliveryCompanyImgUrl: PropTypes.string.isRequired,
  deliveryCompanyName: PropTypes.string.isRequired,
  deliveryCompanyId: PropTypes.string.isRequired,
  next: PropTypes.func,
  close: PropTypes.func,
  showButtons: PropTypes.bool,
  isMobileDevice: PropTypes.bool.isRequired,
};

DeliveryCompaniesObservations.defaultProps = {
  next: () => null,
  close: () => null,
  showButtons: true,
};

export default (DeliveryCompaniesObservations);
