/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import { connect } from 'react-redux';
import { compose } from 'redux';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getTransactionService } from '../../../services/cash';
import Loader from '../../../components/loaderList';
import FilterToggle from './filterToggle';
import Typography from '../../../components/typography';
import exportAsExcelFile from '../../../utils/exportxls';
import ImageEmpyState from '../../../components/imagesEmptyState';
import MovementsItem from './movementsItem';
import NoMovements from '../../../assets/images/tu_billetera.png';
import Button from '../../../components/button';

import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import './movementsTab.scss';

const MovementsTab = ({
  isMobileDevice, filter, page, setPage, getChange, listTransactions,
  setListTransactions,
}) => {
  const [loading, setLoading] = useState(true);
  const [lastTransactionId, setLastTransactionId] = useState({});
  const [showMoreTransactions, setshowMoreTransactions] = useState(true);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const getTransactions = async () => {
    const Transactions = await getTransactionService(filter, lastTransactionId, 7);
    const lastTransaction = Transactions[Transactions.length - 1];
    if (lastTransaction) {
      setLastTransactionId({ lastTransactionId: lastTransaction._id });
    } else {
      setLastTransactionId({});
      setshowMoreTransactions(false);
    }

    setPage(page + 1);
    setListTransactions(listTransactions.concat(Transactions));
  };

  const trasformaTransactionType = (typeTransaction) => {
    const transactionsTypes = {
      expense: 'Gasto',
      income: 'Ingreso',
      credit: 'credito',
      collection: 'Recaudo',
      points: 'Puntos',
    };
    return transactionsTypes[typeTransaction];
  };

  const generateXls = async () => {
    const transactionsList = [];
    let lastTransactionExistence = true;
    let lastTransaction = {};
    while (lastTransactionExistence) {
      // eslint-disable-next-line no-await-in-loop
      const Transactions = await getTransactionService(filter, lastTransaction, 100);
      lastTransaction = { lastTransactionId: Transactions[Transactions.length - 1]?._id };
      lastTransactionExistence = !!lastTransaction.lastTransactionId;
      Transactions.map((transaction) => {
        const trasnformTransaction = {
          'Fecha de transacción': transaction.createdAt,
          'Descripción de la transacción': transaction.description,
          'Tipo de transacción': trasformaTransactionType(transaction.transactionType),
          'Valor de la transacción': transaction.paymentPrice,
          'Divisa de transacción': transaction.currencyToPay || 'COP',
        };
        return transactionsList.push(trasnformTransaction);
      });
    }
    exportAsExcelFile(transactionsList, `Transacciones mipaquete ${new Date()}`);
  };

  useEffect(() => {
    getTransactions();
  }, [filter]);

  useEffect(async () => {
    if (loading) {
      await getTransactions();
      setLoading(false);
    }
  }, [loading]);

  if (loading) {
    return (<div id="movement-list"><Loader /></div>);
  }

  return (
    <>
      <div id="movement-list" className={isMobileDevice ? 'isMobileDeviceTabs' : 'noIsMobileDeviceTabs'}>
        {listTransactions.length > 0 && listTransactions.map((item, index) => (
          <MovementsItem
            key={`${index + 1}`}
            date={item.createdAt}
            description={item.description}
            typeTransaction={item.transactionType}
            value={item.paymentPrice}
            notifications={item.notificationsFee}
            currencyToPay={item.currencyToPay || 'COP'}
          />
        ))}

        {listTransactions.length <= 0 && (
          <ImageEmpyState
            uri={NoMovements}
            title="Aún no has hecho movimientos"
            description="Aquí quedarán registrados los ingresos y egresos. También podrás descargar el registro de estos."
          />
        )}

        {showMoreTransactions && (
          <button onClick={getTransactions} aria-label="button">
            <Typography
              text="Ver más"
              type="superTitle-italic"
              className="loadData"
            />
          </button>
        )}
      </div>

      <div id="secctionFilter" className={isMobileDevice ? 'isMobileDevice' : 'noIsMobileDevice'}>
        <Button
          text="Descargar movimientos"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          onClick={generateXls}
          className={isCountryValid ? 'downloadFile-mx' : 'downloadFile'}
        />
        {!isMobileDevice && (<FilterToggle onChange={getChange} />)}
      </div>
    </>
  );
};

MovementsItem.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  typeTransaction: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  notifications: PropTypes.shape({}).isRequired,
};

MovementsTab.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  filter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.string.isRequired,
  setPage: PropTypes.func.isRequired,
  getChange: PropTypes.func.isRequired,
  listTransactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setListTransactions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(MovementsTab);
