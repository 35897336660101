/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Typography from '../../../../components/typography';
import Button from '../../../../components/button';

import ShipmentsList from '../shipmentList';
import HeaderTicketDetail from './header';
import ModalFilters from '../modals/filters';
import { dataState } from '../data';

import IconArrowBack from '../../../../assets/images/icon-arrow-back-color-primary.svg';
import IconFilter from '../../../../assets/images/filter_list.svg';

import { removeFilter, dataMethodFiltered, initialFalseCheck } from '../common';
import collectionExcel from '../common/ExcelGenerator';
import { getShipmentPaidTotalService } from '../../../../services/billing';
import { getShippingForList } from '../services/getShipping';

import './ticketDetail.scss';
import { detailTypes } from '../common/enums';
import {
  statesSelected,
  deliveryCompaniesSelectedId,
  deliveryCompaniesSelectedName,
  statesSelectedName,
} from '../getTickets/checkedData';
import Swal from '../modals/errors';
import ActivFilters from '../../../../components/activFilters';

const TicketDetail = ({ ticket, deliveryCompanies, handleTicketDetail }) => {
  const [modalFilter, setModalFilter] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [dataDeliveryCompany, setDataDeliveryCompany] = useState(deliveryCompanies);
  const [dataStatesReport, setDataStateReport] = useState(dataState);
  const [dataShipments, setDataShipments] = useState(null);
  const [filterDataDelivery, setFilterDataDelivery] = useState(dataDeliveryCompany);
  const [filterDataStates, setFilterDataStates] = useState(dataStatesReport);
  const [totalDataShipments, setTotalDataShipments] = useState(null);
  const [activeFilters, setActiveFilters] = useState({});
  const [filters, setFilters] = useState({ ticket: ticket?._id });
  const [keyNames, setKeyNames] = useState({});

  const [completeFilter, setCompleteFilter] = useState(null);

  const getFilterDetail = async () => {
    const queryFilter = {
      ...deliveryCompaniesSelectedId(dataDeliveryCompany).length > 0 && {
        deliveryCompaniesId: deliveryCompaniesSelectedId(dataDeliveryCompany),
      },
      ...statesSelected(dataStatesReport).length > 0
      && { status: statesSelected(dataStatesReport) },
      ticket: ticket?._id,
    };

    let companiesName = {};
    const daliveryCompanyNameList = deliveryCompaniesSelectedName(dataDeliveryCompany);
    if (daliveryCompanyNameList) {
      daliveryCompanyNameList.forEach((company) => {
        companiesName = {
          ...companiesName,
          [company]: company,
        };
      });
    }

    const status = statesSelectedName(dataStatesReport);
    let statusName = {};
    if (status) {
      status.forEach((name) => {
        statusName = {
          ...statusName,
          [name]: name,
        };
      });
    }
    const tempKeyNames = daliveryCompanyNameList.map((company) => ({ value: company }))
      .concat(status.map((state) => ({ value: state })));

    const tempFilters = {
      ...companiesName,
      ...statusName,
    };

    setKeyNames(tempKeyNames);
    setActiveFilters(tempFilters);
    setFilters(queryFilter);
    setModalFilter(false);
  };

  useEffect(async () => {
    const data = await getShippingForList({
      query: filters,
      showError: () => Swal({}),
    });
    if (data) setDataShipments(data);
  }, [completeFilter, filters]);

  useEffect(() => {
    if (!modalFilter) {
      getFilterDetail();
    }
  }, [dataDeliveryCompany, dataStatesReport]);

  useEffect(() => {
    if (Object.keys(activeFilters)?.length <= 0) {
      setDataShipments(null);
      setDataDeliveryCompany(deliveryCompanies);
      setDataStateReport(dataState);
    }
  }, [activeFilters]);

  useEffect(async () => {
    const data = await getShipmentPaidTotalService({ ticket: ticket?._id });
    if (data) setTotalDataShipments(data);
  }, [ticket]);

  useEffect(() => {
    initialFalseCheck(deliveryCompanies, setDataDeliveryCompany);
    initialFalseCheck(dataState, setDataStateReport);
    dataMethodFiltered(filterDataDelivery, setFilterDataDelivery, '_id');
    dataMethodFiltered(filterDataStates, setFilterDataStates, 'state');
  }, []);

  const removeAllFilters = () => {
    removeFilter(dataDeliveryCompany, setDataDeliveryCompany);
    removeFilter(dataStatesReport, setDataStateReport);
  };

  const downloadShipmentsByFilter = () => {
    const filterState = statesSelected(dataStatesReport);
    const filterDeliveryCompany = deliveryCompaniesSelectedId(dataDeliveryCompany);

    let validateDeliveryCompany = dataShipments.filter((sending) => (
      filterDeliveryCompany.find((company) => (
        company === sending.deliveryCompany))));

    if (filterDeliveryCompany?.length <= 0) validateDeliveryCompany = dataShipments;

    let filteredShipments = validateDeliveryCompany.filter((item) => (
      filterState.find((state) => (state === item.state))));

    if (filterState?.length <= 0) filteredShipments = validateDeliveryCompany;

    collectionExcel(filteredShipments);
  };

  const dateCorrection = (courtDate) => {
    if (courtDate) return new Date(courtDate).toLocaleDateString('en-GB');
    return '';
  };

  const removeOneFilter = (newFilters) => {
    const keyFilters = Object.keys(newFilters);
    const newCheckedCompanies = dataDeliveryCompany.map((company) => ({
      ...company,
      checked: !!keyFilters.find((key) => key.toUpperCase() === company.name.toUpperCase()),
    }));

    const newStates = dataStatesReport.map((state) => ({
      ...state,
      checked: !!keyFilters.find((key) => key.toUpperCase() === state.state.toUpperCase()),
    }));

    setActiveFilters(newFilters);
    setDataDeliveryCompany(newCheckedCompanies);
    setDataStateReport(newStates);
  };

  const linkCollection = 'https://www.mipaquete.com/soluciones-ecommerce/envios-pago-contraentrega#preguntas-frecuentes';

  return (
    <div id="ticket-details">
      <div className="top">
        <div className="left">
          <img src={IconArrowBack} alt="icon" className="arrow-back" onClick={() => handleTicketDetail(null, detailTypes.none)} />
          <Typography type="superTitle-italic" text={`Recaudo ${dateCorrection(ticket?.courtDate)}`} className="title" />
        </div>
      </div>
      <div className="line" />
      <div className="message-collection-desktop">
        <p>
          Conoce
          {' '}
          <span onClick={() => window.open(linkCollection, 'blank', 'noopener')}>aquí</span>
          {' '}
          las fechas de transferencia de recaudos y sus costos.

        </p>
      </div>
      <div className="content-header">
        {totalDataShipments && (
          <HeaderTicketDetail
            totalData={totalDataShipments}
          />
        )}
        <div className="button-block">
          <Button
            iconText={(
              <div className="filter-content__title">
                <p className="text">Filtrar</p>
                <img src={IconFilter} alt="icon-filter" />
              </div>
            )}
            type="tertiary"
            onClick={() => setModalFilter(true)}
            className="filter-content__button"
          />
        </div>
      </div>
      <div className="shipment-list-wrapper">
        <ActivFilters
          filters={activeFilters}
          handleChangeFilter={(newFilters) => removeOneFilter(newFilters)}
          show={Object.keys(activeFilters).length > 0}
          keyNames={keyNames}
        />
        <ShipmentsList
          shipmentsData={dataShipments}
          deliveryCompanies={deliveryCompanies}
          downloadData={() => setDownloadModal(true)}
          handleTicketDetailShipments={handleTicketDetail}
        />
      </div>
      {(modalFilter || downloadModal) && (
        <ModalFilters
          dataDeliveryCompany={dataDeliveryCompany}
          dataStatesReport={dataStatesReport}
          modalFilter={modalFilter}
          hideDatePicker
          removeAllFilters={() => removeAllFilters()}
          setDataDeliveryCompany={setDataDeliveryCompany}
          setDataStateReport={setDataStateReport}
          setDownloadModal={setDownloadModal}
          setModalFilter={setModalFilter}
          setFilter={setCompleteFilter}
          searchByfilters={() => (downloadModal ? downloadShipmentsByFilter() : getFilterDetail())}
        />
      )}
    </div>
  );
};

TicketDetail.propTypes = {
  ticket: PropTypes.shape({
    _id: PropTypes.string,
    courtDate: PropTypes.string,
    guidesQuantity: PropTypes.number,
    totalValuePaid: PropTypes.number,
  }).isRequired,
  deliveryCompanies: PropTypes.shape().isRequired,
  handleTicketDetail: PropTypes.func.isRequired,
};

export default TicketDetail;
