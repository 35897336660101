/* eslint-disable no-console */
import React from 'react';

import { useSelector } from 'react-redux';

import FirstLogin from './firstLogin';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './login.scss';

const FirstLoginScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <FirstLogin />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <div
          className="container-login__desktop-mode"
        >
          <FirstLogin />
        </div>
      </ContainerSectionDesktop>
    )
  );
};

export default FirstLoginScreen;
