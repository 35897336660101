import { createAndUpdateNegotiation } from '../../../../../services/user';

const createAndUpdateNegotiationService = async (negotiation) => {
  try {
    return await createAndUpdateNegotiation(negotiation);
  } catch (error) {
    return { message: 'error' };
  }
};

export default createAndUpdateNegotiationService;
