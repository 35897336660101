/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReactHtmlParser from 'react-html-parser';

import {
  toCurrencyCop, concatenateArray, userName, capitalizeWords,
} from '../../../../utils/strings';
import { humanizationOfTimeDuration } from '../../../../utils/generals';
import { finalPrice } from '../../../../utils/prices';
import { possibleConflictsList } from '../filtersAndFunctions';
import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';
import { typeDelivery } from '../../../../constants/general';
import { getQuoteCounters } from '../../../../services/user';

import ImageSelect from '../../../../components/imageSelect';
import ContentSection from '../../../../components/contentSection';
import Typography from '../../../../components/typography';
import Button from '../../../../components/button';
import CheckBox from '../../../../components/checkBox';
import RadioButton from '../../../../components/radioButton';
import Index from '../../../../components/lastDetails/prohibitedItems';

import transformToCsv from './downloadToCSV/formatDataCSV';

import IconDownload from '../../../../assets/images/Icon-download.svg';
import IconDropUp from '../../../../assets/images/Dropup_black.svg';
import IconDelete from '../../../../assets/images/delete_black.svg';
import ArrowBack from '../../../../assets/images/icon-arrow-back-color-primary.svg';
import IconStarOrange from '../../../../assets/images/star.svg';
import IconClockOrange from '../../../../assets/images/clock-orange.svg';
import IconDropDown from '../../../../assets/images/selectIcons/arrow_drop_down-Blue.svg';
import IconArrowBlue from '../../../../assets/images/arrow_blue.svg';
import optionsImageSelect from '../data/index';

import './stepShippingList.scss';
import './generalQuoteMultipleShippings.scss';

const orderByBlock = (dataShippings) => {
  const organizedShippings = [];

  dataShippings.forEach((shipping) => {
    const temporalOrganizedShippings = organizedShippings.slice();
    let indexOriginDestiny = -1;

    temporalOrganizedShippings.forEach((item, index) => {
      if (
        item[0].shippingOriginCode === shipping.shippingOriginCode
        && item[0].shippingDestinyCode === shipping.shippingDestinyCode
      ) {
        indexOriginDestiny = index;
      }
    });

    if (indexOriginDestiny >= 0) {
      organizedShippings[indexOriginDestiny].push(shipping);
    } else {
      organizedShippings.push([shipping]);
    }
  });

  return organizedShippings;
};

const StepShippingList = ({
  next, back, dataShippings, deliveryCompanies,
  setShippingSelected, loadingdataToPayment, isMobileDevice,
  selectPaymentMethod, setSelectPaymentMethod,
  notProhibitedArticles, setNotProhibitedArticles,
  selectPaymentMethodManually, collectionServiceExistance,
  selectDeliveryCompanyManually, selectByDeliverySelected,
  deliverySelectedForAll, shippingsWithConflicts, deleteShipping,
  isPosibleContinueToPayment, thePaymentFromCollecionValueIsPosible,
  deliveryCompanySelectByFilter, setDeliveryCompanySelectByFilter,
  user, showOfficcesAddress, selectRequestPickupFalse, setSelectRequestPickupFalse,
}) => {
  const [itemSelected, setItemSelected] = useState(null);
  const [rowSelectedPaymentMethod, setRowSelectedPaymentMethod] = useState(null);
  const [rowSelectedDelivery, setRowSelectedDelivery] = useState(null);
  const [showShippingListInMobileMode, setShowShippingListInMobileMode] = useState(false);
  const [showDeliveryCompanies, setShowDeliveryCompanies] = useState(false);
  const [prohibitedItems, setProhibitedItems] = useState(null);
  const [showProhibitedItems, setShowProhibitedItems] = useState(false);
  const [counterCsv, setCounterCsv] = useState(null);

  const textWithLink = ReactHtmlParser('<span style="color:#6B7177; font-size: 16px; font-weight: 500;">Declaro no enviar</span> artículos prohibidos');

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const counterNumber = async () => {
    // eslint-disable-next-line no-return-await
    const counterData = async () => await getQuoteCounters(user._id);
    const dataToSet = await counterData();
    setCounterCsv(dataToSet[0].quantity);
  };

  useEffect(() => {
    counterNumber()
      .catch((err) => (err));
  }, []);

  const checkBoxCreditPayment = () => (
    (user.creditPayment) && (
      <CheckBox
        checked={selectPaymentMethod === 105}
        onChange={() => setSelectPaymentMethod(105)}
        text="Pagar a credito"
      />
    ));

  const spanCreditPayment = (send) => ((user.creditPayment) && (
    <span
      className="options"
      onClick={() => selectPaymentMethodManually(105, send.rowNum)}
    >
      Pagar a credito
    </span>
  ));

  const titleForHeader = () => {
    let text = '';
    if (shippingsWithConflicts) {
      if (isMobileDevice) {
        text = 'Conflictos';
      } else {
        text = 'Algunos de tus envíos tienen conflictos';
      }
    } else if (isMobileDevice) {
      text = 'Tus envíos';
    } else {
      text = 'Cotiza múltiples envíos';
    }
    return text;
  };

  const childrenRigthForHeader = () => {
    if (!isMobileDevice) {
      return (
        <span className="shipping-request-quantity">
          Total de envíos solicitados:
          {' '}
          <b>
            {dataShippings?.length}
          </b>
        </span>
      );
    }
    return null;
  };

  const viewCredit = (send) => (send.paymentMethod === 105) && (user.creditPayment) && (
    'Pagar a credito'
  );

  const buttonToPayment = () => (
    <Button
      text="Pagar envíos"
      type={isCountryValid ? 'primary-mx' : 'primary'}
      disabled={!isPosibleContinueToPayment(dataShippings)}
      loading={loadingdataToPayment}
      onClick={() => next()}
      className="button-payment"
    />
  );

  const buttonToDownloadCSV = () => (
    <Button
      text="Cotización"
      type="tertiary"
      disabled={!isPosibleContinueToPayment(dataShippings)}
      loading={loadingdataToPayment}
      onClick={() => transformToCsv(dataShippings, deliveryCompanySelectByFilter)}
      rightImgButton={!isPosibleContinueToPayment(dataShippings) ? '' : IconDownload}
      className="button-download-csv"
    />
  );

  const contentTop = (visibility) => (
    <div className={`content-top ${isMobileDevice ? 'mobile-mode' : ''} ${!visibility ? 'hidden' : ''}`}>
      <div className="container-column__column-top second">
        <span className="title__second">{capitalizeWords(typeDelivery[user.countryCode])}</span>
        <div className="content-second">
          <div className="column__content-second">
            <div className="column__content-second">
              <ImageSelect
                imageSelectData={optionsImageSelect}
                deliverySelectedForAllImage="Seleccionar por"
                onClick={(selectByFilter) => setDeliveryCompanySelectByFilter(selectByFilter)}
              />
            </div>
            <div
              className={`container-select-delivery-company ${showDeliveryCompanies ? 'show' : ''}`}
              onClick={() => setShowDeliveryCompanies(!showDeliveryCompanies)}
            >
              <div className="container-selector">
                <span className="label">{deliverySelectedForAll?.name ?? capitalizeWords(typeDelivery[user.countryCode])}</span>
                <img src={IconDropDown} alt="" />
              </div>
              <div className="container-options">
                {deliveryCompanies.map((delivery) => (
                  <span
                    key={delivery.id}
                    className="options-delivery-company"
                    onClick={() => selectByDeliverySelected(delivery.id)}
                  >
                    <img src={delivery.img} alt="" />
                    {delivery.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {collectionServiceExistance && (
        <div className="container-column__column-top second align-mobile">
          <span className="title__second">Método de pago</span>
          <div className="content-second">
            <div className="column__content-second">
              <CheckBox
                checked={selectPaymentMethod === 101}
                onChange={() => setSelectPaymentMethod(101)}
                text="Pagar con saldo"
              />
              <CheckBox
                checked={selectPaymentMethod === 102}
                onChange={() => setSelectPaymentMethod(102)}
                text="Descontar del recaudo"
              />
              {checkBoxCreditPayment()}
            </div>
          </div>
        </div>
      )}

      <div className="container-column__column-top second">
        <span className="title__second-personalized">Dirección de origen</span>
        <div className="content-second">
          <div className="column__content-second">
            <RadioButton
              checked={!selectRequestPickupFalse}
              onChange={() => setSelectRequestPickupFalse(null)}
              text="Dirección predeterminada"
              selectedColor="blue"
              className="radio-text-massive"
            />
            {showOfficcesAddress && (
              <RadioButton
                checked={selectRequestPickupFalse === 'Lo llevaré a la transportadora'}
                onChange={() => setSelectRequestPickupFalse('Lo llevaré a la transportadora')}
                text={`Lo llevaré a la  ${typeDelivery[user.countryCode]}`}
                selectedColor="blue"
                className="radio-text-massive"
              />
            )}
          </div>
        </div>
      </div>

      {isMobileDevice && (
        <div className="container-column__column-top second">
          <span
            onClick={() => setShowShippingListInMobileMode(true)}
            className="button__show-shipping-list"
          >
            Ver todos los envíos
            {' '}
            <img src={IconArrowBlue} alt="icon" />
          </span>
          <div className="container_mobile_payment">
            <div className="button-continue__mobile-mode personalized-class-button">
              {buttonToPayment()}
            </div>
            <CheckBox
              value={prohibitedItems}
              onChange={setNotProhibitedArticles}
              clickOnText={() => setShowProhibitedItems(true)}
              text={textWithLink}
              checked={notProhibitedArticles}
            />
          </div>
        </div>
      )}

      <div className="container-column__column-top third">
        <h1>{childrenRigthForHeader()}</h1>
      </div>
    </div>
  );

  const shippingListForDesktopMode = (shippingBlock) => (
    <table>
      <thead>
        <tr>
          <th>Destinatario</th>
          <th>Producto</th>
          <th>Pago contra entrega</th>
          <th>{typeDelivery[user.countryCode].charAt(0).toUpperCase()}</th>
          <th>Método de pago</th>
          <th>Valor</th>
          <th>{' '}</th>
        </tr>
      </thead>
      <tbody>
        {shippingBlock.map((send) => (
          <>
            <tr
              key={send.rowNum.toString()}
              onClick={() => setShippingSelected(send)}
            >
              <td>{userName(send.receiverName, send.receiverSurname)}</td>
              <td>{send.articleDescription}</td>
              <td>{send.collectionService ? 'Sí' : 'No'}</td>
              <td
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {send.deliveryCompaniesOptions?.length > 0 ? (
                  <div
                    className={`container-select-delivery-company ${rowSelectedDelivery === send.rowNum ? 'select' : ''}`}
                    onClick={() => {
                      setRowSelectedDelivery(rowSelectedDelivery === send.rowNum ? null : send.rowNum);
                      setRowSelectedPaymentMethod(null);
                    }}
                  >
                    <div className="container-selector">
                      <span className="label">{send.deliveryCompanySelected.id ? send.deliveryCompanySelected.deliveryCompanyName : capitalizeWords(typeDelivery[user.countryCode])}</span>
                      <img src={IconDropDown} alt="" />
                    </div>
                    <div className="container-options relative">
                      {send.deliveryCompaniesOptions.map((delivery) => (
                        <span
                          key={delivery.deliveryCompanyName}
                          className="options-delivery-company"
                          onClick={() => selectDeliveryCompanyManually(delivery, send.rowNum)}
                        >
                          <span className="container-details-delivery-company first">
                            <img src={delivery.deliveryCompanyImgUrl} className="logo" alt="" />
                            <span>{delivery.deliveryCompanyName}</span>
                          </span>
                        </span>
                      ))}
                    </div>
                    <div className="container-options">
                      {send.deliveryCompaniesOptions.map((delivery) => (
                        <span
                          className="options-delivery-company"
                          onClick={() => selectDeliveryCompanyManually(delivery, send.rowNum)}
                          key={delivery.deliveryCompanyName}
                        >
                          <span className="container-details-delivery-company first">
                            <img src={delivery.deliveryCompanyImgUrl} className="logo" alt="" />
                            <span>{delivery.deliveryCompanyName}</span>
                          </span>
                          <span className="container-details-delivery-company second">
                            <img src={IconStarOrange} alt="" />
                            <span>{delivery.score}</span>
                          </span>
                          <span className="container-details-delivery-company third">
                            <img src={IconClockOrange} alt="" />
                            <span>{humanizationOfTimeDuration(delivery.shippingTime)}</span>
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>
                ) : (
                  <span className="red">Sin cobertura</span>
                )}
              </td>
              <td
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {send.deliveryCompaniesOptions.length > 0 ? (
                  <div
                    className={`container-select-payment-method ${rowSelectedPaymentMethod === send.rowNum ? 'select' : ''}`}
                    onClick={() => {
                      setRowSelectedPaymentMethod(rowSelectedPaymentMethod === send.rowNum ? null : send.rowNum);
                      setRowSelectedDelivery(null);
                    }}
                  >
                    <div className="container-selector">
                      <span className="label">
                        {send.paymentMethod === 101 && (
                          'Pagar con saldo'
                        )}
                        {send.paymentMethod === 102 && (
                          'Descontar del recaudo'
                        )}
                        {viewCredit(send)}
                        {!send.paymentMethod && (
                          'Seleccionar método'
                        )}
                      </span>
                      <img src={IconDropDown} alt="" />
                    </div>
                    <div className="container-options relative">
                      <span
                        className="options"
                        onClick={() => selectPaymentMethodManually(101, send.rowNum)}
                      >
                        Pagar con saldo
                      </span>
                      {thePaymentFromCollecionValueIsPosible(send) && (
                        <span
                          className="options"
                          onClick={() => selectPaymentMethodManually(102, send.rowNum)}
                        >
                          Descontar del recaudo
                        </span>
                      )}
                      {spanCreditPayment()}
                    </div>
                    <div className="container-options">
                      <span
                        className="options"
                        onClick={() => selectPaymentMethodManually(101, send.rowNum)}
                      >
                        Pagar con saldo
                      </span>
                      {thePaymentFromCollecionValueIsPosible(send) && (
                        <span
                          className="options"
                          onClick={() => selectPaymentMethodManually(102, send.rowNum)}
                        >
                          Descontar del recaudo
                        </span>
                      )}
                      {spanCreditPayment(send)}
                    </div>
                  </div>
                ) : (
                  '-'
                )}
              </td>
              <td>
                {
                  send.deliveryCompanySelected.id ? (
                    toCurrencyCop(
                      user,
                      Math.floor(
                        finalPrice(
                          send.deliveryCompanySelected.shippingCost,
                          send.collectionService,
                          send.addShippingToCollectionValue,
                          send.deliveryCompanySelected.collectionCommissionWithRate,
                          send.deliveryCompanySelected.collectionCommissionWithOutRate,
                        )),
                    )
                  ) : '-'
                }
              </td>
              <td
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <img
                  src={IconDelete}
                  alt="drop Up"
                  onClick={() => deleteShipping('row', send.rowNum)}
                />
              </td>
            </tr>
            {shippingsWithConflicts && (
              <tr>
                <td colSpan="7">
                  <Typography
                    type="value"
                    className="shipping-conflict"
                    text={concatenateArray(send.conflicts?.map((conflict) => conflict.longText), 'y', '.')}
                    aria-label="button"
                  />
                </td>
              </tr>
            )}
          </>
        ))}
      </tbody>
    </table>
  );

  const shippingListForMobileMode = (sendingBlock) => (
    sendingBlock.map((send) => (
      <div
        className="data-sending__mobile-mode"
        key={send.rowNum.toString()}
        onClick={() => setShippingSelected(send)}
      >
        <span className="send-text__mobile-mode">{userName(send.receiverName, send.receiverSurname)}</span>
        <span className="send-text__mobile-mode">{send.articleDescription}</span>
        <span className="send-text__mobile-mode">
          Pago contra entrega:
          {' '}
          <span>{send.collectionService ? 'Sí' : 'No'}</span>
        </span>

        {send.deliveryCompaniesOptions.length > 0 ? (
          <div
            className={`container-select-payment-method ${rowSelectedPaymentMethod === send.rowNum ? 'select' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setRowSelectedPaymentMethod(rowSelectedPaymentMethod === send.rowNum ? null : send.rowNum);
              setRowSelectedDelivery(null);
            }}
          >
            <div className="container-selector">
              <span className="label">
                {send.paymentMethod === 101 && (
                  'Pagar con saldo'
                )}
                {send.paymentMethod === 102 && (
                  'Descontar del recaudo'
                )}
                {viewCredit}
                {!send.paymentMethod && (
                  'Seleccionar método'
                )}
              </span>
              <img src={IconDropDown} alt="" />
            </div>
            <div className="container-options relative">
              <span
                className="options"
                onClick={() => selectPaymentMethodManually(101, send.rowNum)}
              >
                Pagar con saldo
              </span>
              {thePaymentFromCollecionValueIsPosible(send) && (
                <span
                  className="options"
                  onClick={() => selectPaymentMethodManually(102, send.rowNum)}
                >
                  Descontar del recaudo
                </span>
              )}
              {spanCreditPayment(send)}
            </div>
            <div className="container-options">
              <span
                className="options"
                onClick={() => selectPaymentMethodManually(101, send.rowNum)}
              >
                Pagar con saldo
              </span>
              {thePaymentFromCollecionValueIsPosible(send) && (
                <span
                  className="options"
                  onClick={() => selectPaymentMethodManually(102, send.rowNum)}
                >
                  Descontar del recaudo
                </span>
              )}
              {spanCreditPayment(send)}
            </div>
          </div>
        ) : (
          '-'
        )}

        {send.deliveryCompaniesOptions?.length > 0 ? (
          <div
            className={`container-select-delivery-company ${rowSelectedDelivery === send.rowNum ? 'select' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setRowSelectedDelivery(rowSelectedDelivery === send.rowNum ? null : send.rowNum);
              setRowSelectedPaymentMethod(null);
            }}
          >
            <div className="container-selector">
              <span className="label">{send.deliveryCompanySelected.id ? send.deliveryCompanySelected.deliveryCompanyName : typeDelivery[user.countryCode]}</span>
              <img src={IconDropDown} alt="" />
            </div>
            <div className="container-options relative">
              {send.deliveryCompaniesOptions.map((delivery) => (
                <span
                  key={delivery.deliveryCompanyName}
                  className="options-delivery-company"
                  onClick={() => selectDeliveryCompanyManually(delivery, send.rowNum)}
                >
                  <span className="container-details-delivery-company first">
                    <img src={delivery.deliveryCompanyImgUrl} className="logo" alt="" />
                    <span>{delivery.deliveryCompanyName}</span>
                  </span>
                </span>
              ))}
            </div>
            <div className="container-options">
              {send.deliveryCompaniesOptions.map((delivery) => (
                <span
                  className="options-delivery-company"
                  onClick={() => selectDeliveryCompanyManually(delivery, send.rowNum)}
                  key={delivery.deliveryCompanyName}
                >
                  <span className="container-details-delivery-company first">
                    <img src={delivery.deliveryCompanyImgUrl} className="logo" alt="" />
                    <span>{delivery.deliveryCompanyName}</span>
                  </span>
                  <span className="container-details-delivery-company second">
                    <img src={IconStarOrange} alt="" />
                    <span>{delivery.score}</span>
                  </span>
                  <span className="container-details-delivery-company third">
                    <img src={IconClockOrange} alt="" />
                    <span>{humanizationOfTimeDuration(delivery.shippingTime)}</span>
                  </span>
                </span>
              ))}
            </div>
          </div>
        ) : (
          <span className="red">Sin cobertura</span>
        )}

        {send.deliveryCompaniesOptions?.length <= 0 && (
          '-'
        )}

        <span className="value-sending__mobile-mode">
          Valor:
          {' '}
          <span className="number">
            {send.deliveryCompanySelected.id ? toCurrencyCop(user, Math.floor(finalPrice(
              send.deliveryCompanySelected.shippingCost,
              send.collectionService,
              send.addShippingToCollectionValue,
              send.deliveryCompanySelected.collectionCommissionWithRate,
              send.deliveryCompanySelected.collectionCommissionWithOutRate,
            ))) : '-'}
          </span>
        </span>

        <img
          src={IconDelete}
          alt="drop Up"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            deleteShipping('row', send.rowNum);
          }}
          className="delete-sending__mobile-mode"
        />
      </div>
    ))
  );

  const contentShippingsList = () => (
    <div className={`content-bottom ${shippingsWithConflicts ? 'full-height' : ''} ${isMobileDevice ? 'mobile-mode' : ''}`}>
      {isMobileDevice && (
        <span className="shipping-request-quantity-mobile">
          Total de envíos solicitados:
          {' '}
          <b>
            {dataShippings?.length}
          </b>
        </span>
      )}
      <div className="container-item-list-sendings">
        {orderByBlock(dataShippings).map((shippingsBlock, index) => (
          <>
            <div
              className={`item-list-sending ${itemSelected === index ? 'selected' : ''}`}
              onClick={() => {
                if (itemSelected === index) {
                  setItemSelected(null);
                  setRowSelectedPaymentMethod(null);
                } else {
                  setItemSelected(index);
                }
              }}
              key={`sending-${shippingsBlock[0].rowNum}`}
            >
              <div className="options">
                <img
                  src={IconDropUp}
                  alt="drop Up"
                  className={itemSelected === index ? 'selected' : ''}
                />
              </div>
              <div className="name">
                <Typography
                  type="field"
                  text={`${shippingsBlock[0].shippingOriginName} - ${shippingsBlock[0].shippingDestinyName}: `}
                />
                {' '}
                <Typography
                  type="value"
                  text={`${shippingsBlock?.length} envío${shippingsBlock?.length > 1 ? 's' : ''}`}
                />
                {shippingsWithConflicts && (
                  <Typography
                    type="value"
                    className="conflicts"
                    text={shippingsBlock.map((shipping) => shipping.conflicts.length).reduce((a, b) => a + b, 0) > 0 ? 'Envíos con conflictos' : ''}
                  />
                )}

              </div>
              <div className="options">
                <img
                  src={IconDelete}
                  alt="drop Up"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteShipping('block', shippingsBlock[0].rowNum);
                  }}
                  className="delete-icon"
                />
              </div>
            </div>
            <div className={`list-sendings__item-list-sending ${itemSelected === index ? 'selected' : ''}`}>
              {isMobileDevice && shippingListForMobileMode(shippingsBlock)}
              {!isMobileDevice && shippingListForDesktopMode(shippingsBlock)}
            </div>
          </>
        ))}
      </div>
    </div>
  );

  return (
    <ContentSection
      headerChildrenCenter={isMobileDevice && (showShippingListInMobileMode ? (
        <Typography className="title-shipping-details" text="Envíos" type="superTitle-italic" />
      ) : (<Typography className="title-shipping-details" text="Características" type="superTitle-italic" />
      )
      )}
      headerChildrenLeft={isMobileDevice && (
        <div className="container-arrow-back">
          <img
            src={ArrowBack}
            alt="Arrow back"
            onClick={() => back()}
            className="arrow-back-payment-multiple"
          />
        </div>
      )}
    >
      <div id="multiple-shippings__sending-list">
        {isMobileDevice && (
          showShippingListInMobileMode || shippingsWithConflicts ? contentShippingsList() : contentTop()
        )}
        {!isMobileDevice && (
          <>
            {shippingsWithConflicts && (
              <span className="title-conflicts-annotation">
                {`Si continúa, los envios con conflictos
                ${concatenateArray(possibleConflictsList.map((conflict) => `"${conflict.shortText}"`), 'o', ',')}
                no seran procesados.`}
              </span>
            )}

            {contentTop(!shippingsWithConflicts)}
            {contentShippingsList()}
          </>
        )}
        {!isMobileDevice && (
          <div className="container_acept_buttons_payment">
            <CheckBox
              value={prohibitedItems}
              onChange={setNotProhibitedArticles}
              clickOnText={() => setShowProhibitedItems(true)}
              text={textWithLink}
              checked={notProhibitedArticles}
              className="checkbox-prohibitedItems"
            />
            <div className="container-buttons">
              {user.shipmentQuotes && (
                <>
                  <div className="counter-container">
                    <div className="counter">{counterCsv || 0}</div>
                    <span>
                      Cotizaciones realizadas
                      &nbsp;
                      <p>
                        mes en curso
                      </p>
                    </span>
                  </div>
                  {buttonToDownloadCSV()}
                </>
              )}
              {buttonToPayment()}
            </div>
          </div>
        )}
        {showProhibitedItems && (
          <Index
            close={() => setShowProhibitedItems(false)}
            isMobileDevice={isMobileDevice}
            closeBackground
          />
        )}
      </div>
    </ContentSection>
  );
};

StepShippingList.propTypes = {
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  setShippingSelected: PropTypes.func.isRequired,
  dataShippings: PropTypes.any.isRequired,
  deliveryCompanies: PropTypes.any,
  loadingdataToPayment: PropTypes.bool.isRequired,
  notProhibitedArticles: PropTypes.bool.isRequired,
  setNotProhibitedArticles: PropTypes.func.isRequired,
  selectByDeliverySelected: PropTypes.func.isRequired,
  deliverySelectedForAll: PropTypes.any,
  selectPaymentMethod: PropTypes.number,
  setSelectPaymentMethod: PropTypes.func.isRequired,
  selectPaymentMethodManually: PropTypes.func.isRequired,
  collectionServiceExistance: PropTypes.bool.isRequired,
  selectDeliveryCompanyManually: PropTypes.func.isRequired,
  deleteShipping: PropTypes.func.isRequired,
  shippingsWithConflicts: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  isPosibleContinueToPayment: PropTypes.func.isRequired,
  thePaymentFromCollecionValueIsPosible: PropTypes.func.isRequired,
  deliveryCompanySelectByFilter: PropTypes.string,
  setDeliveryCompanySelectByFilter: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  showOfficcesAddress: PropTypes.bool.isRequired,
  selectRequestPickupFalse: PropTypes.number,
  setSelectRequestPickupFalse: PropTypes.func.isRequired,
};

StepShippingList.defaultProps = {
  deliveryCompanies: null,
  selectPaymentMethod: null,
  deliverySelectedForAll: null,
  deliveryCompanySelectByFilter: '',
  selectRequestPickupFalse: null,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(StepShippingList);
