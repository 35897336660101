import { useSelector } from 'react-redux';

import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import HeaderButtonBack from '../../../components/headerButtonBack';
import Typography from '../../../components/typography';
import StateSwitch from '../../../components/stateSwitch';
import Button from '../../../components/button';
import getShippingStatus from '../../../services/getConfigurations';
import { setConfigNotificationsUser } from '../../../services/user';
import NotificationsInfoModal from '../../../components/notificationsInfoModal/index';

import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import './notificationSettingsViewer.scss';

const NotificationSettingsViewer = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth?.user || {});

  const [isSaas] = useState((user.clientType === 'SaaS'));
  const [listShippingStatus, setListShippingStatus] = useState(null);
  const [turnAllNotiiification, setTurnAllNotiiification] = useState({ saas: false, mp: false });
  const [showModalNotificationInfo, setShowModalNotificationInfo] = useState(false);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const redirectUri = () => window.open('https://www.mipaquete.com/centro-de-ayuda/notificaciones-destinatarios', 'WindowName', 'noopener');

  const switchAllNotifications = async (type) => {
    const object = {};
    object[type] = !turnAllNotiiification[type];
    const listCodeNotifications = {};
    listCodeNotifications[type] = [];
    if (object[type] === true) {
      listShippingStatus.map((status) => listCodeNotifications[type].push(status.code));
    }

    if (type === 'saas') {
      listCodeNotifications.mp = [];
      listShippingStatus.forEach((state) => {
        if (state.activeMp === true) listCodeNotifications.mp.push(state.code);
      });
    } else {
      listCodeNotifications.saas = [];
      listShippingStatus.forEach((state) => {
        if (state.activeSaas === true) listCodeNotifications.saas.push(state.code);
      });
    }

    await setConfigNotificationsUser({ activeNotifications: listCodeNotifications });

    const listStatusPrice = await getShippingStatus();

    setListShippingStatus(listStatusPrice);

    setTurnAllNotiiification({
      ...turnAllNotiiification,
      ...object,
    });
  };

  useEffect(async () => {
    if (listShippingStatus) {
      const listCodeNotifications = {};
      listCodeNotifications.mp = [];
      listCodeNotifications.saas = [];
      listShippingStatus.forEach((state) => {
        if (state.activeMp === true) listCodeNotifications.mp.push(state.code);
        if (state.activeSaas === true) listCodeNotifications.saas.push(state.code);
      });

      const AllNotificationSaas = listShippingStatus.filter((status) => status.activeSaas === true);
      const AllNotificationMp = listShippingStatus.filter((status) => status.activeMp === true);

      const AllNotification = { saas: false, mp: false };

      if (AllNotificationSaas.length === listShippingStatus.length) {
        AllNotification.saas = true;
        setTurnAllNotiiification(AllNotification);
      }

      if (AllNotificationMp.length === listShippingStatus.length) {
        AllNotification.mp = true;
        setTurnAllNotiiification(AllNotification);
      }
      await setConfigNotificationsUser({ activeNotifications: listCodeNotifications });
    }
  }, [listShippingStatus]);

  useEffect(async () => {
    const listStatusPrice = await getShippingStatus();
    const AllNotificationSaas = listStatusPrice.filter((status) => status.activeSaas === true);
    const AllNotificationMp = listStatusPrice.filter((status) => status.activeMp === true);

    const AllNotification = { saas: false, mp: false };

    if (AllNotificationSaas.length === listStatusPrice.length) {
      AllNotification.saas = true;
      setTurnAllNotiiification(AllNotification);
    }

    if (AllNotificationMp.length === listStatusPrice.length) {
      AllNotification.mp = true;
      setTurnAllNotiiification(AllNotification);
    }
    setListShippingStatus(listStatusPrice);
  }, []);

  return (
    <>
      <div className="card-notifications">
        <HeaderButtonBack title="Notificaciones a destinatarios" />
        <div className="container-section-notifications">
          <div className={`container-title-notificaion ${(isMobileDevice) ? 'isMobileDevice' : ''}`}>
            <Typography
              type="paragraph"
              text="Configura las notificaciones de seguimiento de envío que quieres que tus clientes reciban para los próximos envíos que realices."
              className="paragraph-notification-settings heeboRegular"
            />
          </div>
          <div className={`settings ${(isMobileDevice) ? 'isMobileDevice' : ''}`}>
            <div className={`section ${isMobileDevice ? 'isMobileDevice' : ''}`}>
              {(listShippingStatus) ? (
                <>
                  {isSaas ? (
                    <Typography
                      type="superTitle"
                      text="Mipaquete"
                      className="title-section hebboMedium"
                    />
                  ) : null}
                  <StateSwitch
                    text="Todas:"
                    active={turnAllNotiiification.mp}
                    constDisable
                    onChange={() => {
                      switchAllNotifications('mp');
                    }}
                  />
                  {listShippingStatus.map(
                    (status) => (
                      <StateSwitch
                        key={status.code}
                        text={status.description}
                        active={!!(status.activeMp)}
                        cost={status.fee.mp}
                        onChange={(e) => {
                          e.stopPropagation();
                          const listShipping = [];
                          listShippingStatus.forEach((element) => {
                            const elementStatus = element;
                            if (elementStatus.code === status.code) {
                              elementStatus.activeMp = !(status.activeMp);
                            }
                            listShipping.push(elementStatus);
                          });
                          setListShippingStatus(listShipping);
                        }}
                        user={user}
                      />
                    ))}
                </>
              ) : null}
            </div>
            <div className={`section ${isMobileDevice ? 'isMobileDevice' : ''}`}>
              {isSaas && listShippingStatus ? (
                <>
                  <Typography
                    type="superTitle"
                    text="Pasarela de envíos"
                    className="title-section hebboMedium"
                  />
                  <StateSwitch
                    text="Todas:"
                    active={turnAllNotiiification.saas}
                    constDisable
                    onChange={() => {
                      switchAllNotifications('saas');
                    }}
                  />
                  {listShippingStatus.map(
                    (status) => (
                      <StateSwitch
                        key={status.code}
                        text={status.description}
                        active={!!(status.activeSaas)}
                        cost={status.fee.saas}
                        onChange={(e) => {
                          e.stopPropagation();
                          const listShipping = [];
                          listShippingStatus.forEach((element) => {
                            const elementStatus = element;
                            if (elementStatus.code === status.code) {
                              elementStatus.activeSaas = !(status.activeSaas);
                            }
                            listShipping.push(elementStatus);
                          });
                          setListShippingStatus(listShipping);
                        }}
                      />
                    ))}
                </>
              ) : null}
            </div>
          </div>
          <div
            className={`change-button ${(isMobileDevice) ? 'isMobileDevice' : ''}`}
            onClick={() => setShowModalNotificationInfo(!showModalNotificationInfo)}
          >
            <Typography
              type="field"
              text="Ver notificaciones"
            />
          </div>
          {showModalNotificationInfo && (
          <NotificationsInfoModal
            close={() => setShowModalNotificationInfo(false)}
            isMobileDevice={isMobileDevice}
            closeBackground
          />
          )}
          <div className={`footer ${isMobileDevice ? 'isMobileDevice' : ''}`}>
            <Typography
              type="paragraph"
              text={ReactHtmlParser('¿Sabías que el <b>78%</b> de los colombianos usan <b>WhatsApp</b>?')}
              className="paragraph-notification-settings heeboRegular"
            />
            <Typography
              type="paragraph"
              text="El cobro de las notificaciones se hace indiferente si tu destinatario tiene o no, WhatsApp instalado o actualizado."
              className="paragraph-notification-settings heeboRegular"
            />
            <Button
              text="Conocer más de este servicio"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              className="button-redirection"
              onClick={() => redirectUri()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationSettingsViewer;
