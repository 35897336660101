/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { optionsProductTypeArray } from 'constants/quoteShipping';
import { useValidationCountry } from 'utils/validationCountry';
import {
  discountCalculateValue, totalPrices,
} from 'utils/prices';
import { valueChangeCommissionRate } from 'utils/quoteShipping';

import { stepOneValidation } from 'components/lastDetails/validations/validation';
import Right from 'components/lastDetails/stepOne/right';
import Toast from 'components/toast';
import Button from 'components/button';
import Left from './left';
import LeftCollectionService from './leftCollectionService';

import './stepFour.scss';

const existProductTypeArray = (user) => {
  if (user.productType) {
    return optionsProductTypeArray.map((productType) => {
      const producsTypeSelected = user.productType.split('-');
      return {
        value: productType.value,
        text: productType.text,
        checked: productType.value === producsTypeSelected
          .filter((productTypeSelected) => productTypeSelected === productType.value)[0],
      };
    });
  }
  return optionsProductTypeArray;
};

const StepFour = ({
  deliverySelected,
  shippingInfo,
  user,
  addShippingToCollectionValue,
  setAddShippingToCollectionValue,
  productInfo,
  onChange,
  isMobileDevice,
  shippingData,
}) => {
  const [changes, setChanges] = useState({});
  const [errorsList, setErrorsList] = useState([]);
  const [optionsProduct] = useState(existProductTypeArray(user));

  const isCountryValid = useValidationCountry(user.countryCode);

  const existenceUserAgency = user.agency;
  const setData = ({
    formValues,
    collectionOrTakeToOffice,
    productDescription,
    listTypeProducts,
  }) => {
    setChanges({
      ...formValues,
      collectionOrTakeToOffice,
      productDescription,
      listTypeProducts: formValues.productType ? listTypeProducts : [],
    });
  };

  return (
    <div id="delivery-info__sending-details" className={` ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'} `}>
      <div className="delivery-info__sending-details">
        <div className="shipping-info__sending-details">
          {(user.countryCode === '170' && (deliverySelected?.routeType === 'reexpedicion'
            || deliverySelected?.routeType === 'regional'
            || deliverySelected?.routeType === 'especial'
            || deliverySelected?.routeType === 'zonal') && (
            isMobileDevice ? (
              <Toast
                autoClose={7000}
                title="Población lejana"
                message="El tiempo de entrega podría ser superior al estimado."
                position="top-center"
              />
            ) : (
              <Toast
                autoClose={7000}
                title="Población lejana"
                message="El tiempo de entrega podría ser superior al estimado."
                position="top-right"
              />
            )))}
          {shippingInfo.collectionService ? (
            <LeftCollectionService
              deliverySelected={deliverySelected}
              productInfo={productInfo}
              addShippingToCollectionValue={addShippingToCollectionValue}
              setAddShippingToCollectionValue={setAddShippingToCollectionValue}
              shippingValue={totalPrices(
                (deliverySelected.shippingCost - deliverySelected.insuranceValue),
                deliverySelected.insuranceValue,
              )}
              shippingRealValue={deliverySelected?.shippingRealCost ? totalPrices(
                (deliverySelected.shippingCost - deliverySelected.insuranceValue),
                deliverySelected.insuranceValue,
                discountCalculateValue(
                  deliverySelected?.shippingCost,
                  deliverySelected?.shippingRealCost,
                ),
              ) : 0}
              collectionCommissionValue={valueChangeCommissionRate(
                deliverySelected,
                addShippingToCollectionValue).collectionCommissionRate}
              realCollectionCommissionValue={deliverySelected?.realCollectionCommissionWithoutRateValue
                ? valueChangeCommissionRate(deliverySelected, addShippingToCollectionValue).realCommissionValue
                : valueChangeCommissionRate(deliverySelected, addShippingToCollectionValue).collectionCommissionRate}
            />
          ) : (
            <Left
              deliverySelected={deliverySelected}
              shippingInfo={shippingInfo}
              addShippingToCollectionValue={addShippingToCollectionValue}
              setAddShippingToCollectionValue={setAddShippingToCollectionValue}
            />
          )}
          <Right
            shippingData={shippingData}
            user={user}
            optionsProductTypeArray={optionsProduct}
            deliverySelected={deliverySelected}
            onChange={setData}
            errorsList={errorsList}
            setErrorsList={setErrorsList}
          >
            <div className="buttons">
              <Button
                text="Continuar"
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={() => {
                  const validateStepOne = {
                    changes,
                    optionsProductTypeArray,
                    errorsList,
                    setErrorsList,
                    existenceUserAgency,
                    countryCode: user.countryCode,
                  };
                  const validation = stepOneValidation(validateStepOne);
                  if (validation) {
                    onChange(
                      {
                        changes,
                        action: 'continue',
                      },
                    );
                  }
                }}
              />
            </div>
          </Right>
        </div>

      </div>
    </div>
  );
};

StepFour.propTypes = {
  deliverySelected: PropTypes.shape({
    deliveryCompanyId: PropTypes.string.isRequired,
    routeType: PropTypes.string.isRequired,
    shippingCost: PropTypes.number.isRequired,
    collectionCommissionWithRate: PropTypes.number,
    collectionCommissionWithOutRate: PropTypes.number,
    insuranceValue: PropTypes.number.isRequired,
    shippingRealCost: PropTypes.number,
    realCollectionCommissionWithoutRateValue: PropTypes.number,
  }).isRequired,
  shippingInfo: PropTypes.shape({
    collectionService: PropTypes.bool.isRequired,
    declaredValues: PropTypes.number.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    address: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    businessName: PropTypes.string.isRequired,
    cellPhone: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    alternativeDirections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    agency: PropTypes.shape({}),
  }).isRequired,
  shippingData: PropTypes.shape({
    expressAddress: PropTypes.string,
  }).isRequired,
  addShippingToCollectionValue: PropTypes.bool,
  setAddShippingToCollectionValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  productInfo: PropTypes.shape({
    saleValue: PropTypes.string,
  }),
  isMobileDevice: PropTypes.bool.isRequired,
  shippingRealCost: PropTypes.number.isRequired,
};

StepFour.defaultProps = {
  addShippingToCollectionValue: false,
  productInfo: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(StepFour);
