import React from 'react';
import PropTypes from 'prop-types';

const handleImageClick = (event, setShowDeliveryAnnottation) => {
  // Evita que el evento se propague al contenedor principal
  event.stopPropagation();
  setShowDeliveryAnnottation(true);
};

const onKeyDownDeliveryList = (e, setShowDeliveryAnnottation) => {
  if (e.key === 'Enter') {
    e.stopPropagation();
    handleImageClick(e, setShowDeliveryAnnottation);
  }
};

const DeliveryImage = ({
  deliveryImg,
  deliveryName,
  setShowDeliveryAnnottation,
}) => (
  <div
    onClick={(e) => handleImageClick(e, setShowDeliveryAnnottation)}
    onKeyDown={(e) => onKeyDownDeliveryList(e, setShowDeliveryAnnottation)}
    className="delivery-img"
    role="button"
    tabIndex="0"
  >
    <img src={deliveryImg} alt={`logo-${deliveryName}`} />
    <div className="overlay">
      <span>Ver info</span>
    </div>
  </div>
);

DeliveryImage.propTypes = {
  deliveryImg: PropTypes.string.isRequired,
  deliveryName: PropTypes.string.isRequired,
  setShowDeliveryAnnottation: PropTypes.func.isRequired,
};

export default DeliveryImage;
