/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../../../../../components/typography';

import './billingDataMexico.scss';

const title = () => (
  <div>
    Los siguientes datos son necesarios para
    facturar y deben coincidir con los del
    {' '}
    <span>SAT </span>
    <br />
    {' '}
    (Servicio de Administración Tributaria)
  </div>
);

const RFC = () => (
  <>
    RFC
    {' '}
    <span>
      (Registro Federal de Contribuyentes)
    </span>
  </>
);

const userTradeName = () => (
  <>
    Razón social
    {' '}
    <span>
      sin los conceptos mercantiles
    </span>
  </>
);

const BillingDataMexico = ({ user, isMobileDevice }) => (
  <div className={`contacInfo-container ${isMobileDevice && 'invoicing-mobile'}`}>
    <Typography
      className="main-title"
      text={title()}
    />
    <div className="content-info">
      <div className="content-left">
        <div>
          <Typography className="title-info" text={RFC()} />
          <Typography className="subtitle-info" text={user.invoicingData?.RFC ?? ''} />
        </div>
        <div>
          <Typography className="title-info" text={userTradeName()} />
          <Typography className="subtitle-info" text={user.tradeName ?? ''} />
        </div>
        <div>
          <Typography className="title-info" text="Tipo de persona" />
          <Typography className="subtitle-info" text={user.personType ?? ''} />
        </div>
        <div className="last">
          <Typography className="title-info" text="Régimen Fiscal" />
          <Typography className="subtitle-info" text={user.invoicingData?.taxRegime ?? ''} />
        </div>
      </div>
      <div className="content-right">
        <div>
          <Typography className="title-info" text="Domicilio fiscal" />
          <Typography className="subtitle-info" text={user.invoicingData?.taxResidence ?? ''} />
        </div>
        <div>
          <Typography className="title-info" text="Código postal" />
          <Typography className="subtitle-info" text={user.invoicingData?.postalCode ?? ''} />
        </div>
        <div>
          <Typography className="title-info" text="Forma de pago" />
          <Typography className="subtitle-info" text={user.invoicingData?.methodOfPayment ?? ''} />
        </div>
        <div>
          <Typography className="title-info" text="Clave de uso CFDI" />
          <Typography className="subtitle-info" text={user.invoicingData?.CFDI ?? ''} />
        </div>
        <div className="last">
          <Typography className="title-info" text="E-mail para facturación electrónica" />
          <Typography className="subtitle-info" text={user.invoicingData?.emailForBilling ?? ''} />
        </div>
      </div>
    </div>
  </div>
);

BillingDataMexico.propTypes = {
  user: PropTypes.instanceOf.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

BillingDataMexico.defaultProps = {

};

export default BillingDataMexico;
