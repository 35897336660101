import React from 'react';
import PropTypes from 'prop-types';

import './containerSection.scss';

const ContainerSection = ({
  children, className, fullWidth,
}) => (
  <div
    className={`
      container-section
      ${className}
      ${fullWidth ? 'full-width' : ''}
    `}
  >
    {children}
  </div>
);

ContainerSection.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

ContainerSection.defaultProps = {
  className: null,
  fullWidth: false,
};

export default ContainerSection;
