import { documentTypeList } from '../../constants/general';
import { validationValue, insertOrRemoveErrorFromList } from '../../utils/strings';

const addOrRemoveError = ({ errorsList, setErrorsList, validateData }) => {
  const temporalErrorsList = errorsList.slice();
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    validateData.errorName,
    validateData.errorText,
    validateData.action || (validationValue(validateData.value, validateData.type, validateData.list).correct ? 'remove' : 'insert'),
  );

  setErrorsList(temporalErrorsList);
  return temporalErrorsList.length === 0;
};

export const validateBank = ({
  errorsList, setErrorsList, bank, banksWithTranferFee,
}) => {
  const validateObject = {
    errorsList,
    setErrorsList,
    validateData: {
      errorName: 'bank',
      value: bank,
      errorText: 'Por favor seleccionar un banco de la lista',
      type: 'list',
      list: banksWithTranferFee,
    },
  };
  addOrRemoveError(validateObject);
};

export const validateAccountNumber = ({ errorsList, setErrorsList, accountNumber }) => {
  const validateObject = {
    errorsList,
    setErrorsList,
    validateData: {
      errorName: 'accountNumber',
      value: accountNumber,
      errorText: 'Debe ser numerico y contener más de 6 dígitos ',
      type: 'accountBankNumber',
    },
  };
  addOrRemoveError(validateObject);
};

export const veirfyAccountsNumber = ({
  errorsList, setErrorsList, accountNumber, verifyAccountNumber,
}) => {
  const validateObject = {
    errorsList,
    setErrorsList,
    validateData: {
      errorName: 'verifyAccountNumber',
      value: verifyAccountNumber,
      errorText: 'Deben coincidir los números de cuenta bancaría',
      action: accountNumber === verifyAccountNumber ? 'remove' : 'insert',
    },
  };
  addOrRemoveError(validateObject);
};

export const validateSupportAccount = ({
  errorsList, setErrorsList, fileName, accept,
}) => {
  const ext = fileName.split('.');
  const acceptExt = accept.split(',').find((extension) => extension === ext[1]) ? 'remove' : 'insert';
  const validateObject = {
    errorsList,
    setErrorsList,
    validateData: {
      errorName: 'support',
      value: fileName,
      errorText: 'Solo se acepta imagenes: jpg, png o achivos pdf',
      type: 'accountBankNumber',
      action: acceptExt,
    },
  };
  addOrRemoveError(validateObject);
};

export const validateNameOfOwner = ({ errorsList, setErrorsList, nameOfOwner }) => {
  const validateObject = {
    errorsList,
    setErrorsList,
    validateData: {
      errorName: 'nameOfOwner',
      value: nameOfOwner,
      errorText: 'Debe escribir un nombre valido',
    },
  };
  addOrRemoveError(validateObject);
};

export const validateDocumentType = ({ errorsList, setErrorsList, documentType }) => {
  const validateObject = {
    errorsList,
    setErrorsList,
    validateData: {
      errorName: 'documentType',
      value: documentType,
      errorText: 'Por favor seleccionar una opción de la lista',
      type: 'list',
      list: documentTypeList.map((document) => document.value),
    },
  };
  addOrRemoveError(validateObject);
};

export const validateDocumentNumer = ({ errorsList, setErrorsList, documentNumber }) => {
  const validateObject = {
    errorsList,
    setErrorsList,
    validateData: {
      errorName: 'documentNumber',
      value: documentNumber,
      errorText: 'Debe escribir minimo 6 diígitos',
      type: 'identificationNumber',
    },
  };
  addOrRemoveError(validateObject);
};
