/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import swal from 'sweetalert';
import routeExistenceVerificationService from '../../services/routeExistanceVerification';
import { findLocationByName, findLocationByNameMexico } from '../filters';
import { validationValue, insertOrRemoveErrorFromList, addThousandPoint } from '../strings';
import { errorMessages } from '../../constants/messages';

export const returnNumber = (value) => {
  const number = parseInt(value.toString().replaceAll('.', '') ?? 0, 10);
  return typeof number === 'number' ? number : 0;
};

export const routeExistenceValidationFunction = async (countryCode, originCode, destinyCode, next) => {
  try {
    const responseRoutesVerification = await routeExistenceVerificationService(countryCode, originCode, countryCode, destinyCode);
    if (responseRoutesVerification.length > 0) next();
    else swal('Lo sentimos', 'Aún no tenemos cobertura en esta ruta, estamos trabajando para ti.');
  } catch {
    swal('Error', 'No hemos podido validar el origen y destino, inténtalo de nuevo.');
  }
};

export const stepOneValidation = (
  errorList, setErrorList, name, value, locations, countryCode,
) => {
  const temporalErrorList = errorList.slice();

  if (name === 'origin' || name === 'destiny') {
    const originSplitted = value.split('-');
    let location;

    if (originSplitted.length > 1) {
      if (countryCode === '170') {
        location = findLocationByName(
          originSplitted[0],
          originSplitted[1],
          locations,
        );
      } else {
        location = findLocationByNameMexico(
          originSplitted[0],
          originSplitted[1],
          originSplitted[2],
          originSplitted[3],
          locations,
        );
      }
    }

    insertOrRemoveErrorFromList(
      temporalErrorList,
      name,
      errorMessages[name],
      location ? 'remove' : 'insert',
    );
  }

  setErrorList(temporalErrorList);
};

export const stepTwoValidation = (
  errorList,
  setErrorList,
  name,
  value,
  unitOfMeasurement,
  minValue,
  maxValue,
  valueMaxError,
  valueMinError,
) => {
  const temporalErrorList = errorList.slice();
  const isLengthValid = typeof value === 'number' && value >= 1 && value <= 200;
  const textLengthValidation = 'Ingresa un valor entre 1 y 200 cm.';

  if (['height', 'width', 'length'].includes(name)) {
    insertOrRemoveErrorFromList(
      temporalErrorList, name, textLengthValidation,
      isLengthValid ? 'remove' : 'insert',
    );
  }

  if (name === 'weight' && typeof value === 'number') {
    const isWeightValid = (
      value >= 1
      && ((unitOfMeasurement === 'Kg' && value <= 150)
      || (unitOfMeasurement === 'g' && value <= 150000))
    );

    insertOrRemoveErrorFromList(
      temporalErrorList, 'weight', 'Ingresa un valor entre 1 y 150 kilos o 150.000 gramos.',
      isWeightValid ? 'remove' : 'insert',
    );
  }

  if (name === 'quantity' && typeof value === 'number') {
    insertOrRemoveErrorFromList(
      temporalErrorList, 'quantity', 'La cantidad de artículos debe ser 1 o más.',
      value >= 1 ? 'remove' : 'insert',
    );
  }

  if (name === 'declaredValue') {
    if (!value) {
      insertOrRemoveErrorFromList(
        temporalErrorList, name, valueMinError,
        value ? 'remove' : 'insert',
      );
    }

    if (value < minValue || value) {
      insertOrRemoveErrorFromList(
        temporalErrorList, name, valueMinError,
        value >= minValue ? 'remove' : 'insert',
      );
    }

    if (value > maxValue) {
      insertOrRemoveErrorFromList(
        temporalErrorList,
        name,
        `Ingresar un valor máximo de ${addThousandPoint(maxValue)}`,
        value <= maxValue ? 'remove' : 'insert',
      );
    }
  }
  setErrorList(temporalErrorList);
};

export const collectionServiceValidation = (
  collectionService, saleValue, errorsList, setErrorsList,
) => {
  const temporalErrorsList = errorsList.slice();

  if (
    collectionService
    && (!saleValue || saleValue < 1 || saleValue > 2000000)
  ) {
    const indexOfFind = temporalErrorsList.map((element) => element.name).indexOf('saleValue');
    if (indexOfFind < 0) {
      temporalErrorsList.push({
        name: 'saleValue',
        error: 'Ingresa un valor entre 1 y 2.000.000.',
      });
    }
  } else {
    const indexOfFind = temporalErrorsList.map((element) => element.name).indexOf('saleValue');
    if (indexOfFind !== -1) {
      temporalErrorsList.splice(indexOfFind, 1);
    }
  }

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length === 0) return true;
  return false;
};

export const sendingDetailsValidation = (
  articleDescription, recommendationsForDeliveryCompany,
  notProhibitedArticles, errorsList, setErrorsList,
) => {
  const temporalErrorsList = errorsList.slice();

  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'articleDescription',
    'Proporciona una descripción del artículo para continuar.',
    validationValue(articleDescription).correct ? 'remove' : 'insert',
  );

  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'recommendationsForDeliveryCompany',
    'El número máximo de caracteres de las recomendaciones para la transportadora es de 90 caracteres.',
    !recommendationsForDeliveryCompany || recommendationsForDeliveryCompany?.length <= 90 ? 'remove' : 'insert',
  );

  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'notProhibitedArticles',
    'Confirma para continuar.',
    notProhibitedArticles ? 'remove' : 'insert',
  );

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length === 0) return true;
  return false;
};

export const senderDetailsValidation = (
  senderName, senderSurname, senderIdentificationNumber, senderEmail,
  senderCellPhone, senderAddress, collectionOrTakeToOffice, errorsList, setErrorsList,
) => {
  const temporalErrorsList = errorsList.slice();
  const listToValidate = [
    {
      value: senderName,
      errorName: 'senderName',
      errorText: 'Ingresa un nombre.',
    },
    {
      value: senderSurname,
      errorName: 'senderSurname',
      errorText: 'Ingresa un apellido.',
    },
    {
      value: senderIdentificationNumber,
      errorName: 'senderIdentificationNumber',
      errorText: 'Ingresa un número de identificación válido.',
    },
    {
      value: senderEmail,
      errorName: 'senderEmail',
      errorText: 'Ingresa un email válido.',
    },
    {
      value: senderCellPhone,
      errorName: 'senderCellPhone',
      errorText: 'Ingresa un número válido.',
    },
    {
      value: collectionOrTakeToOffice,
      errorName: 'collectionOrTakeToOffice',
      errorText: 'Selecciona dónde quiere que recibamos el artículo.',
    },
  ];

  listToValidate.forEach((item) => {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      item.errorName,
      item.errorText,
      validationValue(
        item.value,
        item.errorName === 'senderEmail'
          ? 'email'
          : item.errorName === 'senderCellPhone' && 'cellphone',
      ).correct ? 'remove' : 'insert',
    );
  });

  if (collectionOrTakeToOffice === 'collectionInAddress') {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'senderAddress',
      'Ingresa una dirección.',
      validationValue(senderAddress).correct ? 'remove' : 'insert',
    );
  } else {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'senderAddress',
      'Ingresa una dirección.',
      'remove',
    );
  }

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length === 0) return true;
  return false;
};

export const receiverDetailsValidation = (
  receiverName, receiverSurname, receiverIdentificationNumber, receiverEmail,
  receiverCellPhone, receiverAddress, deliveryInAddressOrOffice, errorsList, setErrorsList,
) => {
  const temporalErrorsList = errorsList.slice();
  const listToValidate = [
    {
      value: receiverName,
      errorName: 'receiverName',
      errorText: 'Ingresa un nombre.',
    },
    {
      value: receiverSurname,
      errorName: 'receiverSurname',
      errorText: 'Ingresa un apellido.',
    },
    {
      value: receiverEmail,
      errorName: 'receiverEmail',
      errorText: 'Ingresa un email válido.',
    },
    {
      value: receiverCellPhone,
      errorName: 'receiverCellPhone',
      errorText: 'Ingresa un número válido.',
    },
    {
      value: deliveryInAddressOrOffice,
      errorName: 'deliveryInAddressOrOffice',
      errorText: 'Selecciona dónde quieres que entreguemos el artículo.',
    },
  ];

  listToValidate.forEach((item) => {
    if (item.errorName === 'receiverEmail' && (!item.value || item.value === '' || item.value?.length <= 0)) {
      insertOrRemoveErrorFromList(
        temporalErrorsList,
        item.errorName,
        item.errorText,
        'remove',
      );
    } else {
      insertOrRemoveErrorFromList(
        temporalErrorsList,
        item.errorName,
        item.errorText,
        validationValue(
          item.value,
          item.errorName === 'receiverEmail'
            ? 'email'
            : item.errorName === 'receiverCellPhone' && 'cellphone',
        ).correct ? 'remove' : 'insert',
      );
    }
  });

  if (deliveryInAddressOrOffice === 'deliveryInAddress') {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'receiverAddress',
      'Ingresa una dirección.',
      validationValue(receiverAddress).correct ? 'remove' : 'insert',
    );
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'receiverIdentificationNumber',
      '',
      'remove',
    );
  } else {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'receiverIdentificationNumber',
      'Ingresa un número de documento.',
      validationValue(receiverIdentificationNumber).correct ? 'remove' : 'insert',
    );
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'receiverAddress',
      '',
      'remove',
    );
  }

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length === 0) return true;
  return false;
};

export const checkBankInformation = (accountBank) => {
  if (!accountBank) return true;
  if (!accountBank.bank) return true;
  if (!accountBank.beneficiaryName) return true;
  if (!accountBank.accountType) return true;
  if (!accountBank.accountNumber) return true;
  if (!accountBank.typeId) return true;
  if (!accountBank.numberId) return true;
  return false;
};
