import getGuidesFormatUser, { updateOrCreatePrintFormatService } from './service';

const defaultFormat = (formats) => (formats.find((format) => format.fault))?.value;

const existGuideSettings = async (
  deliveryCompaniesConfig, setCompaniesWithPrintFormat, setFormatSelected,
) => {
  const guideSettings = await getGuidesFormatUser();

  const companiesFormat = deliveryCompaniesConfig?.filter((company) => company.printFormat);
  const userGuidessttings = [];

  const allCompanies = companiesFormat.map((globalCompany) => {
    const deliveryPrintFormatSetting = {
      deliveryCompany: globalCompany.deliveryCompany,
      companyName: globalCompany.companyName,
      printFormat: globalCompany.printFormat,
    };

    const userSetting = guideSettings.find(
      (userCompany) => userCompany?.deliveryCompany === globalCompany.deliveryCompany);

    userGuidessttings.push({
      deliveryCompany: userSetting?.deliveryCompany || globalCompany.deliveryCompany,
      printFormat: userSetting?.printFormat
        || defaultFormat(globalCompany.printFormat),
    });

    return deliveryPrintFormatSetting;
  });

  setFormatSelected(userGuidessttings);
  setCompaniesWithPrintFormat(allCompanies);
};

export const updateOrCreatePrintFormat = async (
  newPrintFormat, currentFormats, setFormatSelected,
) => {
  try {
    await updateOrCreatePrintFormatService(newPrintFormat);
  } catch (error) {
    setFormatSelected(currentFormats);
  }
};

export default existGuideSettings;
