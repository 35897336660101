/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getUserLevelService, pointsCounterService } from '../../services/user';
import { addThousandPoint } from '../../utils/strings';
import { myDiscountsValidate } from '../../utils/role';

import ContentSection from '../contentSection';
import Typography from '../typography';

import IconIsoType from '../../assets/images/isotype-white.svg';
import IconDropUp from '../../assets/images/Dropup_black.svg';
import IconArrowBack from '../../assets/images/icon-arrow-back-color-primary.svg';

import './userLevel.scss';

const UserLevel = ({
  smallVersion, userId, className,
  onClick, forceUpdate, headerChildrenRigth, back, user,
}) => {
  const [loadingData, setLoadingData] = useState(true);
  const [level, setLevel] = useState(0);
  const [shippingAverage, setShippingAverage] = useState(0);
  const [maxShippingInRange, setMaxShippingInRange] = useState(0);
  const [points, setPoints] = useState(0);
  const [error, setError] = useState(false);

  const getUserLevelAndPoints = async () => {
    try {
      if (userId) {
        const responseUserLevel = await getUserLevelService(userId);
        const responsePoints = await pointsCounterService(userId);
        setLevel(responseUserLevel?.level ?? 0);
        setShippingAverage(responseUserLevel?.sendings ?? 0);
        setMaxShippingInRange(responseUserLevel?.rangesSendings?.max ?? 0);
        setPoints(parseInt(responsePoints?.points ?? 0, 10));
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => getUserLevelAndPoints(), [forceUpdate]);

  const calculatePercentage = () => {
    let progress = (shippingAverage / maxShippingInRange) * 100;
    if (progress === 0 || (shippingAverage === 0 || maxShippingInRange === 0)) progress = 1;
    return progress;
  };

  const ProgressLevelComponent = ({ bold }) => (
    <div className={`container-progress-level ${bold ? 'bold' : ''}`}>
      <div style={{ width: `${calculatePercentage().toString()}%` }} className="progress-level" />
    </div>
  );

  if (smallVersion && !error && !myDiscountsValidate(user)) {
    return (
      <div className="level-counter-small-version" onClick={() => onClick && onClick()}>
        <div className="top">
          <span>
            Nivel
            {' '}
            {level}
            {' '}
            -
            {' '}
            Puntos mipaquete
          </span>
          <img src={IconDropUp} alt="" />
        </div>
        <ProgressLevelComponent bold />
      </div>
    );
  } if (userId && !error && !myDiscountsValidate(user)) {
    return (
      <div onClick={() => onClick && onClick()} style={{ cursor: onClick ? 'pointer' : 'initial' }}>
        <ContentSection
          className={`level-counter ${className}`}
          headerChildrenLeft={(
            <Typography type={back ? 'superTitle-italic' : 'superTitle'} className="flex align-center">
              {back && <img src={IconArrowBack} alt="" onClick={back} />}
              <span className="hidden-in-mobile-mode">
                Estás en el
              </span>
              <span className="hidden-in-mobile-mode">nivel</span>
              <span className="visible-in-mobile-mode">Nivel</span>
              {!loadingData && level}
            </Typography>
          )}
          headerChildrenRigth={headerChildrenRigth || (
            <div className="container-children-rigth-level-counter">
              <Typography type="field" text="Tus puntos:" />
              {' '}
              <Typography type="value" text={!loadingData && addThousandPoint(points)} />
            </div>
          )}
        >
          <div className="content-level-counter">
            <div className="content-level-counter-top">
              <Typography type="field" text="¡Avancemos juntos!" className="title" />
              <div className="progress-in-level-container">
                <div className="container-icon-progress">
                  <div className="icon-progress" style={{ width: `${calculatePercentage().toString()}%` }}>
                    <img src={IconIsoType} alt="isotipo" />
                  </div>
                </div>
                <ProgressLevelComponent />
                <Typography type="paragraph">
                  Tu meta de envíos:
                  {' '}
                  <span>{shippingAverage}</span>
                  /
                  {maxShippingInRange}
                </Typography>
              </div>
            </div>
            <Typography
              type="paragraph"
              className="text-recomendation-level-counter"
            >
              Recuerda que tenemos dos fechas de vencimiento de tus puntos:
              {' '}
              <b>31/diciembre - 30/Junio</b>
              , te recomendamos redimirlos antes.
            </Typography>
          </div>
        </ContentSection>
      </div>
    );
  } return null;
};

UserLevel.propTypes = {
  smallVersion: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  forceUpdate: PropTypes.any,
  headerChildrenRigth: PropTypes.element,
  back: PropTypes.func,
};

UserLevel.defaultProps = {
  smallVersion: false,
  className: '',
  onClick: null,
  forceUpdate: null,
  headerChildrenRigth: null,
  back: null,
};

export default UserLevel;
