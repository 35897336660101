import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { toCurrencyCop } from 'utils/strings';

import './senderAmountValue.scss';

const SenderAmountValue = ({
  text,
  amount,
  icon,
  user,
}) => (
  <div className="sender-amount-container">
    <span className="sender-amount-container__text">{text}</span>
    <div className="sender-amount-container__price">
      <img src={icon} alt="" />
      <p>{toCurrencyCop(user, amount)}</p>
    </div>
  </div>
);

SenderAmountValue.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(SenderAmountValue);
