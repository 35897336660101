import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import swal from 'sweetalert';

import Modal from '../../../../components/modal';
import InputCode from '../../../../components/inputCode';
import Typography from '../../../../components/typography';
import ModalChildren from '../../../../components/modalChildren';

import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import './modals.scss';
import Button from '../../../../components/button';
import { createValidationCodesService, validateCodeService } from '../../../profileScreen/profile/service';

const createValidationCodes = () => {
  createValidationCodesService('accountBank');
};

const BankAccountModal = ({ setShowBankAccountModal, navigate, isMobileDevice }) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  useEffect(() => (createValidationCodes()), []);

  const validateCode = () => {
    if (code) {
      validateCodeService('accountBank', code)
        .then((validation) => {
          if (validation?.message?.toLowerCase() === 'ok') return navigate(`/actualizar-datos-bancarios/${code}`);
          return swal({ text: 'El código ingresado no coincide', buttons: 'Entendido' });
        })
        .catch(() => swal({ text: 'El código expiró por favor pedir esto' }))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal>
      <ModalChildren
        isMobileDevice={isMobileDevice}
        headerText="Ingresa tu código de verificación"
        close={() => setShowBankAccountModal(false)}
      >
        <div id="modal-bank-account">
          <div className="message-container">
            <Typography
              text="Sabemos que eres tú pero por motivos de seguridad necesitamos que ingreses el código que "
              type="field-gray-light"
              className="color-dark"
            />
            <Typography
              text="te llegó a tu celular o tu  tu correo."
              type="field-gray"
              className="color-dark f14"
            />
            <Typography
              text=" Si aún no te llega puedes solicitar"
              type="field-gray-light"
              className="color-dark"
            />
            <Typography
              text=" un nuevo código haciendo clic aquí."
              type="field-gray-light"
              className="hyper-link"
              onClick={() => createValidationCodes()}
            />
          </div>
          <div className="code-container">
            <InputCode
              type="number-text"
              value={code}
              onChange={setCode}
            />
          </div>
          <div className="button-container">
            <Button
              text="Verificar"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => { validateCode(); setLoading(true); }}
              disabled={code?.length < 6}
              loading={loading}
            />
            <Button
              text="Cancelar"
              type="secondary"
              onClick={() => { setShowBankAccountModal(false); setLoading(false); }}
            />
          </div>
        </div>
      </ModalChildren>
    </Modal>
  );
};

BankAccountModal.propTypes = {
  navigate: PropTypes.func.isRequired,
  setShowBankAccountModal: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

export default BankAccountModal;
