/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { finalPrice } from '../../../../utils/prices';
import { toCurrencyCop } from '../../../../utils/strings';
import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import ContentSection from '../../../../components/contentSection';
import Typography from '../../../../components/typography';
import Button from '../../../../components/button';

import './generatedSuccess.scss';

const GeneratedSuccess = ({
  dataShippingsSuccess, dataShippingsError,
  next, secondNext, isMobileDevice,
}) => {
  const user = useSelector((state) => state?.auth?.user || null);
  const finalPriceCalculator = (shipping) => finalPrice(
    shipping.deliveryCompanySelected.shippingCost,
    shipping.collectionService,
    shipping.addShippingToCollectionValue,
    shipping.deliveryCompanySelected.collectionCommissionWithRate,
    shipping.deliveryCompanySelected.collectionCommissionWithOutRate,
  );

  const totalToPayWithBalance = () => (
    dataShippingsSuccess.slice().filter((shipping) => (
      shipping.paymentMethod === 101
    )).map((shipping) => finalPriceCalculator(shipping)).reduce((a, b) => a + b, 0)
  );

  const totalToPayFromTheCollecion = () => (
    dataShippingsSuccess.slice().filter((shipping) => (
      shipping.paymentMethod === 102
    )).map((shipping) => finalPriceCalculator(shipping)).reduce((a, b) => a + b, 0)
  );

  const totalToPay = () => (
    dataShippingsSuccess.slice().map((shipping) => (
      finalPriceCalculator(shipping)
    )).reduce((a, b) => a + b, 0)
  );

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <ContentSection
      headerText={
        dataShippingsError.length > 0
          ? `${dataShippingsError.length} de ${dataShippingsError.length + dataShippingsSuccess.length} Envíos no fueron generados`
          : '¡Tu solicitud ha sido exitosa!'
      }
    >
      <div
        id="multiple-shippings__payment-success"
        className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}
      >
        <div className="details-prices-shippings">
          <Typography
            text="Resumen de solicitud"
            type="superTitle-italic"
            className="title-details-success"
          />
          {dataShippingsError.length > 0 && (
            <>
              <div className="container-field-value">
                <Typography
                  text="Solicita de nuevo los envíos de las siguientes filas:"
                  type="field"
                />
                <Typography
                  text={`${
                    dataShippingsError.map(
                      (shipping) => shipping.rowNum + 1,
                    ).sort(
                      (a, b) => (a <= b ? -1 : 1),
                    ).join(', ').replace(/, ([^,]*)$/, ' y $1')}.`}
                  type="value"
                />
              </div>

              <hr />
            </>
          )}

          <div className="container-field-value">
            <Typography
              text="Valor pagado con saldo:"
              type="field"
            />
            <Typography
              text={toCurrencyCop(user, totalToPayWithBalance())}
              type="value"
            />
          </div>
          <div className="container-field-value">
            <Typography
              text="Valor pagado con recaudo:"
              type="field"
            />
            <Typography
              text={toCurrencyCop(user, totalToPayFromTheCollecion())}
              type="value"
            />
          </div>
          <div className="container-field-value">
            <Typography
              text="Valor total de los envíos:"
              type="field"
            />
            <Typography
              text={toCurrencyCop(user, totalToPay())}
              type="value"
            />
          </div>
        </div>
        <div className="buttons">
          <Button
            text="Ir a descargar tu guía"
            type={isCountryValid ? 'primary-mx' : 'primary'}
            onClick={() => next()}
          />
          <Button
            text="Realiza otro envío"
            type="secondary"
            onClick={() => secondNext()}
          />
        </div>
      </div>
    </ContentSection>
  );
};

GeneratedSuccess.propTypes = {
  next: PropTypes.func.isRequired,
  secondNext: PropTypes.func.isRequired,
  dataShippingsSuccess: PropTypes.arrayOf(PropTypes.any).isRequired,
  dataShippingsError: PropTypes.arrayOf(PropTypes.any).isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

export default GeneratedSuccess;
