/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { capitalizeWords, toCurrencyCop } from '../../../../../utils/strings';
import { finalPrice } from '../../../../../utils/prices';
import { findIndexOfObject } from '../../../../../utils/filters';
import { useValidationCountry, useFetchIpData } from '../../../../../utils/validationCountry';

import ContentSection from '../../../../../components/contentSection';
import Typography from '../../../../../components/typography';
import Button from '../../../../../components/button';

import ArrowBack from '../../../../../assets/images/icon-arrow-back-color-primary.svg';

import './paymentMultipleShippings.scss';
import NotificationsSettings from '../../../../../components/notificationsSettings/notificationsSettings';
import { showDependingOnBusinessFlag } from '../../../../../utils/businessFlag';
import BalanceErrorInNotifications from '../../../../../components/balanceErrorInNotifications';
import calculateToNotificationsPrice from '../../../../../utils/calculateTonotificationsPrice';
import { typeDelivery } from '../../../../../constants/general';

const PaymentMultipleShippings = ({
  next, back, dataShippingsToPay, currentBalance, user,
  loadingSendingGenerating, rechargeBalance, isMobileDevice,
  onChange, businessFlags,
}) => {
  const [errorBalanceInNotification, setErrorBalanceInNotification] = useState(false);
  const [totalPriceNotifications, seTotalPriceNotifications] = useState(0);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const priceRealCostCalculator = (value) => finalPrice(
    value.deliveryCompanySelected.shippingRealCost,
    value.collectionService,
    value.addShippingToCollectionValue,
    value.deliveryCompanySelected.collectionCommissionWithRate,
    value.deliveryCompanySelected.collectionCommissionWithOutRate,
  );

  const finalPriceCalculator = (shipping) => finalPrice(
    shipping.deliveryCompanySelected.shippingCost,
    shipping.collectionService,
    shipping.addShippingToCollectionValue,
    shipping.deliveryCompanySelected.collectionCommissionWithRate,
    shipping.deliveryCompanySelected.collectionCommissionWithOutRate,
  );

  const totalToPayWithBalance = () => (
    dataShippingsToPay.slice().filter((shipping) => (
      shipping.paymentMethod === 101
    )).map((shipping) => finalPriceCalculator(shipping)).reduce((a, b) => a + b, 0)
  );

  const totalToPayFromTheCollecion = () => (
    dataShippingsToPay.slice().filter((shipping) => (
      shipping.paymentMethod === 102
    )).map((shipping) => finalPriceCalculator(shipping)).reduce((a, b) => a + b, 0)
  );

  const totalToPayFromCredit = () => (
    dataShippingsToPay.slice().filter((shipping) => (
      shipping.paymentMethod === 105
    )).map((shipping) => finalPriceCalculator(shipping)).reduce((a, b) => a + b, 0)
  );

  const totalToPay = () => (
    dataShippingsToPay.slice().map((shipping) => (
      finalPriceCalculator(shipping)
    )).reduce((a, b) => a + b, 0)
  );

  const shippingsPerDeliveryCompany = () => {
    const deliveryCompanies = [];
    dataShippingsToPay.slice().forEach((shipping) => {
      const indexToInsert = findIndexOfObject(shipping.deliveryCompanySelected.deliveryCompanyId, deliveryCompanies, 'deliveryCompanyId');
      if (indexToInsert >= 0) {
        deliveryCompanies[indexToInsert].numberOfShippings += 1;
        deliveryCompanies[indexToInsert].sendingValue += finalPriceCalculator(shipping);
        // eslint-disable-next-line max-len
        if (shipping.deliveryCompanySelected.shippingRealCost) { deliveryCompanies[indexToInsert].realCost += priceRealCostCalculator(shipping); }
      } else {
        deliveryCompanies.push({
          deliveryCompanyId: shipping.deliveryCompanySelected.deliveryCompanyId,
          deliveryCompanyImgUrl: shipping.deliveryCompanySelected.deliveryCompanyImgUrl,
          deliveryCompanyName: shipping.deliveryCompanySelected.deliveryCompanyName,
          numberOfShippings: 1,
          sendingValue: finalPriceCalculator(shipping),
          // eslint-disable-next-line max-len
          ...shipping.deliveryCompanySelected.shippingRealCost && { realCost: priceRealCostCalculator(shipping) },
        });
      }
    });
    return deliveryCompanies;
  };

  const contentDataToPayment = () => (
    <>
      <div className="shipping-details__payment">
        <Typography type="field-dark-gray" text="Cantidad de envíos solicitados:" />
        <Typography type="field-gray-light" text={dataShippingsToPay.length} />
      </div>
      <div className="shipping-details__payment">
        <Typography type="field-dark-gray" text="Valor a pagar con saldo:" />
        <Typography type="field-gray-light" text={toCurrencyCop(user, totalToPayWithBalance())} />
      </div>
      <div className="shipping-details__payment">
        <Typography type="field-dark-gray" text="Valor a pagar con recaudo:" />
        <Typography type="field-gray-light" text={toCurrencyCop(user, totalToPayFromTheCollecion())} />
      </div>
      {(user.creditPayment) && (
        <div className="shipping-details__payment">
          <Typography type="field-dark-gray" text="Valor a pagar con credito:" />
          <Typography type="field-gray-light" text={toCurrencyCop(user, totalToPayFromCredit())} />
        </div>
      )}
      {showDependingOnBusinessFlag({ businessFlags, section: 'notificationsSettings' }) && (
        <div className="shipping-details__payment">
          <Typography type="field-dark-gray" text="Valor a pagar por Notificaciones:" />
          <Typography type="field-gray-light" text={toCurrencyCop(user, totalPriceNotifications)} />
        </div>
      )}
      <div className="shipping-details__payment">
        <Typography type="field-dark-gray" text="Valor total de los envíos:" />
        <Typography type="field-gray-light" text={toCurrencyCop(user, totalToPay() + totalPriceNotifications)} />
      </div>
    </>
  );

  return (
    <ContentSection
      headerText="Paga tu envío"
      headerChildrenLeft={(
        <div className="container-arrow-back">
          <img
            src={ArrowBack}
            alt="Arrow back"
            onClick={() => back()}
            className="arrow-back-payment-multiple"
          />
        </div>

      )}
    >
      <div id="multiple-shippings__payment" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
        <div className="delivery-companies-list-and-details-container">
          <table className="general-table delivery-companies-list-and-details">
            {!isMobileDevice && (
              <thead>
                <tr>
                  <th className="title-th">
                    {capitalizeWords(typeDelivery[user.countryCode])}
                    s
                  </th>
                  <th className="title-th">Cantidad de envíos</th>
                  <th className="title-th">Valor de los envíos</th>
                </tr>
              </thead>
            )}
            <tbody>
              {shippingsPerDeliveryCompany().map((item) => (
                <tr key={item.deliveryCompanyId}>
                  <td>
                    <div className="delivery-company">
                      <img src={item.deliveryCompanyImgUrl} alt="logo" />
                      <span>{item.deliveryCompanyName}</span>
                    </div>
                  </td>
                  <td>{item.numberOfShippings}</td>
                  <td>
                    <div className="prices">
                      <td className={item.realCost ? 'value' : null}>{toCurrencyCop(user, Math.floor(item.sendingValue))}</td>
                      {!Number.isNaN(Number(item.realCost)) && (<td className="real-cost">{toCurrencyCop(user, Math.floor(item.realCost))}</td>)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="container__content-data-to-payment">
            {contentDataToPayment()}
            {showDependingOnBusinessFlag({ businessFlags, section: 'notificationsSettings' })
              && (
                <div className="notifications-settings">
                  <NotificationsSettings onChange={(notifications) => {
                    const shippingInfo = {
                      deliverySelected: {
                        negotiation: false,
                      },
                    };
                    const response = calculateToNotificationsPrice(notifications, shippingInfo, 'mp');
                    seTotalPriceNotifications(response.sumWithInitial * dataShippingsToPay.length);
                    onChange(notifications);
                  }}
                  />
                </div>
              )}
          </div>
        </div>
        <div className="cash">
          <div>
            <div className="info">
              <div className="label">Tu saldo actual:</div>
              <div className={`value ${(totalToPay() + totalPriceNotifications) > currentBalance ? 'red' : 'blue'}`}>
                {toCurrencyCop(user, currentBalance)}
              </div>
            </div>
            <Button
              type="secondary"
              onClick={() => rechargeBalance()}
              text="Recargar saldo"
            />
          </div>
          <Button
            type={isCountryValid ? 'primary-mx' : 'primary'}
            onClick={() => {
              if ((totalToPayWithBalance() + totalPriceNotifications) <= currentBalance) {
                next();
              } else if (
                (totalToPayFromCredit() + totalPriceNotifications) <= user.creditLimitCop
              ) {
                next();
              }
            }}
            className="flotant-button__payment-multiple-shippings"
            loading={loadingSendingGenerating}
            text="Pagar ahora"
            disabled={((totalToPayWithBalance() + totalPriceNotifications) > currentBalance)
              && ((totalToPayFromCredit() + totalPriceNotifications) > user.creditLimitCop)}
          />
        </div>

      </div>
      {errorBalanceInNotification && (
        <BalanceErrorInNotifications
          onChangeBack={() => setErrorBalanceInNotification(!errorBalanceInNotification)}
          countryCode={user.countryCode}
        />
      )}
    </ContentSection>
  );
};

PaymentMultipleShippings.propTypes = {
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  dataShippingsToPay: PropTypes.any.isRequired,
  currentBalance: PropTypes.number.isRequired,
  loadingSendingGenerating: PropTypes.bool.isRequired,
  rechargeBalance: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  businessFlags: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  businessFlags: state.globalData.businessFlags || [],
});

export default compose(
  connect(mapStateToProps),
)(PaymentMultipleShippings);
