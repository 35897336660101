/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import getExcel from './shippingHistory/getExcel';
import { findIndexOfObject } from '../../../utils/filters';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';
import {
  existanceOfGuide, downloadShippingGuidesFunction,
} from './shippingHistory/utils';

import Filters from '../../../components/filters';
import Button from '../../../components/button';
import Pagination from '../../../components/pagination';
import ContentTop from './shippingHistory/contentTop/contentTop';
import MobileTable from './shippingHistory/mobileTable/mobileTable';
import DesktopTable from './shippingHistory/desktopTable/desktopTable';
import ContentSection from '../../../components/contentSection';
import ToggleMenu from '../../../components/toggleMenu';

import ArrowReload from '../../../assets/images/icon-reload-color-primary.svg';
import Search from '../../../assets/images/Icon-feather-search-primary-color.svg';
import iconFeatherDownload from '../../../assets/images/Icon-feather-download-line.svg';

import buildFilters from './common/buildObjects';

import './shippingHistory.scss';
import { getShipments } from '../../../components/customHooks/getSendings';

const ShippingHistory = () => {
  const { user } = useSelector((state) => state.auth);
  const deliveryCompanies = useSelector((state) => state.globalData.deliveryCompanies);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const navigate = useNavigate();

  const [shippingList, setShippingList] = useState([]);
  const [loadingShippings, setLoadingShippings] = useState(true);
  const [executingDownload, setExecutingDownload] = useState(false);
  const [shippingSelecteds, setShippingSelecteds] = useState([]);
  const [shippingSelected, setShippingSelected] = useState(null);
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [download, setDownload] = useState(false);

  const [numberItemsPerPage, setNumberItemsPerPage] = useState(25);
  const [numberAllItems, setNumberAllItems] = useState(0);

  const [page, setPage] = useState(1);
  const [nextOrBack, setNextOrBack] = useState(null);
  const [lastDocumentId, setLastDocumentId] = useState(null);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const getShippingsFunction = async () => {
    const temporalShippingList = shippingList.slice();
    const filterAndPage = {};
    const mp = parseInt(filters?.mpCode || 0, 10) > 0 ? parseInt(filters.mpCode, 10) : null;

    buildFilters({
      filterAndPage, filters, lastDocumentId, mp, nextOrBack, numberItemsPerPage,
    });

    const responseShippings = await getShipments({ ...filterAndPage });

    if (isMobileDevice && page > 1) {
      temporalShippingList.push(...responseShippings.shipments);
      setShippingList(temporalShippingList);
    } else {
      setLoadingShippings(false);
      setShippingList(responseShippings.shipments);
    }

    setNumberAllItems(responseShippings.totalDocuments);
  };

  useEffect(() => {
    if (shippingSelected) navigate(`/detalle-envio/${shippingSelected}`);
  }, [shippingSelected]);

  useEffect(async () => {
    if (loadingShippings && user && !download) {
      try {
        await getShippingsFunction();
      } catch {
        setShippingList([]);
      } finally {
        setLoadingShippings(false);
      }
    } else if (loadingShippings && download && user) {
      getExcel(filters, user, setDownload);
      setLoadingShippings(false);
    }
  }, [loadingShippings, user]);

  useEffect(() => setLoadingShippings(true), [page, numberItemsPerPage]);

  const setShippingSelectedsFunction = (shippingId) => {
    const temporalShippingSelecteds = shippingSelecteds.slice();
    const index = findIndexOfObject(shippingId, temporalShippingSelecteds);
    if (index < 0) {
      temporalShippingSelecteds.push(shippingId);
    } else {
      temporalShippingSelecteds.splice(index, 1);
    }
    setShippingSelecteds(temporalShippingSelecteds);
  };

  const findAllIntoShippingSelecteds = () => {
    const array1 = [];
    shippingList.forEach((shipping) => (
      existanceOfGuide(shipping.pdfGuide) && array1.push(shipping._id)
    ));
    array1.sort((a, b) => {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
    const array2 = shippingSelecteds.sort();
    return (
      array1.length > 0
      && array1.length === array2.length
      && array1.every((v, i) => v === array2[i])
    );
  };

  const addOrRemoveAllShippingsToSelecteds = () => {
    const temporalList = [];
    shippingList.forEach((shipping) => (
      existanceOfGuide(shipping.pdfGuide) && temporalList.push(shipping._id)
    ));
    setShippingSelecteds(temporalList);
    if (findAllIntoShippingSelecteds()) {
      setShippingSelecteds([]);
    }
  };

  const downloadShippingGuides = async (shippingId, pdfConfirmation, erroConfirmationFunction) => {
    setExecutingDownload(true);
    downloadShippingGuidesFunction(
      shippingId, shippingSelecteds, shippingList, pdfConfirmation)
      .catch(() => { if (pdfConfirmation && erroConfirmationFunction) erroConfirmationFunction(); })
      .finally(() => setExecutingDownload(false));
  };

  const refreshShipmentList = () => {
    setNextOrBack(null);
    setLastDocumentId(null);
    setFilters({ ...filters, states: [] });
    setPage(1);
    setLoadingShippings(true);
  };

  // eslint-disable-next-line react/prop-types
  const ButtonReloadShippingList = ({ noShow }) => (
    <Button
      className={`updateShippingList ${noShow ? 'noShow' : ''}`}
      type="secondary"
      onClick={() => refreshShipmentList()}
    >
      <img src={ArrowReload} alt="reload" />
    </Button>
  );

  const toogleOptions = [
    { label: 'Refrescar envíos', image: ArrowReload, value: 'Refresh' },
    { label: 'Filtrar y buscar', image: Search, value: 'Search' },
    { label: 'Descargar informe', image: iconFeatherDownload, value: 'Download' },
  ];

  const selectToggleOption = (option) => {
    const functionOptions = {
      Search: () => setShowFilters(true),
      Download: () => { setDownload(true); setShowFilters(true); },
      Refresh: () => refreshShipmentList(),
    };

    functionOptions[option]();
  };

  const changeFilters = (filterPagination) => {
    setNumberItemsPerPage(filterPagination.itemsPerPage);

    if (filterPagination.actualPage !== 1 && filterPagination.actualPage > page) {
      const lastShipment = shippingList[shippingList.length - 1]?._id;
      setLastDocumentId(lastShipment);
      setNextOrBack('next');
    }

    if (filterPagination.actualPage !== 1 && filterPagination.actualPage < page) {
      const lastShipment = shippingList[0]?._id;
      setNextOrBack('back');
      setLastDocumentId(lastShipment);
    }

    if (filterPagination.sort === 1) {
      const newList = [...shippingList];
      newList.sort((a, b) => new Date(a.confirmationDate) - new Date(b.confirmationDate));
      return setShippingList(newList);
    }

    if (filterPagination.actualPage === 1 && filterPagination.sort !== 1) {
      setLastDocumentId(null); setNextOrBack(null);
    }

    setPage(filterPagination.actualPage);
    return setLoadingShippings(true);
  };

  const ChildrenRigthElement = () => {
    const itemsFilter = {
      itemsPerPage: numberItemsPerPage,
      actualPage: page,
    };

    return !isMobileDevice ? (
      <>
        <ButtonReloadShippingList />
        <Pagination
          filters={itemsFilter}
          totalItems={numberAllItems}
          handleChangeFilters={changeFilters}
          disabledLoading={loadingShippings}
        />
      </>
    ) : (
      <ToggleMenu
        optionsMenu={toogleOptions}
        onClick={selectToggleOption}
      />
    );
  };

  const showMoreMobile = () => {
    const filterPagination = {
      itemsPerPage: numberItemsPerPage,
      actualPage: page + 1,
    };
    changeFilters(filterPagination);
  };

  const shippingHistoryTitle = () => `Historial ${isMobileDevice ? '' : 'de envíos'}`;

  return (
    <>
      <ContentSection
        className={
          `container-shipping-history-and-quotes
          ${isMobileDevice ? 'mobile-container-header' : ''}
          `
        }
        headerTextAlign="center"
        headerText={shippingHistoryTitle()}
        headerChildrenRigth={<ChildrenRigthElement />}
        headerChildrenLeft={<ButtonReloadShippingList noShow />}
      >
        <div id="container-shipping-history" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
          <ContentTop
            isMobileDevice={isMobileDevice}
            shippingList={shippingList}
            handleVisibilityFiltersModal={setShowFilters}
            triggerDownloadReport={setDownload}
            numberItemsPerPage={numberItemsPerPage}
            handleChangeNumberItemsPerPage={setNumberItemsPerPage}
            numberAllItems={numberAllItems}
            currentPage={page}
          />
          <div className="container-list__shipping-history">
            {isMobileDevice ? (
              <MobileTable
                shippingList={shippingList}
                shippingSelecteds={shippingSelecteds}
                handleChangeShippingSelected={setShippingSelected}
                numberAllItems={numberAllItems}
                handleChangeCurrentPage={showMoreMobile}
                loadingShippings={loadingShippings}
                currentPage={page}
                goToQuoteShipping={() => navigate('/cotizacion')}
                downloadShippingGuides={downloadShippingGuides}
                handleChangeShippingSelecteds={setShippingSelectedsFunction}
              />
            ) : (
              <DesktopTable
                shippingList={shippingList}
                shippingSelecteds={shippingSelecteds}
                handleChangeShippingSelected={setShippingSelected}
                findAllIntoShippingSelecteds={findAllIntoShippingSelecteds}
                loadingShippings={loadingShippings}
                goToQuoteShipping={() => navigate('/cotizacion')}
                downloadShippingGuides={downloadShippingGuides}
                handleChangeShippingSelecteds={setShippingSelectedsFunction}
                deliveryCompanies={deliveryCompanies}
                addOrRemoveAllShippingsToSelecteds={addOrRemoveAllShippingsToSelecteds}
              />
            )}
            {shippingSelecteds.length > 0 && (
            <Button
              type={isCountryValid ? 'primary-mx' : 'primary'}
              text={`Descargar (${shippingSelecteds.length})`}
              className="button-download__shipping-history"
              onClick={() => downloadShippingGuides()}
              loading={executingDownload}
            />
            )}
          </div>
        </div>
      </ContentSection>

      {showFilters && (
        <Filters
          next={() => {
            if (!download) {
              setShippingList([]);
            }
            setLoadingShippings(true);
            setShowFilters(false);
          }}
          download={download}
          setFilters={setFilters}
          filters={filters}
          cancel={() => {
            setShowFilters(false);
            setDownload(false);
          }}
        />
      )}
    </>
  );
};

export default ShippingHistory;
