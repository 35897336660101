/* eslint-disable max-len */
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { connect, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Process } from '../enums';
import createAndUpdateNegotiationService from './service';

import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';
import { typeDelivery } from '../../../../constants/general';
import { capitalizeWords } from '../../../../utils/strings';

import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import Typography from '../../../../components/typography';
import ToogleSwitch from '../../../../components/toggleSwitch';

import linkImage from '../../../../assets/images/Link.png';
import IconArrowBack from '../../../../assets/images/icon-arrow-back-color-primary.svg';

import './steps.scss';

const constants = {
  message: 'MESSAGE',
  package: 'PACKAGE',
};

const initSwitches = (transportersWithNegotiation) => {
  if (transportersWithNegotiation?.length <= 0) return [];

  return transportersWithNegotiation && transportersWithNegotiation.map((delivery) => ({
    company: delivery.deliveryCompanyId,
    package: delivery.packageEnabled,
    message: delivery.messengerEnabled,
  }));
};

const switchesHandler = (switches, company, type, setSwitches, updateUser) => {
  const prevSwiches = switches.slice();
  const newSwitches = switches.map((delivery) => {
    if (delivery.company === company) {
      return {
        company: delivery.company,
        package: type === constants.package ? !delivery.package : delivery.package,
        message: type === constants.message ? !delivery.message : delivery.message,
      };
    }
    return {
      company: delivery.company,
      package: delivery.package,
      message: delivery.message,
    };
  });
  setSwitches(newSwitches);

  const companySelected = switches.find((delivery) => delivery.company === company);
  const negotiation = {
    deliveryCompany: company,
    dataToUpdate: {
      ...type === constants.message && { messengerEnabled: !companySelected.message },
      ...type === constants.package && { packageEnabled: !companySelected.package },
    },
  };

  createAndUpdateNegotiationService(negotiation)
    .then((userNegotiation) => {
      if (userNegotiation?.email) {
        updateUser(userNegotiation);
      } else {
        setSwitches(prevSwiches);
      }
    }).catch(() => setSwitches(prevSwiches));
};

const NegotiationsList = ({
  object, setObject, updateUser,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { transportersWithNegotiation } = user;

  const [switches, setSwitches] = useState(initSwitches(transportersWithNegotiation));
  const [selectedTypeOnClik, setSelectedTypeOnClik] = useState({});
  const [objectSwitchHandler, setObjectSwitchHandler] = useState(null);
  const [showModalSwitch, setShowModalSwitch] = useState(false);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const goToDetail = (e, company) => {
    e.stopPropagation();
    const supportObject = { ...object, deliveryToDetail: company };
    setObject(supportObject);
    object.setCurrentStep(Process.DetailNegotiation);
  };

  const onOffSwitches = (e, company, type) => {
    e.stopPropagation();
    setObjectSwitchHandler({ company, type });
    const selected = switches.find((shift) => shift.company === company.deliveryCompanyId);
    const typeOnClik = type === constants.message ? selected.message : selected.package;
    setSelectedTypeOnClik(!typeOnClik);
    setShowModalSwitch(true);
  };

  return (
    <div className="container-bargainings">
      <div className="principal-header">
        <img src={IconArrowBack} alt="atras" className="arrow-back" onClick={() => object.history.goBack()} />
        <Typography
          type="superTitle-italic"
          text="Tus negociaciones"
        />
      </div>
      <div className={`content-bargainings ${!transportersWithNegotiation?.length > 0 ? 'empty' : ''}`}>
        <div className={`wrapper one ${!transportersWithNegotiation?.length > 0 ? 'empty' : ''}`}>
          {(transportersWithNegotiation?.length <= 0) ? (
            <>
              <Typography
                text={`Aquí se listarán las ${typeDelivery[user.countryCode]}s vinculadas con las que tienes negociaciones, para realizar tus envíos.`}
                type="field-gray"
                className="custom-empty-state-title"
              />
              <img src={linkImage} alt="no bargainings" className="empty-image" />
              <Typography
                text={`Aún no vinculas las ${typeDelivery[user.countryCode]}s con las que tienes negociaciones.`}
                type="field-gray"
                className="custom-empty-state-title"
              />
            </>
          ) : (
            <>
              <div className="delivery-companies-list desktop">
                <Typography
                  text={`Aquí se listan las ${typeDelivery[user.countryCode]}s vinculadas con las que tienes negociaciones, para realizar tus envíos.`}
                  type="field-gray"
                  className="custom-empty-state-title"
                />
                <div className="bargainings-list">
                  <ul className="header-list">
                    <li className="one">
                      <Typography
                        text={capitalizeWords(typeDelivery[user.countryCode])}
                        type="field-gray"
                        className="custom-empty-state-title"
                      />
                    </li>
                    <li className="second">
                      <Typography
                        text="Mensajería"
                        type="field-gray"
                        className="custom-empty-state-title"
                      />
                    </li>
                    <li className="third">
                      <Typography
                        text="Paquetería"
                        type="field-gray"
                        className="custom-empty-state-title"
                      />
                    </li>
                    <li className="quarter" />
                  </ul>
                  {transportersWithNegotiation && transportersWithNegotiation.map((company, index) => (
                    <ul
                      className="body-list"
                      key={(index + 1).toString()}
                      onClick={(e) => goToDetail(e, company)}
                    >
                      <li className="one">
                        <img src={object.deliveryCompanies?.find((delivery) => delivery._id === company.deliveryCompanyId)?.image} alt="companyImage" className="company-image" />
                        <Typography
                          text={company.deliveryCompanyName}
                          type="field-gray"
                          className="custom-empty-state-title"
                        />
                      </li>
                      <li className="second">
                        <ToogleSwitch
                          checked={switches.find((delivery) => delivery.company === company.deliveryCompanyId).message}
                          onChange={(e) => onOffSwitches(e, company, constants.message)}
                        />
                      </li>
                      <li className="third">
                        <ToogleSwitch
                          checked={switches.find((delivery) => delivery.company === company.deliveryCompanyId).package}
                          onChange={(e) => onOffSwitches(e, company, constants.package)}
                        />
                      </li>
                      <li className="quarter">
                        <Button
                          text="Ver vínculo"
                          type="tertiary"
                          onClick={(e) => goToDetail(e, company)}
                          className="size-link-button"
                        />
                      </li>
                    </ul>
                  ))}
                </div>
              </div>
              <div className="delivery-companies-list mobile">
                <Typography
                  text={`Aquí se listan las ${typeDelivery[user.countryCode]}s vinculadas con las que tienes negociaciones, para realizar tus envíos.`}
                  type="field-gray"
                  className="custom-empty-state-title"
                />
                <div className="bargainings-list">
                  {transportersWithNegotiation && transportersWithNegotiation.map((company, index) => (
                    <div
                      key={(index + 1).toString()}
                      onClick={(e) => goToDetail(e, company)}
                      className="negotiation-container"
                    >
                      <div className="image-and-title">
                        <img src={object.deliveryCompanies?.find((delivery) => delivery._id === company.deliveryCompanyId)?.image} alt="companyImage" className="company-image" />
                        <Typography
                          text={company.deliveryCompanyName}
                          type="field-gray"
                          className="custom-empty-state-title"
                        />
                      </div>
                      <div className="messager-and-package-container">
                        <div className="messager-and-package">
                          <Typography
                            text="Mensajería"
                            type="field-gray"
                            className="switches-title"
                          />
                          <ToogleSwitch
                            checked={switches.find((delivery) => delivery.company === company.deliveryCompanyId).message}
                            onChange={(e) => onOffSwitches(e, company, constants.message)}
                          />
                        </div>
                        <div className="messager-and-package">
                          <Typography
                            text="Paquetería"
                            type="field-gray"
                            className="switches-title"
                          />
                          <ToogleSwitch
                            checked={switches.find((delivery) => delivery.company === company.deliveryCompanyId).package}
                            onChange={(e) => onOffSwitches(e, company, constants.package)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        {(transportersWithNegotiation?.length !== (object.deliveryCompanies?.length) || transportersWithNegotiation?.length) && (
          <div className="container-buttons">
            <Button
              text={`Vincular ${typeDelivery[user.countryCode]}`}
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => object.setCurrentStep(Process.NoNegotiantionList)}
            />
          </div>
        )}
      </div>
      {showModalSwitch && objectSwitchHandler && (
        <Modal>
          <div className="enable-negotiation-modal">
            <div className="header">
              <Typography
                text={
                  `Se ${selectedTypeOnClik ? 'activará' : 'desactivará'}  vínculo ${objectSwitchHandler.type === constants.message ? 'Mensajería' : 'Paquetería'}`
                }
                type="superTitle-italic"
                className="title-header"
              />
              <div className="close-wrapper" onClick={() => { setObjectSwitchHandler(null); setShowModalSwitch(false); }}>
                <div className="close" />
              </div>
            </div>
            <div className="description">
              <Typography
                text={
                  `${selectedTypeOnClik
                    ? 'Al activar el vínculo el cobro será por transacción y no por envío en el caso de los envíos'
                    : 'Al desactivar el vínculo el cobro será por envío y no por transacción en el caso de los envíos'}
                  ${objectSwitchHandler.type === constants.message ? 'Mensajería' : 'Paquetería'}
                  con ${objectSwitchHandler.company.deliveryCompanyName} `
                }
                type="value"
              />
            </div>
            <div className="container-buttons">
              <Button
                text="Conservar vínculo"
                type="secondary"
                onClick={() => { setObjectSwitchHandler(null); setShowModalSwitch(false); }}
              />
              <Button
                text={`${selectedTypeOnClik ? 'Activar' : 'Desactivar'} vínculo`}
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={() => {
                  switchesHandler(switches, objectSwitchHandler.company.deliveryCompanyId, objectSwitchHandler.type, setSwitches, updateUser);
                  setShowModalSwitch(false);
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

NegotiationsList.propTypes = {
  object: PropTypes.shape().isRequired,
  setObject: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(NegotiationsList);
