/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useNavigate } from 'react-router-dom';

import ToggleSwitch from '../../../components/toggleSwitch';

import Typography from '../../../components/typography';
import './switchStateDeliveryCompany.scss';

const SwitchStateDeliveryCompany = ({
  text, active, isMobileDevice, onChange, image, textDescription,
}) => {
  const navigate = useNavigate(); // Mover la declaración de navigate aquí

  const history = {
    goBack: () => (navigate(-1)),
    push: (url) => (navigate(url)),
  };

  return (
    <>
      <div className={`container-state-switch ${isMobileDevice ? 'mobile-mode' : ''}`}>
        <div className={`container-image ${!isMobileDevice ? 'desktop-mode' : ''}`}>
          <img src={image} className="delivery-company-image" />
          <Typography
            type="superTitle"
            text={text}
            className="text-state-switch hebboMedium"
          />
          <div className="container-description">
            <span onClick={() => { history.push('/mis-direcciones'); }}>{textDescription}</span>
          </div>
        </div>
        <ToggleSwitch
          checked={active}
          onChange={(e) => onChange(e)}
          customClassCheckedText="custom-ToggleSwitch"
          customcClassFirstSpan="custom-ToggleSwitch"
        />
      </div>
    </>
  );
};

SwitchStateDeliveryCompany.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  textDescription: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(SwitchStateDeliveryCompany);
