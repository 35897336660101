/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dataIp from '../services/ipapi';

export const useFetchIpData = () => {
  const [ipData, setIpData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await dataIp();
      setIpData(result);
    };
    fetchData();
  }, []);
  return ipData;
};

export const useValidationCountry = (countryCode) => {
  const user = useSelector((state) => state.auth.user);
  const validateDefaultCountry = !user;
  const validateCountryCodeUser = user ? user.countryCode : undefined;

  if (validateDefaultCountry) {
    return false;
  }

  if (validateCountryCodeUser !== '170' || countryCode !== 'CO') {
    return true;
  }

  return !!(countryCode !== 'CO' && user);
};
