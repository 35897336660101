/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import Modal from '../../../components/modal';
import Input from '../../../components/input';
import Button from '../../../components/button';
import CheckBox from '../../../components/checkBox';
import Loader from '../../../components/loaderList';
import Typography from '../../../components/typography';
import WompyPayment from './wompy';

import ELP_MESSAGES from '../../../constants/messages';

import { findObjectInArray } from '../../../utils/filters';
import { valueAndTermsValidation } from './validations';
import crendetialsTuCompra from '../../../constants/tuCompara';
import { UpdateUserAction } from '../../../store/actions/auth.actions';
import { showDependingOnBusinessFlag } from '../../../utils/businessFlag';
import { ReactComponent as ArrowBack } from '../../../assets/images/icon-arrow-back-color-primary.svg';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import pseIcon from '../../../assets/images/pse_icon.png';
import IconCash from '../../../assets/images/attach_money.svg';

import paymentFunction from './commons/paymentFunction';
import PSE from './mathodPayments/pse';
import CardData from './mathodPayments/cardData';
import ReceiptOfPayment from './receiptOfPayment';

import './rechargeBalance.scss';

const RechargeBalance = ({
  setclassName,
  setShowRecharBalanceScreen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const businessFlags = useSelector((state) => state.globalData.businessFlags || []);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const [errorsList, setErrorsList] = useState([]);
  const [userUpdated, setUserUpdated] = useState(null);
  const [elpResponse, setElpResponse] = useState(null);
  const [errorMessage, setErrrorMessage] = useState(null);
  const [messageModal, setMessageModal] = useState(ELP_MESSAGES.processing);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);
  const [balanceToRecharge, setBalanceToRecharge] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [termsAndConditionsError] = useState(false);
  const [showReceipt, setShowPreceipt] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setErrorModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const TACUrl = 'https://mipaquete.com/politicas-privacidad-de-datos-terminos-y-condiciones/';

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  useEffect(() => (
    balanceToRecharge >= 100 ? setButtonEnabled(true) : setButtonEnabled(false)
  ), [balanceToRecharge]);

  useEffect(() => {
    if (paymentMethodSelected === 'card') setclassName('screen-extend');
    if (!paymentMethodSelected || paymentMethodSelected === 'pse') setclassName('');
  }, [paymentMethodSelected]);

  useEffect(() => (
    userUpdated !== null && dispatch(UpdateUserAction(userUpdated))
  ), [userUpdated]);

  const handlerPaymentFunction = async (data, paymentMethod) => {
    try {
      setShowModal(true);
      setMessageModal(ELP_MESSAGES.processing);
      const result = await paymentFunction(data, paymentMethod);
      if (paymentMethod === 'PSE') {
        window.location.assign(result.url);
      }
      setMessageModal(paymentMethod === 'PSE' ? 'Preparando para ir la banco' : ELP_MESSAGES.successfull);
      setTimeout(() => {
        setUserUpdated(result.user || user);
        setElpResponse(result.elpResonse);
        setShowModal(false);
        if (paymentMethod !== 'PSE') setPaymentMethodSelected(null);
        if (paymentMethod !== 'PSE') setSelectedPayment(false);
        if (paymentMethod !== 'PSE') setShowPreceipt(true);
      }, 2500);
    } catch (error) {
      setShowModal(false);
      setErrorModal(true);
      setErrrorMessage(error.publicMessage || ELP_MESSAGES.error);
    }
  };

  const validationConfrimation = (paymentMethod) => {
    const validate = valueAndTermsValidation(
      balanceToRecharge,
      termsAndConditions,
      errorsList,
      setErrorsList,
    );
    if (validate) {
      setPaymentMethodSelected(paymentMethod);
    }
  };

  const currentPaymentMethod = (method) => {
    switch (method) {
      case 'card':
        setSelectedPayment(false);
        return null;
      case 'pse':
        return (
          <>
            <PSE
              balanceToRecharge={balanceToRecharge}
              paymentFunction={handlerPaymentFunction}
              setErrorsListBalance={setErrorsList}
            />
          </>
        );
      default:
        return null;
    }
  };

  const GoBackAndHideRechargeBalance = () => (
    <ArrowBack
      onClick={() => (
        setShowRecharBalanceScreen
          ? setShowRecharBalanceScreen(false)
          : navigate(-1)
      )}
      className="arrowBack"
    />
  );

  const noLoader = () => loader && setLoader(false);

  return (
    <>
      {loader && (
        <Loader />
      )}
      {showDependingOnBusinessFlag({ businessFlags, section: 'tuCompra' }) && (
        <div id="container-recharge-balance" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
          {noLoader()}
          <div id="header__container-recharge-balance">
            <div className="left">
              <GoBackAndHideRechargeBalance />
            </div>
            <div className="center">
              <Typography text="Recarga tu saldo aquí" type="superTitle-italic" />
            </div>
            <div className="rigth" />
          </div>
          <div id="body__container-recharge-balance">
            <div className="second-body__container-recharge-balance">
              <form action={crendetialsTuCompra.gateWay}>
                <div className="container-input-and-checkbox">
                  <Input
                    label="¿Cuál es el valor de tu recarga?"
                    value={balanceToRecharge}
                    onChange={setBalanceToRecharge}
                    type="number"
                    iconRigth={IconCash}
                    placeholder="$69.000"
                    personalizedFirstSpan="new-color"
                  />
                  <CheckBox
                    clickOnText={() => window.open(TACUrl, '_blank', 'noopener')}
                    checked={termsAndConditions}
                    onChange={setTermsAndConditions}
                    annotationError={termsAndConditionsError && 'Se requiere de esta confirmación para poder continuar.'}
                    text="Acepto términos y condiciones"
                  />
                </div>
                <input type="hidden" className="form-control underlined" name="usuario" value={crendetialsTuCompra.idSistema} required />
                <input type="hidden" className="form-control underlined" id="factura" name="factura" value={uuidv4()} required />
                <input type="hidden" className="form-control underlined" id="valor" name="valor" value={balanceToRecharge} required />
                <input type="hidden" className="form-control underlined" name="descripcionFactura" value="Compra de saldo en mipaquete.com" required />
                <input type="hidden" className="form-control underlined" name="tokenSeguridad" value={crendetialsTuCompra.claveDeEncripcion} required />
                <input type="hidden" className="form-control underlined" name="documentoComprador" value={user.documentNumber} required />
                <input type="hidden" className="form-control underlined" name="correoComprador" value={user.email} required />
                <input type="hidden" className="form-control underlined" name="nombreComprador" value={user.name} required />
                <input type="hidden" className="form-control underlined" name="apellidoComprador" value={user.surname} required />
                <input type="hidden" className="form-control underlined" name="telefonoComprador" value={user.cellPhone} required />
                <input type="hidden" className="form-control underlined" name="celularComprador" value={user.cellPhone} required />
                <input type="hidden" className="form-control underlined" name="direccionComprador" value={user.address} required />
                <input type="hidden" className="form-control underlined" name="campoExtra1" value={user._id} required />
                <input type="hidden" className="form-control underlined" id="transaction" value={uuidv4()} required />
                <input type="hidden" className="form-control underlined" id="tipoTransaccion" name="campoExtra5" value="icome" required />
                <div className="container-buttons">
                  <input type="submit" className="Button-primary button-primary" value="Recargar saldo" id="buton" disabled={!buttonEnabled || !termsAndConditions} />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {showDependingOnBusinessFlag({ businessFlags, section: 'wompi' }) && (
        <>
          {noLoader()}
          <WompyPayment />
        </>
      )}
      {showDependingOnBusinessFlag({ businessFlags, section: 'elp' }) && (
        <>
          {noLoader()}
          <div id="container-recharge-balance" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
            {setShowRecharBalanceScreen && (
              <div className="close" onClick={() => setShowRecharBalanceScreen(false)} />
            )}
            {!showReceipt && (
              <>
                <div id="header__container-recharge-balance" className={selectedPayment ? '' : 'full-width'}>
                  <div className={`left ${selectedPayment ? '' : 'more-space'}`}>
                    {selectedPayment && (
                      <GoBackAndHideRechargeBalance />
                    )}
                    {!selectedPayment && (
                      <ArrowBack
                        onClick={() => {
                          setPaymentMethodSelected(null);
                          setSelectedPayment(true);
                        }}
                        className="arrowBack"
                      />
                    )}
                    {!selectedPayment && !isMobileDevice && (<Typography text="Pago con tarjeta de crédito" type="superTitle-italic" className="left-space" />)}
                  </div>
                  <div className="center">
                    {selectedPayment && (<Typography text="Recarga tu saldo aquí" type="superTitle-italic" />)}
                    {!selectedPayment && isMobileDevice && (<Typography text="Pago con tarjeta de crédito" type="superTitle-italic" />)}
                  </div>
                  <div className="rigth" />
                </div>
                <div id="body__container-recharge-balance">
                  <div className="second-body__container-recharge-balance">
                    <div className="form">
                      {selectedPayment && (
                        <>
                          <div className="container-input-and-checkbox">
                            <Input
                              label="¿Cuál es el valor de tu recarga?"
                              value={balanceToRecharge}
                              onChange={setBalanceToRecharge}
                              type="number"
                              iconRigth={IconCash}
                              placeholder="$69.000"
                              annotationError={
                                findObjectInArray('balanceToRecharge', errorsList, 'name')
                                  ? findObjectInArray('balanceToRecharge', errorsList, 'name').error
                                  : null
                              }
                              personalizedFirstSpan="new-color"
                            />
                            <CheckBox
                              clickOnText={() => window.open(TACUrl, '_blank', 'noopener')}
                              checked={termsAndConditions}
                              onChange={setTermsAndConditions}
                              className="center"
                              annotationError={findObjectInArray('termsAndConditions', errorsList, 'name')
                                ? findObjectInArray('termsAndConditions', errorsList, 'name').error
                                : null}
                              text="Acepto términos y condiciones"
                            />
                          </div>
                          <div className="center">
                            <Typography
                              text="Selecciona un método de pago"
                              type="subtitle"
                              className="payment-method-title"
                            />
                          </div>
                          <div className="payment-methods">
                            <div className="each" onClick={() => validationConfrimation('card')}>
                              <div className="img" />
                              <div className="left-column">
                                <Typography text="Tarjeta de crédito" type="field" className="method" />
                                <div className="arrow" />
                              </div>
                            </div>
                            <div className="each" onClick={() => validationConfrimation('pse')}>
                              <img src={pseIcon} alt="pse" />
                              <div className="left-column">
                                <Typography text="PSE" type="field" className="method" />
                                <div className="arrow" />
                              </div>
                            </div>
                            <div>
                              {paymentMethodSelected && currentPaymentMethod(paymentMethodSelected)}
                            </div>
                            <div className="recharge-button">
                              <Button
                                link="https://www.youtube.com/watch?v=yP-qAUgexI0"
                                type="secondary"
                                text="Aprende a recargar saldo"
                                className="recharge-button-tutorial"
                              />
                              <h4 className="link-text">
                                ¿Tienes problemas con la recarga?
                                <a rel="noreferrer" target="_blank" href="https://bit.ly/3D8Lhwi">haz clic aquí</a>
                              </h4>
                            </div>
                          </div>
                        </>
                      )}
                      {!selectedPayment && paymentMethodSelected === 'card' && (
                        <CardData
                          paymentFunction={handlerPaymentFunction}
                          balanceToRecharge={balanceToRecharge}
                          setSelectedPayment={setSelectedPayment}
                          setPaymentMethodSelected={setPaymentMethodSelected}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {showReceipt && (
              <div className="receipt-container">
                <ReceiptOfPayment
                  user={userUpdated}
                  elpResult={elpResponse}
                />
              </div>
            )}
          </div>
          {showModal && (
            <Modal className={`recharge-balance-modal ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
              <Typography
                text={messageModal}
                type="title"
                className="processing-title"
              />
            </Modal>
          )}
          {showErrorModal && (
            <Modal className={`recharge-balance-modal more-height ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
              <Typography
                text="No ha sido posible recargar tu saldo, inténtalo de nuevo"
                type="title"
                className="processing-title"
              />
              {errorMessage && (
                <Typography
                  text={errorMessage}
                  type="title"
                  className="error-text"
                />
              )}
              <Button
                onClick={() => setErrorModal(false)}
                type={isCountryValid ? 'primary-mx' : 'primary'}
                text="Cerrar"
                className="close_modal_button"
              />
            </Modal>
          )}
        </>
      )}
    </>
  );
};

RechargeBalance.propTypes = {
  setclassName: PropTypes.object.isRequired,
  setShowRecharBalanceScreen: PropTypes.func,
};

RechargeBalance.defaultProps = {
  setShowRecharBalanceScreen: null,
};

export default RechargeBalance;
