/* eslint-disable object-shorthand */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import swal from 'sweetalert';
import { compose, bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import React, { useState, useEffect } from 'react';

import { bankDataValidation } from './validations';
import { updateUserService } from '../../services/user';
import { findObjectInArray } from '../../utils/filters';
import { extractToSpecialCharacter } from '../../utils/strings';
import { UpdateUserAction } from '../../store/actions/auth.actions';
import { useValidationCountry } from '../../utils/validationCountry';
import bankDataByUserCountryCode from '../../utils/banks';

import Modal from '../modal';
import Input from '../input';
import Button from '../button';
import Select from '../select';
import Typography from '../typography';
import RadioButton from '../radioButton';
import { documentTypeList } from '../../constants/general';

import IconClose from '../../assets/images/icon-close-color-primary.svg';
import IconArrowBack from '../../assets/images/icon-arrow-back-color-primary.svg';
import IconSearch from '../../assets/images/icon-search-color-accent.svg';

import './bankData.scss';

const selectDocumentType = (documenType) => (
  documentTypeList.find(({ value }) => value === documenType)?.value ?? null
);

const parse = (number) => Number.parseInt(number, 10);
const transformNumber = (document) => (Number.isNaN(parse(document))
  ? null : String(parse(document)));

const BankData = ({
  isMobileDevice,
  next,
  back,
  cancel,
  close,
  userIdToUpdate,
  setAccountBank,
  globalBankData,
  countryCode,
  UpdateUserFunction,
  completeScreen,
}) => {
  const user = useSelector((state) => state?.auth?.user);

  const [accountHolder, setAccountHolder] = useState(`${user?.name ?? null} ${user?.surname ?? null}`);
  const [documentType, setDocumentType] = useState(selectDocumentType(user?.documentType));
  const [documentNumber, setDocumentNumber] = useState(transformNumber(user?.documentNumber));
  const [documentNumberConfirmation, setDocumentNumberConfirmation] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [accountType, setAccountType] = useState('ahorros');
  const [accountNumber, setAccountNumber] = useState(null);
  const [accountNumberConfirmation, setAccountNumberConfirmation] = useState(null);
  const [errorsList, setErrorsList] = useState([]);
  const [executingAction, setExecutingAction] = useState(false);
  const [showModalChangesAccount, setShowModalChangesAccount] = useState(false);

  const [countryBankData] = useState(bankDataByUserCountryCode(globalBankData));
  const isCountryValid = useValidationCountry(countryCode);

  useEffect(() => {
    if (setAccountBank) {
      setAccountBank({
        bank: bankName,
        beneficiaryName: accountHolder,
        accountType: accountType,
        accountNumber: accountNumber,
        typeId: documentType,
        numberId: documentNumber,
      });
    }
  }, [accountHolder, documentType, documentNumber, bankName, accountType, accountNumber]);

  const updateUserFunction = async () => {
    setExecutingAction(true);
    const accountBank = {
      bank: extractToSpecialCharacter(bankName, '-')[0],
      beneficiaryName: accountHolder,
      accountType: accountType,
      accountNumber: accountNumber,
      typeId: documentType,
      numberId: documentNumber,
    };
    try {
      const responseUpdate = await updateUserService(userIdToUpdate, { accountBank });
      if (responseUpdate) {
        swal('¡Listo!', 'Tus datos bancarios se han actualizado exitosamente.');
        UpdateUserFunction(responseUpdate);
        next();
      }
    } catch {
      swal('Error', 'Ha habido un problema al intentar actualizar los datos, inténtalo de nuevo.');
    } finally {
      setExecutingAction(false);
      setShowModalChangesAccount(false);
    }
  };

  return (
    <>
      <div
        id="bank-data"
        className={`${isMobileDevice ? 'mobile-mode' : 'desktop-mode'} ${completeScreen && 'completeScreen'}`}
      >
        <div className="header__bank-data">
          <div className="left">
            {back && (
              <img
                src={IconArrowBack}
                alt=""
                onClick={() => back()}
              />
            )}
            <Typography type="superTitle-italic" text="Inscribir cuenta bancaria" className="header-text" />
          </div>
          <div className="center" />
          <div className="rigth">
            {close && (
              <img
                src={IconClose}
                alt="cerrar"
                onClick={() => close()}
              />
            )}
          </div>
        </div>
        <div className={`body__bank-data ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'} ${completeScreen && 'completeScreen'}`}>
          <div className="second-body__bank-data">
            <div className="container__body__bank-data">
              <div className="container-top-bottom-or-side__bank-data">
                <Typography
                  text="Información del titular de la cuenta"
                  type="field-gray-light"
                  className="field-title-dark font-size-16"
                />
                <Input
                  label="Nombre"
                  value={accountHolder}
                  onChange={setAccountHolder}
                  annotationError={
                    findObjectInArray('accountHolder', errorsList, 'name')
                      ? findObjectInArray('accountHolder', errorsList, 'name').error
                      : null
                  }
                  placeholder="Ingrese un nombre"
                />
                <div className="container-select-bank-data">
                  <Typography type="field" text="Tipo de documento" className="grayColor" />
                  <Select
                    label="Tipo de documento"
                    value={documentType}
                    onChange={setDocumentType}
                    startingPosition="top-left"
                    className="select-identification-type"
                    annotationError={
                      findObjectInArray('documentType', errorsList, 'name')
                        ? findObjectInArray('documentType', errorsList, 'name').error
                        : null
                    }
                    options={documentTypeList}
                  />
                </div>

                <Input
                  label="Número de identificación"
                  value={documentNumber}
                  type="number-text"
                  onChange={setDocumentNumber}
                  annotationError={
                    findObjectInArray('documentNumber', errorsList, 'name')
                      ? findObjectInArray('documentNumber', errorsList, 'name').error
                      : null
                  }
                  placeholder="123456789"
                />

                <Input
                  label="Confirma el número de identificación"
                  value={documentNumberConfirmation}
                  type="number-text"
                  onChange={setDocumentNumberConfirmation}
                  annotationError={
                    findObjectInArray('documentNumberConfirmation', errorsList, 'name')
                      ? findObjectInArray('documentNumberConfirmation', errorsList, 'name').error
                      : null
                  }
                  placeholder="123456789"
                  onCopy
                  onPaste
                />

              </div>
              <div className="line-container">
                <hr />
              </div>
              <div className="container-top-bottom-or-side__bank-data">
                <Typography
                  text="Cuenta bancaria"
                  type="field-gray-light"
                  className="field-title-dark font-size-16"
                />

                <Input
                  label="Entidad Bancaria"
                  value={bankName}
                  onChange={setBankName}
                  placeholder="Banco"
                  dataList={countryBankData.banksWithTranferFee}
                  annotationError={
                    findObjectInArray('bankName', errorsList, 'name')
                      ? findObjectInArray('bankName', errorsList, 'name').error
                      : null
                  }
                  iconRigth={IconSearch}
                />

                <div className="container-radio-buttons__bank-data">
                  <Typography type="field" text="Tipo de cuenta" className="grayColor" />
                  <RadioButton
                    name="account-type"
                    text="Ahorros / Ahorro a la mano"
                    value="ahorros"
                    onChange={setAccountType}
                    checked={accountType === 'ahorros'}
                    className="primeryColor"
                  />
                  <RadioButton
                    name="account-type"
                    text="Corriente"
                    value="corriente"
                    onChange={setAccountType}
                    checked={accountType === 'corriente'}
                    className="primeryColor"
                  />
                </div>

                <Input
                  label="Número de cuenta bancaria"
                  value={accountNumber}
                  type="number-text"
                  onChange={setAccountNumber}
                  annotationError={
                    findObjectInArray('accountNumber', errorsList, 'name')
                      ? findObjectInArray('accountNumber', errorsList, 'name').error
                      : null
                  }
                  placeholder="1000000000"
                />

                <Input
                  label="Confirma el número de cuenta bancaria"
                  value={accountNumberConfirmation}
                  type="number-text"
                  onChange={setAccountNumberConfirmation}
                  annotationError={
                    findObjectInArray('accountNumberConfirmation', errorsList, 'name')
                      ? findObjectInArray('accountNumberConfirmation', errorsList, 'name').error
                      : null
                  }
                  placeholder="1000000000"
                  onCopy
                  onPaste
                />
              </div>
            </div>
          </div>
          <div className={`container-buttons__bank-data ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
            <div className="container__body__bank-data mgTop">
              {isMobileDevice ? (
                <>
                  <div className="container-top-bottom-or-side__bank-data">
                    {next && (
                      <Button
                        text="Continuar"
                        type={isCountryValid ? 'primary-mx' : 'primary'}
                        onClick={() => {
                          if (bankDataValidation(
                            errorsList, setErrorsList, accountHolder, documentType,
                            documentNumber, documentNumberConfirmation,
                            bankName, accountNumber, accountType, accountNumberConfirmation,
                            countryBankData.bankTransferFee,
                          )) {
                            setShowModalChangesAccount(true);
                          }
                        }}
                      />
                    )}
                  </div>
                  <div className="container-top-bottom-or-side__bank-data">
                    {cancel && (
                      <Button
                        text="Más tarde"
                        type="secondary"
                        onClick={() => cancel()}
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="container-top-bottom-or-side__bank-data">
                    {cancel && (
                      <Button
                        text="Más tarde"
                        type="secondary"
                        onClick={() => cancel()}
                      />
                    )}
                  </div>
                  <div className="container-top-bottom-or-side__bank-data">
                    {next && (
                      <Button
                        text="Continuar"
                        type={isCountryValid ? 'primary-mx' : 'primary'}
                        onClick={() => {
                          if (bankDataValidation(
                            errorsList, setErrorsList, accountHolder, documentType,
                            documentNumber, documentNumberConfirmation,
                            bankName, accountNumber, accountType, accountNumberConfirmation,
                            countryBankData.bankTransferFee,
                          )) {
                            setShowModalChangesAccount(true);
                          }
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {showModalChangesAccount && (
        <Modal
          className={`confirmation__bank-data ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
        >
          <div>
            <Typography
              text="Verifica bien los datos bancarios"
              type="superTitle-italic"
              className="center-text"
            />
            <span className="modal__text-confirmation">
              ten presente que estos podrán ser cambiados
              <span className="bold">
                máximo 3 veces al mes.
              </span>
            </span>
            <div className="modal-confirmation__bank-data">
              <Button
                text="Revisar datos"
                type="secondary"
                onClick={() => {
                  setShowModalChangesAccount(false);
                }}
                disabled={executingAction}
              />
              <Button
                text="Guardar datos"
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={() => {
                  if (userIdToUpdate) {
                    updateUserFunction();
                  } else {
                    next();
                    setShowModalChangesAccount(false);
                  }
                }}
                loading={executingAction}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

BankData.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  setAccountBank: PropTypes.func.isRequired,
  back: PropTypes.func,
  cancel: PropTypes.func,
  close: PropTypes.func,
  next: PropTypes.func,
  userIdToUpdate: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  globalBankData: PropTypes.shape(),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  UpdateUserFunction: PropTypes.func.isRequired,
  completeScreen: PropTypes.bool,
};

BankData.defaultProps = {
  back: null,
  cancel: null,
  next: null,
  close: null,
  userIdToUpdate: null,
  completeScreen: false,
  globalBankData: {},
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  globalBankData: state.globalData.bankTransferFee || {},
});

const mapDispatchToProps = (dispatch) => ({
  UpdateUserFunction: bindActionCreators(UpdateUserAction, dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(BankData);
