import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../../typography';
import UserAvatar from '../../userAvatar';
import { capitalizeWords } from '../../../utils/strings';
import './senderDetails.scss';

const SenderDetails = ({
  access, senderName, senderAddress, senderLocation,
}) => access !== 'public' && (
  <div className="information-block">
    <Typography type="subtitle" className="subtitle">Remitente</Typography>
    <div className="sender-data__container">
      <UserAvatar
        firstLetter={senderName && senderName?.charAt(0).toUpperCase()}
        size={40}
      />
      <div className="text__sender-data">
        <Typography type="paragraph">{capitalizeWords(senderName)}</Typography>
        <Typography type="paragraph">{senderAddress}</Typography>
        <Typography type="paragraph">{senderLocation}</Typography>
      </div>
    </div>
  </div>
);

SenderDetails.propTypes = {
  access: PropTypes.string,
  senderName: PropTypes.string.isRequired,
  senderAddress: PropTypes.string.isRequired,
  senderLocation: PropTypes.string.isRequired,
};

SenderDetails.defaultProps = {
  access: '',
};

export default SenderDetails;
