/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';

import { documentTypeName } from '../../../../../constants/general';
import Typography from '../../../../../components/typography';

import './billingDataColombia.scss';

const title = () => (
  <div>
    Recibirás tu
    {' '}
    <span>factura </span>
    {' '}
    con los siguientes datos. Verifica que sean los correctos.
  </div>
);

const BillingDataColombia = ({ user, isMobileDevice }) => (
  <div className={`contacInfo-container ${isMobileDevice && 'invoicing-mobile'}`}>
    <Typography
      className="main-title"
      text={title()}
    />
    <div className="content-info">
      <div className="content-left">
        <div>
          <Typography className="title-info" text="Tipo de persona" />
          <Typography className="subtitle-info" text={user.personType ?? ''} />
        </div>
        <div>
          <Typography className="title-info" text="Tipo de documento" />
          <Typography className="subtitle-info" text={documentTypeName[user.documentType] ?? ''} />
        </div>
        <div>
          <Typography className="title-info" text={user.personType === 'Persona Jurídica' ? 'Número de NIT' : 'Número de identificación'} />
          <Typography className="subtitle-info" text={user.documentNumber ?? ''} />
        </div>
        {
          user.personType === 'Persona Jurídica' && (
            <div className="last">
              <Typography className="title-info" text="Razón social" />
              <Typography className="subtitle-info" text={user.tradeName ?? ''} />
            </div>
          )
        }
        {
          user.personType === 'Persona Natural' && (
            <div className="last">
              <Typography className="title-info" text="Nombre completo" />
              <Typography className="subtitle-info" text={`${user.name ?? ''} ${user.surname ?? ''}`} />
            </div>
          )
        }
      </div>
      <div className="content-right">
        <div>
          <Typography className="title-info" text="Ciudad" />
          <Typography className="subtitle-info" text={user.invoicingData?.locationName ?? ''} />
        </div>
        <div>
          <Typography className="title-info" text="Dirección" />
          <Typography className="subtitle-info" text={user.invoicingData?.address ?? ''} />
        </div>
        <div>
          <Typography className="title-info" text="Celular" />
          <Typography className="subtitle-info" text={user.cellPhone ?? ''} />
        </div>
        <div className="last">
          <Typography className="title-info" text="E-mail para facturación electrónica" />
          <Typography className="subtitle-info" text={user.invoicingData?.emailForBilling ?? ''} />
        </div>
      </div>
    </div>
  </div>
);

BillingDataColombia.propTypes = {
  user: PropTypes.instanceOf.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

BillingDataColombia.defaultProps = {

};

export default BillingDataColombia;
