import axios from 'axios';
import { deliveryCompanyMicroserviceUrl } from '../constants';

const getDeliveryCompaniesService = () => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = '664e0f80-4a69-4042-9604-7797e95df194';
  axios.get(`${deliveryCompanyMicroserviceUrl}/getdeliverycompanies`)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) resolve(response.data);
      const message = response.mesage;
      if (response.status >= 400) {
        resolve(message.title = 'error al obtener la compañía');
      }
    }).catch((e) => reject(e));
});

export default getDeliveryCompaniesService;
