import { useSelector } from 'react-redux';
import React from 'react';

import DiscountsScreen from './discounts';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './discountsScreen.scss';

const MyDiscountsScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile className="">
        <DiscountsScreen />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop className="container-discounts__desktop-mode">
        <DiscountsScreen />
      </ContainerSectionDesktop>
    )
  );
};

export default MyDiscountsScreen;
