import { validationValue, insertOrRemoveErrorFromList } from '../../../../utils/strings';

export const validationSteps = ({
  errorList, setErrorList, cellPhone, personalized, lengthError, value,
}) => {
  const temporalErrorList = errorList.slice();
  const evaluateToInsertOrRemove = (boolean) => (boolean ? 'remove' : 'insert');

  if (cellPhone || !cellPhone) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'cellPhone',
      'Ingresa un número de celular válido',
      evaluateToInsertOrRemove(validationValue(cellPhone, 'cellPhone').correct),
    );
  }

  if (lengthError) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'lengthError',
      personalized,
      validationValue(value, 'lengthError').correct ? 'remove' : 'insert',
    );
  }

  setErrorList(temporalErrorList);
};

export default { validationSteps };
