/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, {
  useState, useEffect,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import { addThousandPoint } from '../../../utils/strings';
import {
  pointsCounterService, getProductsAndServices, getUserService, getUserLevelService,
} from '../../../services/user';
import { redeemPointsService, redeemPointsServieAndProductService } from '../../../services/cash';
import { UpdateUserAction } from '../../../store/actions/auth.actions';
import { showDependingOnBusinessFlag } from '../../../utils/businessFlag';

import ContentSection from '../../../components/contentSection';
import Typography from '../../../components/typography';
import UserLevel from '../../../components/userLevel';
import RedeemPointsSection from './redeemPoints';
import Button from '../../../components/button';
import Carousel from '../../../components/carousel';

import {
  ModalSucessOrError,
  ModalConfirm,
  ModalProductOrServiceDetails,
  ModalCarrierService,
} from './modals';

import ImageNoContent from '../../../assets/images/no-content.png';
import IconLockColorWhite from '../../../assets/images/icon-lock-color-white.svg';
import IconUnlockColorWhite from '../../../assets/images/icon-unlock-color-white.svg';
import IconSideColorGray from '../../../assets/images/icon-arrow-side-color-accent.svg';

import './pointsModule.scss';

const includeBoldInText = (text) => {
  const regex = /^dontNeedRegexBEfore[0-9]+$/;
  const temporalText = [];
  let initialInserted = false;

  text.toString().split('').forEach((letter, index) => {
    let temporalLetter = letter;

    if (letter.match(regex) && !initialInserted) {
      temporalLetter = `<b>${temporalLetter}`;
      initialInserted = true;
    }

    if (!letter.match(regex) && initialInserted) {
      if (!text[index + 1]?.match(regex)) {
        temporalLetter = `</b>${temporalLetter}`;
        initialInserted = false;
      }
    }
    temporalText.push(temporalLetter);
  });

  return {
    __html: `<span>${text}</span>`,
  };
};

const HorizontalOptionList = ({
  title, items, onClickItem, showMore, children, showName,
}) => (
  <div className="horizontal-option-list">
    <div className="title-and-action-horizontal-option-list">
      <span>{title}</span>
      {showMore && (
        <div className="complete-button-size">
          <Button
            onClick={showMore}
            type="tertiary"
          >
            Ver todos
          </Button>
        </div>
      )}
    </div>
    {items && (
      <Carousel className="carousel-option-list">
        <>
          {items && items.map((item) => (
            <div className="flex column align-center" onClick={() => (item.onClick ? item.onClick() : onClickItem(item))}>
              <img
                key={item._id?.toString()}
                className="image-carousel-option-list"
                src={item.images ? item.images[0] : ImageNoContent}
                alt={`option ${item.name}`}
              />
              {showName && (
                <Typography style={{ 'max-width': '180px', 'text-align': 'center', margin: '0 8px' }} type="field">
                  {item.name}
                </Typography>
              )}
            </div>
          ))}
        </>
      </Carousel>
    )}
    {children}
  </div>
);

const TargetBenefictsList = ({ businessFlags, handleChangeShowModalCarrierService, urlRedemBeneficts }) => {
  const beneficts = [
    { name: 'Notificación de whatsapp', _id: 'benefict_1', images: ['https://s3.amazonaws.com/images.dev.mipaquete/products-and-services/Notificacion-wpp.png'] },
    { name: 'Instalación asistida de plugin', _id: 'benefict_2', images: ['https://s3.amazonaws.com/images.dev.mipaquete/products-and-services/Plugins.png'] },
    {
      name: 'Carrier service Shopify',
      onClick: () => handleChangeShowModalCarrierService(true),
      _id: 'benefict_4',
      images: ['https://s3.amazonaws.com/images.dev.mipaquete/products-and-services/Carrier-service.png'],
    },
  ];
  return (
    <ContentSection
      className="content-section-container-list-products-and-services"
      headerTextLeft="Beneficios por nivel"
      headerTextAlign="left"
    >
      <>
        {showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule', feature: 'beneficts' }) && (
          <HorizontalOptionList
            items={beneficts}
            onClickItem={() => window.open(urlRedemBeneficts, '_blank', 'noopener')}
            showName
          />
        )}
      </>
    </ContentSection>
  );
};

const TargetProductsAndServicesList = ({
  businessFlags, products, services, handleChangeProductOrServiceSelected, handleChangeContentSection,
}) => (
  <ContentSection
    className="content-section-container-list-products-and-services"
    headerTextLeft="Canjea tus puntos por"
    headerTextAlign="left"
  >
    <>
      {showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule', feature: 'product' }) && products.length > 0 && (
        <HorizontalOptionList
          name="product"
          title="Productos"
          items={products}
          onClickItem={(item) => handleChangeProductOrServiceSelected(item)}
          showMore={() => handleChangeContentSection({
            showSection: true,
            benefictType: 'product',
            benefictName: 'Productos',
            next: () => null,
          })}
        />
      )}
      {showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule', feature: 'service' }) && services.length > 0 && (
        <HorizontalOptionList
          name="service"
          title="Servicios"
          items={services}
          onClickItem={(item) => handleChangeProductOrServiceSelected(item)}
          showMore={() => handleChangeContentSection({
            showSection: true,
            benefictType: 'service',
            benefictName: 'Servicios',
            next: () => null,
          })}
        />
      )}
      {showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule', feature: 'rechargeBalance' }) && (
        <HorizontalOptionList title="Recarga de saldo">
          <Button
            text="Recarga tu saldo"
            onClick={() => handleChangeContentSection({
              showSection: true,
              benefictType: 'rechargeBalance',
              benefictName: 'Recargar mi saldo',
              next: () => null,
            })}
            type="tertiary"
            className="button-recharge-balance-horizontal-option-list"
          />
        </HorizontalOptionList>
      )}
    </>
  </ContentSection>
);

const benefictLevel = ({ level }) => level || 0;
const keyIterator = (prefix, index) => (`${prefix}-${index}`);

const TargetBenfefictsList = ({
  beneficts, level, isMobileDevice,
}) => (
  <ContentSection
    className="container-section-beneficts-list"
    headerTextLeft="Beneficios por tu nivel"
    headerTextAlign="left"
  >
    <Carousel className="carousel-beneficts-list" pixelsPerStep={isMobileDevice ? 292 : 998}>
      <>
        {beneficts.map((benefict, index) => (
          <div className="card-benefict" key={keyIterator('benefict', index)}>
            <div className="flex align-center justify-between" style={{ background: benefict.color }}>
              <Typography type="superTitle-italic" className="color-white">{`Nivel ${benefictLevel(benefict || {})}`}</Typography>
              <img
                src={benefict.level <= level ? IconUnlockColorWhite : IconLockColorWhite}
                alt={`benefict-${benefict.level.toString()}`}
              />
            </div>
            <div>
              <Typography type="paragraph">
                <span dangerouslySetInnerHTML={includeBoldInText(benefict.requirements)} />
              </Typography>
              {benefict.beneficts.map((text) => (
                <div
                  className="flex align-start"
                  style={{ margin: '12px 0', padding: '0 12px', boxSizing: 'border-box' }}
                  key={keyIterator('benefictText', index)}
                >
                  <div
                    style={{
                      width: '8px',
                      height: '8px',
                      background: benefict.color,
                      borderRadius: '50%',
                      margin: '6px 8px 0 0',
                    }}
                  />
                  <Typography
                    type="paragraph"
                    style={{ width: 'calc(100% - 16px)' }}
                  >
                    <span dangerouslySetInnerHTML={includeBoldInText(text)} />
                  </Typography>
                </div>
              ))}
              {benefict.actions?.map((btn) => (
                <aside className="buttons-container">
                  <Button
                    type={btn.type}
                    text={btn.text || ''}
                    link={btn.action}
                  />
                </aside>
              ))}
            </div>
          </div>
        ))}
      </>
    </Carousel>
  </ContentSection>
);

const TargetText = () => (
  <div className="container-text-points-module">
    <Typography type="superTitle-italic">
      <>
        Puntos
        {' '}
        <span className="color-primary">mipaquete</span>
      </>
    </Typography>
    <Typography type="paragraph">
      <>
        ¡Recompensamos tu fidelidad! Por cada envío entregado acumulas puntos para que redimas por increíbles productos o servicios, también podrás usarlos para  pagar nuevos envíos. Avanza por los 5 niveles de puntos mipaquete y accede a beneficios adicionales
        {' '}
        <b>¡Por esta y muchas más razones, mipaquete.com es para ti!</b>
      </>
    </Typography>
    <Button
      type="secondary"
      text="Cómo canjear mis puntos"
      link="https://youtu.be/GdpfAIODCGk"
      className="saize-button"
    />
  </div>
);

const TargetVideo = () => (
  <div className="container-video-points-module">
    <iframe
      style={{ width: '100%', height: '100%' }}
      src="https://www.youtube.com/embed/lror0oIgG_o"
      frameBorder="0"
      allow="autoplay; encrypted-media"
      allowFullScreen
      title="video"
    />
  </div>
);

const PointsModule = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth?.user || {});
  const businessFlags = useSelector((state) => state.globalData.businessFlags || []);
  const beneficts = useSelector((state) => state.globalData.beneficts || []);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [loadingData, setLoadingData] = useState(true);
  const [executingAction, setExecutingAction] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const [showModalCarrierService, setShowModalCarrierService] = useState(false);
  const [dataProductOrServiceSelected, setDataProductOrServiceSelected] = useState(null);
  const [contentModalConfirm, setContentModalConfirm] = useState(null);
  const [section, setSection] = useState(null);
  const [showBenefictsPerLevel, setShowBenefictsPerLevel] = useState(false);
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);
  const [benefictSelected, setBenefictSelected] = useState(null);
  const [level, setLevel] = useState(0);
  const [points, setPoints] = useState(0);
  const urlTyC = 'https://www.mipaquete.com/tyc-campanas-comerciales';
  const urlRedemBeneficts = 'https://forms.gle/Mk9HCevSPEgXT99L7';
  const firstAccesslocalStorage = 'FirstAccessPointsModule';

  useEffect(() => {
    if (
      JSON.parse(window.localStorage.getItem(firstAccesslocalStorage)) === null
      || JSON.parse(window.localStorage.getItem(firstAccesslocalStorage)) === false
    ) {
      setShowBenefictsPerLevel(true);
      window.localStorage.setItem(firstAccesslocalStorage, true);
    }
  }, []);

  useEffect(() => businessFlags.length > 0 && (
    !showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule' }) && navigate('/', { replace: true })
  ), [businessFlags]);

  const getPointsFunction = async () => {
    const responsePoints = await pointsCounterService(user?._id);
    setPoints(parseInt(responsePoints?.points ?? 0, 10));
  };

  useEffect(() => {
    async function functionGetProductsServicesAndPoints() {
      try {
        getPointsFunction();
        let responseProducts = await getProductsAndServices('product');
        responseProducts = responseProducts.filter((item) => item.enabled);
        setProducts(responseProducts);
        let responseServices = await getProductsAndServices('service');
        responseServices = responseServices.filter((item) => item.enabled);
        setServices(responseServices);
        const responseUserLevel = await getUserLevelService(user?._id);
        setLevel(responseUserLevel?.level ?? 0);
      } finally {
        setLoadingData(false);
      }
    }

    if (loadingData) functionGetProductsServicesAndPoints();
  }, [loadingData]);

  const returnOptionList = (benefictType) => {
    if (benefictType === 'product') return products;
    if (benefictType === 'service') return services;
    return null;
  };

  const exchangeBenefits = async (benefictType, optionId, pointsCost) => {
    setExecutingAction(true);

    try {
      if (benefictType === 'rechargeBalance') {
        const responseRedeemedPoints = await redeemPointsService(user?._id, pointsCost);
        if (responseRedeemedPoints) {
          const responseUser = await getUserService(user?._id);
          getPointsFunction();
          dispatch(UpdateUserAction(responseUser));
          setDataModal({
            header: (
              <Typography type="superTitle-italic">
                ¡Has redimido
                {' '}
                <span className="color-primary">
                  {addThousandPoint(pointsCost)}
                  {' '}
                  {points === 1 ? 'punto' : 'puntos'}
                </span>
                {' '}
                en tu saldo!
              </Typography>
            ),
          });
        }
        setSection(null);
      }

      if (benefictType === 'product' || benefictType === 'service') {
        const responseRedeemedPoints = await redeemPointsServieAndProductService(
          user._id, optionId,
        );
        if (responseRedeemedPoints) {
          getPointsFunction();
          setDataModal({
            header: (
              <Typography type="superTitle-italic">
                Tu solicitud ha sido creada
                {' '}
                <span className="color-primary">con éxito</span>
              </Typography>
            ),
            body: (
              <Typography type="paragraph" className="paragraph-modal-redeem-point-section more-width">
                Tu solicitud ha sido creada con éxito, nos pondremos en contacto contigo en breve.
              </Typography>
            ),
          });
          setSection(null);
          setBenefictSelected(null);
          setDataProductOrServiceSelected(null);
        }
      }
    } catch (e) {
      if (benefictType === 'rechargeBalance') {
        setDataModal({
          header: (
            <Typography type="superTitle-italic">
              No ha sido posible redimir tu puntos, inténtalo más tarde.
            </Typography>
          ),
        });
      }

      if (benefictType === 'product' || benefictType === 'service') {
        setDataModal({
          header: (
            <Typography type="superTitle-italic">
              No ha sido posible realizar la solicitud, inténtalo más tarde.
            </Typography>
          ),
        });
      }
    } finally {
      setContentModalConfirm(null);
      setExecutingAction(false);
      setLoadingData(true);
    }
  };

  return showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule' }) ? (
    !user?._id ? (
      <div id="points-module" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
        <div className="container-top">
          <TargetText user={user} urlTyC={urlTyC} />
          <TargetVideo />
        </div>
        <TargetBenfefictsList
          beneficts={beneficts || []}
          level={level}
          isMobileDevice={isMobileDevice}
        />
        <div className="container-bottom">
          <span onClick={() => window.open(urlTyC, '_blank', 'noopener')}>*Términos y condiciones del programa</span>
        </div>
      </div>
    ) : (
      <>
        {section && (
          <RedeemPointsSection
            benefictType={section.benefictType}
            benefictName={section.benefictName}
            optionList={returnOptionList(section.benefictType)}
            handleChangeBenefictSelected={setBenefictSelected}
            benefictSelected={benefictSelected}
            showMore={(item) => setDataProductOrServiceSelected(item)}
            points={points}
            isMobileDevice={isMobileDevice}
            loading={executingAction}
            back={() => {
              setSection(null);
              setBenefictSelected(null);
            }}
            next={() => {
              exchangeBenefits(benefictSelected.typeProduct, benefictSelected._id);
            }}
            setContentModalConfirm={setContentModalConfirm}
          />
        )}

        {!section && (
          <div id="points-module" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
            <div className="container-top">
              {!showBenefictsPerLevel && <TargetText user={user} urlTyC={urlTyC} />}
              <UserLevel
                forceUpdate={points}
                className="container-section-left"
                userId={user?._id}
                back={showBenefictsPerLevel ? () => setShowBenefictsPerLevel(false) : null}
                headerChildrenRigth={!showBenefictsPerLevel
                  && beneficts.length > 0
                  && showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule', feature: 'beneficts' }) && (
                    <Button
                      onClick={() => setShowBenefictsPerLevel(true)}
                      type="tertiary"
                    >
                      Conoce más
                      {' '}
                      <img src={IconSideColorGray} alt="icon" />
                    </Button>
                )}
              />
              {showBenefictsPerLevel && <TargetVideo />}
            </div>

            {showBenefictsPerLevel ? (
              <TargetBenfefictsList
                beneficts={beneficts || []}
                level={level}
                isMobileDevice={isMobileDevice}
              />
            ) : (
              <>
                <TargetBenefictsList
                  businessFlags={businessFlags}
                  urlRedemBeneficts={urlRedemBeneficts}
                  handleChangeShowModalCarrierService={setShowModalCarrierService}
                />
                <TargetProductsAndServicesList
                  businessFlags={businessFlags}
                  products={products}
                  services={services}
                  handleChangeProductOrServiceSelected={setDataProductOrServiceSelected}
                  handleChangeContentSection={setSection}
                />
              </>
            )}
            <div className="container-bottom">
              <span className="right" onClick={() => window.open(urlTyC, '_blank', 'noopener')}>*Términos y condiciones del programa</span>
              <HorizontalOptionList className="black" title="Tengo una novedad con mis puntos ">
                <Button
                  text="Reportar una novedad"
                  onClick={() => window.open('https://docs.google.com/forms/d/1O6WNRlBWcWuzDfsf_za-GsyYoe3c9jvkaOwuYG6Chkk/edit', '_blank', 'noopener')}
                  type="tertiary"
                  className="button-recharge-balance-horizontal-option-list left"
                />
              </HorizontalOptionList>
            </div>
          </div>
        )}

        {showModalCarrierService && (
          <ModalCarrierService
            next={() => window.open(urlRedemBeneficts, '_blank', 'noopener')}
            handleChangeShowModalCarrierService={setShowModalCarrierService}
          />
        )}

        {dataModal && (
          <ModalSucessOrError
            dataModal={dataModal}
            handleChangeDataModal={setDataModal}
          />
        )}

        {contentModalConfirm && (
          <ModalConfirm
            option={contentModalConfirm.optionName}
            pointsCost={contentModalConfirm.pointsCost}
            cancel={() => setContentModalConfirm(null)}
            executingAction={executingAction}
            confirm={() => {
              exchangeBenefits(
                contentModalConfirm.benefictType,
                contentModalConfirm.optionId,
                contentModalConfirm.pointsCost,
              );
            }}
          />
        )}

        {dataProductOrServiceSelected && (
          <ModalProductOrServiceDetails
            benefictId={dataProductOrServiceSelected._id}
            name={dataProductOrServiceSelected.name}
            description={dataProductOrServiceSelected.description}
            images={dataProductOrServiceSelected.images}
            deliveryTime={dataProductOrServiceSelected.deliveryTime}
            allied={dataProductOrServiceSelected.allied}
            points={dataProductOrServiceSelected.value}
            totalPoints={points}
            benefictType={dataProductOrServiceSelected.typeProduct}
            loading={executingAction}
            close={() => setDataProductOrServiceSelected(null)}
            next={(benefictType, optionId) => {
              exchangeBenefits(benefictType, optionId);
            }}
          />
        )}
      </>
    )
  ) : (
    <div className="loading-section">
      <div>
        <div />
      </div>
    </div>
  );
};

export default PointsModule;
