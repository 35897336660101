/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import ContentSection from '../../../../components/contentSection';
import Typography from '../../../../components/typography';
import Input from '../../../../components/input';
import Button from '../../../../components/button';

import Add from '../../../../assets/images/add.svg';
import ArrowBack from '../../../../assets/images/icon-arrow-back-color-gray-dark.svg';

import './stepTwo.scss';

const StepTwoQuoteMultipleShippings = ({
  next, back, file, setFile, fileName, setFileName,
  readExcelLoading, excelWithErrors, isMobileDevice,
}) => (
  <ContentSection
    headerText={isMobileDevice ? null : 'Cotiza múltiples envíos'}
  >
    <div
      id="multiple-shippings__upload-template"
      className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}
    >
      <div className="content-top">
        <img
          src={ArrowBack}
          alt="back"
          onClick={() => back()}
        />
        <Typography
          text={`Sube tu plantilla ${excelWithErrors ? 'corregida' : 'diligenciada'}`}
          type="title"
        />
        <div />
      </div>
      <div className="content-bottom">
        <div className="container__input-upload-template">
          <Input
            label="Selecciona el archivo a cargar"
            value={fileName}
            onChange={(target) => {
              setFile(target.files[0]);
              setFileName(target.files[0].name);
            }}
            type="file"
            iconLeft={Add}
            leftIconCustomClass="add_box"
            personalizedValueSpan="w100"
            typeHidden
            lineHidden
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.ms-excel"
          />
        </div>
        <Button
          text="Cargar archivo"
          type="quaternary"
          disabled={!file}
          onClick={() => next()}
          loading={readExcelLoading}
        />
      </div>
    </div>
  </ContentSection>
);

StepTwoQuoteMultipleShippings.propTypes = {
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  file: PropTypes.any,
  setFile: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  setFileName: PropTypes.func.isRequired,
  readExcelLoading: PropTypes.bool.isRequired,
  excelWithErrors: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

StepTwoQuoteMultipleShippings.defaultProps = {
  file: null,
  fileName: null,
};

export default StepTwoQuoteMultipleShippings;
