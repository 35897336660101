import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Modal from '../../../modal';
import Button from '../../../button';
import ModalChildren from '../../../modalChildren';
import { ReactComponent as IconClose } from '../../../../assets/images/icon-close-color-primary.svg';

import './completingData.scss';

const AlertIncompleteData = ({ visible, next, close }) => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (isMobileDevice && visible) ? (
    <Modal>
      <ModalChildren
        className="modalIncompleteData-mexico"
        headerChildren={(
          <p className="incompleteDataText-mexico">
            <span>Completa tus datos para comenzar a hacer tus envíos</span>
          </p>
        )}
        close={close}
        confirmButtonText="Completa tus datos"
        confirmButtonType="secondary"
        onClickButtonConfirm={next}
      />
    </Modal>
  ) : (
    <div className={['alertIncompleteData-mexico', visible ? 'visible' : ''].join(' ')}>
      <div>
        <p className="incompleteDataText-mexico">
          <span>Completa tus datos para comenzar a hacer tus envíos.</span>
        </p>
        <Button type="secondary" text="Completa tus datos" onClick={next} />
      </div>
      <IconClose className="iconClose-mexico" onClick={close} />
    </div>
  );
};

AlertIncompleteData.propTypes = {
  visible: PropTypes.bool,
  next: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

AlertIncompleteData.defaultProps = {
  visible: false,
};

export default AlertIncompleteData;
