const environment = {
  DEV: {
    routesMicroserviceUrl: 'https://core.dev.mipaquete.com/routes', // Routes microservice development
    usersMicroserviceUrl: 'https://core.dev.mipaquete.com/users', // Users microservice development
    // usersMicroserviceUrl: 'http://localhost:3040/users', // Sendings microservice development
    NotificationsMicroserviceUrl: 'https://core.dev.mipaquete.com/notification', // Notifications microservice development
    sendingsMicroserviceUrl: 'https://core.dev.mipaquete.com/sendings', // Sendings microservice development
    // sendingsMicroserviceUrl: 'http://localhost:3050/sendings', // Sendings microservice development
    deliveryCompanyMicroserviceUrl: 'https://core.dev.mipaquete.com/deliverycompanies', // Delivery company microservice development
    cashMicroserviceUrl: 'https://core.dev.mipaquete.com/cash', // cash company microservice development
    noveltiesMicroserviceUrl: 'https://core.dev.mipaquete.com/novelties', // Ruta microservicio de novedades
    statisticsMicroserviceUrl: 'https://core.dev.mipaquete.com/statistics', // dashboard microservice development,
    // billingMicroserviceUrl: 'http://localhost:4080/billing', // billing microservice development
    billingMicroserviceUrl: 'https://core.dev.mipaquete.com/billing', // billing microservice development
    globalconfigMicroserviceUrl: 'https://core.dev.mipaquete.com/settings', // comfigGlobal route,
    reportingMicroserviceUrl: 'https://core.dev.mipaquete.com/reports', // reporting service route,
    mipqueteUrl: 'https://app.dev.mipaquete.com',
    interactionsMicroserviceUrl: 'https://core.dev.mipaquete.com/interactions',

    elpMicroserviceUrl: 'https://elp.dev.mpr.mipaquete.com/elp', // elp microservice route
    noveltiesCenterUrl: 'https://centrodenovedades.dev.mipaquete.com', // novelties center platform development
    adminUrl: 'https://administrativo.dev.mipaquete.com', // administrative platform development
    collectionPaidUrl: 'https://recaudos.mipaquete.com/', // collections paid platform development
    operationsUrl: 'https://operaciones.dev.mipaquete.com', // operation platform url

    // url archivo de configuracion desarrollo
    urlConfigFile: 'https://config-mpr-dev.s3.amazonaws.com/config.dev.json',
    urlConfigDeliveries: 'https://config-mpr-dev.s3.amazonaws.com/deliveryCompanies.json',

    // url archivo de masivos en desarrollo
    urlMassiveTemplateExcel: 'https://docuemnts-mpr-dev.s3.amazonaws.com/templates/masivo.xlsx',

    // customers key
    customerKeyNotifications: 'd7629d43-8f7f-4f59-bef1-64e375f593e5',
    customerKeyGlobalConfig: 'f2430064-6f40-40a5-8cc5-a42ce62420a1',

    // Variables de entorno desarrollo Tu Compra
    claveDeEncripcion: 'a8da40937f284f34a38e715c02ec0c1d',
    idSistema: 'f4pr0986ke2pbf91',
    gateWay: 'https://demover3-1.tucompra.net/tc/app/inputs/compra.jsp',
    configFileName: 'config.front.json',
  },
  PDN: {
    routesMicroserviceUrl: 'https://core.mipaquete.com/routes', // Routes microservice production
    usersMicroserviceUrl: 'https://core.mipaquete.com/users', // Users microservice production
    NotificationsMicroserviceUrl: 'https://core.mipaquete.com/notification', // Notifications microservice production
    sendingsMicroserviceUrl: 'https://core.mipaquete.com/sendings', // Sendings microservice production
    deliveryCompanyMicroserviceUrl: 'https://core.mipaquete.com/deliverycompanies', // Delivery company microservice production
    cashMicroserviceUrl: 'https://core.mipaquete.com/cash', // cash company microservice production
    noveltiesMicroserviceUrl: 'https://core.mipaquete.com/novelties', // Ruta microservicio de novedades producción
    elpMicroserviceUrl: 'https://core.mipaquete.com/elp', // elp microservice route
    noveltiesCenterUrl: 'https://centrodenovedades.mipaquete.com', // novelties center platform production
    billingMicroserviceUrl: 'https://core.mipaquete.com/billing',
    statisticsMicroserviceUrl: 'https://score.mipaquete.com/statistics', // dashboard microservice development,
    globalconfigMicroserviceUrl: 'https://core.mipaquete.com/settings', // comfigGlobal route,
    reportingMicroserviceUrl: 'https://core.mipaquete.com/reports', // reporting service route,
    mipqueteUrl: 'https://app.mipaquete.com',
    interactionsMicroserviceUrl: 'https://core.mipaquete.com/interactions',

    collectionPaidUrl: 'https://recaudos.mipaquete.com/', // collections paid platform production

    urlConfigFile: 'https://config-mpr-prod.s3.amazonaws.com/config.prod.json',
    urlConfigDeliveries: 'https://config-mpr-prod.s3.amazonaws.com/deliveryCompanies.json',

    adminUrl: 'https://administrativo.mipaquete.com',
    operationsUrl: 'https://operaciones.mipaquete.com', // operation platform url

    // url archivo de masivos en producción
    urlMassiveTemplateExcel: 'https://docuemnts-mpr-prod.s3.amazonaws.com/templates/masivo.xlsx',

    customerKeyNotifications: 'd7629d43-8f7f-4f59-bef1-64e375f593e5',
    customerKeyGlobalConfig: 'f2430064-6f40-40a5-8cc5-a42ce62420a1',

    // Variables de entorno produccion Tu Compra
    claveDeEncripcion: '1318c966e26a43b2a070e51b5b5e75570212',
    idSistema: 'x158objes15i4b5f',
    gateWay: 'https://gateway2.tucompra.com.co/tc/app/inputs/compra.jsp',
    configFileName: 'config.front.json',
  },
};

export default environment[process.env.REACT_APP_ENVIRONMENT];
