/* eslint-disable no-unneeded-ternary */
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';

import './modal.scss';

const Modal = ({
  className, children, isMobileDevice, closeBackground,
}) => ReactDOM.createPortal(
  <div className={`background-modal ${!isMobileDevice ? 'desktop-mode' : 'mobile-mode'}`} onClick={closeBackground}>
    <div
      onClick={(e) => e.stopPropagation()}
      className={`container-modal ${className ? className : ''}`}
    >
      {children}
    </div>
  </div>,
  document.getElementById('root'),
);

Modal.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  closeBackground: PropTypes.func,
};

Modal.defaultProps = {
  className: null,
  closeBackground: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default connect(mapStateToProps)(Modal);
