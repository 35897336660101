/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { humanizationOfTimeDuration } from '../../../utils/generals';
import { addThousandPoint } from '../../../utils/strings';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import ContentSection from '../../../components/contentSection';
import Typography from '../../../components/typography';
import Button from '../../../components/button';
import Input from '../../../components/input';

import ArrowBack from '../../../assets/images/icon-arrow-back-color-gray-dark.svg';
import ImageNoContent from '../../../assets/images/no-content.png';
import IconCash from '../../../assets/images/attach_money.svg';

import './redeemPoints.scss';

const RedeemPointsItemCard = ({
  name, image, description, points, deliveryTime,
  totalPoints, onClick, showMore, selected, benefictType,
}) => {
  const itIsPossibleToSelect = () => totalPoints > points;

  return (
    <div
      className={`
        redeem-points-item-card ${itIsPossibleToSelect() ? 'is-possible-to-selected' : ''}
        ${selected ? 'selected' : ''}
      `}
      onClick={() => itIsPossibleToSelect() && onClick()}
    >
      <img src={image || ImageNoContent} alt={`option ${name}`} />
      <div className="text-card">
        <div className="title-and-points-text-card">
          <Typography type="superTitle-italic">{name}</Typography>
          <Typography
            type="paragraph"
            className={`${itIsPossibleToSelect() ? 'color-primary' : 'color-red'} bold`}
          >
            {`${addThousandPoint(points)} ${points === 1 ? 'punto' : 'puntos'}`}
          </Typography>
        </div>
        <Typography type="paragraph">
          {benefictType === 'product' && 'Tiempo de entrega:'}
          {benefictType === 'service' && 'Tiempo de activación:'}
          {' '}
          {humanizationOfTimeDuration(deliveryTime)}
        </Typography>
        <div className="description-and-show-details-text-card">
          <Typography type="paragraph">
            {description?.length > 120 ? `${description.slice(0, 120)}...` : description}
          </Typography>
          <Typography
            type="superTitle-italic"
            className="color-accent"
          >
            <span
              onClick={(e) => {
                e.stopPropagation(e);
                showMore(e);
              }}
            >
              Ver detalles
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

const contentSection = (
  benefictType, optionList, benefictSelected, totalPoints, setContentModalConfirm, showMore,
  pointsToRedeem, setPointsToRedeem, errorInput, setErrorInput, handleChangeBenefictSelected,
  isMobileDevice,
) => {
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);
  switch (benefictType) {
    case 'product':
    case 'service':
      return (
        <div className={`container-list-redeem-point-section ${isMobileDevice ? 'vertical-list' : ''}`}>
          {optionList?.map((item) => (
            <RedeemPointsItemCard
              key={item.name.toString()}
              selected={benefictSelected?._id === item._id}
              image={item.images[0]}
              name={item.name}
              description={item.description}
              points={item.value}
              deliveryTime={item.deliveryTime}
              totalPoints={totalPoints}
              showMore={() => showMore(item)}
              benefictType={benefictType}
              onClick={() => (
                handleChangeBenefictSelected(benefictSelected?._id === item._id ? null : item)
              )}
            />
          ))}
        </div>
      );
    case 'rechargeBalance':
      return (
        <div className={`container-cash-redeem-point-section ${isMobileDevice ? 'mobile-mode' : ''}`}>
          <Input
            classNameContainer="input-cash-redeem-points"
            label="¿Cuántos puntos quieres redimir?"
            value={pointsToRedeem}
            onChange={setPointsToRedeem}
            type="number"
            iconRigth={IconCash}
            placeholder="13.000"
            annotationError={errorInput}
          />
          <Button
            className="button-cash-redeem-points"
            text="Redimir mis puntos"
            type={isCountryValid ? 'primary-mx' : 'primary'}
            onClick={() => (
              setContentModalConfirm && (
                (pointsToRedeem > 0 && totalPoints >= pointsToRedeem) ? setContentModalConfirm({
                  benefictType,
                  optionId: null,
                  optionName: null,
                  pointsCost: pointsToRedeem,
                }) : setErrorInput('Ingresa un valor mayor a 0 y menor que la cantidad de puntos acumulados.')
              )
            )}
          />
        </div>
      );
    default:
      return <></>;
  }
};

const RedeemPointsSection = ({
  benefictType, benefictName, optionList, handleChangeBenefictSelected, benefictSelected,
  points, back, isMobileDevice, setContentModalConfirm, showMore, next, loading,
}) => {
  const [pointsToRedeem, setPointsToRedeem] = useState(null);
  const [errorInput, setErrorInput] = useState(null);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <>
      <ContentSection
        className="redeem-point-section"
        headerText={benefictType === 'rechargeBalance' ? '¡Redime tus puntos!' : benefictName}
        headerChildrenLeft={(<img src={ArrowBack} alt="Arrow back" onClick={() => back && back()} />)}
      >
        <div className="container-redeem-point-section">
          <span>
            <Typography type="field">
              Puntos disponibles:
              {' '}
              <span className="color-primary heebo-regular">{addThousandPoint(points)}</span>
            </Typography>
          </span>
          {contentSection(
            benefictType, optionList, benefictSelected, points,
            setContentModalConfirm, showMore, pointsToRedeem, setPointsToRedeem,
            errorInput, setErrorInput, handleChangeBenefictSelected, isMobileDevice,
          )}
        </div>
      </ContentSection>

      {benefictSelected && (
        <Button
          className="button-flotant-redeem-beneficts"
          text={`Redimir ${benefictName.toLowerCase()}`}
          type={isCountryValid ? 'primary-mx' : 'primary'}
          loading={loading}
          onClick={next}
        />
      )}
    </>
  );
};

RedeemPointsSection.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  benefictName: PropTypes.string,
  benefictType: PropTypes.string,
  optionList: PropTypes.arrayOf(PropTypes.any),
  points: PropTypes.number.isRequired,
  back: PropTypes.func.isRequired,
};

RedeemPointsSection.defaultProps = {
  benefictName: null,
  optionList: null,
  benefictType: null,
};

export default RedeemPointsSection;
