/* eslint-disable quote-props */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import BankData from './infoUser/bankData';
import BasicInfoUser from './basicInfoUser';
import DinamicDataUser from './dinamicDataUser';
// import ContactInfo from './infoUser/contactInfo';
// import BillingData from './infoUser/billingData';

import Typography from '../../../components/typography';

import ArrowBack from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './profile.scss';

const ProfileScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();

  return (
    <div id="profile" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className={`header-profile ${isMobileDevice ? 'header-mobile' : 'desktop-mode'}`}>
        <div className="content-back">
          <img
            src={ArrowBack}
            alt="back"
            onClick={() => navigate('/cotizacion')}
          />
          <Typography
            type="superTitle-italic"
            text="Mi Perfil"
            className="button-back"
          />
        </div>
      </div>
      <div className={isMobileDevice ? 'profile-mobile' : 'profile'}>
        <div className="profile-direction">
          <BasicInfoUser user={user} isMobileDevice={isMobileDevice} />
          <DinamicDataUser
            user={user}
            isMobileDevice={isMobileDevice}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileScreen;
