/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import dayjs from 'dayjs';

import { showDependingOnBusinessFlag } from '../../../utils/businessFlag';
import { findIndexOfObject } from '../../../utils/filters';
import { getShippingsWithNoveltiesService } from '../../../services/novelties.service';
import { statesHomologation } from './trackingFunctions';
import { noveltiesCenterUrl } from '../../../constants';

import Typography from '../../typography';
import StepProgressBar from '../../stepProgressBar';
import Button from '../../button';

import './tracking.scss';

require('dayjs/locale/es');

dayjs.locale('es');

const DeliveryTrackingMobile = ({
  states, checkState,
}) => (
  <div className="delivery-tracking mobile">
    <StepProgressBar
      steps={states}
      orientation="vertical"
      currentStep={10}
      className={`select-step-${findIndexOfObject(true, states, 'checked') + 1}`}
      noAutomaticMeasure
    />
    <div className="container-states-and-details">
      {states.map((state) => (
        <div className="state-and-details" key={state.state}>
          <button
            type="button"
            className={`state ${state.checked ? 'checked' : ''} ${state.error ? 'error' : ''}`}
            onClick={() => {
              if (state.success) {
                checkState(state.state);
              }
            }}
          >
            {state.state}
          </button>
          {state.success && (
            <div className={`details ${state.checked ? 'show' : ''}`}>
              <div className="data">
                {state.icon && (
                  <img
                    src={state.icon}
                    alt=""
                  />
                )}
                <div className="container-dates">
                  <div className="primary-date">
                    <span className={`day ${state.error ? 'error' : ''}`}>
                      {moment(state.date).format('D')}
                    </span>
                    <span className="month-year">
                      <span>{moment(state.date).format('MMMM')}</span>
                      <span>{moment(state.date).format('YYYY')}</span>
                    </span>
                    <span className="hour">
                      {moment(state.date).format('h:m a')}
                    </span>
                  </div>
                </div>
              </div>
              {state.stateDescription && (
                <Typography
                  type="value"
                  className="state-description"
                  text={state.stateDescription}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
);

const DeliveryTrackingDesktop = ({
  states, checkState, stateToShow,
  novelties, noveltyCodes, businessFlags, access,
}) => {
  const showNovelty = (novelty) => novelty.description || (noveltyCodes && (
    noveltyCodes.find((noveltyCode) => (
      noveltyCode.code === novelty.noveltyCode
    ))?.description
  ));
  return (
    <div className="delivery-tracking desktop">
      <StepProgressBar
        steps={states}
        orientation="horizontal"
        currentStep={10}
        sizePoints={14}
        className={`select-step-${findIndexOfObject(true, states, 'checked') + 1}`}
      />
      <div className="container-states">
        {states.map((state) => (
          <button
            type="button"
            key={state.state}
            className={`state ${state.success ? 'pointer' : 'disabled'} ${state.checked ? 'checked' : ''} ${state.error ? 'error' : ''}`}
            onClick={() => {
              if (state.success) {
                checkState(state.state);
              }
            }}
          >
            {state.state}
          </button>
        ))}
      </div>
      {stateToShow() && (
        stateToShow().state === 'Envío con novedad' ? (
          <div className="container-details-novelties">
            <div className="head-table-details-novelties one">Causa de la novedad</div>
            <div className="head-table-details-novelties two">Observación</div>
            <div className="head-table-details-novelties three">Fecha de novedad</div>
            {novelties
              && novelties.length > 0
              && novelties.slice().reverse().map((novelty) => (
                <Fragment key={novelty.noveltyName}>
                  <Typography type="paragraph" className="one">
                    {novelty.noveltyName}
                  </Typography>
                  <Typography type="paragraph" className="two">
                    {showNovelty(novelty)}
                  </Typography>
                  <Typography type="paragraph" className="three">
                    {dayjs(novelty.reportDate).format('DD/MM/YYYY')}
                  </Typography>
                </Fragment>
              ))}
            {showDependingOnBusinessFlag({ businessFlags, section: 'noveltiesCenter' })
              && access !== 'public'
              && (
                novelties
                && novelties.length > 0
                && novelties.slice().find((novelty) => novelty.state === 'Sin gestión')
              ) && (
                <div className="button-manage-solution">
                  <Button
                    type="quaternary"
                    text="Solucionar novedad"
                    onClick={() => window.location.replace(noveltiesCenterUrl)}
                  />
                </div>
            )}
          </div>
        ) : (
          <div className="container-details">
            <div className="data">
              {stateToShow().icon && <img src={stateToShow().icon} alt="" />}
              {stateToShow().stateDescription && (
                <Typography type="paragraph" className="state-description">
                  {stateToShow().stateDescription}
                </Typography>
              )}
              <div className="container-dates">
                <div className="primary-date">
                  <span className={`day ${stateToShow().error ? 'error' : ''}`}>
                    {dayjs(stateToShow().date).format('DD')}
                  </span>
                  <span className="month-year">
                    <span>{dayjs(stateToShow().date).format('MMMM')}</span>
                    <span>{dayjs(stateToShow().date).format('YYYY')}</span>
                  </span>
                  <span className="hour">
                    {dayjs(stateToShow().date).format('hh:mm a')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

const Tracking = ({
  guideNumber, tracking, isMobileDevice, noveltyCodes, access, businessFlags,
}) => {
  const [states, setStates] = useState([]);
  const [novelties, setNovelties] = useState([]);
  const countryCode = useSelector((state) => state?.country);

  const getShippingWithNoveltiesFunction = async () => {
    if (guideNumber) {
      const responseShippingWithNovelties = await getShippingsWithNoveltiesService({ guideNumber });
      if (responseShippingWithNovelties?.sendings[0]?.novelties
      ) setNovelties(responseShippingWithNovelties?.sendings[0]?.novelties || []);
    }
  };

  useEffect(() => {
    const temporalStates = statesHomologation(
      tracking, getShippingWithNoveltiesFunction, countryCode);

    setStates(temporalStates);
  }, [tracking]);

  const checkState = (state) => {
    const temporalStates = states.slice();
    temporalStates.map((item) => {
      item.checked = false;
      return null;
    });
    const index = findIndexOfObject(state, states, 'state');
    temporalStates[index].checked = true;
    setStates(temporalStates);
  };

  const stateToShow = () => states.find((state) => state.checked) || null;

  return (
    <div className="container-tracking__shipping-details">
      {states && states.length > 0 && (
        <>
          <Typography type="superTitle" className="subtitle center">
            Estado
          </Typography>
          {isMobileDevice ? (
            <DeliveryTrackingMobile
              states={states}
              checkState={checkState}
            />
          ) : (
            <DeliveryTrackingDesktop
              states={states}
              checkState={checkState}
              stateToShow={stateToShow}
              tracking={tracking}
              novelties={novelties}
              noveltyCodes={noveltyCodes}
              businessFlags={businessFlags}
              access={access}
            />
          )}
        </>
      )}
    </div>
  );
};

Tracking.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  tracking: PropTypes.arrayOf(PropTypes.shape({})),
  guideNumber: PropTypes.string,
  noveltyCodes: PropTypes.arrayOf(PropTypes.shape({})),
  access: PropTypes.string.isRequired,
  businessFlags: PropTypes.shape({}).isRequired,
};

Tracking.defaultProps = {
  tracking: [],
  guideNumber: null,
  noveltyCodes: [],
};

DeliveryTrackingDesktop.propTypes = {
  states: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkState: PropTypes.func.isRequired,
  stateToShow: PropTypes.func.isRequired,
  novelties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noveltyCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  businessFlags: PropTypes.shape({}).isRequired,
  access: PropTypes.string.isRequired,
};

DeliveryTrackingMobile.propTypes = {
  states: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkState: PropTypes.func.isRequired,
};
export default Tracking;
