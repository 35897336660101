/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'moment/locale/es';
import swal from 'sweetalert';

import { toCurrencyCop } from '../../utils/strings';
import { cancelSendingService } from '../../services/sendings';
import getDeliveryCompaniesService from '../../services/deliveryCompany';
import { typeDelivery } from '../../constants/general';

import Typography from '../typography';
import Button from '../button';
import Tooltip from '../tooltip';
import Tracking from './traking/tracking';
import NoDeliveryConfirmation from './modals/deliveryConfirmation';
import SenderDetails from './senderDetails';

import DeliveryCompanyDetails from './deliveryCompanyDetails';

import IconArrowBack from '../../assets/images/icon-arrow-back-color-primary.svg';
import iconTooltip from '../../assets/images/Tooltip-disabled.svg';
import iconTooltipActive from '../../assets/images/Tooltip.svg';
import iconFeatherDownload from '../../assets/images/Icon-feather-download-line.svg';

import './shippingDetails.scss';

const ReceiverDetails = ({
  access, receiverName, receiverEmail, receiverCellPhone,
  receiverAddress, receiverLocation,
}) => access !== 'public' && (
  <div className="information-block">
    <Typography type="subtitle" className="subtitle">
      Destinatario
    </Typography>
    <Typography type="paragraph">{receiverName}</Typography>
    <Typography type="paragraph">{receiverEmail}</Typography>
    <Typography type="paragraph">{receiverCellPhone}</Typography>
    <Typography type="paragraph">{receiverAddress}</Typography>
    <Typography type="paragraph">{receiverLocation}</Typography>
  </div>
);
const ProductDetails = ({
  access, articleDescription, articleWidth, articleHeight, articleLength,
  articleWeight, articleWeightVolume, declaredValue, quantityOfArticles,
  tracking, shippingCancelled, cancellingShipping, shippingCancelFunction,
  mpCode, downloadShippingGuides, shippingId, executingDownload, stateShipping,
}) => {
  const user = useSelector((state) => state?.auth?.user || null);
  return (access !== 'public' && (
    <div className="information-block">
      <Typography type="subtitle" className="subtitle">
        Producto
      </Typography>
      <span className="container-field-and-value">
        <Typography type="paragraph" className="field margin-top-and-bottom">
          Descripción del producto:
        </Typography>
        <Typography type="paragraph">{articleDescription}</Typography>
      </span>
      <span className="container-field-and-value">
        <Typography type="paragraph" className="field">Ancho:</Typography>
        <Typography type="paragraph">{`${articleWidth}cm`}</Typography>
      </span>
      <span className="container-field-and-value">
        <Typography type="paragraph" className="field">Alto:</Typography>
        <Typography type="paragraph">{`${articleHeight}cm`}</Typography>
      </span>
      <span className="container-field-and-value">
        <Typography type="paragraph" className="field">Largo:</Typography>
        <Typography type="paragraph">{`${articleLength}cm`}</Typography>
      </span>
      <span className="container-field-and-value">
        <Typography type="paragraph" className="field">Peso:</Typography>
        <Typography type="paragraph">{`${articleWeight}Kg`}</Typography>
      </span>
      <span className="space-between margin-top-and-bottom">
        <span className="container-field-and-value">
          <Typography type="paragraph" className="field">Peso volumen:</Typography>
          <Typography type="paragraph">{`${articleWeightVolume}Kg`}</Typography>
        </span>
        <Tooltip
          overlayTitle="Peso/Volumen"
          overlayText="Peso volumen es la fórmula que utilizan las transportadoras
        para calcular el valor del envío según las dimensiones y el peso real."
          startingPosition="bottom-rigth"
        />
      </span>
      <span className="container-field-and-value">
        <Typography type="paragraph" className="field">Valor declarado por unidad:</Typography>
        <Typography type="paragraph">{toCurrencyCop(user, declaredValue)}</Typography>
      </span>
      <span className="container-field-and-value">
        <Typography type="paragraph" className="field">Cantidad de unidades:</Typography>
        <Typography type="paragraph">{quantityOfArticles}</Typography>
      </span>
      <div className="container-buttons">
        {stateShipping && (stateShipping === 'Recolección programada' || stateShipping === 'Envío programado' || stateShipping === 'Procesando tu envío' || stateShipping === 'Envío pendiente por pago') && !shippingCancelled && (
          <Button
            type="secondary"
            className="secondary"
            text="Cancelar envío"
            loading={cancellingShipping}
            onClick={() => shippingCancelFunction(mpCode)}
          />
        )}
        {tracking[tracking.length - 1].updateState === 'Entregado' && (
          <div className="button-and-tooltip">
            <Button
              type="tertiary"
              text="Comprobante"
              className="secondary"
              onClick={() => downloadShippingGuides(shippingId, true)}
              loading={executingDownload}
            >
              <span className="button-inner-text">Comprobante</span>
              <img src={iconFeatherDownload} alt="D" />
            </Button>
            <Tooltip
              iconTooltip={iconTooltip}
              iconTooltipHover={iconTooltipActive}
              overlayTextElement={(
                <p className="delivery-confirmation-normal-text">
                  <span className="delivery-confirmation-word-tooltip">Comprobante</span>
                  {' '}
                  de entrega sujeto a disponibilidad de la
                  {' '}
                  {typeDelivery[user?.countryCode]}
                </p>
              )}
              startingPosition="bottom-rigth"
            />
          </div>
        )}
      </div>
    </div>
  ));
};

const ShippingDetails = ({
  back,
  mpCode,
  guideNumber,
  senderName,
  senderAddress,
  senderLocation,
  receiverName,
  receiverEmail,
  receiverCellPhone,
  receiverAddress,
  receiverLocation,
  articleDescription,
  articleWidth,
  articleHeight,
  articleLength,
  articleWeight,
  articleWeightVolume,
  declaredValue,
  quantityOfArticles,
  deliveryCompanyImage,
  deliveryCompanyName,
  shippingValue,
  collectionService,
  recommendationsForDeliveryCompany,
  requestDate,
  saleValue,
  collectionValue,
  collectionComision,
  rateValue,
  valueToTransfer,
  tracking,
  access,
  afterShippingCanceled,
  shippingId,
  downloadShippingGuides,
  executingDownload,
  deliveryCompanyId,
  routeType,
  stateShipping,
}) => {
  const user = useSelector((state) => state?.auth?.user || null);
  const [cancellingShipping, setCancellingShipping] = useState(false);
  const [shippingCancelled, setShippingCancelled] = useState(false);
  const [showModalConfirmationDelivery, setShowModalConfirmationDelivery] = useState(false);
  const [realDeclaredValue, setRealDeclaredValue] = useState(0);

  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const noveltyCodes = useSelector((state) => state.globalData.noveltyCodes);
  const businessFlags = useSelector((state) => state.globalData.businessFlags);

  const validateDeclaredValue = async () => {
    const deliveryCompanies = await getDeliveryCompaniesService();
    const matchingCompany = deliveryCompanies.find(
      (company) => company._id === deliveryCompanyId);
    let delcaredValueFixed = declaredValue;
    if (matchingCompany && collectionService && matchingCompany.propertyDeclaredValueDefault === 'saleValue') {
      delcaredValueFixed = saleValue;
    }
    return delcaredValueFixed;
  };

  useEffect(async () => {
    tracking.forEach((state) => {
      if (state.updateState === 'Envío cancelado') setShippingCancelled(true);
    });
    setRealDeclaredValue(await validateDeclaredValue());
  }, [tracking]);

  const shippingCancelFunction = async (mpCodeParam) => {
    setCancellingShipping(true);
    try {
      const response = await cancelSendingService(null, parseInt(mpCodeParam, 10));
      if (response) {
        swal('¡Envío Cancelado!', 'El envío ha sido cancelado exitosamente.');
        if (afterShippingCanceled) afterShippingCanceled();
      }
    } catch {
      swal('Error', 'Ha habido un problema al intentar cancelar el envío, es posible que ya se haya cancelado o el estado actual no lo permita, de no ser asi, intentelo de nuevo.');
    } finally {
      setCancellingShipping(false);
    }
  };

  const showModalDeliveryConfirmation = () => setShowModalConfirmationDelivery(true);

  const downloadConfirmation = (shippingSelectedId, pdfConfirmation) => {
    downloadShippingGuides(shippingSelectedId, pdfConfirmation, showModalDeliveryConfirmation);
  };

  return (
    <div id="shipping-details">
      <div className="body">
        {access === 'public' && (
          <div className={`header ${access ?? ''}`}>
            <div onClick={() => back()} className="left">
              <img src={IconArrowBack} alt="" />
            </div>
            <div className="center">
              <span>{`MP${mpCode}`}</span>
              {access !== 'public' && (
                <span>
                  Nº de guía
                  {' '}
                  <span className="color-ligth">{guideNumber}</span>
                </span>
              )}
            </div>
            <div className="rigth" />
          </div>
        )}

        <div className={`side-top-or-bottom-container ${access === 'public' ? 'left' : ''}`}>
          <div>
            <SenderDetails
              access={access}
              senderName={senderName}
              senderAddress={senderAddress}
              senderLocation={senderLocation}
            />

            <DeliveryCompanyDetails
              access={access}
              collectionService={collectionService}
              deliveryCompanyImage={deliveryCompanyImage}
              deliveryCompanyName={deliveryCompanyName}
              shippingValue={shippingValue}
              recommendationsForDeliveryCompany={recommendationsForDeliveryCompany}
              requestDate={requestDate}
              saleValue={saleValue}
              collectionValue={collectionValue}
              collectionComision={collectionComision}
              rateValue={rateValue}
              valueToTransfer={valueToTransfer}
              senderLocation={senderLocation}
              receiverLocation={receiverLocation}
              isMobileDevice={isMobileDevice}
              guideNumber={guideNumber}
              routeType={routeType}
            />
          </div>

          <ReceiverDetails
            access={access}
            receiverName={receiverName}
            receiverEmail={receiverEmail}
            receiverCellPhone={receiverCellPhone}
            receiverAddress={receiverAddress}
            receiverLocation={receiverLocation}
          />

          <ProductDetails
            access={access}
            articleDescription={articleDescription}
            articleWidth={articleWidth}
            articleHeight={articleHeight}
            articleLength={articleLength}
            articleWeight={articleWeight}
            articleWeightVolume={articleWeightVolume}
            declaredValue={realDeclaredValue}
            quantityOfArticles={quantityOfArticles}
            tracking={tracking}
            shippingCancelled={shippingCancelled}
            cancellingShipping={cancellingShipping}
            shippingCancelFunction={shippingCancelFunction}
            mpCode={mpCode}
            shippingId={shippingId}
            downloadShippingGuides={downloadConfirmation}
            executingDownload={executingDownload}
            stateShipping={stateShipping}
          />
        </div>

        <div className="side-top-or-bottom-container">
          <Tracking
            noveltyCodes={noveltyCodes}
            guideNumber={guideNumber}
            isMobileDevice={isMobileDevice}
            tracking={tracking}
            access={access}
            businessFlags={businessFlags}
          />
        </div>

        {showModalConfirmationDelivery && (
          <NoDeliveryConfirmation
            closeModal={() => {
              try {
                setShowModalConfirmationDelivery(false);
              } catch (error) {
                setShowModalConfirmationDelivery(true);
              }
            }}
            user={user?.countryCode}
          />
        )}
      </div>
    </div>
  );
};

ShippingDetails.propTypes = {};

ShippingDetails.defaultProps = {};

export default ShippingDetails;
