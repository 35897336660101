/* eslint-disable max-len */
import saveAsExcel from '../../../../utils/exportxls';

const valueCollectionValidation = (item) => (item.state === 'Devuelto' ? 0 : item.valueCollection);
const discountValueValidation = (item) => (item.state === 'Devuelto' ? item.discountValue : 0);
const totalValueService = (item) => (item.adminTransactionData.sendingValue + item.adminTransactionData.collectionCommission);

const collectionExcel = (shipments) => {
  const [result] = shipments.reduce((acc, item) => {
    const bankValidation = item.bankTransferCost === 'Banco no encontrado' ? 0 : item.bankTransferCost;
    if (!acc.includes(bankValidation)) {
      acc.push(bankValidation);
    }
    return acc;
  }, []);

  const data = shipments.map((item) => ({
    Fecha_del_corte: new Date(item.paymentDate),
    Transportadora: item.deliveryCompanyName,
    Código_mipaquete: item.code,
    Número_guía: item.guideNumber,
    Destinatario: `${item.receiver.name} ${item.receiver.surname}`,
    Forma_de_pago: item.paymentType === 102 ? 'Descontado del recaudo' : 'Pagado con saldo',
    Estado: item.state,
    Fecha_de_entrega: new Date(item.deliveryDate),
    Valor_del_recaudo: valueCollectionValidation(item),
    Valor_total_del_servicio: totalValueService(item),
    Devolución_descontada: discountValueValidation(item),
    Valor_transferido_del_recaudo: item.transferCost,
    Fecha_de_pago_Recaudo: new Date(item.paymentDate),
    // eslint-disable-next-line max-len
    Documento_del_beneficiario: item.bankInformation.numberId,
    Banco: item.bankInformation.bank,
    Número_de_cuenta: item.bankInformation.accountNumber,
    Tipo_de_cuenta: item.bankInformation.typeAccount || item.bankInformation.accountType,
  }));
  data.push({ Fecha_del_corte: 'Total transferencia bancaria:', Transportadora: `$${result}` });
  saveAsExcel(data, 'informe de Recaudos');
};

export const generatorExcel = (shipments) => {
  const data = shipments.map((item) => ({
    Transportadora: item.deliveryCompanyName,
    Número_guía: item.guideNumber,
    Código_mipaquete: item.code,
    Destinatario: `${item.receiver.name} ${item.receiver.surname}`,
    Estado: item.state,
    Valor_descontado: item.discountValue || '',
    Valor_recaudo: item.valueCollection,
    Valor_transferido: item.transferCost,
  }));
  saveAsExcel(data, 'informe de Recaudos');
};

export default collectionExcel;
