/* eslint-disable quote-props */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/button';
import Typography from '../../../components/typography';

import pencil from '../../../assets/images/profile-pencil-icon.svg';
import ArrowBack from '../../../assets/images/icon-arrow-back-color-primary.svg';

import DataContactInfo from './dataContactInfo';

import './contactInfo.scss';
import ImageRedirectProfile from '../../../components/imageRedirectProfile';

const ContactInfoScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();

  return (
    <div id="profile" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className={`header-profile ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
        <div className="content-back">
          <ImageRedirectProfile
            src={ArrowBack}
            url="/perfil"
          />
          <Typography
            type="superTitle-italic"
            text="Información de contacto"
            className="button-back"
          />
        </div>
        <div className="editButton">
          {isMobileDevice ? (
            <div>
              <ImageRedirectProfile
                src={pencil}
                url="/editar-perfil/informacion-de-contacto"
              />
            </div>
          ) : (
            <Button
              text="Editar"
              type="tertiary"
              onClick={() => navigate('/editar-perfil/informacion-de-contacto')}
            />
          )}
        </div>
      </div>
      <div className="profile">
        <DataContactInfo
          user={user}
          isMobileDevice={isMobileDevice}
        />
      </div>
    </div>
  );
};

export default ContactInfoScreen;
