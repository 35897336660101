import { yupResolver } from '@hookform/resolvers/yup';
import { useForm as useFormHook } from 'react-hook-form';

const useFields = (defaultValues, schema) => {
  const {
    control,
    reset,
    resetField,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields },
    setValue,
    setError,
    watch,
    trigger,
    register,
  } = useFormHook({
    defaultValues,
    resolver: schema && yupResolver(schema),
  });
  const isComplete = () => {
    const isDirtyFields = Object.keys(defaultValues).filter(
      (key) => dirtyFields[key],
    );
    return isDirtyFields.length === Object.keys(defaultValues).length;
  };
  const getErros = () => {
    const keys = Object.keys(errors);
    const results = {};
    // eslint-disable-next-line no-return-assign
    keys.forEach((key) => results[key] = errors?.[key]?.message);
    return results;
  };
  const resetFieldIntern = (name, value) => {
    resetField(name, {
      defaultValue: value,
    });
  };
  return {
    reset,
    control,
    isComplete,
    handleSubmit,
    setValue,
    errors: getErros(),
    values: getValues(),
    resetField: resetFieldIntern,
    setError,
    watch,
    trigger,
    register,
  };
};

export default useFields;
