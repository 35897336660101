import configS3 from '../../config/nocompanyconfig.fron.json';
import bankS3 from '../../config/config.bank.json';
import deliveryCompaniesS3 from '../../config/deliveryCompanies.json';

import { capitalizeWords } from '../../utils/strings';
import { getLocationsService } from '../../services/location';
import getDeliveryCompaniesService from '../../services/deliveryCompany';
import { getNoveltyCodesService } from '../../services/novelties.service';

const filter = (array, companiesId) => (
  array.filter(
    (company) => companiesId.includes(company.deliveryCompany))
);

const country = '170';

const loadGlobalData = (LoadDataGlobalFunction) => new Promise((resolve) => {
  const config = configS3;
  const bankConfig = bankS3[country];
  const companiesconfig = deliveryCompaniesS3;

  let responseLocations = [];
  let responseDeliveryCompanies = [];
  let responseNoveltyCodes = [];

  Promise.allSettled([
    getLocationsService(),
    getDeliveryCompaniesService(),
    getNoveltyCodesService({ module: 'operations' }),
  ]).then((response) => {
    response.forEach((item, index) => {
      if (item.status === 'fulfilled') {
        if (index === 0) responseLocations = item.value;
        if (index === 1) responseDeliveryCompanies = item.value;
        if (index === 2) responseNoveltyCodes = item.value;
      }
    });

    LoadDataGlobalFunction({
      noveltyCodes: responseNoveltyCodes,
      locations: responseLocations,
      deliveryCompaniesConfig: companiesconfig.configTransporters,
      businessFlags: config.businessFlags,
      beneficts: config.beneficts,
      negotationSchemas: companiesconfig.negotationSchemas,
      showNegotiationInformation: companiesconfig.showNegotiationInformation,
      wompiPublicKey: config.wompiPublicKey,
      bankTransferFee: bankConfig,
      deliveryCompanies: responseDeliveryCompanies.slice().map((deliveryCompany) => {
        const newDeliveryCompany = deliveryCompany;
        newDeliveryCompany.name = capitalizeWords(deliveryCompany.name);
        return newDeliveryCompany;
      }),
      myTransportersSetting: companiesconfig.myTransportersSetting,
      deliveryDisabled: companiesconfig.deliveryDisabled,
    });

    resolve();
  });
});

export const updateGlobalDeliveryCompaniesData = async (UpdateDataGlobalFunction) => {
  const deliveryCompanies = await getDeliveryCompaniesService();
  const companiesId = deliveryCompanies.map((company) => company._id);

  const config = deliveryCompaniesS3;

  UpdateDataGlobalFunction({
    deliveryCompaniesConfig: filter(config.configTransporters, companiesId),
    negotationSchemas: filter(config.negotationSchemas, companiesId),
    showNegotiationInformation: filter(config.showNegotiationInformation, companiesId),
    deliveryCompanies: deliveryCompanies.slice().map((deliveryCompany) => {
      const newDeliveryCompany = deliveryCompany;
      newDeliveryCompany.name = capitalizeWords(deliveryCompany.name);
      return newDeliveryCompany;
    }),
  });
};

export default loadGlobalData;
