import loadGlobalData from '../components/globalData/load';
import { nameLocalStorage } from '../constants';

export const getGlobalData = (LoadDataGlobalFunction, setLoadingData, setLoadingToken) => {
  setLoadingToken(true);

  loadGlobalData(LoadDataGlobalFunction).then(() => {
    setLoadingData(false);
    if (!localStorage.getItem(nameLocalStorage) && !sessionStorage.getItem(nameLocalStorage)) {
      setLoadingToken(false);
    }
  });
};

export default {
  getGlobalData,
};
