/* eslint-disable no-restricted-exports */
/* eslint-disable no-underscore-dangle */
import { getSendingsService, getShipmentService } from '../../services/sendings';

const useGetSendingsShippingAndQuotes = async (
  user,
  page,
  numberItemsPerPage,
  deliveryCompany,
  state,
  mpCode,
  guideNumber,
  receiverCellphone,
  destinyLocationCode,
) => {
  const responseShippings = await getSendingsService(
    user && user._id,
    page,
    numberItemsPerPage,
    null, // initialDateConfirmation
    null, // finalDateConfirmation
    null, // initialDateDelivery
    null, // finalDateDelivery
    deliveryCompany, // deliveryCompany
    null, // service
    state, // state
    mpCode, // mpCode
    guideNumber, // guideNumber
    receiverCellphone, // receiverCellphone
    null, // senderNit
    destinyLocationCode, // destinyLocationCode
  );
  return responseShippings;
};

export { useGetSendingsShippingAndQuotes as default };

export const getShipments = async (filtersAndPagination) => (
  getShipmentService(filtersAndPagination));
