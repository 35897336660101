import { validationValue, insertOrRemoveErrorFromList } from '../../../utils/strings';

const evaluateToInsertOrRemove = (boolean) => (boolean ? 'remove' : 'insert');

export const validationSteps = ({
  errorList, setErrorList, name, surname, email, cellPhone, country,
  averageShipments, averageShipmentsOptions, password, confirmPassword,
}) => {
  const temporalErrorList = errorList.slice();

  if (email) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'email',
      'Ingrese un correo válido',
      evaluateToInsertOrRemove(validationValue(email, 'email').correct),
    );
  }

  if (name) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'name',
      'Ingresa tu nombre para continuar',
      evaluateToInsertOrRemove(validationValue(name).correct),
    );
  }

  if (surname) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'surname',
      'Ingresa tus apellidos para continuar',
      evaluateToInsertOrRemove(validationValue(surname).correct),
    );
  }

  if (cellPhone) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'cellPhone',
      `El número de celular debe ser ${country} y de 10 dígitos`,
      evaluateToInsertOrRemove(validationValue(cellPhone, 'cellPhone').correct),
    );
  }

  if (averageShipments && averageShipmentsOptions) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'averageShipments',
      'Debes elegir uno de los datos de la lista',
      evaluateToInsertOrRemove(
        averageShipmentsOptions.find((quanity) => quanity.value === averageShipments),
      ),
    );
  }

  if (password && !confirmPassword) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'password',
      'Ingresa una contraseña válida.',
      evaluateToInsertOrRemove(validationValue(password, 'password').correct),
    );
  }

  if (password && confirmPassword) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'confirmPassword',
      'Las contraseñas deben coincidir.',
      evaluateToInsertOrRemove(confirmPassword === password),
    );
  }

  setErrorList(temporalErrorList);
};

export const stepOneValidation = () => null;
export const stepTwoValidation = () => null;
export const stepThreeValidation = () => null;

export default { validationSteps };
