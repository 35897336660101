import { useSelector } from 'react-redux';
import React from 'react';

import InvoicingData from './contactInfo';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './contactInfoScreen.scss';

const ContactInfoScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile className="profile-information">
        <InvoicingData />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <InvoicingData />
      </ContainerSectionDesktop>
    )
  );
};

export default ContactInfoScreen;
