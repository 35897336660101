/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { updateUser } from '../../services/user';
import { SignInAction } from '../../store/actions/auth.actions';
import { useValidationCountry, useFetchIpData } from '../../utils/validationCountry';

import Typography from '../typography';
import Modal from '../modal';
import Button from '../button';
import Checkbox from '../checkBox';

import './modalNewTyC.scss';

const ModalNewTyC = () => {
  const dispatch = useDispatch();
  const { temporalCredentials, showModalNewTyC } = useSelector((state) => state?.auth || {});

  const [executingAction, setExecutingAction] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const acceptNewTermsAndConditions = async () => {
    try {
      setExecutingAction(true);
      const responseUser = await updateUser({ newTyc: true }, temporalCredentials.id);
      responseUser.password = temporalCredentials.password;
      dispatch(SignInAction(responseUser));
      setErrorMessage(null);
    } catch (e) {
      setErrorMessage('Ha ocurrido un error al aceptar los términos y condiciones, inténtelo de nuevo.');
    } finally {
      setExecutingAction(false);
    }
  };

  return showModalNewTyC ? (
    <Modal className="modal-new-terms-and-conditions">
      <Typography type="superTitle-italic">Actualizamos los términos</Typography>
      <Typography type="paragraph">
        Mipaquete.com y nuestra visión de la logística de envíos sigue evolucionando,
        por lo que hemos actualizado
        {' '}
        <span onClick={() => window.open('https://mipaquete.com/politicas-privacidad-de-datos-terminos-y-condiciones/', '_blank')}>
          la política de tratamiento de datos, los términos y condiciones de uso
        </span>
        .
        {' '}
        recomendamos que leas detenidamente las nuevas versiones.
      </Typography>
      <Checkbox
        className="accept-new-terms-and-conditions"
        checked={acceptTermsAndConditions}
        onChange={setAcceptTermsAndConditions}
        text="He leído y comprendido la actualización de la política del tratamiento de datos y los términos y condiciones de uso."
      />
      <Button
        text="Aceptar"
        onClick={() => acceptNewTermsAndConditions()}
        disabled={!acceptTermsAndConditions}
        loading={executingAction}
        type={isCountryValid ? 'primary-mx' : 'primary'}
      />
      <span className="error-message">{errorMessage}</span>
    </Modal>
  ) : null;
};

export default ModalNewTyC;
