import React from 'react';
import PropTypes from 'prop-types';

import { totalPrices } from 'utils/prices';

import DetailsPricesDiscounts from 'components/detailsPricesDiscounts';

import IconClose from '../../assets/images/icon-close-color-primary.svg';

import './dataCollectionCommisionDetails.scss';

const DataCollectionCommissionDetails = ({
  handleClose,
  user,
  commissionCollectionValue,
  discountCommissionCollectionValue,
  discountFreightValue,
  freightValue,
  insuranceValue,
  titleCollectionService,
  titleDiscountShipping,
}) => (
  <div>
    <div className="container-data-overlay" />
    <div className="container-data show">
      <p className="title">Desglose costo</p>
      <div className="container-data__commission-details-header">
        <button type="submit" onClick={handleClose} aria-label="close" className="close-icon">
          <img src={IconClose} alt="" />
        </button>
      </div>
      <DetailsPricesDiscounts
        user={user}
        freightValue={freightValue}
        commissionCollectionValue={commissionCollectionValue}
        discountCommissionCollectionValue={discountCommissionCollectionValue}
        discountFreightValue={discountFreightValue}
        insuranceValue={insuranceValue}
        titleCollectionService={titleCollectionService}
        titleDiscountShipping={titleDiscountShipping}
        totalDiscountCommissionCollection={totalPrices(
          commissionCollectionValue,
          discountCommissionCollectionValue,
        )}
        totalDiscountShipping={totalPrices(
          insuranceValue,
          freightValue,
          discountFreightValue,
        )}
        totalSaved={Math.abs(totalPrices(
          discountFreightValue,
          discountCommissionCollectionValue,
        ))}
      />
    </div>
  </div>
);

DataCollectionCommissionDetails.propTypes = {
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  commissionCollectionValue: PropTypes.number,
  discountCommissionCollectionValue: PropTypes.number,
  discountFreightValue: PropTypes.number,
  freightValue: PropTypes.number,
  insuranceValue: PropTypes.number,
  titleCollectionService: PropTypes.string.isRequired,
  titleDiscountShipping: PropTypes.string.isRequired,
};

DataCollectionCommissionDetails.defaultProps = {
  commissionCollectionValue: null,
  discountCommissionCollectionValue: null,
  discountFreightValue: null,
  freightValue: null,
  insuranceValue: null,
};

export default DataCollectionCommissionDetails;
