import React from 'react';
import PropTypes from 'prop-types';

import { toCurrencyCop } from 'utils/strings';

import './detailsPricesDiscounts.scss';
import { connect } from 'react-redux';

const DetailsPricesDiscounts = ({
  commissionCollectionValue,
  discountCommissionCollectionValue,
  discountFreightValue,
  freightValue,
  insuranceValue,
  titleCollectionService,
  titleDiscountShipping,
  totalDiscountCommissionCollection,
  totalDiscountShipping,
  user,
  totalSaved,
  isMobileDevice,
}) => {
  const totalToPay = toCurrencyCop(user, totalDiscountShipping + totalDiscountCommissionCollection);
  const classTotalToPayAmount = () => {
    if (!(totalSaved
      && Number(discountCommissionCollectionValue) <= 0
      && Number(discountFreightValue) <= 0)) {
      return 'only-pay-amount';
    }
    return 'total-to-pay-amount';
  };
  return (
    <>
      <div className={`container-details-prices-discounts ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
        {discountFreightValue && (
          <>
            <p className="container-details-prices-discounts__title">{titleDiscountShipping}</p>
            <div className="container-details-prices-discounts__list">
              <p className="amount">
                <span>Flete</span>
                <span className="value">{toCurrencyCop(user, freightValue)}</span>
              </p>

              <p className="amount">
                <span>Dcto en Flete</span>
                <span className="value-discount">{toCurrencyCop(user, discountFreightValue)}</span>
              </p>

              <p className="amount">
                <span>Seguro</span>
                <span className="value">{toCurrencyCop(user, insuranceValue)}</span>
              </p>
            </div>
            <p className="container-details-prices-discounts__final-price">{toCurrencyCop(user, totalDiscountShipping)}</p>
          </>
        )}
        {discountCommissionCollectionValue && (
          <>
            <p className="container-details-prices-discounts__title">{titleCollectionService}</p>
            <div className="container-details-prices-discounts__list">
              <p className="amount">
                <span>Comisión de recaudo</span>
                <span className="value">{toCurrencyCop(user, commissionCollectionValue)}</span>
              </p>
              <p className="amount">
                <span>Dcto en comisión</span>
                <span className="value-discount">{toCurrencyCop(user, discountCommissionCollectionValue)}</span>
              </p>
            </div>
            <p className="container-details-prices-discounts__final-price">{toCurrencyCop(user, totalDiscountCommissionCollection)}</p>
          </>
        )}
      </div>
      {totalSaved > 0 && (
        <p className="total-saved-amount">
          <span className="text">Total ahorrado:</span>
          <span className="amount">{toCurrencyCop(user, totalSaved)}</span>
        </p>
      )}
      <p className={classTotalToPayAmount()}>
        <span className="text">Total a pagar:</span>
        <span className="amount">{totalToPay}</span>
      </p>
    </>
  );
};

DetailsPricesDiscounts.propTypes = {
  user: PropTypes.shape({
    countryCode: PropTypes.string.isRequired,
  }).isRequired,
  commissionCollectionValue: PropTypes.number,
  discountCommissionCollectionValue: PropTypes.number,
  discountFreightValue: PropTypes.number,
  freightValue: PropTypes.number.isRequired,
  insuranceValue: PropTypes.number.isRequired,
  titleCollectionService: PropTypes.string.isRequired,
  titleDiscountShipping: PropTypes.string.isRequired,
  totalDiscountCommissionCollection: PropTypes.number.isRequired,
  totalDiscountShipping: PropTypes.number.isRequired,
  totalSaved: PropTypes.number.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

DetailsPricesDiscounts.defaultProps = {
  commissionCollectionValue: null,
  discountCommissionCollectionValue: null,
  discountFreightValue: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  deliveryCompaniesConfig: state.globalData.deliveryCompaniesConfig,
  user: state.auth.user,
});
export default connect(mapStateToProps)(DetailsPricesDiscounts);
