/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import {
  AUTH_IN, AUTH_OUT, AUTH_UPDATE, SHOW_MODAL_NEW_TYC,
  AUTH_UPDATE_TOKEN, AUTH_TEMPORAL_DATA, getAction,
} from './type.actions';

export const SignInAction = (user) => {
  const acceptanceDate = new Date(user?.newTyc?.acceptanceDate ?? null);
  const updateTyC = new Date('2023-07-23T00:00:00');

  if (user.newTyc && acceptanceDate >= updateTyC) {
    return getAction(AUTH_IN, user);
  }
  return getAction(
    SHOW_MODAL_NEW_TYC,
    {
      id: user._id,
      email: user.email,
      password: user.password,
    },
  );
};

export const UpdateUserAction = (user) => getAction(AUTH_UPDATE, user);
export const UpdateTokenAction = (token) => getAction(AUTH_UPDATE_TOKEN, token);
export const SaveTemporalDataAction = (data) => getAction(AUTH_TEMPORAL_DATA, data);

export const LogoutAction = () => getAction(AUTH_OUT);
