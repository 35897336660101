/* eslint-disable no-unused-expressions */
/* eslint-disable quote-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { stepsValidation } from '../../../../../components/completingData/quoteCompletingSteps/validation';
import { optionsSelectFiltered } from '../../../../../utils/generals';

import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import Select from '../../../../../components/select';
import Typography from '../../../../../components/typography';
import SuccessModal from '../../../../../components/successModal';

import {
  personTypeOptions,
  documentTypeOptions,
} from '../../../../../constants/general';
import { findLocationByName, findObjectInArray } from '../../../../../utils/filters';
import { UpdateUserAction } from '../../../../../store/actions/auth.actions';
import { updateUserService } from '../../../../../services/user';
import createOrUpdateInvoicingData from '../service';
import { getLocationService } from '../../../../../utils/users/completeDataModal';

import IconSearch from '../../../../../assets/images/search.svg';
import ColombiaFlag from '../../../../../assets/images/colombia.svg';

import '../billingDataEdit.scss';

const BillingDataEditColombia = ({ user, isMobileDevice }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [locations, setLocations] = useState('');
  const [name, setName] = useState(`${user.name || ''} ${user.surname || ''}`);
  const [newLocation, setNewLocation] = useState('');
  const [nitValidate, setNitValidate] = useState(user.documentNumber?.split('-')[1] || '');
  const [annotationError, setAnnotationError] = useState('');
  const [newLocationCode, setNewLocationCode] = useState('');
  const [tradeName, setTradeName] = useState(user?.tradeName || '');
  const [cellPhone, setCellPhone] = useState(user?.cellPhone || '');
  const [personType, setPersonType] = useState(user?.personType || '');
  const [address, setAddress] = useState(user?.invoicingData?.address || '');
  const [documentType, setDocumentType] = useState(user?.documentType || '');
  const [documentNumber, setDocumentNumber] = useState(user.documentNumber?.split('-')[0] || '');
  const [emailForBilling, setEmailForBilling] = useState(user?.invoicingData?.emailForBilling || '');

  const [errors, setErrors] = useState({});
  const [errorsList, setErrorsList] = useState([]);
  const [modalContent, setModalContent] = useState(false);
  const [modalContentSuccess, setModalContentSuccess] = useState(false);

  const documentNumberValidate = () => {
    if (personType === 'Persona Jurídica') {
      return `${documentNumber}-${nitValidate}`;
    }
    return documentNumber;
  };

  const handleSaveChanges = async () => {
    try {
      let updatedUser = {};
      let updatedUserData = {
        name: name.split(' ')[0],
        surname: name.split(' ')[1],
        personType,
        documentType,
        documentNumber: documentNumberValidate(),
        cellPhone,
      };

      if (tradeName.trim() !== '') {
        updatedUserData = {
          ...updatedUserData,
          tradeName,
        };
      }

      const isUpdateRequired = Object.keys(updatedUserData).length > 0;
      if (isUpdateRequired) {
        updatedUser = await updateUserService(user._id, updatedUserData);
      }
      const updateInvoicingData = await createOrUpdateInvoicingData(user, {
        address,
        emailForBilling,
        locationName: newLocation,
        locationCode: newLocationCode,
        locationId: locations._id,
        countryCode: user.countryCode,
      });
      dispatch(UpdateUserAction({
        ...updatedUser,
        invoicingData: {
          ...updateInvoicingData,
        },
      }));
      setErrors({});
      setModalContentSuccess(true);
    } catch (error) {
      setModalContent(true);
    }
  };

  const handleModalClose = () => {
    setModalContentSuccess(false);
    navigate('/perfil/datos-de-facturacion');
  };

  useEffect(() => {
    getLocationService(user, setLocations, setNewLocation);
  }, []);

  useEffect(() => {
    const originSplitted = newLocation.split('-');
    if (originSplitted.length > 1) {
      const originObject = findLocationByName(
        originSplitted[0], originSplitted[1], locations,
      );
      setNewLocationCode(originObject?.locationCode ?? '');
    }
  }, [newLocation]);

  const title = () => (
    <div>
      Recibirás tu
      {' '}
      <span>factura </span>
      {' '}
      con los siguientes datos. Verifica que sean los correctos.
    </div>
  );

  return (
    <div className={`content-edit-info ${isMobileDevice && 'billing-edit-mobile'}`}>
      <div className="content-with-button">
        <Typography
          className="main-title"
          text={title()}
        />
        <div className="input-content">
          <div className="content-edit-left">
            <div className="selectContainer">
              <Typography className="textType" text="Tipo de persona" type="title" />
              <Select
                label="Selecciona una opción"
                value={personType}
                onChange={(value) => {
                  setPersonType(value);
                  setDocumentType('');
                  if (value === 'Persona Jurídica') {
                    setDocumentType('NIT');
                  }
                }}
                startingPosition="top-left"
                options={personTypeOptions}
              />
            </div>
            <div className="selectContainer">
              <Typography className="textType" text="Tipo de documento" type="title" />
              <Select
                label="Selecciona una opción"
                value={documentType}
                onChange={(value) => { setDocumentType(value); setDocumentNumber(''); }}
                startingPosition="top-left"
                disabled={personType === 'Persona Jurídica'}
                options={optionsSelectFiltered('documentType', documentTypeOptions, personType)}
              />
            </div>
            {personType === 'Persona Natural' && (
              <div className="inputContainer">
                <Input
                  label="Número de identificación"
                  placeholder="12121212"
                  value={documentNumber}
                  onChange={(value) => {
                    if (documentType === 'CC') {
                      const numericValue = value.replace(/\D/g, '');
                      setDocumentNumber(numericValue);
                    } else {
                      setDocumentNumber(value);
                    }
                  }}
                  onBlur={(e) => {
                    stepsValidation(
                      'identificationNumber',
                      'Ingrese un número de de identificación',
                      e.target.value,
                      errorsList,
                      setErrorsList,
                    );
                  }}
                  annotationError={findObjectInArray('identificationNumber', errorsList, 'name')?.error ?? null}
                />
              </div>
            )}
            {personType === 'Persona Jurídica' && (
              <div className="nit-container">
                <div className="inputContainer-nit">
                  <Input
                    label="Número de NIT"
                    value={documentNumber}
                    placeholder="1234567898"
                    onChange={(value) => {
                      const numericValue = value.replace(/\D/g, '');
                      setDocumentNumber(numericValue);
                    }}
                    onBlur={(e) => {
                      stepsValidation(
                        'identificationNumber',
                        'Ingrese un NIT válido',
                        e.target.value,
                        errorsList,
                        setErrorsList,
                      );
                    }}
                    annotationError={findObjectInArray('identificationNumber', errorsList, 'name')?.error ?? null}
                  />
                </div>
                <span className="line">-</span>
                <div className="inputContainer-number">
                  <Input
                    label="Código de verificación"
                    value={nitValidate}
                    placeholder={1}
                    onChange={(value) => {
                      const numericValue = value.replace(/[^\d]/g, '');
                      const sanitizedValue = numericValue.slice(0, 1);
                      setNitValidate(sanitizedValue);
                    }}
                    onBlur={(e) => {
                      stepsValidation(
                        'codeVerification',
                        'ingresa solo números',
                        e.target.value,
                        errorsList,
                        setErrorsList,
                      );
                    }}
                    annotationError={findObjectInArray('codeVerification', errorsList, 'name')?.error ?? null}
                  />
                </div>
              </div>
            )}
            {personType === 'Persona Natural'
              && (
                <div className="inputContainer">
                  <Input
                    label="Nombre completo"
                    placeholder="Juan Pablo Pérez Botero"
                    value={name}
                    onChange={(value) => {
                      setName(value);
                      if (findObjectInArray('name', errorsList, 'name')) {
                        stepsValidation(
                          'name',
                          'Ingrese un nombre',
                          value,
                          errorsList,
                          setErrorsList,
                          locations,
                        );
                      }
                    }}
                    onBlur={(e) => {
                      stepsValidation(
                        'name',
                        'Ingrese un nombre',
                        e.target.value,
                        errorsList,
                        setErrorsList,
                      );
                    }}
                    annotationError={findObjectInArray('name', errorsList, 'name')?.error ?? null}
                  />
                </div>
              )}
            {personType === 'Persona Jurídica'
              && (
                <div className="inputContainer">
                  <Input
                    label="Razón social"
                    placeholder="Mi Paquete"
                    value={tradeName}
                    onChange={setTradeName}
                    onBlur={(e) => {
                      stepsValidation(
                        'name',
                        'Ingresa tu razón social',
                        e.target.value,
                        errorsList,
                        setErrorsList,
                      );
                    }}
                    annotationError={findObjectInArray('name', errorsList, 'name')?.error ?? null}
                  />
                </div>
              )}
          </div>
          <div className="content-edit-right">
            <div className="inputContainer">
              <Input
                label="Ciudad"
                value={newLocation}
                placeholder="MEDELLÍN-ANTIOQUIA"
                onChange={(value) => {
                  setNewLocation(value);
                  !value ? setAnnotationError('Ingresa una ciudad válida*') : setAnnotationError(null);
                }}
                onClick={(value) => {
                  !value ? setAnnotationError('Ingresa una ciudad válida*') : setAnnotationError(null);
                }}
                annotationError={annotationError}
                dataList={locations && locations.map((location) => `${location.locationName}-${location.departmentOrStateName}`)}
                iconRigth={IconSearch}
              />
            </div>
            <div className="inputContainer">
              <Input
                label="Dirección"
                value={address}
                placeholder="Cra 30 # 56 - 123"
                onChange={(value) => {
                  setAddress(value);
                  if (findObjectInArray('address', errorsList, 'name')) {
                    stepsValidation(
                      'address',
                      'Ingresa una dirección válida',
                      value,
                      errorsList,
                      setErrorsList,
                      locations,
                    );
                  }
                }}
                onBlur={(e) => {
                  stepsValidation(
                    'address',
                    'Ingresa un email válido',
                    e.target.value,
                    errorsList,
                    setErrorsList,
                  );
                }}
                annotationError={findObjectInArray('address', errorsList, 'name')?.error ?? null}
              />
            </div>
            <div className="inputContainer">
              <Input
                label="Celular"
                placeholder="3003003030"
                value={cellPhone}
                onChange={(value) => {
                  setCellPhone(value);
                  if (findObjectInArray('cellPhone', errorsList, 'name')) {
                    stepsValidation(
                      'cellPhone',
                      'Ingresa un número válido',
                      value,
                      errorsList,
                      setErrorsList,
                      locations,
                    );
                  }
                }}
                iconLeft={ColombiaFlag}
                onBlur={(e) => {
                  stepsValidation(
                    'cellPhone',
                    'Ingresa un número válido',
                    e.target.value,
                    errorsList,
                    setErrorsList,
                  );
                }}
                annotationError={findObjectInArray('cellPhone', errorsList, 'name')?.error ?? null}
              />
            </div>
            <div className="inputContainer">
              <Input
                label="E-mail para facturación electrónica"
                value={emailForBilling}
                placeholder="micorreo@gmail.com"
                onChange={(value) => {
                  setEmailForBilling(value);
                  if (findObjectInArray('emailForBilling', errorsList, 'name')) {
                    stepsValidation(
                      'emailForBilling',
                      'Ingresa una email válido',
                      value,
                      errorsList,
                      setErrorsList,
                      locations,
                    );
                  }
                }}
                onBlur={(e) => {
                  stepsValidation(
                    'emailForBilling',
                    'Ingresa un email válido',
                    e.target.value,
                    errorsList,
                    setErrorsList,
                  );
                }}
                annotationError={findObjectInArray('emailForBilling', errorsList, 'name')?.error ?? null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="content-button">
        <Button
          onClick={handleSaveChanges}
          type="primary"
          text="Guardar cambios"
          disabled={!errors || (documentType === 'NIT' && !nitValidate) || errorsList.length > 0}
        />
      </div>
      {modalContent && (
        <SuccessModal
          text="Hubo un error al guardar los cambios"
          onClose={() => setModalContent(false)}
        />
      )}
      {modalContentSuccess && (
        <SuccessModal
          text="Los cambios se han guardado exitosamente"
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

BillingDataEditColombia.propTypes = {
  user: PropTypes.shape().isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

BillingDataEditColombia.defaultProps = {

};

export default BillingDataEditColombia;
