/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { toCurrencyCop } from '../../utils/strings';

import ToggleSwitch from '../toggleSwitch';

import Typography from '../typography';
import './stateSwitch.scss';

const StateSwitch = ({
  text, active, cost, isMobileDevice, constDisable, onChange, user,
}) => (
  <>
    <div className={`container-state-switch ${isMobileDevice ? 'mobile-mode' : ''}`}>
      <div className={`container-price ${!isMobileDevice ? 'desktop-mode' : ''}`}>
        <Typography
          type="superTitle"
          text={text}
          className="text-state-switch hebboMedium"
        />
        <div className="container-cost">
          {!constDisable ? (
            <Typography
              type="field-dark-gray"
              text={(!cost || cost === '0') ? 'Gratis' : `${toCurrencyCop(user, cost)}`}
              className="text-state-cost hebboMedium"
            />
          ) : null}
        </div>
      </div>
      <ToggleSwitch
        checked={active}
        onChange={(e) => onChange(e)}
        customClassCheckedText="custom-ToggleSwitch"
        customcClassFirstSpan="custom-ToggleSwitch"
      />
    </div>
  </>
);

StateSwitch.propTypes = {
  text: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  cost: PropTypes.any,
  isMobileDevice: PropTypes.bool.isRequired,
  constDisable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

StateSwitch.defaultProps = {
  cost: 0,
  constDisable: false,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(StateSwitch);
