/* eslint-disable react/destructuring-assignment */
import React, {
  useEffect, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import queryString from 'query-string';

import { LogoutAction, SignInAction, UpdateTokenAction } from './store/actions/auth.actions';
import { isMobileDeviceAction, isNotMobileDeviceAction } from './store/actions/mediaQuery.actions';
import { LoadDataGlobalAction } from './store/actions/global.actions';

import { setHeaderRequest } from './services/config';

import { redirectToOtherPlatforms } from './utils/intercommunication';

import { logout } from './utils/auth';

import LoadingScreen from './screens/loading';

import { Router } from './components/routes';
import { getGlobalData } from './globalData';
import { loginSystem } from './utils/loginRouter';
import { tokenRenewal } from './utils/tokenRenewal';
import { setCountryAction } from './store/actions/country.actions';
import { getCountry } from './globalData/country';

const RouterApp = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [loadingToken, setLoadingToken] = useState(true);
  const [loadingData, setLoadingData] = useState(true);

  const setCountryFunction = (data) => (dispatch(setCountryAction(data)));
  const updateTokenFunction = (data) => (dispatch(UpdateTokenAction(data)));
  const SignInFunction = (data) => (dispatch(SignInAction(data)));
  const logoutFunction = () => (dispatch(LogoutAction()));
  const LoadDataGlobalFunction = (data) => (dispatch(LoadDataGlobalAction(data)));
  const isMobileDeviceFunction = () => (dispatch(isMobileDeviceAction()));
  const isNotMobileDeviceFunction = () => (dispatch(isNotMobileDeviceAction()));

  const loginSystemRouter = async () => {
    try {
      await loginSystem(updateTokenFunction, SignInFunction, setLoadingToken, setCountryFunction);
    } catch {
      logoutFunction();
    }
    return null;
  };

  useEffect(async () => {
    await getCountry(setCountryFunction);
    const queryParams = queryString.parse(window.location.search);
    const intercommunicationData = queryParams.intercommunicationData
      ? JSON.parse(queryParams.intercommunicationData) : {};
    if (intercommunicationData.urlToReturnAfterLogin) {
      redirectToOtherPlatforms({ urlToRedirect: intercommunicationData.urlToReturnAfterLogin });
    } else if (intercommunicationData.action && intercommunicationData.action === 'logout') {
      logout();
      logoutFunction();
      window.location.replace(`${window.location.origin}`);
    }
    if (loadingToken) {
      loginSystemRouter();
    }
  }, []);

  // contador para renovación del token
  useEffect(() => {
    tokenRenewal(token, loginSystemRouter, logoutFunction);
  }, [token]);

  if (useMediaQuery({ query: '(max-width: 1023px)' })) {
    isMobileDeviceFunction();
  } else {
    isNotMobileDeviceFunction();
  }

  useEffect(() => {
    if (loadingData) {
      setHeaderRequest();
      getGlobalData(LoadDataGlobalFunction, setLoadingData, setLoadingToken);
    }
  }, [loadingData]);

  if (!loadingToken) return Router();

  return (
    <LoadingScreen />
  );
};

export default RouterApp;
