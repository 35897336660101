/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  sendingDetailsValidation,
  senderDetailsValidation,
  receiverDetailsValidation,
} from '../../utils/validation/validationsQuoteShipping';
import { findObjectInArray } from '../../utils/filters';

import SendingDetails from './sendingDetails';
import SenderDetails from './senderDetails';
import ReceiverDetails from './receiverDetails';
import ProhibitedItems from './prohibitedItems';

import ArrowBack from '../../assets/images/icon-arrow-back-color-gray-dark.svg';
import IconDropUp from '../../assets/images/Dropup_black.svg';

import './lastDetails.scss';

const LastDetails = ({
  next,
  lastBack,
  cancel,
  shippingOriginName,
  shippingDestinyName,
  articleHeight,
  articleWidth,
  articleLength,
  articleWeight,
  unitOfMeasurement,
  quantityOfArticles,
  declaredValue,
  articleDescription,
  setArticleDescription,
  recommendationsForDeliveryCompany,
  setRecommendationsForDeliveryCompany,
  notProhibitedArticles,
  setNotProhibitedArticles,
  deliverySelected,
  collectionService,
  addShippingToCollectionValue,
  saleValue,
  user,
  senderName,
  setSenderName,
  senderSurname,
  setSenderSurname,
  senderIdentificationNumber,
  senderEmail,
  senderCellPhone,
  setSenderCellPhone,
  senderAddress,
  setSenderAddress,
  collectionOrTakeToOffice,
  setCollectionOrTakeToOffice,
  receiverName,
  setReceiverName,
  receiverSurname,
  setReceiverSurname,
  receiverIdentificationNumber,
  setReceiverIdentificationNumber,
  receiverEmail,
  setReceiverEmail,
  receiverCellPhone,
  setReceiverCellPhone,
  receiverAddress,
  setReceiverAddress,
  deliveryInAddressOrOffice,
  setDeliveryInAddressOrOffice,
  isMobileDevice,
  errorsList,
  setErrorsList,
  showAllDropDwons,
  showDeliveryCompany,
  detailsFromQuoteMultipleShippings,
  showButtonGoBack,
}) => {
  const [sendingDetailsValidated, setSendingDetailsValidated] = useState(false);
  const [showSendingDetails, setShowSendingDetails] = useState(!detailsFromQuoteMultipleShippings);
  const [senderDetailsValidated, setSenderDetailsValidated] = useState(false);
  const [showSenderDetails, setShowSenderDetails] = useState(false);
  const [showReceiverDetails, setShowReceiverDetails] = useState(false);
  const [showProhibitedItems, setShowProhibitedItems] = useState(false);
  const [numberOfVisibleButtons, setNumberOfVisibleButtons] = useState(1);

  useEffect(() => {
    if (sendingDetailsValidated) {
      setNumberOfVisibleButtons(numberOfVisibleButtons + 1);
    } else if (numberOfVisibleButtons > 1) {
      setNumberOfVisibleButtons(numberOfVisibleButtons - 1);
    }
    if (senderDetailsValidated) {
      setNumberOfVisibleButtons(numberOfVisibleButtons + 1);
    } else if (numberOfVisibleButtons > 1) {
      setNumberOfVisibleButtons(numberOfVisibleButtons - 1);
    }
  }, [sendingDetailsValidated, senderDetailsValidated]);

  const heightOfContainerDetails = () => {
    switch (numberOfVisibleButtons) {
      case 1:
        return 'h-50';
      case 2:
        return 'h-100';
      case 3:
        return 'h-150';
      default:
        return '';
    }
  };

  return (
    <>
      <div
        className={`last-details__button__drop-down ${detailsFromQuoteMultipleShippings ? 'details-from-quote-multiple-shippings' : ''} ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
        onClick={() => {
          setShowSendingDetails(!showSendingDetails);
          setShowSenderDetails(false);
          setShowReceiverDetails(false);
        }}
      >
        {isMobileDevice && showButtonGoBack && (
          <img
            src={ArrowBack}
            alt="arrow back"
            className="icon-back__last-details"
            onClick={() => lastBack()}
          />
        )}
        <span>Información de tu envío</span>
        <img
          src={IconDropUp}
          alt="drop Up"
          className={showSendingDetails ? 'displayed' : ''}
        />
      </div>
      {showSendingDetails && (
        <SendingDetails
          heightType={heightOfContainerDetails()}
          setShowProhibitedItems={setShowProhibitedItems}
          cancel={() => cancel()}
          next={() => {
            if (sendingDetailsValidation(
              articleDescription, recommendationsForDeliveryCompany, notProhibitedArticles, errorsList, setErrorsList,
            )) {
              setSendingDetailsValidated(true);
              setShowSendingDetails(false);
              setShowSenderDetails(true);
            }
          }}
          shippingOriginName={shippingOriginName}
          shippingDestinyName={shippingDestinyName}
          articleHeight={articleHeight}
          articleWidth={articleWidth}
          articleLength={articleLength}
          articleWeight={articleWeight}
          unitOfMeasurement={unitOfMeasurement}
          quantityOfArticles={quantityOfArticles}
          declaredValue={declaredValue}
          saleValue={saleValue}
          articleDescription={articleDescription}
          articleDescriptionError={
            findObjectInArray('articleDescription', errorsList, 'name')
              ? findObjectInArray('articleDescription', errorsList, 'name').error
              : null
          }
          setArticleDescription={setArticleDescription}
          recommendationsForDeliveryCompany={recommendationsForDeliveryCompany}
          recommendationsForDeliveryCompanyError={
            findObjectInArray('recommendationsForDeliveryCompany', errorsList, 'name')
              ? findObjectInArray('recommendationsForDeliveryCompany', errorsList, 'name').error
              : null
          }
          setRecommendationsForDeliveryCompany={setRecommendationsForDeliveryCompany}
          notProhibitedArticles={notProhibitedArticles}
          notProhibitedArticlesError={
            findObjectInArray('notProhibitedArticles', errorsList, 'name')
              ? findObjectInArray('notProhibitedArticles', errorsList, 'name').error
              : null
          }
          setNotProhibitedArticles={setNotProhibitedArticles}
          deliverySelected={deliverySelected}
          collectionService={collectionService}
          addShippingToCollectionValue={addShippingToCollectionValue}
          isMobileDevice={isMobileDevice}
          showDeliveryCompany={showDeliveryCompany}
          detailsFromQuoteMultipleShippings={detailsFromQuoteMultipleShippings}
        />
      )}

      {(sendingDetailsValidated || showAllDropDwons) && (
        <>
          <div
            className={`last-details__button__drop-down ${detailsFromQuoteMultipleShippings ? 'details-from-quote-multiple-shippings' : ''} ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
            onClick={() => {
              setShowSendingDetails(false);
              setShowSenderDetails(!showSenderDetails);
              setShowReceiverDetails(false);
            }}
          >
            <span>Tus datos (remitente)</span>
            <img
              src={IconDropUp}
              alt="drop Up"
              className={showSenderDetails ? 'displayed' : ''}
            />
          </div>
          {showSenderDetails && (
            <SenderDetails
              user={user}
              senderName={senderName}
              senderNameError={
                findObjectInArray('senderName', errorsList, 'name')
                  ? findObjectInArray('senderName', errorsList, 'name').error
                  : null
              }
              setSenderName={setSenderName}
              senderSurname={senderSurname}
              senderSurnameError={
                findObjectInArray('senderSurname', errorsList, 'name')
                  ? findObjectInArray('senderSurname', errorsList, 'name').error
                  : null
              }
              setSenderSurname={setSenderSurname}
              senderIdentificationNumber={senderIdentificationNumber}
              senderIdentificationNumberError={
                findObjectInArray('senderIdentificationNumber', errorsList, 'name')
                  ? findObjectInArray('senderIdentificationNumber', errorsList, 'name').error
                  : null
              }
              senderEmail={senderEmail}
              senderEmailError={
                findObjectInArray('senderEmail', errorsList, 'name')
                  ? findObjectInArray('senderEmail', errorsList, 'name').error
                  : null
              }
              senderCellPhone={senderCellPhone}
              senderCellPhoneError={
                findObjectInArray('senderCellPhone', errorsList, 'name')
                  ? findObjectInArray('senderCellPhone', errorsList, 'name').error
                  : null
              }
              setSenderCellPhone={setSenderCellPhone}
              senderAddress={senderAddress}
              senderAddressError={
                findObjectInArray('senderAddress', errorsList, 'name')
                  ? findObjectInArray('senderAddress', errorsList, 'name').error
                  : null
              }
              setSenderAddress={setSenderAddress}
              collectionOrTakeToOffice={collectionOrTakeToOffice}
              collectionOrTakeToOfficeError={
                findObjectInArray('collectionOrTakeToOffice', errorsList, 'name')
                  ? findObjectInArray('collectionOrTakeToOffice', errorsList, 'name').error
                  : null
              }
              setCollectionOrTakeToOffice={setCollectionOrTakeToOffice}
              deliverySelected={deliverySelected}
              heightType={heightOfContainerDetails()}
              isMobileDevice={isMobileDevice}
              errorsList={errorsList}
              next={() => {
                if (senderDetailsValidation(
                  senderName, senderSurname, senderIdentificationNumber.toString(), senderEmail,
                  senderCellPhone.toString(), senderAddress, collectionOrTakeToOffice,
                  errorsList, setErrorsList,
                )) {
                  setSenderDetailsValidated(true);
                  setShowSenderDetails(false);
                  setShowReceiverDetails(true);
                }
              }}
              detailsFromQuoteMultipleShippings={detailsFromQuoteMultipleShippings}
            />
          )}
        </>
      )}

      {(senderDetailsValidated || showAllDropDwons) && (
        <>
          <div
            className={`last-details__button__drop-down ${detailsFromQuoteMultipleShippings ? 'details-from-quote-multiple-shippings' : ''} ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
            onClick={() => {
              setShowSendingDetails(false);
              setShowSenderDetails(false);
              setShowReceiverDetails(!showReceiverDetails);
            }}
          >
            <span>Datos del destinatario</span>
            <img
              src={IconDropUp}
              alt="drop Up"
              className={showReceiverDetails ? 'displayed' : ''}
            />
          </div>
          {showReceiverDetails && (
            <ReceiverDetails
              receiverName={receiverName}
              receiverNameError={
                findObjectInArray('receiverName', errorsList, 'name')
                  ? findObjectInArray('receiverName', errorsList, 'name').error
                  : null
              }
              setReceiverName={setReceiverName}
              receiverSurname={receiverSurname}
              receiverSurnameError={
                findObjectInArray('receiverSurname', errorsList, 'name')
                  ? findObjectInArray('receiverSurname', errorsList, 'name').error
                  : null
              }
              setReceiverSurname={setReceiverSurname}
              receiverIdentificationNumber={receiverIdentificationNumber}
              receiverIdentificationNumberError={
                findObjectInArray('receiverIdentificationNumber', errorsList, 'name')
                  ? findObjectInArray('receiverIdentificationNumber', errorsList, 'name').error
                  : null
              }
              setReceiverIdentificationNumber={setReceiverIdentificationNumber}
              receiverEmail={receiverEmail}
              receiverEmailError={
                findObjectInArray('receiverEmail', errorsList, 'name')
                  ? findObjectInArray('receiverEmail', errorsList, 'name').error
                  : null
              }
              setReceiverEmail={setReceiverEmail}
              receiverCellPhone={receiverCellPhone}
              receiverCellPhoneError={
                findObjectInArray('receiverCellPhone', errorsList, 'name')
                  ? findObjectInArray('receiverCellPhone', errorsList, 'name').error
                  : null
              }
              setReceiverCellPhone={setReceiverCellPhone}
              receiverAddress={receiverAddress}
              receiverAddressError={
                findObjectInArray('receiverAddress', errorsList, 'name')
                  ? findObjectInArray('receiverAddress', errorsList, 'name').error
                  : null
              }
              setReceiverAddress={setReceiverAddress}
              deliveryInAddressOrOffice={deliveryInAddressOrOffice}
              deliveryInAddressOrOfficeError={
                findObjectInArray('deliveryInAddressOrOffice', errorsList, 'name')
                  ? findObjectInArray('deliveryInAddressOrOffice', errorsList, 'name').error
                  : null
              }
              setDeliveryInAddressOrOffice={setDeliveryInAddressOrOffice}
              deliverySelected={deliverySelected}
              heightType={heightOfContainerDetails()}
              isMobileDevice={isMobileDevice}
              errorsList={errorsList}
              next={() => {
                if (receiverDetailsValidation(
                  receiverName, receiverSurname, receiverIdentificationNumber, receiverEmail,
                  receiverCellPhone, receiverAddress, deliveryInAddressOrOffice,
                  errorsList, setErrorsList,
                )) {
                  next();
                }
              }}
              detailsFromQuoteMultipleShippings={detailsFromQuoteMultipleShippings}
            />
          )}
        </>
      )}

      {showProhibitedItems && (
        <ProhibitedItems
          isMobileDevice={isMobileDevice}
          close={() => setShowProhibitedItems(false)}
        />
      )}
    </>
  );
};

LastDetails.propTypes = {
  showAllDropDwons: PropTypes.bool,
  next: PropTypes.func.isRequired,
  lastBack: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  shippingOriginName: PropTypes.string.isRequired,
  shippingDestinyName: PropTypes.string.isRequired,
  articleHeight: PropTypes.number.isRequired,
  articleWidth: PropTypes.number.isRequired,
  articleLength: PropTypes.number.isRequired,
  articleWeight: PropTypes.number.isRequired,
  unitOfMeasurement: PropTypes.string.isRequired,
  quantityOfArticles: PropTypes.number.isRequired,
  declaredValue: PropTypes.number.isRequired,
  saleValue: PropTypes.number,
  articleDescription: PropTypes.string,
  setArticleDescription: PropTypes.func.isRequired,
  recommendationsForDeliveryCompany: PropTypes.string,
  setRecommendationsForDeliveryCompany: PropTypes.func.isRequired,
  notProhibitedArticles: PropTypes.bool.isRequired,
  collectionService: PropTypes.bool.isRequired,
  addShippingToCollectionValue: PropTypes.bool.isRequired,
  setNotProhibitedArticles: PropTypes.func.isRequired,
  deliverySelected: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  senderName: PropTypes.string.isRequired,
  setSenderName: PropTypes.func.isRequired,
  senderSurname: PropTypes.string.isRequired,
  setSenderSurname: PropTypes.func.isRequired,
  senderIdentificationNumber: PropTypes.string.isRequired,
  senderEmail: PropTypes.string.isRequired,
  senderCellPhone: PropTypes.string.isRequired,
  setSenderCellPhone: PropTypes.func.isRequired,
  senderAddress: PropTypes.string.isRequired,
  setSenderAddress: PropTypes.func.isRequired,
  collectionOrTakeToOffice: PropTypes.bool.isRequired,
  setCollectionOrTakeToOffice: PropTypes.func.isRequired,
  receiverName: PropTypes.string,
  setReceiverName: PropTypes.func.isRequired,
  receiverSurname: PropTypes.string,
  setReceiverSurname: PropTypes.func.isRequired,
  receiverIdentificationNumber: PropTypes.string,
  setReceiverIdentificationNumber: PropTypes.func.isRequired,
  receiverEmail: PropTypes.string,
  setReceiverEmail: PropTypes.func.isRequired,
  receiverCellPhone: PropTypes.string,
  setReceiverCellPhone: PropTypes.func.isRequired,
  receiverAddress: PropTypes.string,
  setReceiverAddress: PropTypes.func.isRequired,
  deliveryInAddressOrOffice: PropTypes.string,
  setDeliveryInAddressOrOffice: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.any).isRequired,
  setErrorsList: PropTypes.func.isRequired,
  showDeliveryCompany: PropTypes.bool,
  detailsFromQuoteMultipleShippings: PropTypes.bool,
  showButtonGoBack: PropTypes.bool,
};

LastDetails.defaultProps = {
  showAllDropDwons: false,
  articleDescription: null,
  recommendationsForDeliveryCompany: null,
  receiverName: null,
  receiverSurname: null,
  receiverIdentificationNumber: null,
  receiverEmail: null,
  receiverCellPhone: null,
  receiverAddress: null,
  deliveryInAddressOrOffice: null,
  saleValue: null,
  showDeliveryCompany: true,
  detailsFromQuoteMultipleShippings: false,
  showButtonGoBack: true,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(LastDetails);
