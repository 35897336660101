import React from 'react';
import PropTypes from 'prop-types';

import IconRemoveColorRed from '../../assets/images/icon-remove-color-red.png';

import './activFilters.scss';

const ActivFilters = ({
  show, filters, handleChangeFilter, keyNames,
}) => {
  const getKeysAndValuesFromFilters = () => {
    const items = Object.keys(filters);

    return items.map((item) => {
      const keyName = keyNames.find((key) => key.value === item)?.value;

      if (keyName) {
        return (
          <div className="item-activ-filter">
            <span>{keyName?.length > 18 ? `${keyName.slice(0, 15)}...` : keyName}</span>
            <img
              src={IconRemoveColorRed}
              onClick={() => {
                const temporalFilter = JSON.parse(JSON.stringify(filters));
                delete temporalFilter[item];
                handleChangeFilter(temporalFilter);
              }}
              alt="remove"
            />
          </div>
        );
      }

      return (
        <div className="item-activ-filter">
          <span>{`${item?.length > 13 ? `${item.slice(0, 10)}...` : item}: ${filters[item]}`}</span>
          <img
            src={IconRemoveColorRed}
            onClick={() => {
              const temporalFilter = JSON.parse(JSON.stringify(filters));
              delete temporalFilter[item];
              handleChangeFilter(temporalFilter);
            }}
            alt="remove"
          />
        </div>
      );
    });
  };

  return show ? (
    <div className="activ-filters">
      <div className="content-activ-filters">
        {getKeysAndValuesFromFilters()}
        <div className="degraded" />
      </div>
      {JSON.stringify(filters).length > 2 && (
        <span
          className="clear-filter"
          onClick={() => handleChangeFilter({})}
        >
          Eliminar filtros
        </span>
      )}
    </div>
  ) : null;
};

ActivFilters.propTypes = {
  filters: PropTypes.shape(),
  handleChangeFilter: PropTypes.func,
  show: PropTypes.bool,
  keyNames: PropTypes.arrayOf(PropTypes.shape()),
};

ActivFilters.defaultProps = {
  keyNames: [],
  filters: {},
  handleChangeFilter: null,
  show: true,
};

export default ActivFilters;
