/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectToOtherPlatforms } from '../../utils/intercommunication';
import { showDependingOnBusinessFlag } from '../../utils/businessFlag';
import { useValidationCountry, useFetchIpData } from '../../utils/validationCountry';
import { getShippingsWithNoveltiesService } from '../../services/novelties.service';
import { cashThousandPoint, toCurrencyCopWithOutCOP } from '../../utils/strings';

import Modal from '../../components/modal';
import Button from '../../components/button';
import CardSectionMobile from './cardSectionMobile';
import HeaderCards from '../../components/headerCards';
import dataSections from '../../constants/dataSections';

import { existRole } from '../../utils/role';
import { operationsUrl } from '../../constants';

import wallet from '../../assets/images/wallet-icon.svg';
import add from '../../assets/images/add-orange-icon.svg';
import star from '../../assets/images/star-blue-icon.svg';
import discount from '../../assets/images/discount-icon.svg';

import './home.scss';

const ModalQuoteShippingOptions = ({ closeModal, user }) => {
  const navigate = useNavigate();
  const countryCode = useSelector((state) => state.country);

  return (
    <Modal className={user ? 'express-shipping' : 'quote-shipping-options__modal'} closeBackground={closeModal}>
      <>
        <Button
          text="Cotiza"
          type="none"
          onClick={() => navigate('/cotizacion/quoteShipping')}
        />
        {
          user && user.countryCode !== '484' && (
            <Button
              text="Cotización Express"
              type="none"
              onClick={() => navigate('/cotizacion/expressShipping')}
            />
          )
        }
        {(countryCode !== '484') && (
          <Button
            text="Cotización Masiva"
            type="none"
            onClick={() => navigate('/cotizacion/quoteMultipleShippings')}
          />
        )}
      </>
    </Modal>
  );
};

ModalQuoteShippingOptions.propTypes = {
  closeModal: PropTypes.func.isRequired,
  user: PropTypes.shape(),
};

ModalQuoteShippingOptions.defaultProps = {
  user: null,
};

const Home = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.user);
  const isMobileDevice = useSelector((state) => state?.mediaQuery?.isMobileDevice);
  const businessFlags = useSelector((state) => state?.globalData?.businessFlags || []);

  const [showModalQuoteShippingOptions, setShowModalQuoteShippingOptions] = useState(false);
  const [noveltiesCenterVisibility, setNoveltiesCenterVisibility] = useState(false);
  const [numberOfNovelties, setNumberOfNovelties] = useState(0);

  useEffect(() => {
    if (!isMobileDevice) existRole(user) ? window.location.assign(`${operationsUrl}/buscar-codigo-mp`) : navigate('/cotizacion');
  }, [isMobileDevice]);

  useEffect(() => {
    const getShippingWithNoveltiesFunction = async () => {
      const responseShippingWithNovelties = await getShippingsWithNoveltiesService({
        userId: user._id, state: 'Sin gestión', page: 1,
      });
      setNumberOfNovelties(responseShippingWithNovelties.totalDocuments);
    };

    if (user) getShippingWithNoveltiesFunction();
  }, [user]);

  useEffect(() => {
    if (user && businessFlags) {
      const visible = showDependingOnBusinessFlag({
        businessFlags, section: 'noveltiesCenter', feature: 'see', userEmail: user.email,
      });
      setNoveltiesCenterVisibility(visible);
    }
  }, [user, businessFlags]);

  const dataToMap = () => {
    if (user && user.role) return dataSections.internalAutenticated;
    if (user && !user.role) return dataSections.userAutenticated;
    return dataSections.notAutenticated;
  };

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div id="home-mobile">
      {showModalQuoteShippingOptions && (
        <ModalQuoteShippingOptions
          user={user}
          closeModal={() => setShowModalQuoteShippingOptions(false)}
        />
      )}
      {user && (
        <div className="cards">
          <div>
            <span>Recargar saldo</span>
            <HeaderCards
              iconLeft={add}
              colorData="cash"
              iconLeftSize="orange"
              textRight={user.currencyToPay}
              action={() => navigate('/recargar-saldo')}
              data={toCurrencyCopWithOutCOP(user, user?.cash)}
              user={user}
            />
          </div>
          {user?.creditPayment && (
            <div>
              <span>Cupo crédito</span>
              <HeaderCards
                iconLeft={wallet}
                colorData="cash"
                iconLeftSize="icon-default"
                textRight={user.currencyToPay}
                data={`$${cashThousandPoint(Number.parseInt(user?.creditLimitCop ?? 0, 10))}`}
              />
            </div>
          )}
          <div>
            {user?.volumeDiscountNegotiation ? (
              <HeaderCards
                iconLeft={discount}
                colorData="default"
                iconLeftSize="icon-default"
                data="Descuentos activos"
              />
            ) : (
              <>
                <span>Redimir puntos</span>
                <HeaderCards
                  iconLeft={star}
                  textRight="Pts"
                  colorData="default"
                  iconLeftSize="blue"
                  data={`${cashThousandPoint(user?.points ?? 0)}`}
                  action={() => (user?.points === 0 ? navigate('/acumula-puntos') : null)}
                />
              </>
            )}
          </div>
        </div>
      )}

      <div id="section-list__home-mobile">
        {dataToMap().map((option) => (
          (option.label === 'Novedades' && noveltiesCenterVisibility && option.label === 'Tus estadísticas')
          || (!['Tus estadísticas', 'Cotiza tu envío'].includes(option.label) && !option.noLeftMenu)
        ) && (
        <CardSectionMobile
          key={`option-section-${option.label}`}
          img={option.Icon}
          label={option.label}
          elementRight={option.label === 'Novedades' && numberOfNovelties > 0 && (
          <span className="count-unread">{numberOfNovelties}</span>
          )}
          onClick={() => {
            if (option.label === 'Novedades') {
              redirectToOtherPlatforms({});
            } else if (option.blank) {
              window.open(option.url, '_blank');
            } else {
              navigate(option.url);
            }
          }}
        />
        ))}
      </div>

      {(!user?.role) && (
        <div className="container-button-fixed">
          <Button
            text="Cotizar envío"
            type={isCountryValid ? 'primary-mx' : 'primary'}
            onClick={() => {
              setShowModalQuoteShippingOptions(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Home;
