import axios from 'axios';

import {
  usersMicroserviceUrl,
} from '../constants';

const createAccountACService = (
  email,
  name,
  surname,
  phone,
  listId,
  fieldValues,
) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = 'b0c6d2aa-4d53-11eb-ae93-0242ac130002';
  axios.post(`${usersMicroserviceUrl}/createUserAC`, {
    email,
    name,
    surname,
    phone,
    listId,
    fieldValues,
  })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      reject(e);
    });
});

export default createAccountACService;
