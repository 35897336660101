import React from 'react';
import PropTypes from 'prop-types';

import './imagesEmpyState.scss';

const ImageEmpyState = ({
  uri, title, description, supportDescription,
  containerDescription,
}) => (

  <div className="imagesEmptyState">
    <div>
      <img className="imageEmpty" src={uri} alt="Logo" />
      <div>
        <h1 className="imageEmptyTitle">{title}</h1>
        <div className={containerDescription}>
          <h2 className="emptyDescription">{description}</h2>
          <h2 className="emptySupportDescription">{supportDescription}</h2>
        </div>
      </div>
    </div>

  </div>
);

ImageEmpyState.propTypes = {
  uri: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  supportDescription: PropTypes.string,
  containerDescription: PropTypes.string,
};

ImageEmpyState.defaultProps = {
  supportDescription: null,
  containerDescription: '',
};

export default ImageEmpyState;
