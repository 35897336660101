/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { toCurrencyCop } from 'utils/strings';
import PickupTag from 'components/pickupTag';
import DeliveryCompaniesObservations from 'components/deliveryCompaniesObservations';

import DeliveryTime from 'constants/deliveryTime';
import DeliveryValue from 'constants/deliveryValue';
import DeliveryImage from 'constants/handleImageClick';

import IconStar from 'assets/images/star.svg';
import IconDropUp from 'assets/images/Dropup_black.svg';

import '../deliveryCardToList.scss';

const DeliveryCardToList = ({
  id,
  deliveryName,
  deliveryImg,
  deliveryTime,
  shippingValue,
  shippingRealValue,
  collectionValue,
  deliveryScore,
  delivery,
  detailsCostElement,
  isMobileDevice,
  details,
  routeType,
  pickupService,
  officeAddress,
  routeTypeValidation,
  handleShowDetail,
}) => {
  const user = useSelector((state) => state.auth.user);
  const countryCode = useSelector((state) => state?.country || null);
  const [showDeliveryAnnottation, setShowDeliveryAnnottation] = useState(false);

  return (
    <div className="container__content-top-and-content-bottom">
      <div className="content-top__delivery-card-to-list">
        <div className="container-delivery-info">
          <DeliveryImage
            deliveryImg={deliveryImg}
            deliveryName={deliveryName}
            setShowDeliveryAnnottation={setShowDeliveryAnnottation}
          />
          <div className="content-delivery-info">
            <span className="content-delivery-info__value-collection">
              {toCurrencyCop(user, Math.floor(shippingValue))}
            </span>
            <div className="content-delivery-info__name-value">
              <span className="delivery-name">{deliveryName}</span>
              {shippingRealValue ? (
                <DeliveryValue
                  shippingValue={shippingValue}
                  shippingRealValue={shippingRealValue}
                  user={user}
                />
              ) : (
                <div className="shipping-value__mount-score">
                  <div className="score">
                    <img src={IconStar} alt="score-img" height="16px" />
                    <p>{deliveryScore}</p>
                  </div>
                </div>
              )}
            </div>
            <DeliveryTime deliveryTime={deliveryTime} />
          </div>
        </div>
        <div className="delivery-score">
          {countryCode === '170' ? (
            <div className="routeType">{routeTypeValidation(routeType)}</div>
          ) : (
            <>
              {pickupService && <PickupTag pickup={pickupService} />}
              {officeAddress && <PickupTag />}
            </>
          )}
        </div>
        <div className="shipping-value">
          {collectionValue > 0 && details && (
            <button
              type="button"
              className="see-more"
              onClick={(e) => handleShowDetail(e)}
            >
              {delivery && delivery.id === id ? 'Ver menos' : 'Ver más'}
              <img
                src={IconDropUp}
                className={delivery && delivery.id === id ? 'up' : 'down'}
                alt=""
              />
            </button>
          )}
        </div>
      </div>
      {collectionValue > 0 && (
        <div className="content-bottom__delivery-card-to-list">
          {collectionValue && collectionValue >= 1 && (
            <div className="collection-value">
              {delivery
                && delivery.id === id
                && isMobileDevice
                && detailsCostElement()}
            </div>
          )}
        </div>
      )}
      {showDeliveryAnnottation && (
        <DeliveryCompaniesObservations
          deliveryCompanyImgUrl={deliveryImg}
          deliveryCompanyName={deliveryName}
          deliveryCompanyId={id}
          close={() => setShowDeliveryAnnottation(false)}
          isMobileDevice={isMobileDevice}
          countryCode={user?.countryCode || countryCode}
        />
      )}
      <hr />
    </div>
  );
};

DeliveryCardToList.propTypes = {
  id: PropTypes.string.isRequired,
  deliveryName: PropTypes.string.isRequired,
  deliveryImg: PropTypes.string.isRequired,
  deliveryTime: PropTypes.number.isRequired,
  shippingValue: PropTypes.number.isRequired,
  shippingRealValue: PropTypes.number,
  collectionValue: PropTypes.number,
  deliveryScore: PropTypes.number.isRequired,
  delivery: PropTypes.any,
  detailsCostElement: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  details: PropTypes.bool,
  routeType: PropTypes.string.isRequired,
  pickupService: PropTypes.bool.isRequired,
  officeAddress: PropTypes.string,
  routeTypeValidation: PropTypes.func.isRequired,
  handleShowDetail: PropTypes.func.isRequired,
};

DeliveryCardToList.defaultProps = {
  collectionValue: null,
  delivery: null,
  details: true,
  shippingRealValue: null,
  officeAddress: null,
};

export default DeliveryCardToList;
