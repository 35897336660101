import dataIp from '../services/ipapi';
import { getCountriesService } from '../services/routes';
import { businessFlagValidation } from '../constants/countryCodeValidation';

const defaultCountryCode = () => '170';

const countryCodeUser = async (setCountryFunction, validate) => {
  const api = await dataIp();
  const countries = await getCountriesService();

  const countryApiCode = api.country_code;
  const matchedCountry = countries.find((country) => country.alfa2Code === countryApiCode);
  const selectedCountry = matchedCountry || countries.find((country) => country.ISOCode === '170');
  const countryCode = selectedCountry.ISOCode;

  const shouldUseDefaultCountryCode = validate.some(
    (item) => item.country === countryCode && !item.enabled);

  if (shouldUseDefaultCountryCode) {
    setCountryFunction(defaultCountryCode());
  } else {
    setCountryFunction(countryCode);
  }
};

export const getCountry = async (setCountryFunction) => {
  const validate = businessFlagValidation();

  countryCodeUser(setCountryFunction, validate);
};

export default {
  getCountry,
};
