import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { validationSteps } from '../createAccount/validations';
import { findObjectInArray } from '../../../utils/filters';
import { validateEmailService } from '../../../services/user';
import { capitalizeWords, insertOrRemoveErrorFromList, validationValue } from '../../../utils/strings';
import { averageShipmentsOptions } from '../../../constants/general';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import Input from '../../../components/input';
import Button from '../../../components/button';
import Select from '../../../components/select';
import TermsAndConditions from './termsAndConditions';
import Typography from '../../../components/typography';
import NameSurnameAndCellPhone from './nameSurnameAndCellPhone';
import { ReactComponent as IconArrowBack } from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './stepsCreateAccount.scss';

const StepSocialAccount = ({
  goBack, next, userToCreate, errorList, setErrorList,
}) => {
  const [email, setEmail] = useState(userToCreate.email || '');
  const [showEmail] = useState(!userToCreate.email);
  const [searchingEmail, setSearchingEmail] = useState(false);
  const [name, setName] = useState(userToCreate.name || '');
  const [surname, setSurname] = useState(userToCreate.surname || '');
  const [cellPhone, setCellPhone] = useState(userToCreate.cellPhone || '');
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [averageShipments, setAverageShipments] = useState(userToCreate.averageShipments || '');

  const ipData = useFetchIpData();
  const navigate = useNavigate();
  const isCountryValid = useValidationCountry(ipData.country);

  const searchEmail = async () => {
    const temporalErrorList = errorList.slice();
    try {
      setSearchingEmail(true);
      const response = await validateEmailService(email);
      if (response.status === 200) {
        insertOrRemoveErrorFromList(temporalErrorList, 'email', 'Este correo ya está registrado', 'insert');
      } else {
        insertOrRemoveErrorFromList(temporalErrorList, 'email', null, 'remove');
        next(email, name, surname, cellPhone, averageShipments);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setSearchingEmail(false);
      setErrorList(temporalErrorList);
    }
  };

  return (
    <div className="stepCreateAccount">
      <div className="top">
        <IconArrowBack onClick={goBack} className="arrowBack" />
        <Typography type="superTitle-italic" text="Registro de cuenta" />
        <div />
      </div>

      <div className="bottom">
        {showEmail && (
          <Input
            label="Correo electrónico"
            className="inputUserToCreate"
            value={email}
            onChange={(value) => {
              setEmail(value);
              if (findObjectInArray('email', errorList, 'name')) {
                validationSteps({ errorList, setErrorList, email: value });
              }
            }}
            onBlur={() => validationSteps({ errorList, setErrorList, email })}
            annotationError={findObjectInArray('email', errorList, 'name')?.error || null}
            placeholder="usuario@email.com"
          />
        )}
        <NameSurnameAndCellPhone
          name={name}
          setName={setName}
          surname={surname}
          setSurname={setSurname}
          cellPhone={cellPhone}
          setCellPhone={setCellPhone}
          errorList={errorList}
          setErrorList={setErrorList}
          userToCreate={userToCreate}
        />
        <Select
          label="Envíos que haces mensualmente"
          value={averageShipments}
          onChange={(value) => {
            setAverageShipments(value);
            validationSteps({
              errorList, setErrorList, averageShipments: value, averageShipmentsOptions,
            });
          }}
          startingPosition="top-left"
          className="selectUserToCreate"
          annotationError={findObjectInArray('averageShipments', errorList, 'name')?.error || null}
          options={averageShipmentsOptions}
        />
        <TermsAndConditions check={termsAndConditions} handleChangeCheck={setTermsAndConditions} />
        <Button
          text={`Registrarme con ${capitalizeWords(userToCreate.socialNetwork)}`}
          type={isCountryValid ? 'primary-mx' : 'primary'}
          loading={searchingEmail}
          disabled={
            !termsAndConditions
            || name === '' || !validationValue(name).correct
            || surname === '' || !validationValue(surname).correct
            || cellPhone === '' || !validationValue(cellPhone, 'cellPhone').correct
            || !averageShipmentsOptions.map((option) => option.value).includes(averageShipments)
          }
          onClick={searchEmail}
        />
        <Typography type="paragraph" className="redirectToLogin">
          ¿Ya tienes cuenta?
          <button
            type="button"
            onClick={() => navigate('/ingreso')}
            className="linkButton"
          >
            Inicia sesión aquí
          </button>
        </Typography>
      </div>
    </div>
  );
};

StepSocialAccount.propTypes = {
  next: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  userToCreate: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    cellPhone: PropTypes.string,
    socialNetwork: PropTypes.string,
    averageShipments: PropTypes.string,
  }).isRequired,
  errorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setErrorList: PropTypes.func.isRequired,
};

export default StepSocialAccount;
