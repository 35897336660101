/* eslint-disable max-len */
import { addDiscount } from 'utils/generals';

export const finalPrice = (
  shippingCost,
  collectionService,
  addShippingToCollectionValue,
  collectionCommissionWithRate,
  collectionCommissionWithOutRate,
) => {
  if (collectionService) {
    if (addShippingToCollectionValue) {
      return collectionCommissionWithRate + shippingCost;
    }
    return collectionCommissionWithOutRate + shippingCost;
  }
  return shippingCost;
};

export const collectionServiceValue = (
  saleValue,
  shippingCost,
  collectionService,
  addShippingToCollectionValue,
  collectionCommissionWithRate,
  collectionCommissionWithOutRate,
) => {
  if (collectionService && saleValue) {
    if (addShippingToCollectionValue) {
      return finalPrice(
        shippingCost,
        collectionService,
        addShippingToCollectionValue,
        collectionCommissionWithRate,
        collectionCommissionWithOutRate,
      ) + saleValue;
    }
    return saleValue;
  }
  return 0;
};

export const collectionServiceCommision = (
  collectionService,
  addShippingToCollectionValue,
  collectionCommissionWithRate,
  collectionCommissionWithOutRate,
) => {
  if (collectionService) {
    if (addShippingToCollectionValue) {
      return collectionCommissionWithRate;
    }
    return collectionCommissionWithOutRate;
  }
  return 0;
};

export const discountCalculateValue = (shippingValue, realShippingValue, isNegativeNumber = false) => {
  const discountValue = Math.ceil(realShippingValue - shippingValue);
  return isNegativeNumber ? -Math.abs(discountValue) : discountValue;
};

export const totalPrices = (...values) => values
  .filter((value) => value !== undefined)
  .reduce((sum, currentValue) => sum + currentValue, 0);
export const functionDiscount = (delivery, collectionService, addShippingToCollectionValue, deliveryCompaniesConfig) => {
  const shippingCost = finalPrice(
    delivery.shippingCost,
    collectionService,
    addShippingToCollectionValue,
    delivery.collectionCommissionWithRate,
    delivery.collectionCommissionWithOutRate,
  );

  const shippingCostshippingCost = finalPrice(
    delivery.shippingRealCost ?? delivery.shippingCost,
    collectionService,
    addShippingToCollectionValue,
    delivery.realCollectionCommissionWithRateValue ?? delivery.collectionCommissionWithRate,
    delivery.realCollectionCommissionWithoutRateValue
    ?? delivery.collectionCommissionWithOutRate,
  );

  return shippingCost === shippingCostshippingCost ? 0 : addDiscount(
    shippingCost,
    shippingCostshippingCost,
    delivery.deliveryCompanyId,
    delivery.routeType,
    delivery.negotiation,
    deliveryCompaniesConfig,
  );
};
export default {
  finalPrice,
  discountCalculateValue,
  functionDiscount,
  totalPrices,
};
