import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import redirectOperationUserRouter from '.';

const NoRoleRoutes = ({ children }) => {
  const { user } = useSelector((state) => state.auth);

  if (user?.role) return <Navigate to="/perfil" replace />;

  if (!user) {
    return <Navigate to="/cotizacion" replace />;
  }

  return children;
};

NoRoleRoutes.propTypes = {
  children: PropTypes.element.isRequired,
};

export const ExistRole = ({ children }) => {
  const { user } = useSelector((state) => state.auth);

  if (user?.role) return redirectOperationUserRouter();
  return children;
};

ExistRole.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NoRoleRoutes;
