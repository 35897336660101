// eslint-disable-next-line import/prefer-default-export
import IconStarBlue from '../../../../assets/images/star-blue.svg';
import IconClockBlue from '../../../../assets/images/clock-blue.svg';
import IconMoneyBlue from '../../../../assets/images/attach_money-blue.svg';

const optionsImageSelect = [
  {
    id: '1',
    name: 'Menor precio',
    img: IconMoneyBlue,
    selectByFilter: 'price',
  },
  {
    id: '2',
    name: 'Menor tiempo',
    img: IconClockBlue,
    selectByFilter: 'time',
  },
  {
    id: '3',
    name: 'Mejor servicio',
    img: IconStarBlue,
    selectByFilter: 'bestService',
  },
];

export default optionsImageSelect;
