/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import ContentSection from '../../../../components/contentSection';
import Typography from '../../../../components/typography';
import Button from '../../../../components/button';

import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import './stepExcelErrors.scss';

const StepExcelErrors = ({
  next, back, excelErrors, numberOfShippings, isMobileDevice,
}) => {
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);
  return (
    <ContentSection
      headerText="Cotiza múltiples envíos"
    >
      <div id="multiple-shippings__excel-errors">
        {isMobileDevice && (
          <div className="content-top">
            <Typography
              text="Hay un problema con la platilla"
              type="title"
            />
          </div>
        )}
        <div className={`content-bottom ${isMobileDevice ? '' : 'full-height'}`}>
          <div className="container-list__excel-errors">
            {excelErrors && (
              <>
                <div className="item">
                  <Typography
                    type="field"
                    text="Total de envíos solicitados:"
                    className="field dark font-size-14"
                  />
                  {' '}
                  <Typography
                    type="value"
                    text={numberOfShippings}
                  />
                </div>
                <div className="item">
                  <Typography
                    type="field"
                    text="Total de errores:"
                    className="field dark font-size-14"
                  />
                  {' '}
                  <Typography
                    type="value"
                    text={excelErrors.length}
                  />
                </div>

                <hr />
                {excelErrors.map((error) => (
                  <div className="item" key={error.row}>
                    <Typography
                      type="field"
                      text={`Errores fila ${error.row + 1}:`}
                      className="field dark font-size-14"
                    />
                    {' '}
                    <Typography
                      type="value"
                      text={error.errors.join(' - ')}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="container-buttons__content-bottom">
            <Button
              text="Regresar"
              type="secondary"
              onClick={() => back()}
            />
            <Button
              text="Corregir errores"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => next()}
            />
          </div>
        </div>
      </div>
    </ContentSection>
  );
};

StepExcelErrors.propTypes = {
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  excelErrors: PropTypes.any.isRequired,
  numberOfShippings: PropTypes.number.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

export default StepExcelErrors;
