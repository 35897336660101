import { validationValue, insertOrRemoveErrorFromList } from '../../utils/strings';
import { findObjectInArray } from '../../utils/filters';

export const bankDataValidation = (
  errorsList, setErrorsList, accountHolder, documentType,
  documentNumber, documentNumberConfirmation,
  bankName, accountNumber, accountType, accountNumberConfirmation,
  bankTransferFee,
) => {
  const temporalErrorsList = errorsList.slice();
  const listToValidate = [
    {
      value: accountHolder,
      errorName: 'accountHolder',
      errorText: 'Ingresa el nombre del titular para continuar.',
    },
    {
      value: documentType,
      errorName: 'documentType',
      errorText: 'Selecciona el tipo de documento.',
    },
    {
      value: documentNumber,
      errorName: 'documentNumber',
      errorText: 'Ingresa el número de documento.',
    },
    {
      value: documentNumber,
      errorName: 'documentNumber',
      errorText: 'Tu identificanción debe tener más de 5 dígitos',
      type: 'identificationNumber',
    },
    {
      value: bankName,
      errorName: 'bankName',
      errorText: 'Ingresa el nombre del banco.',
    },
    {
      value: accountNumber,
      errorName: 'accountNumber',
      errorText: 'Ingresa el número de cuenta.',
    },
    {
      value: accountNumber,
      errorName: 'accountNumber',
      errorText: 'Tu cuenta debe tener más de 5 dígitos',
      type: 'identificationNumber',
    },
    {
      value: accountType,
      errorName: 'accountType',
      errorText: 'Selecciona el tipo de cuenta.',
    },
  ];

  const dataList = () => [
    'BANCOLOMBIA',
    'NEQUI',
    'DAVIPLATA',
    'BANCO AV VILLAS',
    'BANCO CAJA SOCIAL',
    'BANCO DAVIVIENDA',
    'BANCO DE BOGOTA',
    'BANCO DE OCCIDENTE',
    'BANCO FINANDINA',
    'BANCO GNB SUDAMERIS',
    'BANCO MULTIBANK',
    'BANCO POPULAR',
    'BANCO SANTANDER',
    'BANCOOMEVA',
    'BBVA',
    'CITIBANK',
    'COLPATRIA',
    'COLTEFINANCIERA',
    'FALLABELLA',
    'ITAU',
  ].map((bank) => `${bank}-(Desembolse: $${bankTransferFee[bank.toLowerCase()]})`);

  listToValidate.map((item) => {
    if (item.errorName === 'bankName') {
      insertOrRemoveErrorFromList(
        temporalErrorsList,
        'bankName',
        'Ingresa un banco válido.',
        bankName && bankName !== '' && bankName.length > 0 && findObjectInArray(bankName, dataList()) ? 'remove' : 'insert',
      );
    } else {
      insertOrRemoveErrorFromList(
        temporalErrorsList,
        item.errorName,
        item.errorText,
        validationValue(item.value, item.type).correct ? 'remove' : 'insert',
      );
    }
    return null;
  });

  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'documentNumberConfirmation',
    'el número de documento debe coincidir en ambos campos.',
    documentNumberConfirmation === documentNumber ? 'remove' : 'insert',
  );

  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'accountNumberConfirmation',
    'el número de cuenta debe coincidir en ambos campos.',
    accountNumberConfirmation === accountNumber ? 'remove' : 'insert',
  );

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length === 0) {
    return true;
  }
  return false;
};

export default {
  bankDataValidation,
};
