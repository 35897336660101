/* eslint-disable no-useless-catch */
/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { findObjectInArray } from '../../../../utils/filters';
import { getDeliveryConfirmation } from '../../../../services/sendings';
import STATES from '../../../../enums/states';

const isDelivered = (shipping) => shipping.state === STATES.delivered;

const getPDFConfirmation = (shipping) => {
  try {
    const linkPdf = getDeliveryConfirmation(shipping.guideNumber, shipping.deliveryCompany);
    return linkPdf;
  } catch (error) {
    throw error;
  }
};

const downloadPdfRequired = async (shipping, pdfConfirmation, index) => {
  if (isDelivered(shipping) && pdfConfirmation) {
    const linkPdf = await getPDFConfirmation(shipping);
    if (linkPdf?.deliveryConfirmation) return linkPdf.deliveryConfirmation;
  }

  return shipping.pdfGuide[index || 0] || null;
};

export const colorTextState = (state) => {
  switch (state) {
    case 'Pérdida del producto':
    case 'Envío no procesado':
    case 'Devuelto':
    case 'Envío cancelado':
    case 'Envío con novedad':
    case 'Pendiente pago devolución':
      return 'red';
    case 'Distribución':
    case 'En ciudad destino':
    case 'En transporte':
    case 'Recogido por la transportadora':
    case 'Entregado':
    case 'Procesando tu envío':
      return 'gray';
    case 'Envío programado':
    case 'Recolección programada':
      return 'blue';
    default:
      return 'gray';
  }
};

export const existanceOfGuide = (guidesArray) => (
  guidesArray?.length > 0
);

export const downloadShippingGuidesFunction = async (
  shippingId, shippingSelecteds, shippingList, pdfConfirmation,
) => new Promise((resolve, reject) => {
  const zip = new JSZip();
  const listToAnalized = shippingId ? [shippingId] : shippingSelecteds.slice();

  const shippingSelectedsObjects = listToAnalized.map((item) => (
    findObjectInArray(item, shippingList, '_id')
  ));

  if (
    pdfConfirmation
    || (shippingSelectedsObjects.length === 1
    && shippingSelectedsObjects[0].pdfGuide?.length === 1)
  ) {
    downloadPdfRequired(shippingSelectedsObjects[0], pdfConfirmation).then((pdfUri) => {
      axios.get(pdfUri, { responseType: 'blob' }).then((response) => {
        const separteUri = response.config.url.split('/');
        const fileName = separteUri[separteUri.length - 1];

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.data);
        link.download = fileName;

        link.click();
        window.URL.revokeObjectURL(link);
        resolve();
      });
    }).catch(() => (!pdfConfirmation ? resolve() : reject()));
  } else {
    for (let i = 0; i < shippingSelectedsObjects.length; i++) {
      let downloadedDocuments = [];
      for (let j = 0; j < shippingSelectedsObjects[i].pdfGuide?.length; j++) {
        const link = shippingSelectedsObjects[i].pdfGuide[j] || null;
        if (link) {
          axios.get(link, { responseType: 'blob' }).then((response) => {
            downloadedDocuments.push({
              name: `${shippingSelectedsObjects[i].code} ${j === 0 ? 'Guía' : 'Relación'}.pdf`,
              file: response.data,
            });

            if (downloadedDocuments.length === shippingSelectedsObjects[i].pdfGuide.length) {
              if (downloadedDocuments.length === 2) {
                zip.file(
                  downloadedDocuments[0].name, downloadedDocuments[0].file,
                ).file(
                  downloadedDocuments[1].name, downloadedDocuments[1].file,
                );
              } else if (downloadedDocuments.length === 1) {
                zip.file(downloadedDocuments[0].name, downloadedDocuments[0].file);
              }

              downloadedDocuments = [];
              if (i === shippingSelectedsObjects.length - 1) {
                zip.generateAsync({ type: 'blob' }).then((content) => {
                  saveAs(content, 'guías.zip');
                });
                resolve();
              }
            }
          }).catch(() => resolve());
        }
      }
    }
  }
});

export default {
  colorTextState,
  downloadShippingGuidesFunction,
  existanceOfGuide,
  downloadPdfRequired,
};
