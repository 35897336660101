import { getGlobalConfigService } from './getGlobalConfig';
import { getConfigNotificationsUser } from './user';

const getShippingStatus = async () => {
  const getconfig = await getGlobalConfigService('notifications').then((notification) => notification.data.shipmentStatus);
  const getconfigUser = await getConfigNotificationsUser();
  const configList = [];

  if (Object.keys(getconfigUser).length === 0) return getconfig;

  getconfig.forEach((config) => {
    const configFilterMp = getconfigUser.mp.filter(
      (configurationOptions) => configurationOptions === config.code,
    )[0];

    const configFilterSaaS = getconfigUser.saas.filter(
      (configurationOptions) => configurationOptions === config.code,
    )[0];

    if (configFilterMp && configFilterSaaS) {
      return configList.push({ activeMp: true, activeSaas: true, ...config });
    }

    if (configFilterMp) {
      return configList.push({ activeMp: true, ...config });
    }

    if (configFilterSaaS) {
      return configList.push({ activeSaas: true, ...config });
    }

    return configList.push(config);
  });

  return configList;
};

export default getShippingStatus;
