import config from '../config/config';
import configS3 from '../config/nocompanyconfig.fron.json';

// Microservices urls
export const { routesMicroserviceUrl } = config; // Routes microservice
export const { usersMicroserviceUrl } = config; // Users microservice
export const { NotificationsMicroserviceUrl } = config; // Notifications microservice
export const { sendingsMicroserviceUrl } = config; // Sendings microservice
export const { deliveryCompanyMicroserviceUrl } = config; // Delivery company microservice
export const { cashMicroserviceUrl } = config; // cash company microservice
export const { statisticsMicroserviceUrl } = config; // dashboard microservice
export const { noveltiesMicroserviceUrl } = config; // Ruta microservicio de novedades
export const { elpMicroserviceUrl } = config;
export const { billingMicroserviceUrl } = config;
export const { globalconfigMicroserviceUrl } = config;
export const { reportingMicroserviceUrl } = config;
export const { interactionsMicroserviceUrl } = config;

export const { customerKeyNotifications } = config;
export const customerKeyNoveltiesMicroservice = 'ad849e98-b2e6-4492-b966-94d93245e003';
export const customerKeyDeliveryCompaniesMicroservice = '664e0f80-4a69-4042-9604-7797e95df194';
export const customerKeyUsersMicroservice = 'b0c6d2aa-4d53-11eb-ae93-0242ac130002';
export const customerKeyRoutesMicroservice = '74390816-b8bd-4c38-8c5d-4453256c110e';
export const customerKeyElpMicroservice = '72562106-decc-4911-9de2-2b53308c66f3';
export const customerKeyStatistics = 'ab9c04f4-cd30-452f-add3-1adb83b970d4';
export const customerKeyBilling = 'b5602275-621f-4c3f-9d00-e8039dbba020';
export const customerKeyReporting = '80d64383-060b-424f-b181-bdb5f835e008';
export const customerKeyInteractionsMicroservice = '0b43a69d-4817-4815-af45-4568c3157554';

export const { customerKeyGlobalConfig } = config;
export const { noveltiesCenterUrl } = config;
export const { collectionPaidUrl } = config;
export const { operationsUrl } = config;
export const { adminUrl } = config;
export const { businessFlags } = configS3;

export const { configFileName } = config;
export const { urlConfigFile } = config; // url configuration file
export const { urlConfigDeliveries } = config;
export const { urlMassiveTemplateExcel } = config; // url archivo de masivos

export const nameLocalStorage = 'userDataToken'; // user data token name
export const secretEncryptionKey = 'de5d19a1-37e4-44bf-a67e-8f08e2d5abf5'; // llave secreta de encriptación
