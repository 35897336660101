import React from 'react';

import ContainerSection from '../../components/containerSection';

import FirstSending from './firstSending';
import './firstSendingScreen.scss';

const FirstSendingScreen = () => (
  <ContainerSection>
    <FirstSending />
  </ContainerSection>
);

export default FirstSendingScreen;
