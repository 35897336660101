import axios from 'axios';

export const getWompi = (endPoint) => new Promise((resolve, reject) => {
  const instance = axios.create({
    method: 'get',
    baseURL: endPoint,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
  });
  delete instance.defaults.headers;
  instance.get()
    .then((response) => resolve(response?.data?.data))
    .catch((err) => reject(err));
});

export const postWompi = (endPoint, headers, data) => new Promise((resolve, reject) => {
  const instance = axios.create({
    method: 'post',
  });

  delete instance.defaults.headers;
  instance.defaults.headers = headers;

  instance.post(endPoint, data)
    .then((response) => resolve(response?.data?.data))
    .catch((err) => reject(err));
});
