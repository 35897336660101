/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { typeDelivery, typeTextDelivery } from '../../../constants/general';
import Modal from '../../modal';
import Typography from '../../typography';
import IconClose from '../../../assets/images/close.svg';

import '../lastDetails.scss';

const ProhibitedItems = ({
  close,
  isMobileDevice,
  closeBackground,
}) => {
  const countryCode = useSelector((state) => state?.country || null);
  return (
    <Modal
      className={`modal__prohibited-items ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
      closeBackground={() => closeBackground && close()}
    >
      <div className="header__prohibited-items">
        <div className="left" />
        <div className="center">
          <span>Artículos prohibidos</span>
        </div>
        <button
          type="button"
          onClick={() => close()}
          className="right"
        >
          <img
            src={IconClose}
            alt="cerrar"
          />
        </button>
      </div>
      <div className="body__prohibited-items">
        <div className="paragraph">
          <Typography
            type="paragraph"
            text={`Las empresas ${typeDelivery[countryCode]}s aliadas a mipaquete son legalmente constituidas y habilitadas para operar por ${typeTextDelivery[countryCode]}. En cumplimiento con la normatividad te agradecemos abstenerse de hacer envíos que contengan artículos u objetos postales prohibidos:`}
          />
        </div>
        <div className="container-items">
          <div className="items left">
            <div className="container-image-text">
              <div className="container-img no-weapons" />
              <Typography
                type="paragraph"
                text="Armas y estupefacientes."
              />
            </div>
            <div className="container-image-text">
              <div className="container-img no-animals" />
              <Typography
                type="paragraph"
                text="Animales."
              />
            </div>
            <div className="container-image-text">
              <div className="container-img no-food" />
              <Typography
                type="paragraph"
                text="Alimentos o productos perecederos."
              />
            </div>
            <div className="container-image-text">
              <div className="container-img no-fire" />
              <Typography
                type="paragraph"
                text="Productos que por su naturaleza sean tóxicos, inflamables o de clasificación “mercancía peligrosa”."
              />
            </div>
            <div className="container-image-text">
              <div className="container-img no-jewell" />
              <Typography
                type="paragraph"
                className="strict-width"
                text="Papel moneda, joyas, títulos, valores, platino, oro, plata, piedras preciosas y otros objetos de valor de similar naturaleza, que no se envíen asegurados."
              />
            </div>
            <div className="container-image-text">
              <div className="container-img no-law" />
              <Typography
                type="paragraph"
                text="Los objetos cuyo tráfico sea constitutivo de un delito."
              />
            </div>
          </div>
          <div className="items rigth">
            <div className="container-image-text">
              <div className="container-img broken-glass" />
              <Typography
                type="paragraph"
                className="strict-width"
                text={`Objetos que por su naturaleza o embalaje pongan en peligro a los empleados de las ${typeDelivery[countryCode]}s que en sí el producto a transportar sea muy delicado como vidrio, acrílico, obras de arte, porcelanas entre otros.`}
              />
            </div>
            <div className="container-image-text">
              <div className="container-img No-liquid" />
              <Typography
                type="paragraph"
                text="Tampoco está permitido el transporte de líquidos."
              />
            </div>
            <div className="container-image-text">
              <div className="container-img no-law" />
              <Typography
                type="paragraph"
                text="Objetos cuyo transporte esté prohibido por la ley."
              />
            </div>
            <div className="container-image-text">
              <div className="container-img no-tv" />
              <Typography
                type="paragraph"
                className="strict-width"
                text="Electrodomésticos como televisores, equipos de sonido, lavadoras, neveras, entre otros. Nuevos o usados. En caso de que se envíen estos productos MI PAQUETE SAS no se hace responsable de pérdidas o averías."
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ProhibitedItems.propTypes = {
  close: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  closeBackground: PropTypes.bool,
};

ProhibitedItems.defaultProps = {
  closeBackground: false,
};

export default ProhibitedItems;
