/* eslint-disable prefer-promise-reject-errors */
import { processCards } from '../../../../services/cash';
import { startPayments } from '../../../../services/elp';

const paymentFunction = async (data, methodPayment) => {
  const suppData = { ...data };
  switch (methodPayment) {
    case 'transactionWithCard':
      return processCards(suppData);
    case 'PSE':
      if (data.documentType === 'PP' || data.documentType === 'CE') {
        suppData.documentType = 'CC';
      }
      return startPayments(suppData);
    default:
      return Promise.reject(
        { status: 500, message: 'not exist method payment provided', publicMessage: 'error interno en el servidor' },
      );
  }
};

export default paymentFunction;
