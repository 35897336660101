import React from 'react';
import PropTypes from 'prop-types';

import './inputCode.scss';

const InputCode = ({
  type, onChange, value, id, className, maxlength,
}) => (
  <input
    id={id || null}
    className={`${className || ''} input-code`}
    maxLength={maxlength}
    value={value}
    onChange={(e) => {
      if (e.target.value && e.target.value !== '') {
        if (type === 'number-text' && /^[\d]+$/.test(e.target.value)) {
          onChange(e.target.value);
        }
        if (type === 'string-no-special' && /^[a-zA-Z0-9]+$/.test(e.target.value)) {
          onChange(e.target.value);
        }
        if (type === 'string') {
          onChange(e.target.value);
        }
      }
      if (e.target.value === '') onChange(e.target.value);
    }}
  />
);

InputCode.propTypes = {
  id: PropTypes.string,
  maxlength: PropTypes.number,
  className: PropTypes.string,
  value: PropTypes.oneOf(['string', 'number']),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['string', 'string-no-special', 'number-text']).isRequired,
};

InputCode.defaultProps = {
  id: null,
  className: null,
  value: undefined,
  maxlength: 6,
};

export default InputCode;
