import React, { useState /* useEffect */ } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Typography from '../../../../components/typography';

import { deleteUsersAddresses } from '../../../../services/user';

import './modalDeleteAddress.scss';

const ModalDeleteAddress = ({
  setListAddress, deleteAddress, setShowModalDelete, closeAndRemove,
}) => {
  const [showModalDeleleConfirm, setShowModalDeleleConfirm] = useState(false);

  const deleteAddresses = async (data) => {
    await deleteUsersAddresses([data]).then((res) => {
      setListAddress(res);
    });
  };

  return (
    <div>
      <Modal closeBackground={closeAndRemove}>
        <div className="deleteDirection">
          <div className="centerTitleModal">
            <div className="iconClose2" onClick={() => setShowModalDelete(false)} />
            <Typography
              type="title"
              text="¿Quieres eliminar esta Sede?"
              className="italic"
            />
          </div>
          <div className="adjustDeleteDirection">
            <div className="titlesDeleteDirection">
              <div>
                <Typography type="field" text="Nombre" />
                <Typography className="ajust-delete-address" type="field" text={deleteAddress.name} />
              </div>
              <div>
                <Typography type="field" text="Origen" />
                <Typography className="ajust-delete-address" type="value" text={`${deleteAddress.locationName}-${deleteAddress.departmentOrStateName}`} />
              </div>
              <div>
                <Typography type="field" text="Dirección" />
                <Typography className="ajust-delete-address" type="value" text={deleteAddress.address} />
              </div>
            </div>
          </div>
          <div className="buttonModal">
            <Button
              type="secondary"
              text="No, volver atrás"
              id="cancelModalDelete"
              onClick={() => setShowModalDelete(false)}
            />
            <Button
              type="quaternary"
              text="Sí, eliminar"
              id="saveModalDelete"
              onClick={() => {
                deleteAddresses(deleteAddress._id);
                setShowModalDeleleConfirm(true);
              }}
            />
          </div>
        </div>
      </Modal>
      {
        showModalDeleleConfirm && (
          <div>
            <Modal closeBackground={() => {
              closeAndRemove();
              setShowModalDeleleConfirm();
            }}
            >
              <div className="confirmDeleteDirection">
                <div>
                  <Typography
                    type="title"
                    text="Se ha eliminado exitosamente la Sede seleccionada"
                    className="italic"
                  />
                </div>
              </div>
            </Modal>
          </div>

        )
      }
    </div>
  );
};

ModalDeleteAddress.propTypes = {
  closeAndRemove: PropTypes.func.isRequired,
  setShowModalDelete: PropTypes.func.isRequired,
  setListAddress: PropTypes.shape().isRequired,
  deleteAddress: PropTypes.shape().isRequired,
};

ModalDeleteAddress.defaultProps = {};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(ModalDeleteAddress);
