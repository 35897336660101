import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import Button from '../../../../../components/button';
import Typography from '../../../../../components/typography';

import { addThousandPoint, toCurrencyCop } from '../../../../../utils/strings';
import { currentCurrency } from '../../../../../constants/general';

import DataStepsThree from '../../../../../config/quoteShippingFields.json';

import collectionIcon from '../../../../../assets/images/Icono_pago_contra_entrega.svg';

import '../stepsQuoteShipping.scss';

const StepThree = ({
  shippingData,
  next,
  back,
  isMobileDevice,
  saleValue,
}) => {
  const user = useSelector((state) => state.auth.user);
  const countryCode = useSelector((state) => state.country);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(DataStepsThree.country[countryCode].dataStepsThree);
  }, []);

  return (
    <div className={isMobileDevice ? 'contentStepQuoteShipping' : 'contentStepQuoteShipping-mobile'}>
      <section className={isMobileDevice ? 'content stepThree' : 'content stepThree-mobile'}>
        {data.map(({
          title, type, classTitle, children, name,
        }) => (
          <Fragment key={name}>
            <Typography className={classTitle} type={type} text={title} key={title} />
            {children.map(({
              textKey,
              text,
              typeTypography,
              textKeyTwo,
              measuringLength,
              classContainer,
            }) => (
              <div
                className={classContainer}
                key={textKey}
              >
                <Typography type={typeTypography} text={text} />
                {textKey === 'declaredValue'
                  ? (
                    <Typography
                      type={typeTypography}
                      text={`$${countryCode === '170'
                        ? addThousandPoint(shippingData[textKey])
                        : shippingData[textKey]} ${user?.currencyToPay ? user?.currencyToPay : currentCurrency[countryCode]}`}
                    />
                  )
                  : (
                    <Typography
                      type={typeTypography}
                      text={`${shippingData[textKey]} ${textKeyTwo && shippingData[textKeyTwo]} ${measuringLength}`}
                    />
                  )}
              </div>
            ))}
          </Fragment>
        ))}
        {saleValue && !isMobileDevice && (
        <div className="containerData">
          <Typography type="paragraph" text="Valor a recaudar:" />
          <div className="collectioIcon">
            <img src={collectionIcon} alt="icono" />
            <Typography type="paragraph" text={toCurrencyCop(user, saleValue, countryCode)} />
          </div>
        </div>
        )}
      </section>

      {isMobileDevice && (
      <section className="containerButtonsStepsQuoteShipping">
        <Button
          text="Quiero corregir"
          type="secondary"
          onClick={() => back()}
          className="widthless"
        />
        <Button
          text="¡Todo está correcto!"
          type="primary"
          onClick={() => {
            next();
          }}
          className="widthless"
        />
      </section>
      )}
    </div>
  );
};

StepThree.propTypes = {
  shippingData: PropTypes.shape({
    origin: PropTypes.string,
    originCode: PropTypes.string,
    destiny: PropTypes.string,
    destinyCode: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    length: PropTypes.number,
    weight: PropTypes.number,
    unitOfMeasurement: PropTypes.string,
    quantity: PropTypes.number,
    declaredValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  saleValue: PropTypes.string,
};

StepThree.defaultProps = {
  saleValue: null,
};
const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default connect(mapStateToProps)(StepThree);
