/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-catch */
/* eslint-disable no-underscore-dangle */
import swal from 'sweetalert';
import buildFilters from '../common/buildObjects';
import saveAsExcel from '../../../../utils/exportxls';
import { humanizationOfTimeDate } from '../../../../utils/generals';
import totalServiceValue, { reimbursementCommission, saving, valueToTransfer } from '../common/calculations';
import {
  deliveryDate, notificationData, paymentKind, pickupDate,
} from '../common/shipping';
import { getShipments } from '../../../../components/customHooks/getSendings';

const pagination = (totalItems, pageSize) => {
  const pages = totalItems / pageSize;
  const possiblePages = Math.floor(pages);
  if (pages - possiblePages === 0) {
    return pages;
  }
  return possiblePages + 1;
};

const shippingTimeValue = (shipment) => (
  shipment.shippingTime ? Math.floor(shipment.shippingTime / 1440) : 'No disponible'
);

const collectionCommissionPercentage = ({ userCollectionDiscountPercentage }) => (
  userCollectionDiscountPercentage ?? 'No aplica'
);

const savingOfValue = (shipment) => saving(shipment) ?? 'No aplica';

const saveExcel = (data, user) => {
  const date = new Date().toISOString();
  const name = `envios de ${user.name}-${date}`;

  const bodyExcel = data.map((shipment) => ({
    'Producto o Referencia': shipment.description,
    'Código mipaquete': shipment.code,
    'Número de Guía': shipment.guideNumber,
    'Número de Recolección': shipment.pickupCode || 'No disponible',
    'Fecha de Solicitud': shipment.confirmationDate ? humanizationOfTimeDate(shipment.confirmationDate, 'general') : '',
    'Fecha de Recogida': pickupDate(shipment),
    Origen: `${shipment.locate.originName}-${shipment.locate.originDepName}`,
    'Nombre del remitente': `${shipment.sender.name} ${shipment.sender.surname}`,
    'Celular del Remitente': shipment.sender.cellPhone,
    'Dirección Remitente': shipment.sender.pickupAddress,
    Destino: `${shipment.locate.destinyName}-${shipment.locate.destinyDepName}`,
    Destinatario: `${shipment.receiver.name} ${shipment.receiver.surname}`,
    'Celular del destinatario': shipment.receiver.cellPhone,
    'Dirección Destinatario': shipment.receiver.destinationAddress,
    Cantidad: shipment.productInformation.quantity,
    'Dimensiones(cm)': `${shipment.productInformation.width}x${shipment.productInformation.large}x${shipment.productInformation.height}`,
    'Peso Volumen (kg)': shipment.productInformation.realWeight,
    'Servicio Adicional': shipment.collectionService ? 'SI' : 'NO',
    'Valor de la Venta': shipment.adminTransactionData.saleValue || 0,
    'Valor a recaudar': shipment.adminTransactionData.collectionValue || 0,
    'Valor de la comisión de recaudo': shipment.adminTransactionData.collectionCommission || 0,
    'Valor del flete': shipment.adminTransactionData.sendingValue,
    'Valor total del servicio': totalServiceValue(shipment),
    'Valor a Transferir': valueToTransfer(shipment),
    'Reintegro comisión del recaudo (por devolución)': reimbursementCommission(shipment),
    'Cobro flete devolución': shipment.returnValue || 0,
    Banco: shipment.bankInformation?.bank || 'No disponible',
    'Nombre del Beneficiario': shipment.bankInformation?.nameBeneficiary || 'No disponible',
    'Número de cuenta': shipment.bankInformation?.numberAccount || 'No disponible',
    'Número de identificación del beneficiario': shipment.bankInformation?.nitBeneficiary || 'No disponible',
    'Tipo de identificación': shipment.bankInformation?.nitTypeBeneficiary || 'No disponible',
    'Tipo de cuenta': shipment.bankInformation?.typeAccount || 'No disponible',
    'Valor declarado': shipment.productInformation.declaredValue,
    Transportadora: shipment.deliveryCompanyName,
    Observaciones: shipment.comments,
    'Forma de pago': paymentKind(shipment),
    'Estado actual del envío': shipment.state,
    'Fecha de entrega': deliveryDate(shipment),
    'Tiempo promesa de entrega en días hábiles': shippingTimeValue(shipment),
    'Valor Flete tarifa Plena': shipment.adminTransactionData.sendingRealValue || 'No aplica',
    '%Descuento en flete': shipment.adminTransactionData.discountValue || 'No aplica',
    '%Descuento comisión recaudo': collectionCommissionPercentage(shipment.adminTransactionData),
    Ahorro: savingOfValue(shipment),
    'Tipo de trayecto': shipment.locate.routeType,
    ...notificationData(shipment),
  }));

  saveAsExcel(bodyExcel, name);
};

const getExcel = async (filtersToDownload, user, setDownload) => {
  try {
    const filters = { ...filtersToDownload };
    const numberItemsPerPage = 100;
    const mp = parseInt(filters?.mpCode || 0, 10) > 0 ? parseInt(filters.mpCode, 10) : null;
    const shipments = [];

    const filterAndPage = {};
    buildFilters({
      filterAndPage, filters, numberItemsPerPage, mp,
    });

    const response = await getShipments(filterAndPage);
    shipments.push(...response.shipments);

    const pages = pagination(response.totalDocuments, numberItemsPerPage);
    for (let i = 2; i <= pages; i += 1) {
      const lastDocumentId = shipments[shipments.length - 1]._id;

      buildFilters({
        filterAndPage, filters, numberItemsPerPage, mp, lastDocumentId, nextOrBack: 'next',
      });

      const answer = await getShipments(filterAndPage);
      shipments.push(...answer.shipments);
    }

    if (shipments.length > 0) saveExcel(shipments, user);
    setDownload(false);
  } catch (error) {
    swal('Error', 'Hubo un error al buscar los datos');
    setDownload(false);
    throw error;
  }
};

export default getExcel;
