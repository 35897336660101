/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom/';

import { cashThousandPoint } from '../../utils/strings';
import { showDependingOnBusinessFlag } from '../../utils/businessFlag';

import WalletList from './walletList';
import HeaderCards from '../../components/headerCards';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';
import UserLevel from '../../components/userLevel';

import add from '../../assets/images/add-orange-icon.svg';

import './walletScreen.scss';

const WalletScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const businessFlags = useSelector((state) => state.globalData.businessFlags || []);
  const { user } = useSelector((state) => state.auth || {});

  const navigate = useNavigate();

  const showUserLevel = () => showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule' }) && (

    <UserLevel
      className="mile-counter-wallet"
      userId={user?._id}
      onClick={() => navigate('/acumula-puntos')}
      user={user}
    />
  );

  return isMobileDevice ? (
    <ContainerSectionMobile>
      <div className="cash-content">
        <span>Recargar saldo</span>
        <HeaderCards
          iconLeft={add}
          colorData="cash"
          iconLeftSize="orange"
          textRight={user.currencyToPay}
          action={() => navigate('/recargar-saldo')}
          data={`${cashThousandPoint(parseInt(user?.cash ?? 0, 10))}`}
        />
      </div>
      <WalletList />
    </ContainerSectionMobile>
  ) : (
    <ContainerSectionDesktop>
      <div className="container-wallet__section-desktop">
        <div className="primary-column">
          <WalletList />
        </div>
        <div className="secondary-column">
          {showUserLevel()}
        </div>
      </div>
    </ContainerSectionDesktop>
  );
};

export default WalletScreen;
