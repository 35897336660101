import { invoicingDataService, invoicingDataUpdateService } from '../../../../../services/user';

const createOrUpdateInvoicingData = async (user, data) => {
  if (user?.invoicingData) {
    return invoicingDataUpdateService(data);
  }

  return invoicingDataService(data);
};

export default createOrUpdateInvoicingData;
