/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { ReactComponent as ArrowBack } from '../../../assets/images/icon-arrow-back-color-primary.svg';

import config from '../../../config/config';

import IconCash from '../../../assets/images/attach_money.svg';
import arrowRight from '../../../assets/images/arrow_back_ios_new-24px_orange.svg';
import arrowRightDisabled from '../../../assets/images/arrow_back_ios_new-24px.svg';
import bancolombiaActive from '../../../assets/images/LogoBancolombiaActive.png';
import bancolombiaDisabled from '../../../assets/images/LogoBancolombia_Disabled.png';
import bankCardActive from '../../../assets/images/Icon-material-credit-card.svg';
import bankCardDisabled from '../../../assets/images/icon-disabled- credit-card.svg';

import Input from '../../../components/input';
import CheckBox from '../../../components/checkBox';
import Typography from '../../../components/typography';
import RadioButton from '../../../components/radioButton';
import CardImageButton from '../../../components/cardImageButon';

import './rechargeBalanceWompy.scss';
import { getTokenAndDocument } from './wompi';
import { createTransaction } from './wompi/transaction';

const wompiPersonType = {
  naturalPerson: 'PERSON',
  legalPerson: 'LEGAL', // this type of person, does not exist in wompi, this was created by me
};

const WompyPayment = ({
  setShowRecharBalanceScreen,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const wompiPublicKey = useSelector((state) => state.globalData.wompiPublicKey || []);

  const [typePerson, setTypePerson] = useState(null);
  const [balanceError, setBalanceError] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(null);
  const [tokenAndCoument, setTokenAndDocument] = useState(null);
  const [balanceToRecharge, setBalanceToRecharge] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState(null);

  const TACUrl = 'https://mipaquete.com/politicas-privacidad-de-datos-terminos-y-condiciones/';

  useEffect(() => {
    if (balanceToRecharge >= 1500) setButtonEnabled(true); setBalanceError(null);
    if (typeof balanceToRecharge === 'number' && balanceToRecharge < 1500) {
      setButtonEnabled(false);
      setBalanceError('La recarga minima debe ser de $1.500');
    }
  }, [balanceToRecharge]);

  const GoBackAndHideRechargeBalance = () => (
    <ArrowBack
      onClick={() => (
        setShowRecharBalanceScreen
          ? setShowRecharBalanceScreen(false)
          : navigate(-1)
      )}
      className="arrowBack"
    />
  );

  const getToken = async () => {
    const data = await getTokenAndDocument();
    if (data?.acceptance_token && data.documentLink) setTokenAndDocument(data);
  };

  useEffect(() => {
    if (typePerson === wompiPersonType.naturalPerson) {
      getToken();
    }
    if (typePerson !== wompiPersonType.naturalPerson) setTokenAndDocument(null);
  }, [typePerson]);

  const fakeSubmitForm = () => {
    const formButton = document.getElementById('buton');
    formButton.click();
  };

  const generateTransaction = async () => {
    const amountInCents = Number.parseInt(balanceToRecharge, 10) * 100;
    const urlPayment = await createTransaction(
      amountInCents, tokenAndCoument.acceptance_token, user.email, user._id,
    );
    if (typeof urlPayment === 'string') window.open(urlPayment, '_blank', 'noopener');
  };

  return (
    <div id="container-recharge-balance-wompy" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div id="header__container-recharge-balance">
        <div className="left">
          <GoBackAndHideRechargeBalance />
        </div>
        <div className="center">
          <Typography text="Recarga tu saldo aquí" type="superTitle-italic" />
        </div>
        <div className="rigth" />
      </div>
      <div id="body__container-recharge-balance">
        <div className="second-body__container-recharge-balance">
          <form action="https://checkout.wompi.co/p/" target="blank" method="GET">
            <div className="container-input-and-checkbox">
              <div className="wompi-value">
                <Input
                  label="¿Cuál es el valor de tu recarga?"
                  value={balanceToRecharge}
                  onChange={setBalanceToRecharge}
                  type="number"
                  iconRigth={IconCash}
                  placeholder="$69.000"
                  personalizedFirstSpan="new-color"
                  annotation={!balanceError && 'Recarga minima de $1.500'}
                  annotationError={balanceError}
                />
              </div>
              <div className="container-type-person">
                <Typography
                  type="field-dark-gray"
                  text="Tipo de persona"
                  className="color-darker-gray left-space"
                />
                <RadioButton
                  name="Persona Natural"
                  value={wompiPersonType.naturalPerson}
                  text="Persona Natural"
                  checked={typePerson === wompiPersonType.naturalPerson}
                  onChange={setTypePerson}
                />
                <RadioButton
                  name="Persona jurídica"
                  value={wompiPersonType.legalPerson}
                  text="Persona jurídica"
                  checked={typePerson === wompiPersonType.legalPerson}
                  onChange={setTypePerson}
                />
              </div>
              <div className="container-terms">
                <CheckBox
                  clickOnText={() => window.open(TACUrl, '_blank', 'noopener')}
                  clickOnSeconText={() => window.open(tokenAndCoument?.documentLink, '_blank', 'noopener')}
                  checked={termsAndConditions}
                  onChange={setTermsAndConditions}
                  annotationError={termsAndConditions === false && 'Es necesaria esta confirmación para continuar.'}
                  text="Acepto términos y condiciones Mi paquete"
                  secondText={tokenAndCoument?.documentLink && 'y Wompi'}
                  className="terms-and-condition-text"
                />
              </div>
              {typePerson === wompiPersonType.naturalPerson && (
                <div className="contaier-payment-methods">
                  <div className="title">
                    <Typography
                      text="Métodos de pago"
                      type="field-gray"
                      className="color-darker"
                    />
                  </div>
                  <hr />
                  <div className="banks-container">
                    <CardImageButton
                      imageButtonText="Bancolombia"
                      imageButtonDescription="Cuenta ahorros, corriente o ahorro a la mano"
                      imgRight={arrowRight}
                      imgRightDisabled={arrowRightDisabled}
                      imgLeft={bancolombiaActive}
                      imgLeftDisabled={bancolombiaDisabled}
                      disabled={!buttonEnabled || !termsAndConditions}
                      onClick={generateTransaction}
                    />
                    <hr />
                    <CardImageButton
                      imageButtonText="Otros bancos"
                      imageButtonDescription="Tarjetas de crédito u otros medios"
                      imgRight={arrowRight}
                      imgRightDisabled={arrowRightDisabled}
                      imgLeft={bankCardActive}
                      imgLeftDisabled={bankCardDisabled}
                      disabled={!buttonEnabled || !termsAndConditions}
                      onClick={fakeSubmitForm}
                    />
                    <hr />
                  </div>
                </div>
              )}
              <div className={`container-buttons ${typePerson === wompiPersonType.naturalPerson ? 'hidden' : ''}`}>
                <input type="submit" className="Button-primary button-primary" value="Recargar saldo" id="buton" disabled={!buttonEnabled || !termsAndConditions} />
              </div>
            </div>
            <input type="hidden" name="public-key" value={wompiPublicKey} />
            <input type="hidden" name="currency" value="COP" />
            <input type="hidden" name="amount-in-cents" value={Number.parseInt(balanceToRecharge, 10) * 100} />
            <input type="hidden" name="reference" value={`${Date.now()}-${user._id}`} />
            <input type="hidden" name="redirect-url" value={config.mipqueteUrl} />
            <input type="hidden" name="customer-data:email" value={user.email} />
            <input type="hidden" name="customer-data:full-name" value={`${user.name} ${user.surname}`} />
            <input type="hidden" name="customer-data:phone-number" value={user.cellPhone} />
            <input type="hidden" name="customer-data:legal-id" value={user.documentNumber} />
            <input type="hidden" name="customer-data:legal-id-type" value={user.documentType} />
          </form>
        </div>
      </div>
    </div>
  );
};

WompyPayment.propTypes = {
  setShowRecharBalanceScreen: PropTypes.func,
};

WompyPayment.defaultProps = {
  setShowRecharBalanceScreen: null,
};

export default WompyPayment;
