const ELP_MESSAGES = {
  processing: 'Estamos procesando tu pago esto puede tomar unos minutos',
  successfull: 'Hemos recargado tu saldo con éxito',
  error: 'Error del sistema',
};

export const errorMessages = {
  origin: 'Origen no encontrado',
  destiny: 'Destino no encontrado',
};
export default ELP_MESSAGES;
