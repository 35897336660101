import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './buttonHelp.scss';

/**
 * Componente que abarca los botones de la app
 * @param {string} text - texto a mostrarse en el botón
 * @param {string} imgRigth - imagen derecha del botón
 * @param {string} imgLeft - imagen izquierda del botón
 * @param {string} id - propiedad única del componente
 * @param {string} linkHelp - propiedad única del componente
 * @param {function} onClick - función a ejecutar al presionar el botón
 * @param {string} textDescription - texto que aparecerá una vez se haga clic en el boton
 * @param {string} linkIconHelp - propiedad única del componente

 */

const ButtonHelp = ({
  text,
  imgRigth,
  imgLeft,
  id,
  linkHelp,
  linkIconHelp,

}) => {
  const link = linkHelp;
  const linkIcon = linkIconHelp;

  return (
    <div className="container-button">
      <div className="flexbox-container">
        <div className="z-index">
          <button
            id={id}
            type="button"
            className="button-help"
            onClick={() => { window.open(link, 'noopener'); }}
          >
            <p onClick={() => window.open(linkHelp, 'noopener')}>{text}</p>
            <img onClick={() => window.open(linkIcon, 'noopener')} src={imgLeft} alt={imgLeft} />
          </button>
        </div>

        <div className="right-image">
          <img src={imgRigth} alt={imgRigth} onClick={() => window.open(linkIcon)} title="Contacta tu asesor en caso de dudas con tus recaudos" />
          <div>
            <span className="title-contact">Contacta tu asesor</span>
            <p>En caso de dudas con tus recaudos</p>
          </div>
        </div>
      </div>
    </div>
  );
};

ButtonHelp.propTypes = {
  text: PropTypes.string,
  imgLeft: PropTypes.string,
  imgRigth: PropTypes.string,
  linkHelp: PropTypes.string,
  id: PropTypes.string,
  linkIconHelp: PropTypes.string,
};

ButtonHelp.defaultProps = {
  text: null,
  imgLeft: '',
  imgRigth: '',
  id: '',
  linkHelp: '#',
  linkIconHelp: '#',
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,

});

export default connect(mapStateToProps)(ButtonHelp);
