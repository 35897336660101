import React from 'react';
import PropTypes from 'prop-types';

import './toggleSwitch.scss';

const ToggleSwitch = ({
  checked,
  onChange,
  text,
  customcClassFirstSpan,
  customClassCheckedText,
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="bodyChecBox" onClick={(e) => e.stopPropagation()}>
    <span className={`${customcClassFirstSpan} ${checked ? customClassCheckedText || '' : ''}` || ''}>{text}</span>
    <div className="toggle-control">
      <input type="checkbox" checked={checked} onClick={onChange} />
      <span className="control" />
    </div>
  </label>
);

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  customcClassFirstSpan: PropTypes.string,
  customClassCheckedText: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  text: null,
  customcClassFirstSpan: null,
  customClassCheckedText: null,
};

export default ToggleSwitch;
