export const stepOne = {
  className: 'stepOne',
  alt: 'Lo primero',
  title: 'Lo primero',
  description: 'es que solicitar tus envíos será mucho más sencillo.',
  specialText: 'Lo mejor de todo',
};

export const stepTwo = {
  className: 'stepTwo',
  alt: 'Tus cotizaciones',
  title: 'Tus cotizaciones',
  description: 'En esta sección puedes guardar tus envíos durante un mes para que luego los puedas solicitar.',
};

export const stepThree = {
  className: 'stepThree',
  alt: 'Seguimiento de envíos',
  title: 'Seguimiento de envíos',
  description: 'Tienes un enlace de seguimiento para que compartas con tus destinatarios el estado del envío.',
};

export const stepFour = {
  className: 'stepFour',
  alt: 'Historial de envíos',
  title: 'Historial de envíos',
  description: 'Puedes descargar los informes de tus envíos y conocer el detalle de cada uno.',
};

export const stepFive = {
  className: 'stepFive',
  alt: 'Cotiza múltiples envíos',
  title: 'Cotiza múltiples envíos',
  description: 'Puedes solicitar múltiples envíos a través de una plantilla de Excel súper sencilla que hemos diseñado para ti.',
};

export const stepSix = {
  className: 'stepSix',
  alt: 'Tu billetera',
  title: 'Tu billetera',
  description: 'En tu billetera encuentras la información de tu saldo y el detalle de tus transacciones.',
};
