import { useSelector } from 'react-redux';
import React from 'react';

import MyTransportersSettings from './myTransportersSettings';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './myTrasportersSettings.scss';

const MyTransportersSettingsScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile className="bargainings-information">
        <MyTransportersSettings />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <MyTransportersSettings />
      </ContainerSectionDesktop>
    )
  );
};

export default MyTransportersSettingsScreen;
