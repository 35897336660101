/* eslint-disable import/prefer-default-export */
import axios from 'axios';

import {
  routesMicroserviceUrl,
} from '../constants';

const routeExistenceVerificationService = (
  originCountryCode, originLocationCode, destinyCountryCode, destinyLocationCode,
) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = '74390816-b8bd-4c38-8c5d-4453256c110e';
  axios.post(`${routesMicroserviceUrl}/routeExistenceVerification`, {
    originCountryCode,
    originLocationCode,
    destinyCountryCode,
    destinyLocationCode,
  })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((e) => {
      reject(e);
    });
});

export default routeExistenceVerificationService;
