import { createValidationCodes, validateCodes } from '../../../services/notifications';

export const createValidationCodesService = async (process) => {
  try {
    return await createValidationCodes({ process });
  } catch (error) {
    return { error: 'No es posible crear el código' };
  }
};

export const validateCodeService = async (process, code) => {
  try {
    return await validateCodes({ process, code });
  } catch (error) {
    return { error: 'Código incorrecto' };
  }
};
