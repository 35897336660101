/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import swal from 'sweetalert';

import { loginService, resetPasswordService } from '../../../services/user';
import { SignInAction } from '../../../store/actions/auth.actions';
import { setCurrentUser } from '../../../utils/auth';
import { findObjectInArray } from '../../../utils/filters';
import { validationValue, insertOrRemoveErrorFromList } from '../../../utils/strings';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import Input from '../../../components/input';
import Button from '../../../components/button';

import Typography from '../../../components/typography';

import IconVisibility from '../../../assets/images/icon-visibility-color-gray.svg';
import IconVisibilityOff from '../../../assets/images/icon-visibility-off-color-gray.svg';

import './resetPassword.scss';
import { annotationPassword } from '../../../constants/annotations';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [readParams, setReadParams] = useState(false);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [executingAction, setExecutingAction] = useState();
  const [errorsList, setErrorsList] = useState([]);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  useEffect(() => {
    if (!readParams) {
      const values = Object.fromEntries([...searchParams]);
      if (values && !readParams) {
        setUserId(values.user);
        setToken(values.token);
        navigate('/restaurar-contrasena', { replace: true });
      }
      setReadParams(true);
    }
  }, [readParams]);

  const validatePasswords = () => {
    const temporalErrorsList = errorsList.slice();

    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'password',
      'Ingrese una contraseña válida.',
      validationValue(password, 'password').correct ? 'remove' : 'insert',
    );

    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'confirmPassword',
      'Las contraseñas deben coincidir.',
      ((confirmPassword && password) && confirmPassword === password) ? 'remove' : 'insert',
    );

    setErrorsList(temporalErrorsList);
    if (temporalErrorsList.length === 0) return true;
    return false;
  };

  const resetPasswordFunction = async () => {
    setExecutingAction(true);
    try {
      const responseResetPassword = await resetPasswordService(userId, password, token);
      if (responseResetPassword) {
        swal('¡Listo!', 'Tu contraseña ha sido reestablecida exitosamente.');
        try {
          const responseLogin = await loginService({
            email: responseResetPassword.email, password,
          });
          if (responseLogin) {
            dispatch(SignInAction(responseLogin.user));
            setCurrentUser(responseLogin, false);
            navigate('/', { replace: true });
          }
        } catch {
          navigate('/ingreso', { replace: true });
        }
      }
    } catch {
      swal('Error', 'Ha ocurrido un error.');
    } finally {
      setExecutingAction(false);
    }
  };

  return (
    <div id="reset-password" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <Typography
        text="Crea tu nueva contraseña"
        type="superTitle-italic"
        className="title-reset-password"
      />
      <div className="form__reset-password">
        <Input
          label="Escribe tu nueva contraseña"
          classNameContainer="password__container"
          type={showPassword ? 'text' : 'password'}
          iconRigth={showPassword ? IconVisibilityOff : IconVisibility}
          onClickIconRight={() => setShowPassword(!showPassword)}
          value={password}
          onChange={setPassword}
          annotation={annotationPassword}
          annotationError={
            findObjectInArray('password', errorsList, 'name')
              ? findObjectInArray('password', errorsList, 'name').error
              : null
          }
        />
        <Input
          label="Confirma tu nueva contraseña"
          classNameContainer="confirm-password__container"
          type={showConfirmPassword ? 'text' : 'password'}
          iconRigth={showConfirmPassword ? IconVisibilityOff : IconVisibility}
          onClickIconRight={() => setShowConfirmPassword(!showConfirmPassword)}
          value={confirmPassword}
          onChange={setConfirmPassword}
          annotationError={
            findObjectInArray('confirmPassword', errorsList, 'name')
              ? findObjectInArray('confirmPassword', errorsList, 'name').error
              : null
          }
          onPaste
          onCopy
        />

        <Button
          text="Reestablecer contraseña"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          loading={executingAction}
          onClick={() => {
            if (validatePasswords()) {
              resetPasswordFunction();
            }
          }}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
