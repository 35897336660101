/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../../../components/checkBox';
import { colorTextState, existanceOfGuide } from '../../utils';
import { findObjectInArray } from '../../../../../../utils/filters';
import { findImageIntoDeliveries, humanizationOfTimeDate } from '../../../../../../utils/generals';
import { addThousandPointCollection, capitalizeWords, extractToSpecialCharacter } from '../../../../../../utils/strings';
import collectionIcon from '../../../../../../assets/images/Icono_pago_contra_entrega.svg';
import errorGuideActive, { guideErrorState } from '../../../common/isAnError';

const shippingRead = (shipping) => !!shipping;

export const stateImageClase = ({ state }) => state !== 'Procesando tu envío' ? '' : 'load';
export const textSpanState = ({ state }) => state !== 'Procesando tu envío' ? 'Descargar guía' : 'Tu guía se está generando. No canceles tu envío';

const ItemListDesktop = ({
  shipping,
  handleChangeShippingSelecteds,
  handleChangeShippingSelected,
  shippingSelecteds,
  deliveryCompanies,
  downloadShippingGuides,
  isCountryMex,
}) => (
  <button className="history_row" key={shipping._id}>
    <button className={`desktop-table__shipping-history ${isCountryMex ? 'mx' : ''}`}>
      <div className={`cell one ${!shippingRead(shipping) && 'unread'}`}>
        <Checkbox
          onClick={() => existanceOfGuide(shipping.pdfGuide) && (
            handleChangeShippingSelecteds(shipping._id)
          )}
          className={errorGuideActive(shipping) && 'error-checkbox'}
          checked={!!findObjectInArray(shipping._id, shippingSelecteds)}
        />
      </div>
      <button
        type="button"
        className={`cell two ${!shippingRead(shipping) && 'unread'}`}
        onClick={() => handleChangeShippingSelected(shipping.code)}
      >
        <img
          src={findImageIntoDeliveries(shipping.deliveryCompany, deliveryCompanies)}
          alt=""
        />
      </button>
      <button
        type="button"
        className={`cell three ${!shippingRead(shipping) && 'unread'} ${(errorGuideActive(shipping)) && 'red'}`}
        onClick={() => handleChangeShippingSelected(shipping.code)}
      >
        {shipping.guideNumber || 'No disponible'}
      </button>
      <button
        type="button"
        className={`cell four ${!shippingRead(shipping) && 'unread'}`}
        onClick={() => handleChangeShippingSelected(shipping.code)}
      >
        {`MP${shipping.code}`}
      </button>
      <button
        type="button"
        className={`cell five ${!shippingRead(shipping) && 'unread'}`}
        onClick={() => handleChangeShippingSelected(shipping.code)}
      >
        {capitalizeWords(extractToSpecialCharacter(shipping.locate.originName, '-')[0])}
        -
        {capitalizeWords(extractToSpecialCharacter(shipping.locate.destinyName, '-')[0])}
      </button>
      <button
        type="button"
        className={`cell six ${!shippingRead(shipping) && 'unread'}`}
        onClick={() => handleChangeShippingSelected(shipping.code)}
      >
        {`${shipping.receiver.name} ${shipping.receiver.surname}`}
      </button>
      <button
        type="button"
        className={`cell seven ${colorTextState(guideErrorState(shipping))} ${!shippingRead(shipping) && 'unread'}`}
        onClick={() => handleChangeShippingSelected(shipping.code)}
      >
        {guideErrorState(shipping)}
      </button>
      <button
        type="button"
        className={`cell eight ${!shippingRead(shipping) && 'unread'}`}
        onClick={() => handleChangeShippingSelected(shipping.code)}
      >
        {humanizationOfTimeDate(shipping.confirmationDate, 'general')}
      </button>
      {!isCountryMex && (
        <button
          type="button"
          className={`cell nine ${!shippingRead(shipping) && 'unread'}`}
          onClick={() => handleChangeShippingSelected(shipping.code)}
        >
          {shipping.valueCollection > 0 && (
            <>
              <img
                src={collectionIcon}
                alt={addThousandPointCollection(shipping.valueCollection)}
              />
              <span>{`Valor recaudo: ${addThousandPointCollection(shipping.valueCollection)}`}</span>
            </>
          )}
        </button>
      )}
      <div
        className={`cell ten ${!shippingRead(shipping) && 'unread'}`}
      >
        {!errorGuideActive(shipping) && (
          <button
            type="button"
            className={`imagen_button ${!existanceOfGuide(shipping.pdfGuide) || shippingSelecteds.length > 0 ? 'disabled' : 'enabled'} ${stateImageClase(shipping)}`}
            onClick={() => {
              if (existanceOfGuide(shipping.pdfGuide) && shippingSelecteds.length <= 0) {
                downloadShippingGuides(shipping._id);
              }
            }}
          >
            <div className="img" />
            <span>{textSpanState(shipping)}</span>
          </button>
        )}
      </div>
    </button>
  </button>
);

ItemListDesktop.propTypes = {
  shipping: PropTypes.shape({
    code: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    deliveryCompanyName: PropTypes.string.isRequired,
    deliveryCompany: PropTypes.string.isRequired,
    guideNumber: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    pdfGuide: PropTypes.arrayOf(PropTypes.string).isRequired,
    receiver: PropTypes.shape({
      surname: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    locate: PropTypes.shape({
      originName: PropTypes.string.isRequired,
      destinyName: PropTypes.string.isRequired,
    }).isRequired,
    adminTransactionData: PropTypes.shape({
      collectionValue: PropTypes.number.isRequired,
    }),
    confirmationDate: PropTypes.string.isRequired,
    valueCollection: PropTypes.number.isRequired,
  }).isRequired,
  handleChangeShippingSelecteds: PropTypes.func.isRequired,
  handleChangeShippingSelected: PropTypes.func.isRequired,
  downloadShippingGuides: PropTypes.func.isRequired,
  shippingSelecteds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deliveryCompanies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isCountryMex: PropTypes.bool.isRequired,
};

export default ItemListDesktop;
