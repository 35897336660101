import { frecuencyValues } from './enum';
import { createInterationService, getAdsService } from './services';
import showAdNow from './validations';

export const getAdEffect = async (setState) => {
  const adFound = await getAdsService();

  if (adFound) setState(adFound);
};

export const isneedItShowAd = async (ad, user) => {
  const isItDisplayable = await showAdNow(ad, user);
  return isItDisplayable;
};

export const createInteration = async (ad, user) => {
  if (ad.frecuency === frecuencyValues.one && user) {
    createInterationService(ad);
  }
  return null;
};
