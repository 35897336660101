/* eslint-disable quote-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { createApikey, deleteApiKey } from 'services/user';

import DeleteModal from './modal';

import Toast from '../../../../components/toast';
import Button from '../../../../components/button';
import Typography from '../../../../components/typography';

import copyLogo from '../../../../assets/images/copyLogo.svg';
import toastSucces from '../../../../assets/images/toast-succes.svg';
import toastFailed from '../../../../assets/images/toast-failled.svg';
import copyLogoSelect from '../../../../assets/images/copyLogoSelect.svg';

import './apiKey.scss';

const ApiKey = ({
  user, apiKey, setApiKey, isMobileDevice,
}) => {
  const [showPassword, setShowPassword] = useState(true);
  const [maskApiKey, setMaskApiKey] = useState(true);
  const [copyState, setCopyState] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showToastSucces, setShowToastSucces] = useState(false);
  const [showToastSuccesDelete, setShowToastSuccesDelete] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [tooltipText, setTooltipText] = useState('Copiar clave');

  const deleteApikeyService = async () => {
    try {
      const deleteKey = await deleteApiKey(user._id);
      if (deleteKey) {
        setShowToastSuccesDelete(true);
        setTimeout(() => setApiKey(null), 4000);
        setTimeout(() => {
          setShowToastSuccesDelete(false);
        }, 7500);
      }
    } catch (error) {
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 7500);
    }
  };

  const createApikeyService = async () => {
    try {
      const createapikey = await createApikey(user._id);
      if (createapikey) {
        setShowToastSucces(true);
        setTimeout(() => {
          setShowToastSucces(false);
        }, 7500);
      }
      setApiKey(createapikey.APIKey);
    } catch (error) {
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 7500);
    }
  };

  const copyToClipboard = async (text) => {
    if (!navigator.clipboard) {
      return;
    }
    await navigator.clipboard.writeText(text);
    setTooltipText('Copiado');
    setCopyState(true);
    setTimeout(() => {
      setCopyState(false);
      setTooltipText('Copiar clave');
    }, 3000);
  };

  return (
    <div className="apikey-container">
      <div className="title-direction">
        <Typography text="Clave de Api" className="title" />
      </div>
      <div className="text-direction">
        <Typography text="Crea tu clave de acceso a nuestra API y conecta tu tienda." />
      </div>
      <div className="apikey">
        <Typography text="ApiKey" className="apikey-title" />
        <div className="apikey-info">
          <div className="apikey-info-left">
            {maskApiKey && apiKey ? (
              <Typography
                text={
                  isMobileDevice
                    ? '• • • • • • • • • • •'
                    : '• • • • • • • • • • • • • • • • • • • •'
                }
                className="password"
              />
            ) : (
              <Typography text={apiKey} className="password" />
            )}
            {apiKey && (
              <button
                type="button"
                className="copy-item"
                onClick={() => {
                  setCopyState(!copyState);
                  if (apiKey) {
                    copyToClipboard(apiKey);
                  }
                }}
              >
                <img
                  alt="copy"
                  src={copyState ? copyLogoSelect : copyLogo}
                  className="copy-item-icon"
                />
                <span className="tooltip">{tooltipText}</span>
              </button>
            )}
          </div>
          {apiKey && (
            <div className="apikey-info-rigth">
              {showPassword ? (
                <button
                  type="button"
                  className="icon-hidden"
                  aria-label="Ocultar clave"
                  onClick={() => {
                    setMaskApiKey(false);
                    setShowPassword(false);
                  }}
                />
              ) : (
                <button
                  type="button"
                  className="icon-show"
                  aria-label="Mostrar clave"
                  onClick={() => {
                    setMaskApiKey(true);
                    setShowPassword(true);
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="button-container">
        {apiKey ? (
          <Button
            text="Eliminar"
            type="tertiary"
            className="delete-button"
            onClick={() => setShowModalDelete(true)}
          />
        ) : (
          <Button
            text="Crear Key"
            type="quaternary"
            className="create-button"
            onClick={() => createApikeyService()}
          />
        )}
      </div>
      {showModalDelete && (
        <DeleteModal
          setShowModalDelete={setShowModalDelete}
          deleteApikeyService={deleteApikeyService}
        />
      )}
      {showToastSucces && (
        <Toast
          autoClose={7000}
          iconLeft={toastSucces}
          progressColor="success"
          title="La api-key se ha creado con éxito"
          message="..."
          position="top-right"
        />
      )}
      {showToastError && (
        <Toast
          autoClose={7000}
          iconLeft={toastFailed}
          progressColor="error"
          title="La api-key no pudo crearse"
          message="Ocurrió un error, intenta nuevamente."
          position="top-right"
        />
      )}
      {showToastSuccesDelete && (
        <Toast
          autoClose={7000}
          iconLeft={toastSucces}
          progressColor="success"
          title="La api-key se ha eliminado correctamente"
          message="Genera una nueva clave para conectar tu tienda."
          position="top-right"
        />
      )}
    </div>
  );
};

ApiKey.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  apiKey: PropTypes.string.isRequired,
  setApiKey: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

export default ApiKey;
