/* eslint-disable quote-props */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DataBankData from './dataBankData';
import BankAccountModal from './modals/bankAccount';

import Button from '../../../components/button';
import Typography from '../../../components/typography';

import pencil from '../../../assets/images/profile-pencil-icon.svg';
import ArrowBack from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './contactInfo.scss';
import ImageRedirectProfile from '../../../components/imageRedirectProfile';

const BankDataScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth.user);

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  return (
    <div id="profile" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className={`header-profile ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
        <button type="button" onClick={() => navigate('/perfil')} className="content-back">
          <img
            src={ArrowBack}
            alt="back"
          />
          <Typography
            type="superTitle-italic"
            text="Datos de bancarios"
            className="button-back"
          />
        </button>
        <div className="editButton">
          {isMobileDevice ? (
            <div>
              <button type="button" aria-label="Edit bank" onClick={() => setShowModal(true)}>
                <ImageRedirectProfile
                  src={pencil}
                />
              </button>
            </div>
          ) : (
            <Button
              text="Editar"
              type="tertiary"
              onClick={() => setShowModal(true)}
            />
          )}
        </div>
      </div>
      <div className="profile">
        <DataBankData
          user={user}
        />
      </div>
      {
        showModal && (
          <BankAccountModal
            navigate={navigate}
            setShowBankAccountModal={setShowModal}
            isMobileDevice={isMobileDevice}
          />
        )
      }
    </div>
  );
};

export default BankDataScreen;
