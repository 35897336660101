/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import StepOne from '../completingData/modalSteps/stepOne/stepOne';
import StepTwo from '../completingData/modalSteps/stepTwo/stepTwo';
import StepThree from '../completingData/modalSteps/stepThree/stepThree';

const StepsModalCompletigData = ({
  step, propsStepOne, propsStepTwo, propsStepThree,
}) => {
  switch (step) {
    case 1: return (
      <StepOne
        {...propsStepOne}
      />
    );
    case 2: return (
      <StepTwo
        {...propsStepTwo}
      />
    );
    case 3: return (
      <StepThree
        {...propsStepThree}
      />
    );
    default: return <></>;
  }
};
StepsModalCompletigData.propTypes = {
  step: PropTypes.number.isRequired,
  propsStepOne: PropTypes.shape({}).isRequired,
  propsStepTwo: PropTypes.shape({}).isRequired,
  propsStepThree: PropTypes.shape({}).isRequired,
};
export default StepsModalCompletigData;
