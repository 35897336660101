import React from 'react';
import PropTypes from 'prop-types';

import './userAvatar.scss';

const UserAvatar = ({
  className, image, firstLetter, size, round,
}) => (
  <div
    className={`user-avatar ${className} ${round ? 'round' : ''}`}
    style={{
      width: `${size.toString()}px`,
      height: `${size.toString()}px`,
      'max-width': `${size.toString()}px`,
      'max-height': `${size.toString()}px`,
      'min-width': `${size.toString()}px`,
      'min-height': `${size.toString()}px`,
    }}
  >
    {image && <img src={image} alt="avatar" />}
    {firstLetter && (
      <div
        className="first-letter"
        style={{ fontSize: `${parseInt(size * (60 / 100), 10).toString()}px` }}
      >
        {firstLetter[0].toUpperCase()}
      </div>
    )}
  </div>
);

UserAvatar.propTypes = {
  className: PropTypes.string,
  image: PropTypes.element,
  firstLetter: PropTypes.string,
  size: PropTypes.number,
  round: PropTypes.bool,
};

UserAvatar.defaultProps = {
  className: '',
  image: null,
  firstLetter: null,
  size: 44,
  round: false,
};

export default UserAvatar;
