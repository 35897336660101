import {
  noveltiesCenterUrl, adminUrl, collectionPaidUrl, businessFlags,
} from './index';
import { searchBusinessFlag } from '../utils/businessFlag';

const dataSectionsTitles = {
  notAutenticated: [
    {
      url: '/',
      label: 'Inicio',
    },
    {
      url: '/ingreso',
      label: 'Ingreso',
    },
    {
      url: '/cotizacion',
      label: 'Cotiza tu envío',
      IconHome: true,
    },
    {
      url: '/seguimiento-envio',
      label: 'Seguimiento de envíos',
    },
    {
      url: '/registro',
      label: 'Registro',
      IconHome: true,
      noLeftMenu: true,
    },
    {
      url: '/cotizacion/quoteShipping',
      label: 'Cotiza tu envío',
    },
    {
      url: '/cotizacion/quoteMultipleShippings',
      label: 'Cotización Masiva',
    },
  ],
  userAutenticated: [
    {
      url: '/',
      label: 'Inicio',
    },
    {
      url: '/cotizacion',
      label: 'Cotiza tu envío',
    },
    {
      url: '/historial-envios',
      label: 'Tus envíos',
    },
    {
      url: '/detalle-envio',
      label: 'Tus envíos',
    },
    {
      url: '/billetera',
      label: 'Tu billetera',
    },
    {
      url: '/perfil/mobile',
      label: 'Tu perfil',
    },
    {
      url: noveltiesCenterUrl,
      label: 'Novedades',
    },
    {
      url: searchBusinessFlag(businessFlags, 'tusRecaudos')?.enabled ? '/tus-recaudos' : collectionPaidUrl,
      label: 'Tus recaudos',
    },
    {
      url: '/cotizacion/quoteShipping',
      label: 'Cotiza tu envío',
    },
    {
      url: '/cotizacion/quoteMultipleShippings',
      label: 'Cotización Masiva',
    },
  ],
  internalAutenticated: [
    {
      url: `${adminUrl}/recaudos`,
      label: 'Recaudos',
    },
    {
      url: noveltiesCenterUrl,
      label: 'Novedades',
    },
    {
      url: `${adminUrl}/negociaciones`,
      label: 'Negociaciones',
    },
    {
      url: '/modelo-saas',
      label: 'Modelo SaaS',
    },
    {
      url: `${adminUrl}/sucursales`,
      label: 'Sucursales',
    },
  ],
};

export default dataSectionsTitles;
