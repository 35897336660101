/* eslint-disable dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { findIndexOfObject, findObjectInArray, findLocationByName } from '../../utils/filters';
import { capitalizeWords, extractToSpecialCharacter } from '../../utils/strings';
import { states, typeDelivery } from '../../constants/general';
import { useValidationCountry, useFetchIpData } from '../../utils/validationCountry';

import Modal from '../modal';
import Typography from '../typography';
import Button from '../button';
import Checkbox from '../checkBox';
import Input from '../input';

import IconSearch from '../../assets/images/search.svg';
import IconArrow from '../../assets/images/selectIcons/arrow_drop_down-Orange.svg';
import IconClose from '../../assets/images/close.svg';
import IconArrowBack from '../../assets/images/icon-arrow-back-color-primary.svg';

import './filters.scss';

const Filters = ({
  next, setFilters, filters, cancel, deliveryCompanies, locations, isMobileDevice,
}) => {
  const user = useSelector((state) => state?.auth?.user || null);
  const itemsSearchBy = [
    {
      field: 'guideNumber',
      text: 'Número de guía',
    },
    {
      field: 'mpCode',
      text: 'Código MP',
    },
    {
      field: 'receiverCellPhone',
      text: 'Número de celular destinatario',
    },
    {
      field: 'destinyLocation',
      text: 'Ciudad destino',
    },
  ];
  const [recentOpen, setRecenOpen] = useState(true);
  const [showFirstColumn, setShowFirstColumn] = useState(false);
  const [showSecondColumn, setShowSecondColumn] = useState(false);
  const [showThirdColumn, setShowThirdColumn] = useState(false);

  const [optionSection, setOptionSection] = useState(null);

  const [guideNumber, setGuideNumber] = useState(null);
  const [mpCode, setMpCode] = useState(null);
  const [receiverCellPhone, setReceiverCellPhone] = useState(filters?.receiverCellPhone ?? null);
  const [destinyLocationName, setDestinyLocationName] = useState(null);
  const [date, setDate] = useState(null);
  const [deliveryCompaniesSelected, setDeliveryCompanySelected] = useState(
    filters?.deliveryCompanies ?? [],
  );
  const [statesSelected, setStatesSelected] = useState(
    filters?.states ?? [],
  );
  const [filtersObject, setFiltersObject] = useState(filters || {});

  const statesColumVisibility = () => (
    filters?.states?.length > 0
  );
  const deliveryCompaniesColumVisibility = () => (
    filters?.deliveryCompanies?.length > 0
  );
  const searchByColumVisibility = () => (
    filters?.receiverCellPhone?.length > 0 || filters?.destinyLocationCode?.length > 0
  );

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  /* si dentro de los filtros llega el campo 'guideNumber' o 'mpCode'
  se eliminaran ya que deben estar vacios esos campos para poder tener una búsqueda limpia
  debido a que estos dos no son acumulables en los filtros
  -en que casos podrian estar llenos estos campos?
  R// despues de haber realizado una búsqueda previamente de ellos */
  useEffect(() => {
    if (recentOpen) {
      const temporalFilters = JSON.parse(JSON.stringify(filtersObject));
      delete temporalFilters.guideNumber;
      delete temporalFilters.mpCode;
      setFiltersObject(temporalFilters);
      if (deliveryCompaniesColumVisibility()) setShowThirdColumn(true);
      if (statesColumVisibility()) setShowSecondColumn(true);
      if (searchByColumVisibility()) setShowFirstColumn(true);
      setRecenOpen(false);
    }
  }, [recentOpen]);

  // Cada vez que se cambie el estado del componente, cambiara el del padre
  useEffect(() => {
    setFilters(filtersObject);
  }, [filtersObject]);

  /* Si el usuario decide buscar por guia o codigo mp,
  estos no se agregaran a los filtros sino que se ejecutara la Búsqueda enseguida */
  useEffect(() => {
    switch (optionSection) {
      case 'guideNumber':
        setOptionSection(null);
        setGuideNumber(null);
        next();
        break;
      case 'mpCode':
        setOptionSection(null);
        setMpCode(null);
        next();
        break;
      default:
        setOptionSection(null);
    }
  }, [filters]);

  // funcion para agregar o eliminar un campo en los filtros
  const setFilter = (action) => {
    let temporalFilters = JSON.parse(JSON.stringify(filtersObject));
    switch (optionSection) {
      case 'guideNumber':
        if (action === 'accept') {
          temporalFilters = {};
          temporalFilters.guideNumber = guideNumber;
        }
        break;
      case 'mpCode':
        if (action === 'accept') {
          temporalFilters = {};
          temporalFilters.mpCode = mpCode;
        }
        break;
      case 'receiverCellPhone':
        if (action === 'accept') {
          temporalFilters.receiverCellPhone = receiverCellPhone;
        }
        setOptionSection(null);
        setReceiverCellPhone(null);
        break;
      case 'destinyLocation':
        if (action === 'accept') {
          const locationNameArray = extractToSpecialCharacter(destinyLocationName, '-');
          const temporalLocation = findLocationByName(
            locationNameArray[0], locationNameArray[1], locations,
          );
          temporalFilters.destinyLocationCode = temporalLocation.locationCode ?? null;
        }
        setOptionSection(null);
        setDestinyLocationName(null);
        break;
      case 'date':
        if (action === 'accept') {
          temporalFilters.date = date;
        }
        setOptionSection(null);
        setDate(null);
        break;
      default:
        setOptionSection(null);
    }
    setFiltersObject(temporalFilters);
  };

  /* Function para agregar o eliminar estados de envio a los filtros */
  const setStatesSelectedFunction = (stateValue) => {
    const temporalFilters = JSON.parse(JSON.stringify(filtersObject));
    const temporalStatesSelected = statesSelected.slice();
    const index = findIndexOfObject(stateValue, statesSelected);
    if (index < 0) {
      temporalStatesSelected.push(stateValue);
    } else {
      temporalStatesSelected.splice(index, 1);
    }
    setStatesSelected(temporalStatesSelected);
    temporalFilters.states = temporalStatesSelected;
    setFiltersObject(temporalFilters);
  };

  /* Function para agregar o eliminar id de transportadoras a los filtros */
  const setDeliveryCompaniesSelectedFunction = (deliveryCompanyId) => {
    const temporalFilters = JSON.parse(JSON.stringify(filtersObject));
    const temporalDeliveryCompaniesSelected = deliveryCompaniesSelected.slice();
    const index = findIndexOfObject(deliveryCompanyId, deliveryCompaniesSelected);
    if (index < 0) {
      temporalDeliveryCompaniesSelected.push(deliveryCompanyId);
    } else {
      temporalDeliveryCompaniesSelected.splice(index, 1);
    }
    setDeliveryCompanySelected(temporalDeliveryCompaniesSelected);
    temporalFilters.deliveryCompanies = temporalDeliveryCompaniesSelected;
    setFiltersObject(temporalFilters);
  };

  /* Function que devuelve el titulo del componente */
  const selectTitle = (fieldName) => {
    switch (fieldName) {
      case 'guideNumber':
        return 'Busca por número de guía';
      case 'mpCode':
        return 'Busca por código MP';
      case 'receiverCellPhone':
        return 'Busca por número de celular del destinatario';
      case 'destinyLocation':
        return 'Busca por Ciudad de destino';
      case 'date':
        return 'Busca por Fecha de envío';
      default:
        return 'Filtrar y buscar por';
    }
  };

  /* Function que devuelve el contenido del componente */
  const selectContentToFilter = (fieldName) => {
    switch (fieldName) {
      case 'guideNumber':
        return (
          <Input
            label="Escribe el número de la guía"
            type="number-text"
            value={guideNumber}
            onChange={setGuideNumber}
            iconRigth={IconSearch}
            annotationError={null}
          />
        );
      case 'mpCode':
        return (
          <Input
            label="Escribe el número del código MP"
            type="number-text"
            value={mpCode}
            onChange={setMpCode}
            iconRigth={IconSearch}
            annotationError={null}
          />
        );
      case 'receiverCellPhone':
        return (
          <Input
            label="Escribe número de celular"
            type="number-text"
            value={receiverCellPhone}
            onChange={setReceiverCellPhone}
            iconRigth={IconSearch}
            annotationError={null}
          />
        );
      case 'destinyLocation':
        return (
          <Input
            label="Escribe la ciudad de destino"
            value={destinyLocationName}
            onChange={setDestinyLocationName}
            iconRigth={IconSearch}
            dataList={locations && locations.map((location) => `${location.locationName}-${location.departmentOrStateName}`)}
            annotationError={null}
          />
        );
      case 'date':
        return (
          'Date'
        );
      default:
        return (
          <>
            <Typography
              text="Puedes seleccionar el filtro por uno o varios métodos combinados."
              type="value"
              className="text-search-by"
            />

            <div className="container-columns">
              <div className="column">
                <span
                  className={`selector ${showFirstColumn ? 'selected' : ''}`}
                  onClick={() => {
                    setShowFirstColumn(!showFirstColumn);
                    if (!statesColumVisibility()) setShowSecondColumn(false);
                    if (!deliveryCompaniesColumVisibility()) setShowThirdColumn(false);
                  }}
                >
                  Busca por
                  <img
                    src={IconArrow}
                    alt="arrow"
                  />
                </span>
                <div className={`content-column ${showFirstColumn ? 'show' : ''}`}>
                  {itemsSearchBy.map((item) => (
                    <div
                      key={item.field}
                      className="search-by filter-text"
                      onClick={() => setOptionSection(item.field)}
                    >
                      {item.text}
                    </div>
                  ))}
                </div>
              </div>

              <div className="column">
                <span
                  className={`selector ${showSecondColumn ? 'selected' : ''}`}
                  onClick={() => {
                    if (!searchByColumVisibility())setShowFirstColumn(false);
                    setShowSecondColumn(!showSecondColumn);
                    if (!deliveryCompaniesColumVisibility()) setShowThirdColumn(false);
                  }}
                >
                  Estado de envío
                  <img
                    src={IconArrow}
                    alt="arrow"
                  />
                </span>
                <div className={`content-column ${showSecondColumn ? 'show' : ''}`}>
                  {states && states.map((state) => (
                    <div className="item-state" key={state.value}>
                      <span className="filter-text">{state.text}</span>
                      <Checkbox
                        onClick={() => setStatesSelectedFunction(state.value)}
                        checked={!!findObjectInArray(state.value, statesSelected)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="column">
                <span
                  className={`selector ${showThirdColumn ? 'selected' : ''}`}
                  onClick={() => {
                    if (!searchByColumVisibility())setShowFirstColumn(false);
                    if (!statesColumVisibility()) setShowSecondColumn(false);
                    setShowThirdColumn(!showThirdColumn);
                  }}
                >
                  {capitalizeWords(typeDelivery[user.countryCode])}
                  <img
                    src={IconArrow}
                    alt="arrow"
                  />
                </span>
                <div className={`content-column ${showThirdColumn ? 'show' : ''}`}>
                  {deliveryCompanies && deliveryCompanies.map((deliveryCompany) => (
                    <div className="item-delivery-company" key={deliveryCompany['_id']}>
                      <div className="delivery">
                        <img src={deliveryCompany.image} alt="delivery logo" />
                        <span className="filter-text">{deliveryCompany.name}</span>
                      </div>
                      <Checkbox
                        onClick={() => setDeliveryCompaniesSelectedFunction(deliveryCompany._id)}
                        checked={
                          !!findObjectInArray(deliveryCompany._id, deliveryCompaniesSelected)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <Modal
      className={`filters-modal ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
    >
      <>
        <div className="header">
          <div className="left">
            {optionSection && (
              <img
                className="icon-back"
                src={IconArrowBack}
                onClick={() => {
                  setOptionSection(null);
                }}
                alt="close"
              />
            )}
          </div>
          <div className="center">{selectTitle(optionSection)}</div>
          <div className="right">
            <img
              src={IconClose}
              onClick={() => cancel()}
              alt="close"
            />
          </div>
        </div>
        <div className="body">
          {selectContentToFilter(optionSection)}
        </div>
        <div className={`footer ${optionSection ? 'content-align-center' : ''}`}>
          {statesColumVisibility()
          || deliveryCompaniesColumVisibility()
          || searchByColumVisibility()
            ? (
              <Button
                type="secondary"
                text="limpiar filtros"
                className="delete-filters"
                onClick={() => {
                  const emptyObject = {};
                  setFiltersObject(emptyObject);
                }}
              />
            ) : (
              <div />
            )}
          <Button
            type={isCountryValid ? 'primary-mx' : 'primary'}
            text="Aplicar filtros"
            onClick={() => {
              if (optionSection) {
                setFilter('accept', optionSection);
              } else {
                next();
              }
            }}
          />
        </div>
      </>
    </Modal>
  );
};

Filters.propTypes = {
  filters: PropTypes.any,
  next: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  deliveryCompanies: PropTypes.any,
  locations: PropTypes.any,
  isMobileDevice: PropTypes.bool.isRequired,
};

Filters.defaultProps = {
  filters: null,
  deliveryCompanies: null,
  locations: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  deliveryCompanies: state.globalData.deliveryCompanies,
  locations: state.globalData.locations,
});

export default connect(mapStateToProps)(Filters);
