import documentTypeList from '../commons/documents';
import { validationValue, insertOrRemoveErrorFromList } from '../../../../utils/strings';
import getCreditCardsType from '../../../../utils/creditCardsType';

const cardValidate = ({
  creditNumber, documentNumber, cvv, name, surname,
  monthYear, documentType, address, country, city,
  cellPhone, email, errorsList, setErrorsList, countryList,
}) => {
  const temporalErrorsList = errorsList.slice();

  const creditCard = getCreditCardsType(creditNumber)?.name;
  if (!creditCard) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'creditNumber',
      'El número de tarjeta proporcionada no es válido',
      'insert',
    );
    setErrorsList(temporalErrorsList);
    return false;
  }

  const listToValidate = [
    {
      value: documentNumber,
      errorName: 'documentNumber',
      errorText: 'El documento debe ser valido',
    },
    {
      value: name,
      errorName: 'name',
      errorText: 'Se requiere tener este campo lleno',
    },
    {
      value: surname,
      errorName: 'surname',
      errorText: 'Se requiere tener este campo lleno',
    },
    {
      value: address,
      errorName: 'address',
      errorText: 'Se requiere tener este campo lleno',
    },
    {
      value: city,
      errorName: 'city',
      errorText: 'Se requiere tener este campo lleno',
    },
    {
      value: cellPhone,
      errorName: 'cellPhone',
      errorText: 'Debe ser un número colombiano máximo 10 dígitos',
      type: 'cellPhone',
    },
    {
      value: email,
      errorNAme: 'email',
      errorText: 'Debe ser un correo valido',
      type: 'email',
    },
  ];

  listToValidate.forEach((item) => {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      item.errorName,
      item.errorText,
      validationValue(
        item.value,
        item.type,
      ).correct ? 'remove' : 'insert',
    );
  });

  // creditNumber Error
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'creditNumber',
    'Debe ser un númro de tarjeta valido',
    creditNumber?.toString().length < 14 ? 'insert' : 'remove',
  );

  // cvv Error
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'cvv',
    'Debe ser una clave valida, se encuentra en el reverso de la tarjeta',
    cvv?.toString().length >= 3 ? 'remove' : 'insert',
  );

  // monthYear Error
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'monthYear',
    'Debe ser un mes y un año valido',
    Number.parseInt(monthYear?.split('/')[0], 10) >= 1
    && Number.parseInt(monthYear.split('/')[0], 10) <= 12
    && Number.parseInt(monthYear?.split('/')[1], 10) >= new Date().getFullYear().toString().slice(-2)
    && Number.parseInt(monthYear.split('/')[1], 10) <= 99
      ? 'remove' : 'insert',
  );

  // documentType Error
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'documentType',
    'Debe ser una tipo de documento valido',
    documentTypeList?.find((document) => document.value === documentType) ? 'remove' : 'insert',
  );

  // country Error
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'country',
    'Debe ser una ciudad valida',
    countryList?.find((nation) => nation.label === country) ? 'remove' : 'insert',
  );

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length === 0) {
    return {
      permission: true,
      creditCard,
      countrySelected: countryList.find((nation) => nation.label === country).value,
    };
  }
  return false;
};

export const pseValidate = ({
  email, documentNumber, pseName, pseSurname, city,
  documentType, bankSelected, bankList, personType,
  balanceToRecharge, personTypeList, errorsList,
  setErrorsList, setErrorsListBalance,
}) => {
  const temporalErrorsList = errorsList.slice();

  const listToValidate = [
    {
      value: documentNumber,
      errorName: 'documentNumber',
      errorText: 'El documento debe ser valido',
    },
    {
      value: pseName,
      errorName: 'pseName',
      errorText: 'Se requiere tener este campo lleno',
    },
    {
      value: pseSurname,
      errorName: 'pseSurname',
      errorText: 'Se requiere tener este campo lleno',
    },
    {
      value: city,
      errorName: 'city',
      errorText: 'Se requiere tener este campo lleno',
    },
    {
      value: email,
      errorNAme: 'email',
      errorText: 'Debe ser un correo valido',
      type: 'email',
    },
  ];

  listToValidate.forEach((item) => {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      item.errorName,
      item.errorText,
      validationValue(
        item.value,
        item.type,
      ).correct ? 'remove' : 'insert',
    );
  });

  // documentType Error
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'documentType',
    'Debe ser una tipo de documento valido',
    documentTypeList?.find((document) => document.value === documentType) ? 'remove' : 'insert',
  );

  // bank Error
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'bankList',
    'Debe seleccionar un banco',
    bankList?.find((bank) => bank.value === bankSelected) ? 'remove' : 'insert',
  );

  // person type Error
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'personType',
    'Debe seleccionar un tipo de persona',
    personTypeList?.find((person) => person.value === personType) ? 'remove' : 'insert',
  );

  // balance grater than $100
  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'balanceToRecharge',
    'Debe ser $5.000 o mayor',
    balanceToRecharge >= 100 ? 'remove' : 'insert',
  );

  setErrorsList(temporalErrorsList);
  setErrorsListBalance(temporalErrorsList);
  if (temporalErrorsList.length === 0) {
    return true;
  }
  return false;
};

export default cardValidate;
