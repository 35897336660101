/* eslint-disable import/prefer-default-export,no-mixed-operators */
import { validationValue, insertOrRemoveErrorFromList } from '../../../utils/strings';
import { findProductByName } from '../../../utils/filters';

export const stepOneValidation = ({
  changes, optionsProductTypeArray,
  errorsList, setErrorsList, existenceUserAgency,
  countryCode,
}) => {
  let productName;
  let productTypeSelected = '';
  const temporalErrorsList = errorsList.slice();
  const tempProductDescription = changes.productType.split('-');
  tempProductDescription.map((productType) => {
    if (optionsProductTypeArray.filter((x) => x.value === productType).length > 0) {
      productTypeSelected += productType;
    }
    return null;
  });

  const listToValidate = [
    {
      value: countryCode === '170' ? productTypeSelected : changes.productType,
      errorName: 'productType',
      errorText: countryCode === '170'
        ? 'Selecciona por lo menos un tipo de producto'
        : 'Debes ingresar un tipo de producto',
    },
    {
      value: changes.senderCellPhone,
      errorName: 'senderCellPhone',
      errorText: 'Debes ingresar un número de celular',
      ...countryCode === '170' && { type: 'cellPhone' },
    },
    {
      value: changes.senderAddress,
      errorName: 'senderAddress',
      errorText: 'Debes ingresar una dirección',
    },
    {
      value: changes.nameInTheGuide,
      errorName: 'nameInTheGuide',
      errorText: 'Debes ingresar un nombre para la guía',
    },
    existenceUserAgency ? {
      value: changes.senderEmail,
      errorName: 'senderEmail',
      errorText: 'Debe existir un email válido del remitente, en el siguiente formato ejemplo@ejemplo.com',
      type: 'email',
    } : null,
    existenceUserAgency ? {
      value: changes.senderIdentificationType,
      errorName: 'senderIdentificationType',
      errorText: 'Debe existir un tipo de identificación del remitente',
    } : null,
  ].filter((item) => item !== null);

  if (changes.recommendations) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'recommendations',
      'La recomendación debe tener menos de 100 caracteres',
      changes.recommendations.length < 100 ? 'remove' : 'insert',
    );
  }

  if (existenceUserAgency) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'senderIdentificationNumber',
      'Debe existir un número de documento del remitente',
      !changes.senderIdentificationNumber || changes.senderIdentificationNumber.length < 4 ? 'insert' : 'remove',
    );
  }

  listToValidate.map((item) => {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      item?.errorName,
      item?.type && (validationValue(
        item?.value,
        item?.type,
      )?.error) || item?.errorText,
      validationValue(
        item?.value,
        item?.type,
      )?.correct ? 'remove' : 'insert',
    );
    return null;
  });

  if (countryCode === '484' && (changes.productType || changes.listTypeProducts)) {
    const productSplitted = changes.productType.split('-');
    if (productSplitted.length > 1 && changes.listTypeProducts) {
      productName = findProductByName(
        productSplitted[0],
        productSplitted[1],
        changes.listTypeProducts,
      );
    }

    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'productType',
      'No se encontro el producto',
      productName ? 'remove' : 'insert',
    );
  }

  setErrorsList(temporalErrorsList);
  return temporalErrorsList.length === 0;
};
