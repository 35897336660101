/* eslint-disable default-param-last */
import {
  ADD_COUNTRY_CODE,
} from '../actions/type.actions';

const initialState = {
  alfa2CountryCode: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_COUNTRY_CODE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
