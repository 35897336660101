import { removeAccents } from './strings';

/**
 * funcion para filtrar datos un array
 * @param {String} value valor por el que se filtrará
 * @param {Object[]} array array a filtrar
 * @returns array filtrado con respecto al valor dado
 */
export const filterByValue = (value, array) => (
  array.filter((item) => (
    removeAccents(item).toUpperCase().includes(removeAccents(value).toUpperCase())
  ))
);

export const filterByName = (value, array) => (
  array.filter((item) => (
    removeAccents(item?.name).toUpperCase().includes(removeAccents(value).toUpperCase())
  ))
);

/**
 * funcion para buscar la ciudad con base en su nombre y departamento
 * @param {String} locationName nombre de la ciudad a buscar
 * @param {String} departamentOrStateName nombre del departamento a buscar
 * @param {Object[]} locations array a filtrar
 * @returns objeto, lugar coicidente con la ciudad y departamento
 */
export const findLocationByName = (locationName, departamentOrStateName, locations) => {
  const temporalLocation = locations.filter((locate) => (
    removeAccents(locationName).toUpperCase()
    === removeAccents(locate.locationName).toUpperCase()
    && removeAccents(departamentOrStateName).toUpperCase()
    === removeAccents(locate.departmentOrStateName).toUpperCase()
  ));
  return temporalLocation.length > 0 ? temporalLocation[0] : null;
};

export const findLocationByNameMexico = (
  locationCode, locationName, municipality, departamentOrStateName, locations) => {
  if (!Array.isArray(locations)) {
    return null;
  }

  const temporalLocation = locations.filter((locate) => (
    removeAccents(locationCode)
    === removeAccents(locate.locationCode)
    && removeAccents(locationName).toUpperCase()
    === removeAccents(locate.locationName).toUpperCase()
    && removeAccents(municipality).toUpperCase()
    === removeAccents(locate.municipality).toUpperCase()
    && removeAccents(departamentOrStateName).toUpperCase()
    === removeAccents(locate.departmentOrStateName).toUpperCase()
  ));

  return temporalLocation.length > 0 ? temporalLocation[0] : null;
};

export const findProductByName = (codeProduct, descriptionName, list) => {
  const temporalLocation = list?.filter((locate) => (
    removeAccents(codeProduct).toUpperCase()
    === removeAccents(String(locate.code)).toUpperCase()
    && removeAccents(descriptionName).toUpperCase()
    === removeAccents(locate.description).toUpperCase()
  ));
  return temporalLocation.length > 0 ? temporalLocation[0] : null;
};

/**
 * function que busca un objeto en un array dado
 * @param {String} value valor a buscar en el array
 * @param {Object[]} array array dentro del que se buscara
 * @param {String} searchString nombre del campo a buscar (opcional)
 * @returns objeto, elemento coicidente con el valor dado
 */
export const findObjectInArray = (value, array, searchString) => (
  array.find((item) => (searchString ? item[searchString] === value : item === value))
);

/**
 * function que busca el index de un objeto en un array dado
 * @param {String} value valor a buscar en el array
 * @param {Object[]} array array dentro del que se buscara
 * @param {String} searchString nombre del campo a buscar (opcional)
 * @returns numero, posicion del dato buscado en el array dado
 */
export const findIndexOfObject = (value, array, searchString) => (
  array.map((item) => (searchString ? item[searchString] : item)).indexOf(value)
);

export default {
  filterByValue,
  findObjectInArray,
  findIndexOfObject,
};
