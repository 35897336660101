import STATES from '../../../../enums/states';
import { humanizationOfTimeDate, humanizationOfTimeDuration } from '../../../../utils/generals';

const deliveredShipment = (shipping) => (
  shipping['Estado actual del envío'] === STATES.delivered
);

export default deliveredShipment;

export const pickupDate = ({ tracking }) => {
  const stateFound = tracking.find(({ updateState }) => updateState === 'Recogido por la transportadora');
  if (!stateFound) return 'Recolección pendiente';
  return `${humanizationOfTimeDate(stateFound.date)} ${humanizationOfTimeDuration(stateFound.date)}`;
};

export const paymentKind = ({ paymentType }) => {
  switch (paymentType) {
    case 101:
      return 'Pago con saldo disponible';
    case 102:
      return 'Descontar el costo del envío del valor recaudado';
    case 105:
      return 'Pago con Crédito';
    default:
      return 'Este tipo de pago no se encuentra disponible';
  }
};

export const deliveryDate = ({ tracking }) => {
  const stateFound = tracking.find(({ updateState }) => updateState === 'Entregado');
  if (!stateFound) return 'Pendiente de entrega';
  return `${humanizationOfTimeDate(stateFound.date)}T${humanizationOfTimeDuration(stateFound.date)}`;
};

const validateNotificationCode = (notificationCode, Notificacion) => {
  if (!Notificacion) return false;
  const leable = Notificacion.filter((notification) => notification.code === notificationCode)[0];
  return !!(leable?.code);
};

export const notificationData = ({ activeNotifications }) => ({
  'Notificación WhatsApp: Recogido por la transportadora': validateNotificationCode(220, activeNotifications) ? 'Si' : 'No',
  'Notificación WhatsApp: En transporte': validateNotificationCode(230, activeNotifications) ? 'Si' : 'No',
  'Notificación WhatsApp: En ciudad destino': validateNotificationCode(240, activeNotifications) ? 'Si' : 'No',
  'Notificación WhatsApp: En distribución': validateNotificationCode(250, activeNotifications) ? 'Si' : 'No',
  'Notificación WhatsApp: Entregado': validateNotificationCode(260, activeNotifications) ? 'Si' : 'No',
});
