import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Typography from '../typography';
import StepProgressBar from '../stepProgressBar';

import './HeaderQuoteShipping.scss';

const HeaderQuoteShipping = ({
  handleClick,
  title,
  img,
  isElementRight,
  htmlContent,
  isProgressBar,
  steps,
  currentStep,
}) => {
  const isMobileDevice = useSelector((state) => state?.mediaQuery?.isMobileDevice);
  return (
    <>
      <div className={`header__container-quote-shipping ${!isMobileDevice ? 'header__border-quote-shipping' : 'mobile-mode'}`}>
        <div className="left">
          <img
            src={img}
            alt="back"
            onClick={handleClick}
            onKeyUp={handleClick}
          />
        </div>
        <div className="center">
          <Typography text={title} type="superTitle-italic" />
        </div>
        {isElementRight && (
          <div className="right">
            { htmlContent }
          </div>
        )}
      </div>
      {isProgressBar && (
        <div className="progress-bar-shipping">
          <StepProgressBar
            steps={steps}
            currentStep={currentStep}
          />
        </div>
      )}
    </>
  );
};

HeaderQuoteShipping.propTypes = {
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  img: PropTypes.string.isRequired,
  isElementRight: PropTypes.bool,
  htmlContent: PropTypes.node,
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  currentStep: PropTypes.number,
  isProgressBar: PropTypes.bool,
};

HeaderQuoteShipping.defaultProps = {
  isElementRight: false,
  htmlContent: '',
  title: '',
  isProgressBar: false,
  steps: null,
  currentStep: 0,
};
export default HeaderQuoteShipping;
