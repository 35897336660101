import { insertOrRemoveErrorFromList } from '../../../../../utils/strings';
import inputsValidations from '../../../../../utils/validation/schemaInputs';

export const validateData = ({
  errorsList, setErrorsList, data, inputs,
}) => {
  const temporalErrorsList = errorsList?.slice() || [];
  const supportData = { ...data };

  inputs.forEach((input) => {
    if (supportData[input.propertyName]) {
      const valueAndValidation = inputsValidations({
        inputType: input.inputType,
        radioButtonOptions: input.radioButtonOptions,
        value: supportData[input.propertyName],
        validations: input.validations || {},
      });

      insertOrRemoveErrorFromList(
        temporalErrorsList,
        input.propertyName,
        input?.annotationError || 'Debe ingresar este dato',
        valueAndValidation.correct ? 'remove' : 'insert',
      );
      supportData[input.propertyName] = valueAndValidation.value;
    }
  });

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length > 0) {
    return {
      correct: false,
      data,
    };
  }

  return {
    correct: true,
    data: supportData,
  };
};

export const requiredData = ({
  errorsList, setErrorsList, data, inputs,
}) => {
  const temporalErrorsList = errorsList?.slice() || [];

  inputs.forEach((input) => {
    if (input.required) {
      insertOrRemoveErrorFromList(
        temporalErrorsList,
        input.propertyName,
        input.annotationError || 'Este valor es requerido',
        data[input.propertyName] ? 'remove' : 'insert',
      );
    }
  });

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length > 0) return false;
  return true;
};
