import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { loginService } from '../../../services/user';
import { SignInAction, UpdateTokenAction } from '../../../store/actions/auth.actions';
import { setCurrentUser, encriptData } from '../../../utils/auth';
import { userName } from '../../../utils/strings';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import Button from '../../../components/button';
import Input from '../../../components/input';
import Typography from '../../../components/typography';
import Checkbox from '../../../components/checkBox';
import Modal from '../../../components/modal';

import GuideMan from '../../../assets/images/guide-man.png';
import IconVisibility from '../../../assets/images/icon-visibility-color-gray.svg';
import IconVisibilityOff from '../../../assets/images/icon-visibility-off-color-gray.svg';
import IconClose from '../../../assets/images/close.svg';

import './firstLogin.scss';

const FirstLogin = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [readParams, setReadParams] = useState(false);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState(null);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorLogin, setErrorLogin] = useState(null);
  const [executingAction, setExecutingAction] = useState();

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  useEffect(() => {
    if (!readParams) {
      const values = Object.fromEntries([...searchParams]);
      if (values && !readParams) {
        setEmail(values.email);
        setName(values.name);
        setSurname(values.surname);
        navigate('/primer-ingreso', { replace: true });
      }
      setReadParams(true);
    }
  }, [readParams]);

  const loginFunction = async () => {
    setExecutingAction(true);
    try {
      const response = await loginService({ email, password });
      response.user.password = password;
      await setCurrentUser(response, remember);
      const tokenEncrypt = await encriptData(response);
      await dispatch(UpdateTokenAction(tokenEncrypt));
      dispatch(SignInAction(response.user));
      navigate('/cotizacion', { replace: true });
    } catch (e) {
      setErrorLogin('Credenciales incorrectas.');
    } finally {
      setExecutingAction(false);
    }
  };

  return (
    <>
      {showModalPassword && (
        <Modal>
          <div id="container-modal__input-password">
            <img
              src={IconClose}
              alt="close"
              className="icon-close"
              onClick={() => setShowModalPassword(false)}
            />
            <Typography
              text="Ingresa tu contraseña para iniciar tu experiencia"
              type="superTitle-italic"
              className="center-text"
            />
            <Input
              onChange={setPassword}
              value={password}
              placeholder="••••••••••"
              type={showPassword ? 'text' : 'password'}
              iconRigth={showPassword ? IconVisibilityOff : IconVisibility}
              onClickIconRight={() => setShowPassword(!showPassword)}
              annotationError={errorLogin}
              classNameContainer="input-password-first-login"
            />
            <Checkbox
              checked={remember}
              onChange={setRemember}
              text="Recordarme en este equipo"
            />
            <Button
              text="Ingresar"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => loginFunction()}
              loading={executingAction}
            />
          </div>
        </Modal>
      )}

      <div id="first-login" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
        <div className="container-text__first-login">
          <Typography
            text="Completa tus datos para comenzar a hacer tus envíos "
            type="value"
            className="text__first-login"
          />
          <b className="cursor-pointer color-accent" onClick={() => window.open('https://www.mipaquete.com/tyc-campanas-comerciales', 'WindowName', 'noopener')}>*Aplican términos y condiciones</b>
          {(name || surname) && (
            <Typography
              text={userName(name, surname)}
              type="superTitle-italic"
              className="text__first-login"
            />
          )}
        </div>
        <div className="button-and-guide-man">
          <img src={GuideMan} alt="" />
          <Button
            text="Quiero realizar mi primer envío"
            type={isCountryValid ? 'primary-mx' : 'primary'}
            onClick={() => setShowModalPassword(true)}
          />
        </div>
      </div>
    </>
  );
};

export default FirstLogin;
