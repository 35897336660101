import { useSelector } from 'react-redux';
import React from 'react';

import MyAddressesSettingsViewer from './myAddressesSettingsViewer';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './myAddressesScreen.scss';

const MyAddressesScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <MyAddressesSettingsViewer />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <MyAddressesSettingsViewer />
      </ContainerSectionDesktop>
    ));
};

export default MyAddressesScreen;
