/* eslint-disable no-console */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ValidateEmail from './validateEmail';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import './validateEmail.scss';

const ValidateEmailScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const navigate = useNavigate();

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <ValidateEmail next={() => navigate('/')} />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop fullWidth>
        <div
          className="container-validate-email__desktop-mode"
        >
          <ValidateEmail next={() => navigate('/')} />
        </div>
      </ContainerSectionDesktop>
    )
  );
};

export default ValidateEmailScreen;
