const bankDataByUserCountryCode = (banks) => {
  const { bankNames, bankTransferFee } = banks ?? {};
  if (!bankNames) return {};

  const banksWithTranferFee = bankNames.map((bank) => `${bank}-(Desembolse: $${bankTransferFee[bank.toLowerCase()]})`);

  return {
    bankNames,
    bankTransferFee,
    banksWithTranferFee,
  };
};

export default bankDataByUserCountryCode;
