import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import {
  backgroundWhite, backgroundBlue, backgroundFigurePosition,
} from '../../utils/generals';

import './background.scss';

const Background = () => {
  const location = useLocation();
  const [pathname, setPathname] = useState(null);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  return (
    <div id="background">
      <div
        id="figure__background"
        className={`
          primary
          ${backgroundWhite(pathname) ? 'white' : ''}
          ${backgroundBlue(pathname) ? 'accent' : ''}
          ${backgroundFigurePosition(pathname)}
        `}
      />
    </div>
  );
};

export default Background;
