import React from 'react';
import { PropTypes } from 'prop-types';

import StringInput from './stringInput';
import PasswordInput from './passwordInput';
import RadioButtonSchema from './radioButtonSchema';

import '../negotiationSchema.scss';

const setInputInformation = (lastData, newData, setData) => {
  const supportData = { ...lastData };
  supportData[newData.propertyName] = newData.value;
  setData(supportData);
};

const InputType = ({
  input, data, setData, errorsList, key,
}) => {
  const { inputType, radioButtonOptions } = input;

  switch (inputType) {
    case 'string':
    case 'number-text':
      return (
        <StringInput
          data={data}
          input={input}
          setData={setData}
          errorsList={errorsList}
          setInputInformation={setInputInformation}
          key={key}
        />
      );
    case 'password':
      return (
        <PasswordInput
          data={data}
          errorsList={errorsList}
          input={input}
          setData={setData}
          setInputInformation={setInputInformation}
          key={key}
        />
      );
    case 'radioButton':
      return radioButtonOptions ? (
        <RadioButtonSchema
          data={data}
          errorsList={errorsList}
          input={input}
          setData={setData}
          setInputInformation={setInputInformation}
          key={key}
        />
      ) : (
        null
      );
    default:
      return (
        <div key={key} />
      );
  }
};

const PositionHandler = ({
  inputs, position, creationData, setCreationData, errorsList,
}) => (
  <div className="row-input">
    <div className="individual-input">
      <InputType
        input={inputs[position]}
        data={creationData}
        setData={setCreationData}
        errorsList={errorsList}
        key={position.toString()}
      />
    </div>
    <div className="individual-input">
      <InputType
        input={inputs[position + 1] || ''}
        data={creationData}
        setData={setCreationData}
        errorsList={errorsList}
        key={(position + 1).toString()}
      />
    </div>
  </div>
);

PositionHandler.propTypes = {
  position: PropTypes.number.isRequired,
  creationData: PropTypes.shape().isRequired,
  setCreationData: PropTypes.func.isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  inputs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

InputType.propTypes = {
  key: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
  input: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  errorsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default (PositionHandler);
