import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { apiKeyPriceLimit, currentCurrency, formatNumberAsCurrency } from 'constants/general';

import Button from '../../../../components/button';
import Typography from '../../../../components/typography';

import emptyLogo from '../../../../assets/images/integrationsEmptyState.svg';

import './emptyState.scss';

const title = (countryCode, isMobileDevice) => (
  <>
    Para generar la api-key completa tus datos de perfil
    {isMobileDevice ? '' : <br />}
    y tus datos bancarios o
    recarga al menos $
    {formatNumberAsCurrency(apiKeyPriceLimit[countryCode])}
    {' '}
    {currentCurrency[countryCode]}
  </>
);

const EmptyStateUsers = ({ isMobileDevice }) => {
  const navigate = useNavigate();
  const countryCode = useSelector((state) => state.country);
  return (
    <div className="empty-state-container">
      <img alt="empty" src={emptyLogo} className="logo" />
      <Typography text="Aún no puedes generar claves" className="title" />
      <Typography
        text={title(countryCode, isMobileDevice)}
        className="subtitle"
      />
      <Button
        type="tertiary"
        text="Recargar saldo"
        className="button"
        onClick={() => navigate('/recargar-saldo')}
      />
    </div>
  );
};

EmptyStateUsers.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
};

EmptyStateUsers.defaultProps = {};

export default EmptyStateUsers;
