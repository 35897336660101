/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';

import './observations.scss';

const openLink = (link) => {
  window.open(link);
};

const TccObservation = () => (
  <div id="Company">
    <p>Los tiempos de recogida y entrega son aproximados bajo condiciones normales de transporte.</p>
    <ul>
      <li>Los servicios solicitados después de las 10:30 a.m. son recogidos al día siguiente hábil. Recuerda que también puedes dejar los envíos en la oficina de la transportadora. TCC no presta servicio de recogidas ni entregas los domingos y festivos.</li>
      <li>Incluye la opción de recaudo contra entrega en efectivo.</li>
      <li>Los paquetes deben estar bien empacados en cajas, bolsas selladas con un <span onClick={() => openLink('https://www.youtube.com/watch?v=XDvBleamv3I&ab_channel=mipaquete.colombia')}>bolsillo porta guía</span> o en bolsas de mensajería.</li>
      <li>TCC realiza hasta tres intentos de entrega, siempre y cuando el envío no tenga novedad reportada. En caso de no recibir una solución a la incidencia dentro de los 10 primeros días hábiles, se realiza la devolución del envío, solo se cobra el flete de ida en paquetes con peso físico o volumen menor o igual a 5 kilos, para paquetes mayores a 5 kilos se cobra flete de ida y regreso.</li>
      <li>El producto no puede ser abierto frente al destinatario justo en el momento de entrega, con el propósito de garantizar la seguridad de la mercancía.</li>
      <li>Para envíos con destinos especiales (poblaciones lejanas), el tiempo de pago puede ser superior a los cortes establecidos.</li>
      <li>No es posible recomendar fechas, horas exactas de entrega, ni llamadas previas al destinatario. El cubrimiento en ciudades es dentro del perímetro urbano. No se cubren corregimientos.</li>
      <li>La plataforma genera tu guía, En caso de que no se entregue la guía con la mercancía, mipaquete.com no se hace responsable del servicio ni de recaudos no efectuados.</li>
      <li>En caso de daño, perdida parcial o total mipaquete.com en representación del titular de la c0uenta realizará la solicitud de indemnización por el valor declarado diligenciado en la plataforma y quedará sujeto a
        la respuesta final por parte de la transportadora. Es indispensable que tu envío tenga condiciones de empaque óptimas para el transporte, contar con el comprobante  de despacho firmado, documentos que soporten el valor declarado, que la unidad no se encuentre en la categoría de artículos prohibidos  y en caso de que esta llegue al destinatario en mal estado se  debe dejar como obligación la observación sobre la guía que firma a la transportadora como material probatorio y reportar el caso por medio del remitente dentro de las 24 horas siguientes, solo se responde en caso tal de  que la unidad se encuentre en alguno de los estados anteriormente mencionados  y la omisión de alguno de estos puede afectar la aprobación del caso.
      </li>
    </ul>
  </div>
);

const ServientregaObservation = () => (
  <div id="Company">
    <p>Los tiempos de recogida y entrega son aproximados bajo condiciones normales de transporte.</p>
    <ul>
      <li>Los servicios solicitados después de las 10:30 a.m. son recogidos al día siguiente hábil. Recuerda que también puedes dejar los envíos en la oficina de la transportadora con todos los documentos. Servientrega no presta servicio de recogidas ni entregas los domingos y festivos.</li>
      <li>Incluye la opción de recaudo contra entrega en efectivo.</li>
      <li>Debido a políticas de la transportadora, solo es posible realizar el pago del valor del envío con saldo recargado en nuestra plataforma.</li>
      <li>Los paquetes deben estar bien empacados en cajas, bolsas selladas con un <span onClick={() => openLink('https://www.youtube.com/watch?v=XDvBleamv3I&ab_channel=mipaquete.colombia')}>bolsillo porta guía</span> o en bolsas de mensajería.</li>
      <li>Servientrega realiza hasta tres intentos de entrega, siempre y cuando el envío no tenga novedad reportada. En caso de no recibir una solución a la incidencia dentro de los 2 primeros días hábiles o que el destinatario se rehúse a recibir, se realiza la devolución del envío sin costo adicional al remitente.</li>
      <li>El producto no puede ser abierto frente al destinatario justo en el momento de entrega, con el propósito de garantizar la seguridad de la mercancía.</li>
      <li>Para envíos con destinos especiales (poblaciones lejanas), el tiempo de pago puede ser superior a los cortes establecidos.</li>
      <li>No es posible recomendar fechas, horas exactas de entrega, ni llamadas previas al destinatario. El cubrimiento en ciudades es dentro del perímetro urbano. No se cubren corregimientos.</li>
      <li>En caso de que no se entregue la guía con la mercancía, mipaquete.com no se hace responsable del servicio ni de recaudos no efectuados.</li>
      <li>En caso de daño, perdida parcial o total mipaquete.com en representación del titular de la cuenta realizará la solicitud de indemnización por el valor declarado diligenciado en la plataforma y quedará sujeto a
        la respuesta final por parte de la transportadora. Es indispensable que tu envío tenga condiciones de empaque óptimas para el transporte, contar con el comprobante  de despacho firmado, documentos que soporten el valor declarado, que la unidad no se encuentre en la categoría de artículos prohibidos  y en caso de que esta llegue al destinatario en mal estado se  debe dejar como obligación la observación sobre la guía que firma a la transportadora como material probatorio y reportar el caso por medio del remitente dentro de las 24 horas siguientes, solo se responde en caso tal de  que la unidad se encuentre en alguno de los estados anteriormente mencionados  y la omisión de alguno de estos puede afectar la aprobación del caso.
      </li>
    </ul>
  </div>
);

const CoordinadoraObservation = () => (
  <div id="Company">
    <p>Los tiempos de recogida y entrega son aproximados bajo condiciones normales de transporte.</p>
    <ul>
      <li>Los servicios solicitados después de las 10:30 a.m. son recogidos al día siguiente hábil.  Recuerda que también puedes dejar los envíos en la oficina de la transportadora. Coordinadora no presta servicio de recogidas ni entregas los domingos y festivos.</li>
      <li>Incluye la opción de recaudo contra entrega en efectivo, Tarjeta Débito o Tarjeta Crédito (VISA, MasterCard y American Express) Nota: Para pagos con tarjeta es por medio de aplicación leyendo código QR por parte del destinatario.</li>
      <li>Los paquetes deben estar bien empacados en cajas, bolsas selladas con un <span onClick={() => openLink('https://www.youtube.com/watch?v=XDvBleamv3I&ab_channel=mipaquete.colombia')}>bolsillo porta guía</span> o en bolsas de mensajería.</li>
      <li>Coordinadora realiza dos intentos de entrega, siempre y cuando el envío no tenga novedad. En caso de no recibir el producto por parte del destinatario se dejará en la oficina más cercana para que pueda ser reclamado durante un periodo de 10 días. Intentos de entrega adicionales tienen costos adicionales.  En caso de no recibir una solución a la incidencia dentro de los 10 primeros días hábiles o el cliente no hubiera reclamado en el punto drop más cercano, se realiza la devolución del envío cobrando flete de ida y regreso sin comisión de recaudo.</li>
      <li>El producto no puede ser abierto frente al destinatario justo en el momento de entrega, con el propósito de garantizar la seguridad de la mercancía.</li>
      <li>Para envíos con destinos especiales (poblaciones lejanas), el tiempo de pago puede ser superior a los cortes establecidos.</li>
      <li>No es posible recomendar fechas, horas exactas de entrega, ni llamadas previas al destinatario. El cubrimiento en ciudades es dentro del perímetro urbano. No se cubren corregimientos.</li>
      <li>La plataforma genera tu guía, En caso de que no se entregue la guía con la mercancía, mipaquete.com no se hace responsable del servicio ni de recaudos no efectuados.</li>
      <li>En caso de daño, perdida parcial o total mipaquete.com en representación del titular de la cuenta realizará la solicitud de indemnización por el valor declarado diligenciado en la plataforma y quedará sujeto a
        la respuesta final por parte de la transportadora. Es indispensable que tu envío tenga condiciones de empaque óptimas para el transporte, contar con el comprobante  de despacho firmado, documentos que soporten el valor declarado, que la unidad no se encuentre en la categoría de artículos prohibidos  y en caso de que esta llegue al destinatario en mal estado se  debe dejar como obligación la observación sobre la guía que firma a la transportadora como material probatorio y reportar el caso por medio del remitente dentro de las 24 horas siguientes, solo se responde en caso tal de  que la unidad se encuentre en alguno de los estados anteriormente mencionados  y la omisión de alguno de estos puede afectar la aprobación del caso.
      </li>
    </ul>
  </div>
);

const TempoExpress = () => (
  <div id="Company">
    <p>Los tiempos de recogida y entrega son aproximados bajo condiciones normales de transporte.</p>
    <ul>
      <li>Los servicios solicitados después de las 10:30 a.m. son recogidos al día siguiente hábil. Tempo Express no presta servicio de recogidas ni entregas los domingos y festivos.</li>
      <li>Incluye la opción de recaudo contra entrega en efectivo.</li>
      <li>Los paquetes deben estar bien empacados en cajas, bolsas  selladas con un <span onClick={() => openLink('https://www.youtube.com/watch?v=XDvBleamv3I&ab_channel=mipaquete.colombia')}>bolsillo porta guía</span> o en bolsas de mensajería.</li>
      <li>Tempo Express realiza hasta tres intentos de entrega, siempre y cuando el envío no tenga novedad. En caso de no recibir una solución a la incidencia dentro de los 10 primeros días hábiles, se realiza la devolución del envío cobrando  flete de ida y regreso sin comisión de recaudo</li>
      <li>El producto no puede ser abierto frente al destinatario justo en el momento de entrega, con el propósito de garantizar la seguridad de la mercancía.</li>
      <li>Para envíos con destinos especiales (poblaciones lejanas), el tiempo de pago puede ser superior a los cortes establecidos.</li>
      <li>No es posible recomendar fechas, horas exactas de entrega, ni llamadas previas al destinatario. El cubrimiento en ciudades es dentro del perímetro urbano. No se cubren corregimientos.</li>
      <li>La plataforma genera tu guía, En caso de que no se entregue la guía con la mercancía, mipaquete.com no se hace responsable del servicio ni de recaudos no efectuados.</li>
      <li>En caso de daño, perdida parcial o total mipaquete.com en representación del titular de la cuenta realizará la solicitud de indemnización por el valor declarado diligenciado en la plataforma y quedará sujeto a
        la respuesta final por parte de la transportadora. Es indispensable que tu envío tenga condiciones de empaque óptimas para el transporte, contar con el comprobante  de despacho firmado, documentos que soporten el valor declarado, que la unidad no se encuentre en la categoría de artículos prohibidos  y en caso de que esta llegue al destinatario en mal estado se  debe dejar como obligación la observación sobre la guía que firma a la transportadora como material probatorio y reportar el caso por medio del remitente dentro de las 24 horas siguientes, solo se responde en caso tal de  que la unidad se encuentre en alguno de los estados anteriormente mencionados  y la omisión de alguno de estos puede afectar la aprobación del caso.
      </li>
    </ul>
  </div>
);

const Envia = () => (
  <div id="Company">
    <p>Los tiempos de recogida y entrega son aproximados bajo condiciones normales de transporte.</p>
    <ul>
      <li>Los servicios solicitados después de las 10:30 a.m. son recogidos al día siguiente hábil. Recuerda que también puedes dejar los envíos en la oficina de la transportadora.</li>
      <li>Incluye la opción de recaudo contra entrega en efectivo y con tarjeta débito y crédito.</li>
      <li>Envía no presta servicio de recogidas ni entregas los domingos y festivos.</li>
      <li>Los paquetes deben estar bien empacados en cajas, bolsas selladas con un <span onClick={() => openLink('https://www.youtube.com/watch?v=XDvBleamv3I&ab_channel=mipaquete.colombia')}>bolsillo porta guía</span> o en bolsas de mensajería.</li>
      <li>Envía realiza hasta tres intentos de entrega, desde que el envío no tenga novedad. En caso de no recibir una solución a la incidencia dentro de los tres primeros días hábiles, se realiza la devolución del envío con un costo total del 1,6 veces del valor del flete sin comisión de recaudo.</li>
      <li>El producto no puede ser abierto frente al destinatario justo en el momento de entrega, con el propósito de garantizar la seguridad de la mercancía.</li>
      <li>Para envíos con destinos especiales (poblaciones lejanas), el tiempo de pago puede ser superior a los cortes establecidos.</li>
      <li>No es posible recomendar fechas, horas exactas de entrega, ni llamadas previas al destinatario. El cubrimiento en ciudades es dentro del perímetro urbano. No se cubren corregimientos.</li>
      <li>En caso de que no se entregue la guía con la mercancía, mipaquete.com no se hace responsable del servicio ni de recaudos no efectuados.</li>
      <li>El producto no puede ser reclamado por el destinatario en las oficinas de la transportadora para modalidad de recaudo contra entrega.</li>
      <li>En caso de daño, perdida parcial o total mipaquete.com en representación del titular de la cuenta realizará la solicitud de indemnización por el valor declarado diligenciado en la plataforma y quedará sujeto a
        la respuesta final por parte de la transportadora. Es indispensable que tu envío tenga condiciones de empaque óptimas para el transporte, contar con el comprobante de despacho firmado, documentos que soporten el valor declarado, que la unidad no se encuentre en la categoría de artículos prohibidos y en caso de que esta llegue al destinatario en mal estado se debe dejar como obligación la observación sobre la guía que firma a la transportadora como material probatorio y reportar el caso por medio del remitente dentro de las 24 horas siguientes, solo se responde en caso tal de que la unidad se encuentre en alguno de los estados anteriormente mencionados y la omisión de alguno de estos puede afectar la aprobación del caso.
      </li>
    </ul>
  </div>
);

const Entregalo = () => (
  <div id="Company">
    <p>Los tiempos de recogida y entrega son aproximados bajo condiciones normales de transporte para entrega el mismo día.</p>
    <ul>
      <li>Los servicios solicitados después de las 11 a.m. son recogidos al día siguiente hábil. Entregalo no presta servicio de recogidas ni entregas los domingos y festivos. Sábados tiene condiciones especiales de servicio.</li>
      <li>Incluye la opción de recaudo contra entrega en efectivo.</li>
      <li>Los paquetes deben estar bien empacados en cajas, bolsas selladas.</li>
      <li>
        Entregalo realiza hasta 2 intentos de entrega, siempre y cuando el envío no tenga novedad reportada.
        En caso de no recibir una solución a la incidencia dentro del 1 día hábil o el destinatario confirme que no va a recibir, se realiza la devolución del envío, solo se cobra el flete de ida sin comisión de recaudo.
      </li>
      <li>El producto no puede ser abierto frente al destinatario justo en el momento de entrega, con el propósito de garantizar la seguridad de la mercancía.</li>
      <li>No es posible recomendar fechas, horas exactas de entrega, ni llamadas previas al destinatario. El cubrimiento en ciudades es dentro del perímetro urbano. No se cubren corregimientos.</li>
      <li>La plataforma genera tu guía, En caso de que no se entregue la guía con la mercancía, mipaquete.com no se hace responsable del servicio ni de recaudos no efectuados.</li>
      <li>
        En caso de daño, perdida parcial o total  mipaquete.com  en representación del titular de la cuenta realizará la solicitud de indemnización por el valor declarado diligenciado en la plataforma y quedará sujeto a la respuesta final por parte de la transportadora. Es indispensable que tu envío tenga condiciones de empaque óptimas para el transporte, contar con el comprobante de despacho firmado, documentos que soporten el valor declarado, que la unidad no se encuentre en la categoría de artículos prohibidos y en caso de que esta llegue al destinatario o remitente en mal estado se debe dejar como obligación la observación sobre la guía que firma a la transportadora como material probatorio y reportar el caso por medio del remitente dentro de las 24 horas siguientes, solo se responde en caso tal de que la unidad se encuentre en alguno de los estados anteriormente mencionados y la omisión de alguno de estos puede afectar la aprobación del caso.
      </li>
    </ul>
  </div>
);

const Deprisa = () => (
  <div id="Company">
    <p>Los tiempos de recogida y entrega son aproximados bajo condiciones normales de transporte.</p>
    <ul>
      <li>Los servicios solicitados después de las 10:30 a.m. son recogidos al día siguiente hábil. Recuerda que también puedes dejar los envíos en la oficina de la transportadora.</li>
      <li>Debido a políticas de la transportadora, solo es posible realizar el pago del valor del envío con saldo recargado en nuestra plataforma.</li>
      <li>Deprisa no presta servicio de recogidas ni entregas los domingos y festivos.</li>
      <li>Los paquetes deben estar bien empacados en cajas, bolsas selladas con un  o en bolsas de mensajería con su respectivo rotulo otorgado por la plataforma.</li>
      <li>Deprisa realiza hasta tres intentos de entrega, desde que el envío no tenga novedad. En caso de no recibir una solución a la incidencia dentro de los tres primeros días hábiles, se realiza la devolución del envío con un costo total del 2 veces del valor del flete.</li>
      <li>El producto no puede ser abierto frente al destinatario justo en el momento de entrega, con el propósito de garantizar la seguridad de la mercancía.</li>
      <li>Para envíos con destinos especiales (poblaciones lejanas), el tiempo de actualización de estos puede tarar un poco más.</li>
      <li>No es posible recomendar fechas, horas exactas de entrega, ni llamadas previas al destinatario. El cubrimiento en ciudades es dentro del perímetro urbano. No se cubren corregimientos.</li>
      <li>En caso de que no se entregue la guía con la mercancía, mipaquete.com no se hace responsable del envío.</li>
      <li>En caso de daño, perdida parcial o total mipaquete.com en representación del titular de la cuenta realizará la solicitud de indemnización por el valor declarado diligenciado en la plataforma y quedará sujeto a la respuesta final por parte de la transportadora. Es indispensable que tu envío tenga condiciones de empaque óptimas para el transporte, contar con el comprobante de despacho firmado, documentos que soporten el valor declarado, que la unidad no se encuentre en la categoría de artículos prohibidos y en caso de que esta llegue al destinatario en mal estado se debe dejar como obligación la observación sobre la guía que firma a la transportadora como material probatorio y reportar el caso por medio del remitente dentro de las 24 horas siguientes, solo se responde en caso tal de que la unidad se encuentre en alguno de los estados anteriormente mencionados y la omisión de alguno de estos puede afectar la aprobación del caso</li>
      <li>Interrapidísimo para envíos con recaudo contra entrega, cobra el valor declarado en relación al monto que pones en el valor a recaudar. Dicho valor a recaudar en caso de daño, perdida parcial o total que el operador acepte como responsabilidad de su operación, retornara dicho valor a recaudar sustentado en una factura legal.</li>
    </ul>
  </div>
);

const IRP = () => (
  <div id="Company">
    <p>Los tiempos de recogida y entrega son aproximados bajo condiciones normales de transporte.</p>
    <ul>
      <li>Los servicios solicitados después de las 10:30 a.m. son recogidos al día siguiente hábil. Recuerda que también puedes dejar los envíos en la oficina de la transportadora.</li>
      <li>Inter rapidísimo no presta servicio de recogidas ni entregas los domingos y festivos.</li>
      <li>Los paquetes deben estar bien empacados en cajas, bolsas selladas con un  o en bolsas de mensajería con su respectivo rotulo otorgado por la plataforma.</li>
      <li>Interrapidísimo realiza dos intentos de entrega, desde que el envío no tenga novedad. En caso de no recibir una solución a la incidencia dentro de los cuatro primeros días hábiles, se realiza la devolución del envío cobrando flete de ida y regreso sin comisión de recaudo.</li>
      <li>El producto no puede ser abierto frente al destinatario justo en el momento de entrega, con el propósito de garantizar la seguridad de la mercancía.</li>
      <li>Para envíos con destinos especiales (poblaciones lejanas), el tiempo de actualización de estos puede tarar un poco más.</li>
      <li>No es posible recomendar fechas, horas exactas de entrega, ni llamadas previas al destinatario. El cubrimiento en ciudades es dentro del perímetro urbano. No se cubren corregimientos.</li>
      <li>En caso de que no se entregue la guía con la mercancía, mipaquete.com no se hace responsable del envío.</li>
      <li>En caso de daño, perdida parcial o total mipaquete.com en representación del titular de la cuenta realizará la solicitud de indemnización por el valor declarado diligenciado en la plataforma y quedará sujeto a la respuesta final por parte de la transportadora. Es indispensable que tu envío tenga condiciones de empaque óptimas para el transporte, contar con el comprobante de despacho firmado, documentos que soporten el valor declarado, que la unidad no se encuentre en la categoría de artículos prohibidos y en caso de que esta llegue al destinatario en mal estado se debe dejar como obligación la observación sobre la guía que firma a la transportadora como material probatorio y reportar el caso por medio del remitente dentro de las 24 horas siguientes, solo se responde en caso tal de que la unidad se encuentre en alguno de los estados anteriormente mencionados y la omisión de alguno de estos puede afectar la aprobación del caso</li>
      <li>Para acceder o cambiar los datos del remitente sobre la guía, debes crear o editar una sucursal. Te recomendamos verificar bien los datos que arroja la guía que te brindo el sistema para tu despacho. </li>
      <li>Para envíos con recaudo contra entrega, el valor declarado que utiliza Inter Rapidísimo será el correspondiente al del valor de recaudo. En caso de pérdida o avería la transportadora podrá solicitar factura legal que sustente dicho valor.</li>
    </ul>
  </div>
);

const observations = {
  '5ca22d9587981510092322f6': <TccObservation />,
  '5fceb46c8229797cb139a7aa': <ServientregaObservation />,
  '5cb0f5fd244fe2796e65f9fc': <CoordinadoraObservation />,
  '5ea34d5e254c3206ee0fc628': <TempoExpress />,
  '6080a75ef08a770ddd9724fd': <Envia />,
  '61f037022d7e65848ac2c1a9': <Entregalo />,
  '632b73069fd0d82d8b77c6b5': <Deprisa />,
  '64baafead968aa4f73ce67c5': <IRP />,
};

export default observations;
