/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { toCurrencyCop } from '../../../utils/strings';
import { humanizationOfTimeDuration } from '../../../utils/generals';
import { finalPrice, collectionServiceValue } from '../../../utils/prices';
import { useValidationCountry } from '../../../utils/validationCountry';
import { typeDelivery } from '../../../constants/general';

import Button from '../../button';
import Input from '../../input';
import Typography from '../../typography';
import CheckBox from '../../checkBox';

import IconArrow from '../../../assets/images/selectIcons/arrow_drop_down-Orange.svg';

import '../lastDetails.scss';

const SendingDetails = ({
  cancel,
  setShowProhibitedItems,
  heightType,
  shippingOriginName,
  shippingDestinyName,
  articleHeight,
  articleWidth,
  articleLength,
  articleWeight,
  unitOfMeasurement,
  quantityOfArticles,
  declaredValue,
  saleValue,
  articleDescription,
  articleDescriptionError,
  notProhibitedArticlesError,
  setArticleDescription,
  recommendationsForDeliveryCompany,
  recommendationsForDeliveryCompanyError,
  setRecommendationsForDeliveryCompany,
  notProhibitedArticles,
  setNotProhibitedArticles,
  deliverySelected,
  collectionService,
  addShippingToCollectionValue,
  isMobileDevice,
  detailsFromQuoteMultipleShippings,
}) => {
  const user = useSelector((state) => state?.auth?.user || null);
  const countryCode = useSelector((state) => state?.country || null);

  const [detailsArticleVisible, setDetailsArticleVisible] = useState(false);
  const next = () => {

  };

  const isCountryValid = useValidationCountry(countryCode);

  useEffect(() => (
    detailsFromQuoteMultipleShippings && setDetailsArticleVisible(true)
  ), [detailsFromQuoteMultipleShippings]);

  const dataShippingDetails = () => (
    <>
      <div className="sending-details-article">
        <div className="container-info__sending-details-article">
          <Typography type="field" text="Origen:" />
          <Typography type="value" text={shippingOriginName} />
        </div>
        <div className="container-info__sending-details-article">
          <Typography type="field" text="Destino:" />
          <Typography type="value" text={shippingDestinyName} />
        </div>
      </div>
      <button
        type="button"
        className="active-drop-down-container"
        onClick={() => setDetailsArticleVisible(!detailsArticleVisible)}
      >
        <Typography type="title" text="Dimensiones de mi paquete" />
        <img
          src={IconArrow}
          alt="icon drop down"
          className={detailsArticleVisible ? 'top' : 'bottom'}
        />
      </button>
      <div
        className={`sending-details__drop-down-container ${!detailsArticleVisible ? 'hidden' : ''}`}
      >
        <div className="sending-details-article">
          <div className="container-info__sending-details-article">
            <Typography type="field" text="Alto:" />
            <Typography type="value" text={`${articleHeight} cm`} />
          </div>
          <div className="container-info__sending-details-article">
            <Typography type="field" text="Ancho:" />
            <Typography type="value" text={`${articleWidth} cm`} />
          </div>
          <div className="container-info__sending-details-article">
            <Typography type="field" text="Largo:" />
            <Typography type="value" text={`${articleLength} cm`} />
          </div>
          <div className="container-info__sending-details-article">
            <Typography type="field" text="Peso:" />
            <Typography type="value" text={`${articleWeight} ${unitOfMeasurement}`} />
          </div>
        </div>
      </div>
      <div className="sending-details-article">
        <div className="container-info__sending-details-article">
          <Typography type="field" text="Valor declarado por unidad:" />
          <Typography type="value" text={toCurrencyCop(user, declaredValue)} />
        </div>
        <div className="container-info__sending-details-article">
          <Typography type="field" text="Cantidad:" />
          <Typography type="value" text={quantityOfArticles} />
        </div>
      </div>
    </>
  );

  const dataDeliveryCompany = () => (
    <div className="delivery-info__sending-details">
      <Typography type="title" className="title-delivery" text={typeDelivery[countryCode]} />
      {collectionService && (
        <>
          <Typography
            type="value"
            className="recolection-value-info"
            text="El precio incluye la comisión de pago contra entrega"
          />
        </>
      )}
      <div className="delivery-data__delivery-info__sending-details">
        <div className="flexbox-delivery-data">
          <img src={deliverySelected.deliveryCompanyImgUrl} alt="" />
          <div className="text-delivery-data">
            <Typography
              type="field"
              className="delivery-name__delivery-data"
              text={deliverySelected.deliveryCompanyName}
            />
            <Typography
              type="value"
              className="shipping-time__delivery-data"
              text={humanizationOfTimeDuration(deliverySelected.shippingTime)}
            />
          </div>
        </div>
        <Typography
          type="title"
          text={
            toCurrencyCop(
              user,
              finalPrice(
                deliverySelected.shippingCost,
                collectionService,
                addShippingToCollectionValue,
                deliverySelected.collectionCommissionWithRate,
                deliverySelected.collectionCommissionWithOutRate,
              ),
            )
          }
        />
      </div>
      {collectionService && (
        <div
          className="collection-value"
        >
          <span>
            Tarifa calculada para recaudo:
            <b>
              {' '}
              {toCurrencyCop(
                user,
                collectionServiceValue(
                  saleValue,
                  deliverySelected.shippingCost,
                  collectionService,
                  addShippingToCollectionValue,
                  deliverySelected.collectionCommissionWithRate,
                  deliverySelected.collectionCommissionWithOutRate,
                ),
              )}
            </b>
          </span>
        </div>
      )}
    </div>
  );

  const dataComplementaryInformation = (showCheckBox) => (
    <>
      <Input
        label="Descripción del producto"
        value={articleDescription}
        onChange={setArticleDescription}
        annotationError={articleDescriptionError}
      />
      <Input
        label={`Recomendaciones para la ${typeDelivery[countryCode]}`}
        value={recommendationsForDeliveryCompany}
        onChange={setRecommendationsForDeliveryCompany}
        annotationError={recommendationsForDeliveryCompanyError}
        annotation="Utiliza este campo para complementar los datos de tu envío"
      />
      {showCheckBox && (
        <CheckBox
          checked={notProhibitedArticles}
          onChange={setNotProhibitedArticles}
          annotationError={notProhibitedArticlesError}
          text="No envío artículos prohibidos"
          clickOnText={() => setShowProhibitedItems(true)}
        />
      )}
    </>
  );

  const dataContentBottom = () => (
    <>
      <Button
        text="Continuar"
        type={isCountryValid ? 'primary-mx' : 'primary'}
        onClick={() => next()}
      />
      <Button
        text="Cotizar nuevamente"
        type="secondary"
        onClick={() => cancel()}
      />
    </>
  );

  return (
    <div className={`body-accordion__last-details sending-details ${heightType ? heightType : ''} ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
      <div className="second-body-accordion__last-details">
        <div className="container__second-body-accordion__last-details top">
          <div className="content-top-bottom-or-side__container__second-body-accordion__last-details">
            {dataShippingDetails()}
            {detailsFromQuoteMultipleShippings && dataComplementaryInformation(false)}
          </div>
          {!detailsFromQuoteMultipleShippings && (
            <div className="content-top-bottom-or-side__container__second-body-accordion__last-details">
              {dataDeliveryCompany()}
              {dataComplementaryInformation(true)}
            </div>
          )}
        </div>
        {!detailsFromQuoteMultipleShippings && (
          <div className="container__second-body-accordion__last-details bottom">
            {dataContentBottom()}
          </div>
        )}
      </div>
    </div>
  );
};

SendingDetails.propTypes = {
  heightType: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  setShowProhibitedItems: PropTypes.func.isRequired,
  shippingOriginName: PropTypes.string.isRequired,
  shippingDestinyName: PropTypes.string.isRequired,
  articleHeight: PropTypes.number.isRequired,
  articleWidth: PropTypes.number.isRequired,
  articleLength: PropTypes.number.isRequired,
  articleWeight: PropTypes.number.isRequired,
  unitOfMeasurement: PropTypes.string.isRequired,
  quantityOfArticles: PropTypes.number.isRequired,
  declaredValue: PropTypes.number.isRequired,
  saleValue: PropTypes.number,
  articleDescription: PropTypes.string,
  articleDescriptionError: PropTypes.string,
  setArticleDescription: PropTypes.func.isRequired,
  recommendationsForDeliveryCompany: PropTypes.string,
  recommendationsForDeliveryCompanyError: PropTypes.string,
  setRecommendationsForDeliveryCompany: PropTypes.func.isRequired,
  notProhibitedArticles: PropTypes.bool.isRequired,
  notProhibitedArticlesError: PropTypes.string,
  collectionService: PropTypes.bool.isRequired,
  setNotProhibitedArticles: PropTypes.func.isRequired,
  deliverySelected: PropTypes.any.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  addShippingToCollectionValue: PropTypes.bool.isRequired,
  detailsFromQuoteMultipleShippings: PropTypes.bool.isRequired,
};

SendingDetails.defaultProps = {
  heightType: 'h-50',
  articleDescription: null,
  articleDescriptionError: null,
  recommendationsForDeliveryCompany: null,
  recommendationsForDeliveryCompanyError: null,
  saleValue: null,
  notProhibitedArticlesError: null,
};

export default SendingDetails;
