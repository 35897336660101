/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable dot-notation */
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getSendingTrackingService } from '../../services/sendings';
import { findImageIntoDeliveries } from '../../utils/generals';
import { findObjectInArray } from '../../utils/filters';
import { useValidationCountry, useFetchIpData } from '../../utils/validationCountry';

import ShippingDetails from '../../components/shippingDetails';
import Select from '../../components/select';
import Input from '../../components/input';
import Button from '../../components/button';
import Typography from '../../components/typography';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';

import ImageEmpyState from '../../components/imagesEmptyState';

import NotFoundShippingImage from '../../assets/images/sin_resultados.png';
import ArrowBack from '../../assets/images/icon-arrow-back-color-primary.svg';

import './publicShippingTrackingScreen.scss';

const OptionsList = [
  { value: 'code', label: 'Código MP' },
  { value: 'guide', label: 'Número de guía' },
];

const PublicShippingTrackingScreen = () => {
  const deliveryCompanies = useSelector((state) => state?.globalData?.deliveryCompanies || null);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const [searchParams] = useSearchParams();
  const [shipping, setShipping] = useState(null);
  const [readParams, setReadParams] = useState(false);
  const [optionSelected, setOptionSelected] = useState(OptionsList[0].value);
  const [loadingShipping, setLoadingShipping] = useState(false);
  const [notFoundShipping, setNotFoundShipping] = useState(false);
  const [mpCodeOrGuideNumber, setMpCodeOrGuideNumber] = useState(null);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const getShippingFunction = async (mpcode) => {
    setLoadingShipping(true);
    const params = OptionsList[0].value === optionSelected ? { mpCode: mpCodeOrGuideNumber || mpcode } : { guideNumber: mpCodeOrGuideNumber };
    getSendingTrackingService(params).then((result) => {
      setShipping(result);
    }).catch(() => {
      setNotFoundShipping(true);
    }).finally(() => {
      setLoadingShipping(false);
    });
  };

  useEffect(() => {
    setLoadingShipping(true);
    if (!searchParams.get('mpcode')) setLoadingShipping(false);
    if (!readParams && deliveryCompanies?.length > 0) {
      const values = Object.fromEntries([...searchParams]);
      if (values?.mpcode && !readParams) {
        setMpCodeOrGuideNumber(values.mpcode);
        getShippingFunction(values.mpcode);
      }
      setReadParams(true);
    }
  }, [readParams, deliveryCompanies]);

  const searchAgain = () => {
    setNotFoundShipping(false);
  };

  const contentInScreen = () => (
    <div
      className={`container-public-shipping-tracking ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
    >
      <div className="header_public-shipping-tracking">
        <Typography
          type="title"
          text="Seguimiento de envíos"
          className="title-public-shipping-tracking"
        />
      </div>
      <div className="body_public-shipping-tracking container-input">
        {shipping && (
          <ShippingDetails
            access="public"
            back={() => setShipping(null)}
            mpCode={shipping['mpCode']}
            guideNumber={shipping['guideNumber']}
            senderLocation={shipping['origin']}
            collectionService={shipping?.collectionService}
            receiverLocation={shipping['destiny']}
            deliveryCompanyId={shipping?.deliveryCompany}
            deliveryCompanyImage={findImageIntoDeliveries(shipping['deliveryCompany'], deliveryCompanies || [])}
            deliveryCompanyName={findObjectInArray(shipping['deliveryCompany'], deliveryCompanies || [], '_id')?.name}
            tracking={shipping['tracking']}
          />
        )}
        {notFoundShipping ? (
          <div className="not-found-shipping">
            <div className="header public">
              <button
                onClick={() => searchAgain()}
                className="left"
              >
                <img
                  src={ArrowBack}
                  alt=""
                />
              </button>
              <div className="center">
                <span>{`No hay datos con el código ${mpCodeOrGuideNumber}`}</span>
              </div>
              <div className="rigth" />
            </div>
            <div className="container-input-and-button container-image">
              <ImageEmpyState
                uri={NotFoundShippingImage}
                title="¡Ups! no encontramos resultados"
                description="No encontramos resultados de tu búsqueda"
              />
            </div>
          </div>
        ) : !shipping && !notFoundShipping && (
          <div className="container-input-and-button">
            <div className="container-select-input">
              <Select
                options={OptionsList}
                value={optionSelected}
                onChange={setOptionSelected}
              />
              <div className="container-input">
                <Input
                  label="Escribe el número de guía o codigo MP"
                  placeholder="012356874"
                  type="number-text"
                  value={mpCodeOrGuideNumber}
                  onChange={(e) => setMpCodeOrGuideNumber(e)}
                />
              </div>
            </div>
            <div className="buttons">
              <div className="no-buttons" />
              <Button
                text="Buscar"
                disabled={!mpCodeOrGuideNumber}
                type={isCountryValid ? 'primary-mx' : 'primary'}
                loading={loadingShipping}
                onClick={() => getShippingFunction()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        {contentInScreen()}
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        {contentInScreen()}
      </ContainerSectionDesktop>
    )
  );
};

export default PublicShippingTrackingScreen;
