/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Document, Page, Text,
} from '@react-pdf/renderer';

const MyDoc = (customDoc) => (
  <Document>
    <Page>
      <Text> Section </Text>
    </Page>
  </Document>
);

export default MyDoc;
