/* eslint-disable no-useless-escape,no-mixed-operators */
/* eslint-disable max-len */
import {
  validationValue,
  insertOrRemoveErrorFromList,
} from '../../../utils/strings';
import { findLocationByName } from '../../../utils/filters';

export const stepsValidation = (
  name,
  errorText,
  changes,
  errorsList,
  setErrorsList,
  locations,
  countryCode,
) => {
  const temporalErrorsList = errorsList.slice();

  insertOrRemoveErrorFromList(
    temporalErrorsList,
    name,
    errorText,
    changes ? 'remove' : 'insert',
  );

  if (
    name === 'postalCode'
    || name === 'RFC'
    || name === 'email'
    || name === 'emailForBilling'
    || name === 'cellPhone'
    || name === 'address'
  ) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      name,
      validationValue(changes, name).error,
      validationValue(changes, name).correct ? 'remove' : 'insert',
    );
  }

  if (countryCode && countryCode === '170' && name === 'locationName') {
    const value = changes?.split('-');
    let location;

    if (value?.length > 1) {
      location = findLocationByName(value[0], value[1], locations);
    }

    insertOrRemoveErrorFromList(
      temporalErrorsList,
      name,
      'Ciudad no encontrada',
      location ? 'remove' : 'insert',
    );
  }

  setErrorsList(temporalErrorsList);
  return temporalErrorsList.length === 0;
};

const validateReceiverInfo = (receiverInfo, temporalErrorsList) => {
  const listToValidate = [
    {
      value: receiverInfo.receiverName,
      errorName: 'receiverName',
      errorText: 'Ingresa el nombre de quien recibe',
    },
    {
      value: receiverInfo.receiverLastName,
      errorName: 'receiverLastName',
      errorText: 'Ingresa el apellido de quien recibe',
    },
    {
      value: receiverInfo.receiverCellphone,
      errorName: 'receiverCellphone',
      errorText: 'Ingresa un número de celular',
    },
    {
      value: receiverInfo.destinationAddress,
      errorName: 'destinationAddress',
      errorText: 'Ingresa máximo 200 caracteres.',
    },
  ];

  listToValidate.map((item) => {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      item.errorName,
      item.errorText,
      validationValue(item.value, item.type).correct ? 'remove' : 'insert',
    );
    return null;
  });

  return listToValidate;
};

const validateReceiverCellphoneLength = (receiverInfo, temporalErrorsList) => {
  if (receiverInfo.receiverCellphone.length !== 10) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'receiverCellphone',
      'El número de celular debe tener 10 dígitos',
      receiverInfo.receiverCellphone.length === 10 ? 'remove' : 'insert',
    );
  }
};

const validateReceiverEmail = (receiverInfo, listToValidate) => {
  if (receiverInfo.receiverEmail) {
    listToValidate.push({
      value: receiverInfo.receiverEmail,
      errorName: 'receiverEmail',
      errorText: 'Ingresa un correo valido',
      type: 'email',
    });
  }
};

const validateDestinationAddressLength = (receiverInfo, listToValidate) => {
  if (receiverInfo.destinationAddress.length > 200) {
    listToValidate.push({
      value: receiverInfo.destinationAddress,
      errorName: 'destinationAddress',
      errorText: 'Ingresa máximo 200 caracteres.',
      type: 'destinationAddress',
    });
  }
};

const validateTakeToOfficeOrCollection = (
  receiverInfo,
  temporalErrorsList,
  listToValidate,
) => {
  if (receiverInfo.takeToOfficeOrCollection === 'takeToOffice') {
    listToValidate.push({
      value: receiverInfo.receiverDocumentNumber,
      errorName: 'receiverDocumentNumber',
      errorText: 'Ingresa el número de documento de quien recibe',
    });

    const removeReceiverAddress = temporalErrorsList
      .map((errorMessage) => errorMessage.name)
      .indexOf('destinationAddress');
    if (removeReceiverAddress > -1) temporalErrorsList.splice(removeReceiverAddress, 1);
  } else {
    listToValidate.push({
      value: receiverInfo.destinationAddress,
      errorName: 'destinationAddress',
      errorText: 'Ingresa una dirección de entrega',
    });

    const removeReceiverDocumentNumber = temporalErrorsList
      .map((errorMessage) => errorMessage.name)
      .indexOf('receiverDocumentNumber');
    if (removeReceiverDocumentNumber > -1) temporalErrorsList.splice(removeReceiverDocumentNumber, 1);
  }
};

export const stepTwoValidation = (
  countryCode,
  receiverInfo,
  errorsList,
  setErrorsList,
) => {
  const temporalErrorsList = errorsList.slice();

  const listToValidate = validateReceiverInfo(receiverInfo, temporalErrorsList);

  validateReceiverCellphoneLength(receiverInfo, temporalErrorsList);
  validateReceiverEmail(receiverInfo, listToValidate);
  validateDestinationAddressLength(receiverInfo, listToValidate);
  validateTakeToOfficeOrCollection(
    receiverInfo,
    temporalErrorsList,
    listToValidate,
  );

  if (
    countryCode === '170'
    || (countryCode === '484' && receiverInfo.receiverCellphone.length < 10)
  ) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      'receiverCellphone',
      'El número de celular debe tener 10 dígitos',
      receiverInfo.receiverCellphone.length === 10 ? 'remove' : 'insert',
    );
  }

  setErrorsList(temporalErrorsList);

  return temporalErrorsList.length === 0;
};
