import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { operationValidate, marketingValidate } from '../../utils/role';

import QuoteShipping from './quoteShipping';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';
import QuoteMultipleShippings from './quoteMultipleShippings';

import './quoteShippingScreen.scss';

const QuoteShippingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state?.auth?.user || {});

  const [, setExpressMobile] = useState(false);
  const [contentToShowInMobileDevice, setContentToShowInMobileDevice] = useState('quoteShipping');
  const [fullContainterQuoteShipping, setFullContainerQuoteShipping] = useState(false);
  const [deliveryListSizeChange, setDeliveryListSizeChange] = useState(false);

  useEffect(() => {
    if (location.pathname !== '/cotizacion') {
      if (location.pathname === '/cotizacion/quoteMultipleShippings') {
        setContentToShowInMobileDevice('quoteMultipleShippings');
      }
      if (location.pathname === '/cotizacion/expressShipping') {
        setContentToShowInMobileDevice('expressShipping');
      }
      if (!isMobileDevice) navigate('../cotizacion', { replace: true });
    }
  });

  useEffect(() => {
    if (operationValidate(user) || marketingValidate(user)) {
      navigate('../buscar-codigo-mp', { replace: true });
    }
  }, [user]);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile>
        <>
          {contentToShowInMobileDevice === 'quoteShipping' && (
            <QuoteShipping />
          )}
          {contentToShowInMobileDevice === 'expressShipping' && (
            <QuoteShipping
              expressMobile={() => setExpressMobile(true)}
            />
          )}
          {contentToShowInMobileDevice === 'quoteMultipleShippings' && (
            <QuoteMultipleShippings />
          )}
        </>
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <div className="container-quote-shipping__section-desktop">
          <div
            className={[
              'primary-column',
              fullContainterQuoteShipping ? 'full-container' : '',
              deliveryListSizeChange ? 'size-change' : '',
            ].join(' ')}
          >
            <QuoteShipping
              setFullContainerQuoteShipping={setFullContainerQuoteShipping}
              setDeliveryListSizeChange={setDeliveryListSizeChange}
              onChangeTypeSection={(e) => {
                if (e === 'Masivos') setFullContainerQuoteShipping(true);
                if (e !== 'Masivos') setFullContainerQuoteShipping(false);
              }}
            />
          </div>
        </div>
      </ContainerSectionDesktop>
    )
  );
};

export default QuoteShippingScreen;
