import React from 'react';
import PropTypes from 'prop-types';

import './button.scss';

/**
 * Componente que abarca los botones de la app
 * @param {string} text - texto a mostrarse en el botón
 * @param {string} type - tipo de botón, admite "primary" y "secondary"
 * @param {string} className - clase adicional para aplicar estilos
 * @param {string} id - propiedad única del componente
 * @param {boolean} disabled - propiedad para deshabilitar el botón
 * @param {function} onClick - función a ejecutar al presionar el botón
 */

const Button = ({
  text,
  onClick,
  type,
  className,
  id,
  disabled,
  hidden,
  loading,
  link,
  normal,
  children,
  iconText,
  rightImgButton,
}) => (
  link ? (
    <a
      href={!disabled && link}
      id={id}
      className={`button-${type} ${className} ${hidden ? 'hidden' : ''} ${disabled ? 'disabled' : ''}`}
      type="button"
      target="_blank"
      rel="noreferrer"
    >
      {loading ? <div className="preloader-button" /> : text}
    </a>
  ) : (
    <button
      id={id}
      className={`button-${type} ${className} ${hidden ? 'hidden' : ''} ${normal ? 'normal' : 'small'}`}
      type="button"
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <div className="preloader-button" />
      ) : (
        children || text || iconText
      )}
      {rightImgButton && (<img alt="Icon" src={rightImgButton} className="right-image-button" />)}
    </button>
  )
);

Button.propTypes = {
  children: PropTypes.element,
  text: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'primary-second', 'link', 'none', 'primary-mx']).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  link: PropTypes.string,
  normal: PropTypes.bool,
  iconText: PropTypes.element,
  rightImgButton: PropTypes.string,
};

Button.defaultProps = {
  children: null,
  text: null,
  className: '',
  id: '',
  disabled: false,
  hidden: null,
  loading: false,
  link: null,
  normal: false,
  rightImgButton: null,
  onClick: () => null,
  iconText: null,
};

export default Button;
