/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */

import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';

import JSEncrypt from 'jsencrypt';

import Input from '../../../../components/input';
import Select from '../../../../components/select';
import Button from '../../../../components/button';
import Typography from '../../../../components/typography';

import config from '../../../../config/nocompanyconfig.fron.json';
import documentTypeList from '../commons/documents';
import { toCurrencyCop } from '../../../../utils/strings';
import { findObjectInArray } from '../../../../utils/filters';
import { getLocationsService } from '../../../../services/location';
import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import cardValidate from './validate';
import getCreditCardsType from '../../../../utils/creditCardsType';

import noCard from '../../../../assets/images/typesCard/Franciquicas.png';
import './methodPayments.scss';

const CardData = ({
  isMobileDevice, user, balanceToRecharge, paymentFunction,
  setSelectedPayment, setPaymentMethodSelected,
}) => {
  const [date] = useState((new Date()));
  const [errorsList, setErrorsList] = useState([]);
  const [creditNumber, setCreditNumber] = useState(null);
  const [showCreditNumber, setShowCreditNumber] = useState(null);
  const [documentNumber, setDocumentNumber] = useState(user.documentNumber);
  const [documentType, setDocumentType] = useState(null);
  const [name, setName] = useState(`${user.name} ${user.surname}`);
  const [surname, setSurname] = useState(`${user.name} ${user.surname}`);
  const [address, setAddress] = useState(user.address);
  const [monthYear, setMonthYear] = useState(null);
  const [cvv, setCvv] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [countriesToSearch, setCountryToSearch] = useState([]);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(user.city);
  const [cellPhone, setCellPhone] = useState(user.cellPhone);
  const [email, setEmail] = useState(user.email);
  const [cardImage, setCardImage] = useState(noCard);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  useEffect(async () => {
    const countries = await getLocationsService('countries=true');
    const tempCountryList = countries.map((nation) => ({
      label: nation.name,
      value: nation.alfa3Code,
    }));
    setCountryList(tempCountryList);
  }, []);

  useEffect(() => (setSurname(name)), [name]);
  useEffect(() => {
    const cardTypeImage = getCreditCardsType(creditNumber)?.image || noCard;
    setCardImage(cardTypeImage);
  }, [creditNumber]);

  useEffect(() => {
    const tempCountryList = countryList.map((nation) => nation.label);
    setCountryToSearch(tempCountryList);
  }, [countryList]);

  const mask = (value) => {
    let hideNum = [];
    let newValue = [];
    const correctValue = value.replace(/ /gmi, '');
    if (/^[\d\s]+$/.test(value) || (value === '' || null)) {
      for (let i = 0; i < correctValue.length; i += 1) {
        if (!Number.isNaN(Number.parseInt(correctValue[i], 10))) {
          newValue.push(correctValue[i]);
        } else if (correctValue[i] !== ' ') {
          newValue.push(correctValue[i]);
        }

        if (i % 4 === 0 && i > 0 && correctValue[i] !== ' ') {
          hideNum.push(` ${correctValue[i]}`);
        } else {
          hideNum.push(correctValue[i]);
        }
      }
      hideNum = hideNum.join('');
      newValue = newValue.join('');

      setCreditNumber(newValue);
      setShowCreditNumber(hideNum);
    }
  };

  const monthYearCorecction = (value) => {
    const currentValue = value.split('/');
    if (currentValue[0]?.length <= 2 && (currentValue[1]?.length || 0) <= 2) {
      if (value.length === 2 && monthYear.length < value.length && Number.parseInt(monthYear, 10) <= 12) {
        const newValue = `${value}/`;
        setMonthYear(newValue);
      } else setMonthYear(value);
    }
  };

  const validate = async () => {
    const validateData = cardValidate({
      creditNumber,
      documentNumber,
      cvv,
      name,
      surname,
      monthYear,
      documentType,
      address,
      country,
      city,
      cellPhone,
      email,
      countryList,
      errorsList,
      setErrorsList,
    });
    if (validateData.permission) {
      const rsa = config.rsaElp;
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(rsa);
      const creditCardNumber = await encrypt.encrypt(creditNumber.toString());
      const ccvNumber = await encrypt.encrypt(cvv);

      paymentFunction({
        creditCard: validateData.creditCard,
        creditNumber: creditCardNumber,
        cvv: ccvNumber,
        month: monthYear.split('/')[0],
        year: monthYear.split('/')[1],
        accountType: 'C',
        documentType,
        documentNumber,
        address,
        country: validateData.countrySelected,
        city,
        cellPhone,
        email,
        amount: balanceToRecharge,
        user: user._id,
        name,
        surname,
      }, 'transactionWithCard');
    }
    return null;
  };

  return (
    <div id="card-container" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className="first-inner-container">
        <div className="first-row row">
          <div className="wrapper">
            <Input
              type="text"
              label="Número de tarjeta"
              placeholder="123456789"
              classNameContainer="custom-icon"
              value={showCreditNumber}
              onChange={mask}
              annotationError={
                findObjectInArray('creditNumber', errorsList, 'name')
                  ? findObjectInArray('creditNumber', errorsList, 'name').error
                  : null
              }
              annotation="No se procesan tarjetas Diners Club"
              iconLeft={cardImage}
            />
          </div>
          <div className="wrapper">
            <Input
              type="text"
              label="Fecha de expiración"
              placeholder="12/27"
              value={monthYear}
              onChange={monthYearCorecction}
              annotationError={
                findObjectInArray('monthYear', errorsList, 'name')
                  ? findObjectInArray('monthYear', errorsList, 'name').error
                  : null
              }
            />
          </div>

        </div>
        <div className="first-row row">
          <div className="wrapper">
            <Input
              type="password"
              label="CVV"
              placeholder="1234"
              value={cvv}
              onChange={(value) => value.length < 5 && !Number.isNaN(Number.parseInt(value || 0, 10)) && setCvv(value)}
              annotationError={
                findObjectInArray('cvv', errorsList, 'name')
                  ? findObjectInArray('cvv', errorsList, 'name').error
                  : null
              }
            />
          </div>
          <div className="wrapper">
            <Input
              type="text"
              label="Nombre impreso en la tarjeta"
              placeholder="Damocles"
              value={name}
              onChange={setName}
              annotationError={
                findObjectInArray('name', errorsList, 'name')
                  ? findObjectInArray('name', errorsList, 'name').error
                  : null
              }
            />
          </div>
        </div>
        <div className="first-row row">
          <div className="wrapper">
            <Select
              options={documentTypeList}
              label="Tipo de identificación"
              value={documentType}
              onChange={setDocumentType}
              className="bank-select first-select"
              annotationError={
                findObjectInArray('documentType', errorsList, 'name')
                  ? findObjectInArray('documentType', errorsList, 'name').error
                  : null
              }
            />
          </div>
          <div className="wrapper">
            <Input
              type="number-text"
              label="Nit o cédula del titular"
              placeholder="123456789"
              annotation="Poner sin el dígito de verificación"
              value={documentNumber}
              onChange={setDocumentNumber}
              annotationError={
                findObjectInArray('documentNumber', errorsList, 'name')
                  ? findObjectInArray('documentNumber', errorsList, 'name').error
                  : null
              }
            />
          </div>
        </div>
        <div className="first-row row">
          <div className="wrapper">
            <Input
              dataList={countriesToSearch}
              label="País"
              value={country}
              onChange={setCountry}
              placeholder="Colombia"
              className="bank-select second-select"
              annotationError={
                findObjectInArray('country', errorsList, 'name')
                  ? findObjectInArray('country', errorsList, 'name').error
                  : null
              }
            />
          </div>
          <div className="wrapper">
            <Input
              type="text"
              label="Ciudad"
              placeholder="Medellín"
              value={city}
              onChange={setCity}
              annotationError={
                findObjectInArray('city', errorsList, 'name')
                  ? findObjectInArray('city', errorsList, 'name').error
                  : null
              }
            />
          </div>
        </div>
        <div className="first-row row">
          <div className="wrapper">
            <Input
              type="text"
              label="Dirección"
              placeholder="Carrera 12"
              value={address}
              onChange={setAddress}
              annotationError={
                findObjectInArray('address', errorsList, 'name')
                  ? findObjectInArray('address', errorsList, 'name').error
                  : null
              }
              annotation="Su dirección debe ser calle 10A en lugar de Cll 10A"
            />
          </div>
          <div className="wrapper">
            <Input
              type="text"
              label="E-mail del titular"
              placeholder="test@test.com"
              value={email}
              onChange={setEmail}
              annotationError={
                findObjectInArray('email', errorsList, 'name')
                  ? findObjectInArray('email', errorsList, 'name').error
                  : null
              }
            />
          </div>
        </div>
        <div className="first-row row">
          <div className="wrapper">
            <Input
              type="number-text"
              label="Teléfono del titular"
              placeholder="3112311212"
              value={cellPhone}
              onChange={setCellPhone}
              annotationError={
                findObjectInArray('cellPhone', errorsList, 'name')
                  ? findObjectInArray('cellPhone', errorsList, 'name').error
                  : null
              }
            />
          </div>
        </div>
      </div>
      <div className="second-row">
        <Typography
          text="Resumen de tu transacción"
          type="title"
          className="summary"
        />
        <Typography
          text="valor a pagar"
          type="value"
          className="no-value"
        />
        <Typography
          text={toCurrencyCop(user, balanceToRecharge)}
          type="title"
          className="balance-to-recharge"
        />
        <Typography
          text="Detalles de pago"
          type="value"
          className="no-value"
        />
        <Typography
          text="Recarga de saldo en mipaquete.com"
          type="title"
          className="no-value space"
        />
        <div className="date">
          <Typography
            text="Fecha límite de pago:"
            type="value"
            className="no-value"
          />
          <Typography
            text={date.toLocaleDateString('es-CO')}
            type="value"
            className="no-value"
          />
        </div>
        <div className="buttons-container">
          <Button
            text="Recargar el saldo"
            type={isCountryValid ? 'primary-mx' : 'primary'}
            onClick={() => validate()}
            className="first-button"
          />
          <Button
            text="Cancelar"
            type="secondary"
            onClick={() => { setPaymentMethodSelected(null); setSelectedPayment(true); }}
            className="second-button"
          />
        </div>
      </div>
    </div>
  );
};

CardData.propTypes = {
  user: PropTypes.object.isRequired,
  paymentFunction: PropTypes.func.isRequired,
  setSelectedPayment: PropTypes.func.isRequired,
  setPaymentMethodSelected: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  balanceToRecharge: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(CardData);
