export const operationValidate = (user) => (
  !!user?.role?.length > 0 && user.role.find((role) => role.module === 'Operations')
);

export const marketingValidate = (user) => (
  !!user?.role?.length > 0 && user.role.find((role) => role.module === 'Marketing')
);

export const commercialValidate = (user) => (
  !!user?.role?.length > 0 && user.role.find((role) => role.module === 'Commercial')
);

export const financialValidate = (user) => (
  !!user?.role?.length > 0 && user.role.find((role) => role.module === 'Financial')
);

export const myDiscountsValidate = (user) => (
  user?.volumeDiscountNegotiation?.enabled && Object.prototype.hasOwnProperty.call(user, 'volumeDiscountNegotiation')
);

export const existRole = (user) => !!user?.role?.length > 0;
