import React from 'react';
import Parser from 'react-html-parser';
import Typography from '../../../../components/typography';
import ImageEmpyState from '../../../../components/imagesEmptyState';
import emptyImage from '../../../../assets/images/Tus cotizaciones.svg';

import './noTickets.scss';
import Button from '../../../../components/button';

import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

const linkDescription = '';

const NoTickets = () => {
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div className="no-tickets-container">
      <Typography
        type="title"
        text="Historial de recaudos"
        className="title-no-tickets"
      />

      <div className="link-content">
        <p>{Parser(linkDescription)}</p>
      </div>

      <ImageEmpyState
        title="Aún no tienes recaudos pagados"
        description="Aquí encontrarás el detalle de tus ventas realizadas con pago contra entrega, es decir, la información de los recaudos que se han transferido."
        uri={emptyImage}
        containerDescription="container-description-empty-state"
        supportDescription="Conoce más sobre nuestros Envíos con Pago Contra Entrega, costos, fechas de transferencia, períodos de recaudos y todo lo que necesitas para que aumentes tus ventas."
      />

      <div className="no-tickets-button">
        <Button
          type={isCountryValid ? 'primary-mx' : 'primary'}
          text="Quiero conocer más"
          onClick={() => window.open('https://www.mipaquete.com/soluciones-ecommerce/envios-pago-contraentrega', 'blank', 'noopener')}
        />
      </div>
    </div>
  );
};

export default NoTickets;
