import React from 'react';
import PropTypes from 'prop-types';

import { humanizationOfTimeDuration } from '../../../utils/generals';
import { addThousandPoint } from '../../../utils/strings';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import Typography from '../../../components/typography';
import Modal from '../../../components/modal';
import ModalChildren from '../../../components/modalChildren';
import Button from '../../../components/button';
import Carousel from '../../../components/carousel';

import ImageNoContent from '../../../assets/images/no-content.png';

import './modals.scss';

export const ModalConfirm = ({
  option, pointsCost, cancel, confirm, executingAction,
}) => (
  <Modal>
    <ModalChildren
      headerText={(
        <>
          {option ? `${option} equivale a ` : 'Redimirás'}
          {' '}
          <span className="color-primary">
            {addThousandPoint(pointsCost)}
            {' '}
            {pointsCost === 1 ? 'punto' : 'puntos'}
          </span>
        </>
      )}
      close={() => cancel && cancel()}
      bodyChildren={(
        <Typography type="paragraph" className="paragraph-modal-redeem-point-section">
          ¿estás seguro de redimir tus puntos en
          {' '}
          <span className="bold">{option || 'tu saldo precargado'}</span>
          ?
        </Typography>
      )}
      confirmButtonText="si, redimir"
      executingAction={executingAction}
      onClickButtonConfirm={() => confirm && confirm()}
      cancelButtonText="no, más tarde"
      onClickButtonCancel={() => cancel && cancel()}
    />
  </Modal>
);

export const ModalSucessOrError = ({ dataModal, handleChangeDataModal }) => (
  <Modal
    className="modal-success-or-error-redeem-points"
    closeBackground={() => handleChangeDataModal(null)}
  >
    <ModalChildren
      headerChildren={dataModal.header}
      noIconClose
      bodyChildren={dataModal.body}
      confirmButtonText="Entendido"
      onClickButtonConfirm={() => handleChangeDataModal(null)}
    />
  </Modal>
);

export const ModalCarrierService = ({ next, handleChangeShowModalCarrierService }) => {
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);
  return (
    <Modal
      className="modal-carrier-service-redeem-points"
      closeBackground={() => handleChangeShowModalCarrierService(false)}
    >
      <ModalChildren
        close={() => handleChangeShowModalCarrierService(false)}
      >
        <div className="content-modal-carrier-service flex row">
          <img
            src="https://s3.amazonaws.com/images.dev.mipaquete/products-and-services/Carrier-service.png"
            alt="bonificaciones"
          />
          <div className="container-text-carrier-service">
            <Typography type="superTitle-italic">¿Qué es Carrier Service?</Typography>
            <Typography type="paragraph" style={{ whiteSpace: 'pre-line' }}>
              El “Carrier Service” (Servicio de Transportadora) de Shopify es
              una opción que habilita Shopify que le permite al comprador de la tienda virtual
              ver el precio del envío calculado de forma automática.
              Esto se logra cuando la tienda tiene instalada
              la app de mipaquete.com
              El servicio Carrier Service está incluido
              en el Plan advanced Shopify o la anualidad del plan basic Shopify.
              <br />
              <br />
              * Recuerda que el mes gratis del Carrier Service es válido
              para los usuarios en nivel 5.
            </Typography>
            <div className="full-width">
              <Button
                text="Quiero saber más"
                onClick={() => window.open('https://www.mipaquete.com/conecta-tu-tiendavirtual/tienda-en-shopify/', 'WindowName', 'noopener')}
                type="tertiary"
              />
              <Button
                text="Quiero solicitar mi mes gratis"
                onClick={() => {
                  next();
                  handleChangeShowModalCarrierService(false);
                }}
                type={isCountryValid ? 'primary-mx' : 'primary'}
              />
            </div>
          </div>
        </div>
      </ModalChildren>
    </Modal>
  );
};

export const ModalProductOrServiceDetails = ({
  benefictId, name, description, images, deliveryTime, benefictType,
  allied, points, close, next, loading, totalPoints,
}) => {
  const imageSize = 200;
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);
  return (
    <Modal closeBackground={() => close(null)} className="modal-product-or-service-details" visible={!!benefictId}>
      <ModalChildren
        close={() => close(null)}
        headerText={name}
      >
        <div className="content-modal-product-or-service-details flex row">
          <Carousel
            className="carousel-images-product-or-service-details"
            style={{ width: `${imageSize + 60}px`, margin: '0 30px' }}
          >
            <>
              {images && images.map((image) => (
                <img
                  key={image}
                  style={{ width: `${imageSize}px`, height: `${imageSize}px`, margin: '0 30px' }}
                  src={image || ImageNoContent}
                  alt="bonificaciones"
                />
              ))}
            </>
          </Carousel>
          <div className="container-text-product-or-service-details">
            <Typography type="superTitle-italic">{name}</Typography>
            <Typography type="paragraph" style={{ whiteSpace: 'pre-line' }}>{description}</Typography>
            <Typography type="paragraph">
              {benefictType === 'product' && 'Tiempo de entrega:'}
              {benefictType === 'service' && 'Tiempo de activación:'}
              {' '}
              {humanizationOfTimeDuration(deliveryTime)}
            </Typography>
            {points > totalPoints && (
              <Typography type="paragraph">
                {benefictType === 'service' ? '¡Sigue acumulando más puntos para canjearlos por este servicio!' : '¡Sigue acumulando más puntos para canjearlos por este producto!'}
              </Typography>
            )}
            <div className="full-width flex row align-center justify-between">
              {allied && (
                <Typography type="paragraph" className="color-accent">
                  <span onClick={() => window.open(`http://${allied}`, '_blank')} style={{ cursor: 'pointer' }}>{allied}</span>
                </Typography>
              )}
              <Typography type="paragraph" className={`${points > totalPoints ? 'color-red' : 'color-primary'} bold`}>
                {addThousandPoint(points)}
                {' '}
                {points === 1 ? 'punto' : 'puntos'}
              </Typography>
            </div>
            <div className="full-width">
              <Button
                text="No, más tarde"
                onClick={close}
                type="secondary"
              />
              <Button
                text="Sí, redimir"
                onClick={() => next(benefictType, benefictId)}
                type={isCountryValid ? 'primary-mx' : 'primary'}
                loading={loading}
                disabled={points > totalPoints}
              />
            </div>
          </div>
        </div>
      </ModalChildren>
    </Modal>
  );
};

ModalCarrierService.propTypes = {
  next: PropTypes.func.isRequired,
  handleChangeShowModalCarrierService: PropTypes.func.isRequired,
};

ModalConfirm.propTypes = {
  option: PropTypes.string.isRequired,
  pointsCost: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  executingAction: PropTypes.func.isRequired,
};

ModalSucessOrError.propTypes = {
  dataModal: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
  handleChangeDataModal: PropTypes.func.isRequired,
};

ModalProductOrServiceDetails.propTypes = {
  benefictId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  deliveryTime: PropTypes.string.isRequired,
  benefictType: PropTypes.string.isRequired,
  allied: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  totalPoints: PropTypes.number.isRequired,
};
