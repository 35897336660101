import React from 'react';
import { useSelector } from 'react-redux';
import Prototype from 'prop-types';

import {
  HeaderDetail, ProductDetail, RecipientDetail, SenderDetail, DeliveryCompanyDetails,
} from '../sectionsDetails';
import shippingPropTypes from '../propTypesShippingDetails';

import { typeDelivery } from '../../../constants/general';
import { isDelivered } from '../../../services/sendings';
import { validateComments, validateDestinyLocation, validateOriginLocation } from '../../../constants/shippingDetails';

import Button from '../../../components/button';
import Tracking from '../../../components/shippingDetails/traking/tracking';
import Tooltip from '../../../components/tooltip';

import iconTooltip from '../../../assets/images/Tooltip-disabled.svg';
import iconTooltipActive from '../../../assets/images/Tooltip.svg';
import iconFeatherDownload from '../../../assets/images/Icon-feather-download-line.svg';

import './shippingDetailsDesktop.scss';

const ShippingDetailsDesktop = ({
  shipping,
  back,
  downloadGuideFunction,
  executingDownload,
  shippingCancelFunction,
  cancellingShipping,
  cancellationIsValid,
  user,
  noveltyCodes,
  businessFlags,
}) => {
  const countryCode = useSelector((state) => state?.country || null);

  return (
    <div className="card-container-desktop-shipping-details">
      <HeaderDetail
        guideNumber={shipping.guideNumber}
        code={shipping.code}
        back={back}
      />
      {shipping?.error?.clientMessageR && (
        <div className="container-information-shipping-desktop-top error">
          <div className="container-title">
            <div className="image"><div className="ex" /></div>
            <div className="title"><h3>{shipping?.error?.clientMessage ?? ''}</h3></div>
          </div>
        </div>
      )}
      <div className="container-information-shipping-desktop-top">
        <div>
          <SenderDetail
            senderName={`${shipping.sender.name} ${shipping.sender.surname}`}
            senderAddress={shipping.sender.pickupAddress}
            senderLocation={
              validateOriginLocation(
                user.countryCode,
                shipping.locate.originDaneCode,
                shipping.locate.originName,
                shipping.locate.originDepName,
              )
            }
            pickup={shipping.requestPickup}
          />
          <br />
          <DeliveryCompanyDetails
            deliveryCompanyName={shipping.deliveryCompanyName}
            curencyPay={shipping.currencyToPay}
            price={shipping.price}
            observations={validateComments(shipping.comments)}
            imageUrl={shipping.deliveryCompanyImage}
            user={user}
          />
        </div>
        <RecipientDetail
          recipientAddress={shipping.receiver.destinationAddress}
          recipientLocation={
            validateDestinyLocation(
              user.countryCode,
              shipping.locate.destinyDaneCode,
              shipping.locate.destinyName,
              shipping.locate.destinyDepName,
            )
          }
          recipientName={`${shipping.receiver.name} ${shipping.receiver.surname}`}
          recipientEmail={shipping.receiver.email}
          recipientCellphone={shipping.receiver.cellPhone}
          recipientComment={shipping.comments}
        />
        <div className="content-product-detail-desktop">
          <ProductDetail
            description={shipping.description}
            width={shipping.productInformation.width}
            height={shipping.productInformation.height}
            large={shipping.productInformation.large}
            realWeight={shipping.productInformation.realWeight}
            weight={shipping.productInformation.weight}
            declaredValue={shipping.productInformation.declaredValue}
            quantity={shipping.productInformation.quantity}
            user={user}
          />
          {isDelivered(shipping) && (
            <div className="button-and-tooltip">
              <Button
                type="tertiary"
                text="Comprobante"
                className="secondary"
                onClick={() => {
                  downloadGuideFunction();
                }}
                loading={executingDownload}
              >
                <span className="button-inner-text">Comprobante</span>
                <img src={iconFeatherDownload} alt="D" />
              </Button>
              <Tooltip
                iconTooltip={iconTooltip}
                iconTooltipHover={iconTooltipActive}
                overlayTextElement={(
                  <p className="delivery-confirmation-normal-text">
                    <span className="delivery-confirmation-word-tooltip">Comprobante</span>
                    {' '}
                    de entrega sujeto a disponibilidad de la
                    {' '}
                    {typeDelivery[countryCode]}
                    .
                  </p>
                )}
                startingPosition="bottom-rigth"
              />
            </div>
          )}
          {cancellationIsValid && (
            <Button
              type="secondary"
              className="secondary"
              onClick={() => {
                shippingCancelFunction(shipping.code);
              }}
              text="Cancelar envío"
              loading={cancellingShipping}
            />
          )}
        </div>
      </div>
      <div className="container-information-shipping-desktop-bottom">
        <Tracking
          noveltyCodes={noveltyCodes}
          guideNumber={shipping.guideNumber}
          isMobileDevice={false}
          tracking={shipping.tracking}
          access={null}
          businessFlags={businessFlags}
        />
      </div>
    </div>
  );
};

ShippingDetailsDesktop.propTypes = {
  shipping: Prototype.shape(shippingPropTypes).isRequired,
  back: Prototype.func.isRequired,
  noveltyCodes: Prototype.shape({}).isRequired,
  businessFlags: Prototype.shape({}).isRequired,
  user: Prototype.shape({
    countryCode: Prototype.string,
  }).isRequired,
  downloadGuideFunction: Prototype.func.isRequired,
  executingDownload: Prototype.bool.isRequired,
  cancellingShipping: Prototype.bool.isRequired,
  shippingCancelFunction: Prototype.func.isRequired,
  cancellationIsValid: Prototype.bool.isRequired,
};
export default ShippingDetailsDesktop;
