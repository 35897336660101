import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRouter = ({ children }) => {
  const { user } = useSelector((state) => state.auth);

  if (!user) return <Navigate to="/cotizacion" replace />;

  return children;
};

ProtectedRouter.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProtectedRouter;

export const SaaSRouter = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const { clientType, role } = user || {};

  if (clientType !== 'SaaS') return <Navigate to="/cotizacion" replace />;
  if (role) return <Navigate to="/cotizacion" replace />;

  return children;
};

SaaSRouter.propTypes = {
  children: PropTypes.element.isRequired,
};
