const { getCollectionPaymentsByUserService, getShipmentPaidService } = require('../../../../services/billing');

export const getCollectionPaymentsByUser = async (
  itemsPerPage,
  lastItem,
  page = undefined,
  filters = {},
) => {
  try {
    return await getCollectionPaymentsByUserService(page, itemsPerPage, lastItem, filters);
  } catch {
    return null;
  }
};

export const getShipmentPaidByUser = async (query, showError = null) => {
  try {
    const newQuery = {
      ...query,
      ...query.itemsQuantity ? { itemsQuantity: query.itemsQuantity } : { itemsQuantity: 30 },
    };
    const data = await getShipmentPaidService(newQuery);
    if ((data?.length <= 0 || !data) && showError) return [];
    return data;
  } catch {
    if (showError) showError();
  }
  return null;
};
