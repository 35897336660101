const totalServiceValue = ({ adminTransactionData }) => (
  adminTransactionData.sendingValue + adminTransactionData.collectionCommission);

export default totalServiceValue;

const withCollectionService = ({ collectionValue, sendingValue, collectionCommission }) => (
  collectionValue - (sendingValue + collectionCommission)
);

export const valueToTransfer = ({ adminTransactionData, paymentType }) => (
  paymentType === 102 && adminTransactionData.collectionValue > 0
    ? withCollectionService(adminTransactionData)
    : adminTransactionData.saleValue
);

export const reimbursementCommission = ({ adminTransactionData, state }) => (
  state === 'Devuelto' && adminTransactionData > 0 ? adminTransactionData.collectionCommission : 0
);

export const isNotANumber = (value) => (isNaN(value) ? 0 : value);

export const saving = ({ adminTransactionData }) => {
  const {
    sendingRealValue,
    completeCollectionCommission,
    sendingValue,
    collectionCommission,
  } = adminTransactionData;

  const totalRealValue = isNotANumber(sendingRealValue ?? sendingValue)
    + isNotANumber(completeCollectionCommission ?? collectionCommission);

  const totalDiscountsValue = isNotANumber(sendingValue) + isNotANumber(collectionCommission);

  return totalRealValue === 0 ? 0 : totalRealValue - totalDiscountsValue;
};
