import { validationValue, insertOrRemoveErrorFromList } from '../../../../utils/strings';

export const validationSteps = ({
  errorList, setErrorList, address, RFC, postalCode, emailForBilling,
  lengthError, value, personalized,
}) => {
  const temporalErrorList = errorList.slice();

  if (typeof address === 'string') {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'address',
      'Ingresa una dirección válida',
      validationValue(address).correct ? 'remove' : 'insert',
    );
  }

  if (typeof postalCode === 'string') {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'postalCode',
      'Ingresa un código postal válido de 5 dígitos',
      validationValue(postalCode, 'postalCode').correct ? 'remove' : 'insert',
    );
  }

  if (typeof RFC === 'string') {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'RFC',
      'Ingresa tu RFC completo',
      validationValue(RFC, 'RFC').correct ? 'remove' : 'insert',
    );
  }

  if (typeof emailForBilling === 'string') {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'emailForBilling',
      'Ingresa un correo válido',
      validationValue(emailForBilling, 'emailForBilling').correct ? 'remove' : 'insert',
    );
  }

  if (lengthError) {
    insertOrRemoveErrorFromList(
      temporalErrorList,
      'lengthError',
      personalized,
      validationValue(value, 'lengthError').correct ? 'remove' : 'insert',
    );
  }

  setErrorList(temporalErrorList);
};

export default { validationSteps };
