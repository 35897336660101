import axios from 'axios';

import {
  billingMicroserviceUrl, customerKeyBilling,
} from '../constants';

export const getCollectionPaymentsByUserService = (
  page, itemsPerPage, lastItem, filters,
) => new Promise((resolve, reject) => {
  const endPoint = '/getCollectionPaymentsByUser';
  axios.defaults.headers.common['customer-key'] = customerKeyBilling;
  axios.post(`${billingMicroserviceUrl}${endPoint}`, {
    page,
    itemsPerPage,
    lastItem,
    ...filters,
  })
    .then((response) => { resolve(response.data); })
    .catch((response) => { reject(response); });
});

export const getNextCollectionData = () => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyBilling;
  axios.get(`${billingMicroserviceUrl}/nextCollection`)
    .then((res) => resolve(res.data))
    .catch((error) => reject(error));
});

export const getShipmentPaidService = (query) => new Promise((resolve, reject) => {
  const endPoint = '/getShipmentsPaid';
  axios.defaults.headers.common['customer-key'] = 'b5602275-621f-4c3f-9d00-e8039dbba020';
  axios.post(`${billingMicroserviceUrl}${endPoint}`, {
    ...query,
  }).then((response) => { resolve(response.data); }).catch((response) => { reject(response); });
});

export const getShipmentPaidTotalService = (query) => new Promise((resolve, reject) => {
  const endPoint = '/getShipmentsPaidTotal';
  axios.defaults.headers.common['customer-key'] = 'b5602275-621f-4c3f-9d00-e8039dbba020';
  axios.get(`${billingMicroserviceUrl}${endPoint}`, { params: query }).then((response) => { resolve(response.data); }).catch((response) => { reject(response); });
});

export const getTotalNumberOfShipments = (query) => new Promise((resolve, reject) => {
  const endPoint = '/getTotalNumberOfShipments';
  axios.defaults.headers.common['customer-key'] = 'b5602275-621f-4c3f-9d00-e8039dbba020';
  axios.post(`${billingMicroserviceUrl}${endPoint}`, {
    ...query,
  }).then((response) => { resolve(response.data); }).catch((response) => { reject(response); });
});
