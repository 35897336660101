/* eslint-disable prefer-destructuring */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Typography from '../../../../components/typography';
import { formatDay } from '../../../../utils/strings';
import CheckboxCustom from '../filterToggle/checkBoxCustom';
import CalendarComponent from '../../../../components/calendarComponent';

import '../filterToggle/filterToggle.scss';

const Filter = ({ onChangeFilter, isVisible }) => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [income, setIncome] = useState(false);
  const [expense, setExpense] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [datesRange, setDatesRange] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const reg = new RegExp(/de/g);

  useEffect(() => {
    if (!firstRender) {
      onChangeFilter(selectedFilters);
    } else {
      setFirstRender(false);
    }
  }, [selectedFilters]);

  const selectedFilter = () => {
    const filter = {};
    if (income) filter.transactionType = 'income';
    if (expense) filter.transactionType = 'expense';
    if (Array.isArray(datesRange)) {
      filter.initDate = datesRange[0];
      filter.finishDate = (datesRange[1]) ? datesRange[1] : datesRange[0];
    }
    setSelectedFilters(filter);
  };

  useEffect(() => {
    if (!firstRender) {
      selectedFilter();
    } else {
      setFirstRender(false);
    }
  }, [income, expense, datesRange]);

  const selectedExpense = () => {
    if (income) setIncome(!income);
    setExpense(!expense);
  };

  const selectedIncome = () => {
    if (expense) setExpense(!expense);
    setIncome(!income);
  };

  const selectedDateRage = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={isVisible ? 'contenFilter' : 'noContainer'}>
      <CheckboxCustom
        checked={income}
        onChange={selectedIncome}
        text="Ingresos"
      />
      <CheckboxCustom
        checked={expense}
        onChange={selectedExpense}
        text="Egresos"
      />
      <button
        type="button"
        tabIndex={0}
        className={`calendar ${datesRange ? '' : 'cursorP'} `}
        onClick={() => { if (datesRange === null) setShowModal(!showModal); }}
      >
        <Typography text="Fecha" type="field-gray-light" className="dateTextClass" />
        {datesRange && (
          <div className="selectedDates">
            <Typography
              text={datesRange[0] ? formatDay(datesRange[0], 'DD-MM').replace(reg, ' ') : ''}
              type="field-gray-light"
              className="color-ligther-gray"
            />
            <Typography
              text=" - "
              type="field-gray-light"
              className="color-ligther-gray separation"
            />
            <Typography
              text={datesRange[1] ? formatDay(datesRange[1], 'DD-MM').replace(reg, ' ') : ''}
              type="field-gray-light"
              className="color-ligther-gray"
            />
            {datesRange && (
              <button
                type="button"
                className="closeImage"
                tabIndex={0}
                onClick={() => setDatesRange(null)}
                aria-label="cerrar"
              />
            )}
          </div>
        )}
      </button>
      {showModal && (
        <CalendarComponent
          setShowModal={selectedDateRage}
          datesRange={datesRange}
          onChange={setDatesRange}
        />
      )}
    </div>
  );
};

Filter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  isVisible: PropTypes.string.isRequired,
};

export default Filter;
