/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
const saveAsCSV = async (data, fileName) => {
  try {
    const fields = Object.keys(data[0]);
    let csv = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName]?.toString().replaceAll('\r', '')?.replaceAll('\n', '');
      }).join(';');
    });
    const bom = '\uFEFF'; // byte order mark
    csv.unshift(bom + fields.join(';')); // add header column
    csv = csv.join('\r\n');

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'data.csv';
    a.style.display = 'none';

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    // Generate Excel File with given name
  } catch (error) {
    throw new Error(error);
  }
};

export default saveAsCSV;
