import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { validationSteps } from '../createAccount/validations';
import { validationValue } from '../../../utils/strings';
import { findObjectInArray } from '../../../utils/filters';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import Input from '../../../components/input';
import Button from '../../../components/button';
import Typography from '../../../components/typography';
import TermsAndConditions from './termsAndConditions';
import { ReactComponent as IconArrowBack } from '../../../assets/images/icon-arrow-back-color-primary.svg';

import IconVisibility from '../../../assets/images/icon-visibility-color-gray.svg';
import IconVisibilityOff from '../../../assets/images/icon-visibility-off-color-primary.svg';

import './stepsCreateAccount.scss';
import { annotationPassword } from '../../../constants/annotations';

const StepFour = ({
  goBack, next, userToCreate, executingAction, errorList, setErrorList,
}) => {
  const [password, setPassword] = useState(userToCreate.password || '');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(userToCreate.confirmPassword || '');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div className="stepCreateAccount-lastStep">
      <div className="top">
        <IconArrowBack onClick={goBack} className="arrowBack" />
        <Typography type="superTitle-italic" text="Establece tu contraseña" />
        <div />
      </div>

      <div className="bottom">
        <Input
          label="Escribe tu contraseña"
          className="inputUserToCreate"
          type={showPassword ? 'text' : 'password'}
          iconRigth={showPassword ? IconVisibilityOff : IconVisibility}
          onClickIconRight={() => setShowPassword(!showPassword)}
          value={password}
          onChange={(value) => {
            setPassword(value);
            if (findObjectInArray('password', errorList, 'name')) {
              validationSteps({ errorList, setErrorList, password: value });
            }
          }}
          onBlur={() => validationSteps({ errorList, setErrorList, password })}
          placeholder="Escribe tu contraseña"
          annotation={annotationPassword}
          annotationError={findObjectInArray('password', errorList, 'name')?.error || null}
        />
        <Input
          label="Confirma tu contraseña"
          className="inputUserToCreate"
          type={showConfirmPassword ? 'text' : 'password'}
          iconRigth={showConfirmPassword ? IconVisibilityOff : IconVisibility}
          onClickIconRight={() => setShowConfirmPassword(!showConfirmPassword)}
          value={confirmPassword}
          onChange={(value) => {
            setConfirmPassword(value);
            if (findObjectInArray('confirmPassword', errorList, 'name')) {
              validationSteps({
                errorList, setErrorList, password, confirmPassword: value,
              });
            }
          }}
          onBlur={() => validationSteps({
            errorList, setErrorList, password, confirmPassword,
          })}
          placeholder="Escribe tu contraseña"
          annotationError={findObjectInArray('confirmPassword', errorList, 'name')?.error || null}
          onCopy
          onPaste
        />
        <TermsAndConditions check={termsAndConditions} handleChangeCheck={setTermsAndConditions} />
        <Button
          text="Crear mi cuenta"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          loading={executingAction}
          disabled={
            password === '' || !validationValue(password, 'password').correct
            || confirmPassword !== password
            || !termsAndConditions
          }
          onClick={() => next(password, confirmPassword)}
        />
      </div>
    </div>
  );
};

StepFour.propTypes = {
  next: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  userToCreate: PropTypes.shape({
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
  }).isRequired,
  executingAction: PropTypes.bool.isRequired,
  errorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setErrorList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(StepFour);
