import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Modal from '../modal';
import ModalChildren from '../modalChildren';

import { createInteration, getAdEffect, isneedItShowAd } from './process';

import './modalAds.scss';

const ModalAds = () => {
  const { user } = useSelector((state) => state.auth);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const [ad, setAd] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const getAd = () => getAdEffect(setAd);

  const showAdOrNo = async () => {
    const showAd = await isneedItShowAd(ad, user);

    if (showAd) setShowModal(true);
    if (!showAd) setShowModal(false);
  };

  useEffect(() => getAd(), []);

  useEffect(() => {
    if (ad && !showModal) {
      showAdOrNo();
    }
  }, [user, ad]);

  const closeModal = () => {
    setShowModal(false);
    createInteration(ad, user);
  };

  if (showModal) {
    return (
      <Modal className="modal-ads" closeBackground={() => closeModal()}>
        <ModalChildren
          close={() => closeModal()}
          bodyChildren={(
            <div className="modal-ads-wrapper">
              <img
                src={isMobileDevice ? ad.mobileImage : ad.desktopImage}
                alt="inter"
                onClick={() => (ad.link ? window.open(ad.link, 'noopener') : null)}
              />
            </div>
          )}
        />
      </Modal>
    );
  }

  return null;
};

export default ModalAds;
