/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Button from '../../button';
import Input from '../../input';
import Typography from '../../typography';
import RadioButton from '../../radioButton';
import Tooltip from '../../tooltip';

import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';
import { typeDelivery } from '../../../constants/general';

import IconWhatsapp from '../../../assets/images/whatsapp.svg';
import IconColombia from '../../../assets/images/colombia.svg';

import '../lastDetails.scss';

const ReceiverDetails = ({
  next,
  heightType,
  receiverName,
  setReceiverName,
  receiverSurname,
  setReceiverSurname,
  receiverIdentificationNumber,
  setReceiverIdentificationNumber,
  receiverEmail,
  setReceiverEmail,
  receiverCellPhone,
  setReceiverCellPhone,
  receiverAddress,
  setReceiverAddress,
  deliveryInAddressOrOffice,
  setDeliveryInAddressOrOffice,
  deliverySelected,
  isMobileDevice,
  receiverNameError,
  receiverSurnameError,
  receiverIdentificationNumberError,
  receiverEmailError,
  receiverCellPhoneError,
  receiverAddressError,
  deliveryInAddressOrOfficeError,
  detailsFromQuoteMultipleShippings,
}) => {
  const countryCode = useSelector((state) => state?.country || null);

  useEffect(() => {
    if (
      deliverySelected.singleOfficeDelivery
    ) {
      setDeliveryInAddressOrOffice('deliveryInOffice');
    } else if (!deliverySelected.officeAddress) {
      setDeliveryInAddressOrOffice('deliveryInAddress');
    }
  }, [deliverySelected]);

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div
      className={`body-accordion__last-details receiver-details ${heightType ? heightType : ''} ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
    >
      <div
        className="second-body-accordion__last-details"
      >
        <div
          className="container__second-body-accordion__last-details top"
        >
          <div className="content-top-bottom-or-side__container__second-body-accordion__last-details">
            <Input
              label="Nombre"
              value={receiverName}
              onChange={setReceiverName}
              annotationError={receiverNameError}
            />
            <Input
              label="Apellido"
              value={receiverSurname}
              onChange={setReceiverSurname}
              annotationError={receiverSurnameError}
            />
            <div className="container-address__receiver-details">
              <Typography type="subtitle" className="title-address__sender-details" text="Dirección de entrega" />
              {!deliverySelected.singleOfficeDelivery && (
                <RadioButton
                  name="address-receiver"
                  value="deliveryInAddress"
                  onChange={setDeliveryInAddressOrOffice}
                  checked={deliveryInAddressOrOffice === 'deliveryInAddress'}
                  annotationError={
                    !deliverySelected.officeAddress && deliveryInAddressOrOfficeError
                  }
                >
                  <Input
                    value={receiverAddress}
                    placeholder="Escribir dirección"
                    onChange={setReceiverAddress}
                    onFocus={() => setDeliveryInAddressOrOffice('deliveryInAddress')}
                    annotationError={receiverAddressError}
                  />
                </RadioButton>
              )}
              {deliverySelected.officeAddress && (
                <RadioButton
                  name="address-receiver"
                  value="deliveryInOffice"
                  onChange={setDeliveryInAddressOrOffice}
                  checked={deliveryInAddressOrOffice === 'deliveryInOffice'}
                  annotationError={deliveryInAddressOrOfficeError}
                >
                  <div className="address-receiver">
                    <Typography
                      type="value"
                      text={`Entrega en punto de la ${typeDelivery[countryCode]}`}
                    />
                    <Typography
                      type="value"
                      text={`(${deliverySelected.officeAddress})`}
                    />
                  </div>
                </RadioButton>
              )}
            </div>
          </div>
          <div className="content-top-bottom-or-side__container__second-body-accordion__last-details">
            <Input
              label="Correo"
              value={receiverEmail}
              type="email"
              onChange={setReceiverEmail}
              annotationError={receiverEmailError}
            />
            <Input
              label="Celular"
              value={receiverCellPhone}
              type="number-text"
              onChange={setReceiverCellPhone}
              annotationError={receiverCellPhoneError}
              iconLeft={IconColombia}
              childrenRigth={(
                <Tooltip
                  overlayTitle="Notificaciones Whatsapp"
                  overlayText="Por whatsapp podrías recibir notificaciones para facilitar tu proceso de envíos."
                  startingPosition="bottom-rigth"
                  iconTooltip={IconWhatsapp}
                />
              )}
            />
            {deliveryInAddressOrOffice === 'deliveryInOffice' && (
              <>
                <Input
                  label="Número de identificación destinatario"
                  value={receiverIdentificationNumber}
                  type="text"
                  onChange={setReceiverIdentificationNumber}
                  annotationError={receiverIdentificationNumberError}
                />
                <Typography
                  type="value"
                  className="document-info__receiver-details"
                  text="Recuérdale a tu destinatario presentar su documento en físico para la entrega de su envío."
                />
              </>
            )}
          </div>
        </div>
        {!detailsFromQuoteMultipleShippings && (
          <div className="container__second-body-accordion__last-details bottom">
            <Button
              text="Continuar"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => next()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ReceiverDetails.propTypes = {
  heightType: PropTypes.string,
  next: PropTypes.func.isRequired,
  receiverName: PropTypes.string,
  setReceiverName: PropTypes.func.isRequired,
  receiverSurname: PropTypes.string,
  setReceiverSurname: PropTypes.func.isRequired,
  receiverIdentificationNumber: PropTypes.string,
  setReceiverIdentificationNumber: PropTypes.func.isRequired,
  receiverEmail: PropTypes.string,
  setReceiverEmail: PropTypes.func.isRequired,
  receiverCellPhone: PropTypes.string,
  setReceiverCellPhone: PropTypes.func.isRequired,
  receiverAddress: PropTypes.string,
  setReceiverAddress: PropTypes.func.isRequired,
  deliveryInAddressOrOffice: PropTypes.string,
  setDeliveryInAddressOrOffice: PropTypes.func.isRequired,
  deliverySelected: PropTypes.any.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  receiverNameError: PropTypes.string,
  receiverSurnameError: PropTypes.string,
  receiverIdentificationNumberError: PropTypes.string,
  receiverEmailError: PropTypes.string,
  receiverCellPhoneError: PropTypes.string,
  receiverAddressError: PropTypes.string,
  deliveryInAddressOrOfficeError: PropTypes.string,
  detailsFromQuoteMultipleShippings: PropTypes.bool.isRequired,
};

ReceiverDetails.defaultProps = {
  heightType: 'h-50',
  receiverName: null,
  receiverSurname: null,
  receiverIdentificationNumber: null,
  receiverEmail: null,
  receiverCellPhone: null,
  receiverAddress: null,
  deliveryInAddressOrOffice: null,
  receiverNameError: null,
  receiverSurnameError: null,
  receiverIdentificationNumberError: null,
  receiverEmailError: null,
  receiverCellPhoneError: null,
  receiverAddressError: null,
  deliveryInAddressOrOfficeError: null,
};

export default ReceiverDetails;
