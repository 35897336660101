import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../../../components/checkBox';
import './stepsCreateAccount.scss';

const TermsAndConditions = ({ check, handleChangeCheck }) => {
  const openLink = () => {
    window.open(
      'https://mipaquete.com/politicas-privacidad-de-datos-terminos-y-condiciones/',
      'WindowName',
      'noopener',
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      openLink();
    }
  };

  return (
    <CheckBox
      className="termsAndConditionsCreateAccount"
      checked={check}
      onChange={handleChangeCheck}
      text={(
        <span className="terms">
          He leído y acepto
          {' '}
          <span
            role="button"
            tabIndex={0}
            className="termsAndConditionsCreateAccount__button"
            onClick={openLink}
            onKeyDown={handleKeyDown}
          >
            Términos y Condiciones,
            {' '}
          </span>
          la
          <span
            role="button"
            tabIndex={0}
            className="termsAndConditionsCreateAccount__button"
            onClick={openLink}
            onKeyDown={handleKeyDown}
          >
            {' '}
            Política de tratamiento de datos.
          </span>
        </span>
      )}
    />
  );
};

TermsAndConditions.propTypes = {
  check: PropTypes.bool.isRequired,
  handleChangeCheck: PropTypes.func.isRequired,
};

export default TermsAndConditions;
