/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useFetchIpData, useValidationCountry } from '../../../../../utils/validationCountry';
import { toCurrencyCop } from '../../../../../utils/strings';
import { humanizationOfTimeDuration } from '../../../../../utils/generals';
import { finalPrice, collectionServiceValue } from '../../../../../utils/prices';
import { findObjectInArray } from '../../../../../utils/filters';

import Button from '../../../../../components/button';
import Typography from '../../../../../components/typography';
import RadioButton from '../../../../../components/radioButton';

import '../stepsQuoteShipping.scss';

const PaymentShipping = ({
  next,
  paymentMethod,
  setPaymentMethod,
  deliverySelected,
  user,
  isMobileDevice,
  rechargeBalance,
  generatingSending,
  collectionService,
  addShippingToCollectionValue,
  saleValue,
  deliveryCompaniesConfig,
}) => {
  const finalPriceNumber = finalPrice(
    deliverySelected.shippingCost,
    collectionService,
    addShippingToCollectionValue,
    deliverySelected.collectionCommissionWithRate,
    deliverySelected.collectionCommissionWithOutRate,
  );

  const collectionValueNumber = collectionServiceValue(
    saleValue,
    deliverySelected.shippingCost,
    collectionService,
    addShippingToCollectionValue,
    deliverySelected.collectionCommissionWithRate,
    deliverySelected.collectionCommissionWithOutRate,
  );

  const thePaymentFromCollecionValueIsPosible = () => (
    deliveryCompaniesConfig ? (
      findObjectInArray(deliverySelected.deliveryCompanyId, deliveryCompaniesConfig, 'deliveryCompany')?.paymentFromCollecionValue
    ) : (
      false
    )
  );

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div
      className={`body__payment-shipping ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
    >
      <div
        className="second-body__payment-shipping"
      >
        <div
          className="container__second-body__payment-shipping"
        >
          <div className="content-top-bottom-or-side__container__second-body__payment-shipping">
            <div className="delivery-company-info__payment-shipping">
              <div className="flexbox-delivery-data">
                <img src={deliverySelected.deliveryCompanyImgUrl} alt="" />
                <div className="text-delivery-data">
                  <Typography
                    type="field"
                    className="delivery-name__delivery-data"
                    text={deliverySelected.deliveryCompanyName}
                  />
                  <Typography
                    type="value"
                    className="shipping-time__delivery-data"
                    text={humanizationOfTimeDuration(deliverySelected.shippingTime)}
                  />
                </div>
              </div>
              <div className="flexbox-column-delivery-data">
                <Typography
                  type="field"
                  className="delivery-name__delivery-data"
                  text="Valor total:"
                />
                <Typography
                  type="title"
                  text={toCurrencyCop(user, Math.floor(finalPriceNumber))}
                />
              </div>
            </div>
            <div className="balance-info__payment-shipping">
              <div className="current-balance">
                <Typography type="field" text="Tu saldo actual:" />
                <Typography
                  type="subtitle"
                  className={`current-balance-value ${user.cash < finalPriceNumber ? 'warning-cash' : ''}`}
                  text={toCurrencyCop(user, user.cash)}
                />
              </div>
              <Button
                text="Recargar saldo"
                type="secondary"
                onClick={() => rechargeBalance()}
              />
              <Typography
                type="value"
                className="min-recharge-info"
                text="La recarga mínima es de $5000"
              />
            </div>
          </div>
          <div className="content-top-bottom-or-side__container__second-body__payment-shipping">
            <div className="container-payment-method__sender-details">
              <Typography
                type="subtitle"
                className="title-payment-method__sender-details"
                text="Método de pago"
              />
              {user.cash >= finalPriceNumber && (
                <RadioButton
                  name="payment-method"
                  text="Quiero pagar con mi saldo disponible"
                  value="availableBalance"
                  onChange={setPaymentMethod}
                  checked={paymentMethod === 'availableBalance'}
                />
              )}
              {(collectionService
              && collectionValueNumber > finalPriceNumber
              && thePaymentFromCollecionValueIsPosible()
              ) && (
                <RadioButton
                  name="payment-method"
                  text="Quiero descontar el costo de mi envío del valor a recaudar"
                  value="deductFromCollection"
                  onChange={setPaymentMethod}
                  checked={paymentMethod === 'deductFromCollection'}
                />
              )}
              <Button
                text="Pagar ahora"
                type={isCountryValid ? 'primary-mx' : 'primary'}
                loading={generatingSending}
                onClick={() => next()}
                disabled={!paymentMethod}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentShipping.propTypes = {
  next: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
  setPaymentMethod: PropTypes.func.isRequired,
  deliverySelected: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  generatingSending: PropTypes.bool.isRequired,
  rechargeBalance: PropTypes.func.isRequired,
  collectionService: PropTypes.bool.isRequired,
  addShippingToCollectionValue: PropTypes.bool.isRequired,
  saleValue: PropTypes.number,
  deliveryCompaniesConfig: PropTypes.arrayOf(PropTypes.any),
};

PaymentShipping.defaultProps = {
  paymentMethod: null,
  saleValue: null,
  deliveryCompaniesConfig: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  deliveryCompaniesConfig: state.globalData.deliveryCompaniesConfig,
});

export default connect(mapStateToProps)(PaymentShipping);
