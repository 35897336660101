import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import Tooltip from '../../../../components/tooltip';
import Typography from '../../../../components/typography';
import ShipmentsList from '../shipmentList';

import { dateFixed } from '../common/times';
import { transformData } from './transformData';
import { collectionPaymentBlog } from '../../../../constants/urls';
import { hideAccountNumbers, bankNameFormat, toCurrencyCop } from '../../../../utils/strings';

import IconGray from '../../../../assets/images/tooltip-gray.svg';
import IconHover from '../../../../assets/images/Tooltip.svg';
import IconArrowBack from '../../../../assets/images/icon-arrow-back-color-primary.svg';

import './nextCollectionDetail.scss';

const NextCollectionDetail = ({ handleTicketDetail, ticket, deliveryCompanies }) => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state?.auth?.user || null);
  return (
    <div id="next-collection-detail">
      <div className="top">
        <div className="left">
          <img src={IconArrowBack} alt="icon" className="arrow-back" onClick={() => handleTicketDetail(null)} />
          {!isMobileDevice && (<Typography type="superTitle-italic" text="Tu próximo recaudo" className="title" />)}
        </div>
        {isMobileDevice && (
          <>
            <div className="center">
              <Typography type="superTitle-italic" text="Tu próximo recaudo" className="title" />
            </div>
            <div className="rigth" />
          </>
        )}
      </div>

      <div className="line" />

      <Typography
        type="paragraph"
        text="Estos son los envíos entregados o devueltos hasta este momento, que posiblemente recibirás en el siguiente pago de tus recaudos, siempre y cuando hayan sido igualmente pagados por parte de la transportadora a Mi Paquete."
        className="first-subtitle"
      />

      <div className="header-container">
        <div className="scroll-container">
          <div className="columns">
            <div className="row">
              <Typography type="field-title-dark" text="Total de guías:" />
              <Typography type="header-quote-gray" text={ticket.totalGuides} />
            </div>
            <div className="row">
              <Typography type="field-title-dark" text="Entregadas:" />
              <Typography type="header-quote-gray" text={ticket.deliveredGuides} />
            </div>
            <div className="row">
              <Typography type="field-title-dark" text="Devueltas:" />
              <Typography type="header-quote-gray" text={ticket.returnedGuides} />
            </div>
          </div>
          <div className="columns">
            <div className="row">
              <Typography type="field-title-dark" text="Fecha de pago:" />
              <Typography type="header-quote-gray" text={dateFixed(ticket.courtDate)} />
              <Tooltip
                overlayTextElement={(
                  <div className="text-overlay">
                    <p>
                      Ten presente que esta fecha puede variar
                      <a
                        href={collectionPaymentBlog}
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                      >
                        Conoce más sobre las fechas de transferencia de recaudos.
                      </a>
                    </p>
                  </div>
                )}
                startingPosition="top-rigth"
                iconTooltipHover={IconHover}
                iconTooltip={IconGray}
              />
            </div>
            <div className="row">
              <Typography type="field-title-dark" text="Transferencia a cuenta:" />
              <Typography type="header-quote-gray" text={hideAccountNumbers(ticket.userAccountBank)} />
            </div>
            <div className="row">
              <Typography type="field-title-dark" text="Banco:" />
              <Typography type="header-quote-gray" text={bankNameFormat(ticket.userBank)} />
              <Tooltip
                overlayTextElement={(
                  <div className="text-overlay">
                    <p>
                      Se cobra el valor de la transferencia
                      dependiendo del banco.
                      <a
                        href={collectionPaymentBlog}
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                      >
                        Conoce más aquí
                      </a>
                    </p>
                  </div>
                )}
                startingPosition="bottom-rigth"
                iconTooltipHover={IconHover}
                iconTooltip={IconGray}
              />
            </div>
          </div>
          <div className="columns">
            <div className="row">
              <Typography type="field-title-dark" text="Valor total recaudado:" />
              <Typography type="header-quote-gray" text={toCurrencyCop(user, ticket.totalValueCollection)} />
            </div>
            <div className="row">
              <Typography type="field-title-dark" text="Valor a descontar:" />
              <Typography type="header-quote-gray" text={toCurrencyCop(user, ticket.totalValueDiscount)} />
            </div>
          </div>
        </div>
      </div>

      <Typography type="field" text="Guías hasta el momento" className="second-subtitle" />

      {ticket.sendings && (
        <div className="wrapper-next-collection-senging-list">
          <ShipmentsList
            shipmentsData={transformData(ticket.sendings)}
            deliveryCompanies={deliveryCompanies}
            customTotalColumClass="customTotalColumns"
          />
        </div>
      )}
    </div>
  );
};

NextCollectionDetail.propTypes = {
  ticket: PropTypes.shape().isRequired,
  handleTicketDetail: PropTypes.func.isRequired,
  deliveryCompanies: PropTypes.shape().isRequired,
};

export default NextCollectionDetail;
