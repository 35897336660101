/* eslint-disable default-param-last */
import { IS_MOBILE_DEVICE, IS_NOT_MOBILE_DEVICE } from '../actions/type.actions';

const initialState = {
  isMobileDevice: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_MOBILE_DEVICE: {
      return {
        isMobileDevice: true,
      };
    }
    case IS_NOT_MOBILE_DEVICE: {
      return {
        isMobileDevice: false,
      };
    }
    default: {
      return state;
    }
  }
};
