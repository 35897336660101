import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../../../../components/typography';

import mexicoFlag from '../../../../assets/images/mexico-square.svg';
import colombiaFlag from '../../../../assets/images/colombia.svg';

import './basicInfoUser.scss';

const IconUser = ({ name, countryCode }) => (
  <div className={`${countryCode === '170' ? 'avatarIconProfile' : 'avatarIconProfileMX'}`}>
    <h1>{name.charAt(0)}</h1>
  </div>
);

const flag = {
  170: colombiaFlag,
  484: mexicoFlag,
};

const BasicInfoUser = ({ user, isMobileDevice }) => (
  <div id="content-profile" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
    <div className="adjust-data">
      {user.image && (
      <div id="user-image__content__button__menu">
        <img src={user.image} alt="" />
      </div>
      )}
      {!user.image && (<IconUser name={user.name} countryCode={user.countryCode} />)}
      <div className="container-text-personal-info">
        <Typography
          text={user.businessName ?? ''}
          type="value"
          className="title-info"
        />
        <Typography
          text={user.email}
          type="value"
          className="description-info"
        />
        <div className="country-flag">
          <img alt="countryFlag" src={flag[user.countryCode]} />
          <Typography
            text={user.cellPhone ?? ''}
            type="value"
            className="description-info"
          />
        </div>
      </div>
    </div>
  </div>
);

BasicInfoUser.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    countryCode: PropTypes.string,
    image: PropTypes.string,
    cellPhone: PropTypes.string,
    businessName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
};

IconUser.propTypes = {
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
};
export default BasicInfoUser;
