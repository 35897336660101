import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { compose } from 'redux';

import { useValidationCountry } from '../../utils/validationCountry';

import './balanceErrorInNotifications.scss';
import Typography from '../typography';
import Modal from '../modal';
import Button from '../button';

const BalanceErrorInNotifications = ({ history, onChangeBack, countryCode }) => {
  const isCountryValid = useValidationCountry(countryCode);

  return (
    <Modal className="container-modal-balance-error">
      <Typography
        text="¡Ups! tu saldo es insuficiente"
        type="title"
        className="title-modal-balance-error"
      />
      <Typography
        text="El saldo no alcanza para pagar las notificaciones. Puedes recargar tu saldo o desactivar las notificaciones para generar el envío."
        type="paragraph"
        className="paragraph-modal-balance-error"
      />
      <div className="container-bottom-modal-balance-error">
        <Button
          text="Volver"
          type="secondary"
          className="button-balance-error"
          onClick={onChangeBack}
        />
        <Button
          text="Recargar mi saldo"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          className="button-balance-error"
          onClick={() => history.push('/recargar-saldo')}
        />
      </div>
    </Modal>
  );
};

BalanceErrorInNotifications.propTypes = {
  onChangeBack: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  countryCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(BalanceErrorInNotifications);
