import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const ImageRedirectProfile = ({
  src, url,
}) => {
  const navigate = useNavigate();
  return (
    <button
      className="button-arrow-left"
      type="button"
      onClick={() => navigate(url)}
    >
      <img
        src={src}
        alt="back"
      />
    </button>
  );
};

ImageRedirectProfile.propTypes = {
  src: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

ImageRedirectProfile.defaultProps = {

};

export default ImageRedirectProfile;
