/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './checkBoxCustom.scss';

const CheckBox = ({
  onChange,
  disabled,
  checked,
  text,
  clickOnText,
  selectedColor,
}) => {
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);

  return (
    <>
      <label className="container-checkbox-and-label">
        <span
          className={`text-checkbox ${clickOnText ? 'color-blue' : ''}`}
          onClick={(e) => {
            if (clickOnText) {
              e.preventDefault();
              e.stopPropagation();
              clickOnText();
            }
          }}
        >
          {text}
        </span>
        <div className="container-checkbox">
          <div className={`background-checkbox ${checkBoxChecked ? 'checked' : 'no-checked'}`} />
          <input
            onChange={(e) => onChange(e.target.checked)}
            onClick={() => setCheckBoxChecked(!checkBoxChecked)}
            type="checkbox"
            disabled={disabled}
            checked={checked}
          />
          <span className={`checkmark ${selectedColor}`} />
        </div>
      </label>
    </>
  );
};

CheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  clickOnText: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  text: PropTypes.string,
  selectedColor: PropTypes.string,
};

CheckBox.defaultProps = {
  disabled: false,
  text: '',
  clickOnText: null,
  selectedColor: '',
};

export default CheckBox;
