import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Modal from '../modal';

import './successModal.scss';

const SuccessModal = ({ text, onClose }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <Modal>
      <div ref={modalRef} className="content-modal">
        {text}
      </div>
    </Modal>
  );
};

SuccessModal.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

SuccessModal.defaultProps = {

};

export default SuccessModal;
