export const checkedItems = (dataChecked) => dataChecked?.every((item) => item.checked);

export const handleAllCheckbox = (data, setState) => {
  const newData = data?.map((item) => ({
    ...item,
    checked: !item.checked,
  }));
  return setState(newData);
};

export const removeFilter = (data, setState) => {
  const newData = data?.map((item) => ({
    ...item,
    checked: false,
  }));
  return setState(newData);
};

export const handleChangeCheckbox = (id, key, data, setState) => {
  const newData = data?.map((item) => ({
    ...item,
    checked: id === item[key] ? !item.checked : item.checked,
  }));
  return setState(newData);
};

export const handleChangeTicketInfo = (id, data, setState) => {
  const selectItem = data?.find((item) => item._id === id);
  return setState(selectItem);
};

export const dataMethodFiltered = (data, setData, key) => {
  const dataFiltered = data?.filter((item) => item.checked === true)?.map((it) => it[key]);
  return setData(dataFiltered);
};

export const initialFalseCheck = (array, setData) => {
  const newData = array?.map((item) => ({
    ...item,
    checked: false,
  }));
  return setData(newData);
};
