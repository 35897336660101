const queryFilters = (
  dateInitSelected,
  dateEndSelected,
  deliveryCompaniesSelectedId,
  dataDeliveryCompany,
  statesSelected,
  dataStatesReport,
  lastItem,
) => (
  {
    ...(dateInitSelected || dateEndSelected)
    && {
      paidDate: {
        ...dateInitSelected && { init: dateInitSelected },
        ...dateEndSelected && { end: dateEndSelected },
      },
    },
    ...deliveryCompaniesSelectedId(dataDeliveryCompany)?.length > 0 && {
      deliveryCompaniesId: deliveryCompaniesSelectedId(dataDeliveryCompany),
    },
    ...statesSelected(dataStatesReport)?.length > 0
    && { status: statesSelected(dataStatesReport) },
    ...lastItem && { lastItem },
  }
);

export default queryFilters;
