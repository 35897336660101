import React from 'react';
import PropTypes from 'prop-types';
import './headerCards.scss';

const HeaderCards = ({
  iconLeft, data, colorData, textRight, tooltip,
  iconLeftSize, action,
}) => {
  const handleClick = () => {
    if (action) {
      action();
    }
  };

  const containerClass = `main-container ${iconLeftSize === 'icon-default' ? 'default-cursor' : ''}`;

  return (
    <button
      type="button"
      className={containerClass}
      onClick={handleClick}
    >
      <img alt="img" className={iconLeftSize} src={iconLeft} />
      <div className={`${colorData}`}>{data}</div>
      {textRight && <div className="text-right">{textRight}</div>}
      {tooltip && <span className="tooltip">{tooltip}</span>}
    </button>
  );
};

HeaderCards.propTypes = {
  iconLeft: PropTypes.string.isRequired,
  iconLeftSize: PropTypes.oneOf(['orange', 'blue', 'icon-default']),
  data: PropTypes.string.isRequired,
  colorData: PropTypes.oneOf(['default', 'cash']),
  textRight: PropTypes.string,
  tooltip: PropTypes.string,
  action: PropTypes.func,
};

HeaderCards.defaultProps = {
  colorData: 'default',
  textRight: null,
  tooltip: null,
  action: null,
  iconLeftSize: 'orange',
};

export default HeaderCards;
