/* eslint-disable max-len */
import { newSortDeliveries, sortDeliveries } from 'constants/quoteShipping';

import { discountCalculateValue } from 'utils/prices';
import { order, shippingValueSort } from './deliveryListOrder';
import { validationValue } from './strings';

export const getMethodPayment = (labelMethod) => {
  if (labelMethod === 'availableBalance') return 101;
  if (labelMethod === 'availableCredit') return 105;
  if (labelMethod === 'deductFromCollection') return 102;
  return null;
};

export const sortListDelivery = (list, criterion, direction) => {
  if (direction === 'asc') {
    return order(list, criterion, 'asc');
  }
  if (direction === 'desc') {
    return order(list, criterion, 'desc');
  }
  if (criterion === 'shippingValue' && direction === 'shippingValueSortAsc') {
    return shippingValueSort(list, 'asc');
  }
  return list;
};

export const selectTitleToStep = (step) => {
  switch (step) {
    case 1: return ('Ruta de envío');
    case 2: return ('¿Cuánto mide tu envío?');
    case 3: return ('Así vamos hasta ahora');
    case 4: return ('Últimos detalles');
    default: return ('');
  }
};

export const validationsFilledInputs = (fields, errorsList) => {
  const emailValid = validationValue(fields.emailForBilling, 'email').correct;
  const {
    name, tradeName, codeVerification, locationCode, ...otherFields
  } = fields;

  const allFilled = Object.values(otherFields)?.every((value) => value) && !errorsList?.length;

  const juridicaTaxRegimeRequired = fields.personType === 'Persona Jurídica' && !(tradeName && codeVerification);

  const naturalBusinessNameRequired = fields.personType === 'Persona Natural' && !name;

  const isValidationRequired = allFilled && (juridicaTaxRegimeRequired || naturalBusinessNameRequired);

  if (emailValid && isValidationRequired) {
    return false;
  }

  if (isValidationRequired) {
    return false;
  }

  return allFilled;
};

export const colorButton = (code) => (code === '170' ? 'primary' : 'primary-mx');

export const limitCharacters = (name, value, maxValue, updateFormValues) => {
  const limitValue = value.toString().slice(0, maxValue);
  const numericValue = parseInt(limitValue, 10);
  return updateFormValues(name, numericValue);
};

export const getNewSortDeliveries = (flag, orderDeliveriesList) => {
  if (flag) {
    return newSortDeliveries[orderDeliveriesList] || [null, null];
  }
  return sortDeliveries[orderDeliveriesList] || [null, null];
};

export const valueChangeCommissionRate = (delivery, addShippingToCollectionValue) => {
  const collectionCommissionRate = addShippingToCollectionValue
    ? delivery.collectionCommissionWithRate
    : delivery.collectionCommissionWithOutRate;

  const realCommissionValue = addShippingToCollectionValue
    ? delivery.realCollectionCommissionWithRateValue
    : delivery.realCollectionCommissionWithoutRateValue;

  return {
    collectionCommissionRate,
    realCommissionValue,
  };
};
export const dataDeliveryPrices = (delivery, customerPayShipping) => {
  const data = [];
  const commissionData = valueChangeCommissionRate(delivery, customerPayShipping);

  const dataShipping = [
    {
      title: 'Flete',
      value: (delivery.shippingRealCost || delivery.shippingCost) - delivery.insuranceValue,
    },
    delivery.shippingRealCost && {
      title: 'Dcto en flete',
      value: discountCalculateValue(
        delivery.shippingCost,
        delivery.shippingRealCost,
        true,
      ),
      isDiscount: true,
    },
    {
      title: 'Seguro',
      value: delivery.insuranceValue,
    },
    {
      title: '4*1000 incluido',
      value: 0,
    },
  ];

  const dataCommissionService = [
    commissionData.realCommissionValue && {
      title: 'Comisión de recaudo',
      value: commissionData.realCommissionValue,
    },
    delivery.realCollectionCommissionWithoutRateValue && {
      title: 'Dcto en comisión',
      value: discountCalculateValue(
        commissionData.realCommissionValue,
        commissionData.collectionCommissionRate,
        true,
      ),
      isDiscount: true,
    },
  ];

  if (dataShipping.length > 0) {
    data.push({ dataShipping });
  }

  if (dataCommissionService.length > 0) {
    data.push({ dataCommissionService });
  }

  return data;
};
