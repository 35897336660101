/* eslint-disable import/order */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import {
  checkBankInformation,
  collectionServiceValidation,
  returnNumber,
  routeExistenceValidationFunction,
  stepOneValidation,
  stepTwoValidation,
} from 'utils/validation/validationsQuoteShipping';
import { findObjectInArray } from 'utils/filters';

import { finalPrice, collectionServiceValue } from 'utils/prices';
import userAddressSelected from 'utils/users/address';
import {
  getMethodPayment,
  getNewSortDeliveries,
  selectTitleToStep,
  sortListDelivery,
} from 'utils/quoteShipping';
import { userName } from 'utils/strings';
import { useValidationCountry } from 'utils/validationCountry';
import { searchBusinessFlag } from 'utils/businessFlag';
import { newOrderListDeliveries, orderListDeliveries } from 'constants/quoteShipping';
import { typeDelivery } from 'constants/general';
import { businessFlags } from 'config/config.front.json';

import Select from 'components/select';
import Button from 'components/button';
import InfoDataList from 'components/infoDataList';
import Modal from 'components/modal';
import BankData from 'components/bankData';
import Login from 'components/login';
import Typography from 'components/typography';
import StepProgressBar from 'components/stepProgressBar';
import ModalShippingType from 'components/modalShippingType';
import HeaderQuoteShipping from 'components/HeaderQuoteShipping';
import ModalChildren from 'components/modalChildren';
import ModalErrorUbication from 'components/modalErrorUbication';
import StepOneCompletingData from 'components/completingData/quoteCompletingSteps/stepOne/oneStep';
import StepTwoCompletingData from 'components/completingData/quoteCompletingSteps/stepTwo/stepTwo';

import { getUserService, updateUser } from 'services/user';
import { setCountryAction } from 'store/actions/country.actions';
import { UpdateUserAction } from 'store/actions/auth.actions';
import { quoteShippingService } from 'services/quoteShipping';
import { createSendingService, getSendingsService } from 'services/sendings';
import { useShippingSteps } from 'hooks/useShippingSteps';
import PaymentShipping from './stepsQuoteShipping/paymentShipping/paymentShipping';

import StepOne from './stepsQuoteShipping/stepOne/stepOne';
import StepTwo from './stepsQuoteShipping/stepTwo/stepTwo';
import StepThree from './stepsQuoteShipping/stepThree/stepThree';

import StepOneShipping from './stepsInfoShipping/stepOne/stepOne';
import StepTwoShipping from './stepsInfoShipping/stepTwo/stepTwo';
import StepThreeShipping from './stepsInfoShipping/stepThree/stepThree';

import DeliveriesList from './stepsQuoteShipping/deliveriesList/deliveriesList';
import DeliveryCardToList from './newStepsQuoteShipping/stepThree/right';
import NewStepThree from './newStepsQuoteShipping/stepThree';
import StepFour from './newStepsQuoteShipping/stepFour';
import CashDeliveryService from './stepsQuoteShipping/stepThree/cashDeliveryService';

import QuoteMultipleShippings from '../quoteMultipleShippings';
import StepOneExpressShipping from './stepsQuoteShipping/stepOneExpressShipping/stepOneExpressShipping';

import IconOrder from 'assets/images/Order.svg';
import IconClose from 'assets/images/icon-close-color-primary.svg';
import ArrowBackIcon, { ReactComponent as ArrowBack } from 'assets/images/icon-arrow-back-color-primary.svg';

import './quoteShipping.scss';

const QuoteShipping = ({
  setFullContainerQuoteShipping,
  setDeliveryListSizeChange,
  expressMobile,
  onChangeTypeSection,
}) => {
  const navigate = useNavigate();
  const history = {
    push: (url) => (navigate(url)),
    goBack: () => (navigate(-1)),
  };

  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const quoteShippingParam = queryParams.get('quoteShipping');
  const flagViewDeliveryListPrices = searchBusinessFlag(businessFlags, 'newViewDeliveryListPrices')?.enabled;

  const user = useSelector((state) => state?.auth?.user || null);
  const countryCode = useSelector((state) => state?.country || null);
  const locations = useSelector((state) => state?.globalData?.locations || []);
  const isMobileDevice = useSelector((state) => state?.mediaQuery?.isMobileDevice);
  const isCountryValid = useValidationCountry('170');

  const [step, setStep] = useState(1);
  const [currentStepShipping, setCurrentStepShipping] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);
  const [errorsList, setErrorsList] = useState([]);
  const [showOrder, setShowOrder] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState({});
  const [lastCountryCode, setLastCountryCode] = useState('');
  const [showModalErrorUbication, setShowModalErrorUbication] = useState(false);

  const [showDataEntryViews, setShowDataEntryViews] = useState(true);
  const [showCollectionService, setShowCollectionService] = useState(false);
  const [showModalCollectionService, setShowModalCollectionService] = useState(false);
  const [showDeliveriesList, setShowDeliveriesList] = useState(false);
  const [showAnnotationsOfDelivery, setShowAnnotationsOfDelivery] = useState(false);
  const [showLastDetails, setShowLastDetails] = useState(false);
  const [showPaymentShipping, setShowPaymentShipping] = useState(false);
  const [showShippingGenerated, setShowShippingGenerated] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showNoDataBank, setShowNoDataBank] = useState(false);
  const [showBankData, setShowBankData] = useState(false);
  const [showSendingSave, setShowSendingSave] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [modalErrorText, setModalErrorText] = useState(null);

  const [shippingData, setShippingData] = useState({});
  const [errorList, setErrorList] = useState([]);

  const [saleValue, setSaleValue] = useState(null);
  const [addShippingToCollectionValue, setAddShippingToCollectionValue] = useState(false);
  const [collectionService, setCollectionService] = useState(false);
  const [deliverySelected, setDeliverySelected] = useState(null);

  const [selectButton, setSelectButton] = useState('Envío');
  const [isActive, setIsActive] = useState(true);
  const [isActiveExpress, setIsActiveExpress] = useState(false);
  const [isActiveMassive, setIsActiveMassive] = useState(false);

  const [senderInfo, setSenderInfo] = useState({
    senderName: user?.name,
    senderLastName: user?.surname,
    senderEmail: user?.email,
    senderCellPhone: user?.cellPhone,
    senderIdentificationNumber: user?.documentNumber,
    senderIdentificationType: user?.documentType,
    senderAddress: shippingData?.expressAddress || user?.address,
    prefix: user?.prefix,
  });
  const [initExpressAddress, setInitExpressAddress] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState(null);

  const [deliveriesList, setDeliveriesList] = useState([]);
  const [loadingDeliveriesList, setLoadingDeliveriesList] = useState(true);
  const [orderDeliveriesList, setOrderDeliveriesList] = useState(flagViewDeliveryListPrices ? 'lowestToHighest' : 'deliveryTime');

  const [generatingSending, setGeneratingSending] = useState(false);
  const [mpCode, setMpCode] = useState(null);
  const [notificationsToSendings, setNotificationsToSendings] = useState(null);

  const existenceUserAgency = user?.agency ? user?.agency : null;
  const prevCountryCode = useRef(countryCode);

  const {
    stepsShipping,
    noDataCompleting,
    updateShippingSteps,
    setStepsShipping,
  } = useShippingSteps(countryCode, user);

  useEffect(async () => {
    if (quoteShippingParam && locations) {
      const quoteShipping = JSON.parse(quoteShippingParam);
      const temporalShippingData = JSON.parse(JSON.stringify(shippingData));
      let temporalErrorList = errorList.slice();
      let stepQueryParams = 1;
      let advanceFlag = false;
      const unitOfMeasurement = ['Kg', 'g'].includes(quoteShipping.unitOfMeasurement)
        ? quoteShipping.unitOfMeasurement : 'Kg';

      const coverage = stepOneValidation({
        errorList: temporalErrorList,
        setErrorList: (returnedList) => { temporalErrorList = returnedList; },
        origin: quoteShipping.origin || '',
        destiny: quoteShipping.destiny || '',
        locations,
      });

      temporalShippingData.origin = quoteShipping.origin || '';
      temporalShippingData.originCode = coverage.originCode || '';
      temporalShippingData.destiny = quoteShipping.destiny || '';
      temporalShippingData.destinyCode = coverage.destinyCode || '';

      if (coverage.originCode && coverage.destinyCode) {
        await routeExistenceValidationFunction(
          coverage.originCode, coverage.destinyCode,
          () => { advanceFlag = true; },
        );
        if (advanceFlag) stepQueryParams = 2;
      }

      stepTwoValidation({
        errorList: temporalErrorList,
        setErrorList: (returnedList) => { temporalErrorList = returnedList; },
        height: returnNumber(quoteShipping.height),
        width: returnNumber(quoteShipping.width),
        length: returnNumber(quoteShipping.length),
        weight: returnNumber(quoteShipping.weight),
        unitOfMeasurement,
        quantity: returnNumber(quoteShipping.quantity),
        declaredValue: returnNumber(quoteShipping.declaredValue),
      });

      temporalShippingData.height = quoteShipping.height || '';
      temporalShippingData.width = quoteShipping.width || '';
      temporalShippingData.length = quoteShipping.length || '';
      temporalShippingData.weight = quoteShipping.weight || '';
      temporalShippingData.unitOfMeasurement = unitOfMeasurement;
      temporalShippingData.quantity = quoteShipping.quantity || '';
      temporalShippingData.declaredValue = quoteShipping.declaredValue || '';

      if (stepQueryParams === 2 && temporalErrorList.length === 0) stepQueryParams = 2;

      setErrorList(temporalErrorList);
      setShippingData(temporalShippingData);
      setStep(stepQueryParams);
    }
  }, [locations]);

  useEffect(() => {
    const temporalDeliveriesList = deliveriesList.slice();
    const [criterion, direction] = getNewSortDeliveries(
      flagViewDeliveryListPrices,
      orderDeliveriesList);
    const list = sortListDelivery(temporalDeliveriesList, criterion, direction);
    setDeliveriesList(list);
    setShowOrder(!showOrder);
  }, [orderDeliveriesList]);

  useEffect(() => {
    if (deliverySelected) {
      setShowAnnotationsOfDelivery(true);
    }
  }, [deliverySelected]);

  const continueToLastDetails = () => {
    if (!user) {
      setShowLogin(true);
    } else if (user && collectionService && checkBankInformation(user.accountBank)) {
      setShowNoDataBank(true);
    } else {
      if (setFullContainerQuoteShipping) setFullContainerQuoteShipping(true);
      setShowLogin(false);
      setShowNoDataBank(false);
      setShowDeliveriesList(false);
      setShowLastDetails(true);
    }
  };

  const getLastCountryCode = () => {
    prevCountryCode.current = lastCountryCode;
    setLastCountryCode(prevCountryCode.current);
  };

  useEffect(() => {
    if (user) {
      if (!isMobileDevice) setDeliveryListSizeChange(false);
    }
    updateShippingSteps();
  }, [countryCode, user]);

  useEffect(() => {
    if (!user && deliverySelected) {
      setShowLogin(true);
      setShowAnnotationsOfDelivery(true);
    }
  }, [deliverySelected]);

  useEffect(() => {
    if (showAnnotationsOfDelivery && user?._id) {
      setShowLogin(false);
      dispatch(setCountryAction(user?.countryCode));
      getLastCountryCode();
    }
  }, [showAnnotationsOfDelivery, user, countryCode]);

  const quoteShippingFunction = async (data, paramCollectionService) => {
    try {
      setLoadingDeliveriesList(true);
      const response = await quoteShippingService(
        countryCode,
        data.originCode,
        countryCode,
        data.destinyCode,
        parseInt(data.height, 10),
        parseInt(data.width, 10),
        parseInt(data.length, 10),
        parseInt(
          data.unitOfMeasurement === 'g'
            ? Math.ceil(data.weight / 1000)
            : data.weight, 10,
        ),
        parseInt(data.quantity, 10),
        countryCode === '170' ? parseInt(data.declaredValue, 10) : parseFloat((data.declaredValue.replace(/[$,]/g, ''))),
        parseInt(paramCollectionService ? saleValue : 0, 10),
        data.interCode,
      );

      if (response) {
        const deliveriesListOrganized = response.sort((a, b) => {
          if (a.shippingCost > b.shippingCost) return 1;
          if (a.shippingCost < b.shippingCost) return -1;
          return 0;
        });
        setDeliveriesList(deliveriesListOrganized);
        setLoadingDeliveriesList(false);
      }
    } catch {
      swal('Error', 'Lo sentimos!');
      setLoadingDeliveriesList(false);
    }
  };

  const deliveryList = (data) => {
    setCollectionService(false);
    setShowDataEntryViews(false);
    setShowDeliveriesList(true);
    if (!isMobileDevice) setDeliveryListSizeChange(true);
    setShowCollectionService(false);
    quoteShippingFunction(data, false);
    setShowModalCollectionService(false);
    if (setFullContainerQuoteShipping) setFullContainerQuoteShipping(false);
  };

  useEffect(() => {
    if (currentStep === 3 && existenceUserAgency) {
      if (shippingData) {
        deliveryList(shippingData);
      }
    }
    if (selectButton === 'Masivos' || selectButton === 'Express') {
      setCurrentStep(1);
    }
  }, [currentStep, selectButton]);

  const setStepsFunction = (action) => {
    if (action === 'continue' && currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }

    if (action === 'return') {
      if (currentStep === 4) {
        setShowDataEntryViews(true);
        setShowCollectionService(false);
      }
      if (currentStep > 1) {
        setCurrentStep(currentStep - 1);
        if (!isMobileDevice) setDeliveryListSizeChange(false);
      }
    }

    if (action === 'toBeginning') {
      setCurrentStep(1);
      setShowDeliveriesList(false);
    }

    if (action === 'openCollectionService') {
      setShowCollectionService(true);
      setShowDataEntryViews(false);
      setShowModalCollectionService(false);
    }
  };

  const returnFirstStep = () => {
    setStepsFunction('toBeginning');
    setStep(1);
    setShippingData({});
  };

  useEffect(() => {
    getLastCountryCode();
    if (prevCountryCode?.current !== countryCode) {
      returnFirstStep();
    }
  }, [countryCode]);

  const commonContentStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            shippingData={shippingData}
            errorList={errorList}
            setErrorList={setErrorList}
            next={(data) => {
              setShippingData({
                ...shippingData,
                ...data,
                expressAddress: null,
              });
              setStepsFunction('continue');
              setStep(2);
            }}
          />
        );
      case 2:
        return (
          <StepTwo
            shippingData={shippingData}
            errorList={errorList}
            setErrorList={setErrorList}
            next={(data) => {
              setShippingData({
                ...shippingData,
                ...data,
              });
              setStepsFunction('continue');
              if (isMobileDevice) { setStep(3); }
              if (countryCode === '484') {
                deliveryList({ ...shippingData, ...data });
              }
            }}
            setShowModalCollectionService={setShowModalCollectionService}
            isMobileDevice={isMobileDevice}
          />
        );
      case 3:
        return (
          <StepThree
            shippingData={shippingData}
            back={() => {
              setStepsFunction('toBeginning');
              setStep(1);
            }}
            next={() => {
              if (existenceUserAgency) {
                // eslint-disable-next-line no-use-before-define
                quoteShippingFunction(shippingData, false);
                setCollectionService(false);
                setShowDataEntryViews(false);
                setShowDeliveriesList(true);
              } else {
                setStepsFunction('continue');
                setShowModalCollectionService(true); // cambiar al step 2
                setCollectionService(true);
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  const contentStepExpressShipping = () => {
    switch (step) {
      case 1:
        return (
          <StepOneExpressShipping
            shippingData={shippingData}
            errorList={errorList}
            setErrorList={setErrorList}
            next={(origin, originCode, destiny, destinyCode, expressAddress, interCode) => {
              setShippingData({
                ...shippingData,
                origin,
                originCode,
                destiny,
                destinyCode,
                expressAddress,
                interCode,
              });
              setInitExpressAddress(expressAddress);
              setStepsFunction('continue');
              setStep(2);
            }}
          />
        );
      default:
        return commonContentStep();
    }
  };

  const contentStep = () => commonContentStep();

  const generateShipmentFunction = async (generateShipment) => {
    try {
      setGeneratingSending(true);
      updateUser({
        productType: user.productType,
        alternativeDirections:
          user.alternativeDirections,
      }, user._id);
      const response = await createSendingService(
        deliverySelected,
        user,
        senderInfo,
        (!senderInfo.senderAddress
        && user.alternativeDirections
          ? user.alternativeDirections[0].address || user.alternativeDirections[0] || user.address
          : userAddressSelected(user, initExpressAddress, senderInfo.senderAddress)),
        shippingData,
        finalPrice(
          deliverySelected.shippingCost,
          collectionService,
          addShippingToCollectionValue,
          deliverySelected.collectionCommissionWithRate,
          deliverySelected.collectionCommissionWithOutRate,
        ), // sending value
        collectionService,
        getMethodPayment(paymentMethod),
        'N/A',
        'N/A',
        0,
        'N/A',
        0,
        'N/A',
        saleValue || 0,
        collectionServiceValue(
          saleValue,
          deliverySelected.shippingCost,
          collectionService,
          addShippingToCollectionValue,
          deliverySelected.collectionCommissionWithRate,
          deliverySelected.collectionCommissionWithOutRate,
        ),
        null,
        null,
        'página transaccional',
        (notificationsToSendings) || null,
      );
      if (response) {
        if (generateShipment) {
          // Actualizacion de datos del usuario, principalmente cash
          const responseUser = await getUserService(user._id);
          dispatch(UpdateUserAction(responseUser));

          // Verificacion de envios realizados por el usuario anteriormente
          const responseGetSendings = await getSendingsService(user._id, 1);
          if (responseGetSendings?.sendings?.length === 1) {
            history.push('/primer-envio');
          } else {
            setShowLastDetails(false);
            setMpCode(response.mpCode);
            setShowPaymentShipping(false);
            setShowShippingGenerated(true);
          }
        } else {
          swal('sin generación.');
        }
      }
    } catch (e) {
      setGeneratingSending(false);
      if (e.response?.data?.message?.code === 326) {
        setModalErrorText(e.response.data.message.detail || 'error into server');
      } else {
        setShowModalError(true);
        setGeneratingSending(false);
        setModalErrorText('Puedes intentar el envío más tarde.');
      }
    } finally {
      setGeneratingSending(false);
    }
  };

  useEffect(() => {
    if (paymentMethod) generateShipmentFunction(true);
  }, [paymentMethod]);

  const setStepsShippingFunction = (action) => {
    const temporalSteps = stepsShipping.slice();

    if (action === 'continue') {
      if (currentStepShipping < 5) {
        temporalSteps[currentStepShipping - 1].success = true;
        setCurrentStepShipping(currentStepShipping + 1);
      }
    }

    if (action === 'return') {
      if (currentStepShipping > 1) {
        if (temporalSteps[currentStepShipping - 1]) {
          temporalSteps[currentStepShipping - 1].success = false;
        }
        setCurrentStepShipping(currentStepShipping - 1);
      }
    }

    if (action === 'toBeginning') {
      setCurrentStepShipping(1);
    }

    setStepsShipping(temporalSteps);
  };

  const ChangesStepOneShipping = ({
    changes,
    action,
    productDescription,
  }) => {
    if ((userName(user.name, user.surname)) !== changes.nameInTheGuide) {
      const name = changes.nameInTheGuide.split(' ');
      let newSenderName = '';
      let newSenderSurname = '';
      name.map((eachName, index) => {
        if (index < 2) {
          newSenderName = `${newSenderName} ${eachName}`;
        } else if (index > 0) {
          newSenderSurname = `${newSenderSurname} ${eachName}`;
        }
        return null;
      });

      setSenderInfo({
        ...senderInfo,
        senderCellPhone: changes.senderCellPhone || user.cellPhone,
        senderIdentificationNumber: changes.senderIdentificationNumber || user.documentNumber,
        senderIdentificationType: changes.senderIdentificationType || user.documentType,
        senderAddress: changes.senderAddress,
        senderEmail: changes.senderEmail || user.email,
        senderName: newSenderName.trim(),
        senderLastName: newSenderSurname.trim() || '.',
      });
    }

    setShippingData({
      ...changes,
      collectionOrTakeToOffice: changes.collectionOrTakeToOffice || 'collectionInAddress',
      description: productDescription,
    });
    setStepsShippingFunction(action);
  };

  const changesStepTwoShipping = ({
    action,
    formValues,
  }) => {
    setShippingData(formValues);
    setStepsShippingFunction(action);
  };

  const changesStepThreeShipping = ({
    PaymentMethod,
    notifications,
  }) => {
    setPaymentMethod(PaymentMethod);
    setNotificationsToSendings(notifications);
  };

  useEffect(() => {
    if (onChangeTypeSection) onChangeTypeSection(selectButton);
  }, [selectButton]);

  return (
    <>
      {showDataEntryViews && (
        <div className="containterEntryDataQuoteShipping">
          <header>
            <ArrowBack
              className={`arrowBack ${step > 1 && !isActiveMassive ? 'visible' : ''}`}
              onClick={() => {
                if (step > 1) setStep(step - 1);
                setStepsFunction('return');
              }}
            />
            <Button
              type="button"
              className={`adjust-shipping ${isActive ? 'isActive' : ''}`}
              onClick={() => {
                setStep(1);
                setShippingData({});
                setSelectButton('Envío');
                setIsActive(true);
                setIsActiveMassive(false);
                setIsActiveExpress(false);
              }}
              selectButton={selectButton}
            >
              <div className="edit-hover">
                <Typography className="shipping-title" type="superTitle-italic" text="Cotiza" />
              </div>
            </Button>
            {
              user?._id && user.countryCode !== '484' && (
                <Button
                  type="button"
                  className={`express ${isActiveExpress ? 'isActiveExpress' : ''}`}
                  onClick={() => {
                    setStep(1);
                    setShippingData({});
                    setSelectButton('Express');
                    setIsActiveExpress(true);
                    setIsActiveMassive(false);
                    setIsActive(false);
                  }}
                  selectButton={selectButton}
                >
                  <div className="edit-hover">
                    <Typography className="express-title" type="superTitle-italic" text="Cotización Express" />
                  </div>
                </Button>
              )
            }
            {(countryCode !== '484') && (
              <Button
                type="button"
                className={`massive ${isActiveMassive ? 'isActiveMassive' : ''}`}
                onClick={() => {
                  setSelectButton('Masivos');
                  setIsActiveMassive(true);
                  setIsActiveExpress(false);
                  setIsActive(false);
                }}
                selectButton={selectButton}
              >
                <div className="edit-hover">
                  <Typography className="massive-title" type="superTitle-italic" text="Cotización Masiva" />
                </div>
              </Button>
            )}
            <Button
              type="button"
              className={user?._id ? 'empty' : 'adjust-empty'}
            >
              <Typography type="superTitle-italic" text="" />
            </Button>
            <Typography type="paragraph" className={step === 2 ? 'visible' : ''} />
          </header>
          <body>
            {(selectButton === 'Envío' || selectButton === 'Express') && (
              <section className="containerTitleAndProgressBar">
                <section>
                  <ArrowBack
                    className={`arrowBack ${isMobileDevice && !isActiveMassive ? 'visible' : ''}`}
                    onClick={() => (step > 1 ? setStep(step - 1) : history.goBack())}
                  />
                  <Typography type="title" text={selectTitleToStep(step)} />
                  <div />
                </section>
                <StepProgressBar
                  steps={[{}, {}, {}].map((item, index) => {
                    const newItem = item;
                    if (index < step) newItem.success = true;
                    return newItem;
                  })}
                  currentStep={currentStep}
                />
              </section>
            )}
            {(!expressMobile && selectButton === 'Envío')
              && (
                <section className="contentQuoteShippingSteps">
                  {contentStep()}
                </section>
              )}
            {(expressMobile || selectButton === 'Express')
              && (
                <section className="contentQuoteShippingSteps">
                  {contentStepExpressShipping()}
                </section>
              )}
            {(selectButton === 'Masivos')
              && (
                <section>
                  <QuoteMultipleShippings
                    isMobileDevice={isMobileDevice}
                    history={history}
                  />
                </section>
              )}
          </body>
        </div>
      )}
      {showModalCollectionService && !existenceUserAgency && (
        <ModalShippingType
          setDeliverySelected={() => setDeliverySelected(null)}
          closeModal={() => setShowModalCollectionService(false)}
          deliveryList={() => deliveryList(shippingData)}
          setStepsFunction={() => setStepsFunction('openCollectionService')}
        />
      )}

      {showModalErrorUbication && user && (
        <ModalErrorUbication
          countryCode={user?.countryCode}
          onClickConfirm={() => {
            setShowModalErrorUbication(false);
            setStepsFunction('toBeginning');
            setStep(1);
            setShippingData({});
          }}
        />
      )}

      {showCollectionService && (
        <div id="containter-quote-shipping__entry-data">
          {!isMobileDevice && (
            <HeaderQuoteShipping
              title="Cotiza tu envío"
              handleClick={
                () => {
                  setShowDataEntryViews(true);
                  setShowCollectionService(false);
                  setStepsFunction('return');
                }
              }
              img={ArrowBackIcon}
            />
          )}
          <div
            id="body__containter-quote-shipping__entry-data"
            className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}
          >
            <div id="container-quote-shipping-steps" className="collection-service">
              <CashDeliveryService
                saleValue={parseInt(saleValue, 10)}
                setSaleValue={setSaleValue}
                addShippingToCollectionValue={addShippingToCollectionValue}
                setAddShippingToCollectionValue={setAddShippingToCollectionValue}
                setCollectionService={setCollectionService}
                saleValueError={
                  findObjectInArray('saleValue', errorsList, 'name')
                    ? findObjectInArray('saleValue', errorsList, 'name').error
                    : null
                }
                nextWithCollectionService={() => {
                  if (collectionServiceValidation(
                    true, saleValue, errorsList, setErrorsList,
                  )) {
                    setCollectionService(true);
                    setShowDataEntryViews(false);
                    setShowDeliveriesList(true);
                    if (!isMobileDevice) setDeliveryListSizeChange(true);
                    setShowCollectionService(false);
                    quoteShippingFunction(shippingData, true);
                    if (setFullContainerQuoteShipping) setFullContainerQuoteShipping(false);
                  }
                }}
                nextWithOutCollectionService={() => {
                  if (collectionServiceValidation(
                    false, saleValue, errorsList, setErrorsList,
                  )) {
                    setCollectionService(false);
                    setShowDataEntryViews(false);
                    setShowDeliveriesList(true);
                    if (!isMobileDevice) setDeliveryListSizeChange(true);
                    setShowCollectionService(false);
                    quoteShippingFunction(shippingData, false);
                    if (setFullContainerQuoteShipping) setFullContainerQuoteShipping(false);
                  }
                  setCurrentStep(currentStep - 1);
                }}
                back={() => {
                  setStepsFunction('return');
                }}
              />
            </div>
          </div>
        </div>
      )}

      {showDeliveriesList && (
        <div
          id="container-deliveries-list-quote-shipping"
          className={`${isMobileDevice ? 'mobile-mode' : 'desktop-mode'} ${collectionService ? 'without-background' : ''}`}
        >
          {flagViewDeliveryListPrices ? (
            <HeaderQuoteShipping
              img={ArrowBackIcon}
              handleClick={
                () => {
                  if (collectionService) {
                    setShowCollectionService(true);
                  } else {
                    setShowDataEntryViews(true);
                    if (!isMobileDevice) setStepsFunction('return');
                  }
                  if (!isMobileDevice) setDeliveryListSizeChange(false);
                  setShowDeliveriesList(false);
                  if (setFullContainerQuoteShipping) setFullContainerQuoteShipping(false);
                }
              }
              title={`Elige una ${typeDelivery[countryCode]}`}
              isElementRight
              htmlContent={(
                <Select
                  id="order-deliveries-list"
                  element={isMobileDevice && (
                    <img
                      src={IconOrder}
                      alt=""
                    />
                  )}
                  label="Ordenar por"
                  showLabel={!isMobileDevice}
                  className="z90"
                  value={orderDeliveriesList}
                  onChange={setOrderDeliveriesList}
                  startingPosition="top-rigth"
                  options={newOrderListDeliveries}
                />
              )}
            />
          ) : (
            <HeaderQuoteShipping
              img={ArrowBackIcon}
              handleClick={
                () => {
                  if (collectionService) {
                    setShowCollectionService(true);
                  } else {
                    setShowDataEntryViews(true);
                    if (!isMobileDevice) setStepsFunction('return');
                  }
                  if (!isMobileDevice) setDeliveryListSizeChange(false);
                  setShowDeliveriesList(false);
                  if (setFullContainerQuoteShipping) setFullContainerQuoteShipping(false);
                }
              }
              title={`Elige una ${typeDelivery[countryCode]}`}
              isElementRight
              htmlContent={(
                <Select
                  id="order-deliveries-list"
                  element={isMobileDevice && (
                    <img
                      src={IconOrder}
                      alt=""
                    />
                  )}
                  label="Ordenar por"
                  showLabel={!isMobileDevice}
                  className="z90"
                  value={orderDeliveriesList}
                  onChange={setOrderDeliveriesList}
                  startingPosition="top-rigth"
                  options={orderListDeliveries}
                />
              )}
            />
          )}

          <div
            id="body__container-deliveries-list-quote-shipping"
            className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}
          >
            <div className="container-body__body__container-deliveries-list-quote-shipping">
              <InfoDataList loading={loadingDeliveriesList} list={deliveriesList} />
              {flagViewDeliveryListPrices ? (
                <>
                  {!loadingDeliveriesList && deliveriesList.length > 0 && !isMobileDevice && (
                    <div className="deliveries-list-step-three-container">
                      <NewStepThree
                        shippingData={shippingData}
                        back={() => {
                          setStepsFunction('toBeginning');
                          setStep(1);
                        }}
                        nextLeft={() => {
                          if (existenceUserAgency) {
                            // eslint-disable-next-line no-use-before-define
                            quoteShippingFunction(shippingData, false);
                            setCollectionService(false);
                            setShowDataEntryViews(false);
                            setShowDeliveriesList(true);
                          } else {
                            setStepsFunction('continue');
                            setShowModalCollectionService(true); // cambiar al step 2
                            setCollectionService(true);
                          }
                        }}
                        nextRight={setDeliverySelected}
                        isMobileDevice={isMobileDevice}
                        saleValue={saleValue}
                        closeDeliveryCompanies={() => {
                          setDeliverySelected(null);
                          setShowAnnotationsOfDelivery(false);
                        }}
                        nextDeliveryCompanies={() => {
                          continueToLastDetails();
                          if (setDeliveryListSizeChange) setDeliveryListSizeChange(false);
                          setShowAnnotationsOfDelivery(false);
                        }}
                        showAnnotationsOfDelivery={showAnnotationsOfDelivery}
                        deliverySelected={deliverySelected}
                        quoteShippingFunction={() => {
                          quoteShippingFunction(shippingData, collectionService);
                        }}
                        deliveriesList={deliveriesList}
                        addShippingToCollectionValue={addShippingToCollectionValue}
                        collectionService={collectionService}
                        setAddShippingToCollectionValue={setAddShippingToCollectionValue}
                        continueToLastDetails={() => continueToLastDetails()}
                      />
                    </div>
                  )}
                  {!loadingDeliveriesList && deliveriesList.length > 0 && isMobileDevice
                    && (
                      <DeliveryCardToList
                        deliveriesList={deliveriesList}
                        addShippingToCollectionValue={addShippingToCollectionValue}
                        collectionService={collectionService}
                        saleValue={saleValue}
                        setAddShippingToCollectionValue={setAddShippingToCollectionValue}
                        next={setDeliverySelected}
                        continueToLastDetails={() => continueToLastDetails()}
                      />
                    )}
                </>
              ) : (
                <>
                  {!loadingDeliveriesList && deliveriesList.length > 0 && !isMobileDevice && (
                    <div className="deliveries-list-step-three-container">
                      <StepThree
                        shippingData={shippingData}
                        back={() => {
                          setStepsFunction('toBeginning');
                          setStep(1);
                        }}
                        next={() => {
                          if (existenceUserAgency) {
                            // eslint-disable-next-line no-use-before-define
                            quoteShippingFunction(shippingData, false);
                            setCollectionService(false);
                            setShowDataEntryViews(false);
                            setShowDeliveriesList(true);
                          } else {
                            setStepsFunction('continue');
                            setShowModalCollectionService(true); // cambiar al step 2
                            setCollectionService(true);
                          }
                        }}
                        isMobileDevice={isMobileDevice}
                        saleValue={saleValue}
                        closeDeliveryCompanies={() => {
                          setDeliverySelected(null);
                          setShowAnnotationsOfDelivery(false);
                        }}
                        nextDeliveryCompanies={() => {
                          continueToLastDetails();
                          if (setDeliveryListSizeChange) setDeliveryListSizeChange(false);
                          setShowAnnotationsOfDelivery(false);
                        }}
                        showAnnotationsOfDelivery={showAnnotationsOfDelivery}
                        deliverySelected={deliverySelected}
                        quoteShippingFunction={() => {
                          quoteShippingFunction(shippingData, collectionService);
                        }}
                        deliveriesList={deliveriesList}
                        addShippingToCollectionValue={addShippingToCollectionValue}
                        collectionService={collectionService}
                        setAddShippingToCollectionValue={setAddShippingToCollectionValue}
                      />
                      <DeliveriesList
                        quoteShippingFunction={() => {
                          quoteShippingFunction(shippingData, collectionService);
                        }}
                        deliveriesList={deliveriesList}
                        addShippingToCollectionValue={addShippingToCollectionValue}
                        collectionService={collectionService}
                        saleValue={saleValue}
                        setAddShippingToCollectionValue={setAddShippingToCollectionValue}
                        next={setDeliverySelected}
                        continueToLastDetails={() => continueToLastDetails()}
                      />
                    </div>
                  )}
                  {!loadingDeliveriesList && deliveriesList.length > 0 && isMobileDevice
                    && (
                      <DeliveriesList
                        quoteShippingFunction={() => {
                          quoteShippingFunction(shippingData, collectionService);
                        }}
                        deliveriesList={deliveriesList}
                        addShippingToCollectionValue={addShippingToCollectionValue}
                        collectionService={collectionService}
                        saleValue={saleValue}
                        setAddShippingToCollectionValue={setAddShippingToCollectionValue}
                        next={setDeliverySelected}
                        continueToLastDetails={() => continueToLastDetails()}
                      />
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {showLastDetails && user && (
        flagViewDeliveryListPrices ? (
          <div id="container-last-details-quote-shipping" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
            <HeaderQuoteShipping
              img={ArrowBackIcon}
              handleClick={
                () => {
                  if (currentStepShipping === 1) {
                    setShowDeliveriesList(true);
                    setShowLastDetails(false);
                    setDeliverySelected(null);

                    if (!isMobileDevice) {
                      setDeliveryListSizeChange(false);
                      setFullContainerQuoteShipping(false);
                    }
                  }
                  if (currentStepShipping !== 1) {
                    setStepsShippingFunction('return');
                  }
                }
              }
              title={!isMobileDevice && ((currentStepShipping === 3 && !noDataCompleting) ? 'Paga tu envío' : 'Tu envío')}
              isElementRight
              isProgressBar
              steps={stepsShipping}
              currentStep={currentStepShipping}
            />
            <div id="body__container-last-details-quote-shipping">
              {' '}
              {currentStepShipping === 1 && (
                <StepFour
                  shippingData={shippingData}
                  deliverySelected={deliverySelected}
                  shippingInfo={{ collectionService, declaredValues: shippingData.declaredValue }}
                  user={user}
                  addShippingToCollectionValue={addShippingToCollectionValue}
                  setAddShippingToCollectionValue={setAddShippingToCollectionValue}
                  productInfo={{ saleValue }}
                  onChange={ChangesStepOneShipping}
                />
              )}

              {(noDataCompleting && currentStepShipping === 2)
                && (
                  <StepOneCompletingData
                    next={(changes) => {
                      setUserToUpdate(changes);
                      setStepsShippingFunction('continue');
                    }}
                    userToUpdate={userToUpdate}
                    errorsList={errorList}
                    setErrorsList={setErrorList}
                    isMobileDevice={isMobileDevice}
                  />
                )}

              {noDataCompleting && currentStepShipping === 3 && (
                <StepTwoCompletingData
                  next={(changes) => {
                    setUserToUpdate({
                      ...userToUpdate,
                      ...changes,
                    });
                    setStepsShippingFunction('continue');
                  }}
                  isMobileDevice={isMobileDevice}
                  changes={userToUpdate}
                />
              )}

              {((noDataCompleting && (currentStepShipping === 4))
                || (!noDataCompleting && (currentStepShipping === 2))) && (
                <StepTwoShipping
                  shippingData={shippingData}
                  deliverySelected={deliverySelected}
                  onChange={changesStepTwoShipping}
                />
              )}
              {(((noDataCompleting && (currentStepShipping === 5)))
                || (!noDataCompleting && currentStepShipping === 3)) && (
                <StepThreeShipping
                  shippingInfo={{
                    collectionService,
                    deliverySelected,
                    declaredValues: shippingData.declaredValue,
                    saleValue,
                  }}
                  totalRealCost={
                    finalPrice(
                      deliverySelected.shippingRealCost,
                      collectionService,
                      addShippingToCollectionValue,
                      deliverySelected.collectionCommissionWithRate,
                      deliverySelected.collectionCommissionWithOutRate,
                    )
                  }
                  deliverySelected={deliverySelected}
                  onChange={changesStepThreeShipping}
                  addShippingToCollectionValue={addShippingToCollectionValue}
                  generatingSending={generatingSending}
                />
              )}
            </div>
          </div>
        ) : (
          <div id="container-last-details-quote-shipping" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
            <HeaderQuoteShipping
              img={ArrowBackIcon}
              handleClick={
                () => {
                  if (currentStepShipping === 1) {
                    setShowDeliveriesList(true);
                    setShowLastDetails(false);
                    setDeliverySelected(null);

                    if (!isMobileDevice) {
                      setDeliveryListSizeChange(false);
                      setFullContainerQuoteShipping(false);
                    }
                  }
                  if (currentStepShipping !== 1) {
                    setStepsShippingFunction('return');
                  }
                }
              }
              title={!isMobileDevice && ((currentStepShipping === 3 && !noDataCompleting) ? 'Paga tu envío' : 'Tu envío')}
              isElementRight
              isProgressBar
              steps={stepsShipping}
              currentStep={currentStepShipping}
            />
            <div id="body__container-last-details-quote-shipping">
              {' '}
              {currentStepShipping === 1 && (
                <StepOneShipping
                  shippingData={shippingData}
                  deliverySelected={deliverySelected}
                  shippingInfo={{ collectionService, declaredValues: shippingData.declaredValue }}
                  user={user}
                  addShippingToCollectionValue={addShippingToCollectionValue}
                  setAddShippingToCollectionValue={setAddShippingToCollectionValue}
                  productInfo={{ saleValue }}
                  onChange={ChangesStepOneShipping}
                />
              )}

              {(noDataCompleting && currentStepShipping === 2)
                && (
                  <StepOneCompletingData
                    next={(changes) => {
                      setUserToUpdate(changes);
                      setStepsShippingFunction('continue');
                    }}
                    userToUpdate={userToUpdate}
                    errorsList={errorList}
                    setErrorsList={setErrorList}
                    isMobileDevice={isMobileDevice}
                  />
                )}

              {noDataCompleting && currentStepShipping === 3 && (
                <StepTwoCompletingData
                  next={(changes) => {
                    setUserToUpdate({
                      ...userToUpdate,
                      ...changes,
                    });
                    setStepsShippingFunction('continue');
                  }}
                  isMobileDevice={isMobileDevice}
                  changes={userToUpdate}
                />
              )}

              {((noDataCompleting && (currentStepShipping === 4))
                || (!noDataCompleting && (currentStepShipping === 2))) && (
                <StepTwoShipping
                  shippingData={shippingData}
                  deliverySelected={deliverySelected}
                  onChange={changesStepTwoShipping}
                />
              )}
              {(((noDataCompleting && (currentStepShipping === 5)))
                || (!noDataCompleting && currentStepShipping === 3)) && (
                <StepThreeShipping
                  shippingInfo={{
                    collectionService,
                    deliverySelected,
                    declaredValues: shippingData.declaredValue,
                    saleValue,
                  }}
                  totalRealCost={
                    finalPrice(
                      deliverySelected.shippingRealCost,
                      collectionService,
                      addShippingToCollectionValue,
                      deliverySelected.collectionCommissionWithRate,
                      deliverySelected.collectionCommissionWithOutRate,
                    )
                  }
                  deliverySelected={deliverySelected}
                  onChange={changesStepThreeShipping}
                  addShippingToCollectionValue={addShippingToCollectionValue}
                  generatingSending={generatingSending}
                />
              )}
            </div>
          </div>
        )
      )}

      {showPaymentShipping && user && (
        <div id="container-payment-shipping-quote-shipping" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
          <HeaderQuoteShipping
            img={ArrowBackIcon}
            handleClick={
              () => {
                setShowLastDetails(true);
                setShowPaymentShipping(false);
              }
            }
            title="Paga tu envío"
          />
          <div id="body__container-payment-shipping-quote-shipping">
            <PaymentShipping
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              deliverySelected={deliverySelected}
              generatingSending={generatingSending}
              user={user}
              saleValue={saleValue}
              collectionService={collectionService}
              addShippingToCollectionValue={addShippingToCollectionValue}
              rechargeBalance={() => setShowSendingSave(true)}
              next={() => generateShipmentFunction(true)}
            />
            <></>
          </div>
        </div>
      )}

      {showShippingGenerated && (
        <div
          id="container-shipping-generated-quote-shipping"
          className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}
        >
          <div id="header__container-shipping-generated-quote-shipping">
            <div className="left" />
            <div className="center">
              <Typography text="Tu envío" type="superTitle-italic" />
            </div>
            <div className="rigth" />
          </div>
          <div id="body__container-shipping-generated-quote-shipping">
            <div className="second-body__container-shipping-generated-quote-shipping">
              <div className="container-text">
                <Typography
                  type="superTitle"
                  text="¡Tu envío ha sido solicitado con éxito!"
                />

                <span className="text">
                  Descarga tu guía, imprímela y pégala en tu paquete.
                </span>

                {mpCode && (
                  <>
                    <Typography
                      type="field"
                      text="Recuerda el código de tu envío es"
                    />
                    <Typography
                      type="superTitle"
                      text={`MP${mpCode}`}
                    />
                  </>
                )}
              </div>
              <div className="container-buttons">
                <Button
                  text="Ir a descargar tu guía"
                  type={isCountryValid ? 'primary-mx' : 'primary'}
                  onClick={() => history.push('/historial-envios')}
                />
                <Button
                  text="Realiza otro envío"
                  type="secondary"
                  onClick={() => window.location.reload()}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showLogin && (
        <Modal
          className="quote-shipping__modal-login"
          closeBackground={() => {
            setDeliverySelected(null);
            setShowLogin(false);
          }}
        >
          <Login
            next={() => {
              continueToLastDetails();
              setShowLogin(false);
            }}
            close={() => {
              setShowLogin(false);
              setDeliverySelected(null);
            }}
          />
        </Modal>
      )}

      {showBankData && (
        <Modal className="quote-shipping__modal-bank-data">
          <BankData
            next={() => setShowBankData(false)}
            userIdToUpdate={user._id}
            countryCode={user.countryCode}
            close={() => {
              setDeliverySelected(null);
              setShowBankData(false);
            }}
          />
        </Modal>
      )}

      {showNoDataBank && (
        <Modal
          className="modal__no-data-bank"
        >
          <>
            <div className="header__no-data-bank">
              <div className="left" />
              <div className="center">
                <Typography
                  text="¡Uy! aún no tenemos tus datos bancarios"
                  type="superTitle-italic"
                  className="title__header__no-data-bank"
                />
              </div>
              <div className="right">
                <img
                  src={IconClose}
                  alt="cerrar"
                  onClick={() => {
                    setShowNoDataBank(false);
                    setDeliverySelected(null);
                  }}
                />
              </div>
            </div>
            <div className="body__no-data-bank">
              <Typography
                type="paragraph"
                text="Recuerda que para poder realizar el pago de recaudos tus datos bancarios deben ser proporcionados."
              />
            </div>
            <div className="footer__no-data-bank">
              <Button
                text="Continuar"
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={() => {
                  setShowBankData(true);
                  setShowNoDataBank(false);
                }}
              />
            </div>
          </>
        </Modal>
      )}

      {showModalError && (
        <Modal>
          <ModalChildren
            headerText="¡Ups!"
            bodyText={`¡Hubo un problema! ${modalErrorText}`}
            onClickButtonConfirm={() => setShowModalError(false)}
          />
        </Modal>
      )}

      {showSendingSave && (
        <Modal
          className="modal__sending-save"
        >
          <>
            <div className="body__sending-save">
              <span>
                Recarga tu saldo, luego vuelve a crear tu envío ya que este no quedará guardado.
              </span>
            </div>
            <div className="footer__sending-save">
              <Button
                text="Continuar"
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={() => {
                  history.push('/recargar-saldo');
                  setShowSendingSave(false);
                }}
              />
              <Button
                text="Cancelar"
                type="secondary"
                onClick={() => {
                  setShowSendingSave(false);
                }}
              />
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

QuoteShipping.propTypes = {
  setFullContainerQuoteShipping: PropTypes.func,
  setDeliveryListSizeChange: PropTypes.func,
  expressMobile: PropTypes.func,
  onChangeTypeSection: PropTypes.func,
};

QuoteShipping.defaultProps = {
  setFullContainerQuoteShipping: null,
  expressMobile: null,
  onChangeTypeSection: null,
  setDeliveryListSizeChange: null,
};

export default QuoteShipping;
