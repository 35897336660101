import React from 'react';
import PropTypes from 'prop-types';
import { toCurrencyCop } from 'utils/strings';

const DeliveryValue = ({
  shippingValue, shippingRealValue, user, classContainer,
}) => (
  <div className={`shipping-value__delivery ${classContainer}`}>
    {shippingValue && (
      <span className="discount">
        {toCurrencyCop(user, Math.floor(shippingValue))}
      </span>
    )}
    <span className="real">
      {shippingRealValue && toCurrencyCop(user, Math.floor(shippingRealValue))}
    </span>
  </div>
);

DeliveryValue.propTypes = {
  shippingValue: PropTypes.number.isRequired,
  shippingRealValue: PropTypes.number.isRequired,
  user: PropTypes.shape.isRequired,
  classContainer: PropTypes.string,
};

DeliveryValue.defaultProps = {
  classContainer: PropTypes.string,
};

export default DeliveryValue;
