/* eslint-disable no-nested-ternary,max-len */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import DetailsPricesDiscounts from 'components/detailsPricesDiscounts';
import { routeTypes } from 'constants/general';
import { totalPrices } from 'utils/prices';

import CardDesktop from './cardDesktop';
import CardMobile from './cardMobile';
import CardMobileCollectionService from './cardMobileCollectionService';

import './deliveryCardToList.scss';

const routeTypeValidation = (type) => routeTypes[type] || 'No especificado';

const DeliveryCardToList = ({
  id,
  deliveryName,
  deliveryImg,
  deliveryTime,
  shippingValue,
  shippingRealValue,
  collectionValue,
  deliveryScore,
  onClick,
  delivery,
  setHideDetails,
  setShowDetails,
  detailsCostElement,
  isMobileDevice,
  focused,
  details,
  routeType,
  pickupService,
  officeAddress,
  collectionService,
  user,
  showDataCollectionCommissionDetails,
  setShowDataCollectionCommissionDetails,
  collectionCommissionValueDiscount,
  realValueCollectionCommission,
  insuranceValue,
  discountFreightValue,
  collectionCommissionWithOutRate,
  shippingCost,
  collectionCommissionValue,
}) => {
  const handleShowDetail = (e) => {
    e.stopPropagation();
    if (delivery && delivery.id === id) {
      setHideDetails();
    } else {
      setShowDetails();
    }
  };

  const finalAmountCollectionService = (previousAmount = false) => {
    if (collectionService) {
      return previousAmount
        ? totalPrices((shippingCost - insuranceValue), insuranceValue)
        : totalPrices((shippingCost - insuranceValue), insuranceValue, discountFreightValue);
    }
    return previousAmount ? shippingRealValue : shippingValue;
  };

  return (
    <button
      className={`delivery-card-to-list ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'} ${(!isMobileDevice && focused) ? 'focused' : ''}`}
      onClick={() => {
        if (!(collectionService && isMobileDevice)) {
          onClick();
        }
      }}
      type="button"
    >
      {isMobileDevice ? (
        collectionService ? (
          <CardMobileCollectionService
            onClick={onClick}
            pickupService={pickupService}
            deliveryTime={deliveryTime}
            officeAddress={officeAddress}
            deliveryImg={deliveryImg}
            detailsCostElement={detailsCostElement}
            id={id}
            deliveryScore={deliveryScore}
            deliveryName={deliveryName}
            routeType={routeType}
            shippingValue={finalAmountCollectionService()}
            isMobileDevice={isMobileDevice}
            shippingRealValue={finalAmountCollectionService(true)}
            collectionValue={collectionValue}
            details={details}
            routeTypeValidation={routeTypeValidation}
            handleShowDetail={handleShowDetail}
            delivery={delivery}
            showDataCollectionCommissionDetails={showDataCollectionCommissionDetails}
            setShowDataCollectionCommissionDetails={setShowDataCollectionCommissionDetails}
            collectionCommissionValueDiscount={collectionCommissionValueDiscount}
            realValueCollectionCommission={realValueCollectionCommission}
            collectionCommissionWithOutRate={collectionCommissionWithOutRate}
            collectionCommissionValue={collectionCommissionValue}
            focused={focused}
          />
        ) : (
          <CardMobile
            pickupService={pickupService}
            deliveryTime={deliveryTime}
            officeAddress={officeAddress}
            deliveryImg={deliveryImg}
            detailsCostElement={detailsCostElement}
            id={id}
            deliveryScore={deliveryScore}
            deliveryName={deliveryName}
            routeType={routeType}
            shippingValue={shippingValue}
            isMobileDevice={isMobileDevice}
            shippingRealValue={shippingRealValue}
            collectionValue={collectionValue}
            details={details}
            routeTypeValidation={routeTypeValidation}
            handleShowDetail={handleShowDetail}
            delivery={delivery}
            realValueCollectionCommission={realValueCollectionCommission}
            collectionCommissionWithOutRate={collectionCommissionWithOutRate}
            collectionCommissionValue={collectionCommissionValue}
          />
        )
      ) : (
        <CardDesktop
          pickupService={pickupService}
          deliveryTime={deliveryTime}
          officeAddress={officeAddress}
          deliveryImg={deliveryImg}
          detailsCostElement={detailsCostElement}
          id={id}
          deliveryScore={deliveryScore}
          deliveryName={deliveryName}
          routeType={routeType}
          shippingValue={finalAmountCollectionService()}
          shippingRealValue={finalAmountCollectionService(true)}
          collectionValue={collectionValue}
          details={details}
          routeTypeValidation={routeTypeValidation}
          handleShowDetail={handleShowDetail}
          delivery={delivery}
          realValueCollectionCommission={realValueCollectionCommission}
          collectionCommissionWithOutRate={collectionCommissionWithOutRate}
          collectionCommissionValue={collectionCommissionValue}
        />
      )}
      {collectionService && !isMobileDevice && user && (
        <div className="details-collection-service">
          <DetailsPricesDiscounts
            user={user}
            commissionCollectionValue={realValueCollectionCommission}
            discountCommissionCollectionValue={collectionCommissionValueDiscount}
            discountFreightValue={discountFreightValue}
            freightValue={shippingCost - insuranceValue}
            insuranceValue={insuranceValue}
            titleCollectionService="Descuento en servicio de recaudo"
            titleDiscountShipping="Descuento en envío"
            totalDiscountCommissionCollection={totalPrices(collectionCommissionValueDiscount, realValueCollectionCommission)}
            totalDiscountShipping={totalPrices((shippingCost - insuranceValue), insuranceValue, discountFreightValue)}
            totalSaved={Math.abs(totalPrices(discountFreightValue, collectionCommissionValueDiscount))}
            isMobileDevice={isMobileDevice}
          />
        </div>
      )}
    </button>
  );
};

DeliveryCardToList.propTypes = {
  id: PropTypes.string.isRequired,
  deliveryName: PropTypes.string.isRequired,
  deliveryImg: PropTypes.string.isRequired,
  deliveryTime: PropTypes.number.isRequired,
  shippingValue: PropTypes.number.isRequired,
  shippingRealValue: PropTypes.number,
  collectionValue: PropTypes.number,
  deliveryScore: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  setShowDetails: PropTypes.func.isRequired,
  setHideDetails: PropTypes.func.isRequired,
  delivery: PropTypes.shape({
    id: PropTypes.string,
  }),
  detailsCostElement: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  focused: PropTypes.bool,
  details: PropTypes.bool,
  routeType: PropTypes.string.isRequired,
  pickupService: PropTypes.bool.isRequired,
  officeAddress: PropTypes.string,
  collectionService: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    countryCode: PropTypes.string.isRequired,
  }).isRequired,
  showDataCollectionCommissionDetails: PropTypes.bool.isRequired,
  setShowDataCollectionCommissionDetails: PropTypes.func.isRequired,
  collectionCommissionValueDiscount: PropTypes.number,
  realValueCollectionCommission: PropTypes.number,
  insuranceValue: PropTypes.number.isRequired,
  discountFreightValue: PropTypes.number,
  collectionCommissionWithOutRate: PropTypes.number,
  shippingCost: PropTypes.number,
  collectionCommissionValue: PropTypes.number,
};

DeliveryCardToList.defaultProps = {
  collectionValue: null,
  delivery: null,
  focused: false,
  details: true,
  shippingRealValue: null,
  officeAddress: null,
  collectionCommissionValueDiscount: null,
  realValueCollectionCommission: null,
  discountFreightValue: null,
  collectionCommissionWithOutRate: null,
  shippingCost: null,
  collectionCommissionValue: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  deliveryCompaniesConfig: state.globalData.deliveryCompaniesConfig,
  user: state.auth.user,
});
export default connect(mapStateToProps)(DeliveryCardToList);
