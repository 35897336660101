import bankS3 from '../config/config.bank.json';

const bankConfig = bankS3['170'].bankTransferFee;

const bankTransferColombia = (user) => {
  const accountBankData = user?.accountBank;
  const toLowerBank = accountBankData?.bank.toLowerCase();
  const findBank = bankConfig[toLowerBank];
  return `${user.accountBank?.bank}-(Desembolso: $${findBank})`;
};

export default bankTransferColombia;
