import axios from 'axios';

import {
  usersMicroserviceUrl,
} from '../constants';

const usersCustomerKey = 'b0c6d2aa-4d53-11eb-ae93-0242ac130002';

export const loginService = (query) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  if (query.email) query.email = query.email?.toLowerCase().trim();
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}/loginUser`, query)
    .then(({ data }) => resolve(data))
    .catch((e) => reject(e));
});

export const loginSocialAccountService = (query) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  if (query.email) query.email = query.email?.toLowerCase().trim();
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}/loginUserSocialAccount`, query)
    .then(({ data }) => resolve(data))
    .catch((e) => reject(e));
});

export const getUserService = (userId) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.get(`${usersMicroserviceUrl}/getUser/${userId}`)
    .then(({ data }) => resolve(data))
    .catch((e) => reject(e));
});

export const validateEmailService = (email) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}/validEmail`, { email: email?.toLowerCase().trim() })
    .then((data) => resolve(data))
    .catch((e) => reject(e));
});

export const createAccountService = (query) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}/registerUser`, query)
    .then(({ data }) => { resolve(data); })
    .catch((e) => reject(e));
});

export const updateUserService = (userId, body) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}/updateUser/${userId}`, body)
    .then(({ data }) => { resolve(data); })
    .catch((e) => { reject(e); });
});

export const resetPasswordService = (userId, password, token) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.defaults.headers.common.token = token;
  axios.post(`${usersMicroserviceUrl}/resetPassword`, { userId, password })
    .then(({ data }) => { resolve(data); })
    .catch((e) => { reject(e); });
});

export const getUserReport = (query) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}/getuser`, query)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const updateUser = (data, userId) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}/updateUser/${userId}`, data)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const getUsersCampaign = (data) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}/getuserscampaign`, data)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const getUserLevelService = (userId) => new Promise((resolve, reject) => {
  const endPoint = '/levelCounter';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.get(`${usersMicroserviceUrl}${endPoint}/${userId}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const pointsCounterService = (userId, token) => new Promise((resolve, reject) => {
  const endPoint = '/pointCounter';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  if (token) axios.defaults.headers.common.token = token;

  axios.get(`${usersMicroserviceUrl}${endPoint}/${userId}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const getProductsAndServices = (benefictType) => new Promise((resolve, reject) => {
  const endPoint = '/getProductAndService';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, { typeProduct: benefictType })
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const createAndUpdateNegotiation = (negotiation) => new Promise((resolve, reject) => {
  const endPoint = '/createAndUpdateNegotiations';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, negotiation)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const getConfigNotificationsUser = () => new Promise((resolve, reject) => {
  const endPoint = '/activeNotifications';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.get(`${usersMicroserviceUrl}${endPoint}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const setConfigNotificationsUser = (
  activeNotifications,
) => new Promise((resolve, reject) => {
  const endPoint = '/activeNotifications';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, activeNotifications)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const updateDeliveryCompanyDisabledUser = (
  deliveryCompanies,
) => new Promise((resolve, reject) => {
  const endPoint = '/deliveryCompaniesSettings';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, { deliveryCompanies })
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const getUsersAddresses = () => new Promise((resolve, reject) => {
  const endPoint = '/myDirections';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.get(`${usersMicroserviceUrl}${endPoint}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const deleteUsersAddresses = (data) => new Promise((resolve, reject) => {
  const endPoint = '/myDirections';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.delete(`${usersMicroserviceUrl}${endPoint}`, { data })
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const createUsersAddresses = (data) => new Promise((resolve, reject) => {
  const endPoint = '/myDirections';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, data)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const updateUsersAddresses = (data) => new Promise((resolve, reject) => {
  const endPoint = '/myDirections';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.put(`${usersMicroserviceUrl}${endPoint}`, data)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const getQuoteCounters = (userId) => new Promise((resolve, reject) => {
  const endPoint = '/quoteCounters';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, { user: userId })
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const updatePrintFormat = (guideSettings) => new Promise((resolve, reject) => {
  const endPoint = '/guidesSettings';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, guideSettings)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const getUserConfigs = (necessarySettings) => new Promise((resolve, reject) => {
  const endPoint = '/getUserConfigs';
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, necessarySettings)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const invoicingDataService = (query) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}/invoicingData`, query)
    .then(({ data }) => { resolve(data); })
    .catch((e) => reject(e));
});

export const invoicingDataUpdateService = (query) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.put(`${usersMicroserviceUrl}/updateInvoicingData`, query)
    .then(({ data }) => { resolve(data); })
    .catch((e) => reject(e));
});

export const getConfigDeliveryCompanyPerUser = (idUser) => new Promise((resolve, reject) => {
  const endPoint = `/getConfigDeliveryCompanyPerUser/${idUser}`;
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.get(`${usersMicroserviceUrl}${endPoint}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const getApikey = (userId) => new Promise((resolve, reject) => {
  const endPoint = `/getApiKey/${userId}`;
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.get(`${usersMicroserviceUrl}${endPoint}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const createApikey = (userId) => new Promise((resolve, reject) => {
  const endPoint = `/createApiKey/${userId}`;
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.post(`${usersMicroserviceUrl}${endPoint}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const deleteApiKey = (userId) => new Promise((resolve, reject) => {
  const endPoint = `/deleteApiKey/${userId}`;
  axios.defaults.headers.common['customer-key'] = usersCustomerKey;
  axios.delete(`${usersMicroserviceUrl}${endPoint}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});
