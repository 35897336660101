import axios from 'axios';

import {
  elpMicroserviceUrl,
  customerKeyElpMicroservice,
} from '../constants';

// const elpMicroserviceUrl = 'http://localhost:4040/elp';

const listBank = async () => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyElpMicroservice;
  axios.get(`${elpMicroserviceUrl}/listBank`)
    .then((response) => resolve(response.data))
    .catch((error) => reject(error));
});

export const startPayments = async (data) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyElpMicroservice;
  axios.post(`${elpMicroserviceUrl}/startPayments`, { ...data })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error));
});

export default listBank;
