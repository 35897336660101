/* eslint-disable no-nested-ternary */
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { Actions, Process } from '../enums';

import Typography from '../../../../components/typography';

import IconArrowBack from '../../../../assets/images/icon-arrow-back-color-primary.svg';

import CreateNegotiation from './createNegotation';
import UpdateNegotiation from './updateNegotiation';

import './steps.scss';

const CreateAndUpdateNegotiation = ({
  object, negotationSchemas, user, updateUser,
}) => {
  const { deliverySelected, action } = object;

  const negotiationSchema = negotationSchemas?.find(
    (company) => deliverySelected._id === company.deliveryCompany);

  return (
    <div className="container-bargainings">
      <div className="principal-header">
        <img
          src={IconArrowBack}
          alt="atras"
          className="arrow-back"
          onClick={() => {
            if (Actions.Create === action) object.setCurrentStep(Process.NoNegotiantionList);
            if (Actions.Update === action) object.setCurrentStep(Process.DetailNegotiation);
          }}
        />
        <Typography
          type="superTitle-italic"
          text="Tus negociaciones"
        />
      </div>
      <div className="wrapper three">
        {!negotiationSchema && (
          <Typography
            type="superTitle-italic"
            text="No se encontró la compañía seleccionada"
          />
        )}
        {negotiationSchema && (action === Actions.Create) ? (
          <CreateNegotiation
            negotiationSchema={negotiationSchema}
            deliverySelected={deliverySelected}
            setCurrentStep={object.setCurrentStep}
            updateUser={updateUser}
            user={user.countryCode}
          />
        ) : (
          <UpdateNegotiation
            negotiationSchema={negotiationSchema}
            deliverySelected={deliverySelected}
            setCurrentStep={object.setCurrentStep}
            user={user}
            updateUser={updateUser}
          />
        )}
      </div>
    </div>
  );
};

CreateAndUpdateNegotiation.propTypes = {
  object: PropTypes.shape().isRequired,
  negotationSchemas: PropTypes.arrayOf(PropTypes.shape()),
  user: PropTypes.shape().isRequired,
  updateUser: PropTypes.func.isRequired,
};

CreateAndUpdateNegotiation.defaultProps = {
  negotationSchemas: [],
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  negotationSchemas: state.globalData.negotationSchemas || [],
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(CreateAndUpdateNegotiation);
