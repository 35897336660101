/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import EmptyList from '../../assets/images/empty-list.png';

import './infoDataList.scss';

const InfoDataList = ({ loading, list }) => (
  <>
    {loading && (
      <div className="info-data">
        <div className="preloader" />
      </div>
    )}
    {!loading && list && list.length === 0 && (
      <div className="info-data">
        <img src={EmptyList} alt="" />
        <span>No hay datos para mostrar.</span>
      </div>
    )}
  </>
);

InfoDataList.propTypes = {
  loading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default InfoDataList;
