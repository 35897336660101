/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import Modal from '../../../../components/modal';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Typography from '../../../../components/typography';

import { updateUsersAddresses } from '../../../../services/user';
import { getLocationsService } from '../../../../services/location';
import { findLocationByName } from '../../../../utils/filters';
import { useValidationCountry, useFetchIpData } from '../../../../utils/validationCountry';

import './modalUpdateAddress.scss';

const ModalUpdateAddress = ({
  closeAndRemove,
  editItem,
  setShowUpdateModal,
  onChangeListAddress,
}) => {
  const [locations, setLocations] = useState([]);
  const [newAddress, setNewAddress] = useState(editItem.address);
  const [newLocationCode, setNewLocationCode] = useState(editItem.locationCode);
  const [newLocation, setNewLocation] = useState('');
  const [newNameAddress, setNewNameAddress] = useState(editItem.name);
  const [addressId] = useState(editItem._id);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [annotationError, setAnnotationError] = useState('');

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const updateAddressService = async (data) => {
    const createAddress = await updateUsersAddresses([data]);
    onChangeListAddress(createAddress);
  };

  const getLocationService = async () => {
    const getLocation = await getLocationsService();
    const locationFilter = getLocation.filter(
      (location) => location.locationCode === editItem.locationCode,
    )[0];
    setLocations(getLocation);
    setNewLocation(`${locationFilter.locationName}-${locationFilter.departmentOrStateName}`);
  };

  useEffect(() => {
    getLocationService();
  }, []);

  useEffect(() => {
    const originSplitted = newLocation.split('-');
    if (originSplitted.length > 1) {
      const originObject = findLocationByName(
        originSplitted[0], originSplitted[1], locations,
      );
      setNewLocationCode(originObject?.locationCode ?? '');
    }
  }, [newLocation]);

  return (
    <div>
      <Modal closeBackground={closeAndRemove}>
        <div className="updateDirection">
          <div className="iconClose" onClick={setShowUpdateModal} />
          <div className="centerTitleModal">
            <Typography
              type="title"
              text="Editar Sede"
              className="italic"
            />
          </div>
          <div className="edit-center">
            <div className="edit-input-top">
              {
                locations && (
                  <Input
                    label="Origen"
                    placeholder="Origen"
                    value={newLocation}
                    onChange={
                      () => setAnnotationError('No puedes editar la ciudad, elimina la sede y créala de nuevo.')
                    }
                    annotationError={annotationError}
                    disabled={false}
                  />
                )
              }
            </div>
            <div className="edit-input-mid">
              <Input
                label="Nombre de la sede"
                placeholder="Sede de caribe"
                value={newNameAddress}
                onChange={setNewNameAddress}
                annotationError={!newNameAddress ? 'Ingresa el nombre de tu dirección' : ''}
              />
            </div>
            <div className="edit-input-bottom">
              <Input
                label="Escribe la dirección"
                placeholder="Carrera 11 #00 - 00"
                value={newAddress}
                onChange={setNewAddress}
                annotationError={!newAddress ? 'Ingresa la dirección de origen' : ''}
              />
              <div className="addressInput">
                <p>Recuerda agregar datos detallados</p>
              </div>
            </div>
          </div>
          <div className="edit-buttonModal">
            <Button
              type="secondary"
              text="Cancelar"
              id="cancelModal"
              onClick={setShowUpdateModal}
            />
            <Button
              type={isCountryValid ? 'primary-mx' : 'primary'}
              text="Guardar cambios"
              id="saveModal"
              disabled={false}
              onClick={newLocationCode ? () => {
                updateAddressService({
                  name: newNameAddress,
                  address: newAddress,
                  _id: addressId,
                });
                setShowModalConfirm(true);
              } : ''}
            />
          </div>
        </div>
      </Modal>
      {
        showModalConfirm && newLocation && (
          <div>
            <Modal closeBackground={() => {
              setShowModalConfirm(false);
              setShowUpdateModal();
            }}
            >
              <div className="confirmSaveDirectionUpdate">
                <div>
                  <Typography
                    type="title"
                    text="Los cambios se han guardado exitosamente."
                    className="italic"
                  />
                </div>
              </div>
            </Modal>
          </div>
        )
      }
    </div>
  );
};

ModalUpdateAddress.propTypes = {
  closeAndRemove: PropTypes.func.isRequired,
  editItem: PropTypes.shape().isRequired,
  setShowUpdateModal: PropTypes.func.isRequired,
  onChangeListAddress: PropTypes.func.isRequired,
};

ModalUpdateAddress.defaultProps = {};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(ModalUpdateAddress);
