import { useState, useEffect } from 'react';
import { getLocationsFilter, getTypeProducts } from '../services/routes';
import { findLocationByName } from '../utils/filters';

export const useShippingSteps = (countryCode, user) => {
  const [stepsShipping, setStepsShipping] = useState([{}, {}, {}]);
  const [noDataCompleting, setNoDataCompleting] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [showModalDataCompleting, setShowModalDataCompleting] = useState(false);

  const {
    address,
    businessName,
    tradeName,
    personType,
    invoicingData,
    cellPhone,
    documentNumber,
    documentType,
  } = user || {};

  const dataUser = address
    && businessName
    && personType;

  const dataMx = !!(dataUser
    && tradeName
    && invoicingData?.RFC
    && invoicingData?.taxResidence
    && invoicingData?.postalCode
    && invoicingData?.methodOfPayment
    && invoicingData?.taxRegime
    && invoicingData?.CFDI
    && invoicingData?.emailForBilling);

  const dataCol = !!((personType !== 'Persona Jurídica' || (personType === 'Persona Jurídica' && tradeName))
    && dataUser
    && cellPhone
    && documentNumber
    && documentType
    && invoicingData?.emailForBilling
    && invoicingData?.address
    && invoicingData?.locationCode);

  const updateShippingSteps = () => {
    let showModal = false;
    let updateData = false;
    let steps = [{}, {}, {}];
    let noData = false;

    if (user) {
      if ((countryCode === '484' && !dataMx) || (countryCode === '170' && !dataCol)) {
        showModal = true;
        steps = countryCode === '484' ? [{}, {}, {}, {}, {}] : [{}, {}, {}, {}];
        noData = true;
      }
      if (invoicingData?._id && ((countryCode === '484' && !dataMx) || (countryCode === '170' && !dataCol))) {
        showModal = true;
        updateData = true;
      }
    }

    setShowModalDataCompleting(showModal);
    setIsUpdateData(updateData);
    setStepsShipping(steps);
    setNoDataCompleting(noData);
  };

  return {
    stepsShipping,
    noDataCompleting,
    updateShippingSteps,
    setStepsShipping,
    showModalDataCompleting,
    isUpdateData,
  };
};

export const useFindLocations = (countryCode, value, locations) => {
  const [locationCode, setLocationCode] = useState('');
  let locationObject;

  useEffect(() => {
    const originSplitted = value?.split('-');

    if (originSplitted?.length > 1) {
      if (countryCode === '170') {
        locationObject = findLocationByName(
          originSplitted[0], originSplitted[1], locations,
        );
      }
    }

    setLocationCode(locationObject?.locationCode ?? '');
  }, [value, locationCode]);

  return { locationCode };
};

const useGenericService = (queryFunction) => {
  const [data, setData] = useState([]);

  const fetchData = async (body) => {
    try {
      const result = await queryFunction(body);
      setData(result);
    } catch (error) {
      setData([]);
    }
  };

  return { data, fetchData };
};

export const useLocationService = () => {
  const {
    data: listLocations,
    fetchData: getLocationService,
  } = useGenericService(getLocationsFilter);
  return { listLocations, getLocationService };
};

export const useTypeProductService = () => {
  const {
    data: listTypeProducts,
    fetchData: getProductsService,
  } = useGenericService(getTypeProducts);
  return { listTypeProducts, getProductsService };
};
