import { React, useState } from 'react';
import PropTypes, { number } from 'prop-types';
import Modal from '../modal';
import Carousel from '../carousel';
import Typography from '../typography';
import { typeDelivery } from '../../constants/general';

import Info1 from '../../assets/images/notificationsInfo/Info_Recogido_por_la_transportadora_SIN_recaudo_Envío_Tradicional.png';
import Info2 from '../../assets/images/notificationsInfo/Info_Recogido_por_la_transportadora_CON_recaudo_Envío_Contra_Entrega.png';
import Info3 from '../../assets/images/notificationsInfo/Info_En_Transporte.png';
import Info4 from '../../assets/images/notificationsInfo/Info_En_Ciudad_Destino.png';
import Info5 from '../../assets/images/notificationsInfo/Info_Distribución_CON_recaudo_Envío_Contra_Entrega.png';
import Info6 from '../../assets/images/notificationsInfo/Info_Distribución_SIN_recaudo_Envío_Tradicional.png';
import Info7 from '../../assets/images/notificationsInfo/Info_Entregado.png';
import IconClose from '../../assets/images/close.svg';

import './notificationsInfoModal.scss';

const NotificationsInfoModal = ({
  close,
  isMobileDevice,
  closeBackground,
  countryCode,
  notificationsList = [
    {
      collectionTypograghy: 'paragraph',
      collectionType: '(Sin recaudo)',
      stateTypography: 'superTitle-italic',
      state: `Recogido por ${typeDelivery[countryCode || 170]}`,
      priceTypography: 'field',
      price: 'Gratis',
      src: Info1,
    },
    {
      collectionTypograghy: 'paragraph',
      collectionType: '(Con recaudo)',
      stateTypography: 'superTitle-italic',
      state: `Recogido por ${typeDelivery[countryCode || 170]}`,
      priceTypography: 'field',
      price: 'Gratis',
      src: Info2,
    },
    {
      stateTypography: 'superTitle-italic',
      state: 'En transporte',
      priceTypography: 'field',
      price: '$200',
      src: Info3,
    },
    {
      stateTypography: 'superTitle-italic',
      state: 'Ciudad destino',
      priceTypography: 'field',
      price: '$200',
      src: Info4,
    },
    {
      collectionTypograghy: 'paragraph',
      collectionType: '(Sin recaudo)',
      stateTypography: 'superTitle-italic',
      state: 'Distribución',
      priceTypography: 'field',
      price: '$500',
      src: Info6,
    },
    {
      collectionTypograghy: 'paragraph',
      collectionType: '(Con recaudo)',
      stateTypography: 'superTitle-italic',
      state: 'Distribución',
      priceTypography: 'field',
      price: '$500',
      src: Info5,
    },
    {
      stateTypography: 'superTitle-italic',
      state: 'Entregado',
      priceTypography: 'field',
      price: '$200',
      src: Info7,
    },
  ],
  indicator = [0, 1, 2, 3, 4, 5, 6],
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const comunicationPos = (direction) => {
    if (direction === 'toRight') {
      setCurrentPage(currentPage + 1);
    }
    if (direction === 'toLeft') {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Modal
      className={`modal__notifications-info ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
      closeBackground={() => closeBackground && close()}
    >
      <div className="header__notifications-info">
        <div className="left" />
        <div className="centerContainer" />
        <div className="right">
          <img src={IconClose} alt="cerrar" onClick={() => close()} />
        </div>
      </div>
      <div className="body__notifications-info">
        <Carousel
          className="carousel-notifications-info-list"
          pixelsPerStep={isMobileDevice ? 348 : 562}
          comunicationPosition={comunicationPos}
          currentPage={currentPage}
        >
          {isMobileDevice ? notificationsList.map((notification) => (
            <>
              <div className="container-notifications">
                <div className="infoCollectionType">
                  <Typography
                    type={notification.collectionTypograghy || ''}
                    text={notification.collectionType || ''}
                  />
                </div>
                <div className="infoState">
                  <Typography
                    type={notification.stateTypography}
                    text={notification.state}
                  />
                </div>
                <div className="infoPrice">
                  <Typography
                    type={notification.priceTypography}
                    text={notification.price}
                  />
                </div>
                <div className="left-info-image">
                  <img
                    alt="sin recaudo"
                    className="notificationImage"
                    src={notification.src}
                  />
                </div>
              </div>
            </>
          )) : (
            <div className="page-Container">
              {notificationsList.map((notification, index) => (
                <div key={`${index + 1}`} className="container-notifications">
                  <div className="infoCollectionType">
                    <Typography
                      type={notification.collectionTypograghy || ''}
                      text={notification.collectionType || ''}
                    />
                  </div>
                  <div className="infoState">
                    <Typography
                      type={notification.stateTypography}
                      text={notification.state}
                    />
                  </div>
                  <div className="infoPrice">
                    <Typography
                      type={notification.priceTypography}
                      text={notification.price}
                    />
                  </div>
                  <div className="infoImage">
                    <img
                      alt="sin recaudo"
                      className="notificationImage"
                      src={notification.src}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Carousel>
        <div className="page-indicators">
          {isMobileDevice ? indicator.map((index, idx) => (
            <div
              key={`${idx + 1}indicator`}
              className={`page-indicator ${currentPage === index ? 'selected' : ''}`}
            />
          ),
          ) : (
            <div className="page-indicators">
              {indicator.slice(0, 4).map((index, idx) => (
                <div
                  key={`page${idx + 1}indicator`}
                  className={`page-indicator ${currentPage === index ? 'selected' : ''}`}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

NotificationsInfoModal.propTypes = {
  close: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  notificationsList: PropTypes.arrayOf(PropTypes.instanceOf(Image)).isRequired,
  closeBackground: PropTypes.bool,
  indicator: PropTypes.arrayOf(number).isRequired,
  countryCode: PropTypes.string.isRequired,
};

NotificationsInfoModal.defaultProps = {
  closeBackground: false,
};

export default NotificationsInfoModal;
