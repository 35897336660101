import React from 'react';
import Prototype from 'prop-types';

import './pickupTag.scss';
import truckImage from '../../assets/images/icons/truck-solid.svg';
import wareHouseImage from '../../assets/images/icons/warehouse-solid.svg';

const PickupTag = ({ pickup }) => (
  <div className="pickup-tag-container">
    <img src={(pickup) ? truckImage : wareHouseImage} alt="" />
    <span>{(pickup) ? 'Recolección' : 'Llevar a la sucursal'}</span>
  </div>
);

PickupTag.propTypes = {
  pickup: Prototype.bool.isRequired,
};

export default PickupTag;
