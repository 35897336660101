import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UpdateUserAction } from '../../../store/actions/auth.actions';

import { Process } from './enums';

import NegotiationsList from './process/negotiationsList';
import DetailNegotiation from './process/detailNegotiation';
import NoNegotiationsList from './process/noNegotiationsList';
import CreateAndUpdateNegotiation from './process/createAndUpdateNegotiation';

import './negotiations.scss';

const Negotiations = () => {
  const deliveryCompanies = useSelector((state) => state.globalData.deliveryCompanies);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(null);
  const [data, setData] = useState({
    history: { goBack: () => (navigate(-1)) },
    setCurrentStep,
  });

  useEffect(() => {
    if (deliveryCompanies) {
      setData({ ...data, deliveryCompanies: deliveryCompanies?.filter((company) => company.saas) });
    }
  }, [deliveryCompanies]);

  const updateUser = (user) => {
    dispatch(UpdateUserAction((user)));
  };

  const steps = () => {
    switch (currentStep) {
      case Process.NoNegotiantionList:
        return (
          <NoNegotiationsList
            object={data}
            setObject={setData}
          />
        );
      case Process.CreateAndUpdateNegotiation:
        return (
          <CreateAndUpdateNegotiation
            object={data}
            setObject={setData}
            updateUser={updateUser}
          />
        );
      case Process.DetailNegotiation:
        return (
          <DetailNegotiation
            object={data}
            setObject={setData}
          />
        );
      default:
        return (
          <NegotiationsList
            object={data}
            setObject={setData}
            updateUser={updateUser}
          />
        );
    }
  };
  return (
    <div id="bargainings">
      {steps()}
    </div>
  );
};

export default Negotiations;
