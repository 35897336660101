/* eslint-disable quote-props */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getApikey } from 'services/user';
import { apiKeyPriceLimit, validateUserBillingData, validateUserInfo } from 'constants/general';

import ApiKey from './apiKey';
import EmptyStateCreditUser from './emptyState/emptyStateCredit';
import EmptyStateUsers from './emptyState/emptyStateUsers';

import Typography from '../../../components/typography';

import './integrations.scss';

const IntegrationsScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const countryCode = useSelector((state) => state.country);
  const user = useSelector((state) => state.auth.user);
  const [apiKey, setApiKey] = useState(null);

  const getApikeyService = async () => {
    const getKey = await getApikey(user._id);
    setApiKey(getKey.apikey);
  };

  useEffect(() => {
    getApikeyService();
  }, [user]);

  const hasInsufficientBalance = user?.cash < apiKeyPriceLimit[countryCode];
  const hasCompleteBankData = user?.accountBank;
  const hasCompleteProfile = validateUserInfo(user);
  const missingUserBillingData = validateUserBillingData(user);

  const hasInsufficientCreditLimit = (!user?.creditLimitCop
  || user?.creditLimitCop < apiKeyPriceLimit[countryCode])
  && hasInsufficientBalance;

  const isMissingAllUserData = hasCompleteBankData
  && hasCompleteProfile && missingUserBillingData;

  const showEmptyState = !missingUserBillingData
  || (!isMissingAllUserData && hasInsufficientCreditLimit);

  const showEmptyStateData = !missingUserBillingData
  || (!isMissingAllUserData && hasInsufficientBalance);

  let content;
  if (user?.creditPayment && showEmptyState && !apiKey) {
    content = <EmptyStateCreditUser isMobileDevice={isMobileDevice} />;
  } else if (!user?.creditPayment && showEmptyStateData && !apiKey) {
    content = <EmptyStateUsers isMobileDevice={isMobileDevice} />;
  } else {
    content = (
      <ApiKey
        user={user}
        apiKey={apiKey}
        setApiKey={setApiKey}
        isMobileDevice={isMobileDevice}
      />
    );
  }

  return (
    <div id="integrations" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className={`header-integrations ${isMobileDevice ? 'header-mobile' : 'desktop-mode'}`}>
        <div className="content-back">
          <Typography
            type="superTitle-italic"
            text="Integraciones"
            className="button-back"
          />
        </div>
      </div>
      <div className="integrations">
        {!isMobileDevice && (
        <div className="left-menu">
          <div className="select-menu">
            <Typography
              text="Claves"
              className="text-menu"
            />
          </div>
        </div>
        )}
        <div className="right-menu">
          {content}
        </div>
      </div>
    </div>
  );
};

export default IntegrationsScreen;
