/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-useless-constructor */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';

import Typography from '../typography';
import Button from '../button';
import { ReactComponent as IconClose } from '../../assets/images/icon-close-color-primary.svg';
import { ReactComponent as IconArrowBack } from '../../assets/images/icon-arrow-back-color-primary.svg';

import './modalChildren.scss';

const getDefaultButtonType = (buttonType, defaultValue) => {
  const allowedTypes = ['primary', 'secondary', 'tertiary', 'quaternary'];
  return allowedTypes.includes(buttonType) ? buttonType : defaultValue;
};

const ModalChildren = ({
  isMobileDevice, onClick, className,
  headerText, headerChildren, noIconClose, close, back,
  bodyText, bodyChildren, children,
  executingAction,
  confirmButtonText, confirmButtonType, onClickButtonConfirm, confirmButtonDisabled,
  cancelButtonText, cancelButtonType, onClickButtonCancel, footerChildren, classButtonConfirm,
  classButtonCancel, rightImgButtonConfirm,
}) => (
  <div
    className={`modal-children ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'} ${className ? className : ''}`}
    onClick={(e) => {
      e.stopPropagation();
      if (onClick) onClick();
    }}
  >
    {(headerText || headerChildren || !noIconClose) && (
      <div className="modal-children-header">
        {back && (
          <button
            type="button"
            onClick={() => !executingAction && back()}
            className={executingAction ? 'disabled' : ''}
            aria-label="Volver"
          >
            <IconArrowBack />
          </button>
        )}
        {(!back && close && !noIconClose) && (
          <div className="padding-left" />
        )}
        <div className="container-content-header">
          {headerText && <Typography type="superTitle-italic" className="title-modal-children-header" text={headerText} />}
          {headerChildren}
        </div>
        {(close && !noIconClose) && (
          <button
            type="button"
            onClick={() => !executingAction && close()}
            className={`close ${executingAction ? 'disabled' : ''}`}
            aria-label="Cerrar"
          >
            <IconClose />
          </button>
        )}
      </div>
    )}
    {(bodyText || bodyChildren || children) && (
      <div className="modal-children-body">
        {bodyText && <Typography type="paragraph" text={bodyText} />}
        {bodyChildren}
        {children}
      </div>
    )}
    {(onClickButtonConfirm || onClickButtonCancel || footerChildren) && (
      <div className="modal-children-footer">
        {footerChildren}
        <div className="container-buttons-footer">
          {onClickButtonConfirm && (
            <Button
              className={classButtonConfirm}
              text={confirmButtonText || 'Continuar'}
              type={getDefaultButtonType(confirmButtonType, 'primary')}
              loading={executingAction}
              disabled={confirmButtonDisabled}
              rightImgButton={rightImgButtonConfirm}
              onClick={() => onClickButtonConfirm && onClickButtonConfirm()}
            />
          )}
          {onClickButtonCancel && (
            <Button
              className={classButtonCancel}
              text={cancelButtonText || 'Cancelar'}
              type={getDefaultButtonType(cancelButtonType, 'secondary')}
              disabled={executingAction}
              onClick={() => onClickButtonCancel && onClickButtonCancel()}
            />
          )}
        </div>
      </div>
    )}
  </div>
);

ModalChildren.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classButtonConfirm: PropTypes.string,
  classButtonCancel: PropTypes.string,
  rightImgButtonConfirm: PropTypes.string,
};

ModalChildren.defaultProps = {
  className: null,
  classButtonConfirm: null,
  classButtonCancel: null,
  rightImgButtonConfirm: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default connect(mapStateToProps)(ModalChildren);
