/* eslint-disable */
import { removeThousandPoint } from '../../../utils/strings';

export const typeInput = (value, type, onChange, onChangeCustom) => {
  switch (type) {
    case 'number':
      onChange(removeThousandPoint(value.target.value));
      break;
    case 'number-text':
      onChange(value.target.value.replace(/\D/g, ''));
      break;
    case 'file':
      if (!onChange) {
        onChangeCustom(value);
      } else {
        onChange(value.target);
      }
      break;
    case 'currency':
      onChange(
        value.target.value
          .replace(/[^\d.]/g, '')
          .replace(/,/g, '')
          .replace(/(?<=\.\d{2})\d+|(?<=\.\d)\d{2}/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      );
      break;
    default:
      onChange(value.target.value);
  }
};
