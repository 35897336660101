/* eslint-disable react/prop-types */
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import Typography from '../../../../components/typography';

import IconArrowBack from '../../../../assets/images/icon-arrow-back-color-primary.svg';

import './steps.scss';
import Button from '../../../../components/button';
import { Actions, Process } from '../enums';

const supportNegotiationSelected = (negotiation, showNegotiationInformation) => {
  const companySelected = showNegotiationInformation?.find(
    (company) => company.deliveryCompany === negotiation);
  if (!companySelected) return { message: 'compañía no encontrada' };
  const { homologation, mask } = companySelected;
  return { homologation, mask };
};

const DetailNegotiation = ({ object, showNegotiationInformation, setObject }) => {
  const [deliverySelected] = useState(object?.deliveryCompanies?.find(
    (company) => company._id === object.deliveryToDetail.deliveryCompanyId));
  const [homologation, setHomologation] = useState(null);
  const [maskData, setMaskData] = useState(null);
  const [negotiationSelected] = useState(object.deliveryToDetail);
  const [keysArray, setKeysArray] = useState(null);

  useEffect(() => {
    const result = supportNegotiationSelected(
      object.deliveryToDetail.deliveryCompanyId, showNegotiationInformation);
    setHomologation(result.homologation);
    setMaskData(result.mask);
  }, []);

  useEffect(() => {
    if (homologation) setKeysArray(Object.keys(homologation));
  }, [homologation]);

  const ArroyDetail = ({ index }) => (
    <div className="detail-arrow">
      <div className="detail-column">
        <Typography
          text={homologation[keysArray[index]]}
          type="value"
          className="title-detail"
        />
        <Typography
          text={maskData && maskData.find((maskProperty) => maskProperty === keysArray[index]) ? '**********' : negotiationSelected[keysArray[index]]}
          type="value"
          className="value-detail"
        />
      </div>
      <div className="detail-column">
        {keysArray[index + 1] && (
          <>
            <Typography
              text={homologation[keysArray[index + 1]]}
              type="value"
              className="title-detail"
            />
            <Typography
              text={maskData && maskData.find((maskProperty) => maskProperty === keysArray[index + 1]) ? '**********' : negotiationSelected[keysArray[index + 1]]}
              type="value"
            />
          </>
        )}
      </div>
      <div className="detail-column">
        {keysArray[index + 2] && (
          <>
            <Typography
              text={homologation[keysArray[index + 2]]}
              type="value"
              className="title-detail"
            />
            <Typography
              text={maskData && maskData.find((maskProperty) => maskProperty === keysArray[index + 2]) ? '**********' : negotiationSelected[keysArray[index + 2]]}
              type="value"
            />
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="container-bargainings">
      <div className="principal-header">
        <img src={IconArrowBack} alt="atras" className="arrow-back" onClick={() => object.setCurrentStep(null)} />
        <Typography
          type="superTitle-italic"
          text={deliverySelected.name?.toUpperCase() || 'Detalle de tus negociaciones'}
        />
      </div>
      <div className="wrapper four">
        <div className="body">
          <div className="container-detail">
            {keysArray?.map((key, index) => {
              if (Number.isInteger(index / 3)) {
                return (
                  <ArroyDetail
                    index={index}
                  />
                );
              }
              return null;
            })}
          </div>
          <div className="button-container-detail">
            <Button
              text="Editar vínculo"
              type="tertiary"
              onClick={() => {
                setObject({ ...object, deliverySelected, action: Actions.Update });
                object.setCurrentStep(Process.CreateAndUpdateNegotiation);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DetailNegotiation.propTypes = {
  object: PropTypes.shape().isRequired,
  showNegotiationInformation: PropTypes.shape(),
  setObject: PropTypes.func.isRequired,
};

DetailNegotiation.defaultProps = {
  showNegotiationInformation: [],
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  showNegotiationInformation: state.globalData.showNegotiationInformation || [],
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps),
)(DetailNegotiation);
