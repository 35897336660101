/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import './checkBox.scss';

const CheckBox = ({
  className,
  onChange,
  onClick,
  disabled,
  checked,
  text,
  secondText,
  childrenRigth,
  annotation,
  annotationError,
  clickOnText,
  clickOnSeconText,
  selectedColor,
  leftText,
  textColor,
  checkedClassContainer,
}) => (
  <>
    <label className={`container-checkbox-and-label ${checked ? checkedClassContainer || '' : ''} ${className}`}>
      {leftText && (
        <span
          className={`text-checkbox ${clickOnText ? 'color-blue' : ''} ${textColor || ''}`}
          onClick={(e) => {
            if (clickOnText) {
              e.preventDefault();
              e.stopPropagation();
              clickOnText(e);
            }
          }}
        >
          {text}
        </span>
      )}
      <div className="container-checkbox">
        <div className={`background-checkbox ${checked ? 'checked' : 'no-checked'}`} />
        <input
          onChange={(e) => {
            if (onChange) onChange(e.target.checked);
            if (onClick) onClick(e);
          }}
          type="checkbox"
          disabled={disabled}
          checked={checked}
        />
        <span className={`checkmark ${selectedColor}`} />
      </div>
      {!leftText && (
        <div>
          <span
            className={`text-checkbox ${clickOnText ? 'color-blue' : ''}`}
            onClick={(e) => {
              if (clickOnText) {
                e.preventDefault();
                e.stopPropagation();
                clickOnText(e);
              }
            }}
          >
            {childrenRigth || text}
          </span>
          {secondText && (
            <span
              className={`second-text-checkbox ${clickOnSeconText ? 'color-blue' : ''}`}
              onClick={(e) => {
                if (clickOnSeconText) {
                  e.preventDefault();
                  e.stopPropagation();
                  clickOnSeconText(e);
                }
              }}
            >
              {secondText}
            </span>
          )}
        </div>
      )}
    </label>
    {annotation && (
      <span
        className={`annotation__checkbox-general ${annotation ? 'visible' : ''}`}
      >
        *
        {annotation}
      </span>
    )}
    {annotationError && (
      <span
        className={`annotation__checkbox-general error ${(annotationError) ? 'visible' : ''}`}
      >
        *
        {annotationError}
      </span>
    )}
  </>
);

CheckBox.propTypes = {
  className: PropTypes.string,
  childrenRigth: PropTypes.element,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  clickOnSeconText: PropTypes.func,
  clickOnText: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  text: PropTypes.string,
  annotation: PropTypes.string,
  annotationError: PropTypes.string,
  selectedColor: PropTypes.string,
  leftText: PropTypes.bool,
  textColor: PropTypes.string,
  checkedClassContainer: PropTypes.string,
  secondText: PropTypes.string,
};

CheckBox.defaultProps = {
  className: '',
  childrenRigth: null,
  onChange: null,
  onClick: null,
  clickOnSeconText: null,
  disabled: false,
  text: '',
  annotation: null,
  annotationError: null,
  clickOnText: null,
  selectedColor: '',
  leftText: false,
  textColor: null,
  checkedClassContainer: null,
  secondText: null,
};

export default CheckBox;
