/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Modal from '../../components/modal';
import Input from '../../components/input';
import Select from '../../components/select';
import Button from '../../components/button';
import CheckBox from '../../components/checkBox';
import Typography from '../../components/typography';
import RadioButton from '../../components/radioButton';
import ModalChildren from '../../components/modalChildren';
import {
  validateAccountNumber, validateBank, validateDocumentNumer, validateDocumentType,
  validateNameOfOwner, validateSupportAccount, veirfyAccountsNumber,
} from './validate';
import { documentTypeList } from '../../constants/general';
import { findObjectInArray } from '../../utils/filters';
import ContainerSectionMobile from '../../components/containerSectionMobile';
import ContainerSectionDesktop from '../../components/containerSectionDesktop';
import { ReactComponent as ArrowBack } from '../../assets/images/icon-arrow-back-color-primary.svg';

import updateAccountBankService from './service';
import { UpdateUserAction } from '../../store/actions/auth.actions';
import { userName } from '../../utils/strings';
import bankDataByUserCountryCode from '../../utils/banks';
import { useValidationCountry, useFetchIpData } from '../../utils/validationCountry';

import Logo from '../../assets/images/add.svg';
import isTheMessageVisible from './bankMessageShowLogic';
import { validateCodeService } from '../profileScreen/profile/service';

import './editBankData.scss';

const EditBankData = ({
  user,
}) => {
  const { code } = useParams();
  const { bankTransferFee } = useSelector((state) => state.globalData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [bank, setBank] = useState(null);
  const [loadgin, setLoading] = useState(false);
  const [support, setSupport] = useState(null);
  const [fileName, setFileName] = useState('Adjuntar archivo PDF, JPG o PNG');
  const [errorsList, setErrorsList] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [accountType, setAccountType] = useState(null);
  const [nameOfOwner, setNameOfOwner] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [successModal, setSuccessModal] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [documentNumber, setDocumentNumber] = useState(null);
  const [verifyAccountNumber, setVerifyAccountNumber] = useState(null);
  const [diferentsDataUser, setDiferentDataUser] = useState(false);
  const [countryBankData] = useState(bankDataByUserCountryCode(bankTransferFee));

  useEffect(async () => {
    const validatedCode = await validateCodeService('accountBank', code);
    if (!user || validatedCode?.message?.toLowerCase() !== 'ok') navigate('/');
  }, []);

  useEffect(() => { if (successModal === false) navigate('/perfil/datos-bancarios'); }, [successModal]);

  const requiredData = (bank && support && accountNumber && accountType);
  const optionalData = (nameOfOwner, documentNumber, documentType);
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const dataReady = () => {
    if (errorsList.length > 0) return true;

    if (diferentsDataUser && requiredData && optionalData) return false;
    return !(!diferentsDataUser && requiredData);
  };

  const buildBankData = async () => {
    setLoading(true);
    if (errorsList.length > 0) setErrorModal(true);
    if (!requiredData) setErrorModal(true);
    const dataToUpdate = {
      accountBank: {
        bank: bank.split('-')[0],
        beneficiaryName: diferentsDataUser ? nameOfOwner : userName(user.name, user.surname),
        accountType,
        accountNumber,
        typeId: diferentsDataUser ? documentType : user.documentType,
        numberId: diferentsDataUser ? documentNumber : user.documentNumber,
      },
      code,
    };

    if (errorsList.length <= 0 && requiredData) {
      const userUpdated = await updateAccountBankService(dataToUpdate, support, fileName, user._id);
      if (userUpdated.error) setErrorModal(true);
      if (!userUpdated.error) {
        setSuccessModal(true);
        dispatch(UpdateUserAction(userUpdated));
      }
    }

    setLoading(false);
    setTimeout(() => {
      setErrorModal(false);
      if (!isTheMessageVisible()) {
        setSuccessModal(false);
      }
    }, 3000);
  };

  return (
    <div id="edit-bank-account">
      <div className="header">
        <ArrowBack onClick={() => navigate(-1)} className="arrowBack" />
        <Typography
          type="superTitle-italic"
          text="Nueva cuenta bancaria"
          className="header-text"
        />
      </div>
      <div className="body">
        <div className="body-container">
          <div className="inputs-container">
            <Typography
              text="Asegúrate que queden bien escritos los nuevos datos bancarios. Todos los campos son obligatorios."
              type="field-gray-light"
              className="custom-body-text"
            />
            <div className="row separete">
              <Input
                value={bank}
                onChange={setBank}
                type="text"
                dataList={countryBankData.banksWithTranferFee}
                label="Entidad Bancaria"
                placeholder="Sin escribir"
                svgClasscss="search-icon-blue orange-svg-search-edit-bank-data"
                changeColorsvg
                onBlur={(e) => e.target.value && validateBank({
                  errorsList,
                  setErrorsList,
                  bank: e.target.value,
                  banksWithTranferFee: countryBankData.banksWithTranferFee,
                })}
                onFocus={() => setErrorsList(errorsList.filter((item) => item.name !== 'bank'))}
                annotationError={
                  findObjectInArray('bank', errorsList, 'name')
                    ? findObjectInArray('bank', errorsList, 'name').error
                    : null
                }
              />
              <div className="container-radio-buttons__bank-data">
                <Typography type="field-gray-light" text="Tipo de cuenta" className="darker" />
                <RadioButton
                  name="account-type"
                  text="Ahorros / Ahorro a la mano"
                  value="ahorros"
                  onChange={setAccountType}
                  checked={accountType === 'ahorros'}
                  className="primeryColor"
                />
                <RadioButton
                  name="account-type"
                  text="Corriente"
                  value="corriente"
                  onChange={setAccountType}
                  checked={accountType === 'corriente'}
                  className="primeryColor"
                />
              </div>
            </div>
            <div className="row">
              <Input
                value={accountNumber}
                onChange={setAccountNumber}
                type="number-text"
                label="Número de cuenta"
                placeholder="Sin escribir"
                onBlur={() => (accountNumber
                  && validateAccountNumber({ errorsList, setErrorsList, accountNumber })
                )}
                onFocus={() => setErrorsList(errorsList.filter((item) => item.name !== 'accountNumber'))}
                annotationError={
                  findObjectInArray('accountNumber', errorsList, 'name')
                    ? findObjectInArray('accountNumber', errorsList, 'name').error
                    : null
                }
              />
              <Input
                value={verifyAccountNumber}
                onChange={setVerifyAccountNumber}
                type="number-text"
                label="Confirma el número de cuenta"
                placeholder="Sin escribir"
                onBlur={() => (verifyAccountNumber
                  && veirfyAccountsNumber({
                    errorsList, setErrorsList, accountNumber, verifyAccountNumber,
                  })
                )}
                onFocus={() => setErrorsList(errorsList.filter((item) => item.name !== 'verifyAccountNumber'))}
                annotationError={
                  findObjectInArray('verifyAccountNumber', errorsList, 'name')
                    ? findObjectInArray('verifyAccountNumber', errorsList, 'name').error
                    : null
                }
              />
            </div>
            <div className="row">
              <Input
                label="Certificación bancaria"
                value={fileName || 'Adjuntar archivo PDF, JPG o PNG'}
                onChange={(target) => {
                  setSupport(target.files[0]);
                  setFileName(target.files[0]?.name);
                  if (target.files[0]?.name) {
                    validateSupportAccount({
                      errorsList, setErrorsList, fileName: target.files[0]?.name, accept: 'pdf,jpg,jpeg,png',
                    });
                  }
                }}
                classNameContainer="file-container"
                type="file"
                iconLeft={Logo}
                leftIconCustomClass="add_box"
                typeHidden
                lineHidden
                errorLineHidden
                accept="image/png, image/jpeg, application/pdf"
                annotationError={
                  findObjectInArray('support', errorsList, 'name')
                    ? findObjectInArray('support', errorsList, 'name').error
                    : null
                }
              />
              <CheckBox
                text="Los datos del titular de la cuenta son diferentes a los de mi usuario."
                onChange={setDiferentDataUser}
                checked={diferentsDataUser}
                className="check-row"
              />
            </div>
            {diferentsDataUser && (
              <>
                <div className="row">
                  <Input
                    value={nameOfOwner}
                    onChange={setNameOfOwner}
                    type="text"
                    label="Nombre del titular"
                    placeholder="Sin escribir"
                    onBlur={() => (
                      nameOfOwner && validateNameOfOwner({ errorsList, setErrorsList, nameOfOwner })
                    )}
                    onFocus={() => setErrorsList(errorsList.filter((item) => item.name !== 'nameOfOwner'))}
                    annotationError={
                      findObjectInArray('nameOfOwner', errorsList, 'name')
                        ? findObjectInArray('nameOfOwner', errorsList, 'name').error
                        : null
                    }
                  />
                  <Select
                    label="Tipo de idenficación"
                    value={documentType}
                    onChange={(e) => {
                      setDocumentType(e);
                      validateDocumentType({ errorsList, setErrorsList, documentType: e });
                    }}
                    nonBlur={() => setErrorsList(errorsList.filter((item) => item.name !== 'documentType'))}
                    className="select-identification-type"
                    options={documentTypeList}
                    annotationError={
                      findObjectInArray('documentType', errorsList, 'name')
                        ? findObjectInArray('documentType', errorsList, 'name').error
                        : null
                    }
                  />
                </div>
                <div className="row">
                  <Input
                    value={documentNumber}
                    onChange={setDocumentNumber}
                    type="text"
                    label="Número de identificación"
                    placeholder="Sin escribir"
                    onBlur={() => (
                      documentNumber && validateDocumentNumer(
                        { errorsList, setErrorsList, documentNumber },
                      )
                    )}
                    onFocus={() => setErrorsList(errorsList.filter((item) => item.name !== 'documentNumber'))}
                    annotationError={
                      findObjectInArray('documentNumber', errorsList, 'name')
                        ? findObjectInArray('documentNumber', errorsList, 'name').error
                        : null
                    }
                  />
                </div>
              </>
            )}
          </div>
          <div className="row buttons-container">
            <Button
              text="Cancelar"
              onClick={() => navigate(-1)}
              type="secondary"
            />
            <Button
              text="Guardar nueva cuenta"
              disabled={dataReady()}
              onClick={() => {
                if (accountNumber !== verifyAccountNumber) {
                  veirfyAccountsNumber({
                    errorsList, setErrorsList, accountNumber, verifyAccountNumber,
                  });
                }
                if (accountNumber === verifyAccountNumber) buildBankData();
              }}
              type={isCountryValid ? 'primary-mx' : 'primary'}
              loading={loadgin}
            />
          </div>
        </div>
      </div>

      {successModal && (
        <Modal className="update-bank-data-modal-container" closeBackground={() => setSuccessModal(false)}>
          <ModalChildren
            headerText="¡Tu número de cuenta ha sido actualizado!"
            className="edit-bank-data-success-modal"
            close={() => setSuccessModal(false)}
          >
            {isTheMessageVisible() && (
              <>
                <div className="text-container">
                  <Typography
                    type="field-gray-light"
                    className="sucess-modal-text"
                  >
                    <span className="text">
                      Ten presente que el cambio de cuenta bancaria que acabas de
                      realizar lo aplicaremos para la transferencia de
                      tus recaudos de la
                    </span>
                    <span className="assert-text">próxima semana.</span>
                  </Typography>
                </div>
                <div className="button-container">
                  <Button
                    text="¡Entendido!"
                    type={isCountryValid ? 'primary-mx' : 'primary'}
                    onClick={() => {
                      setSuccessModal(false);
                      navigate('/perfil/datos-bancarios');
                    }}
                    className="new-width"
                  />
                </div>
              </>
            )}
          </ModalChildren>
        </Modal>
      )}
      {errorModal && (
        <Modal className="update-bank-data-modal-container">
          <ModalChildren
            headerText="¡Ups! no se pudo guardar la nueva cuenta."
            className="edit-bank-data-success-modal"
          >
            <div>
              <Typography
                text="Inténtalo más tarde o comunícate con el equipo de mipaquete.com"
                type="field-gray-light"
                className="sucess-modal-text"
              />
            </div>
          </ModalChildren>
        </Modal>
      )}
    </div>
  );
};

const EditBankDataScreen = () => {
  const { user } = useSelector((state) => state.auth);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  return (
    isMobileDevice ? (
      <ContainerSectionMobile className="profile-information">
        <EditBankData
          user={user}
          isMobileDevice={isMobileDevice}
        />
      </ContainerSectionMobile>
    ) : (
      <ContainerSectionDesktop>
        <EditBankData
          user={user}
        />
      </ContainerSectionDesktop>
    )
  );
};

export default EditBankDataScreen;
