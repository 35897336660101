import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconDropDown from '../../assets/images/arrow_orange.svg';

import './imageSelect.scss';

const ImageSelect = ({
  imageSelectData, deliverySelectedForAllImage, onClick,
}) => {
  const [showSearchCriteria, setShowSearchCriteria] = useState(false);
  const [changeNameSelect, setChangeNameSelect] = useState(null);

  return (
    <div
      className={`container-select-filter ${showSearchCriteria ? 'show' : ''}`}
      onClick={() => setShowSearchCriteria(!showSearchCriteria)}
    >
      <div className="container-selector">
        <span className="label">{changeNameSelect || deliverySelectedForAllImage}</span>
        <img src={IconDropDown} alt="" />
      </div>
      <div className="container-options">
        {imageSelectData.map((delivery) => (
          <span
            key={delivery.id}
            className="options-delivery-company"
            onClick={() => {
              onClick(delivery.selectByFilter);
              setChangeNameSelect(delivery.name);
            }}
          >
            <img src={delivery.img} alt="" width="16px" />
            {delivery.name}
          </span>
        ))}
      </div>
    </div>
  );
};

ImageSelect.propTypes = {
  imageSelectData: PropTypes.func,
  deliverySelectedForAllImage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ImageSelect.defaultProps = {
  imageSelectData: [],
  deliverySelectedForAllImage: '',
};

export default ImageSelect;
