/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';

import ShippingDetails from '../../../../components/shippingDetails';

import { getShipmentPaidService } from '../../../../services/billing';
import { getSendingToExcel } from '../../../../services/sendings';
import { findImageIntoDeliveries, findNameIntoDeliveries } from '../../../../utils/generals';
import { capitalizeWords, toCurrencyCop } from '../../../../utils/strings';
import Button from '../../../../components/button';

import IconArrowBack from '../../../../assets/images/icon-arrow-back-color-primary.svg';

import './shipmentDetail.scss';

// eslint-disable-next-line consistent-return
const ShipmentDetail = ({ guideNumber, deliveryCompanies, handleTicketDetail }) => {
  const user = useSelector((state) => state.auth.user);
  const [dataShipments, setDataShipments] = useState(null);
  const [dataDetailShipment, setDataShipmentDetail] = useState(null);
  const [showDetailShipment, setShowDetailShipment] = useState(false);

  useEffect(async () => {
    const data = await getShipmentPaidService({ guideNumber });
    if (data) setDataShipments(data[0]);

    const dataDetail = await getSendingToExcel({
      user: user._id, guideNumber, pageSize: 1,
    }, 1);
    if (dataDetail) setDataShipmentDetail(dataDetail.sendings[0]);
  }, [guideNumber]);

  const dateCorrection = (courtDate) => {
    if (courtDate) return new Date(courtDate).toLocaleDateString('en-GB');
    return '';
  };
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  if (!isMobileDevice && dataShipments && !showDetailShipment) {
    return (
      <div id="shipmentDetail-collection">
        <div className="top">
          <div className="left">
            <img src={IconArrowBack} alt="icon" className="arrow-back" onClick={() => handleTicketDetail(null, null)} />
            <h2>
              {`MP${dataShipments.code} - ${dataShipments.guideNumber}`}
            </h2>
          </div>
        </div>
        <div className="image-delivery">
          <img
            src={findImageIntoDeliveries(dataShipments.deliveryCompany, deliveryCompanies)}
            alt=""
            className="deliverycompanycollection"
          />
        </div>
        <div className="flexbox-container">
          <div>
            <h3>Destinatario</h3>
            <p>{`${dataShipments?.receiver?.name} ${dataShipments?.receiver?.surname}`}</p>
            <p>{dataShipments?.receiver?.cellPhone}</p>
            <p>{capitalizeWords(dataShipments?.receiver?.destinationAddress)}</p>
            <p>{dataShipments?.receiver?.email}</p>
          </div>
          <div>
            <h3>Estado del envío</h3>
            <p className={dataShipments?.state === 'Entregado' ? 'blue' : 'red'}>{dataShipments.state}</p>
          </div>
          <div>
            <h3>Método de pago</h3>
            <p>{dataShipments?.paymentType === 102 ? 'Descuento del valor recaudado' : 'Pago con saldo'}</p>
          </div>
          <div>
            <h3>Valor del recaudo</h3>
            <p>{toCurrencyCop(user, dataShipments.valueCollection)}</p>
          </div>
          <div>
            <h3>Valor del envío</h3>
            <p>{toCurrencyCop(user, dataShipments.price)}</p>
          </div>
          <div>
            <h3>Fecha de entrega</h3>
            <p>{dateCorrection(dataShipments.deliveryDate)}</p>
          </div>
        </div>
        <div className="flexbox-container-bottom">
          <div>
            <h3>Valor descontado</h3>
            <p>{toCurrencyCop(user, dataShipments.discountValue)}</p>
          </div>
          <div>
            <h3>Valor transferido</h3>
            <p>{toCurrencyCop(user, dataShipments.transferCost)}</p>
          </div>
          <div>
            <h3>Fecha de pago</h3>
            <p>{dateCorrection(dataShipments.paymentDate)}</p>
          </div>
        </div>
        <Button
          text="Ver detalle de envío"
          type="tertiary"
          className="button-shipment-detail"
          onClick={() => setShowDetailShipment(true)}
        />
      </div>
    );
  }
  if (!dataShipments) {
    return (<div />);
  }
  if (dataDetailShipment && showDetailShipment) {
    return (
      <div id="shipmentDetail-collection">
        <div className="top">
          <div className="left">
            <img src={IconArrowBack} alt="icon" className="arrow-back" onClick={() => setShowDetailShipment(false)} />
            <h2>
              {`MP${dataShipments.code} - ${dataShipments.guideNumber}`}
            </h2>
          </div>
        </div>
        <ShippingDetails
          back={() => setDataShipmentDetail(null)}
          deliveryCompanyId={dataDetailShipment.deliveryCompany}
          mpCode={dataDetailShipment['Código mipaquete']}
          guideNumber={dataDetailShipment['Número de Guía']}
          senderName={dataDetailShipment['Nombre del remitente']}
          senderAddress={dataDetailShipment['Dirección Remitente']}
          senderLocation={dataDetailShipment.Origen}
          receiverName={dataDetailShipment.Destinatario}
          receiverEmail={dataDetailShipment['Email Destinatario']}
          receiverCellPhone={dataDetailShipment['Celular del destinatario']}
          receiverAddress={dataDetailShipment['Dirección Destinatario']}
          receiverLocation={dataDetailShipment.Destino}
          articleDescription={dataDetailShipment['Producto o Referencia']}
          articleWidth={dataDetailShipment.width}
          articleHeight={dataDetailShipment.height}
          articleLength={dataDetailShipment.large}
          articleWeight={dataDetailShipment['Peso (kg)']}
          articleWeightVolume={dataDetailShipment['Peso Volumen (kg)']}
          declaredValue={dataDetailShipment['Valor declarado']}
          quantityOfArticles={dataDetailShipment.Cantidad}
          deliveryCompanyImage={findImageIntoDeliveries(
            dataDetailShipment.deliveryCompany, deliveryCompanies,
          )}
          deliveryCompanyName={dataDetailShipment.Transportadora}
          shippingValue={dataDetailShipment['Valor total del servicio']}
          collectionService={dataDetailShipment['Servicio de recaudo'] === 'SI'}
          recommendationsForDeliveryCompany={dataDetailShipment['Observaciones del envío']}
          requestDate={dataDetailShipment['Fecha de Solicitud']}
          saleValue={dataDetailShipment['Valor de la Venta']}
          collectionValue={dataDetailShipment['Valor a recaudar']}
          collectionComision={dataDetailShipment['Valor de la comisión de recaudo']}
          rateValue={dataDetailShipment['Valor del flete']}
          valueToTransfer={dataDetailShipment['Valor a Transferir']}
          GuideLinks={dataDetailShipment.pdfGuide}
          tracking={dataDetailShipment.tracking}
        />
      </div>

    );
  }
  if (isMobileDevice && dataShipments) {
    return (
      <div id="shipmentDetail-collection">
        <div className="top">
          <div className="left">
            <img src={IconArrowBack} alt="icon" className="arrow-back" onClick={() => handleTicketDetail(null, null)} />
            <h2>
              {`MP${dataShipments.code} - ${dataShipments.guideNumber}`}
            </h2>
          </div>
        </div>
        <div className="image-delivery">
          <img
            src={findImageIntoDeliveries(dataShipments.deliveryCompany, deliveryCompanies)}
            alt=""
            className="deliverycompanycollectionMobile"
          />
          <h1>
            {findNameIntoDeliveries(dataShipments.deliveryCompany, deliveryCompanies)}
          </h1>
        </div>
        <div className="container">

          <div className="grid-cell-1">
            <h3>Destinatario</h3>
            <p>{`${dataShipments?.receiver?.name} ${dataShipments?.receiver?.surname}`}</p>
            <p>{dataShipments?.receiver?.cellPhone}</p>
          </div>
          <div className="grid-cell-2">
            <p>{dataShipments?.receiver?.destinationAddress}</p>
            <p>{dataShipments?.receiver?.email}</p>
          </div>
          <div className="grid-cell-3">
            <h3>Estado del envío</h3>
            <p className={dataShipments?.state === 'Entregado' ? 'blue' : 'red'}>{dataShipments.state}</p>
          </div>
          <div className="grid-cell-4">
            <h3>Fecha de entrega</h3>
            <p>{dateCorrection(dataShipments.deliveryDate)}</p>
          </div>
          <div className="grid-cell-5">
            <h3>Método de pago</h3>
            <p>{dataShipments?.paymentType === 102 ? 'Descuento del valor recaudado' : 'Pago con saldo'}</p>
          </div>
          <div className="grid-cell-6">
            <h3>Valor del recaudo</h3>
            <p>{toCurrencyCop(user, dataShipments.valueCollection)}</p>
          </div>
          <div className="grid-cell-7">
            <h3>Valor del envío</h3>
            <p>{toCurrencyCop(user, dataShipments.price)}</p>
          </div>
          <div className="grid-cell-8">
            <h3>Valor transferido</h3>
            <p>{toCurrencyCop(user, dataShipments.transferCost)}</p>
          </div>
          <div className="grid-cell-9">
            <h3>Valor descontado</h3>
            <p>{toCurrencyCop(user, dataShipments.discountValue)}</p>
          </div>
          <div className="grid-cell-10">
            <h3>Fecha de pago</h3>
            <p>{dateCorrection(dataShipments.courtDate)}</p>
          </div>
        </div>
        <Button
          text="Ver detalle de envío"
          type="tertiary"
          className="button-shipment-detail"
          onClick={() => setShowDetailShipment(true)}
        />
      </div>
    );
  }
};

ShipmentDetail.propTypes = {
  guideNumber: Proptypes.string.isRequired,
  deliveryCompanies: Proptypes.string.isRequired,
  handleTicketDetail: Proptypes.string.isRequired,
};

export default ShipmentDetail;
