/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import swal from 'sweetalert';

import { sendEmailToResetPasswordService } from '../../../services/notifications';

import { useValidationCountry } from '../../../utils/validationCountry';

import Modal from '../../../components/modal';

import Input from '../../../components/input';
import Button from '../../../components/button';
import Typography from '../../../components/typography';

import './validateEmail.scss';

const ValidateEmail = ({ next }) => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const countryCode = useSelector((state) => state?.country || null);
  const navigte = useNavigate();

  const isCountryValid = useValidationCountry(countryCode);

  const [email, setEmail] = useState(null);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [executingAction, setExecutingAction] = useState();

  const SendEmailToResetPasswordFunction = async () => {
    setExecutingAction(true);
    try {
      const response = await sendEmailToResetPasswordService(email);
      if (response) {
        setShowNotificationModal(true);
      }
    } catch {
      swal('Error', 'No se pudo realizar la solicitud.');
    } finally {
      setExecutingAction(false);
    }
  };

  return (
    <>
      <div id="validate-email" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
        <Typography
          text="Olvidé mi contraseña"
          type="superTitle"
          className="title-validate-email"
        />

        <div className="container-paragraph__validate-email">
          <Typography
            text="¿Cuál es el correo de tu cuenta?"
            type="value"
          />

          <Typography
            text="Te enviaremos un enlace para reestablecer tu contraseña."
            type="value"
          />
        </div>

        <div className="form__validate-email">
          <Input
            label="Correo electrónico"
            placeholder="usuario@gmail.com"
            value={email}
            onChange={setEmail}
          />

          <Button
            text="Reestablecer contraseña"
            type={isCountryValid ? 'primary-mx' : 'primary'}
            loading={executingAction}
            onClick={() => SendEmailToResetPasswordFunction()}
          />
        </div>
        <a onClick={() => navigte('/ingreso')}>
          <Typography
            text="Si ya tienes cuenta"
            type="value"
            className="text-anchor"
          />
          Ingresa aquí
        </a>
        <a onClick={() => navigte('/registro')}>
          <Typography
            text="¿No tienes cuenta?"
            type="value"
            className="text-anchor"
          />
          Crea tu cuenta aquí
        </a>
      </div>

      {showNotificationModal && (
        <Modal
          className="validate-email__modal-notification"
        >
          <>
            <Typography
              text="Por favor verifica tu correo"
              type="value"
              className="text-notification__validate-email"
            />

            <Typography
              text={email}
              type="superTitle"
              className="text-notification__validate-email email"
            />

            <Typography
              text="Te enviamos un enlace para que reestablezcas tu contraseña"
              type="value"
              className="text-notification__validate-email"
            />

            <Button
              text="¡Gracias!"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => {
                next();
                setShowNotificationModal(true);
              }}
            />
          </>
        </Modal>
      )}
    </>
  );
};

ValidateEmail.propTypes = {
  next: PropTypes.func.isRequired,
};

export default ValidateEmail;
