/* eslint-disable no-param-reassign */

const specificNestedProperty = (newObject, arrayPropertyPath, value) => {
  const lastKeyIndex = arrayPropertyPath.length - 1;
  for (let i = 0; i < lastKeyIndex; i += 1) {
    const key = arrayPropertyPath[i];

    if (!(key in newObject)) newObject[key] = {};
    newObject = newObject[key];
  }

  newObject[arrayPropertyPath[lastKeyIndex]] = value;
};

const buildObjectWithConditionalProperties = (object, value, arrayPropertyPath, verificationType = 'normal') => {
  switch (verificationType) {
    case 'normal':
      if (value) specificNestedProperty(object, arrayPropertyPath, value);
      break;

    case 'array':
      if (value?.length > 0) specificNestedProperty(object, arrayPropertyPath, value);
      break;
    default:
      object = {
        ...object,
      };
      break;
  }
};

const buildFilters = ({
  filterAndPage, filters, numberItemsPerPage,
  nextOrBack, lastDocumentId, mp,
}) => {
  buildObjectWithConditionalProperties(filterAndPage, filters?.deliveryCompanies, ['filters', 'deliveryCompany'], 'array');
  buildObjectWithConditionalProperties(filterAndPage, filters?.states, ['filters', 'state'], 'array');
  buildObjectWithConditionalProperties(filterAndPage, numberItemsPerPage || 25, ['pagination', 'pageSize']);
  buildObjectWithConditionalProperties(filterAndPage, nextOrBack, ['pagination', 'nextOrBackPage']);
  buildObjectWithConditionalProperties(filterAndPage, lastDocumentId, ['pagination', 'lastDocumentId']);
  buildObjectWithConditionalProperties(filterAndPage, mp, ['filters', 'mpCode']);
  buildObjectWithConditionalProperties(filterAndPage, filters?.guideNumber, ['filters', 'guideNumber'], 'array');
  buildObjectWithConditionalProperties(filterAndPage, filters?.receiverCellPhone, ['filters', 'receiverCellphone'], 'array');
  buildObjectWithConditionalProperties(filterAndPage, filters?.destinyLocationCode, ['filters', 'destinyLocation'], 'array');
};

export default buildFilters;
