import queryString from 'query-string';
import { operationsUrl } from '../../constants';
import { redirectToOtherPlatforms } from '../../utils/intercommunication';

const redirectOperationUserRouter = () => {
  const queryParams = queryString.parse(window.location.search);
  const intercommunicationData = queryParams.intercommunicationData
    ? JSON.parse(queryParams.intercommunicationData) : {};

  return intercommunicationData.urlToReturnAfterLogin
    ? redirectToOtherPlatforms({ urlToRedirect: intercommunicationData.urlToReturnAfterLogin })
    : window.location.assign(`${operationsUrl}/buscar-codigo-mp`);
};

export default redirectOperationUserRouter;
