/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
import { findLocationByName } from '../../../utils/filters';
import { removeAccents } from '../../../utils/strings';

export const excelValidation = (arrayData, locations) => {
  if (arrayData) {
    const listErrors = [];
    const regValidateEmail = /^(([^<>()[\]\\#*¨?.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/;

    arrayData.map((sending) => {
      const errors = [];
      const receiverEmail = sending['Email destinatario (Opcional, si lo escribes le podrán llegar notificaciones sobre su envío)'];

      // validations
      if (!sending['Ciudad Origen*'] || sending['Ciudad Origen*'] === '') errors.push('Ciudad Origen requerida');
      if (!sending['Departamento Origen*'] || sending['Departamento Origen*'] === '') errors.push('Departamento origen requerido');
      if (!findLocationByName(
        sending['Ciudad Origen*'],
        sending['Departamento Origen*'],
        locations,
      )) errors.push('Verifique la ciudad y departamento de origen');
      if (!sending['Dirección de recogida, donde se encuentra el paquete.* (Puedes poner en cada fila una dirección diferente para cada paquete)'] || sending['Dirección de recogida, donde se encuentra el paquete.* (Puedes poner en cada fila una dirección diferente para cada paquete)'] === '') errors.push('Dirección de recogida requerida');
      if (!sending['Departamento Destino*'] || sending['Departamento Destino*'] === '') errors.push('Departamento de destino requerido');
      if (!sending['Ciudad Destino*'] || sending['Ciudad Destino*'] === '') errors.push('Ciudad de destino requerida');
      if (!findLocationByName(
        sending['Ciudad Destino*'],
        sending['Departamento Destino*'],
        locations,
      )) errors.push('Verifique la ciudad y departamento de destino');
      if (!sending['Descripción de producto*'] || sending['Descripción de producto*'] === '') errors.push('Descripción de producto requerida');
      if (!sending['Alto* (cm)'] || isNaN(sending['Alto* (cm)']) || sending['Alto* (cm)'] === '') errors.push('Alto requerido');
      if (!isNaN(sending['Alto* (cm)']) && (sending['Alto* (cm)'] > 200 || sending['Alto* (cm)'] < 1)) errors.push('La altura debe estar entre 1 y máximo 200cm');
      if (!sending['Ancho* (cm)'] || isNaN(sending['Ancho* (cm)']) || sending['Ancho* (cm)'] === '') errors.push('Ancho requerido');
      if (!isNaN(sending['Ancho* (cm)']) && (sending['Ancho* (cm)'] > 200 || sending['Ancho* (cm)'] < 1)) errors.push('El ancho debe estar entre 1 y máximo 200cm');
      if (!sending['Largo* (cm)'] || isNaN(sending['Largo* (cm)']) || sending['Largo* (cm)'] === '') errors.push('Largo requerido');
      if (!isNaN(sending['Largo* (cm)']) && (sending['Largo* (cm)'] > 200 || sending['Largo* (cm)'] < 1)) errors.push('El largo debe estar entre 1 y máximo 200cm');
      if (!sending['Peso en Kilos* (por unidad)'] || isNaN(sending['Peso en Kilos* (por unidad)']) || sending['Peso en Kilos* (por unidad)'] === '') errors.push('Peso requerido');
      if (!isNaN(sending['Peso en Kilos* (por unidad)']) && (sending['Peso en Kilos* (por unidad)'] > 200 || sending['Peso en Kilos* (por unidad)'] < 1)) errors.push('El peso debe ser mayor a 1 y máximo 150Kg');
      if (!sending['Unidades logísticas* (Cantidad de paquetes)'] || isNaN(sending['Unidades logísticas* (Cantidad de paquetes)']) || sending['Unidades logísticas* (Cantidad de paquetes)'] === '') errors.push('Unidades requeridas');
      if (!sending['Valor declarado* (sin puntos)'] || isNaN(sending['Valor declarado* (sin puntos)']) || sending['Valor declarado* (sin puntos)'] === '') errors.push('Valor declarado requerido');
      if (!isNaN(sending['Valor declarado* (sin puntos)']) && (sending['Valor declarado* (sin puntos)'] > 4000000 || sending['Valor declarado* (sin puntos)'] < 5000)) errors.push('El valor declarado debe estar entre $5.000 y máximo $4.000.000');
      if (!sending['Nombre destinatario*'] || sending['Nombre destinatario*'] === '') errors.push('Nombre destinatario requerido');
      if (!sending['Apellido destinatario*'] || sending['Apellido destinatario*'] === '') errors.push('Apellido destinatario requerido');
      if (!sending['Dirección destinatario*'] || sending['Dirección destinatario*'] === '') errors.push('Dirección destinatario requerido');
      if (!sending['Celular destinatario*'] || sending['Celular destinatario*'] === '') errors.push('Celular destinatario requerido');
      if (sending['Recomendaciones para la transportadora (Campo opcional. No es válido recomendar que las entregas se hagan en días u horarios específicos. máximo 90 caracteres)']?.toString().length > 90) errors.push('El número máximo de caracteres de las recomendaciones para la transportadora es de 90 caracteres');
      if (receiverEmail !== undefined && !regValidateEmail.test(receiverEmail?.trim())) errors.push('El correo del destinatario no es valido');
      const collectionService = sending['¿Necesitas Servicio de pago Contra Entrega?* (Escribe Si o No)'];
      if (!collectionService || collectionService === '' || !(removeAccents(collectionService).toLowerCase() === 'si' || removeAccents(collectionService).toLowerCase() === 'no')) {
        errors.push('¿Necesita servicio de pago conta entrega? se escribe Si o No');
      }
      const saleValue = sending['Valor de la venta* (Escribe sin puntos el valor de la venta si tu envío es con pago contra entrega, de lo contrario escribe N/A)'];
      if (!saleValue || saleValue === '' || !(!isNaN(saleValue) || saleValue === 'N/A')) {
        errors.push('Escribe el valor de la venta si tu envío es pago contra entrega, de lo contrario escribe "N/A');
      }
      if (!isNaN(sending['Valor de la venta* (Escribe sin puntos el valor de la venta si tu envío es con pago contra entrega, de lo contrario escribe N/A)']) && (sending['Valor de la venta* (Escribe sin puntos el valor de la venta si tu envío es con pago contra entrega, de lo contrario escribe N/A)'] > 4000000 || sending['Valor de la venta* (Escribe sin puntos el valor de la venta si tu envío es con pago contra entrega, de lo contrario escribe N/A)'] < 5000)) errors.push('El valor de la venta debe estar entre $5.000 y máximo $4.000.000');
      const addShippingToCollectionValue = sending['¿Quieres que sumemos el costo del envío al valor de la venta?* (Si tu envío es con Pago Contra Entrega escribe SI o NO, de lo contrario escribe N/A)'];
      if (!addShippingToCollectionValue || addShippingToCollectionValue === '' || !(removeAccents(addShippingToCollectionValue).toLowerCase() === 'si' || removeAccents(addShippingToCollectionValue).toLowerCase() === 'no' || removeAccents(addShippingToCollectionValue).toLowerCase() === 'n/a')) {
        errors.push('Si tu envío es con pago contra entrega escribe "Si" o "No" si desea sumar el envío al valor de la venta, de lo contrario escriba n/a');
      }

      // list errors return
      if (errors.length > 0) {
        listErrors.push({
          row: sending.__rowNum__,
          errors,
        });
      }
      return null;
    });

    return listErrors;
  }
  return null;
};

export default excelValidation;
