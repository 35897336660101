/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import swal from 'sweetalert';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Input from '../../../components/input';
import Button from '../../../components/button';
import Typography from '../../../components/typography';

import { validationValue } from '../../../utils/strings';
import { resetPasswordService, loginService } from '../../../services/user';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import ArrowBack from '../../../assets/images/icon-arrow-back-color-gray-dark.svg';
import IconVisibility from '../../../assets/images/icon-visibility-color-gray.svg';
import IconVisibilityOff from '../../../assets/images/icon-visibility-off-color-gray.svg';

import { annotationPassword } from '../../../constants/annotations';
import './changePasswordMobile.scss';

const ChangePasswordMobile = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [errorCurrentPassword, setErrorCurrentPassword] = useState('');

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const confirmPasswordFunction = async () => {
    try {
      const response = await loginService({ email: user.email, password: currentPassword });
      if (!response) {
        swal('Error', 'Contraseña actual incorrecta');
      }

      if (newPassword !== confirmPassword) {
        setErrorCurrentPassword('La contraseña no coincide');
        return;
      }

      if (newPassword === confirmPassword) {
        if (validationValue(newPassword, 'password').correct) {
          try {
            await resetPasswordService(user._id, newPassword, response.token);
            swal('Cambio Exitoso', 'Su contraseña se ha cambiado exitosamente');
            navigate('/perfil');
          } catch (error) {
            swal('Error', 'Hubo un error al cambiar la contraseña');
          }
        }
      }
    } catch (error) {
      swal('Error', 'Contraseña actual incorrecta');
    }
  };

  return (
    <div className="changes-pasword-mobile">
      <div id="changes-password-mobile" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
        <div className="header-profile-mobile">
          <div className="content-back" onClick={() => navigate(-1)}>
            <img
              src={ArrowBack}
              alt="back"
            />
            <Typography
              type="superTitle-italic"
              text="Edita tu perfil"
              className="button-back"
            />
          </div>
        </div>
        <div className="container-change-password-mobile">
          <Typography
            type="superTitle-italic"
            text="Cambia de contraseña"
            className="center-title"
          />
          <div className="container-body">
            <Input
              label="Contraseña actual"
              placeholder="Escribe tu contraseña"
              value={currentPassword}
              onChange={setCurrentPassword}
              personalizedFirstSpan="changeColor"
              changeColorsvg
              svgClasscss={showConfirmPassword ? 'orangeOff' : 'orange'}
              type={showConfirmPassword ? 'text' : 'password'}
              iconRigth={showConfirmPassword ? IconVisibilityOff : IconVisibility}
              onClickIconRight={() => setShowConfirmPassword(!showConfirmPassword)}
            />
            <Input
              label="Contraseña nueva"
              placeholder="Escribe tu contraseña"
              value={newPassword}
              onChange={setNewPassword}
              annotation={annotationPassword}
              personalizedFirstSpan="changeColor"
              changeColorsvg
              svgClasscss={showCurrentPassword ? 'orangeOff' : 'orange'}
              type={showCurrentPassword ? 'text' : 'password'}
              iconRigth={showCurrentPassword ? IconVisibilityOff : IconVisibility}
              onClickIconRight={() => setShowCurrentPassword(!showCurrentPassword)}
              annotationError={errorCurrentPassword}
            />
            <Input
              label="Confirma tu contraseña nueva"
              placeholder="Escribe tu contraseña"
              value={confirmPassword}
              onChange={setConfirmPassword}
              personalizedFirstSpan="changeColor"
              changeColorsvg
              svgClasscss={showNewPassword ? 'orangeOff' : 'orange'}
              type={showNewPassword ? 'text' : 'password'}
              iconRigth={showNewPassword ? IconVisibilityOff : IconVisibility}
              onClickIconRight={() => setShowNewPassword(!showNewPassword)}
              onCopy
              onPaste
            />
            <Button
              text="Guardar cambios"
              type={isCountryValid ? 'primary-mx' : 'primary'}
              onClick={() => confirmPasswordFunction()}
              className="marginTop"
            />
            <Button
              text="Cancelar"
              type="secondary"
              onClick={() => navigate(-1)}
              className="cancelBTN"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordMobile;
