/* eslint-disable quote-props */
import React from 'react';
import { useSelector } from 'react-redux';

import Typography from '../../../components/typography';

import EditProfile from './editData';
import ArrowBack from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './editContactInfo.scss';
import ImageRedirectProfile from '../../../components/imageRedirectProfile';

const ContactInfoScreen = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth.user);

  return (
    <div id="profile" className={isMobileDevice ? 'contact-edit-mobile' : 'desktop-mode'}>
      <div className={`header-profile ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
        <div className="content-back">
          <ImageRedirectProfile
            src={ArrowBack}
            url="/perfil/informacion-de-contacto"
          />
          <Typography
            type="superTitle-italic"
            text="Información de contacto"
            className="button-back"
          />
        </div>
      </div>
      <div className="profile">
        <EditProfile
          user={user}
          isMobileDevice={isMobileDevice}
        />
      </div>
    </div>
  );
};

export default ContactInfoScreen;
