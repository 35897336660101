/* eslint-disable no-await-in-loop */
import { getTotalNumberOfShipments } from '../../../../services/billing';
import { getShipmentPaidByUser } from '../getTickets/getCollectionData';
import collectionExcel from './ExcelGenerator';

const pagination = (totalItems, pageSize) => {
  const pages = totalItems / pageSize;
  return Math.ceil(pages);
};

const getReportData = async (query) => {
  const totalNumberOfShipments = await getTotalNumberOfShipments(query);
  if (totalNumberOfShipments === 0) {
    return null;
  }
  const pages = pagination(totalNumberOfShipments, query.itemsQuantity);

  const totalShipments = [];

  const firsPartialShipments = await getShipmentPaidByUser(query);
  totalShipments.push(...firsPartialShipments);

  for (let i = 1; i < pages; i += 1) {
    const partialShipments = await getShipmentPaidByUser({
      ...query,
      lastItem: totalShipments[totalShipments.length - 1]._id,
    });
    if (partialShipments.length > 0) {
      totalShipments.push(...partialShipments);
    }
  }
  return collectionExcel(totalShipments);
};

export default getReportData;
