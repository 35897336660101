/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
// Here there will be the delivery companies and payment method filters
import { finalPrice, collectionServiceValue, collectionServiceCommision } from '../../../utils/prices';
import { findObjectInArray, findIndexOfObject } from '../../../utils/filters';
import { createSendingService } from '../../../services/sendings';

/**
 * Listado de conflictos posibles dentro del proceso de cotización
 */
const possibleConflictsList = [
  {
    conflict: 'noCoverage',
    shortText: 'Sin cobertura',
    longText: 'Aún no tenemos cobertura en esta ruta, estamos trabajando para ti',
    possibleShippingGeneration: false,
  },
  {
    conflict: 'paymentTypeNotAllowed',
    shortText: 'Tipo de pago no permitido por transportadora',
    longText: 'El tipo de pago elegido no es posible con la transportadora seleccionada',
    possibleShippingGeneration: false,
  },
  {
    conflict: 'valueCollectionInsufficient',
    shortText: 'Valor de recaudo insuficiente',
    longText: 'El valor a recaudar es menor al valor del envío',
    possibleShippingGeneration: false,
  },
];

/**
 * Función que asigna o borra los conflictos de un envío
 * @param {Object} shipping envío a modificar en sus conflictos
 * @param {String} conflict conflicto por el que se ejecutará la acción
 * @param {String} action acción a ejecutar (insert or remove)
 * @returns devuelve el envío editado en sus conflictos
 */
const insertOrRemoveConflicts = (
  shipping, conflict, action,
) => {
  const conflictObject = possibleConflictsList.find((item) => item.conflict === conflict);
  const indexOfConflictIntoShipping = shipping.conflicts.map((item) => (
    item.conflict
  )).indexOf(conflictObject.conflict);
  if (action === 'insert') {
    if (indexOfConflictIntoShipping < 0) {
      shipping.conflicts.push({
        conflict: conflictObject.conflict,
        shortText: conflictObject.shortText,
        longText: conflictObject.longText,
        possibleShippingGeneration: conflictObject.possibleShippingGeneration,
      });
    }
  } else if (action === 'remove') {
    if (indexOfConflictIntoShipping >= 0) {
      shipping.conflicts.splice(indexOfConflictIntoShipping, 1);
    }
  }
  return shipping;
};

/**
 * Función que asigna o retira conflictos de un envío
 * @param {Object} shipping listado de transportadoras la ruta
 * @returns Envío con conflictos modificados
 */
const validateConflictsInShipping = (shipping) => {
  insertOrRemoveConflicts(
    shipping, 'valueCollectionInsufficient',
    (
      shipping.paymentMethod === 102
      && shipping.collectionService
      && finalPrice(
        shipping.deliveryCompanySelected.shippingCost,
        shipping.collectionService,
        shipping.addShippingToCollectionValue,
        shipping.deliveryCompanySelected.collectionCommissionWithRate,
        shipping.deliveryCompanySelected.collectionCommissionWithOutRate,
      ) > collectionServiceValue(
        shipping.saleValue,
        shipping.deliveryCompanySelected.shippingCost,
        shipping.collectionService,
        shipping.addShippingToCollectionValue,
        shipping.deliveryCompanySelected.collectionCommissionWithRate,
        shipping.deliveryCompanySelected.collectionCommissionWithOutRate,
      )
    ) ? 'insert' : 'remove',
  );

  insertOrRemoveConflicts(
    shipping, 'paymentTypeNotAllowed',
    (
      shipping.paymentMethod === 102
      && shipping.deliveryCompanySelected?.deliveryCompanyId
      && !shipping.deliveryCompanySelected?.thePaymentFromCollecionValueIsPosible
    ) ? 'insert' : 'remove',
  );

  return shipping;
};

/**
 * Función que determina si es posible o no proceder a pagar los envíos
 * @param {Object[]} dataShippings listado de envíos
 * @returns boleano que define si el proceder a pagar envíos es posible o no
 */
const isPosibleContinueToPayment = (dataShippings) => {
  const temporalDataShippings = dataShippings.slice();
  let thePaymentIsPosible = true;
  temporalDataShippings.forEach((shipping) => {
    if (
      (
        shipping.deliveryCompaniesOptions.length > 0
        && (
          !shipping.deliveryCompanySelected.deliveryCompanyId
          || !shipping.paymentMethod
          || !shipping.notProhibitedArticles
        )
      )
      && thePaymentIsPosible
    ) thePaymentIsPosible = false;
  });
  return thePaymentIsPosible;
};

/**
 * Función que determina si es posible o no descontar el envío del valor a recaudar
 * @param {Object} shipping datos del envío
 * @returns boleano que define si es posible descontar el envío del valor a recaudar
 */
const thePaymentFromCollecionValueIsPosible = (shipping) => !!(shipping?.collectionService && (
  (shipping?.deliveryCompanySelected?.deliveryCompanyId
    && shipping?.deliveryCompanySelected?.thePaymentFromCollecionValueIsPosible
  ) || !shipping?.deliveryCompanySelected?.deliveryCompanyId
));

/**
 * Función que verifica el método de pago de los envíos
 * @param {Object[]} dataShippings listado de envíos
 * @returns listado de envíos verificados en su método de pago
 */
const paymentMethodVerification = (shipping) => {
  if (shipping.paymentMethod === 102 && !thePaymentFromCollecionValueIsPosible(shipping)) {
    shipping.paymentMethod = 101;
  }
  return shipping;
};

// ----------------------------------------- Funciones para sorganizar y seleccionar transportadoras

/**
 * Función que ordena la lista de compañias para la ruta por precio, tiempo o mejor servicio
 * @param {Object} shipping datos del envío
 * @param {String} filterBy característica por la que se realizará el filtrado
 * @returns array ardenado de las transportadoras
 * o vacío si no hay transportadoras o no hay característica de organización
 */
const orderDeliveryCompanies = (shipping, filterBy) => {
  const deliveryCompaniesOptions = shipping.deliveryCompaniesOptions.slice();
  if (filterBy === 'price') {
    deliveryCompaniesOptions.sort((a, b) => {
      const priceA = finalPrice(
        a.shippingCost,
        shipping.collectionService,
        shipping.addShippingToCollectionValue,
        a.collectionCommissionWithRate,
        a.collectionCommissionWithOutRate,
      );
      const priceB = finalPrice(
        b.shippingCost,
        shipping.collectionService,
        shipping.addShippingToCollectionValue,
        b.collectionCommissionWithRate,
        b.collectionCommissionWithOutRate,
      );

      if (priceA <= priceB) {
        return -1;
      }
      if (priceA > priceB) {
        return 1;
      }
      return 0;
    });
    return deliveryCompaniesOptions || [];
  }

  if (filterBy === 'time') {
    deliveryCompaniesOptions.sort((a, b) => {
      const timeA = a.shippingTime;
      const timeB = b.shippingTime;
      if (timeA <= timeB) {
        return -1;
      }
      if (timeA > timeB) {
        return 1;
      }
      return 0;
    });
    return deliveryCompaniesOptions || [];
  }

  if (filterBy === 'bestService') {
    deliveryCompaniesOptions.sort((a, b) => {
      const timeA = a.score || 5;
      const timeB = b.score || 5;
      if (timeA >= timeB) {
        return -1;
      }
      if (timeA < timeB) {
        return 1;
      }
      return 0;
    });
    return deliveryCompaniesOptions || [];
  }

  return [];
};

/**
 * Función que devuelve la transportadora que cumple con las caracteristicas
 * @param {Object[]} deliveryCompanies listado de transportadoras la ruta
 * @param {Number} paymentMethod tipo de pago del envío
 * @returns transportadora que cumple con las características requeridas
 */
const selectDeliveryCompanyByParams = (deliveryCompaniesOrdered, paymentMethod) => {
  let deliverySelected = {};
  if (paymentMethod === 102) {
    deliveryCompaniesOrdered.forEach((deliveryCompany) => {
      if (
        !deliverySelected.deliveryCompanyId
        && deliveryCompany.thePaymentFromCollecionValueIsPosible
      ) {
        deliverySelected = deliveryCompany;
      }
    });
  }

  if (
    !deliverySelected.deliveryCompanyId
    && deliveryCompaniesOrdered[0]?.deliveryCompanyId
  ) {
    return deliveryCompaniesOrdered[0];
  }
  return deliverySelected;
};

const addNewValueInArray = (array, field, value) => {
  array.forEach((item, index) => {
    array[index] = {
      ...item,
      [field]: value,
    };
  });
};

/**
 * Aplicación de filtros de selección de transportadoras y método de pago
 * @param {Object[]} dataShippings listado de envíos
 * @param {Number} paymentMethod tipo de pago del envío
 * @param {Number} rowNum número de fila en excel del envío
 * @param {Object} setDataShippings functión para setear el array de listado de envíos
 */
const applyFilters = (
  dataShippings, filterDeliveryCompanyBy, paymentMethod, requestPickup, setDataShippings,
) => {
  const temporalDataShippings = dataShippings.slice();
  if (paymentMethod) {
    addNewValueInArray(temporalDataShippings, 'paymentMethod', paymentMethod);
  }

  if (filterDeliveryCompanyBy) {
    temporalDataShippings.forEach((shipping, index) => {
      if (shipping.deliveryCompaniesOptions.length > 0) {
        const deliveryCompaniesOrdered = orderDeliveryCompanies(shipping, filterDeliveryCompanyBy);
        const deliveryCompanySelected = selectDeliveryCompanyByParams(
          deliveryCompaniesOrdered, shipping.paymentMethod,
        );
        temporalDataShippings[index].deliveryCompanySelected = deliveryCompanySelected;
      }
    });
  }

  if (requestPickup) {
    addNewValueInArray(temporalDataShippings, 'collectionOrTakeToOffice', 'takeToOffice');
  }

  temporalDataShippings.forEach((shipping, index) => {
    temporalDataShippings[index] = paymentMethodVerification(shipping);
  });

  setDataShippings(temporalDataShippings);
};

/**
 * Función que asigna una transportadora a todos los envíos
 * @param {Object[]} dataShippings listado de envíos
 * @param {String} deliveryCompanyId id de la transportadora a asignar
 * @param {Object} setDataShippings functión para setear el array de listado de envíos
 */
const selectDeliveryCompanyByIdForAllShippings = (
  dataShippings, deliveryCompanyId, paymentMethod, setDataShippings,
) => {
  const temporalDataShippings = dataShippings.slice().map((shipping) => {
    if (shipping.deliveryCompaniesOptions.length > 0) {
      const deliveryCompany = findObjectInArray(deliveryCompanyId, shipping.deliveryCompaniesOptions, 'deliveryCompanyId');
      shipping.deliveryCompanySelected = deliveryCompany;
      if (paymentMethod) {
        shipping.paymentMethod = paymentMethod;
      } else if (!deliveryCompany.thePaymentFromCollecionValueIsPosible) {
        shipping.paymentMethod = 101;
      }
    }
    shipping = paymentMethodVerification(shipping);
    return shipping;
  });
  setDataShippings(temporalDataShippings);
};

/**
 * Función que asigna una transportadora a un envío
 * @param {Object[]} dataShippings listado de envíos
 * @param {Object} deliveryCompany transportadora a asignar a un envío dado
 * @param {Number} rowNum número de fila en excel del envío
 * @param {Object} setDataShippings functión para setear el array de listado de envíos
 */
const selectDeliveryCompanyManually = (
  dataShippings, deliveryCompany, rowNum, selectPaymentMethod, setDataShippings,
) => {
  const temporalDataShippings = dataShippings.slice().map((shipping) => {
    if (shipping.rowNum === rowNum) {
      shipping.deliveryCompanySelected = deliveryCompany;
      if (!deliveryCompany.thePaymentFromCollecionValueIsPosible) {
        shipping.paymentMethod = 101;
      } else {
        shipping.paymentMethod = selectPaymentMethod;
      }
    }
    shipping = paymentMethodVerification(shipping);
    return shipping;
  });
  setDataShippings(temporalDataShippings);
};

/**
 * Función que asigna un tipo de pago a un envío dada la fila que ocupa en el excel
 * @param {Object[]} dataShippings listado de envíos
 * @param {Number} paymentMethod tipo de pago del envío
 * @param {Number} rowNum número de fila en excel del envío
 * @param {Object} setDataShippings functión para setear el array de listado de envíos
 */
const selectPaymentMethodManually = (
  dataShippings, paymentMethod, rowNum, setDataShippings,
) => {
  const temporalDataShippings = dataShippings.slice().map((shipping) => {
    if (shipping.rowNum === rowNum) {
      shipping.paymentMethod = paymentMethod;
    }
    return shipping;
  });
  setDataShippings(temporalDataShippings);
};

// --------------------------------------------------------------------- Eliminar envíos a solicitar

/**
 * Function que permite la eliminacion de un envio o el bloque de esa ruta completa
 * @param {Object[]} dataShippings listado de envíos
 * @param {String} type elemento que se eliminará (puede ser block para la ruta o row para un envío)
 * @param {Number} rowNum fila a eliminar o que hace parte del grupo a eliminar
 * @param {Object} setDataShippings functión para setear el array de listado de envíos
 */
const deleteShippings = (dataShippings, type, rowNum, setDataShippings) => {
  let temporalDataShippings = dataShippings.slice();
  if (type === 'block') {
    const objectSearch = findIndexOfObject(rowNum, dataShippings, 'rowNum');
    if (objectSearch > -1) temporalDataShippings.splice(objectSearch, 1);
  }
  if (type === 'row') {
    temporalDataShippings = dataShippings.filter((shipping) => shipping.rowNum !== rowNum);
  }
  setDataShippings(temporalDataShippings);
};

// -------------------------------------------- Funcion para declarar no enviar artículos prohibídos

/**
 * Funcion para declarar no enviar artículos prohibídos
 * @param {Object[]} dataShippings listado de envíos
 * @param {Boolean} notProhibitedArticles declaración de no enviar artículos prohibidos
 * (true cuando no se envían articulos prohibidos, false cuando sí se envía)
 * @param {Object} setDataShippings functión para setear el array de listado de envíos
 */
const declareNotProhibitedArticles = (
  dataShippings, notProhibitedArticles, setDataShippings,
) => {
  const temporalDataShippings = dataShippings.slice().map((shipping) => {
    shipping.notProhibitedArticles = notProhibitedArticles;
    return shipping;
  });
  setDataShippings(temporalDataShippings);
};

/**
 * Se preparan los envíos diligenciados correctamente para pagarlos
 * @param {Object[]} dataShippings listado de envíos
 * @returns lista de envíos que cumplen con las condiciones para proceder al pago
 */
const orderShippingListToPayment = (dataShippings) => {
  const temporalDataShippings = dataShippings.slice();

  temporalDataShippings.forEach((shipping, index) => {
    temporalDataShippings[index] = validateConflictsInShipping(shipping);
  });

  return temporalDataShippings.filter((shipping) => (
    shipping.deliveryCompanySelected.deliveryCompanyId
    && (
      shipping.paymentMethod === 101
      || shipping.paymentMethod === 102
      || shipping.paymentMethod === 105
    )
    && shipping.conflicts.length <= 0
  ));
};

/**
 * Función que procesa y genera los envíos
 * @param {Object[]} dataShippings listado de envíos diligenciados correctamente
 * @returns objeto que contiene dos array: envíos generados y envíos rechazados
 */
const generationOfShippings = (dataShippings, userId) => {
  const dataShippingsSuccess = [];
  const dataShippingsError = [];
  return new Promise((resolve) => (
    Promise.allSettled(dataShippings.map((shipping) => createSendingService(
      shipping.deliveryCompanySelected,
      {
        countryCode: shipping.countryCode ?? '170',
        _id: userId,
      },
      {
        ...shipping,
        senderLastName: shipping.senderSurname,
      },
      shipping.senderAddress,
      {
        ...shipping,
        receiverLastName: shipping.receiverSurname,
        receiverDocumentNumber: shipping.receiverIdentificationNumber,
        receiverCellphone: shipping.receiverCellPhone,
        destinationAddress: shipping.receiverAddress,
        quantity: shipping.quantityOfArticles,
        width: shipping.articleWidth,
        length: shipping.articleLength,
        height: shipping.articleHeight,
        weight: shipping.articleWeight,
        prohibitedItems: shipping.notProhibitedArticles,
        originCode: shipping.shippingOriginCode,
        destinyCode: shipping.shippingDestinyCode,
        recommendations: shipping.recommendationsForDeliveryCompany,
        productDescription: shipping.articleDescription,
      },
      finalPrice(
        shipping.deliveryCompanySelected.shippingCost,
        shipping.collectionService,
        shipping.addShippingToCollectionValue,
        shipping.deliveryCompanySelected.collectionCommissionWithRate,
        shipping.deliveryCompanySelected.collectionCommissionWithOutRate,
      ), // sending value,
      shipping.collectionService,
      shipping.paymentMethod,
      shipping.senderAccountBank?.bank,
      shipping.senderAccountBank?.accountType,
      parseInt(shipping.senderAccountBank?.numberId ?? 0, 10),
      shipping.senderAccountBank?.beneficiaryName,
      parseInt(shipping.senderAccountBank?.numberId ?? 0, 10),
      shipping.senderAccountBank?.typeId,
      shipping.saleValue || 0,
      collectionServiceValue(
        shipping.saleValue,
        shipping.deliveryCompanySelected.shippingCost,
        shipping.collectionService,
        shipping.addShippingToCollectionValue,
        shipping.deliveryCompanySelected.collectionCommissionWithRate,
        shipping.deliveryCompanySelected.collectionCommissionWithOutRate,
      ), // collection value
      collectionServiceCommision(
        shipping.collectionService,
        shipping.addShippingToCollectionValue,
        shipping.deliveryCompanySelected.collectionCommissionWithRate,
        shipping.deliveryCompanySelected.collectionCommissionWithOutRate,
      ), // collection commission
      finalPrice(
        shipping.deliveryCompanySelected.shippingCost,
        shipping.collectionService,
        shipping.addShippingToCollectionValue,
        shipping.deliveryCompanySelected.collectionCommissionWithRate,
        shipping.deliveryCompanySelected.collectionCommissionWithOutRate,
      ), // sending value
      'página transaccional - masivos',
      shipping.activeNotifications ?? null,
    ))).then((response) => {
      response.forEach((item, index) => {
        if (item.status === 'fulfilled') {
          dataShippingsSuccess.push(dataShippings[index]);
        } else {
          dataShippingsError.push(dataShippings[index]);
        }
      });
      resolve({
        dataShippingsSuccess,
        dataShippingsError,
      });
    })
  ));
};

const officeInAddresEnabled = (dataShippings, state) => {
  const dataShippingsOfficeEnabled = dataShippings.find((OfficeFound) => !OfficeFound.deliveryCompanySelected);

  if (!dataShippingsOfficeEnabled) {
    state(true);
  } else {
    state(false);
  }
};
// -------------------------------------------- Exportación de funciones

export {
  selectDeliveryCompanyByIdForAllShippings,
  selectDeliveryCompanyManually,
  selectPaymentMethodManually,
  applyFilters,
  deleteShippings,
  declareNotProhibitedArticles,
  isPosibleContinueToPayment,
  thePaymentFromCollecionValueIsPosible,
  insertOrRemoveConflicts,
  possibleConflictsList,

  orderShippingListToPayment,
  generationOfShippings,
  officeInAddresEnabled,

};
