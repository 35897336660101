import configS3 from '../../../config/nocompanyconfig.fron.json';

import { showInCourtDays, showInCourtDates } from './logics';

const logicList = {
  byDays: () => (showInCourtDays()),
  byDates: () => (showInCourtDates()),
};

const isTheMessageVisible = () => {
  const { courtDatesLogic } = configS3;
  return logicList[courtDatesLogic]();
};

export default isTheMessageVisible;
