/* eslint-disable import/no-unresolved */
import AWS from 'aws-sdk';
import config from './nocompanyconfig.fron.json';

export const uploadImage = async (image, name, bucketName, totalData) => {
  try {
    const information = config.uploadImages;
    const credentialsOptions = {
      accessKeyId: information.aws_access_key_id,
      secretAccessKey: information.aws_secret_access_key,
      sessionToken: null,
    };
    const s3 = new AWS.S3({ credentials: credentialsOptions, region: information.region });

    const imageName = name + image.name.substring(image.name.lastIndexOf('.'));
    // parameters
    const bucketParams = {
      Bucket: bucketName || information.bucketNameDev,
      Key: imageName, // object name within Bucket
      Body: image,
    };

    const response = await new Promise((resolve, reject) => {
      s3.upload(bucketParams, (err, data) => {
        if (err) { reject(err); }
        resolve(data);
      });
    });
    return (totalData) ? response : response.Location;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default {
  uploadImage,
};
