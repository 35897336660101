/* eslint-disable */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import payloadData from "../../updateUserService/payloadService/payloadService";
import userUpdateService from "../../updateUserService";
import { useShippingSteps } from "../../../../hooks/useShippingSteps";

import fields from '../../../../config/quoteShippingFields.json';
import { findCodeLocation } from "../../../../utils/generals";

import Modal from "../../../modal";
import ModalChildren from "../../../modalChildren";
import Typography from "../../../typography";

import StepTwo from '../../stepsComponents/stepTwo/stepTwo';

import './stepTwo.scss';

const SecondStep = ({
  next,
  changes,
  isMobileDevice,
}) => {
  const { user } = useSelector((state) => state.auth);
  const countryCode = useSelector((state) => state.country);

  const [listFields, setListFields] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorsList, setErrorsList] = useState([]);

  const {
    isUpdateData,
    updateShippingSteps,
  } = useShippingSteps(countryCode, user);

  useEffect(() => {
    updateShippingSteps();
  }, [user]);


  useEffect(() => {
    setListFields(fields.country[countryCode]?.fieldsCompletingData
      .filter((item) => item.step === 2));
  }, [fields.country[countryCode]]);

  useEffect(() => {
    if (countryCode === '170' && isMobileDevice) {
      const indexIdentification = listFields.findIndex((field) => field.name === 'identificationNumber');
      const indexDocumentType = listFields.findIndex((field) => field.name === 'documentType');

      if (indexIdentification !== -1 && indexDocumentType !== -1 && indexIdentification > indexDocumentType) {
        const identificationField = listFields.splice(indexIdentification, 1)[0];
        listFields.splice(indexDocumentType + 1, 0, identificationField);
        setListFields([...listFields, {}]);
      }
    }
  }, [listFields, isMobileDevice]);

  const getValueOrDefault = (value) => value ?? '';
  const getLastCharacter = (value) => value?.charAt(value.length - 1) ?? '';

  const deleteLastCharacter = (string) =>  string.replace(/-\s*\d$/, '')
  const combineNames = (firstName, lastName) => `${firstName ?? ''} ${lastName ?? ''}`;

  const fieldsFormMexico = {
    RFC: getValueOrDefault(user?.invoicingData?.RFC),
    taxResidence: getValueOrDefault(user?.invoicingData?.taxResidence),
    postalCode: getValueOrDefault(user?.invoicingData?.postalCode),
    personType: getValueOrDefault(user?.personType),
    methodOfPayment: getValueOrDefault(user?.invoicingData?.methodOfPayment),
    taxRegime: getValueOrDefault(user?.invoicingData?.taxRegime),
    CFDI: getValueOrDefault(user?.invoicingData?.CFDI),
    emailForBilling: getValueOrDefault(user?.invoicingData?.emailForBilling) || user?.email,
    tradeName: getValueOrDefault(user?.tradeName),
  };

  const fieldsFormColombia = {
    personType: getValueOrDefault(user?.personType),
    documentType: getValueOrDefault(user?.documentType),
    address: getValueOrDefault(user?.invoicingData?.address) || changes?.address,
    identificationNumber: user?.documentType === 'NIT' ? deleteLastCharacter(getValueOrDefault(user?.documentNumber)) : getValueOrDefault(user?.documentNumber),
    codeVerification: getLastCharacter(user?.documentNumber),
    cellPhone: getValueOrDefault(user?.cellPhone),
    name: combineNames(user?.name, user?.surname),
    emailForBilling: getValueOrDefault(user?.invoicingData?.emailForBilling) || user?.email,
    locationName: getValueOrDefault(user?.invoicingData?.locationName) || changes?.locationName,
    locationCode: getValueOrDefault(user?.invoicingData?.locationCode) || changes?.locationCode,
    tradeName: getValueOrDefault(user?.tradeName),
  };

  const countryFields = countryCode === '484' ? fieldsFormMexico: fieldsFormColombia;

  const getCodeLocation = (nameLocation, locationCode) => {
    if(!locationCode && countryCode === '484') {
     return findCodeLocation(nameLocation);
    }

   return locationCode;
  }
  const updateUser = async (data) => {
    const { formValues, locationCode } = data;
     await userUpdateService(
        payloadData(
          changes,
          {
            ...formValues,
            locationCode: !locationCode ? getCodeLocation(changes.locationName, locationCode) : locationCode },
          user),
        user._id,
        setShowModal,
        setModalContent,
        isUpdateData,
        user.invoicingData?._id,
        setLoading,
        (action) => next('', action),
      );
  };

  const renderTitle = () => {
    if (countryCode === '484') {
     return (
       <Typography type="value">
       Los siguientes datos son necesarios para facturar y deben coincidir
       con los del
       {' '}
       <span className="bold">SAT</span>
       {' '}
       {!isMobileDevice && '(Servicio de Administración Tributaria)'}
     </Typography>
     )}
      return (
        <Typography type="value">
        Recibirás tu  {' '}
        <span className="bold">factura</span>
        {' '} con los siguientes datos. Verifica que sean los correctos.
      </Typography>
      )
    }

  return (
    <>
      <StepTwo
        title="Datos de facturación"
        valuesForm={countryFields}
        next={(values) => {
          next(values);
        }}
        errorsList={errorsList}
        setErrorsList={setErrorsList}
        listFields={listFields}
        handleButton={(value) => {
          updateUser(value);
        }}
        titleButton="Guardar y continuar"
        changes={changes}
        loading={loading}
      >
        {renderTitle()}
      </StepTwo>
      {showModal && (
        <Modal>
          <ModalChildren
            className="modalCompletingData"
            close={() => {
              setShowModal(false);
              setModalContent(null);
            }}
            headerText={modalContent?.title}
            confirmButtonText={
              modalContent?.type === "success"
                ? "¡Entendido!"
                : "Intentar de nuevo"
            }
            onClickButtonConfirm={() => setShowModal(false)}
            bodyText={modalContent?.text}
          />
        </Modal>
      )}
    </>
  );
};

SecondStep.propTypes = {
  next: PropTypes.func.isRequired,
  changes: PropTypes.shape({
    locationName: PropTypes.string,
    locationCode: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  isMobileDevice: PropTypes.bool
};

SecondStep.defaultProps = {
  isMobileDevice: false,
}

export default SecondStep;
