import { findObjectInArray, findIndexOfObject } from '../../../utils/filters';

import Entregado from '../../../assets/images/states/Entregado.svg';
import EnTransporte from '../../../assets/images/states/En-transporte.svg';
import EnDistribucion from '../../../assets/images/states/En-distribución.svg';
import EnCiudadDeDestino from '../../../assets/images/states/En-ciduad-destino.svg';
import RecoleccionProgramada from '../../../assets/images/states/Recogido-por-la-transportadora.svg';
import { typeDelivery } from '../../../constants/general';

/**
 * función que dado el array del tracking permite
 * asignar los estados exitosamente vividos por el envío
 * @param {Array} tracking valor a buscar en el array
 * @param {func} getShippingWithNoveltiesFunction valor a buscar en el array
 * @returns array con los estados y la data necesaria para el muestreo del tracking
 */
export const statesHomologation = (tracking, getShippingWithNoveltiesFunction, countryCode) => {
  const trackingReverse = tracking.slice().reverse();
  const temporalStates = [{
    success: !!findObjectInArray('Recolección programada', trackingReverse, 'updateState'),
    state: 'Recolección programada',
    stateDescription: `El paquete será recogido por la ${typeDelivery[countryCode]} pronto.`,
    date: findObjectInArray('Recolección programada', trackingReverse, 'updateState')?.date,
    icon: RecoleccionProgramada,
    checked: false,
  }, {
    success: !!findObjectInArray('Recogido por la transportadora', trackingReverse, 'updateState'),
    state: 'Recogido por la transportadora',
    stateDescription: `La ${typeDelivery[countryCode]} tiene el paquete en sus manos.`,
    date: findObjectInArray('Recogido por la transportadora', trackingReverse, 'updateState')?.date,
    icon: RecoleccionProgramada,
    checked: false,
  }, {
    success: !!findObjectInArray('En transporte', trackingReverse, 'updateState'),
    state: 'En transporte',
    stateDescription: 'El paquete va en camino a la ciudad de destino.',
    date: findObjectInArray('En transporte', trackingReverse, 'updateState')?.date,
    icon: EnTransporte,
    checked: false,
  }, {
    success: !!findObjectInArray('En ciudad destino', trackingReverse, 'updateState'),
    state: 'En ciudad destino',
    stateDescription: 'El paquete ya se encuentra en la ciudad de destino.',
    date: findObjectInArray('En ciudad destino', trackingReverse, 'updateState')?.date,
    icon: EnCiudadDeDestino,
    checked: false,
  }, {
    success: !!findObjectInArray('Distribución', trackingReverse, 'updateState'),
    state: 'Distribución',
    stateDescription: 'El paquete será entregado pronto.',
    date: findObjectInArray('Distribución', trackingReverse, 'updateState')?.date,
    icon: EnDistribucion,
    checked: false,
  }, {
    success: !!findObjectInArray('Entregado', trackingReverse, 'updateState'),
    state: 'Entregado',
    stateDescription: `La ${typeDelivery[countryCode]} ha entregado el paquete.`,
    date: findObjectInArray('Entregado', trackingReverse, 'updateState')?.date,
    icon: Entregado,
    checked: false,
  }];

  tracking.map((state, index) => {
    if (
      state.updateState === 'Envío cancelado'
      || state.updateState === 'Devuelto'
      || state.updateState === 'Pérdida del producto'
      || state.updateState === 'Envío con novedad'
    ) {
      const indexOfStates = findIndexOfObject(tracking[index - 1]?.updateState, temporalStates, 'state');
      temporalStates.splice(indexOfStates + 1, 0, {
        success: true,
        error: true,
        state: state.updateState,
        date: state.date,
        checked: false,
      });
    }
    return null;
  });

  if (temporalStates.find((state) => state.state === 'Envío con novedad')) getShippingWithNoveltiesFunction();

  const temporalStatesCopy = temporalStates?.slice();
  const lastUpdate = findObjectInArray(true, temporalStatesCopy?.slice()?.reverse(), 'success')?.state;
  temporalStates.map((state) => {
    if (state.state === lastUpdate) {
      // eslint-disable-next-line no-param-reassign
      state.checked = true;
    }
    return state;
  });

  return temporalStates;
};

export default {
  statesHomologation,
};
