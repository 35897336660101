/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './containerSectionDesktop.scss';

const ContainerSectionDesktop = ({ children, className, fullWidth }) => (
  <div
    className={`container-section-desktop ${className ? className : ''} ${fullWidth ? 'full-width' : ''}`}
  >
    {children}
  </div>
);

ContainerSectionDesktop.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

ContainerSectionDesktop.defaultProps = {
  className: null,
  fullWidth: false,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default connect(mapStateToProps)(ContainerSectionDesktop);
