import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Typography from '../../typography';
import { toCurrencyCop } from '../../../utils/strings';
import { humanizationOfTimeDate } from '../../../utils/generals';
import { typeDelivery } from '../../../constants/general';

import './deliveryCompanyDetails.scss';

function firstLetterUppercase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const routeTypeValidation = (routeType) => {
  const distantPopulation = ['regional', 'reexpedicion', 'especial', 'zonal'];
  const isDistant = distantPopulation.filter((s) => s === routeType);
  if (isDistant.length === 1) return 'Población lejana';
  return firstLetterUppercase(routeType);
};

const DeliveryCompanyDetails = ({
  access, collectionService,
  deliveryCompanyImage, deliveryCompanyName,
  shippingValue, recommendationsForDeliveryCompany, requestDate,
  saleValue, collectionValue, collectionCommission, rateValue, valueToTransfer,
  senderLocation, receiverLocation, isMobileDevice, guideNumber, routeType,
}) => {
  const countryCode = useSelector((state) => state?.country || null);
  const user = useSelector((state) => state?.auth?.user || null);
  return (
    access !== 'public' ? (
      <div className="information-block">
        <Typography type="subtitle" className="subtitle center">
          {typeDelivery[countryCode]}
        </Typography>

        {collectionService && (
        <Typography type="paragraph" className="collection-service-message">
          Este envío tiene pago contra entrega
        </Typography>
        )}

        <div className="delivery-company-data__container">
          <div className="data">
            <img src={deliveryCompanyImage} alt="" />
            <div className="routeType-content">
              <Typography type="subtitle">
                {deliveryCompanyName}
              </Typography>
              <Typography type="subtitle" className="routeType">
                {routeTypeValidation(routeType)}
              </Typography>
            </div>
          </div>
          <Typography type="subtitle" className="companyName">{toCurrencyCop(user, Math.floor(shippingValue))}</Typography>
        </div>

        <Typography type="paragraph" className="with-margin first">
          {recommendationsForDeliveryCompany}
        </Typography>
        <span className="container-field-and-value with-margin">
          <Typography type="paragraph" className="field">Fecha de solicitud:</Typography>
          <Typography type="paragraph" className="second">
            {humanizationOfTimeDate(requestDate, 'general')}
          </Typography>
        </span>
        {collectionService && (
        <>
          <span className="container-field-and-value">
            <Typography type="paragraph" className="field">Valor de la venta:</Typography>
            <Typography type="paragraph">{toCurrencyCop(user, saleValue)}</Typography>
          </span>
          <span className="container-field-and-value">
            <Typography type="paragraph" className="field">Valor a recaudar:</Typography>
            <Typography type="paragraph">{toCurrencyCop(user, collectionValue)}</Typography>
          </span>
          <span className="container-field-and-value">
            <Typography type="paragraph" className="field">Valor comisión de recaudo:</Typography>
            <Typography type="paragraph">{toCurrencyCop(user, collectionCommission)}</Typography>
          </span>
          <span className="container-field-and-value">
            <Typography type="paragraph" className="field">Valor del flete:</Typography>
            <Typography type="paragraph">{toCurrencyCop(user, rateValue)}</Typography>
          </span>
          <span className="container-field-and-value">
            <Typography type="paragraph" className="field">Valor a transferir:</Typography>
            <Typography type="paragraph">{toCurrencyCop(user, valueToTransfer)}</Typography>
          </span>
        </>
        )}
      </div>
    ) : (
      <div className="information-block">
        <span className="container-field-and-value">
          <Typography type="paragraph" className="field dark">
            Origen:
          </Typography>
          <Typography type="paragraph">{senderLocation}</Typography>
        </span>
        <span className="container-field-and-value">
          <Typography type="paragraph" className="field dark">
            Destino:
          </Typography>
          <Typography type="paragraph">{receiverLocation}</Typography>
        </span>

        {!isMobileDevice && (
        <Typography type="subtitle" className="subtitle company_title">
          {typeDelivery[countryCode]}
        </Typography>
        )}

        <div className="delivery-company-data__container">
          <div className="data">
            <img src={deliveryCompanyImage} alt="" />
            <Typography type="subtitle">{deliveryCompanyName}</Typography>
          </div>
        </div>

        {guideNumber && (
        <span className="container-field-and-value margin-top-and-bottom">
          <Typography type="paragraph" className="field dark">
            N° de guía:
          </Typography>
          <Typography type="paragraph">{guideNumber}</Typography>
        </span>
        )}
      </div>
    ));
};

DeliveryCompanyDetails.propTypes = {
  access: PropTypes.string.isRequired,
  collectionService: PropTypes.string.isRequired,
  deliveryCompanyImage: PropTypes.string.isRequired,
  deliveryCompanyName: PropTypes.string.isRequired,
  shippingValue: PropTypes.string.isRequired,
  recommendationsForDeliveryCompany: PropTypes.string.isRequired,
  requestDate: PropTypes.string.isRequired,
  saleValue: PropTypes.string.isRequired,
  collectionValue: PropTypes.string.isRequired,
  collectionCommission: PropTypes.string.isRequired,
  rateValue: PropTypes.string.isRequired,
  valueToTransfer: PropTypes.string.isRequired,
  senderLocation: PropTypes.string.isRequired,
  receiverLocation: PropTypes.string.isRequired,
  isMobileDevice: PropTypes.string.isRequired,
  guideNumber: PropTypes.string.isRequired,
  routeType: PropTypes.string.isRequired,
};

DeliveryCompanyDetails.defaultProps = {};

export default DeliveryCompanyDetails;
