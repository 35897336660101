/* eslint-disable react/no-array-index-key,jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { typeInput } from './typeInput';
import { filterByValue } from '../../utils/filters';
import { addThousandPoint } from '../../utils/strings';

import './input.scss';
import './CustomClassesForIcons.scss';

/**
 * Componente que abarca los botones de la app
 * @param {string} className - clase adicional para aplicar estilos
 * @param {string} id - propiedad única del componente
 * @param {string} label: PropTypes.string,
 * @param {string} placeholder: PropTypes.string,
 * @param {function} onChange: PropTypes.func.isRequired,
 * @param {boolean} error: PropTypes.bool,
 * @param {string} annotation: PropTypes.string,
 * @param {string} annotationError: PropTypes.string,
 * @param {string || number} value: PropTypes.oneOf(PropTypes.string, PropTypes.number),
 * @param {function} onBlur: PropTypes.func,
 * @param {function} onFocus: PropTypes.func,
 * @param {boolean} hidden: PropTypes.bool,
 * @param {boolean} disabled - propiedad para deshabilitar el botón
 * @param {string} inputColor - clase adicional Para cambiar el color al seleccionar un input
 */
const Input = ({
  label, label2, placeholder, annotation, annotationError, onChange, iconLeft, iconRigth,
  value, onBlur, onFocus, onCopy, onPaste, className, id, disabled, hidden, inputColor,
  classNameContainer, type, dataList, dataList2, childrenLeft, childrenRigth,
  onClickIconLeft, onClickIconRight, leftIconCustomClass, typeHidden, lineHidden, lastLabel,
  personalizedFirstSpan, accept, changeColorsvg, svgClasscss, personalizedValueSpan,
  onClickDataList, errorLineHidden, iconRightClass, multiple, onChangeCustom, isFormatNumber,
  notCharacterAnnotation,
}) => {
  const [numericValue, setNumericValue] = useState(value);
  const [focus, setFocus] = useState(false);
  const [hoverOptionsList, setHoverOptionsList] = useState(false);
  const [clickOnOptionList, setClickOnOptionList] = useState(false);
  const [dataListFiltered, setDataListFiltered] = useState([]);

  useEffect(() => {
    if (dataList && value) setDataListFiltered(filterByValue(value, dataList));
  }, [value, dataList]);

  useEffect(() => {
    if (dataList2 && value) setDataListFiltered(filterByValue(value, dataList2));
  }, [value, dataList2]);

  useEffect(() => {
    const newValue = isFormatNumber ? addThousandPoint(value || '') : value || '';
    setNumericValue(newValue);
  }, [value]);

  function validateValue() {
    if (type === 'number') {
      if (numericValue) return numericValue;
      return '';
    }
    if (type === 'file') {
      return '';
    }
    if (value) {
      return value;
    }
    return '';
  }

  return (
    <label
      className={`container__input-all ${hidden ? 'hidden' : ''} ${classNameContainer || ''}`}
    >
      {label
        && (
          <span className={`
          span__input-general ${personalizedFirstSpan || ''}
          ${focus ? 'foscus' : ''}
          ${disabled ? 'disabledFirstSpan' : ''}
          ${annotationError ? 'error' : ''}
        `}>
            {label}
          </span>
        )}
      {label2
        && (
          <span className={`
          span__input-general ${personalizedFirstSpan || ''}
          ${focus ? 'foscus' : ''}
          ${disabled ? 'disabledFirstSpan' : ''}
        `}>
            {label2}
          </span>
        )}
      <div
        className={`
          container__input-general ${iconLeft ? 'icon-left' : ''} ${iconRigth ? 'icon-rigth' : ''}
        `}
      >
        <div className={`content-top__input-general ${type === 'file' ? 'file-span' : ''}`}>
          {childrenLeft}
          {iconLeft && (
            <img
              src={iconLeft}
              alt=""
              className={`${onClickIconLeft ? 'cursor-pointer' : ''} ${leftIconCustomClass}`}
              onClick={() => onClickIconLeft && onClickIconLeft()}
            />
          )}
          <input
            id={id || null}
            className={`input-general ${className || ''} ${typeHidden ? 'typeHidden' : ''} `}
            value={validateValue()}
            accept={type === 'file' ? accept : ''}
            onChange={(e) => {
              typeInput(e, type, onChange, onChangeCustom);
              setClickOnOptionList(false);
              return null;
            }}
            type={type !== 'number' ? type : ''}
            onBlur={(e) => {
              setFocus(false);
              if (onBlur) onBlur(e);
            }}
            onFocus={(e) => {
              setHoverOptionsList(true);
              setFocus(true);
              if (onFocus) onFocus(e);
            }}
            onCopy={(e) => { if (onCopy) e.preventDefault(); }}
            onPaste={(paste) => { if (onPaste) paste.preventDefault(); }}
            placeholder={placeholder}
            multiple={multiple}
            disabled={disabled}
          />
          {type === 'file' && (
            <span className={`span__input-general file-span ${personalizedValueSpan || ''} ${!value ? 'placeholder' : ''}`}>
              {value || 'Nombre del archivo'}
            </span>
          )}
          {iconRigth && !changeColorsvg && (
            <img
              src={iconRigth}
              alt=""
              className={onClickIconRight ? 'cursor-pointer' : ''}
              onClick={() => onClickIconRight && onClickIconRight()}
            />
          )}
          {childrenRigth}
          {changeColorsvg && (
            <div className={`icon-wrapper ${iconRightClass} ${onClickIconRight ? 'hover' : ''}`} onClick={() => onClickIconRight && onClickIconRight()}>
              <div
                className={`icon ${svgClasscss || ''} ${onClickIconRight ? 'cursor-pointer' : ''} ${focus ? 'focusOrange' : ''}`}
              />
            </div>
          )}
        </div>
        <hr className={`help-to-line ${annotationError ? 'line-error' : ''} ${focus ? 'transparent' : ''} ${errorLineHidden || (lineHidden && !annotationError) ? 'lineHidden' : ''}`} />
      </div>
      <div className="container-lines__input-general">
        <div className={`${focus ? inputColor : ''} ${lineHidden ? 'lineHidden' : ''}`} />
      </div>
      <div
        className={`container-suggestions__input-general ${dataList && dataListFiltered.length > 0 && value && value.length > 0 && dataListFiltered[0] !== value && hoverOptionsList && !clickOnOptionList ? 'visible' : ''}`}
      >
        <ul>
          {dataList && dataListFiltered[0] !== value && dataListFiltered.map((option, index) => (
            <li
              key={`option-${option}-${index}`}
              onClick={() => {
                setClickOnOptionList(true);
                onChange(option);
                setFocus(false);
                if (onClickDataList) onClickDataList();
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
      <div
        className={`container-suggestions__input-general ${dataList2 && dataListFiltered.length > 0 && value && value.length > 0 && dataListFiltered[0] !== value && hoverOptionsList && !clickOnOptionList ? 'visible' : ''}`}
      >
        <ul>
          {dataList2 && dataListFiltered[0] !== value && dataListFiltered.map((option, index) => (
            <li
              key={`option-${option}-${index}`}
              onClick={() => {
                setClickOnOptionList(true);
                onChange(option);
                setFocus(false);
                if (onClickDataList) onClickDataList();
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
      <span
        className={`annotation__input-general ${focus ? 'focus' : ''} ${annotation ? 'visible' : ''} ${lineHidden ? 'lineHidden' : ''}`}
      >
        {annotation ? `*${annotation}` : notCharacterAnnotation}
      </span>
      <span
        className={`annotation__input-general error ${(annotationError) ? 'visible' : ''} ${lineHidden && !annotationError ? 'lineHidden' : ''}`}
      >
        {annotationError && `*${annotationError}`}
      </span>
      {lastLabel && <span className="span__input-general last">{lastLabel}</span>}
    </label>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  label2: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classNameContainer: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  annotation: PropTypes.string,
  annotationError: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['text', 'number', 'password', 'number-text', 'email', 'file', 'currency']),
  iconLeft: PropTypes.string,
  onClickIconLeft: PropTypes.func,
  childrenLeft: PropTypes.element,
  iconRigth: PropTypes.string,
  onClickIconRight: PropTypes.func,
  childrenRigth: PropTypes.element,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onCopy: PropTypes.bool,
  onPaste: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  dataList: PropTypes.arrayOf(PropTypes.string),
  dataList2: PropTypes.arrayOf(PropTypes.string),
  inputColor: PropTypes.string,
  leftIconCustomClass: PropTypes.string,
  typeHidden: PropTypes.bool,
  lineHidden: PropTypes.bool,
  errorLineHidden: PropTypes.bool,
  lastLabel: PropTypes.string,
  personalizedFirstSpan: PropTypes.string,
  accept: PropTypes.string,
  changeColorsvg: PropTypes.bool,
  svgClasscss: PropTypes.string,
  iconRightClass: PropTypes.string,
  onClickDataList: PropTypes.func,
  onChangeCustom: PropTypes.func,
  personalizedValueSpan: PropTypes.string,
  multiple: PropTypes.bool,
  isFormatNumber: PropTypes.bool,
  notCharacterAnnotation: PropTypes.string,
};

Input.defaultProps = {
  className: null,
  classNameContainer: null,
  id: null,
  label: null,
  label2: null,
  value: null,
  placeholder: null,
  iconLeft: null,
  onClickIconLeft: null,
  childrenLeft: null,
  iconRigth: null,
  onClickIconRight: null,
  childrenRigth: null,
  onBlur: null,
  onFocus: null,
  onCopy: false,
  onPaste: false,
  annotation: null,
  annotationError: null,
  disabled: null,
  hidden: null,
  type: 'text',
  dataList: null,
  dataList2: null,
  inputColor: 'line-focused',
  leftIconCustomClass: '',
  typeHidden: null,
  lineHidden: null,
  errorLineHidden: null,
  lastLabel: null,
  personalizedFirstSpan: null,
  accept: '',
  changeColorsvg: false,
  svgClasscss: null,
  onClickDataList: null,
  onChangeCustom: null,
  personalizedValueSpan: null,
  iconRightClass: null,
  multiple: false,
  onChange: null,
  isFormatNumber: true,
  notCharacterAnnotation: null,
};

export default Input;
