import { validationValue, insertOrRemoveErrorFromList } from '../../../utils/strings';

export const valueAndTermsValidation = (
  balanceToRecharge, termsAndConditions, errorsList, setErrorsList,
) => {
  const temporalErrorsList = errorsList.slice();

  const listToValidate = [
    {
      value: balanceToRecharge,
      errorName: 'balanceToRecharge',
      errorText: 'El valor mínimo de recarga es de $100',
    },
    {
      value: termsAndConditions,
      errorName: 'termsAndConditions',
      errorText: 'Se requiere de esta confirmación para poder continuar.',
    },
  ];

  if (!balanceToRecharge || balanceToRecharge < 100) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      listToValidate[0].errorName,
      listToValidate[0].errorText,
      'insert',
    );
  } else if (balanceToRecharge >= 100) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      listToValidate[0].errorName,
      listToValidate[0].errorText,
      'remove',
    );
  }

  if (!termsAndConditions) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      listToValidate[1].errorName,
      listToValidate[1].errorText,
      'insert',
    );
  } else if (termsAndConditions) {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      listToValidate[1].errorName,
      listToValidate[1].errorText,
      'remove',
    );
  }

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length === 0) return true;
  return false;
};

export const cardValidation = (
  identificationType, identificationNumber, errorsList, setErrorsList,
  authorizationAge,
) => {
  const temporalErrorsList = errorsList.slice();

  insertOrRemoveErrorFromList(
    temporalErrorsList,
    'authorizationAge',
    'En mipaquete.com estamos autorizados a prestar nuestro servicio a personas desde los 15 años.',
    authorizationAge ? 'remove' : identificationType === 'TI' && 'insert',
  );

  const listToValidate = [
    {
      value: identificationType,
      errorName: 'identificationType',
      errorText: 'Selecciona tu tipo de documento.',
    },
    {
      value: identificationNumber,
      errorName: 'identificationNumber',
      errorText: 'Ingresa tu número de documento para continuar.',
    },
    {
      value: identificationNumber,
      errorName: 'identificationNumber',
      errorText: 'Tu identificanción debe tener más de 5 dígitos',
      type: 'identificationNumber',
    },
  ];

  listToValidate.forEach((item) => {
    insertOrRemoveErrorFromList(
      temporalErrorsList,
      item.errorName,
      item.errorText,
      validationValue(
        item.value,
        item.type,
      ).correct ? 'remove' : 'insert',
    );
  });

  setErrorsList(temporalErrorsList);
  if (temporalErrorsList.length === 0) return true;
  return false;
};

export default validationValue;
