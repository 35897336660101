/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import queryString from 'query-string';
import swal from 'sweetalert';

import { SignInAction, UpdateTokenAction } from '../../store/actions/auth.actions';
import { setCountryAction } from '../../store/actions/country.actions';

import { loginService, pointsCounterService } from '../../services/user';
import { resetRegisterEmail } from '../../services/notifications';

import { setCurrentUser, encriptData } from '../../utils/auth';
import { operationValidate } from '../../utils/role';
import { redirectToOtherPlatforms } from '../../utils/intercommunication';
import { useValidationCountry } from '../../utils/validationCountry';

import Input from '../input';
import Button from '../button';
import Checkbox from '../checkBox';
import Typography from '../typography';
import ButtonsSocialMedia from '../buttonsLoginSocialMedia';

import NoEmail from '../../assets/images/email.png';
import IconVisibility from '../../assets/images/icon-visibility-color-gray.svg';
import IconVisibilityOff from '../../assets/images/icon-visibility-off-color-primary.svg';

import './login.scss';
import { operationsUrl } from '../../constants';

const getUserPoints = async (userId, token) => {
  try {
    const responsePoints = await pointsCounterService(userId, token);
    return parseInt(responsePoints?.points ?? 0, 10);
  } catch (error) {
    return null;
  }
};

const Login = ({
  next, close,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const [executingAction, setExecutingAction] = useState();
  const [errorLogin, setErrorLogin] = useState();
  const [urlToRedirect, setUrlToRedirect] = useState(null);

  const isCountryValid = useValidationCountry('170');

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const intercommunicationData = queryParams.intercommunicationData
      ? JSON.parse(queryParams.intercommunicationData) : {};
    if (intercommunicationData.urlToReturnAfterLogin) {
      setUrlToRedirect(intercommunicationData.urlToReturnAfterLogin);
    }
  }, []);

  const loginFunction = async () => {
    setExecutingAction(true);
    try {
      const response = await loginService({ email: email.trim().toLowerCase(), password });
      if (response?.user?.accountEnabled) {
        response.user.password = password;
        const points = await getUserPoints(response.user._id, response.token);
        response.user = {
          ...response.user,
          ...!points && { points },
        };
        dispatch(setCountryAction(response.user?.countryCode));
        await setCurrentUser(response, remember);
        const tokenEncrypt = await encriptData(response);
        if (urlToRedirect) {
          redirectToOtherPlatforms({ urlToRedirect, token: tokenEncrypt, keep: remember });
          return null;
        }
        dispatch(UpdateTokenAction(tokenEncrypt));
        dispatch(SignInAction({ ...response.user, points }));

        if (operationValidate({ ...response.user, points })) {
          redirectToOtherPlatforms({ urlToRedirect: `${operationsUrl}/buscar-codigo-mp`, token: tokenEncrypt, keep: remember });
          return null;
        }
        next();
        return null;
      }

      swal({
        title: '¡Hola!',
        text: 'Aún no has activado tu cuenta. Revisa la bandeja de correos o la carpeta "spam" para encontrar el correo de activación.',
        icon: NoEmail,
        buttons: {
          cancel: 'Entendido',
          confirm: {
            text: 'Enviar correo nuevamente',
            value: 'search',
          },
        },
      }).then((value) => {
        const { user } = response;
        if (value === 'search' && user?._id) {
          resetRegisterEmail(user._id).then(() => {
            swal({
              title: '¡Enviado con exito!',
              text: 'Fué enviado nuevamente el correo de confirmación a tu correo',
              button: 'Entendido',
            });
          }).catch(() => {
            swal({
              title: 'Hubo un error',
              text: 'No pudo hacerse el envío por favor intentarlo de nuevo',
              button: 'Entendido',
            });
          });
        }
      });
    } catch (e) {
      setErrorLogin('Credenciales incorrectas.');
    } finally {
      setExecutingAction(false);
    }
    return null;
  };

  return (
    <div id="login" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className="second-container__login">
        <div className={`header-login ${close ? 'with-svg' : 'without-svg'}`}>
          <div />
          <Typography text="Ingresa con" type="superTitle-italic" className="title-login" />
          {close && <div className="close-login" onClick={close} />}
        </div>

        <div className="form__login">
          <ButtonsSocialMedia urlToRedirect={urlToRedirect} remember={remember} />

          <div className="separator">
            <hr />
            <span>o</span>
            <hr />
          </div>

          <Input
            label="Correo electrónico"
            placeholder="usuario@gmail.com"
            value={email}
            onChange={setEmail}
          />
          <Input
            label="Escribe tu contraseña"
            placeholder="••••••••••"
            value={password}
            type={showPassword ? 'text' : 'password'}
            iconRigth={showPassword ? IconVisibilityOff : IconVisibility}
            onClickIconRight={() => setShowPassword(!showPassword)}
            onChange={setPassword}
            annotationError={errorLogin}
          />
          <Checkbox
            checked={remember}
            onChange={setRemember}
            text="Recordarme en este equipo"
          />
          <Button
            text="Ingresar"
            type="secondary"
            loading={executingAction}
            onClick={() => loginFunction()}
          />
          <a onClick={() => navigate('/validar-correo')}>¿Olvidaste tu contraseña?</a>
          <Button
            text="Crear cuenta"
            type={isCountryValid ? 'primary-mx' : 'primary'}
            onClick={() => navigate('/registro')}
          />
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  next: PropTypes.func.isRequired,
  close: PropTypes.func,
};

Login.defaultProps = {
  close: null,
};

export default Login;
