import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import IconNotificationsWithBorder from '../../assets/images/icon-notifications-border-color-gray.svg';
import IconNotificationsColorGray from '../../assets/images/icon-notifications-color-gray-dark.svg';
import { showDependingOnBusinessFlag } from '../../utils/businessFlag';

import './notificationsIcon.scss';

const NotificationsIcon = ({
  selected, positionOfNumbers, className, isMobileDevice,
}) => {
  const [notifications, setNotifications] = useState(0);
  const [releasepadLoaded, setReleasepadLoaded] = useState(false);

  const businessFlags = useSelector((state) => state.globalData.businessFlags);

  const changeNotificationsNumber = () => (
    setNotifications(parseInt(document.querySelector('.releasepad-widget-numPost').innerHTML || 0, 10))
  );

  useEffect(() => {
    if (releasepadLoaded) {
      const settings = { attributes: true, childList: true };
      const element = document.querySelector('.releasepad-widget-numPost');

      changeNotificationsNumber();
      const observer = new MutationObserver(changeNotificationsNumber);
      observer.observe(element, settings);
    }
  }, [releasepadLoaded]);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (document.querySelector('.releasepad-widget-numPost')) {
        setReleasepadLoaded(true);
        clearInterval(myInterval);
      }
    }, 1000);
  }, []);

  if (!showDependingOnBusinessFlag({ businessFlags, section: 'releasePad' })) {
    return (<></>);
  }
  return (
    <>
      <div
        className={`notificationsIcon ${className} ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          document.querySelector('.rp-container').click();
        }}
      >
        <img
          className={notifications > 0 ? 'animationBell' : ''}
          src={selected ? IconNotificationsColorGray : IconNotificationsWithBorder}
          alt="notifications"
        />
        {notifications > 0 && (
          <p className={`positionOfNumbers-${positionOfNumbers}`}>
            {notifications > 9 ? '+9' : notifications}
          </p>
        )}
      </div>
    </>
  );
};

NotificationsIcon.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  positionOfNumbers: PropTypes.oneOf(['top', 'bottom']),
  isMobileDevice: PropTypes.bool.isRequired,
};

NotificationsIcon.defaultProps = {
  className: '',
  selected: false,
  positionOfNumbers: 'bottom',
};

export default NotificationsIcon;
