import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';

import { humanizationOfTimeDuration } from 'utils/generals';
import { dataDeliveryPrices } from 'utils/quoteShipping';

import SenderAmountValue from 'components/senderAmountValue';
import CheckBox from 'components/checkBox';
import Typography from 'components/typography';

import collectionIcon from 'assets/images/Icono_pago_contra_entrega.svg';
import ArrowDown from 'assets/images/arrow-down-blue.svg';
import ArrowUp from 'assets/images/arrow-up-blue.svg';

import ContentSenderCollectionServiceDetails from '../contentSenderCollectionServiceDetails';

import '../columns.scss';

const LeftCollectionService = ({
  deliverySelected, isMobileDevice,
  addShippingToCollectionValue, setAddShippingToCollectionValue,
  shippingValue, shippingRealValue, realCollectionCommissionValue, collectionCommissionValue,
  productInfo,
}) => {
  const user = useSelector((state) => state?.auth?.user || null);
  const [customerPayShipping, setCustomerPayShipping] = useState(addShippingToCollectionValue);
  const [showDetailsPrices, setShowDetailsPrices] = useState(false);
  const [dataShipping, setDataShipping] = useState([]);
  const [dataCommissionService, setDataCommissionService] = useState([]);
  const [totalSaved, setTotalSaved] = useState(null);
  const [totalValue, setTotalValue] = useState(productInfo.saleValue);
  const calculateTotalDiscounts = (data, key) => {
    let totalDiscount = 0;

    data.forEach((item) => {
      if (item[key]) {
        item[key].forEach((entry) => {
          if (entry?.isDiscount) {
            totalDiscount += entry.value;
          }
        });
      }
    });

    return totalDiscount;
  };

  useEffect(() => {
    if (deliverySelected) {
      const pricesData = dataDeliveryPrices(deliverySelected, customerPayShipping, isMobileDevice);
      setDataShipping(pricesData?.filter((item) => item.dataShipping)[0]?.dataShipping);
      setDataCommissionService(pricesData?.filter((item) => item.dataCommissionService)[0]
        ?.dataCommissionService);
      const totalDiscountShipping = calculateTotalDiscounts(pricesData, 'dataShipping');
      const totalDiscountCommission = calculateTotalDiscounts(pricesData, 'dataCommissionService');
      setTotalSaved(Math.abs(totalDiscountShipping + totalDiscountCommission));
    }
  }, [deliverySelected, isMobileDevice]);

  useEffect(() => {
    if (customerPayShipping) {
      const total = productInfo.saleValue + collectionCommissionValue + shippingValue;
      return setTotalValue(total);
    }
    return setTotalValue(productInfo.saleValue);
  }, [customerPayShipping]);

  return (
    <div className={`delivery-company-collection-service ${isMobileDevice ? 'mobile-mode' : 'desktop-mode'}`}>
      <div className="delivery-company-info">
        <div className="delivery-company-image">
          <img
            src={deliverySelected.deliveryCompanyImgUrl}
            alt={deliverySelected.deliveryCompanyName}
          />
        </div>
        <div className="content-delivery-info-detail">
          <Typography
            text={deliverySelected.deliveryCompanyName}
            type="title"
            className="hebboMedium"
          />
          <div className="delivery-time">
            <Typography
              type="value"
              text="Promedio de entrega:"
              className="size"
            />
            <Typography
              type="value"
              className="shipping-time size"
              text={humanizationOfTimeDuration(deliverySelected.shippingTime)}
            />
          </div>
        </div>
      </div>
      <div className="delivery-company-collection-service__details">
        <div className="delivery-prices">
          <ContentSenderCollectionServiceDetails
            user={user}
            showDetails={showDetailsPrices}
            totalToPay={collectionCommissionValue + shippingValue}
            shippingValue={shippingValue}
            shippingRealValue={shippingRealValue}
            dataCommissionService={dataCommissionService}
            collectionCommissionValue={collectionCommissionValue}
            realCollectionCommissionValue={realCollectionCommissionValue}
            dataShipping={dataShipping}
            totalSaved={totalSaved}
          />
        </div>
        {user.countryCode === '170' && (
          <SenderAmountValue
            text="Tu cliente pagará:"
            amount={totalValue}
            icon={collectionIcon}
          />
        )}
        <CheckBox
          checked={customerPayShipping}
          onChange={(checked) => {
            setAddShippingToCollectionValue(checked);
            setCustomerPayShipping(checked);
          }}
          text="Mi destinatario pagará el envío y la comisión de recaudo"
        />
        <button
          type="button"
          onClick={() => setShowDetailsPrices(!showDetailsPrices)}
          className="details-prices"
        >
          <span className="text">Desglose del costo</span>
          <img src={showDetailsPrices ? ArrowUp : ArrowDown} alt="" />
        </button>
      </div>
    </div>
  );
};

LeftCollectionService.propTypes = {
  deliverySelected: PropTypes.shape({
    deliveryCompanyId: PropTypes.string.isRequired,
    collectionCommissionWithRate: PropTypes.number,
    shippingCost: PropTypes.number.isRequired,
    collectionCommissionWithOutRate: PropTypes.number,
    shippingRealCost: PropTypes.number,
    shippingTime: PropTypes.number.isRequired,
    deliveryCompanyName: PropTypes.string.isRequired,
    deliveryCompanyImgUrl: PropTypes.string.isRequired,
  }).isRequired,
  shippingInfo: PropTypes.shape({
    collectionService: PropTypes.bool.isRequired,
    declaredValues: PropTypes.string.isRequired,
  }).isRequired,
  addShippingToCollectionValue: PropTypes.bool,
  setAddShippingToCollectionValue: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  shippingValue: PropTypes.number,
  shippingRealValue: PropTypes.number.isRequired,
  collectionCommissionValue: PropTypes.number,
  realCollectionCommissionValue: PropTypes.number.isRequired,
  productInfo: PropTypes.shape({
    saleValue: PropTypes.number.isRequired,
  }).isRequired,
};

LeftCollectionService.defaultProps = {
  addShippingToCollectionValue: false,
  collectionCommissionValue: null,
  shippingValue: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default compose(
  connect(mapStateToProps),
)(LeftCollectionService);
