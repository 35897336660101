import { getInteractionAd } from '../services';
import { audienceValues, frecuencyValues } from '../enum';

const valdiationDate = ({ date }) => {
  const [initDate, finishDate] = date;

  return !(
    Date.parse(new Date(initDate))
    <= Date.now()
    && Date.parse(new Date(finishDate))
    >= Date.now()
  );
};

const isPossibleToShow = async ({ frecuency, audience, _id }, user) => {
  if (frecuency === frecuencyValues.one && audience === audienceValues.public && user) {
    return getInteractionAd(_id, user._id);
  }

  if (frecuency === frecuencyValues.one && audience === audienceValues.public) return true;

  if (frecuency === frecuencyValues.everyTime && audience === audienceValues.registered && user) {
    return true;
  }

  if (frecuency === frecuencyValues.everyTime && audience === audienceValues.public) return true;

  if (frecuency === frecuencyValues.one && audience === audienceValues.registered && user) {
    return getInteractionAd(_id, user._id);
  }

  return false;
};

const showAdNow = async (ad, user) => {
  if (valdiationDate(ad)) return false;
  return isPossibleToShow(ad, user);
};

export default showAdNow;
