/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/prefer-default-export
export const setHeaderRequest = (token) => {
  if (axios) {
    if (token) axios.defaults.headers.common.token = token;
    axios.defaults.headers.common['session-tracker'] = uuidv4();
  } else {
    delete axios.defaults.headers.common.token;
  }
};
