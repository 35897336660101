import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { contentSteps } from './content';
import { showDependingOnBusinessFlag } from '../../utils/businessFlag';
import { redirectToOtherPlatforms } from '../../utils/intercommunication';

import Modal from '../modal';
import ModalChildren from '../modalChildren';
import Typography from '../typography';

import { ReactComponent as ArrowNext } from '../../assets/images/icon-arrow-side-color-gray.svg';
import { ReactComponent as ArrowBack } from '../../assets/images/icon-arrow-back-color-gray-dark.svg';

import './onboardingNoveltiesCenter.scss';

const OnboardingNoveltiesCenter = () => {
  const user = useSelector((state) => state.auth.user);
  const businessFlags = useSelector((state) => state.globalData.businessFlags);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const [step, setStep] = useState(0);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    const numberOfTimesShowed = parseInt(localStorage.getItem('onboardingNoveltiesCenter') || 0, 10);
    if (
      businessFlags
      && user
      && showDependingOnBusinessFlag({
        businessFlags, section: 'noveltiesCenter', userEmail: user.email,
      })
      && numberOfTimesShowed < 2
      && (Date.parse(new Date(new Date())) <= Date.parse(new Date(2022, 3, 1)))
    ) {
      setShowOnboarding(true);
      localStorage.setItem('onboardingNoveltiesCenter', (numberOfTimesShowed + 1).toString());
    }
  }, [businessFlags, user]);

  return showOnboarding && (
    <Modal>
      <ModalChildren
        headerText={
          step === 0 ? (
            contentSteps[step].title
          ) : (
            isMobileDevice && contentSteps[step].subTitle
          )
        }
        close={() => setShowOnboarding(false)}
        className="modalOnboardingNoveltiesCenter"
        confirmButtonText={
          isMobileDevice && step === 0
            ? (
              <>
                Continuar
                <ArrowBack />
              </>
            ) : 'Ir al centro de novedades'
        }
        onClickButtonConfirm={
          isMobileDevice && step === 0 ? (
            () => setStep(step + 1)
          ) : (
            step === contentSteps.length - 1 && (() => redirectToOtherPlatforms({}))
          )
        }
        cancelButtonText="Ir más tarde"
        onClickButtonCancel={
          step === contentSteps.length - 1 && (() => setShowOnboarding(false))
        }
      >
        <div className="contentModalOnboardingNoveltiesCenter">
          <div className="top">
            {!isMobileDevice && step > 0 && (
              <button
                type="button"
                className="arrowTop"
                onClick={() => setStep(step - 1)}
                style={{ transform: 'rotate(180deg)' }}
                aria-label="Continuar"
              >
                <ArrowNext />
              </button>
            )}
            <div className={`containerTextAndImage ${step > 0 ? 'widthLess' : ''}`}>
              {contentSteps[step].image && (
                <img src={contentSteps[step].image} alt="" />
              )}
              <div className="containerText">
                {!isMobileDevice && (
                  <Typography type="superTitle-italic">
                    {contentSteps[step].subTitle}
                  </Typography>
                )}
                <Typography type="paragraph">
                  {contentSteps[step].description}
                </Typography>
              </div>
            </div>
            {!isMobileDevice && step < contentSteps.length - 1 && (
              <button
                type="button"
                className="arrowTop"
                onClick={() => setStep(step + 1)}
                aria-label="Continuar"
              >
                <ArrowNext />
              </button>
            )}
          </div>
          <div className="bottom">
            {contentSteps.map((itemStep, index) => (
              <div
                className={`circle ${step === index ? 'selected' : ''}`}
                key={itemStep.title}
              />
            ))}
          </div>
        </div>
      </ModalChildren>
    </Modal>
  );
};

export default OnboardingNoveltiesCenter;
