/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { extractToSpecialCharacter, capitalizeWords } from '../../../utils/strings';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';
import createContactACService from '../../../services/createContactAC';
import { typeDelivery } from '../../../constants/general';

import Button from '../../../components/button';
import Typography from '../../../components/typography';

import GuideMan from '../../../assets/images/guide-man.png';

import './firstSending.scss';

const FirstSending = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth?.user || {});

  const navigte = useNavigate();

  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  createContactACService(
    user.email,
    user.name,
    user.surname,
    `+${user.prefix}${user.cellPhone}`,
    '54',
    [
      {
        field: '41', // saldo actual
        value: JSON.stringify(user.cash),
      },
      {
        field: '46', // Fecha primer envío anterior
        value: JSON.stringify(new Date()),
      },
      {
        field: '107', // Fecha primer envío
        value: JSON.stringify(new Date()),
      },
    ],
  );

  return (
    <div id="first-sending" className={isMobileDevice ? 'mobile-mode' : 'desktop-mode'}>
      <div className="container-text__first-sending">
        {user && (
          <Typography
            text={`Te damos la bienvenida ${capitalizeWords(extractToSpecialCharacter(user?.name ?? '', ' ')[0])}`}
            type="superTitle-italic"
            className="text__first-sending"
          />
        )}
        <Typography
          text="¡Realizaste tu primer envío en"
          type="value"
          className="text__first-sending margin-top"
        />
        <Typography
          text="mipaquete!"
          type="superTitle-italic"
          className="text__first-sending italic margin-bottom"
        />
        <Typography
          text={`Ahora puedes descargar la guía, imprímela y entrégala con tu paquete a la ${typeDelivery[user.countryCode]}, mientras tanto conoce las siguientes recomendaciones para que vivas una gran experiencia con nosotros y les subamos el voltaje a tus ventas online`}
          type="value"
          className="text__first-sending"
        />
      </div>
      <div className="button-and-guide-man">
        <img src={GuideMan} alt="" />
        <Button
          text="Ver Recomedaciones"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          onClick={() => window.open('https://www.mipaquete.com/blog/envios/guia-con-todo-lo-que-debes-saber-para-hacer-tu-primer-envio', 'WindowName', 'noopener')}
        />
        <Button
          text="Ir al historial de envíos"
          type="secondary"
          onClick={() => navigte('/historial-envios', { replace: true })}
        />
      </div>
    </div>
  );
};

export default FirstSending;
