/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../../../../components/typography';

import bankTransferColombia from '../../../../utils/transferCost';

import './dataBankData.scss';

const linkCollection = 'https://www.mipaquete.com/soluciones-ecommerce/envios-pago-contraentrega';

const title = () => (
  <>
    Conoce
    {' '}
    <button
      className="title-interactive"
      type="button"
      onClick={() => window.open(linkCollection, 'blank', 'noopener')}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          window.open(linkCollection, 'blank', 'noopener');
        }
      }}
    >
      aquí
    </button>
    {' '}
    las fechas de transferencia de recaudos y sus costos.
  </>
);

const ocultarDigitos = (str) => {
  const stringValue = String(str);
  if (stringValue.length <= 4) {
    return stringValue;
  }
  const ultimosCuatro = stringValue.slice(-4);
  const asteriscos = '*'.repeat(stringValue.length - 4);
  return asteriscos + ultimosCuatro;
};

// eslint-disable-next-line arrow-body-style
const DataBankData = ({ user }) => {
  return (
    <div className="bankData-container">
      <div className="content-info">
        <div>
          <Typography className="title-info" text="Titular de la Cuenta" />
          <Typography className="subtitle-info" text={user.accountBank?.beneficiaryName ?? ''} />
          <Typography className="subtitle-info" text={`${user.accountBank?.typeId ?? ''}: ${user.accountBank?.numberId ?? ''}`} />
        </div>
        <div>
          <Typography className="title-info" text="Entidad Bancaria" />
          <Typography className="subtitle-info" text={bankTransferColombia(user) ?? ''} />
        </div>
        <div className="account-number">
          <Typography className="title-info" text="Número de cuenta" />
          <div>
            <Typography className="subtitle-info" text={ocultarDigitos(user.accountBank?.accountNumber || '') || ''} />
            <div className="account-type">{user.accountBank?.accountType ?? ''}</div>
          </div>
        </div>
      </div>
      <Typography
        className="main-title"
        text={title()}
      />
    </div>
  );
};

DataBankData.propTypes = {
  user: PropTypes.instanceOf.isRequired,
};

DataBankData.defaultProps = {

};

export default DataBankData;
