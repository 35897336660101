import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Left from './left';
import Right from './right';

import '../stepsQuoteShipping.scss';

const StepThree = ({
  shippingData,
  back,
  nextLeft,
  nextRight,
  isMobileDevice,
  saleValue,
  deliveriesList,
  addShippingToCollectionValue,
  collectionService,
  setAddShippingToCollectionValue,
  deliveryCompaniesConfig,
  user,
  continueToLastDetails,
}) => (
  <>
    {!isMobileDevice && (
      <Left
        shippingData={shippingData}
        back={back}
        next={nextLeft}
        isMobileDevice={isMobileDevice}
        saleValue={saleValue}
        collectionService={collectionService}
      />
    )}
    <Right
      deliveriesList={deliveriesList}
      addShippingToCollectionValue={addShippingToCollectionValue}
      collectionService={collectionService}
      setAddShippingToCollectionValue={setAddShippingToCollectionValue}
      next={nextRight}
      isMobileDevice={isMobileDevice}
      saleValue={saleValue}
      deliveryCompaniesConfig={deliveryCompaniesConfig}
      user={user}
      continueToLastDetails={continueToLastDetails}
    />
  </>
);

StepThree.propTypes = {
  shippingData: PropTypes.shape({
    origin: PropTypes.string,
    originCode: PropTypes.string,
    destiny: PropTypes.string,
    destinyCode: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    length: PropTypes.number,
    weight: PropTypes.number,
    unitOfMeasurement: PropTypes.string,
    quantity: PropTypes.number,
    declaredValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  back: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  saleValue: PropTypes.string,
  deliveriesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addShippingToCollectionValue: PropTypes.bool.isRequired,
  collectionService: PropTypes.bool.isRequired,
  setAddShippingToCollectionValue: PropTypes.func.isRequired,
  nextLeft: PropTypes.func.isRequired,
  nextRight: PropTypes.func.isRequired,
  deliveryCompaniesConfig: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({}),
  continueToLastDetails: PropTypes.func.isRequired,
};

StepThree.defaultProps = {
  saleValue: null,
  user: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  deliveryCompaniesConfig: state.globalData.deliveryCompaniesConfig,
  user: state.auth.user,
});

export default connect(mapStateToProps)(StepThree);
