import React from 'react';
import PropTypes from 'prop-types';
import Parser from 'react-html-parser';
import { useSelector } from 'react-redux';

import { toCurrencyCop } from '../../../../utils/strings';

import IconInfoHelp from '../../../../assets/images/Icon-open-external-link.svg';

import Typography from '../../../../components/typography';
import ButtonHelp from '../../../../components/buttonHelp';

import '../myCollections.scss';

const linkDescription = '';
const linkDescriptionDesktop = '';

const TicketsList = ({ billingData, handleChangeTicketDetail }) => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state?.auth?.user || null);

  return (
    <>
      <div className="link-content">
        {!isMobileDevice ? (
          <p>{Parser(linkDescriptionDesktop)}</p>
        ) : (
          <p>{Parser(linkDescription)}</p>
        )}
      </div>
      <div className="table">
        <div className="header">
          <Typography type="field" text="Fecha de pago" />
          <Typography type="field" text="N° de guías" />
          <Typography type="field" text="Valor total pagado" />
        </div>
        <div className="body">
          {billingData && billingData.map((item, index) => (
            <div className="body__content" key={(index + 1).toString()}>
              <div className="body__content-text" onClick={() => handleChangeTicketDetail(item)}>
                <Typography
                  type="field-dark-gray"
                  text={new Date(item.courtDate).toLocaleDateString('en-GB')}
                />
                <Typography
                  type="field-dark-gray"
                  text={`${item.guidesQuantity} guías`}
                />
                <Typography
                  type="field-dark-gray"
                  text={toCurrencyCop(user, item.totalValuePaid)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="table-mobile">
        <ButtonHelp
          text="Preguntas frecuentes"
          imgLeft={IconInfoHelp}
          linkHelp="https://www.mipaquete.com/soluciones-ecommerce/envios-pago-contraentrega#preguntas-frecuentes"
          linkIconHelp="https://mipaquete.atlassian.net/servicedesk/customer/portal/8/group/48"
        />
        <div className="body">
          {billingData && billingData.map((item, index) => (
            <div
              className="body__content"
              key={(index + 1).toString()}
              onClick={() => handleChangeTicketDetail(item)}
            >
              <div className="body__content--information">
                <Typography
                  type="field-dark-gray"
                  text={new Date(item.courtDate).toLocaleDateString('en-GB')}
                  className="heebo-medium color-darker-gray text-table"
                />
                <Typography
                  type="field-dark-gray"
                  text={`${item.guidesQuantity} guías`}
                />
              </div>
              <div className="body__content--information">
                <Typography
                  type="field-dark-gray"
                  text={toCurrencyCop(user, item.totalValuePaid)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

TicketsList.propTypes = {
  billingData: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    courtDate: PropTypes.string,
    guidesQuantity: PropTypes.number,
    totalValuePaid: PropTypes.number,
  })).isRequired,
  handleChangeTicketDetail: PropTypes.func.isRequired,
};

export default TicketsList;
