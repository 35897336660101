/* eslint-disable no-unused-expressions,react/no-children-prop */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import fields from '../../../../config/quoteShippingFields.json';
import Typography from '../../../typography';
import Modal from '../../../modal';
import ModalChildren from '../../../modalChildren';

import SecondStep from '../../stepsComponents/stepTwo/stepTwo';
import '../steps.scss';

const StepTwo = ({
  close, next, back, stepsData, isMobileDevice,
}) => {
  const { user } = useSelector((state) => state.auth);
  const countryCode = useSelector((state) => state.country);
  const [listFields, setListFields] = useState([]);
  const [errorsList, setErrorsList] = useState([]);

  useEffect(() => {
    setListFields(fields.country[countryCode]?.fieldsCompletingData
      .filter((item) => item.modalStep === 2));
  }, [fields.country[countryCode]]);

  const fieldsFormMexico = {
    RFC: user?.invoicingData?.RFC || '',
    tradeName: user?.tradeName || '',
    personType: user?.personType || '',
    taxRegime: user?.invoicingData?.taxRegime || '',
  };

  const fieldsFormColombia = {
    personType: user?.personType || '',
    documentType: user?.documentType || '',
    identificationNumber: user?.documentNumber || '',
    codeVerification: user?.documentNumber?.charAt(user.documentNumber.length - 1) || '',
    tradeName: user?.tradeName || '',
    name: `${user?.name} ${user?.surname}`,
  };

  return (
    <Modal>
      <ModalChildren
        headerText="Completa tus datos (2 de 3)"
        close={close}
        back={back}
        className="stepCompletingData-mexico"
      >
        <SecondStep
          next={(changes) => next(changes)}
          valuesForm={countryCode === '484' ? fieldsFormMexico : fieldsFormColombia}
          listFields={listFields}
          errorsList={errorsList}
          setErrorsList={setErrorsList}
          children={(
            countryCode === '484' ? (
              <Typography type="value">
                Los siguientes datos son necesarios para facturar y deben coincidir
                con los del
                {' '}
                <span className="bold">SAT</span>
                {' '}
                {!isMobileDevice && '(Servicio de Administración Tributaria)'}
              </Typography>
            )
              : (
                <Typography type="value">
                  Recibirás tu
                  {' '}
                  {' '}
                  <span className="bold">factura</span>
                  {' '}
                  {' '}
                  con los siguientes datos. Verifica que sean los correctos.
                </Typography>
              )
          )}
          titleButton="Continuar"
          changes={stepsData}
        />
      </ModalChildren>
    </Modal>
  );
};

StepTwo.propTypes = {
  close: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  userToUpdate: PropTypes.shape({
    tradeName: PropTypes.string,
    personType: PropTypes.string,
  }),
  invoicingDataUser: PropTypes.shape({
    RFC: PropTypes.string,
    taxRegime: PropTypes.string,
  }),
  isMobileDevice: PropTypes.bool,
  stepsData: PropTypes.shape({}).isRequired,
};

StepTwo.defaultProps = {
  isMobileDevice: false,
  userToUpdate: {},
  invoicingDataUser: {},
};
export default StepTwo;
