/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  globalconfigMicroserviceUrl,
  customerKeyGlobalConfig,
} from '../constants';

export const getGlobalConfigService = (configName) => new Promise((resolve, reject) => {
  const heraders = { 'customer-key': customerKeyGlobalConfig };
  const instance = axios.create({
    method: 'get',
    baseURL: `${globalconfigMicroserviceUrl}/getConfigurations?configName=${configName}`,
    headers: heraders,
  });
  instance.get()
    .then((response) => resolve(response.data))
    .catch((err) => reject(err));
});
