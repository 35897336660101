import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { validationValue } from '../../../utils/strings';

import { validationSteps } from '../createAccount/validations';
import { findObjectInArray } from '../../../utils/filters';
import { averageShipmentsOptions } from '../../../constants/general';
import { useValidationCountry, useFetchIpData } from '../../../utils/validationCountry';

import Button from '../../../components/button';
import Select from '../../../components/select';
import Typography from '../../../components/typography';
import NameSurnameAndCellPhone from './nameSurnameAndCellPhone';
import { ReactComponent as IconArrowBack } from '../../../assets/images/icon-arrow-back-color-primary.svg';

import './stepsCreateAccount.scss';

const StepTwo = ({
  goBack, next, userToCreate, errorList, setErrorList,
}) => {
  const [name, setName] = useState(userToCreate.name || '');
  const [surname, setSurname] = useState(userToCreate.surname || '');
  const [cellPhone, setCellPhone] = useState(userToCreate.cellPhone || '');
  const [averageShipments, setAverageShipments] = useState(userToCreate.averageShipments || '');

  const ipData = useFetchIpData();
  const navigate = useNavigate();
  const isCountryValid = useValidationCountry(ipData.country);

  return (
    <div className="stepCreateAccount">
      <div className="top">
        <IconArrowBack onClick={goBack} className="arrowBack" />
        <Typography type="superTitle-italic" text="Diligencia tus datos" />
        <div />
      </div>
      <div className="bottom">
        <NameSurnameAndCellPhone
          name={name}
          setName={setName}
          surname={surname}
          setSurname={setSurname}
          cellPhone={cellPhone}
          setCellPhone={setCellPhone}
          errorList={errorList}
          setErrorList={setErrorList}
          userToCreate={userToCreate}
        />
        <Select
          selectColor="accent"
          label="Envíos que haces actualmente"
          value={averageShipments}
          onChange={(value) => {
            setAverageShipments(value);
            validationSteps({
              errorList, setErrorList, averageShipments: value, averageShipmentsOptions,
            });
          }}
          startingPosition="top-left"
          className="selectUserToCreate"
          annotationError={findObjectInArray('averageShipments', errorList, 'name')?.error || null}
          options={averageShipmentsOptions}
        />
        <Button
          text="Continuar"
          type={isCountryValid ? 'primary-mx' : 'primary'}
          disabled={
            !averageShipmentsOptions
              .map((option) => option.value)
              .includes(averageShipments)
            || name === '' || !validationValue(name).correct
            || surname === '' || !validationValue(surname).correct
            || cellPhone === '' || !validationValue(cellPhone, 'cellPhone').correct
          }
          onClick={() => next(name, surname, cellPhone, averageShipments)}
        />
        <Typography type="paragraph" className="redirectToLogin">
          ¿Ya tienes cuenta?
          <button
            type="button"
            onClick={() => navigate('/ingreso')}
            className="linkButton"
          >
            Inicia sesión aquí
          </button>
        </Typography>
      </div>
    </div>
  );
};

StepTwo.propTypes = {
  next: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  userToCreate: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    cellPhone: PropTypes.string,
    averageShipments: PropTypes.string,
  }).isRequired,
  errorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setErrorList: PropTypes.func.isRequired,
};

export default StepTwo;
