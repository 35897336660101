import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import Button from 'components/button';
import Typography from 'components/typography';

import { addThousandPoint, toCurrencyCop } from 'utils/strings';
import { currentCurrency } from 'constants/general';

import DataStepsThree from 'config/quoteShippingFields.json';

import collectionIcon from 'assets/images/Icono_pago_contra_entrega.svg';

import '../../stepsQuoteShipping.scss';

const Left = ({
  shippingData,
  next,
  back,
  isMobileDevice,
  saleValue,
  collectionService,
}) => {
  const user = useSelector((state) => state.auth.user);
  const countryCode = useSelector((state) => state.country);
  const [newShippingData, setNewShippingData] = useState({});
  const [data, setData] = useState([]);
  const addNewData = (initialData) => {
    const newData = [...initialData];
    const collectionServiceExists = newData.some((item) => item.title === 'Envío contra entrega');

    if (saleValue && !collectionServiceExists) {
      newData.push({
        title: 'Envío contra entrega',
        type: 'field',
        classTitle: 'adjust-title',
        children: [
          {
            text: 'Valor a recaudar:',
            typeTypography: 'paragraph',
            textKey: 'saleValue',
            classContainer: 'containerData',
            textKeyTwo: '',
            measuringLength: '',
            isIcon: true,
          },
        ],
      });
    }

    const collectionServiceIndex = newData.findIndex((item) => item.title === 'Envío contra entrega');

    if (collectionServiceIndex !== -1 && collectionServiceIndex !== 1) {
      const envioContraEntrega = newData.splice(collectionServiceIndex, 1)[0];
      newData.splice(1, 0, envioContraEntrega);
    }

    setData(newData);
    setNewShippingData({
      ...shippingData,
      ...(saleValue && { saleValue: toCurrencyCop(user, saleValue, countryCode) }),
    });
  };

  useEffect(() => {
    const initialData = DataStepsThree.country[countryCode]?.dataStepsThree || [];
    const clonedInitialData = [...initialData];

    setNewShippingData({
      ...shippingData,
      ...(saleValue && { saleValue: toCurrencyCop(user, saleValue, countryCode) }),
    });

    if (saleValue && collectionService) {
      addNewData(clonedInitialData);
    } else {
      setData(clonedInitialData);
    }
  }, [countryCode, saleValue, collectionService, DataStepsThree]);

  return (
    <div className={isMobileDevice ? 'contentStepQuoteShipping' : 'contentStepQuoteShipping-mobile'}>
      <section className={isMobileDevice ? 'content stepThree' : 'content stepThree-mobile'}>
        {data.map(({
          title, type, classTitle, children, name,
        }) => (
          <Fragment key={name}>
            <Typography className={classTitle} type={type} text={title} key={title} />
            {children.map(({
              textKey,
              text,
              typeTypography,
              textKeyTwo,
              measuringLength,
              classContainer,
              isIcon,
            }) => (
              <div
                className={classContainer}
                key={textKey}
              >
                <Typography type={typeTypography} text={text} />
                {
                  textKey === 'declaredValue' ? (
                    <Typography
                      type={typeTypography}
                      text={`$${countryCode === '170'
                        ? addThousandPoint(newShippingData[textKey])
                        : newShippingData[textKey]} ${user?.currencyToPay ? user?.currencyToPay : currentCurrency[countryCode]}`}
                    />
                  ) : (
                    <div className={`${isIcon ? 'collectioIcon' : ''}`}>
                      {isIcon && <img src={collectionIcon} alt="icono" />}
                      <Typography
                        type={typeTypography}
                        text={`${newShippingData[textKey]} ${textKeyTwo && newShippingData[textKeyTwo]} ${measuringLength}`}
                      />
                    </div>
                  )
                }
              </div>
            ))}

          </Fragment>
        ))}
      </section>

      {isMobileDevice && (
        <section className="containerButtonsStepsQuoteShipping">
          <Button
            text="Quiero corregir"
            type="secondary"
            onClick={() => back()}
            className="widthless"
          />
          <Button
            text="¡Todo está correcto!"
            type="primary"
            onClick={() => {
              next();
            }}
            className="widthless"
          />
        </section>
      )}
    </div>
  );
};

Left.propTypes = {
  shippingData: PropTypes.shape({
    origin: PropTypes.string,
    originCode: PropTypes.string,
    destiny: PropTypes.string,
    destinyCode: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    length: PropTypes.number,
    weight: PropTypes.number,
    unitOfMeasurement: PropTypes.string,
    quantity: PropTypes.number,
    declaredValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  saleValue: PropTypes.string,
  collectionService: PropTypes.bool.isRequired,
};

Left.defaultProps = {
  saleValue: null,
};
const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
});

export default connect(mapStateToProps)(Left);
