const Process = {
  NoNegotiantionList: 'NONEGOTIATIONLIST',
  CreateAndUpdateNegotiation: 'CREATEANDUPDATENEGOTIATION',
  DetailNegotiation: 'DETAILNEGOTIATION',
};

const Actions = {
  Create: 'CREATE',
  Update: 'UPDATE',
};

module.exports = {
  Process,
  Actions,
};
