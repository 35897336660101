/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../typography';

import IconTooltipDefault from '../../assets/images/Tooltip.svg';

import './tooltip.scss';

const Tooltip = ({
  iconTooltip, overlayChildren, overlayTitle, overlayText, startingPosition,
  linkText, linkTextFunction, withoutIcon, isMobile, iconTooltipHover, overlayTextElement,
}) => (
  <div className="tooltip">
    <div className="tooltip-area">
      {withoutIcon || <img src={iconTooltip || IconTooltipDefault} alt="icon tooltip" className="icon" />}
      <img src={iconTooltipHover} alt="" className="icon-hover" />
    </div>
    {overlayChildren || (
      <div className={`tooltip__content-overlay ${startingPosition} ${isMobile && 'top-middle'}`}>
        <Typography text={overlayTitle} type="value" className="tooltip__title" />
        {overlayTextElement || <Typography text={overlayText} type="value" className="tooltip__text" />}
        {linkText && (<h1 className="tooltip__link-text" onClick={() => { if (linkTextFunction) linkTextFunction(); }}>{linkText}</h1>)}
      </div>
    )}
  </div>
);

Tooltip.propTypes = {
  startingPosition: PropTypes.oneOf(['top-left', 'top-rigth', 'bottom-left', 'bottom-rigth', 'top-middle', 'bottom-center', 'bottom-middle']),
  iconTooltip: PropTypes.string,
  overlayChildren: PropTypes.element,
  overlayTitle: PropTypes.string,
  overlayText: PropTypes.string,
  overlayTextElement: PropTypes.element,
  linkText: PropTypes.string,
  linkTextFunction: PropTypes.func,
  withoutIcon: PropTypes.element,
  isMobile: PropTypes.bool,
  iconTooltipHover: PropTypes.string,
};

Tooltip.defaultProps = {
  startingPosition: 'top-left',
  iconTooltip: null,
  overlayChildren: null,
  overlayTitle: null,
  overlayText: null,
  linkText: null,
  linkTextFunction: null,
  withoutIcon: null,
  isMobile: false,
  iconTooltipHover: null,
  overlayTextElement: null,
};

export default Tooltip;
